import { useTheme } from '@emotion/react';
import useLocales from '../../../hooks/useLocales';
import CustomModal, {
  BreadCrumbTitle,
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from '../../../components/CustomModal';
import SearchTextField from '../../../components/SearchTextField';
import { ChangeEvent, useCallback, useState } from 'react';
import { cleanSearchInput } from '../../../utils/string';
import { debounce } from '../../../utils/timers';
import useTranslations from '../../../hooks/useTranslations';
import AnnotationsModalList from './AnnotationsModalList';
import AnnotationsModalDetail from './AnnotationsModalDetail';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';
import InfoRibbon, { InfoTypography } from '../../../components/InfoRibbon';
import { WrappedPatient } from '../../../@nicheaim/fhir-base/wrappers/Patient';
import CustomLink from './CustomLink';
import { PATH_DASHBOARD } from '../../../routes/paths';
import LaunchIcon from '@mui/icons-material/Launch';
import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import Iconify from '../../../components/Iconify';

interface AnnotationsModalProps extends CustomModalBasicProps {
  // patient: WrappedPatient | null;
  // careTeams: WrappedCareTeam[] | null;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  annotations: any[];
  breadcrumbs: string[];
  // relatedProviders: CareTeamMemberWithEditableData[];
  // providerOption: ProviderOption[] | null;
  // workflowInstanceData: any;
  patient?: WrappedPatient | null | undefined;
  handlerSave: (data: any) => Promise<any>;
  modalHeader: React.ReactNode;
}

export interface AnnotationsModalDetailForm {
  text: string;
}

const AnnotationsModal = ({
  open,
  isLoading,
  onClose,
  annotations,
  breadcrumbs,
  handlerSave,
  patient,
  modalHeader,
}: AnnotationsModalProps) => {
  const { i18n } = useLocales();

  const loggedUser = useAuth().getCurrentUser();

  const [searchTextField, setSearchTextField] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [createMode, setCreateMode] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState<any>(null);

  const translateValidationMessages = useTranslations('practitioner.validationMessages');

  const title = `${i18n('annotations.details.title', 'crs')}`;

  const validationSchema = yup.object({
    text: yup.string().required(translateValidationMessages('isRequired')),
  });

  const formik = useFormik<AnnotationsModalDetailForm>({
    initialValues: {
      text: '',
    },
    validationSchema,
    onSubmit: async (data: AnnotationsModalDetailForm, { resetForm }) => {
      const payload = {
        authorString: loggedUser.name,
        time: moment().toISOString(),
        text: data.text,
      };
      handlerSave(payload);
      resetForm();
      setCreateMode(false);
    },
  });

  const { handleSubmit, resetForm } = formik;

  const enterCreateMode = useCallback(() => {
    setSelectedAnnotation(null);
    setCreateMode(true);
  }, []);

  const exitCreateMode = useCallback(() => {
    setCreateMode(false);
  }, []);

  const handleSelectAnnotation = (annotation: any) => {
    if (annotation) {
      setCreateMode(false);
      resetForm();
      setSelectedAnnotation(annotation);
    }
  };

  const CustomActions = () => (
    <Button
      onClick={enterCreateMode}
      variant="contained"
      startIcon={<Iconify icon={'eva:plus-fill'} />}
    >{`${i18n('annotations.details.newAnnotationButton', 'crs')}`}</Button>
  );

  return (
    <CustomModal
      keepMounted
      open={open}
      title={title}
      breadcrumbs={[]}
      showSaveButton={false}
      showCancelButton={false}
      onCancel={onClose as Function}
      onClose={onClose}
      isLoading={isLoading}
      // customActions={<CustomActions />}
      showTitle={false}
      showBreadcrumbs={false}
      containerSx={{ minWidth: '80%', overflow: 'none' }}
      childrenWithoutPadding={modalHeader}

      // <Grid container mt={1} xs={12}>
      //   <Grid container item xs={4}>
      //     {!!patient && (
      //       <InfoRibbon containerSx={{ height: '60px', marginTop: 0, flexWrap: 'wrap' }}>
      //         <Grid container item xs={12} sx={{ marginLeft: 2 }}>
      //           <InfoTypography containerSx={{ fontSize: '1.1rem' }}>
      //             {`${patient?.getFullName?.()} ` ?? ' '}
      //           </InfoTypography>
      //           <CustomLink
      //             to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)}
      //             target="_blank"
      //           >
      //             <Typography noWrap variant="subtitle2">
      //               <LaunchIcon color="primary" />
      //             </Typography>
      //           </CustomLink>
      //         </Grid>
      //         <Grid container item xs={12} sx={{ marginLeft: 2 }}>
      //           {!!patient?.getMRN?.()?.value && (
      //             <>
      //               <InfoTypography>
      //                 <BadgeIcon color="inherit" /> {patient.getMRN()?.value}
      //               </InfoTypography>
      //             </>
      //           )}
      //           {!!patient?.birthDate && (
      //             <>
      //               <InfoTypography containerSx={{ marginLeft: 'auto' }}>
      //                 <CakeIcon color="inherit" />
      //                 {patient?.getBirthDateForDisplay?.()} ({patient?.getAgeInYears?.()} years)
      //               </InfoTypography>
      //             </>
      //           )}
      //         </Grid>
      //       </InfoRibbon>
      //     )}
      //   </Grid>
      //   <Grid container item xs={8}>
      //     <InfoRibbon containerSx={{ height: '60px', marginTop: 0, flexWrap: 'wrap' }}>
      //       <></>
      //     </InfoRibbon>
      //   </Grid>
      // </Grid>
    >
      <form onSubmit={handleSubmit}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={3}>
          <Typography variant="h6">{title}</Typography>
          <Box sx={{ marginLeft: 1 }} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {breadcrumbs.map((breadcrumbTitle, index) => (
              <BreadCrumbTitle
                key={breadcrumbTitle}
                useSeparator={!!index}
                title={breadcrumbTitle}
              />
            ))}
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <CustomActions />
          </Box>
        </Box>
        <Grid container my={0} py={0} spacing={0}>
          <GridSection xs={12} my={0} py={0} spacing={0}>
            <GridSection
              my={0}
              py={0}
              spacing={0}
              xs={6}
              sx={{
                // mb: 2,
                // display: 'flex',
                // flexDirection: 'column',
                // maxHeight: '50vh',
                minHeight: '60vh',
                maxHeight: '60vh',
                // overflow: 'hidden',
                // overflowY: 'auto',
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
              }}
            >
              <AnnotationsModalList
                annotations={annotations}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                searchTextField={searchTextField}
                setSearchTextField={setSearchTextField}
                handleSelectAnnotation={handleSelectAnnotation}
              />
            </GridSection>
            <GridSection
              xs={6}
              sx={{ minHeight: '60vh', maxHeight: '60vh', overflow: 'none' }}
              my={0}
              py={0}
              spacing={0}
            >
              <AnnotationsModalDetail
                createMode={createMode}
                formValues={formik.values}
                formTouched={formik.touched}
                formErrors={formik.errors}
                formHandleChange={formik.handleChange}
                selectedAnnotation={selectedAnnotation}
              />
            </GridSection>
          </GridSection>
          <GridSection xs={12} alignContent={'flex-end'} my={0} py={0} spacing={0}>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button onClick={onClose} sx={{ color: (theme) => theme.palette.grey[900] }}>
                {i18n('annotations.details.closeButton', 'crs')}
              </Button>
              {createMode ? (
                <Button
                  onClick={exitCreateMode}
                  color="error"
                  variant="contained"
                  sx={{ marginLeft: 1 }}
                >
                  {i18n('annotations.details.cancelButton', 'crs')}
                </Button>
              ) : null}

              {createMode ? (
                <Button type="submit" variant="contained" sx={{ marginLeft: 1 }}>
                  {i18n('annotations.details.saveButton', 'crs')}
                </Button>
              ) : null}
            </Box>
          </GridSection>
        </Grid>
      </form>
    </CustomModal>
  );
};

export default AnnotationsModal;
