import Iconify from 'src/components/Iconify';
import { RHFSelect } from 'src/components/hook-form';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { Checkbox, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

type Props = {
  simpleLanguage: WrappedValueSet | null;
}
const Language = ({ simpleLanguage }: Props) => {
  const { i18n } = useLocales();

  const { control, setValue, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'language',
  });

  const handleAddLanguage = () => {
    append({
      value: '',
      preferred: false,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  function handlePreferredLanguage(index: number) {
    const { language } = watch();
    const updatedLanguage = language.map((e: any) => ({ ...e, preferred: false }));
    updatedLanguage[index].preferred = true;
    setValue(`language`, updatedLanguage);
  }

  return (
    <>
      <Stack direction="row" style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">
          {i18n('patients.details.personalDetails.language', 'crs')}
        </Typography>
        <IconButton onClick={handleAddLanguage} sx={{ p: 0.5, ml: 1 }}>
          <Iconify icon={'eva:plus-fill'} />
        </IconButton>
      </Stack>
      {fields.map((item: any, index) => {
        return (
          <Stack key={item.id} direction="row">
            <RHFSelect name={`language.${index}.value`} label="" fullWidth={true}>
              {simpleLanguage?.asList().map((option) => (
                <MenuItem key={option.code} value={option.display}>
                  {option.display}
                </MenuItem>
              ))}
            </RHFSelect>

            <Controller
              name={`language.${index}.preferred`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  value={field.value}
                  onChange={() => {
                    field.onChange(handlePreferredLanguage(index));
                    setValue(`language.${index}.preferred`, true);
                  }}
                  icon={<Iconify icon="eva:star-outline" />}
                  checkedIcon={<Iconify icon="eva:star-fill" />}
                />
              )}
            />

            <IconButton
              onClick={() => handleRemove(index)}
              size="medium"
              sx={{
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              <Iconify icon={'eva:trash-2-outline'} color="red" />
            </IconButton>
          </Stack>
        )
      })}
    </>
  );
};

export default Language;
