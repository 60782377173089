import Iconify from '../../../../components/Iconify';
import { Stack, InputAdornment, TextField, MenuItem, CircularProgress } from '@mui/material';
import { GridView } from '../../../../services/api/gridView';
import { LoadingButton } from '@mui/lab';
import useLocales from 'src/hooks/useLocales';

type Props = {
  isLoading: boolean;
  options: GridView[];
  search: string;
  filterMrn: string;
  gridViewSelected: number;
  onFilterMrn: (value: string) => void;
  onGridValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (value: string) => void;
};

export default function ReferralTableToolbar({
  search,
  filterMrn,
  gridViewSelected,
  onFilterMrn,
  onGridValueChange,
  onSearch,
  options,
  isLoading,
}: Props) {
  const { i18n } = useLocales();

  return (
    <Stack direction="row" spacing={2}>
      {/* <TextField
        value={filterMrn}
        onChange={(event) => onFilterMrn(event.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      /> */}

      <TextField
        value={search}
        onChange={(event) => onSearch(event.target.value)}
        placeholder={i18n('referral.list.search', 'crs')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        select
        value={gridViewSelected}
        onChange={onGridValueChange}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 210 },
          textTransform: 'capitalize',
        }}
        InputProps={{
          endAdornment: (
            <>
              {isLoading && (
                <InputAdornment position="end" sx={{ marginRight: 2 }}>
                  <CircularProgress size={20} />
                </InputAdornment>
              )}
            </>
          ),
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={String(option.id)}
            value={option.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
