import { createContext, useContext } from 'react';
import { nanoid } from 'nanoid';
import create from 'zustand';
import { Box, Dialog } from '@mui/material';
import { immer } from 'zustand/middleware/immer';
import ConfirmDialog from 'src/components/dialogs/ConfirmDialog';

interface PendingDialog<T = any> {
  key: string;
  render: (props: PendingDialogProps) => React.ReactNode;
  promise: Promise<T>;
  resolver: (value: any) => Promise<void> | void;
  // rejecter: (reason: any) => Promise<void> | void; // No need to reject for now
}

interface State {
  dialogs: PendingDialog[];
}

interface Actions {
  /**
   * Open a dialog and return a promise that resolves when the dialog is closed.
   */
  openDialog<T>(options: OpenDialogOptions): Promise<T>;

  /**
   * Close the dialog with the given key.
   */
  closeDialog<T>(key: string, value: T): void;

  /**
   * Opens a confirmation dialog and waits for the user to confirm or cancel.
   */
  confirmDialog(options: ConfirmDialogOptions): Promise<boolean>;
  confirmDialog(options: CancellableConfirmDialogOptions): Promise<boolean | null>;
}

export const useDialogStore = create(
  immer<State & Actions>((set, get) => ({
    dialogs: [] as PendingDialog[],

    openDialog<T>(options: OpenDialogOptions) {
      const key = options.key ?? nanoid();
      const existingDialog = get().dialogs.find((dialog) => dialog.key === key);
      let resolver: (value: any) => void;

      let dialogPromise =
        existingDialog?.promise ??
        new Promise<T>((resolve) => {
          resolver = resolve;
        });

      set((state) => {
        if (!existingDialog) {
          state.dialogs.push({
            key,
            resolver,
            promise: dialogPromise,
            render: options.render,
          });
          return;
        }

        // we need to get the dialog again to update it through immer
        const updatingDialog = state.dialogs.find((dialog) => dialog.key === key);
        if (!updatingDialog) {
          return;
        }

        updatingDialog.render = options.render;
      });

      return dialogPromise;
    },

    async confirmDialog(options: ConfirmDialogOptions | CancellableConfirmDialogOptions) {
      const key = options.key ?? nanoid();

      return get().openDialog<boolean>({
        key,
        render:
          options.render ??
          (() => (
            <ConfirmDialog
              title={options.title}
              description={options.description}
              denyText={options.denyText}
              confirmText={options.confirmText}
              cancellable={options.cancellable}
              cancelText={options.cancellable ? options.cancelText : undefined}
              disabledConfirm={options.disabledConfirm}
            />
          )),
      });
    },

    closeDialog<T>(key: string, value?: T) {
      set((state) => {
        const matchingDialog = state.dialogs.find((dialog) => dialog.key === key);
        if (!matchingDialog) {
          return;
        }

        matchingDialog.resolver(value);
        state.dialogs = state.dialogs.filter((dialog) => dialog.key !== key);
      });
    },

    async alertDialog() {
      // TODO
    },
  }))
);

interface DialogContextState {
  key: string;
  close: (value?: any) => void;
}

const DialogContext = createContext<DialogContextState>({
  key: '',
  close: () => {},
});

export const useCurrentDialog = () => useContext(DialogContext);

export function DialogRenderer() {
  const { dialogs, closeDialog } = useDialogStore();

  return (
    <Box>
      {dialogs.map((dialog) => (
        <DialogContext.Provider
          key={dialog.key}
          value={{
            key: dialog.key,
            close: (value) => closeDialog(dialog.key, value),
          }}
        >
          <Dialog open>
            {dialog.render({
              key: dialog.key,
              resolve: dialog.render,
            })}
          </Dialog>
        </DialogContext.Provider>
      ))}
    </Box>
  );
}

//////////////////////////////////////////////////////////////////////////////

export interface PendingDialogProps<T = any> {
  key: string;
  resolve: (value: T) => void;
}

interface OpenDialogOptions<T = any> {
  key?: string;
  render: (options: PendingDialogProps<T>) => React.ReactNode;
}

type ConfirmDialogOptions = Partial<OpenDialogOptions> & {
  title?: string;
  description?: string | React.ReactNode;
  confirmText?: string;
  denyText?: string;
  cancellable?: false;
  disabledConfirm?: boolean;
};

type CancellableConfirmDialogOptions = Omit<ConfirmDialogOptions, 'cancellable'> & {
  cancellable: true;
  cancelText?: string;
};
