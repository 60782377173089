import { Box } from '@mui/material';
import { ReactNode, createContext, useState } from 'react';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { StatusSeverityCount } from 'src/sections/crs/case/components/CaseTaskGrid';
import ExpandableCard, { ExpandableCardProps } from 'src/components/common/ExpandableCard';

// ----------------------------------------------------------------------

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

interface CollapsibleContextProps {
  handleItem?: (items: StatusSeverityCount) => void;
};

const initialState: CollapsibleContextProps = {
  handleItem: undefined,
};

const CollapsibleContext = createContext(initialState);

interface CollapsibleProviderProps extends ExpandableCardProps {
  children: ReactNode;
};

function CollapsibleProvider({ children, ...other }: CollapsibleProviderProps) {

  const [items, setItems] = useState<StatusSeverityCount>();

  const handleItem = (items: StatusSeverityCount) => {
    setItems(items);
  }

  return (
    <CollapsibleContext.Provider
      value={{
        handleItem
      }}
    >
      <ExpandableCard 
        {...other} 
        inlineChildren={(items && other.isCollapsible) && (
          <Box display={'flex'} flexDirection={'row'}>
            {Object.entries(items).map(([severity, count]) => (
              <SeverityStatusCount
                key={severity}
                count={count}
                severity={severity as AlertSeverity}
              />
            ))}
          </Box>
        )}
      >
        {children}
      </ExpandableCard>
    </CollapsibleContext.Provider>
  );
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export { CollapsibleProvider, CollapsibleContext };