import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { useTasks } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import useHealthCareServicesByServiceRequests from 'src/hooks/useHealthCareServicesByServiceRequests';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { Task } from '../../../../@nicheaim/fhir-base/mappings/Task';
import { commonMenuTaskHandlers } from '../../case/components/TasksByPathway/handlers';
import TasksByPathway from '../../case/components/TasksByPathway/TasksByPathway';
import {
  getAssessmentFromTask,
  getAssessmentUrlWithUserId,
  isAssessmentReadyToStart,
} from '../../helpers/common';
import { TaskPermissions } from '../../types';
import AssessmentFrame from './AssessmentFrame';

export interface PatientTasksProps {
  patient: WrappedPatient;
}

const PatientTasks = ({ patient }: PatientTasksProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useAuth().getCurrentUser();

  const [tasks, { isLoading: isTasksLoading, refresh: refreshTaskList }] = useTasks({
    filter: {
      subject: patient?.id,
    },
    map: TaskWrapper,
  });

  const [openAssessmentFrame, setOpenAssessmentFrame] = useState(false);
  const [selectedTaskAssessmentId, setSelectedTaskAssessmentId] = useState<null | string>(null);
  const [selectedTaskAssessmentUrl, setSelectedTaskAssessmentUrl] = useState<null | string>(null);

  const onCancel = () => {
    setOpenAssessmentFrame(false);
    setSelectedTaskAssessmentId(null);
    setSelectedTaskAssessmentUrl(null);
  };

  const selectedTaskAssessment = useMemo(
    () => tasks.find((t) => t?.id === selectedTaskAssessmentId),
    [tasks, selectedTaskAssessmentId]
  );

  const taskPermissions: TaskPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TASKS.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TASKS.EDIT] }),
      isAllowedToAssignProgram: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.TASKS.ASSIGN_PROGRAM],
      }),
      notes: {
        isAllowedToView: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TASKS.NOTES.VIEW] }),
        isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TASKS.NOTES.ADD] }),
      },
    }),
    []
  );

  useEffect(() => {
    if (selectedTaskAssessment) {
      openOrUpdateAssessment();
    }
  }, [selectedTaskAssessment, tasks]);

  const { healthCareServices, isLoading: isHealthCareServicesLoading } =
    useHealthCareServicesByServiceRequests(null, patient?.id);

  const isLoading = isTasksLoading || isHealthCareServicesLoading || !patient?.id;

  const handleAssessmentSelect = async (task: Task) => {
    if (!task.id) return;

    const inputAssessment = getAssessmentFromTask(task);
    if (!inputAssessment) return;

    if (isAssessmentReadyToStart(inputAssessment)) {
      setSelectedTaskAssessmentId(task.id);
    } else {
      await refreshTaskList();
      setSelectedTaskAssessmentId(task.id);
    }
  };

  const openOrUpdateAssessment = () => {
    if (!selectedTaskAssessment) return;

    const inputAssessment = getAssessmentFromTask(selectedTaskAssessment);
    if (!inputAssessment) return;

    if (isAssessmentReadyToStart(inputAssessment)) {
      console.log('DEBUG isAssessmentReadyToStart');
      const assessmentUrl = getAssessmentUrlWithUserId(
        inputAssessment,
        authUser.user_fhir_uri
      );
      setSelectedTaskAssessmentUrl(assessmentUrl ?? null);
      setOpenAssessmentFrame(true);
    } else {
      enqueueSnackbar('Preparing assessment. Please try again later.', {
        variant: 'error',
      });
    }
    setSelectedTaskAssessmentId(null);
  };

  return (
    <>
      {!isLoading ? (
        <Box mt={4}>
          <TasksByPathway
            permissions={taskPermissions}
            tasks={tasks}
            patient={patient as WrappedPatient}
            healthCareServices={healthCareServices}
            refreshEntities={refreshTaskList}
            refreshTasks={refreshTaskList}
            isCollapsible={false}
            menuTaskHandlers={commonMenuTaskHandlers}
            onAssessmentSelect={handleAssessmentSelect}
            openCollapseExternal={true}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 5 }}>
          <CircularProgress size={60} />
        </Box>
      )}
      {selectedTaskAssessmentUrl && openAssessmentFrame ? (
        <AssessmentFrame
          open={openAssessmentFrame}
          url={selectedTaskAssessmentUrl}
          onCancel={onCancel}
        />
      ) : null}
    </>
  );
};

export default PatientTasks;
