import {
  Button,
  Grid,
  Stack,
} from '@mui/material';
import SummaryTable from './SummaryTable';
import { TableHead } from '../sections/crs/common/table-head';
import { Add } from '@mui/icons-material';

type Props = {
  tableHead: TableHead[];
  data: any;
  childrenButtons?: React.ReactNode;
  handleOpen?: VoidFunction;
  titleButton?: string;
  statusList?: any;
}

export function TableCustom({
  tableHead,
  data,
  childrenButtons,
  handleOpen,
  titleButton,
  statusList
}: Props){

  return (
    <>
      <Stack>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          paddingRight={2}
        >
          {childrenButtons}
          {titleButton && (
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Button 
                size="small"
                sx={{ height: '36px' }}
                startIcon={<Add />}
                style={{ position: 'absolute', top: '25px', right: '25px' }}
                onClick={handleOpen}
              >
                {titleButton}
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      <SummaryTable
        header={tableHead}
        data={Array.isArray(data) ? data : []}
        statusList={statusList}
      />
    </>
  )
}