import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';
import '../task/styles.css';

export interface CellRowProps {
  tooltipTitle?: string | null;
  Icon?: React.ReactNode | null;
  title: string | null | undefined;
  titleSx?: SxProps;
  shouldTruncateText?: boolean;
}

const CellRow = ({
  tooltipTitle = null,
  Icon,
  title,
  titleSx,
  shouldTruncateText = true,
}: CellRowProps) => (
  <>
    {!!title && (
      <Tooltip title={tooltipTitle}>
        <span>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 0.3,
              alignItems: 'center',
            }}
          >
            {Icon}
            <Typography
              variant="body2"
              className={shouldTruncateText ? 'truncate-text' : undefined}
              sx={[
                { whiteSpace: 'normal', wordBreak: 'break-word', marginLeft: Icon ? 1 : 0 },
                ...spreadSxProp(titleSx),
              ]}
            >
              {title ?? ''}
            </Typography>
          </Box>
        </span>
      </Tooltip>
    )}
  </>
);

export default CellRow;
