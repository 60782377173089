import { Box } from '@mui/material';
import CustomModal, { CustomModalBasicProps } from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';

export interface IframeModalProps extends CustomModalBasicProps {
  url: string | undefined;
  title: string | undefined;
  onClose: VoidFunction;
}

const IframeModal = ({ title, url, onClose, ...modalProps }: IframeModalProps) => {
  const { i18n } = useLocales();
  return (
    <CustomModal
      title={title || i18n('externalUrl', 'crs')}
      onClose={onClose}
      onCancel={() => {
        onClose?.();
      }}
      breadcrumbs={[]}
      showSaveButton={false}
      containerSx={{ width: '55vw' }}
      {...modalProps}
    >
      <iframe
        src={url}
        title={title}
        style={{ width: '100%', height: '60vh', border: 'none', marginTop: '20px' }}
      />
    </CustomModal>
  );
};
export default IframeModal;
