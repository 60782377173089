import { IconButton, Stack, Typography, Box, SxProps, CircularProgress } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { spreadSxProp } from 'src/utils/cssStyles';
import { Section } from 'src/utils/layout';

export interface EditableCardProps {
  children: React.ReactNode;
  title: string;
  onEditButtonPress?: VoidFunction;
  showEditButton?: boolean;
  button?: React.ReactNode;
  titleContainerSx?: SxProps;
  isLoading?: boolean;
}

const EditableCard = ({
  title,
  onEditButtonPress,
  children,
  showEditButton = true,
  button,
  titleContainerSx,
  isLoading,
}: EditableCardProps) => (
  <Section fullWidth>
    <Box sx={{ paddingX: 1, paddingY: 2 }}>
      <Stack direction="row" sx={[{ display: 'flex' }, ...spreadSxProp(titleContainerSx)]}>
        <Typography variant="button" sx={{ textTransform: 'capitalize' }}>
          {title}
        </Typography>
        {!!(button && !showEditButton) && button}
        {!!showEditButton && (
          <IconButton
            sx={{ position: 'relative', top: -10, marginLeft: 0.6 }}
            onClick={() => onEditButtonPress?.()}
          >
            <Iconify icon={'eva:edit-outline'} />
          </IconButton>
        )}
      </Stack>
      {!isLoading ? (
        <Box sx={{ marginTop: 0 }}>{children}</Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={55} />
        </Box>
      )}
    </Box>
  </Section>
);

export default EditableCard;
