import { useCallback, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { WrappedCondition } from 'src/@nicheaim/fhir-base/wrappers/Condition';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import GoalConditionDrawer, { OnConditionUnlink } from './GoalConditionDrawer';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import GoalObservationDrawer from './GoalObservationDrawer';

export interface GoalConditionListProps {
  conditions: WrappedCondition[];
  observations?: WrappedObservation[];
  goal?: WrappedGoal | null;
  onConditionUnlink?: OnConditionUnlink;
}

export const GoalConditionList = ({
  conditions,
  observations,
  goal,
  onConditionUnlink,
}: GoalConditionListProps) => {
  const theme = useTheme();
  const [isConditionDrawerOpen, setIsConditionDrawerOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState<WrappedCondition | null>(null);
  const handleSelectCondition = useCallback((condition: WrappedCondition) => {
    setIsConditionDrawerOpen(true);
    setSelectedCondition(condition);
  }, []);
  const handleCloseDrawer = useCallback(() => {
    setIsConditionDrawerOpen(false);
    setSelectedCondition(null);
  }, []);

  const [isObservationDrawerOpen, setIsObservationDrawerOpen] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState<WrappedObservation | null>(null);
  const handleSelectObservation = useCallback((observation: WrappedObservation) => {
    setIsObservationDrawerOpen(true);
    setSelectedObservation(observation);
  }, []);
  const handleCloseObservationDrawer = useCallback(() => {
    setIsObservationDrawerOpen(false);
    setSelectedObservation(null);
  }, []);

  return (
    <>
      <List>
        {conditions.map((x) => (
          <ListItem
            key={x.id}
            sx={{ color: theme.palette.primary.main, '&:hover': { cursor: 'pointer' } }}
            onClick={() => handleSelectCondition(x)}
          >
            <OpenInNewIcon sx={{ mr: 1 }} />
            {x?.code?.text ?? ''}
          </ListItem>
        ))}

        {observations &&
          observations.map((x) => (
            <ListItem
              key={x.id}
              sx={{ color: theme.palette.primary.main, '&:hover': { cursor: 'pointer' } }}
              onClick={() => handleSelectObservation(x)}
            >
              <OpenInNewIcon sx={{ mr: 1 }} />
              {x?.valueCodeableConcept?.text ?? ''}
            </ListItem>
          ))}
      </List>
      <GoalConditionDrawer
        onClose={handleCloseDrawer}
        open={isConditionDrawerOpen}
        conditionExternal={selectedCondition}
        goalExternal={goal}
        onUnlink={onConditionUnlink}
        onCloseIconButtonClick={handleCloseDrawer}
      />
      <GoalObservationDrawer
        onClose={handleCloseObservationDrawer}
        open={isObservationDrawerOpen}
        observationExternal={selectedObservation}
        goalExternal={goal}
        onCloseIconButtonClick={handleCloseObservationDrawer}
      />
    </>
  );
};

export default GoalConditionList;
