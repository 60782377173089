import { Checkbox, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { paramCase } from 'change-case';
import { useState } from 'react';
import { OrganizationManager } from 'src/@types/organization';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CustomLink from 'src/sections/crs/common/CustomLink';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';

type Props = {
  row: OrganizationManager;
  selected: boolean;
  editOption?: boolean;
  deleteOption?: boolean;
  customLink?: boolean;
  onEditRow: VoidFunction;
  onSelectRow?: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function OrganizationList({
  row,
  selected,
  editOption,
  deleteOption,
  customLink,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, uuid } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const isAllowedToDelete = checkAclValidation({
    acls: [crsAcls.ADMIN.ORGANIZATIONS.DELETE],
  });

  const isAllowedToEdit = checkAclValidation({
    acls: [crsAcls.ADMIN.ORGANIZATIONS.EDIT],
  });

  return (
    <TableRow hover selected={selected}>
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}
      <TableCell>
        {customLink ? (
          <CustomLink to={`${PATH_DASHBOARD.organization.edit(paramCase(uuid))}`}>
            <Typography noWrap variant="subtitle2">
              {name}
            </Typography>
          </CustomLink>
        ) : (
          <TableCell>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </TableCell>
        )}
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          disabled={deleteOption}
          actions={
            <>
              {!deleteOption && isAllowedToDelete && (
                <MenuItem
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              )}
              {!editOption && isAllowedToEdit && (
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              )}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
