import { Cake as CakeIcon, Person as PersonIcon } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import { Chip, Grid, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { PatientManager } from 'src/@types/crs/patient';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import MedicaIdIcon from '../../../../assets/icons/medicaid.svg';
import MRNIcon from '../../../../assets/icons/mrn.svg';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import CustomLink from '../../common/CustomLink';

type Props = {
  row: PatientManager;
};

export default function PatientRow({ row }: Props) {
  const {
    id,
    mrn,
    referral,
    phone,
    email,
    gender,
    formattedBirthDate,
    age,
    medicaId,
    sil,
    createdAt,
    lastUpdated,
    status,
    episodeOfCareData,
  } = row;

  const { componentsData } = useTenantConfigData();
  const { showSILInformation, showMedicaID } = componentsData ?? {};
  const { i18n } = useLocales();

  return (
    <>
      <TableRow>
        <TableCell>
          <Tooltip title={i18n('patients.mrn', 'crs')}>
            <Typography variant="subtitle2">
              <MRNIcon style={{ height: 13, width: 13, marginRight: 9 }} />
              <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(row.id)}>{mrn}</CustomLink>
            </Typography>
          </Tooltip>
          {showMedicaID && (
            <Tooltip title={i18n('patients.medicaid', 'crs')}>
              <Typography variant="body2">
                <MedicaIdIcon style={{ height: 13, width: 16, marginRight: 9 }} />
                {medicaId ? medicaId : null}
              </Typography>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <PersonIcon sx={{ marginRight: 1, marginBottom: '-2px' }} fontSize={'small'} />{' '}
            <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(row.id)}>
              {referral}
            </CustomLink>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <CakeIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
              fontSize={'small'}
            />
            {formattedBirthDate ? `${formattedBirthDate} (${age})` : null}
          </Typography>
          <Typography variant="body2">
            {gender && `${gender?.charAt(0).toUpperCase()}${gender?.slice(1)}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            <PhoneIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
              fontSize={'small'}
            />
            {phone}
          </Typography>
          <Typography variant="body2">
            <EmailIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-5px' }}
              fontSize={'small'}
            />
            {email}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip size="small" label={status} color={(status === 'Active' && 'info') || 'error'} />
        </TableCell>

        <TableCell>
          <Grid container direction="column" spacing={1}>
            {episodeOfCareData &&
              Object.keys(episodeOfCareData).map((eocId) => {
                const eoc = episodeOfCareData[eocId];
                const title = eoc.type[0].text;
                let firstTypeCodingDisplay: string | undefined = undefined;
                eoc.type.find((item) => {
                  const firstCodingDisplay = item.coding.find((cod) => cod.display);

                  if (firstCodingDisplay) {
                    firstTypeCodingDisplay = firstCodingDisplay.display;
                  }

                  return !!firstCodingDisplay;
                })
                let label = '';

                if (title) {
                  label += title + ' : ';
                } else if (firstTypeCodingDisplay) {
                  label += firstTypeCodingDisplay + ' : ';
                } else {
                  label += '- : ';
                }

                label += eoc.status;

                return (
                  <Grid key={eocId} item xs={12}>
                    <Chip key={eocId} size="small" label={label} color={'info'} />
                  </Grid>
                );
              })}
          </Grid>
        </TableCell>

        {!!showSILInformation && (
          <TableCell>
            {!!sil && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <ViewComfyOutlinedIcon
                  sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
                  fontSize={'small'}
                />
                SIL Score: {sil ? sil : null}
              </Typography>
            )}
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">Registration Date: {createdAt ? createdAt : null}</Typography>
          <Typography variant="body2">Last Updated: {lastUpdated ? lastUpdated : '-'}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
