import { createContext } from 'react';
import Bed from '../rounding/Model/Bed';
import BedAssignment from '../rounding/Model/BedAssignment';

export type CensusContextType = {
  openAddBedModal: (bed: Bed | null) => void;
  closeAddBedModal: () => void;
  onSaveEntity: (bed: Bed, bedAssignment: BedAssignment) => void;
};

export const CensusContext = createContext<CensusContextType>({
  openAddBedModal: () => {},
  closeAddBedModal: () => {},
  onSaveEntity: () => {},
});

export const CensusProvider = CensusContext.Provider;