import { isArray } from 'lodash';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';

export const getFhirChildOrganizations = async (fhirId: string) => {
  const query = `Organization?partof=${fhirId}`;
  // console.log('DEBUG query: ', query);
  const organizations = await axiosFhirInstance.get(query);
  return organizations;
};

export async function getChildOrganizations(organizationIds: any[] = [], startAt = 0) {
  let newOrgs: any[] = [];
  const orgsToSearch = organizationIds.slice(startAt);
  // console.log('DEBUG orgsToSearch: ', orgsToSearch);
  for (const orgId of orgsToSearch) {
    const result = await getFhirChildOrganizations(orgId);
    const fhirOrgs = result?.data;
    // console.log('DEBUG fhirOrgs: ', fhirOrgs);
    if (
      'entry' in fhirOrgs &&
      fhirOrgs?.entry &&
      isArray(fhirOrgs?.entry) &&
      fhirOrgs?.entry?.length > 0
    ) {
      const childOrgs =
        fhirOrgs?.entry
          ?.map?.((x) => x?.resource?.id)
          // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
          ?.reduce?.((distinctOrgIds: any, orgId: any) => {
            if (organizationIds.includes(orgId)) return distinctOrgIds;
            return [...distinctOrgIds, orgId];
          }, []) ?? [];
      newOrgs = [...newOrgs, ...childOrgs];
    }
  }
  if (newOrgs && newOrgs?.length > 0) {
    startAt = organizationIds?.length;
    organizationIds = [...organizationIds, ...newOrgs];
    return getChildOrganizations(organizationIds, startAt);
  }
  return organizationIds;
}

// export const getChildOrganizations = async (organizationIds: any[] = [], startAt = 0) => {};
