import { 
  Box, 
  Card,
  Grid, 
  Typography 
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "src/components/hook-form";
import SettingColorPresets from "src/components/settings/drawer/SettingColorPresets";

export const SettingForm = () => {
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card sx={{ m: 2,  p: 2 }}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <RHFTextField name="keyRegistry" label="Registry" placeholder="Ex: tenant-theme-registry" />
            <RHFTextField disabled name="groupIdentifier" label="Group Identifier" />
            <RHFTextField 
              multiline 
              rows={2} 
              name="keyDescription" 
              label="Description"
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export const TenantForm = () => {
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card sx={{ m: 2,  p: 2 }}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <RHFTextField name="tenantName" label="Name" placeholder="Ex: MyApp"/>
            <RHFTextField name="logo" label="Logo" placeholder="Ex: /logo/logo.png"/>

            <RHFTextField name="tenantFavicon" label="Favicon" placeholder="Ex: /favicon/favicon.png"/>
            <RHFTextField name="tenantSpinnerLogo" label="Spinner" placeholder="Ex: /logo/spinner.png"/>

            <RHFTextField name="url" label="URL" placeholder="Ex: https://myapp.com/auth/tenant-info"/>

            <RHFTextField name="pagePrefix" label="Page Prefix" placeholder="Ex: MyApp - "/>
            <RHFTextField name="realm" label="Realm" placeholder="Ex: tenant-dev"/>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export const ThemeForm = () => {

  const {
    setValue,
    watch,
  } = useFormContext();

  const { themeColorPresets } = watch();

  const handleColor = (event: any) => {
    setValue('themeColorPresets', event.target.value);
  };
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card sx={{ m: 2,  p: 2 }}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <Typography variant="subtitle2">Theme Color</Typography>
            <SettingColorPresets colorSelected={themeColorPresets} handleColor={handleColor}/>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}