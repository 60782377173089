/* eslint-disable */
// routes

import { CRS_PATH, PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// other
import { getUserPermissions } from '../../../utils/permissions/get.user.permissions';
import { NavListProps } from '../../../components/nav-section/type';
import { matchPath } from 'react-router';
import Iconify from 'src/components/Iconify';
import { CCM_ACLS } from 'src/utils/permissions/permission.utils';
import { IS_CRS, Modules } from 'src/config';
import {
  Person as PersonIcon,
  People as PeopleIcon,
  Topic as TopicIcon,
} from '@mui/icons-material';
import useLocales from 'src/hooks/useLocales';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import StethoscopeIcon from '../../../assets/icons/stethoscope.svg';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard_02'),
  menuItem: getIcon('ic_menu_item'),
  checklist: getIcon('ic_checklist_03'),
  collaboration: getIcon('ic_collaboration'),
  record: getIcon('ic_record'),
  personalInformation: getIcon('ic_personal_information'),
  report: getIcon('ic_report'),
  rounding: <Iconify icon="material-symbols:fact-check-outline" />,
  permissions: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
  task: <Iconify icon="material-symbols:format-list-bulleted" />,
  setting: <Iconify icon="ci:settings-filled" />,
  incident: <Iconify icon="material-symbols:warning-outline" />,
};

interface SectionPermissions {
  subheader: string;
  itemsByPermission: Record<string, NavListProps>;
  module?: Modules;
}

const mappings = ({ i18n }: any): SectionPermissions[] => {
  return [
    {
      subheader: 'Admin',
      itemsByPermission: {
        [crsAcls.ADMIN.USERS.MENU]: {
          title: `${i18n('adminNav.users')}`,
          path: PATH_DASHBOARD.user.list,
          icon: ICONS.user,
        },
        [crsAcls.ADMIN.ORGANIZATIONS.MENU]: {
          title: `${i18n('adminNav.organization')}`,
          path: PATH_DASHBOARD.organization.list,
          icon: ICONS.banking,
        },
        [crsAcls.ADMIN.PERMISSIONS.MENU]: {
          title: `${i18n('adminNav.permissions')}`,
          path: PATH_DASHBOARD.general.permissions,
          icon: ICONS.permissions,
        },
      },
      // 'admin.permissions.setting': {
      //   title: 'Settings',
      //   path: PATH_DASHBOARD.settings.list,
      //   icon: ICONS.setting,
      // },
    },
    {
      subheader: 'TOOLS',
      module: Modules.CCM,
      itemsByPermission: {
        [CCM_ACLS.TASK_ACTIVITIES.MENU]: {
          title: 'Tasks / Activities',
          path: PATH_DASHBOARD.general.activities,
          icon: ICONS.checklist,
        },
        [CCM_ACLS.CENSUS.name]: {
          title: 'Census',
          path: PATH_DASHBOARD.general.roundings.census,
          icon: <Iconify icon={'icon-park-outline:hospital-bed'} />,
        },
        [CCM_ACLS.ROUNDINGS.name]: {
          title: 'Roundings',
          path: PATH_DASHBOARD.general.roundings.list,
          icon: ICONS.rounding,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/roundings', pathname) ||
              !!matchPath('/dashboard/roundings/add-rounding', pathname) ||
              !!matchPath('/dashboard/roundings/:roundingId/edit', pathname)
            );
          },
        },
        [CCM_ACLS.GROUP_SESSIONS.name]: {
          title: 'Group Sessions',
          path: PATH_DASHBOARD.general.groupSessions,
          icon: <Iconify icon={'ic:outline-groups-3'} />,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/group-sessions', pathname) ||
              !!matchPath('/dashboard/group-sessions/:groupSessionId/sessions', pathname) ||
              !!matchPath('/dashboard/group-sessions/:groupSessionId/edit', pathname) ||
              !!matchPath(
                '/dashboard/group-sessions/:groupSessionId/sessions/:sessionId/edit',
                pathname
              )
            );
          },
        },
        [CCM_ACLS.MED_PASS.name]: {
          title: 'Med Pass',
          path: PATH_DASHBOARD.general.medPass,
          icon: <Iconify icon={'material-symbols:medication-liquid-outline-sharp'} />,
          checkActive(pathname) {
            return !!matchPath('/dashboard/med-pass', pathname);
          },
        },
        'ccm.reports': {
          title: 'Analytics / Reports',
          path: PATH_DASHBOARD.general.reports,
          icon: ICONS.report,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/patient/:patientId/reports/*', pathname) ||
              !!matchPath('/dashboard/reports/*', pathname)
            );
          },
        },
      },
    },
    {
      subheader: 'PATIENT DETAILS',
      module: Modules.CCM,
      itemsByPermission: {
        [CCM_ACLS.PATIENt_DEMOGRAPHICS.name]: {
          title: 'Patient Demographics',
          path: PATH_DASHBOARD.general.patientDemographics,
          icon: ICONS.personalInformation,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/patient/:patientId/patient-demographics/*', pathname) ||
              !!matchPath('/dashboard/patient-demographics/*', pathname)
            );
          },
        },
        [CCM_ACLS.HEALTH_RECORD.name]: {
          title: 'Health Record',
          path: PATH_DASHBOARD.general.healthRecords,
          icon: ICONS.record,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/patient/:patientId/health-record/*', pathname) ||
              !!matchPath('/dashboard/health-record/*', pathname)
            );
          },
        },
        [CCM_ACLS.CAREPLAN.name]: {
          title: 'Care Record',
          path: PATH_DASHBOARD.general.careRecord,
          icon: ICONS.collaboration,
          checkActive(pathname) {
            return (
              !!matchPath('/dashboard/patient/:patientId/care-record/*', pathname) ||
              !!matchPath('/dashboard/care-record/*', pathname)
            );
          },
        },
      },
    },
    {
      subheader: i18n('menu_title','crs'),
      module: Modules.CRS,
      itemsByPermission: {
        [crsAcls.CRS.DASHBOARD.MENU]: {
          title: `${i18n('menu.myDashboard','crs')}`,
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
        [crsAcls.CRS.PATIENT.MENU]: {
          title: `${i18n('menu.patient','crs')}`,
          path: PATH_DASHBOARD.crs.patient.list.value,
          icon: ICONS.personalInformation,
        },
        [crsAcls.CRS.REFERRAL.MENU]: {
          title: `${i18n('menu.inboundReferral','crs')}`,
          path: PATH_DASHBOARD.crs.referral,
          icon: ICONS.collaboration,
        },
        [crsAcls.CRS.CASE.MENU]: {
          title: `${i18n('menu.case','crs')}`,
          path: PATH_DASHBOARD.crs.case,
          icon: ICONS.checklist,
        },
        [crsAcls.CRS.TASK.MENU]: {
          title: `${i18n('menu.task','crs')}`,
          path: PATH_DASHBOARD.crs.task,
          icon: ICONS.task,
        },
        [crsAcls.CRS.PRACTITIONER.MENU]: {
          title: `${i18n('menu.practitioner','crs')}`,
          path: CRS_PATH.provider.list,
          icon: <StethoscopeIcon className="fillColor" />,
        },
      },
    },
    {
      subheader: 'YoCare',
      module: Modules.CRS,
      itemsByPermission: {
        [crsAcls.YOCARE.USERS.MENU]: {
          title: `${i18n('yocareNav.users')}`,
          path: PATH_DASHBOARD.yoCare.users,
          icon: <PersonIcon />,
        },
        [crsAcls.YOCARE.PERSONS.MENU]: {
          title: `${i18n('yocareNav.persons')}`,
          path: PATH_DASHBOARD.yoCare.persons,
          icon: <PeopleIcon />,
        },
        [crsAcls.YOCARE.DATASYSTEMS.MENU]: {
          title: `${i18n('yocareNav.dataSystems')}`,
          path: PATH_DASHBOARD.yoCare.dataSystems,
          icon: <TopicIcon />,
        },
      },
    },
    {
      subheader: 'engagement hub',
      module: Modules.CRS,
      itemsByPermission: {
        [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.MENU]: {
          title: `${i18n('patientEngagement.menuTitle', 'engagement')}`,
          path: PATH_DASHBOARD.engagementHub.patientEngagement,
          icon: ICONS.collaboration,
        },
      },
    },
    {
      subheader: 'data quality',
      module: Modules.CRS,
      itemsByPermission: {
        [crsAcls.DATA_QUALITY.INCIDENT.MENU]: {
          title: `${i18n('menu.incident','crs')}`,
          path: PATH_DASHBOARD.dataQuality.incident,
          icon: ICONS.incident,
        }, 
      },
    },
  ];
};

export interface Section {
  subheader: string;
  items: NavListProps[];
}

const getNavConfig = (): Section[] => {
  const { i18n } = useLocales();

  const userPermissions = getUserPermissions();
  const mappingsByModule = IS_CRS
    ? mappings({ i18n }).filter(({ module }) => module === Modules.CRS || module === undefined)
    : mappings({ i18n });
  return mappingsByModule.reduce((sections, sectionPermissions) => {
    const permissions = Object.keys(sectionPermissions.itemsByPermission);
    const allowedPermissions = permissions.filter((permission) =>
      userPermissions.isAllowed(permission)
    );
    if (allowedPermissions.length > 0) {
      sections.push({
        subheader: sectionPermissions.subheader,
        items: allowedPermissions.map(
          (permission) => sectionPermissions.itemsByPermission[permission]
        ),
      });
    }
    return sections;
  }, [] as Section[]);
};

export default getNavConfig;
