import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { DateRange, FilterDrawerListProps, PersonStatus } from '../../types';
import FilterDrawer, { FilterContainer } from 'src/components/FilterDrawer';
import CollapsibleComponent from '../../components/CollapsibleComponent';
import DatePickerMoment from 'src/components/DatePickerMoment';
import { capitalize } from 'lodash';

export interface PersonFilters {
  id: string;
  status: string[];
  createdBy: string;
  createdOn: DateRange | null;
  updatedBy: string;
  updatedOn: DateRange | null;
}

export interface PersonsListFilterProps extends FilterDrawerListProps {
  formik: FormikProps<PersonFilters>;
  onSetSelectedFilterName: (name: string) => void;
  selectedFilterName: string | null;
}

const PersonsListFilter = ({
  formik,
  onSetSelectedFilterName,
  selectedFilterName,
  ...filterDrawerProps
}: PersonsListFilterProps) => {
  const handleFilterSelection = (name: string) => () => {
    onSetSelectedFilterName(name);
  };
  return (
    <FilterDrawer
      {...filterDrawerProps}
      title="Persons Filters"
      anchor={'right'}
      buttonsContainerSx={{ marginTop: 0 }}
    >
      <Box marginY={3}>
        <FilterContainer label="Status">
          <Autocomplete
            multiple
            fullWidth
            options={Object.values(PersonStatus) as string[]}
            id="status"
            value={formik.values.status ?? []}
            getOptionLabel={(value) => capitalize(value as string)}
            onChange={(_: React.SyntheticEvent, status) => {
              formik.setFieldValue('status', status);
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </FilterContainer>
        <CollapsibleComponent
          title="ID"
          sectionName="id"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('id')}
        >
          <TextField
            fullWidth
            id="id"
            name="id"
            placeholder="Id"
            value={formik.values.id}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>

        <CollapsibleComponent
          title="Created By"
          sectionName="createdBy"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('createdBy')}
        >
          <TextField
            fullWidth
            id="createdBy"
            name="createdBy"
            placeholder="Created By"
            value={formik.values.createdBy}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Created On"
          sectionName="createdOn"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('createdOn')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePickerMoment
                ignoreInvalidInputs
                label="Start"
                value={formik.values.createdOn?.start ?? null}
                error={formik.errors?.createdOn ? formik.errors.createdOn : null}
                onChange={(value) => {
                  formik.setFieldValue('createdOn.start', value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerMoment
                ignoreInvalidInputs
                label="End"
                value={formik.values.createdOn?.end ?? null}
                onChange={(value) => {
                  formik.setFieldValue('createdOn.end', value);
                }}
              />
            </Grid>
          </Grid>
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Updated By"
          sectionName="updatedBy"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('updatedBy')}
        >
          <TextField
            fullWidth
            id="updatedBy"
            name="updatedBy"
            placeholder="Updated By"
            value={formik.values.updatedBy}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Updated On"
          sectionName="updatedOn"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('updatedOn')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePickerMoment
                disableMaskedInput
                label="Start"
                value={formik.values.updatedOn?.start ?? null}
                onChange={(value) => {
                  formik.setFieldValue('updatedOn.start', value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerMoment
                disableMaskedInput
                label="End"
                value={formik.values.updatedOn?.end ?? null}
                onChange={(value) => {
                  formik.setFieldValue('updatedOn.end', value);
                }}
              />
            </Grid>
          </Grid>
        </CollapsibleComponent>
      </Box>
    </FilterDrawer>
  );
};

export default PersonsListFilter;
