import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';

export interface UseFrontEndPaginationParams {
  initialRowsPerPage?: number;
  defaultSort?: GridSortModel;
}

const useClientGrid = ({ initialRowsPerPage = 10, defaultSort }: UseFrontEndPaginationParams) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSort ?? []);

  const handlePageSizeChange = useCallback((page: number) => {
    setRowsPerPage(page);
  }, []);

  const handleSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      if (
        newSortModel?.[0]?.field === sortModel?.[0]?.field &&
        newSortModel?.[0]?.sort === sortModel?.[0]?.sort
      )
        return;
      setSortModel(newSortModel);
    },
    [sortModel]
  );

  return {
    sortModel,
    handleSortModelChange,
    rowsPerPage,
    handlePageSizeChange,
  };
};

export default useClientGrid;
