// @ts-nocheck
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OrganizationManager } from 'src/@types/organization';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  LoadingTableRow
} from 'src/components/table';
import useSettings from 'src/hooks/useSettings';
import useTable, { emptyRows, getComparator } from 'src/hooks/useTable';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { OrganizationTableRow, OrganizationTableToolbar } from 'src/sections/organization/list';
import api from 'src/services/api';
import { paramCase } from 'change-case';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { OrganizationAclsArray } from 'src/@types/organization';
import { checkAclValidation, getRelatedAcls } from 'src/utils/permissions/permission.utils';
import {
  getOrganizationByAcl,
  getOrganizationsByIds,
} from 'src/sections/settings/care-plan-automation/organizations';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

const TABLE_HEAD = [{ id: 'name', label: 'admin.name', align: 'left' }, { id: '' }];

export default function OrganizationList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { i18n } = useLocales();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState<OrganizationManager[]>([]);

  const [filterName, setFilterName] = useState('');

  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOrganizationsList();
  }, []);

  const getOrganizationsList = async () => {
    setIsLoading(true);

    const currentUser = auth.getCurrentUser();

    const acls: Partial<OrganizationAclsArray> = {
      all: getRelatedAcls([crsAcls.ADMIN.ORGANIZATIONS.ALL]),
      childOrg: getRelatedAcls([crsAcls.ADMIN.ORGANIZATIONS.CHILD_ORGANIZATIONS.VIEW]),
    };

    const getOrganizations = await getOrganizationByAcl(currentUser, acls);

    if (getOrganizations?.length > 0) {
      const listOrgs = await getOrganizationsByIds(getOrganizations);
      setTableData(listOrgs);
    }

    setIsLoading(false);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = async (uuid: string) => {
    await api.organizations.deleteOrganization(uuid);
    getOrganizationsList();
  };


  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.organization.edit(paramCase(id)));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!filterName;

  return (
    <Page title={i18n('admin.listTitle')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          title={i18n('admin.listTitle')}
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('admin.listTitle')}`, href: PATH_DASHBOARD.organization.root },
          ]}
          action={
            checkAclValidation({ acls: [crsAcls.ADMIN.ORGANIZATIONS.ADD] }) && (
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.organization.new}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                {i18n('admin.newOrganizationButton')}
              </Button>
            )
          }
        />

        <Card>
          <OrganizationTableToolbar filterName={filterName} onFilterName={handleFilterName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={translateTableHead(TABLE_HEAD)}
                  rowCount={tableData.length}
                  onSort={onSort}
                />

               {!isLoading ? (
                 <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <OrganizationTableRow
                        key={row.uuid}
                        row={row}
                        onDeleteRow={() => handleDeleteRow(row.uuid)}
                        onEditRow={() => handleEditRow(row.uuid)}
                        customLink={true}
                      />
                    ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                ) : (
                  <LoadingTableRow colSpan={TABLE_HEAD.length} />
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

export function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: OrganizationManager[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
