import Cookies from 'js-cookie';
import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import { getCareflowToken } from 'src/utils/auth-utils';
import IncidentService, { IncidentApiClient } from './IncidentService';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';

// TODO: refactor to have less similar axios instances
function getAccessToken(): string | undefined {
    return Cookies.get('careflowToken');
}
const baseUrl = getBackEndBaseUrl();

const apiConfig = new ApiConfiguration();
apiConfig.getAccessToken = getCareflowToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const incidentApiClient = new IncidentApiClient(new ApiClient(apiConfig));
export const incidentService = new IncidentService(incidentApiClient);