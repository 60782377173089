import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Typography,
} from '@mui/material';

export interface CollapsibleComponentProps extends AccordionProps {
  title: string;
  sectionName: string;
  selectedSectionName: string | null;
}
const CollapsibleComponent = ({
  title,
  children,
  sectionName,
  selectedSectionName,
  ...accordionProps
}: CollapsibleComponentProps) => (
  <Accordion
    {...accordionProps}
    sx={{ width: '100%', margin: 0, padding: 0 }}
    expanded={sectionName === selectedSectionName}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="body2" sx={{ width: '100%', flexShrink: 0, fontWeight: 'bold' }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default CollapsibleComponent;
