// @ts-nocheck
import { Container, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { Settings as CarouselSettings } from 'react-slick';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Page from 'src/components/Page';
import useCycle from 'src/hooks/useCycle';
import useSettings from 'src/hooks/useSettings';
import { Carousel } from 'src/sections/careflow/common';
import { PatientInfoHeader } from 'src/sections/careflow/health-record/patient-info-header';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import AssessmentsIndex from './assessments';
import { CarePlanIndex } from './care-plan';
import NotesSummary from './notes';
import { checkAclValidation, permissionsAcls } from 'src/utils/permissions/permission.utils';
import Analytics from './analytics';
import ActivitiesSummary from './activities';
import { WidgetEnum, cards } from './constants';
import ReferralsSummary from './referrals/ReferralsSummary';

export default function CareRecordIndex() {
  const navigate = useNavigate();

  const patient = useCurrentPatient();
  const { themeStretch } = useSettings();

  const zoomLevel = useCycle(
    [
      // level 1: all 3 cards visible
      {
        slidesToShow: 3,
        slidesToScroll: 3,
      },

      // level 2: only 1 card visible, others half-visible
      {
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 1,
      },

      // level 3: only 1 card fully visible
      {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    ] as Partial<CarouselSettings>[],
    { loopback: true }
  );

  const zoomAllCards = () => zoomLevel.setIndex(0);
  const zoomInCard = () => zoomLevel.setIndex(2);

  // we use this to keep track of the current card index when zoomed out
  const params = useMatch('/dashboard/patient/:patient/care-record/:card/*')?.params;
  const currentCard = params?.card as WidgetEnum;
  const currentCardSubRoute = params?.['*'];
  const newCardIndex = cards.indexOf(currentCard);
  const [currentCardIndex, setCurrentCardIndex] = useState(() => {
    let initialCard = cards.indexOf(currentCard);

    if (initialCard !== -1) {
      zoomInCard();
      return initialCard;
    }

    return 0;
  });

  const isExpanded = currentCard ? true : false;

  const handleZoomFor = useCallback(
    function handleZoomFor(card: WidgetEnum) {
      return function () {
        if (currentCard === card) {
          navigate(`/dashboard/patient/${patient?.id}/care-record`);
        } else {
          navigate(`/dashboard/patient/${patient?.id}/care-record/${card}`);
        }
      };
    },
    [navigate, currentCard, patient?.id]
  );

  useEffect(() => {
    if (!patient) return;

    if (!currentCard) {
      zoomAllCards();
      return;
    }

    if (newCardIndex === -1) {
      navigate(`/dashboard/patient/${patient.id}/care-record`);
      return;
    }

    zoomInCard();
    setCurrentCardIndex(cards.indexOf(currentCard));

    if (currentCardIndex !== newCardIndex) {
      navigate(
        `/dashboard/patient/${patient.id}/care-record/${currentCard}/${currentCardSubRoute}`
      );
    }
    //  we dont care if zoomLevel changes:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard, patient, navigate]);

  return (
    <Page title="Care Record" height="100%">
      <Container maxWidth={themeStretch ? undefined : 'xl'} sx={{ height: '100%' }}>
        <Stack spacing={3} direction="column" sx={{ height: '100%' }}>
          <Breadcrumbs
            title="Care Plan"
            links={[
              {
                href: '',
                name: 'Dashboard',
              },
              {
                href: '',
                name: 'Care Plan',
              },
              {
                href: '',
                name: patient?.getFullName() ?? '',
              },
            ]}
          />

          <PatientInfoHeader />

          <Carousel
            current={currentCardIndex}
            settings={{
              infinite: false,
              ...zoomLevel.value,
            }}
            buttonsBehavior={zoomLevel.index === 0 ? 'page' : 'slide'}
            onChange={({ slide, behavior, page, external }) => {
              if (!external && behavior === 'slide') {
                navigate(`/dashboard/patient/${patient?.id}/care-record/${cards[slide]}`);
              }

              setCurrentCardIndex(slide);
            }}
          >
            <ActivitiesSummary
              patient={patient}
              isExpanded={isExpanded}
              onExpand={handleZoomFor(WidgetEnum.activities)}
            />

            <Analytics patient={patient} onExpand={handleZoomFor(WidgetEnum.analytics)} />

            <NotesSummary
              patient={{
                id: patient?.id ?? '',
              }}
              isExpanded={isExpanded}
              onExpand={handleZoomFor(WidgetEnum.notes)}
            />

            <AssessmentsIndex onZoom={handleZoomFor(WidgetEnum.assessments)} />

            {checkAclValidation({
              acls: [permissionsAcls.readCarePlan],
            }) && (
              <CarePlanIndex
                patient={{
                  id: patient?.id ?? '',
                }}
                onZoom={handleZoomFor(WidgetEnum.careplan)}
                isExpanded={isExpanded}
              />
            )}

            <ReferralsSummary
              patient={patient}
              isExpanded={isExpanded}
              onExpand={handleZoomFor(WidgetEnum.referrals)}
            />
          </Carousel>
        </Stack>
      </Container>
    </Page>
  );
}
