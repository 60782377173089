import { format } from "date-fns";
import { PatientEngagementContactAttemptResponse } from "src/engagement/patient/PatientService";
import { formattedDateTimeZone } from "src/utils/dates";

export function mapContactAttempts(contactAttempts: PatientEngagementContactAttemptResponse[]) {
  return contactAttempts
    ?.filter((item) => item?.noteId)
    .map((i, index) => ({
      when:
        i?.patientEngagementContactAttemptContactOn && 
          format(new Date(i?.patientEngagementContactAttemptContactOn), 'MMM dd, yyyy hh:mm a'),
      outcome: i?.patientEngagementContactAttemptOutcome,
      type: i?.noteType,
      nextContact: {
        data:
          i?.patientEngagementContactAttemptContactOn &&
          formattedDateTimeZone(i?.patientEngagementContactAttemptNextContactOn),
        last: index === contactAttempts?.length - 1 ? true : false,
      },
      notes: i?.noteText,
    }));
}