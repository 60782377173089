import moment from "moment";
import { capitalCase } from "change-case";
import { WrappedTask } from "src/@nicheaim/fhir-base/wrappers/Task";
import { PractitionerWrapper } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { OrganizationWrapper } from "src/@nicheaim/fhir-base/wrappers/Organization";
import { RelatedPersonWrapper } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { WrappedPlanDefinition } from "src/@nicheaim/fhir-base/wrappers/PlanDefinition";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { PatientWrapper, WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { MeasureReport } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export const HJAssessment = (
  serviceRequest: WrappedServiceRequest | null, 
  planDefinition: WrappedPlanDefinition | null,
  task: WrappedTask | undefined,
  measureReport: MeasureReport | undefined,
  ownerTask: any
) => {
  return {
    reference: {
      show: false,
      "HJ Assessment ID": serviceRequest?.id,
    },
    serviceRequestId: {
      show: false,
      Number: serviceRequest?.identifier?.[0]?.value,
    },
    orderOn: {
      show: false,
      "Order On": moment(serviceRequest?.authoredOn).format('MMM dd, YYYY')
    },
    "HJ Date":  moment(serviceRequest?.authoredOn).format('MMM d, YYYY'),
    "HJ Status": capitalCase(serviceRequest?.status ?? ''),
    serviceRequestIntent: {
      show: false,
      "HJ Assessment Intent": serviceRequest?.intent,
    },
    planDefinition: {
      show: false,
      "Plan ID": planDefinition?.id,
    },
    "HJ Plan": planDefinition?.title,
    taskId: {
      show: false,
      "HJ Invitation ID": task?.id
    },
    "HJ Invitation No":task?.getTaskNumberNAT(),
    "HJ Invitation Date":  moment(task?.authoredOn).format('MMM d, YYYY'),
    "HJ Invitation Status": capitalCase(task?.status ?? ''),
    "HJ Invitation Priority": capitalCase(task?.priority ?? ''),
    "HJ Date ": moment(measureReport?.period?.end).format('MMM d, YYYY'), 
    reportName: {
      show: false,
      "Report Name": measureReport ? measureReport?.measure : "Not Available",
    },
    ...setOwnerType(ownerTask)
  }
};

export const memberDetails = (patient: WrappedPatient | undefined) => {
  const wrapperPatient = patient && PatientWrapper(patient);
  return {
    reference: {
      show: false,
      ID: wrapperPatient?.id,
    },
    Name: wrapperPatient?.getFullName(),
    DoB: wrapperPatient?.getBirthDateForDisplay(),
    Age: wrapperPatient?.getAgeInYears(),
    MRN: wrapperPatient?.getMRN()?.value,
    Phone: wrapperPatient?.getPrimaryPhone()?.value,
    Email: wrapperPatient?.getPrimaryEmail()?.value,
    Race: wrapperPatient?.getRace()?.display,
    Ethnicity: wrapperPatient?.getEthnicity()?.display,
    Language: wrapperPatient?.getLanguage()?.[0]?.value,
    "Mgmt Org": wrapperPatient?.managingOrganization?.display
  }
};

const setOwnerType = (ownerTask: any) => {

 const setOwner = ownerTask && ownerTask?.resourceType === 'Patient' ?  
    PatientWrapper(ownerTask) : RelatedPersonWrapper(ownerTask);

  return {
    referenceOwner: {
      show: false,
      "ID Owner": setOwner?.id,
    },
    "Owner Type": setOwner?.resourceType === 'Patient' ? 'Beneficiary' : 'Parent/Legal Guardian',
    "Owner Name": setOwner?.getFullName(),
    "Owner Phone": setOwner?.getPrimaryPhone()?.value,
    "Owner Email": setOwner?.getPrimaryEmail()?.value
  }
};

export const mapRequester = (requesterTask: any, task: WrappedTask | undefined) => {

  let mapRequester: any;

  const getUser = task?.identifier?.find((item) => item?.type?.text === 'User')?.value;

  const setRequester = requesterTask && requesterTask?.resourceType === 'Practitioner' ?  
     PractitionerWrapper(requesterTask) : OrganizationWrapper(requesterTask);

  if(setRequester?.resourceType === 'Organization'){
    mapRequester = {
      referenceRequester: {
        show: false,
        "ID Requester": setRequester?.id,
      },
      type: {
        show: false,
        type: setRequester?.type?.[0]?.coding?.[0].display,
      },
      Email: getUser
    }
  }else{
    mapRequester = {
      referenceRequester: {
        show: false,
        "ID Requester": setRequester?.id,
      },
      NPI: setRequester?.getIdentifierBySystem(process.env.REACT_APP_SYSTEM_NPI),
      Name: setRequester?.getFullName(),
      Phone: setRequester?.getPrimaryPhone()?.value,
      Email: setRequester?.getPrimaryEmail()?.value
    }
  }
 
   return mapRequester;
 };