import { ReactNode, createContext, useEffect, useReducer, useState } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import jwtDecode from 'jwt-decode';
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'src/@types/auth';
import { SESSION_EXPIRED_URL, } from 'src/config';
import useFirebaseAuth from 'src/hooks/useFirebaseAuth';

import {
  getAcls,
  getAclsFromStorage,
  getUserOrganizations,
  getUserOrganizationsFromStorage,
} from 'src/services/api/permissions';
import { getCareflowToken, getDecodedCareflowToken } from 'src/utils/auth-utils';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  SetTenant = 'SET_TENANT',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.SetTenant]: {
    currentTenant: string;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

export interface SimpleOrgI {
  name: string;
  fhir_uri: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  currentTenantCode: undefined,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case 'SET_TENANT':
      return {
        ...state,
        currentTenantCode: action.payload.currentTenant,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<
  | (JWTContextType & {
      validateCareflowToken: () => boolean;
      getCurrentUser: () => any;
      getCurrentAuthTokenClaims: () => any;
      getSelectedOrg: () => SimpleOrgI;
      checkTenantCodeInMemoryNToken: () => void;
    })
  | null
>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [isAclLoading, setIsAclLoading] = useState(true);
  const [isUserOrgsLoading, setIsUserOrgsLoading] = useState(true);
  useFirebaseAuth();

  useEffect(() => {
    const getAsyncAcls = async () => {
      await getAcls();
      setIsAclLoading(false);
    };
    getAsyncAcls();
    const getAsyncOrganizations = async () => {
      await getUserOrganizations();
      setIsUserOrgsLoading(false);
    };
    getAsyncOrganizations();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/my-account');
          const { user } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/account/login', {
      email,
      password,
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const validateCareflowToken = () => {
    const careflowToken = getCareflowToken(true);

    if (careflowToken && isValidToken(careflowToken)) {
      const decoded = jwtDecode<any>(careflowToken);
      const user = {
        displayName: decoded.preferred_username,
        email: decoded.email,
        organization: decoded?.organization,
        role: decoded?.role,
        roleName: decoded?.role_name ?? '',
      };
      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
      return true;
    }
    return false;
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const getCurrentUser = () => {
    // getACLS()
    try {
      const careflowToken = getCareflowToken();
      const acls = getAclsFromStorage();
      const userOrgs = getUserOrganizationsFromStorage();

      if (careflowToken) {
        const decoded = jwtDecode<any>(careflowToken);
        const user = {
          user_fhir_uri: decoded.user_fhir_uri,
          organization_fhir_uri: decoded.organization_fhir_uri,
          id: decoded.user_uid,
          displayName: decoded.preferred_username,
          name: decoded.name,
          email: decoded.email,
          tenant_code: decoded.tenant_code,
          role: decoded.role,
          roleName: decoded?.role_name ?? '',
          organization: decoded.organization,
          userName: decoded.username,
          acls,
          organizations: userOrgs,
        };
        return user;
      }
    } catch (error) {}

    return null;
  };

  function getCurrentAuthTokenClaims() {
    const dCareflowToken = getDecodedCareflowToken();
    return dCareflowToken;
  }

  function getSelectedOrg() {
    const dCareflowToken = getDecodedCareflowToken();
    const sOrg: SimpleOrgI = {
      name: dCareflowToken.organization_name,
      fhir_uri: dCareflowToken.organization_fhir_uri,
    };
    return sOrg;
  }

  function checkTenantCodeInMemoryNToken() {
    const dCareflowToken = getDecodedCareflowToken();

    if (!state.currentTenantCode && dCareflowToken.tenant_code) {
      dispatch({
        type: Types.SetTenant,
        payload: {
          currentTenant: dCareflowToken.tenant_code,
        },
      });
    }

    if (
      state.currentTenantCode &&
      dCareflowToken.tenant_code &&
      state.currentTenantCode !== dCareflowToken.tenant_code
    ) {
      window.location.href = SESSION_EXPIRED_URL;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        validateCareflowToken,
        getCurrentUser,
        getCurrentAuthTokenClaims,
        getSelectedOrg,
        checkTenantCodeInMemoryNToken,
      }}
    >
      {!isAclLoading && children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
