import { format } from "date-fns";
import { INoteRowInfo } from "src/@types/note";

export function mapNotes(notes: INoteRowInfo[], code?: string[]) {
  return notes
    .filter(
      (e) => code?.length === 0 || code?.some((r) => r === e.type )
    )
    .map((i) => {
      return ({
        text: i?.text,
        type: i?.type,
        author: i?.author,
        date: i?.timestamp ? format(new Date(i?.timestamp), 'MMM dd, yyyy hh:mm a') : "",
      });
    });
}