import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ALLOWED_ORIGINS_TO_HANDLE_ACTIVITY_IFRAME_MESSAGES,
  MAX_INACTIVITY_SECONDS,
} from 'src/config';

const ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

interface UseActivityListenersPropsI {
  afterMaxInactivityHandler: () => void;
}

function useActivityListeners({ afterMaxInactivityHandler }: UseActivityListenersPropsI) {
  const [enableActivitylisteners, setEnableActivitylisteners] = useState(false);
  const [secondsSinceLastActivity, setSecondsSinceLastActivity] = useState(0);
  const [logWillExpireAt, setLogWillExpireAt] = useState(false);

  const timerRef = useRef<any>();

  const onActivityHandlerCallback = useCallback(() => {
    setSecondsSinceLastActivity(() => 0);
    setLogWillExpireAt(true);
  }, [setSecondsSinceLastActivity, setLogWillExpireAt]);

  function addCounter() {
    setSecondsSinceLastActivity(() => 0);
    timerRef.current = setInterval(() => {
      setSecondsSinceLastActivity((prev) => prev + 1);
    }, 1000);
  }

  function removeCounter() {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }

  function enableListeners() {
    console.debug('RefreshAuthFlow enable activity listeners');
    setSecondsSinceLastActivity(() => 0);
    setEnableActivitylisteners(true);
  }

  function disableListeners() {
    setEnableActivitylisteners(false);
  }

  function addListenersToActivityEvents() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      window.addEventListener(eventName, onActivityHandlerCallback, true);
    });
  }

  function removeListenersToActivityEvents() {
    ACTIVITY_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, onActivityHandlerCallback, true);
    });
  }

  const onMessageHandlerCallback = useCallback(
    (e) => {
      if (!ALLOWED_ORIGINS_TO_HANDLE_ACTIVITY_IFRAME_MESSAGES.includes(e.origin)) {
        return;
      }

      if (!e.data || e.data.topic !== 'iframe-activity') {
        return;
      }

      setSecondsSinceLastActivity(() => 0);
    },
    [setSecondsSinceLastActivity]
  );

  function addMessageListenerForIframes() {
    window.addEventListener('message', onMessageHandlerCallback);
  }

  function removeMessageListenerForIframes() {
    window.removeEventListener('message', onMessageHandlerCallback);
  }

  function removeListeners() {
    removeListenersToActivityEvents();
    removeMessageListenerForIframes();
  }

  useEffect(() => {
    // console.debug('DEBUG useActivityListeners secondsSinceLastActivity', secondsSinceLastActivity);

    if (enableActivitylisteners && secondsSinceLastActivity > MAX_INACTIVITY_SECONDS) {
      disableListeners();
      afterMaxInactivityHandler();
    }

    if (enableActivitylisteners && logWillExpireAt && secondsSinceLastActivity > 30) {
      setLogWillExpireAt(false);
      const secondsLeft = MAX_INACTIVITY_SECONDS - secondsSinceLastActivity;
      const expireAt = moment().add(secondsLeft, 'seconds');
      console.debug('RefreshAuthFlow afterMaxInactivityHandler will be triggered at: ' + expireAt.toLocaleString());
    }
  }, [enableActivitylisteners, secondsSinceLastActivity, logWillExpireAt, setLogWillExpireAt]);

  useEffect(() => {
    if (enableActivitylisteners) {
      addCounter();
    } else {
      removeCounter();
    }

    return removeCounter;
  }, [enableActivitylisteners]);

  useEffect(() => {
    if (enableActivitylisteners) {
      addListenersToActivityEvents();
      addMessageListenerForIframes();
    } else {
      removeListeners();
    }

    return removeListeners;
  }, [enableActivitylisteners, onActivityHandlerCallback, onMessageHandlerCallback]);

  return {
    enable: enableListeners,
    disable: disableListeners,
    secondsSinceLastActivity,
  };
}

export default useActivityListeners;
