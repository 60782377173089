import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, ButtonProps, SxProps } from '@mui/material';

import { spreadSxProp } from 'src/utils/cssStyles';

export interface CustomButtonProps extends ButtonProps {}

export interface CustomLoadingButtonProps extends LoadingButtonProps {}

const commonStyle: SxProps = {
  height: 36,
};

const commonVariant: ButtonProps['variant'] = 'contained';

const CustomButton = ({ sx, ...buttonProps }: CustomButtonProps) => (
  <Button sx={[commonStyle, ...spreadSxProp(sx)]} variant={commonVariant} {...buttonProps} />
);

export const CustomLoadingButton = ({ sx, ...buttonProps }: CustomLoadingButtonProps) => (
  <LoadingButton sx={[commonStyle, ...spreadSxProp(sx)]} variant={commonVariant} {...buttonProps} />
);

export default CustomButton;
