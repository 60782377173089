import { CaseDto } from "src/crs/dto/case/case.dto";
import { IApiClient } from "src/api/clients/api_client";
import { CreateCaseDto } from "src/crs/dto/case/create-case.dto";
import { UpdateCaseDto } from "src/crs/dto/case/update-case-dto";
import { ContactAttemptDto } from 'src/crs/dto/contact-attempt.dto';
import { PaginateQuery, paginateQueryToURLParams } from "src/api/pagination/dtos";
import { convertFilterToPaginateQueryParams } from "src/utils/gridView";
export interface CaseResponse {
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  caseId: string;
  caseUUID: string;
  fhirId: string;
  workflowInstanceId: number;
  workflowStatus: string;
  workflowSubstatus: string;
  workflowStageId: string;
  workflowStageName: string;
  workflowStageOrder: number;
  workflowDispositionReason: string;
  workflowDispositionNote: string;
  workflowDispositionOn: string;
  workflowDispositionAt: string;
  workflowOwnedBy: string;
  workflowScope: string;
  indexedFhirRefUri: string;
  indexedSubjectFhirRefUri: string;
  indexedsubjectMrn: string;
  indexedsubjectFullName: string;
  indexedsubjectPhone: string;
  indexedsubjectEmail: string;
  indexedsubjectDob: string;
  indexedStatus: string;
  internalNumber: string;
  subjectDOBFormatted: string;
  subjectAge: string;
  createdOnFormatted: string;
}

export interface PaginatedCaseResponse {
  data: CaseResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedCaseDto {
  data: CaseDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface CaseContactAttemptResponse {
  caseId: string;
  fhirId: string;
  caseContactAttempId: string;
  caseContactAttempUuid: string;
  caseContactAttempOutcome: string;
  caseContactAttempContactOn: string;
  caseContactAttempNextContactOn: string;
  caseContactAttempNoteId: string;
  noteId: string;
  noteUuid: string;
  noteType: string;
  noteText: string;
}

export class CaseApiClient {
  apiBase: string;

  caseApiClient: IApiClient;

  constructor(caseApiClient: IApiClient) {
    this.apiBase = 'crs/case';
    this.caseApiClient = caseApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedCaseResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);

    let subjectFhirId = '';
    if (queryParams.filter?.indexedSubjectFhirRefUri) {
      subjectFhirId = `&filter.${Object.entries(queryParams.filter || []).map(([key, val]) =>
        `${key}=${val}`)[0]}`;
    }

    let carePlanFhirId = '';
    if (queryParams.filter?.fhirId) {
      carePlanFhirId = `&filter.${Object.entries(queryParams.filter || []).map(([key, val]) =>
        `${key}=$in:${val}`)[0]}`;
    }

    let filterIndexedStatus = '';
    if (queryParams.filter?.indexedStatus) {
      filterIndexedStatus = `&filter.${Object.entries(queryParams.filter || []).map(([key, val]) => `${key}=${val}`)[1]
        }`;
    }
    const filters = convertFilterToPaginateQueryParams(queryParams?.filter ?? {});
    const url = `${this.apiBase}/?${urlParams.toString()}${subjectFhirId}${carePlanFhirId}${filterIndexedStatus}${filters}`;
    try {
      const response = await this.caseApiClient.get<PaginatedCaseResponse | undefined>(url);
      return response;
    } catch (error) {
      console.log(error);
    }

  }


  async getAllContactAttempt(fhirId: string): Promise<CaseContactAttemptResponse[] | undefined> {
    try {
      const response = await this.caseApiClient.get<CaseContactAttemptResponse[] | undefined>
        (`${this.apiBase}/contact-attempt/${fhirId}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getOne(fhirId: string): Promise<CaseResponse | undefined> {
    try {
      const response = await this.caseApiClient.get<CaseResponse | undefined>(
        `${this.apiBase}/${fhirId}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async create(payload: CreateCaseDto): Promise<CaseResponse | undefined> {
    try {
      const response = await this.caseApiClient.post<
        CreateCaseDto,
        CaseResponse | undefined
      >(`${this.apiBase}/`, payload);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async update(fhirId: string, payload: UpdateCaseDto): Promise<CaseResponse | undefined> {
    try {
      const response = await this.caseApiClient.put<
        UpdateCaseDto,
        CaseResponse | undefined
      >(`${this.apiBase}/${fhirId}`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async createContactAttempt(payload: ContactAttemptDto): Promise<any | undefined> {
    try {
      const response = await this.caseApiClient.post<
        ContactAttemptDto,
        any | undefined
      >(`${this.apiBase}/contact-attempt`, payload);
      return response;
    } catch (error) {
      console.error('error on case createContactAttempt', error);
    }
  }
}

export default class CaseService {
  caseApiClient: CaseApiClient;

  constructor(caseApiClient: CaseApiClient) {
    this.caseApiClient = caseApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedCaseDto | undefined> {
    const cases = await this.caseApiClient.getAll(queryParams);
    if (!cases) return undefined;
    const dtos = cases.data.map((r) => {
      const dto = new CaseDto();
      dto.fromCaseResponse(r);
      return dto;
    });
    const mapped: PaginatedCaseDto = { ...cases, data: dtos };
    return mapped;
  }

  async getContactAttempt(fhirId: string): Promise<CaseContactAttemptResponse[] | undefined> {
    const contactAttempts = await this.caseApiClient.getAllContactAttempt(fhirId);
    if (!contactAttempts) return undefined;
    return contactAttempts;
  }

  async create(payload: CreateCaseDto): Promise<CaseDto | undefined> {
    const cases = await this.caseApiClient.create(payload);
    if (!cases) return undefined;
    const dto = new CaseDto();
    dto.fromCaseResponse(cases);
    return dto;
  }

  async update(fhirId: string, payload: UpdateCaseDto): Promise<CaseDto | undefined> {
    const cases = await this.caseApiClient.update(fhirId, payload);
    if (!cases) return undefined;
    const dto = new CaseDto();
    dto.fromCaseResponse(cases);
    return dto;
  }

  async createContactAttempt(payload: ContactAttemptDto): Promise<any | undefined> {

    const contactAttempt = await this.caseApiClient.createContactAttempt(payload);
    if (!contactAttempt) return undefined;
    return contactAttempt;
  }
}

