import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import CloseIconButton from "src/components/CloseIconButton";
import { ProviderDirectorySearchFields } from "src/@types/crs/providerDirectory";
import { Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from "@mui/material";

export interface ManualCreationConfirmDialogProps extends DialogProps {
  provider: ProviderDirectorySearchFields | null;
  onClose: () => void;
  onCreate: () => void;
  isLoading: boolean;
}

const ManualCreationConfirmDialog = ({
  provider,
  onClose,
  onCreate,
  isLoading,
  ...dialogProps
}: ManualCreationConfirmDialogProps) => (
  <Dialog
    {...dialogProps}
    onClose={onClose}
    PaperProps={{
      sx: {
        width: "100%",
      },
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <Container>Confirm Manual Creation</Container>
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ paddingX: 3.4, marginTop: 2 }}>
        Are you sure you want to create practitioner:{" "}
        <span style={{ fontWeight: "bold" }}>
          {provider?.first_name} {provider?.last_name}
        </span>
        <DataSection label="NPI" value={provider?.npi ?? ""} />
        <DataSection label="Address" value={provider?.address ?? ""} />
        <DataSection label="City" value={provider?.city ?? ""} />
        <DataSection
          label="Phone Number"
          value={provider?.phone_number ?? ""}
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        startIcon={<Add />}
        onClick={onCreate}
        variant="contained"
      >
        Create
      </LoadingButton>
      <CloseIconButton onClick={onClose} autoFocus />
    </DialogActions>
  </Dialog>
);

export interface DataSectionProps {
  label: string;
  value: string;
}

const DataSection = ({ label, value }: DataSectionProps) => (
  <>
    {value.trim() && (
      <Typography sx={{ marginTop: 1 }}>
        <span style={{ fontWeight: "bold" }}>{label}:</span> {value.trim()}
      </Typography>
    )}
  </>
);

export default ManualCreationConfirmDialog;