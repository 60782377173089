import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import produce from 'immer';
import NotInsured from './NotInsured';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Commercial, Medicare, StateMedicaid } from '../..';
import { InsuredOptionProps } from 'src/@types/crs/referral';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { WrappedCoverage } from 'src/@nicheaim/fhir-base/wrappers/Coverage';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { useBinarys, useCoverages, useDocumentReferences, useValueSet } from 'src/@nicheaim/fhir-react';

const INSURED_OPTION = [
  { label: 'Insured', value: 'insured' },
  { label: 'Not Insured', value: 'notInsured' },
];

type Props = {
  patient: WrappedPatient | null;
  coverage?: WrappedCoverage | null;
  serviceRequest?: WrappedServiceRequest | null;
  open: boolean;
  onCancel: VoidFunction;
  refreshCoverage?: any;
  workflowReferral?: boolean;
  handleInsuranceInfo?: any;
  handleUpdateResource?: any;
};

export default function InsuranceInfo({
  patient,
  coverage,
  serviceRequest,
  open,
  onCancel,
  refreshCoverage,
  workflowReferral,
  handleInsuranceInfo, 
  handleUpdateResource
}: Props) {

  const [option, setOption] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [, { create: createBinary, }] = useBinarys({ autofetch: false });
  const [, { create: createCoverage, update: updateCoverage }] = useCoverages({ autofetch: false });
  const [documentReference, { create: createDocumentReference, update: updateDocumentReference }] = useDocumentReferences({
    filter: { subject: patient?.id || patient },
  });

  const handleChangeOption = (value: string) => {
    setOption(value);
  };

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);
    return result;
  };

  const handleCoverage = async (data: any) => {
    
    const isEditing = Boolean(coverage?.id); 
    let result: any = {};

    if(!isEditing){
      result = await createCoverage(data);
      if(result) enqueueSnackbar('Coverage was created');
      if(workflowReferral) handleInsuranceInfo();
    }else{

     const dataFromForm = Array.isArray(data) ? data[0] : data;
     
      result = await updateCoverage(
        produce(coverage!, (draft) => {
          draft.subscriberId = dataFromForm.subscriberId;
          draft.period = dataFromForm.period;
          draft.payor = dataFromForm.payor;
          draft.class = dataFromForm.class;
        })
      );

      enqueueSnackbar('Coverage was updated');
    }

    if(coverage === null || coverage && refreshCoverage) refreshCoverage();
    return result;
  };

  const handleCreateDocumentReference = async (data:any) => {
    const result = await createDocumentReference(data);
    return result;
  };

  const handleUpdateDocumentReference = async (data:any) => {
    const result = await updateDocumentReference(data);
    return result;
  };

  useEffect(() => {
    if(open && coverage){
      setOption('insured');
    }
  },[open, coverage]);

  const handleClose = () => {
    setOption('');
    onCancel();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" scroll="body">
      <DialogTitle>Insurance Info</DialogTitle>
      <Card sx={{ m: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <RadioGroup row value={option} onChange={(e, value) => handleChangeOption(value)}>
            {INSURED_OPTION.map((val) => (
              <FormControlLabel
                key={val.value}
                value={val.value }
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="subtitle2">{val.label}</Typography>
                  </Box>
                }
                sx={{ py: 1, flexGrow: 1, mr: 6 }}
              />
            ))}
          </RadioGroup>
        </Stack>
        {option === 'insured' && (
          <InsuredOption
            patient={patient}
            documentReference={documentReference}
            coverage={coverage}
            serviceRequest={serviceRequest}
            handleCreateBynary={handleCreateBynary}
            handleCoverage={handleCoverage}
            handleCreateDocumentReference={handleCreateDocumentReference}
            handleUpdateDocumentReference={handleUpdateDocumentReference}
            handleUpdateResource={handleUpdateResource}
            onCancel={onCancel}
          />
        )}
        {option === 'notInsured' && (
          <NotInsuredOption
            patient={patient}
            documentReference={documentReference}
            coverage={coverage}
            serviceRequest={serviceRequest}
            handleCreateBynary={handleCreateBynary}
            handleCoverage={handleCoverage}
            handleCreateDocumentReference={handleCreateDocumentReference}
            handleUpdateDocumentReference={handleUpdateDocumentReference}
            handleUpdateResource={handleUpdateResource}
            onCancel={onCancel}
          />
        )}
      </Card>
    </Dialog>
  );
}

function InsuredOption({
  patient,
  documentReference,
  coverage,
  serviceRequest,
  handleCreateBynary,
  handleCoverage,
  handleCreateDocumentReference,
  handleUpdateDocumentReference,
  handleUpdateResource,
  onCancel,
}: InsuredOptionProps) {

  const [optionInsured, setOptionInsured] = useState('');

  const handleChangeOptionInsured = (value: string) => {
    setOptionInsured(value);
  };

  const [ insuredOptions ] = useValueSet('ph-insurance-info-insured-options', { map: ValueSetWrapper });
  
  useEffect(() => {
    if(coverage !== null){
      let option = coverage?.class?.[0].name?.replace(' ', '_').toLowerCase().split(' ')[0] || '';
      if(option === 'medicare_part') option = 'medicare' ;

      setOptionInsured(option);
    }
  },[coverage]);

  return (
    <>
      <RadioGroup
        row
        value={optionInsured}
        onChange={(e, value) => handleChangeOptionInsured(value)}
        sx={{ ml: 8 }}
      >
        {insuredOptions?.asList().map((val) => (
          <FormControlLabel
            key={val.code}
            value={val.code}
            control={<Radio />}
            label={
              <Box>
                <Typography variant="subtitle2">{val.display}</Typography>
              </Box>
            }
            sx={{ py: 1, flexGrow: 1 }}
          />
        ))}
      </RadioGroup>

      {optionInsured === 'medicare' && (
        <Medicare
          patient={patient}
          documentReference={documentReference}
          coverage={coverage}
          serviceRequest={serviceRequest}
          handleCreateBynary={handleCreateBynary}
          handleCoverage={handleCoverage}
          handleCreateDocumentReference={handleCreateDocumentReference}
          handleUpdateDocumentReference={handleUpdateDocumentReference}
          handleUpdateResource={handleUpdateResource}
          onCancel={onCancel}
        />
      )}

      {(optionInsured === 'commercial' || optionInsured === 'commercial_medicaid') && (
        <Commercial
          option={optionInsured === 'commercial' ? 'commercial' : 'commercial_medicaid'}
          patient={patient}
          documentReference={documentReference}
          coverage={coverage}
          serviceRequest={serviceRequest}
          handleCreateBynary={handleCreateBynary}
          handleCoverage={handleCoverage}
          handleCreateDocumentReference={handleCreateDocumentReference}
          handleUpdateDocumentReference={handleUpdateDocumentReference}
          handleUpdateResource={handleUpdateResource}
          onCancel={onCancel}
        />
      )}

      {optionInsured === 'state_medicaid' && (
        <StateMedicaid
          patient={patient}
          documentReference={documentReference}
          coverage={coverage}
          serviceRequest={serviceRequest}
          handleCreateBynary={handleCreateBynary}
          handleCoverage={handleCoverage}
          handleCreateDocumentReference={handleCreateDocumentReference}
          handleUpdateDocumentReference={handleUpdateDocumentReference}
          handleUpdateResource={handleUpdateResource}
          onCancel={onCancel}
        />
      )}
    </>
  );
}

function NotInsuredOption({
  patient,
  documentReference,
  serviceRequest,
  handleCreateBynary,
  handleCoverage,
  handleCreateDocumentReference,
  handleUpdateDocumentReference,
  handleUpdateResource,
  onCancel,
}: InsuredOptionProps) {
  return (
    <NotInsured
      patient={patient}
      documentReference={documentReference}
      serviceRequest={serviceRequest}
      handleCreateBynary={handleCreateBynary}
      handleCoverage={handleCoverage}
      handleCreateDocumentReference={handleCreateDocumentReference}
      handleUpdateDocumentReference={handleUpdateDocumentReference}
      handleUpdateResource={handleUpdateResource}
      onCancel={onCancel}
    />
  );
}
