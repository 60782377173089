import client from './_client';

export interface FirebaseCustomTokenResponse {
  accessToken: string;
}

export const getFirebaseCustomToken = async (): Promise<string | null> => {
  try {
    const { data } = await client.get<FirebaseCustomTokenResponse>(`auth/firebase/token`);
    return data?.accessToken;
  } catch (error) {
    console.log('Error retrieving firebase token:', error);
    return null;
  }
};
