import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import { getCareflowToken, getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';
import ReferralService, { ReferralApiClient } from './ReferralService';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';

// TODO: refactor to have less similar axios instances
const baseUrl = getBackEndBaseUrl();

const apiConfig = new ApiConfiguration();
apiConfig.getAccessToken = getCareflowToken;
apiConfig.getHeaderTenantIdCode = getTenantCodeFromCareflowToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const referralApiClient = new ReferralApiClient(new ApiClient(apiConfig));
export const referralService = new ReferralService(referralApiClient);
