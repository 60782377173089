import { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextFieldProps,
} from '@mui/material';
import {
  DateTimePicker as MUIDateTimePicker,
  MonthPicker,
  YearPicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from '@mui/x-date-pickers';
import moment from 'moment';
import { useRenderDefaultInput } from './hooks';

export type DefaultDateType = moment.Moment | string | null;

export type DefaultDateTimePickerProps = Omit<
  MUIDateTimePickerProps<DefaultDateType, DefaultDateType>,
  'renderInput'
>;

export interface CustomMobileDateTextInputProps extends DefaultDateTimePickerProps {
  dialogTitle?: string;
  handleOkButtonPress?: VoidFunction;
  errorMessage?: string;
  textFieldProps?: Partial<TextFieldProps>;
}

export const CustomMobileDateTextInput = ({
  dialogTitle,
  children,
  handleOkButtonPress,
  errorMessage,
  InputProps,
  textFieldProps,
  ...props
}: CustomMobileDateTextInputProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  const renderDefaultInput = useRenderDefaultInput({
    errorMessage,
    textFieldProps,
    onCalendarIconPress: handleOpenDialog,
    inputProps: InputProps,
  });
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <MUIDateTimePicker {...props} renderInput={renderDefaultInput} />
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            autoFocus
            onClick={() => {
              handleOkButtonPress?.();
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface MobileMonthYearPickerProps extends DefaultDateTimePickerProps {
  isYearPicker?: boolean;
  errorMessage?: string;
  textFieldProps?: Partial<TextFieldProps>;
}

export const MobileMonthYearPicker = ({
  value,
  onChange,
  isYearPicker,
  errorMessage,
  textFieldProps,
  ...dateProps
}: MobileMonthYearPickerProps) => {
  const [internalValue, setInternalValue] = useState<DefaultDateType>(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const PickerComponent = !!isYearPicker ? YearPicker : MonthPicker;

  return (
    <CustomMobileDateTextInput
      {...dateProps}
      errorMessage={errorMessage}
      value={value}
      onChange={onChange}
      textFieldProps={textFieldProps}
      handleOkButtonPress={() => {
        onChange(internalValue);
      }}
    >
      <PickerComponent date={internalValue} onChange={(newDate) => setInternalValue(newDate)} />
    </CustomMobileDateTextInput>
  );
};
