// @ts-nocheck
import { useMemo, useEffect, useState } from 'react';
import { DatePicker, TimePicker } from '@mui/lab';
import { Alert, Typography, TextField, Autocomplete, Box, Divider, FormControl, Grid, InputLabel, FormControlLabel, Checkbox, IconButton, MenuItem, Select, Button } from '@mui/material';
import {
  // TODO:
  // useCodeSystem,
  useLocations,
  useMedication,
  useMedicationDispenses,
  useMedicationRequest,
  useMedicationRequests,
  usePractitioners,
} from 'src/@nicheaim/fhir-react';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { PatientCard } from '../../common';
import ActionButtons from '../../common/ActionButtons';
import useObjectState from 'src/hooks/useObjectState';
import moment from 'moment';
import ReactQuill from 'react-quill';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import Iconify from 'src/components/Iconify';
import { periodUnitOptions, periodUnitLabels, defaultState } from './constants';
import { MedicationRequestFormState, MedicationRequestFormProps, PeriodUnitFhirEnum, Option } from './types';
import { getDefaultState, getMedicationAdministrationsBundle } from './utils'
import { MedicationDispense } from 'src/@nicheaim/fhir-base/mappings/MedicationDispense';
import { useMatch } from 'react-router';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { Coding, Dosage } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { useQueryClient } from '@tanstack/react-query';
import fhirSystem from 'src/fhir/system';
import _ from 'lodash';
import { useMedications } from 'src/services/api/medication';

const MedicationRequestForm = ({ medicationRequest: medicationRequestProps, onClose }: MedicationRequestFormProps) => {
  const [{
    rxnumber,
    barcode,
    location,
    medication,
    route,
    reasonForUse,
    startDate,
    startHour,
    endDate,
    endHour,
    requester,
    note,
    selfAdministered,
    allowPRN,
    refills,
    inventory,
    schedule,
  }, updateState] = useObjectState<MedicationRequestFormState>(getDefaultState());
  const [medicationSearchValue, setSearchMedicationValue] = useState<string>('');
  const { data: medications, isFetching: loadingMedications, refetch: refreshMedications }
    = useMedications({ ...(medicationSearchValue ? { search: medicationSearchValue } : {} ), take: 600 });
  const [selectedMedication, { isFetching: loadinMedication }] = useMedication(medication?.value);

  const getOptionLabel = (option: Option) => `${option?.group ?? ''} ${option?.label?.split(',')?.[1]}`;

  const medicationsOptions: Option[] = useMemo(() => medications?.map(med => {
    let unitValue = parseFloat(med.unitValue).toFixed(2);
    const valueSplitted = med.unitValue?.split('.');
    if (parseInt(valueSplitted?.[1]) === 0) {
      unitValue = valueSplitted?.[0];
    }
    return {
      label: `${med.medicationName} | ${med.name}, ${unitValue} ${med.unit}`,
      value: med.medicationId,
      group: `${med.medicationName} (${med.medicationForm})`,
    }
  }) ?? [], [medications]);
  
  const medicationRequestUrlMatch =
    useMatch('/dashboard/patient/:patientId/health-record/medication-request/:medicationRequestId');

  // get the medication request by the url id or the props
  const [medicationRequest, { isLoading: medicationRequestLoading }] = useMedicationRequest(medicationRequestProps?.id ?? medicationRequestUrlMatch?.params?.medicationRequestId);
  const queryClient = useQueryClient();
  const patient = useCurrentPatient();
  const loggedUser = useAuth().getCurrentUser();
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();

  const [locations] = useLocations();

  const [medicationDispenses, { create: createMedicationDispense, update: updateMeddicationDispense }] = useMedicationDispenses({
    filter: {
      prescription: medicationRequest?.id,
      _sort: '-whenPrepared',
    },
  })

  const [, { create: createMedicationRequest, update: updateMedicationRequests }] =
    useMedicationRequests();

  // const [practitioners] = usePractitioners({
  //   filter: { active: true },
  //   map: PractitionerWrapper,
  // });

  const locationsOptions = useMemo(() =>
    locations.map(l => ({
      label: l.name,
      value: l.id,
    })), [locations]);
  
  // const prescribersOptions = useMemo(() =>
  //   practitioners.map(p => ({
  //     label: p.getFullNameWithTitles(),
  //     value: p.id,
  //   })), [practitioners]);
  
  // TODO:
  // const reasonForUseOptions = useMemo(
  //     () =>
  //       reasonForUses?.concept?.map((option) => ({
  //         label: option.display,
  //         value: option.code,
  //       })),
  //     [reasonForUses]
  //   );

  const clearAll = () => {
   updateState(defaultState)
  };

  const validate = () => {
    if (
      !medication || 
      !route || 
      !reasonForUse || 
      !startDate || 
      !startHour || 
      !endDate || 
      !endHour || 
      !requester
    ) return false;

    return true;
  }

  const saveMedicationRequest = async () => {
    setIsLoading(true)
    if (!validate()) {
      setIsLoading(false)
      setError('Please fill all the required fields and try again.');
      return;
    };

    const parsedStartDate = moment(startDate)
    .set({
      hour: +startHour?.split(':')[0],
      minute: +startHour?.split(':')[1],
      seconds: 0,
    })
    .format()

    const parsedEndDate = moment(endDate)
    .set({
      hour: +endHour?.split(':')[0],
      minute: +endHour?.split(':')[1],
      seconds: 0,
    })
    .format()

    const dosages: Dosage[] = schedule.times.map((va) => ({
      route: {
          text: route?.label,
          coding: [
            {
              system: fhirSystem.medication.routeName.asString(),
              code: route?.value,
            },
          ],
      },
      doseAndRate: [
        {
          rateQuantity: {
            value: va.quantity,
          }
        }
      ],
      timing: {
        repeat: {
          periodUnit: schedule.interval_type,
          period: schedule.interval_amount,
          frequency: schedule.frequency,
          timeOfDay: [
            moment(startDate)
            .set({
              hour: +va.time?.split(':')[0],
              minute: +va.time?.split(':')[1],
              seconds: 0,
            }).format('HH:mm:ss')
          ],
        },
      },
      asNeededBoolean: allowPRN,
    })) ?? [];

    const payload: MedicationRequest = {
      authoredOn: moment().toISOString(),
      dosageInstruction: dosages,
      intent: "order",
      medicationReference: {
        display: getOptionLabel(medication),
        reference: `Medication/${medication?.value}`,
      },
      recorder: {
        display: loggedUser.name,
        reference: `Practitioner/${getFhirIdFromReferenceString(loggedUser.user_fhir_uri)}`
      },
      requester: {
        display: requester, //?.label ?? '',
        // reference: `Practitioner/${requester?.value}`,
        type: 'Practitioner',
      },
      ...selfAdministered && patient ? ({
        performer: {
          reference: `Patient/${patient?.id}`,
          display: patient?.getFullName() ?? '',
        }
      }) : ({ performer: null }),
      resourceType: "MedicationRequest",
      status: "active",
      subject: {
        reference: `Patient/${patient?.id}`,
        display: patient?.getFullName() ?? '',
      },
      dispenseRequest: {
        numberOfRepeatsAllowed: refills,
        validityPeriod: {
          start: parsedStartDate,
          end: parsedEndDate
        },
        initialFill: {
          quantity: {
            value: parseFloat(inventory.toString()),
          },
        },
        quantity: {
          value: parseFloat(inventory.toString()),
        },
      },
      note: [
        {
          authorString: loggedUser.name,
          text: note,
          time: moment().toISOString(),
        }
      ],
      identifier: [
        {
          type: {
            coding: [
              {
                code: 'RX',
                display: rxnumber
              }
            ]
          },
          value: rxnumber
        },
        {
          type: {
            coding: [
              {
                code: 'barcode',
                display: barcode
              }
            ]
          },
          value: barcode
        }
      ],
      reasonCode: [
        {
          // TODO
          // coding: [
          //   {
          //     system: 'https://careflow2.nicheaim.com/fhir/CodeSystem/ccm-medication-request-reason-for-use',
          //     code: reasonForUse?.value,
          //   },
          // ],
          text: reasonForUse?.label
        }
      ],
      supportingInformation: [
        {
          display: location?.label,
          reference: `Location/${location?.value}`
        },
      ],
    }

    if (medicationRequest) {
      await updateMedicationRequests({
        id: medicationRequest?.id,
        ...payload
      }).then(async () => {
        const dispense = medicationDispenses?.[0];
        if (dispense) {
          await updateMeddicationDispense({
            ...dispense,
            quantity: {
              value: inventory,
            },
          }).then(() => {
            refreshWidgets();
          })
        } else {
          await createDispense(medicationRequest).then(() => {
            refreshWidgets();
          })
        }
        setIsLoading(false);
        setError(null);
        setSuccess('Medication request saved successfully.');
        setTimeout(() => {
          onClose();
        }, 1000);
      })
    } else {
      await createMedicationRequest(payload)
      .then(async ([savedMedicationRequest]) => {
        const bundle = getMedicationAdministrationsBundle(savedMedicationRequest);
        await axiosFhirInstance.post('', bundle)
        .then(() => console.log('=========== BUNDLE REAPONSE =========='))
        .catch((err) => console.log('========== BUNDLE ERROR =============', { err }))
        
        const response = await createDispense(savedMedicationRequest);

        if (response) {
          refreshWidgets();
          setIsLoading(false);
          setError('Medication saved successfully, but an error ocurred storing the initial dispense. Please create it manually.');
          setSuccess(null);
        }

        setIsLoading(false);
        setError(null);
        setSuccess('Medication request saved successfully.');
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch(() => {
        setIsLoading(false);
        setError('Error saving the medication request. Please try again');
        setSuccess(null);
      })
    }
  };

  const refreshWidgets = () => {
    queryClient.refetchQueries({
      predicate(query) {
        return ['MedicationAdministration', 'MedicationDispense'].includes(
          query.queryKey[0] as string
        );
      },
    });
  }

  const createDispense = async (savedMedicationRequest: MedicationRequest) => {
    const dispensePayload: MedicationDispense = {
      status: 'completed',
      resourceType: "MedicationDispense",
      authorizingPrescription: [
        {
          reference: `MedicationRequest/${savedMedicationRequest?.id}`,
        }
      ],
      quantity: savedMedicationRequest?.dispenseRequest?.initialFill?.quantity,
      medicationReference: savedMedicationRequest?.medicationReference,
      subject: savedMedicationRequest?.subject,
      performer: [{
        actor: savedMedicationRequest?.performer!,
      }],
      location: {
        display: location?.label,
        reference: `Location/${location?.value}`
      },
      note: [
        {
          text: `Medication request #${rxnumber} initial dispense.`,
          time: moment().toISOString(),
          authorString: loggedUser.name,
        }
      ],
      dosageInstruction: savedMedicationRequest?.dosageInstruction,
      whenPrepared: moment().toISOString(),
      whenHandedOver: moment().toISOString(),
    }

    return createMedicationDispense(dispensePayload)
  }

  const onChangeScheduleIntervalAmount = (intervalAmount: number) => {
    const newSchedule = { ...schedule };
    newSchedule.interval_amount = intervalAmount;
    updateState({
      schedule: newSchedule,
    });
  };

  const onChangeScheduleIntervalType = (intervalType: PeriodUnitFhirEnum) => {
    const newSchedule = { ...schedule };
    newSchedule.interval_type = intervalType;
    newSchedule.times = intervalType === PeriodUnitFhirEnum.HOUR ? [newSchedule.times[0]] : newSchedule.times
    updateState({
      schedule: newSchedule,
    })
  };

  const onChangeScheduleTimeStart = (time: string, index: number) => {
    const times = [...schedule.times];
    times[index].time = time;
    const newSchedule = { ...schedule };
    newSchedule.times = times;

    updateState({
      schedule: newSchedule,
    })
  }

  const onChangeScheduleTimeQuantity = (quantity: number, index: number) => {
    const times = [...schedule.times];
    times[index].quantity = quantity;
    const newSchedule = { ...schedule };
    newSchedule.times = times;

    updateState({
      schedule: newSchedule,
    })
  }

  const getMedicationRoutes = (): Coding[] =>
    selectedMedication?.form?.coding?.filter(c => c.system === fhirSystem.medication.routeName.asString()) ?? []

  const onChangeMedicationSearchText = _.debounce((e) => setSearchMedicationValue(e.target.value), 400)

  useEffect(() => {
    if ((medicationSearchValue.length >= 3 || medicationSearchValue.length === 0) && !loadingMedications) {
      refreshMedications()
    }
  }, [medicationSearchValue]);


  // set the default state again when medication request is retrieved by the url or the props
  useEffect(() => {
    if (medicationRequest) updateState(getDefaultState(medicationRequest))
  }, [medicationRequest])

  
  return (
    <Box>
      <Box
        sx={{
          my: 3,
          position: 'fixed',
          width: '550px',
          backgroundColor: 'white',
          opacity: 1,
          zIndex: 9,
          top: '-25px',
        }}
      >
        <Box sx={{ my: 3 }}>
          <Grid item>
            <PatientCard id={patient?.id || ''} showCloseButton={false} />
          </Grid>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Create Medication Request
            </Typography>
          </Grid>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ marginTop: '190px' }}>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginTop: '15px' }}>
          <InputLabel shrink>RX number</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={rxnumber}
                onChange={(e) => updateState({
                  rxnumber: e.target.value
                })}
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Barcode</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={barcode}
                onChange={(e) => updateState({
                  barcode: e.target.value
                })}
                variant="standard"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={locationsOptions}
              value={location}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Location"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
              onChange={(ev, selectedLocation) => {
                updateState({
                  location: selectedLocation,
                });
              }}
            />
          </FormControl>
        </Grid>
        {medicationRequest && (
        <Grid item style={{ marginTop: '25px' }}>
          <FormControl fullWidth>
          <TextField value={medication?.label} disabled label="Medication" variant="standard" />
          </FormControl>
        </Grid>)}
        {!medicationRequest && (
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={medicationsOptions}
              value={medication}
              groupBy={(item) => item?.group}
              getOptionLabel={getOptionLabel}
              loading={loadingMedications}
              loadingText="Loading medications..."
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label={`${medication ? 'Change Medication' : "Medication"}`}
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                  onChange={onChangeMedicationSearchText}
                />
              )}
              onChange={(_, option) => {
                updateState({
                  medication: option,
                  route: null,
                })
              }}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  {' '}{option?.label?.split(' | ')?.[1] ?? ''}
                </MenuItem>
              )}
            />
          </FormControl>
        </Grid>)}
        <Grid item style={{ marginTop: '20px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                options={getMedicationRoutes()?.map((coding) => ({
                  label: coding.display,
                  value: coding?.code
                })) ?? []}
                value={route}
                loading={loadinMedication}
                loadingText="...Loading routes"
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Route"
                    margin="dense"
                    size="small"
                    variant="standard"
                    required
                  />
                )}
                disabled={!selectedMedication}
                onChange={(ev, selectedRoute) => {
                  updateState({
                    route: selectedRoute,
                  });
                }}
              />
            </FormControl>
          </Grid>
        <Grid item style={{ marginTop: '20px' }}>
          <FormControl fullWidth>
            {/* // TODO: */}
            {/* <Autocomplete
              disablePortal
              options={reasonForUseOptions ?? []}
              value={reasonForUse}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Reason for use"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
              onChange={(ev, selectedReasonForUse) => {
                updateState({
                  reasonForUse: selectedReasonForUse
                });
              }}
            /> */}
            <TextField
              value={reasonForUse?.value}
              defaultValue={reasonForUse?.value}
              onChange={(e) => {
                const reasonForUseValue = e.target.value;
                updateState({
                  reasonForUse: {
                    label: reasonForUseValue,
                    value: reasonForUseValue,
                  },
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Reason for use"
              variant="standard"
              required
            />
          </FormControl>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Start date *</InputLabel>
            <FormControl fullWidth>
              <DatePicker
                value={startDate}
                onChange={(date) => updateState({ startDate: moment(date).toDate() })}
                renderInput={(params) => <TextField variant="standard" type="date" {...params} />}
                disabled={medicationRequest?.id ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Start hour *</InputLabel>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <TextField
                value={startHour}
                defaultValue={startHour}
                onChange={(e) => {
                  const selectedStartHour = e.target.value;
                  updateState({
                    startHour: selectedStartHour,
                  });
                }}
                type="time"
                variant="standard"
                required
                disabled={medicationRequest?.id ? true : false}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>End date *</InputLabel>
            <FormControl fullWidth>
              <DatePicker
                value={endDate}
                onChange={(date) => updateState({ endDate: moment(date).toDate() })}
                renderInput={(params) => <TextField variant="standard" type="date" {...params} />}
                disabled={medicationRequest?.id ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>End hour *</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={endHour}
                defaultValue={endHour}
                onChange={(e) => {
                  const selectedEndHour = e.target.value;
                  updateState({
                    endHour: selectedEndHour,
                  });
                }}
                type="time"
                variant="standard"
                required
                disabled={medicationRequest?.id ? true : false}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <TextField
              value={requester}
              variant='standard'
              required
              onChange={(e) => {
                updateState({
                  requester: e.target.value
                });
              }}
              label="Prescriber"
            />
            {/* <Autocomplete
              disablePortal
              options={prescribersOptions}
              value={requester}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Prescriber"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
              onChange={(ev, selectedPrescriber) => {
                updateState({
                  requester: selectedPrescriber!
                });
              }}
            /> */}
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '25px' }}>
          <InputLabel shrink>Note</InputLabel>
          <FormControl fullWidth>
            <ReactQuill
              placeholder="Optional note..."
              theme="snow"
              value={note}
              onChange={(val) => updateState({ note: val })}
            />
          </FormControl>
        </Grid>
        <Grid container style={{ marginTop: '15px' }} justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox checked={selfAdministered} onChange={(ev) => updateState({ selfAdministered: ev.target.checked })} />
              }
              label="Self administered"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox checked={allowPRN} onChange={(ev) => updateState({ allowPRN: ev.target.checked })} />
              }
              label="PRN"
            />
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel shrink>Inventory</InputLabel>
          <FormControl fullWidth>
            <TextField
              value={medicationRequest?.id ? medicationDispenses?.[0]?.quantity?.value :  inventory}
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                if (isNaN(value)) {
                  updateState({
                    inventory: 0,
                  })
                } else {
                  updateState({
                    inventory: value,
                  })
                }
              }}
              type='text'
              variant="standard"
              required
              disabled={medicationRequest?.id ? true : false}
            />
          </FormControl>
        </Grid>
       
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel shrink>Refills</InputLabel>
          <FormControl fullWidth>
            <TextField
              value={refills}
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                if (isNaN(value)) {
                  updateState({
                    refills: 0,
                  })
                } else {
                  updateState({
                    refills: value,
                  })
                }
              }}
              type='text'
              variant="standard"
              required
              disabled={medicationRequest?.id ? true : false}
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <Box sx={{ mb: 2, flexDirection: 'row', justifyItems: 'space-between' }}>
            <Typography variant="h6">
              Schedules configuration
            </Typography>
          </Box>
          <Box
            sx={{
              padding: '35px 15px 15px 15px',
              borderRadius: '10px',
              borderWidth: '1px',
              borderColor: '#e3e3e3',
              marginTop: 2
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <InputLabel shrink>Frequency</InputLabel>
                <TextField
                  onChange={(e) => {
                    onChangeScheduleIntervalAmount(parseInt(e.target.value));
                  }}
                  value="Every"
                  variant="standard"
                  required
                  sx={{
                    width: '50px',
                    textAlign: 'center',
                    top: 13
                  }}
                  disabled={true}
                />
              </Grid>
              <Grid item>
                <InputLabel shrink>Period quantity</InputLabel>
                <TextField
                  onChange={(e) => {
                    onChangeScheduleIntervalAmount(parseInt(e.target.value));
                  }}
                  value={schedule.interval_amount}
                  type="number"
                  variant="standard"
                  required
                  sx={{
                    width: '50px',
                    textAlign: 'center',
                    top: 13
                  }}
                  disabled={medicationRequest?.id ? true : false}
                />
              </Grid>
              <Grid item>
                <InputLabel shrink>Period Unit</InputLabel>
                <Select
                  onChange={(e) => {
                    onChangeScheduleIntervalType(e.target.value as PeriodUnitFhirEnum)
                  }}
                  value={schedule.interval_type}
                  disabled={medicationRequest?.id ? true : false}
                >
                  {periodUnitOptions.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {periodUnitLabels[unit]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {schedule.times.map((value, index) => (
              <Grid key={value.index} container sx={{ marginTop: 2 }} flexDirection="row" justifyContent="space-between">
                <Grid item>
                  <TextField
                    value={value.time}
                    onChange={(e) => onChangeScheduleTimeStart(e.target.value, value.index)}
                    type="time"
                    variant="standard"
                    required
                    disabled={medicationRequest?.id ? true : false}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={value.quantity}
                    onChange={(e) => {
                      let newValue = parseFloat(e.target.value)
                      if (newValue < 0) {
                        newValue = 1;
                      }
                      onChangeScheduleTimeQuantity(newValue, value.index)
                    }}
                    placeholder="Quantity"
                    type="number"
                    variant="standard"
                    required
                    disabled={medicationRequest?.id ? true : false}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      const newSchedule = { ...schedule }
                      newSchedule.times = newSchedule?.times?.filter((s) => s.index !== index)
                      updateState({
                        schedule: newSchedule
                      })
                    }}
                    disabled={((medicationRequest?.id ? true : false) || schedule.times.length === 1)}
                  >
                    <Iconify icon="mdi:trash-can-outline" fontSize={25} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid container sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  onClick={() => {
                    const newSchedule = { ...schedule };
                    schedule.times.push({
                      index: schedule.times.length,
                      time: moment().format('hh:mm'),
                      quantity: 1,
                    });
                    updateState({
                      schedule: newSchedule,
                    })
                  }}
                  disabled={schedule.interval_type === PeriodUnitFhirEnum.HOUR || medicationRequest?.id ? true : false}
                  variant="contained"
                >+ add time</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {schedule.interval_type === PeriodUnitFhirEnum.HOUR &&
        <Grid item>
          <Alert severity='info' color="info">In order to add more time entries change the period unit</Alert>
        </Grid>}
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
        <ActionButtons
          isLoading={medicationRequestLoading || isLoading}
          leftButtonTitle='Clear all'
          onLeftButtonPress={clearAll}
          onRightButtonPress={saveMedicationRequest}
        />
      </Box>
    </Box>
  );
};

export default MedicationRequestForm;
