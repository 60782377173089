import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { PatientResponse } from '../../patient/services/patientService';

dayjs.extend(utc);

export class PatientDto {
  indexedCreatedBy: string;

  indexedCreatedAt: Date;

  indexedUpdatedBy: string;

  indexedUpdatedAt: Date;

  indexedPatienId: string;

  indexedPatientUUID: string;

  indexedFhirRefUri: string;

  indexedGivenName: string;

  indexedFamilyName: string;

  indexedFullName: string;

  indexedGender: string;

  indexedMrn: string;

  indexedPhone: string;

  indexedEmail: string;

  indexedDob: Date;

  indexedRace: string;

  indexedEthnicity: string;

  indexedDOBFormatted: string;

  indexedAge: string;

  indexedCreatedAtFormatted: string;

  indexedCustomLastSil: string;

  indexedCustomIdentifierMedicaidId: string;

  metaLastUpdated: Date;

  indexedStatus:string;

  indexedCustomEpisodeOfCare: object | null;

  fromPatientResponse(dto: PatientResponse) {
    this.indexedCreatedBy = dto.indexedCreatedBy;

    if (dto.indexedCreatedAt) {
      this.indexedCreatedAt = new Date(dto.indexedCreatedAt);
    }

    this.indexedUpdatedBy = dto.indexedUpdatedBy;

    if (dto.indexedUpdatedAt) {
      this.indexedUpdatedAt = new Date(dto.indexedUpdatedAt);
    }

    this.indexedPatienId = dto.indexedPatienId;
    this.indexedPatientUUID = dto.indexedPatientUUID;
    this.indexedFhirRefUri = dto.indexedFhirRefUri;
    this.indexedGivenName = dto.indexedGivenName;
    this.indexedFamilyName = dto.indexedFamilyName;
    this.indexedFullName = dto.indexedFullName;
    this.indexedGender = dto.indexedGender;
    this.indexedMrn = dto.indexedMrn;
    this.indexedPhone = dto.indexedPhone;
    this.indexedEmail = dto.indexedEmail;
    this.indexedRace = dto.indexedRace;
    this.indexedEthnicity = dto.indexedEthnicity;
    this.indexedDOBFormatted = dto.indexedDOBFormatted;
    this.indexedAge = dto.indexedAge;
    this.indexedCreatedAtFormatted = dto.indexedCreatedAtFormatted;
    this.indexedCustomLastSil = dto.indexedCustomLastSil;
    this.indexedCustomIdentifierMedicaidId = dto.indexedCustomIdentifierMedicaidId;

    if (dto.indexedMetaLastUpdatedOnUtc) {
      this.metaLastUpdated = dayjs.utc(dto.indexedMetaLastUpdatedOnUtc).local().toDate();
    }

    if (dto.indexedDob) {
      this.indexedDob = new Date(dto.indexedDob);
    }

    this.indexedStatus = dto.indexedStatus;

    this.indexedCustomEpisodeOfCare = dto.indexedCustomEpisodeOfCare;
  }
}
