import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { Row } from './Row';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';
import useTable from 'src/hooks/useTable';
import { ConditionForm } from './ConditionForm';
import { TableHeadCustom } from 'src/components/table';
import { useAppointments, useConditions } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Appointment } from 'src/@nicheaim/fhir-base/mappings/Appointment';
import { TABLE_HEAD_CONDITION } from 'src/sections/crs/common/table-head';
import {
  AppointmentWrapper,
} from 'src/@nicheaim/fhir-base/wrappers/Appointment';
import { v4 as uuidv4 } from 'uuid';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  patient: WrappedPatient | null;
};

export function ConditionsList({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [add, setOpenAdd] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});
  const [
    appointments,
    { create: createAppointment, update: updateAppointment, refresh: refreshAppointment },
  ] = useAppointments({
    filter: { patient: patient?.id },
    autofetch: !!patient?.id,
    map: AppointmentWrapper,
  });
  const { i18n } = useLocales();

  const [conditions, { create, update }] = useConditions({
    filter: { patient: patient?.id },
    autofetch: !!patient?.id,
  });

  const handleAppointment = async (data: Appointment) => {
    try {
      if (data?.id) {
        await updateAppointment(data);
      } else {
        await createAppointment(data);
      }

      enqueueSnackbar(data?.id ? 'Appointment was updated' : 'Appointment was created');
      refreshAppointment();
    } catch (error) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    }
  };
  const paginatedConditions =
    conditions?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONDITION.ADD] }) && (
                  <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      onClick={() => setOpenAdd(true)}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                    >
                      {i18n('patients.details.conditions.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_CONDITION, 'crs')} />
              <TableBody>
                {paginatedConditions.length ? (
                  paginatedConditions.map((row: any) => (
                    <Row
                      key={uuidv4()}
                      row={row}
                      patient={patient}
                      handleAppointment={handleAppointment}
                    />
                  ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_CONDITION?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={conditions?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <ConditionForm
        patient={patient}
        appointment={null}
        open={add}
        onClose={() => setOpenAdd(false)}
        handleAppointment={handleAppointment}
      />
    </>
  );
}
