import { Stack, Typography } from "@mui/material";
import { TimelineDot, TimelineSeparator } from "@mui/lab";

const Item = ({ text, color, value }: any) => (
  <Stack direction='row' alignItems='center' sx={{ml: 1, my: -0.5 }}>
    <TimelineSeparator>
      <TimelineDot color={color}/>
    </TimelineSeparator> 
    <Typography 
      sx={{ml: 1, color: 'text.secondary'}} 
      variant="body2"
    >
      {text}
    </Typography>
    <Typography 
      sx={{ml: 1, color: 'text.primary'}} 
      variant="body2"
    >
      {value}
    </Typography>
  </Stack>
); 

export default Item;