import { PATH_DASHBOARD } from "src/routes/paths";
import { useTasks } from "src/@nicheaim/fhir-react";
import { taskService } from "src/nat/task/services";
import Viewer from "src/sections/crs/common/Viewer";
import { useEffect, useMemo, useState } from "react";
import { ReportResources } from "src/@types/nat/report";
import { getPrintableTask } from "src/sections/nat/helpers/report";
import { TaskWrapper } from "src/@nicheaim/fhir-base/wrappers/Task";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import useTaskServiceRequestQuestionnaire from "src/hooks/useTaskServiceRequestQuestionnaire";
import { Backdrop, Button, CircularProgress, Divider, Drawer, Grid, List, ListItem, Stack } from "@mui/material";

type Props = {
  patients: WrappedPatient | null;
  isOpen: boolean;
  handlerIsOpen: Function;
  handleReviewHJRequest: () => Promise<any>;
}

export default function ReviewNatReportItem({
  patients,
  isOpen,
  handlerIsOpen,
  handleReviewHJRequest,
}: Props) {

  const [file, setFile] = useState<any>();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [ tasks ] = useTasks({
    filter: {
      patient: patients?.id,
      code: 'completed-nat-assessment',
      status: 'completed'
    },
    autofetch: !!patients,
    map: TaskWrapper
  });

  const { 
    report, 
    patient, 
    planDefinition, 
    serviceRequest,
  } = useTaskServiceRequestQuestionnaire(tasks?.[0]?.id ?? '');

  const getTask: ReportResources | undefined = useMemo (() => 
    report?.find((item) => item?.task?.id === tasks?.[0]?.id)
  , [tasks, report])

  useEffect(() => {
    if(tasks && report && getTask){
      getPrintableReport();
    }
  }, [tasks, report, getTask]);

  const getPrintableReport = async () => {
    try {
      if(getTask){
        setFile('');
        const bodyPrintable = getPrintableTask({
          patient, 
          task: getTask?.task, 
          serviceRequest, 
          planDefinition, 
          questionnaire: getTask?.questionnaire, 
          questionnaireResponse: getTask?.questionnaireResponse, 
          measureReport: getTask?.measureReport, 
          ownerTask: getTask?.ownerTask, 
          requesterTask: getTask?.requesterTask,
        }, false);

        const response =  await taskService.getPrintable(bodyPrintable);

        const blob = new Blob([response?.data], { type: 'application/pdf' });
        setFile(blob);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerStep = async () => {
    setOpenBackdrop(true);

    try {
      await handleReviewHJRequest();
    } catch (error) {}

    handlerIsOpen();  
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "60%" },
      }}
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <List component="nav">
        <ListItem>
          <Grid container>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handlerStep}
              >
                Review HJ Report
              </Button>
              <Button
                onClick={() => {
                  handlerIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!serviceRequest?.id}
                onClick={() => window.open(`${PATH_DASHBOARD.nat.task}/${tasks?.[0]?.id}`, '_blank')}
              >
                Open HJ Report
              </Button>
            </Stack>
          </Grid>
        </ListItem>
      </List>
      <Divider sx={{ marginBottom: 2 }} />
      <Viewer file={file} typeResource='blob' />
    </Drawer>
  );
}
