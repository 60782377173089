import { Communication } from 'src/@nicheaim/fhir-base/mappings/Communication';
import client from '../_client';
import { Catalog } from '../_types';
import {
  CodeableReference,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export type NoteTypesCatalog = Catalog;

export async function listTypes() {
  const { data } = await client.get<NoteTypesCatalog[]>(`/ccm/notes/types`);

  return data;
}

export type Note = {
  id: string;
  date: string;
  text: string;
  source: CodeableReference;
  author: Reference;
};

export async function listNotes(patientId: string) {
  const { data } = await client.get<Note[]>(`/ccm/notes/${patientId}`);

  return data;
}

interface CreateNotePayload {
  type: string;
  note: string;
}

export async function createNote(patientId: string, payload: CreateNotePayload) {
  const { data } = await client.post(`/ccm/notes/${patientId}`, payload);

  return data;
}

export async function updateNote(patientId: string, noteId: string, payload: CreateNotePayload) {
  const { data } = await client.patch(`/ccm/notes/${patientId}/${noteId}`, payload);

  return data;
}

export async function deleteNote() {
  throw new Error('deleteNote is not implemented');
}
