import PermissionDenied from '../pages/dashboard/PermissionDenied';
import { checkAclValidation } from '../utils/permissions/permission.utils';

type AclBasedGuardProps = {
  children: React.ReactNode;
  acls: string[];
};

export function AclBasedGuard({ children, acls }: AclBasedGuardProps) {
  if (checkAclValidation({ acls })) {
    return <>{children}</>;
  }
  return <PermissionDenied />;
}
