export const INTERVIEW_CANCELED_STATUSES = ['rejected', 'cancelled', 'failed', 'entered-in-error'];
export const INTERVIEW_ACTIVE_STATUSES = [
  'draft',
  'requested',
  'received',
  'accepted',
  'ready',
  'in-progress',
  'on-hold',
  'completed',
];
