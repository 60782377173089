import Bed from "src/sections/careflow/rounding/Model/Bed";
import BedItem from "./BedItem";
import {
  TableContainer,
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Card,
  TableBody,
  CircularProgress,
  Box,
  TablePagination,
  IconButton,
} from "@mui/material";
import { BedListTableProps } from "src/sections/careflow/census/components/ListTable/ListTable";
import Iconify from 'src/components/Iconify';
import { checkAclValidation, CCM_ACLS } from "src/utils/permissions/permission.utils";
import useAuth from "src/hooks/useAuth";
import { useContext } from "react";
import { CensusContext } from "src/sections/careflow/census/CensusContext";

const columns = [
  {
    label: 'Identifier'
  },
  {
    label: 'Location'
  },
  {
    label: 'Is active'
  },
  {
    label: 'Last Updated by'
  },
  {
    label: 'Last Updated'
  },
  {
    label: 'Actions'
  }
];

const ListTable = ({ beds, isLoading, pagination, changePage }: BedListTableProps) => {
  const censusContext = useContext(CensusContext);
  const user = useAuth();
  const handleChangePage = (_: any, newPage: number) => {
    changePage(newPage);
  };
  return (
    <>
      <Card sx={{ marginBottom: 2, marginTop: 3 }}>
        <Grid container justifyContent="space-between" sx={{ padding: 3, paddingBottom: 1 }}>
          <Grid item>
            <Typography variant="h5">Beds</Typography>
          </Grid>
          {checkAclValidation({ acls: [CCM_ACLS.CCM_SYSTEM_ADMIN, CCM_ACLS.CCM_ADMIN] }) &&
          <Grid item>
            <IconButton onClick={() => censusContext.openAddBedModal(null)}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
          </Grid>}
        </Grid>
        {isLoading && (
          <Box textAlign={'center'} padding={2}>
            <CircularProgress />
          </Box>
        )}
        <TableContainer sx={{ padding: 2 }}>
          <Table>
            {columns && (
              <TableHead>
                <TableRow>
                  {columns.map((col: any, index: number) => (
                    <TableCell style={{ width: col.width }} key={index}>
                      <Typography>
                        {col.label}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading === false && beds?.map((bed: Bed) => (
                <BedItem
                  key={bed?.id}
                  bed={bed}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
       
        {!isLoading && beds.length === 0 && (
          <TableContainer sx={{ padding: 2 }}>
            <Grid
              item
              justifyItems={'center'}
              alignContent="center"
              alignItems={"center"}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                No beds
              </Typography>
            </Grid>
          </TableContainer>
        )}
        <TablePagination
          component="div"
          count={pagination?.total ?? 0}
          page={pagination?.page ?? 0}
          onPageChange={handleChangePage}
          rowsPerPage={pagination?.take ?? 0}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() =>
            `Showing ${beds?.length ?? 0} Beds | Page ${
              pagination && pagination?.total && pagination?.total > 0 ? pagination?.page + 1 : 0
            } of ${pagination && pagination?.total && Math.ceil(pagination?.total / pagination?.take)}`
          }
          showFirstButton
          showLastButton
        />
      </Card>
    </>
  )
}

export default ListTable;
