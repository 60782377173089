import { useCallback, useState } from 'react';
import {
  Tab,
  Tabs,
  Typography,
  Card,
  IconButton,
} from '@mui/material';
import { EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import { Observation } from 'src/@nicheaim/fhir-base/mappings/Observation';
import VitalsSummaryForm from './VitalsSummaryForm';
import {
  ObservationWrapper,
  WrappedObservation,
} from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { useVitalSigns } from 'src/fhir/hooks/vital-signs';
import AddModal from 'src/sections/careflow/common/AddModal';
import TabItem from 'src/sections/careflow/group-sessions/types/TabItem';
import Iconify from 'src/components/Iconify';
import moment from 'moment';

interface VitalsSummaryProps {
  onExpand?: (index: number) => void;
  isExpanded?: boolean;
  patient: {
    id: string;
  };
}

export const enum vitalTypes {
  vitals = 'vitals',
  heightAndWeight = 'heightAndWeight',
  orthostatic = 'orthostatics',
}

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: vitalTypes.vitals,
    label: 'Vitals',
  },
  {
    key: 1,
    value: vitalTypes.heightAndWeight,
    label: 'Height & Weight',
  },
  {
    key: 2,
    value: vitalTypes.orthostatic,
    label: 'Orthostatic',
  },
];



const VitalsSummary = ({ onExpand, isExpanded,  patient }: VitalsSummaryProps) => {
  const [selectedVital, setSelectedVital] = useState<any>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [showVitalFormModal, setShowVitalFormModal] = useState<boolean>(false);
  const [_observations, { remove, refresh }] = useVitalSigns({
    filter: {
      subject: `Patient/${patient?.id}`,
      code: TAB_VALUES.find(i => i.key === selectedTabIndex)?.value,
    },
    map: ObservationWrapper,
  });
  const observations = _observations as WrappedObservation[];

  const handleAdd = () => {
    setShowVitalFormModal(true);
    setSelectedVital(null);
  };
  const handleEdit = (data: Observation) => {
    setSelectedVital(data);
    setShowVitalFormModal(true);
  };

  const handleDelete = useCallback(
    (row: any) => {
      remove(row);
    },
    [remove]
  );

  const closeVitalFormModal = () => {
    setSelectedVital(null);
    setShowVitalFormModal(false);
  }

  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key as number);
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [selectedTabIndex]
  );

  const getColumns = () => {
    const currentSelectedTabValue = TAB_VALUES.find(i => i.key === selectedTabIndex)?.value;

    if (currentSelectedTabValue === vitalTypes.vitals) {
      return [
        {
          header: 'Date And Time',
          field: '$',
          render(row: any) {
            return <Typography variant="body2">{moment(row.effectiveDateTime).format('DD MMM, YYYY / hh:mm a')}</Typography>
          }
        },
        {
          header: 'Blood Plessure',
          field: '$',
          render(row: any) {
            return `${row.getVitalsValue('8480-6')} / ${row.getVitalsValue('8462-4')}`
          }
        },
        {
          header: 'Temperature',
          field: '$',
          render(row: any) {
            return row.getVitalsValueWithUnits('8310-5')
          }
        },
        {
          header: 'Pulse',
          field: '$',
          visible: isExpanded,
          render(row: any) {
            return row.getVitalsValue('8867-4')
          }
        },
        {
          header: 'Respirations',
          field: '$',
          visible: isExpanded,
          render(row: any) {
            return row.getVitalsValue('9279-1')
          }
        },
        {
          header: '02 Sat.',
          field: '$',
          visible: isExpanded,
          render(row: any) {
            return row.getVitalsValue('2708-6')
          }
        },
      ]
    }

    if (currentSelectedTabValue === vitalTypes.heightAndWeight) {
      return [
        {
          header: 'Date And Time',
          field: '$',
          render(row: any) {
            return moment(row.effectiveDateTime).format('DD MMM, YYYY / hh:mm a')
          }
        },
        {
          header: 'Height',
          field: '$',
          render(row: any) {
            return row.getVitalsValueWithUnits('8302-2')
          }
        },
        {
          header: 'Weight',
          field: '$',
          render(row: any) {
            return row.getVitalsValueWithUnits('29463-7')
          }
        },
        {
          header: 'BMI',
          field: '$',
          visible: isExpanded,
          render(row: any) {
            return row.getVitalsValue('39156-5')
          }
        }
      ]
    }

    return [
      {
        header: 'Date And Time',
        field: '$',
        render(row: any) {
          return moment(row.effectiveDateTime).format('DD MMM, YYYY / hh:mm a')
        }
      },
      {
        header: 'Lying Blood Plessure',
        field: '$',
        render(row: any) {
          return `${row.getVitalsValue('8461-6')} / ${row.getVitalsValue(
            '8455-8'
          )}`
        }
      },
      {
        header: 'Sitting Blood Plessure',
        field: '$',
        visible: isExpanded,
        render(row: any) {
          return `${row.getVitalsValue('8459-0')} / ${row.getVitalsValue(
            '8453-3'
          )}`
        }
      },
      {
        header: 'Standing Blood Plessure',
        field: '$',
        visible: isExpanded,
        render(row: any) {
          return `${row.getVitalsValue('8460-8')} / ${row.getVitalsValue(
            '8454-1'
          )}`
        }
      },
      {
        header: 'Temperature',
        field: '$',
        visible: isExpanded,
        render(row: any) {
          return row.getVitalsValueWithUnits('8310-5')
        }
      },
      {
        header: 'Respirations',
        field: '$',
        visible: isExpanded,
        render(row: any) {
          return row.getVitalsValue('9279-1')
        }
      },
      {
        header: '20 Sat.',
        field: '$',
        visible: isExpanded,
        render(row: any) {
          return row.getVitalsValue('2708-6')
        }
      }
    ]
  }

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <AddModal
        open={showVitalFormModal}
        Component={
          <VitalsSummaryForm
            patient={patient}
            onSuccessCallback={() => refresh()}
            data={selectedVital}
            onClose={closeVitalFormModal} />
        }
        onClose={closeVitalFormModal}
      />
      {renderTabs()}
      <SummaryTable
        title={`${TAB_VALUES.find(i => i.key === selectedTabIndex)?.label} Summary`}
        data={observations}
        columns={getColumns()}
        actionBar={
          <>
            <IconButton
              onClick={handleAdd}
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand?.(4)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
        rowContextMenu={(entity) => (
            <EntityContextMenu
              items={[
                {
                  label: 'Edit',
                  icon: 'eva:edit-2-outline',
                  onPress: (data) => handleEdit?.(data),
                },
                {
                  label: 'Delete',
                  icon: 'bi:trash',
                  onPress: (data) => handleDelete?.(data),
                }
              ]}
              entity={entity}
            />
        )}
      />
    </Card>
  );
};

export default VitalsSummary;
