import { ApiConfiguration } from "src/api/clients/api_client";
import ApiClient from "src/api/clients/axios_client";
import { getCareflowToken, getTenantCodeFromCareflowToken } from "src/utils/auth-utils";
import CaseService, { CaseApiClient } from "./CaseService";
import { getBackEndBaseUrl } from "src/utils/domain-utils";

// TODO: refactor to have less similar axios instances
const baseUrl = getBackEndBaseUrl();

const apiConfig = new ApiConfiguration();
apiConfig.getAccessToken = getCareflowToken;
apiConfig.getHeaderTenantIdCode = getTenantCodeFromCareflowToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const caseApiClient = new CaseApiClient(new ApiClient(apiConfig));
export const caseService = new CaseService(caseApiClient);