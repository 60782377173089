import { Button, Container, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import Page from "src/components/Page";
import useLocales from "src/hooks/useLocales";
import { getBackEndBaseUrl } from "src/utils/domain-utils";

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Unauthorized({ show }: { show: boolean }) {
  const { i18n } = useLocales();

  const refreshToken = Cookies.get('refreshToken');
  return (
    <Page title="Unauthorized" hidden={!show}>
      <Container>
        <ContentStyle sx={{ alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            You do not have access. Please, contact your administrator
          </Typography>

          <form action={`${getBackEndBaseUrl()}/auth/logout-handler`} method="post">
            <input type="hidden" name="refresh_token" value={refreshToken} />
            <Button type="submit" variant="contained" size="large">
              {i18n('logout')}
            </Button>
          </form>
        </ContentStyle>
      </Container>
    </Page>
  );
}