import _, { isString } from 'lodash';

export const UI_SCHEMA = {
  VALIDATE_HOME_ADDRESS_REFERRAL_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Home Address Valid',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Home Address Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              city: {
                type: 'string',
                title: 'City',
                deleteOnEmpty: true,
              },
              country: {
                type: 'string',
                title: 'Country',
                deleteOnEmpty: true,
              },
              line_0: {
                type: 'string',
                title: 'Street 1',
                deleteOnEmpty: true,
              },
              line_1: {
                type: 'string',
                title: 'Street 2',
                deleteOnEmpty: true,
              },
              period: {
                type: 'object',
                properties: {
                  start: {
                    type: 'string',
                    format: 'string',
                    title: 'Start',
                    deleteOnEmpty: true,
                  },
                  end: {
                    type: 'string',
                    format: 'string',
                    title: 'End',
                    deleteOnEmpty: true,
                  },
                },
              },
              postalCode: {
                type: 'string',
                title: 'Postal Code',
                deleteOnEmpty: true,
              },
              state: {
                type: 'string',
                title: 'State',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  },
  VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Address In County',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Address in County Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              isInCounty: {
                type: 'boolean',
                title: 'Is In County?',
                deleteOnEmpty: true,
              },
              Notes: {
                type: 'string',
                title: 'Notes',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  },
  CONSENT_FORM_REFERRAL_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Consents Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Consents Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONTACT_CLIENT_REFERRAL_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Contact Client',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Contact Client Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              contactOn: {
                type: 'string',
                format: 'string',
                title: 'Contact On',
                deleteOnEmpty: true,
              },
              nextContactOn: {
                type: 'string',
                format: 'string',
                title: 'Next Contact On',
                deleteOnEmpty: true,
              },
              outcome: {
                type: 'string',
                widget: 'Select',
                title: 'Outcome',
                deleteOnEmpty: true,
                enum: [
                  'Intake Visit Scheduled',
                  'Interested - requires follow-up',
                  'No answer - voicemail left',
                  'No answer - no voicemail left',
                  'Opt out - specify reason',
                  'Prompt to Call Letter',
                  'Wrong number',
                  'Other - specify in notes',
                ],
              },
              reasonNote: {
                type: 'string',
                title: 'Reason Note',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  },
  CONTACT_PCP_REFERRAL_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Contact PCP',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Contact PCP Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              contactOn: {
                type: 'string',
                format: 'string',
                title: 'Contact On',
                deleteOnEmpty: true,
              },
              nextContactOn: {
                type: 'string',
                format: 'string',
                title: 'Next Contact On',
                deleteOnEmpty: true,
              },
              outcome: {
                type: 'string',
                widget: 'Select',
                title: 'Outcome',
                deleteOnEmpty: true,
                enum: [
                  'Intake Visit Scheduled',
                  'Interested - requires follow-up',
                  'No answer - voicemail left',
                  'No answer - no voicemail left',
                  'Opt out - specify reason',
                  'Prompt to Call Letter',
                  'Wrong number',
                  'Other - specify in notes',
                ],
              },
              reasonNote: {
                type: 'string',
                title: 'Reason Note',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  },
  CONTACT_ATTEMPT_CASE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Case Contact Attempt',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Contact Attempts Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              contactOn: {
                type: 'string',
                format: 'string',
                title: 'Contact On',
                deleteOnEmpty: true,
              },
              nextContactOn: {
                type: 'string',
                format: 'string',
                title: 'Next Contact On',
                deleteOnEmpty: true,
              },
              outcome: {
                type: 'string',
                widget: 'Select',
                title: 'Outcome',
                deleteOnEmpty: true,
                enum: [
                  'Intake Visit Scheduled',
                  'Interested - requires follow-up',
                  'No answer - voicemail left',
                  'No answer - no voicemail left',
                  'Opt out - specify reason',
                  'Prompt to Call Letter',
                  'Wrong number',
                  'Other - specify in notes',
                ],
              },
              reasonNote: {
                type: 'string',
                title: 'Reason Note',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  },
  CONFIRM_MEMBER_INFO_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm Patient Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm Patient Validated By',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_GUARANTOR_INFO_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm Parent/Caregiver Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm Parent/Caregiver Validated By',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_NAT_OPTIN_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm HJ Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm HJ Validated By',
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              opt_out_decision: {
                type: 'string',
                title: 'Opt-Out Decision',
                readOnly: true,
              },
              method: {
                type: 'string',
                title: 'Method',
                readOnly: true,
              },
            },
          },
        },
      },
    ],
  },
  SCHEDULE_NAT_SESSION_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Schedule HJ Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Schedule HJ Validated By',
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              date_hour: {
                type: 'string',
                format: 'string',
                title: 'Schedule HJ Date and Hour',
                deleteOnEmpty: true,
              },
              location: {
                type: 'string',
                widget: 'Select',
                title: 'Schedule HJ Location',
                deleteOnEmpty: true,
                enum: ['In Home Visit', 'In Office Visit', 'Community Visit'],
              },
            },
          },
        },
      },
    ],
  },
  INITIATE_NAT_REQUEST_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm Patient Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm Patient Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
          orderId: {
            type: 'string',
            title: 'Order ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  REVIEW_NAT_REPORT_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Review HJ Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Review HJ Validated By',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_CM_OPTIN_INITIAL_PATIENT_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm CM Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm CM Validated By',
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              opt_out_decision: {
                type: 'string',
                title: 'Opt-Out Decision',
                deleteOnEmpty: true,
                readOnly: true,
              },
              type: {
                type: 'string',
                deleteOnEmpty: true,
                readOnly: true,
              },
              notes: {
                type: 'string',
                deleteOnEmpty: true,
                readOnly: true,
              },
            },
          },
        },
      },
    ],
  },
  REFERRAL_INFORMATION_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Referral Information Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Referral Information Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_MEMBER_INFO_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm Patient Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm Patient Validated By',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_GUARANTOR_INFO_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Confirm Parent/Caregiver Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Confirm Parent/Caregiver Validated By',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONSENT_INFORMATION_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Consents Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Consents Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  PROVIDER_INFORMATION_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Provider Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Provider Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  SCHEDULE_NAT_SESSION_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Schedule HJ Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Schedule HJ Validated By',
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              date_hour: {
                type: 'string',
                format: 'string',
                title: 'Schedule HJ Date and Hour',
                deleteOnEmpty: true,
              },
              location: {
                type: 'string',
                widget: 'Select',
                title: 'Schedule HJ Location',
                deleteOnEmpty: true,
                enum: ['Phone Interview', 'In-person Interview', 'Virtual Interview'],
              },
            },
          },
        },
      },
    ],
  },
  SCREENING_INTERVIEW_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Screening Interview Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Screening Interview Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Screening Responses Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Screening Responses Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Consents Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Consents Validated By',
            readOnly: true,
          },
          patientId: {
            type: 'string',
            title: 'Patient ID',
            readOnly: true,
          },
        },
      },
    ],
  },
  SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Schedule HJ Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          validatedBy: {
            type: 'string',
            format: 'email',
            title: 'Schedule HJ Validated By',
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              date_hour: {
                type: 'string',
                format: 'string',
                title: 'Schedule HJ Date and Hour',
                deleteOnEmpty: true,
              },
              location: {
                type: 'string',
                widget: 'Select',
                title: 'Schedule HJ Location',
                deleteOnEmpty: true,
                enum: ['Phone Interview', 'In-person Interview', 'Virtual Interview'],
              },
            },
          },
        },
      },
    ],
  },
  CONTACT_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
    allOf: [
      {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            widget: 'LabelBox',
            title: 'Case Contact Attempt',
            readOnly: true,
          },
          validatedOn: {
            type: 'string',
            format: 'string',
            title: 'Contact Attempts Validated On',
            view: {
              sizeMd: 6,
              shrink: true,
            },
            readOnly: true,
          },
          modifiedFields: {
            type: 'object',
            properties: {
              contactOn: {
                type: 'string',
                format: 'string',
                title: 'Contact On',
                deleteOnEmpty: true,
              },
              nextContactOn: {
                type: 'string',
                format: 'string',
                title: 'Next Contact On',
                deleteOnEmpty: true,
              },
              outcome: {
                type: 'string',
                widget: 'Select',
                title: 'Outcome',
                deleteOnEmpty: true,
                enum: [
                  'Intake Visit Scheduled',
                  'Interested - requires follow-up',
                  'No answer - voicemail left',
                  'No answer - no voicemail left',
                  'Opt out - specify reason',
                  'Prompt to Call Letter',
                  'Wrong number',
                  'Other - specify in notes',
                ],
              },
              reasonNote: {
                type: 'string',
                title: 'Reason Note',
                deleteOnEmpty: true,
              },
            },
          },
        },
      },
    ],
  }
};

export const getParseColumn = (obj: any, field: any) =>
  (obj &&
    Object.keys(obj).length > 0 &&
    obj[field] &&
    typeof obj[field] === 'string' &&
    JSON.parse(obj[field])) ||
  {};

export const isNotAllowed = [
  'extension', 
  'id', 
  'type', 
  'typeNote', 
  'address', 
  'providers', 
  'referral',
  'createdOn',
  'createdBy',
  'role',
  'fhirReference',
  'assignedDate',
  'assignedBy',
  'source'
];

export const isDate = (value: string) => {
  const arrayPropertyDate = [
    'start',
    'end',
    'validatedOn',
    'nextContactOn',
    'contactOn',
    'date_hour',
  ];

  return arrayPropertyDate.includes(value);
};

export const getComplementaryInfoName = (key: any, i18nFunc: any) => {
  let complementaryKeyName = '';

  switch (key) {
    case 'VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM':
      complementaryKeyName = 'Address is in County';
      break;
    case 'VALIDATE_HOME_ADDRESS_REFERRAL_ITEM':
      complementaryKeyName = 'Fix Invalid Address';
      break;
    case 'validatedOn':
      complementaryKeyName = 'Validated On';
      break;
    case 'validatedBy':
      complementaryKeyName = 'Validated By';
      break;
    case 'Notes':
      complementaryKeyName = 'Notes';
      break;
    case 'city':
      complementaryKeyName = 'City';
      break;
    case 'district':
      complementaryKeyName = 'County';
      break;
    case 'use':
      complementaryKeyName = 'Use';
      break;
    case 'line_0':
      complementaryKeyName = 'Street 1';
      break;
    case 'line_1':
      complementaryKeyName = 'Street 2';
      break;
    case 'state':
      complementaryKeyName = 'State';
      break;
    case 'country':
      complementaryKeyName = 'Country';
      break;
    case 'postalCode':
      complementaryKeyName = 'Postal Code';
      break;
    case 'isInCounty':
      complementaryKeyName = 'Is In County';
      break;
    case 'start':
      complementaryKeyName = 'Start';
      break;
    case 'end':
      complementaryKeyName = 'End';
      break;
    case 'CONTACT_CLIENT_REFERRAL_ITEM':
      complementaryKeyName = 'Contact Client';
      break;
    case 'CONTACT_PCP_REFERRAL_ITEM':
      complementaryKeyName = 'Contact PCP';
      break;
    case 'CONTACT_ATTEMPT_CASE_ITEM':
      complementaryKeyName = 'Case Contact Attempt';
      break;
    case 'contactOn':
      complementaryKeyName = 'Contact On';
      break;
    case 'nextContactOn':
      complementaryKeyName = 'Next Contact On';
      break;
    case 'reasonNote':
      complementaryKeyName = 'Reason Note';
      break;
    case 'outcome':
      complementaryKeyName = 'Outcome';
      break;
    case 'CONFIRM_MEMBER_INFO_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Confirm Patient';
      break;
    case 'CONFIRM_NAT_OPTIN_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Confirm HJ OptOut';
      break;
    case 'opt_out_decision':
      complementaryKeyName = 'Opt-Out Decision';
      break;
    case 'method':
      complementaryKeyName = 'Method';
      break;
    case 'CONFIRM_MEMBER_INFO_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Confirm Parent/Caregiver';
      break;
    case 'SCHEDULE_NAT_SESSION_INITIAL_PATIENT_ITEM':
      complementaryKeyName = i18nFunc(
        'patientEngagement.details.complementaryInfo.scheduleAppointment.title',
        'engagement'
      );
      break;
    case 'date_hour':
      complementaryKeyName = i18nFunc(
        'patientEngagement.details.complementaryInfo.scheduleAppointment.date',
        'engagement'
      );
      break;
    case 'location':
      complementaryKeyName = i18nFunc(
        'patientEngagement.details.complementaryInfo.scheduleAppointment.location',
        'engagement'
      );
      break;
    case 'INITIATE_NAT_REQUEST_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Initiate HJ Request';
      break;
    case 'patientId':
      complementaryKeyName = 'Patient ID';
      break;
    case 'orderId':
      complementaryKeyName = 'Order ID';
      break;
    case 'REVIEW_NAT_REPORT_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Review HJ Report';
      break;
    case 'CONFIRM_CM_OPTIN_INITIAL_PATIENT_ITEM':
      complementaryKeyName = 'Confirm CM OptOut';
      break;
    case 'type':
      complementaryKeyName = 'Type';
      break;
    case 'notes':
      complementaryKeyName = 'Notes';
      break;
    case 'REFERRAL_INFORMATION_INTAKE_ITEM':
      complementaryKeyName = 'Referral Information';
      break;
    case 'CONFIRM_MEMBER_INFO_INTAKE_ITEM':
      complementaryKeyName = 'Confirm Member';
      break;
    case 'CONFIRM_GUARANTOR_INFO_INTAKE_ITEM':
      complementaryKeyName = 'Confirm Parent/Caregiver';
      break;
    case 'CONSENT_INFORMATION_INTAKE_ITEM':
      complementaryKeyName = 'Consents';
      break;
    case 'PROVIDER_INFORMATION_INTAKE_ITEM':
      complementaryKeyName = 'Provider';
      break;
    case 'SCHEDULE_NAT_SESSION_INTAKE_ITEM':
      complementaryKeyName = i18nFunc(
        'patientEngagement.details.complementaryInfo.scheduleAppointment.title',
        'engagement'
      );
      break;
    case 'SCREENING_INTERVIEW_INTAKE_ITEM':
      complementaryKeyName = 'Screening Interview';
      break;
    case 'CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM':
      complementaryKeyName = 'Screening Responses';
      break;
    case 'CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM':
      complementaryKeyName = 'Consents';
      break;
    case 'SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM':
      complementaryKeyName = i18nFunc(
        'patientEngagement.details.complementaryInfo.scheduleAppointment.title',
        'engagement'
      );
      break;
    default:
      complementaryKeyName = 'Not Found Key';
  }

  return complementaryKeyName;
};

export const mapDataAndDataSchemaWorkflowInstance = (
  workflowInstance: any,
  userEmail: string,
  modifiedFields: any,
  additionalParams: any,
  checklistItemCode: string
) => {
  const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

  const workflowInstanceDataSchema =
    workflowInstance &&
    workflowInstance.dataSchema &&
    typeof workflowInstance.dataSchema === 'string'
      ? JSON.parse(workflowInstance.dataSchema)
      : workflowInstance && !workflowInstance?.dataSchema
      ? {}
      : workflowInstance?.dataSchema;

  const payloadData =
    workflowInstanceData && Object.keys(workflowInstanceData).length > 0
      ? _.merge(workflowInstanceData, {
          scope: {
            checklistItem: {
              ...workflowInstanceData.checklistItem,
              [checklistItemCode]: {
                validatedOn: new Date().toISOString(),
                validatedBy: userEmail || '',
                modifiedFields,
                ...(additionalParams && additionalParams),
              },
            },
          },
        })
      : {
          scope: {
            checklistItem: {
              [checklistItemCode]: {
                validatedOn: new Date().toISOString(),
                validatedBy: userEmail || '',
                modifiedFields,
                ...(additionalParams && additionalParams),
              },
            },
          },
        };

  const UI_SCHEMA_ITEM = UI_SCHEMA[checklistItemCode];

  const payloadDataSchema =
    workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
      ? _.merge(workflowInstanceDataSchema, {
          type: 'object',
          properties: {
            [checklistItemCode]: UI_SCHEMA_ITEM,
          },
        })
      : {
          type: 'object',
          properties: {
            [checklistItemCode]: UI_SCHEMA_ITEM,
          },
        };

  return {
    data: JSON.stringify(payloadData),
    dataSchema: JSON.stringify(payloadDataSchema),
  };
};

export const getResizedText = (originalText: string, characterLimit: number) => {
  if (originalText && isString(originalText) && originalText.length > characterLimit) {
    return originalText.slice(0, characterLimit) + '...';
  }
  return originalText;
};

export const scrollbarStyle = {
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '0.3em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};
