import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const PlanDefinitionWrapper = createWrapper('PlanDefinition', (planDefinition) => ({
  getGoalDescriptionCoding: () => planDefinition?.goal?.[0]?.description?.coding?.[0],
  getGoalDescription: () =>
    planDefinition?.goal?.[0]?.description?.text ??
    planDefinition?.goal?.[0]?.description?.coding?.[0]?.display,
  getGoalCategory: () =>
    planDefinition?.goal?.[0]?.category?.coding?.[0]?.code ??
    planDefinition?.useContext?.[0]?.code?.code ??
    planDefinition?.useContext?.[0]?.valueCodeableConcept?.coding?.[0]?.code,
  getGoalCategoryCoding: () => planDefinition?.goal?.[0]?.category?.coding?.[0],
  getGoalLifecycleStatus: () =>
    planDefinition?.meta?.tag?.find?.(({ code }) => code === 'ph-goal-lifecycleStatus')?.display,
  getGoalAchievementStatus: (): Coding => {
    const status = planDefinition?.meta?.tag
      ?.find?.(({ code }) => code === 'ph-goal-achievementStatus')
      ?.display?.split?.('|');
    return {
      system: status?.[0],
      code: status?.[1],
      display: status?.[2],
    };
  },
  getGoalDuration: () => planDefinition?.goal?.[0]?.target?.[0]?.due,
}));

export type WrappedPlanDefinition = ReturnType<typeof PlanDefinitionWrapper>;
