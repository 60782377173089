import ArticleIcon from '@mui/icons-material/Article';
import { MenuItem, TableCell, TableRow } from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { TableMoreMenu } from 'src/components/table';
import { PrintableCarePlan } from '../details/PrintableCarePlan';
import { PatientGenericCarePlanDto } from './patientGenericCarePlan.dto';

type Props = {
  row: PatientGenericCarePlanDto;
};

export function PatientGenericCaseRow({ row }: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.created && row?.displayCreated()}</TableCell>
        <TableCell>{row?.status && capitalCase(row?.status)}</TableCell>
        <TableCell>{row?.intent && capitalCase(row?.intent)}</TableCell>
        <TableCell>{row.hasCategories() && row.categoriesAsDisplayList()?.join(' | ')}</TableCell>
        <TableCell>{row.activityCount()}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            width={280}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={[
              <PrintableCarePlan
                key={row?.id}
                carePlanId={row?.id}
                renderButton={(onClick) => {
                  return (
                    <MenuItem onClick={onClick}>
                      <ArticleIcon />
                      Generate Printable CarePlan
                    </MenuItem>
                  );
                }}
              />,
            ]}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
