import { Breakpoint, Dialog, Grid, Stack } from '@mui/material';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface IStandardDialogContextData {
  setElement: (element: JSX.Element | null, maxWidth?: Breakpoint) => void;
}

const initialValue: IStandardDialogContextData = { setElement: () => {} };

const StandardDialogContext = createContext<IStandardDialogContextData>(initialValue);

export function useStandardDialog() {
  const { setElement } = useContext<IStandardDialogContextData>(StandardDialogContext);

  function closeStandardDialog() {
    setElement(null);
  }

  return { open: setElement, close: closeStandardDialog };
}

export interface IStandardDialogData {
  isOpen: boolean;
  childrenDialogElement?: JSX.Element;
}

interface IStandardDialogProviderProps {
  children: ReactNode;
  meta?: ReactNode;
}

export default function StandardDialogProvider({ children, meta }: IStandardDialogProviderProps) {
  const [childrenDialogElement, setChildrenDialogElement] = useState<JSX.Element | null>(null);
  const [maxWidthDialog, setMaxWidthDialog] = useState<Breakpoint | undefined>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (childrenDialogElement === null) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  }, [childrenDialogElement]);

  function _setChildrenDialogElement(element: JSX.Element | null, maxWidth?: Breakpoint) {
    setMaxWidthDialog(maxWidth);
    setChildrenDialogElement(element);
  }

  return (
    <StandardDialogContext.Provider value={{ setElement: _setChildrenDialogElement }}>
      {children}
      <Dialog open={openDialog} fullWidth maxWidth={maxWidthDialog || 'md'}>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}></Stack>
          </Grid>
        </Grid>

        {childrenDialogElement && childrenDialogElement}
      </Dialog>
    </StandardDialogContext.Provider>
  );
}
