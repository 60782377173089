import moment from 'moment';
import { COLLECTION_PATH_SEPARATOR, defaultCollectionsPath } from './constants';

import { PATH_DASHBOARD } from 'src/routes/paths';

export const getFirebaseCollectioPath = (collectionPath: string) =>
  [...defaultCollectionsPath, collectionPath].join(COLLECTION_PATH_SEPARATOR);

export const getUserDetailPath = (id: string) => `${PATH_DASHBOARD.yoCare.userDetails}/${id}`;

export const getIdFromURI = (reference: string): string | null => {
  if (!reference) return null;
  const uriParts = reference.split('/');
  if (!uriParts.length) return null;

  return uriParts[uriParts.length - 1];
};

export const calculateAge = (birthDate: moment.Moment): number => {
  var today = moment();
  var age = today.diff(birthDate, 'years');
  var ageAdjustment = today.subtract(age, 'years').isBefore(birthDate) ? -1 : 0;
  return age + ageAdjustment;
};
