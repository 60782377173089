import moment from 'moment';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import {
  CarePlanActivity,
  Coding,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getIdFromReference } from '../../../../../utils/fhir';

export class PatientGenericCarePlanDto {
  id?: string;

  status?: string;

  intent?: string;

  categories: Coding[];

  activities: CarePlanActivity[];

  created?: string;

  activityCount() {
    return this.activities.length;
  }

  categoriesAsDisplayList() {
    return this.categories.map((x) => x?.display);
  }

  hasCategories() {
    return this.categories.length > 0;
  }

  displayCreated() {
    if (this.created) {
      return moment(this.created)?.format('MMM DD, yyyy hh:mm');
    }
    return '';
  }
}

export const wrappedCarePlanToPatientGenericCarePlanDto = (carePlan: WrappedCarePlan) => {
  const dto: PatientGenericCarePlanDto = new PatientGenericCarePlanDto();
  dto.id = carePlan?.id;
  dto.categories = [];
  dto.activities = [];

  if (carePlan?.created) {
    dto.created = carePlan?.created;
  }

  if (carePlan?.status) {
    dto.status = carePlan?.status;
  }

  if (carePlan?.intent) {
    dto.intent = carePlan?.intent;
  }

  if (carePlan?.category && carePlan?.category?.length > 0) {
    const codings = carePlan?.category
      ?.filter((x) => x?.coding && x?.coding?.length > 0)
      ?.map((x) => x?.coding);
    codings.forEach((codingElement) => {
      codingElement?.forEach((x) => {
        dto.categories.push(x);
      });
    });
  }

  if (carePlan?.activity && carePlan?.activity?.length > 0) {
    dto.activities = [...carePlan?.activity];
  }

  return dto;
};
