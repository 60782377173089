import { useState } from 'react';
import { TaskResponse } from '../../../@types/crs/task';
import TaskList, { GridState } from './TaskList';
import { initialTaskStatusFilter } from '../case/components/TasksGrid/TasksGrid';

const initialGridState: GridState = {
  page: 1,
  rowsPerPage: 5,
  orderBy: 'periodEnd',
  orderDirection: 'asc',
  search: '',
  statusFilter: [...initialTaskStatusFilter],
  ownerFilter: [],
};

const TaskListContainer = () => {
  const [list, setList] = useState<TaskResponse[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <TaskList
      includeBreadcrumbs={true}
      list={list}
      setList={setList}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      totalItems={totalItems}
      setTotalItems={setTotalItems}
      startDate={startDate}
      setStartDate={setStartDate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      initialGridState={initialGridState}
    />
  );
};

export default TaskListContainer;
