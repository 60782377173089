import CloseIconButton from 'src/components/CloseIconButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
  Typography,
} from '@mui/material';
import useLocales from 'src/hooks/useLocales';

export interface SearchCombinationsDialogProps extends DialogProps {
  searchCombinations: string[];
}

const SearchCombinationsDialog = ({
  searchCombinations,
  onClose,
  ...dialogProps
}: SearchCombinationsDialogProps) => {
  const { i18n } = useLocales();
  return (
    <Dialog disableEscapeKeyDown={false} onClose={onClose} {...dialogProps}>
      <DialogTitle> {i18n('practitioner.possibleSearchCombinations', 'crs')}</DialogTitle>
      <DialogContent>
        <List
          sx={{
            width: '100%',
            paddingX: 3,
            marginTop: 2,
          }}
        >
          <ul>
            {searchCombinations.map((searchCombination, index) => (
              <Box key={`combination-item-${index}`}>
                {searchCombination ? (
                  <li style={{ listStyleType: 'disc' }}>
                    <Typography variant="body2" fontSize={'0.9rem'}>
                      {searchCombination}
                    </Typography>
                  </li>
                ) : (
                  <br />
                )}
              </Box>
            ))}
          </ul>
        </List>
      </DialogContent>
      <DialogActions>
        <CloseIconButton
          onClick={() => {
            onClose?.({}, 'backdropClick');
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SearchCombinationsDialog;
