import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function MessagePage({
  message,
  extraElement,
}: {
  message: string;
  extraElement?: JSX.Element;
}) {
  return (
    <Page title="Message">
      <Container>
        <ContentStyle sx={{ alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {message}
          </Typography>

          {extraElement && extraElement}
        </ContentStyle>
      </Container>
    </Page>
  );
}
