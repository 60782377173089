import {
  Cake as CakeIcon,
  Category as CategoryIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  Description as DescriptionIcon,
  EventAvailable as EventAvailableIcon,
  Event as EventIcon,
  Person as PersonIcon,
  Update as UpdateIcon,
} from '@mui/icons-material';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import moment from 'moment';
import { TaskResponse, TaskStatusValue } from 'src/@types/crs/task';
import TableSortMenuPopover, {
  TableSortMenuPopoverOptions,
} from 'src/components/table/TableSortMenuPopover';
import { i18nFunction } from 'src/hooks/useLocales';
import { formatDate, getAge } from 'src/utils/dates';
import { onSuccess } from '../../../@types/crs/case';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import TaskActionMenu, { onResourceAction } from '../case/components/TasksGrid/TaskActionMenu';
import { TaskStatus } from '../case/components/TasksGrid/TaskModal';
import CellRow from '../common/CellRow';
import CustomLink from '../common/CustomLink';
import { NOTAPPLICABLE } from '../constants';
import { getSeverityDueDateData } from '../helpers/getSeverityDueDateData';
import TaskStatusSeverity from './TaskStatusSeverity';

const getTaskListColumns = ({
  taskStatuses,
  i18n,
  onTaskOpen,
  onEditTask,
  onSearchMemberOpen,
  onCopyTask,
  onAddNoteToTask,
  onSuccessfulEdit,
  onAssignProgram,
  isLoading,
  handleSortChange,
}: {
  taskStatuses: TaskStatus[];
  i18n: i18nFunction;
  onTaskOpen: (fhirId: string) => void;
  onEditTask: onResourceAction;
  onSearchMemberOpen: onResourceAction;
  onCopyTask: onResourceAction;
  onAddNoteToTask: onResourceAction;
  onSuccessfulEdit: onSuccess;
  onAssignProgram: onResourceAction;
  isLoading: boolean;
  handleSortChange: (field: string, order: 'asc' | 'desc') => {};
}): GridColDef[] => {
  const sortOptionsForStatusColumn: TableSortMenuPopoverOptions[] = [
    {
      value: 'periodEnd',
      label: i18n('tasks.dueDate', 'crs'),
      icon: (
        <EventIcon
          sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
          fontSize={'small'}
        />
      ),
    },
  ];

  return [
    {
      field: 'fhirId',
      headerName: i18n('tasks.task', 'crs'),
      flex: 1,
      renderCell: (params) => {
        const { taskCodeCodingDisplay, internalNumber, fhirId, taskDescription } =
          params.row as TaskResponse;
        return (
          <Box>
            <CustomLink
              to="#"
              onClick={() => {
                onTaskOpen(fhirId);
              }}
            >
              <CellRow tooltipTitle={i18n('tasks.taskNumber', 'crs')} title={internalNumber} />
            </CustomLink>
            <CellRow
              tooltipTitle={i18n('tasks.taskType', 'crs')}
              title={taskCodeCodingDisplay}
              Icon={<CategoryIcon />}
            />
            <CellRow
              tooltipTitle={i18n('tasks.description', 'crs')}
              title={taskDescription}
              Icon={<DescriptionIcon />}
            />
          </Box>
        );
      },
    },
    {
      field: 'patientFullName',
      headerName: i18n('tasks.beneficiary.beneficiary', 'crs'),
      flex: 1,
      renderCell: (params) => {
        const { patientFullName, patientBirthDate, patientMrn, patientFhirUUID } =
          params.row as TaskResponse;
        const birthDate = moment(patientBirthDate ?? null);
        let formattedBirthDate = formatDate(birthDate);
        if (!birthDate.isValid()) formattedBirthDate = '';
        return (
          <Box>
            <Typography variant="subtitle2">
              <Iconify
                icon="mdi:letter-m"
                sx={{
                  fontSize: '2.2rem',
                  color: '#919eab',
                  marginBottom: '-10px',
                  marginLeft: '-7px',
                }}
              />
              <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(patientFhirUUID)}>
                {patientMrn}
              </CustomLink>
            </Typography>
            <CellRow
              tooltipTitle={i18n('tasks.beneficiary.fullName', 'crs')}
              title={patientFullName}
              Icon={<PersonIcon />}
            />
            <CellRow
              tooltipTitle={i18n('tasks.beneficiary.birthDate', 'crs')}
              title={formattedBirthDate ? `${formattedBirthDate} (${getAge(birthDate)} yr)` : ''}
              Icon={<CakeIcon />}
            />
          </Box>
        );
      },
    },
    {
      field: 'ownerName',
      headerName: i18n('tasks.owner.owner', 'crs'),
      flex: 1,
      renderCell: (params) => {
        const { ownerName, ownerEmail, ownerPhone } = params.row as TaskResponse;
        return (
          <Box>
            <CellRow
              tooltipTitle={i18n('tasks.owner.fullName', 'crs')}
              title={ownerName}
              Icon={<PersonIcon />}
            />
            <CellRow
              tooltipTitle={i18n('tasks.owner.email', 'crs')}
              title={ownerEmail}
              Icon={<ContactMailIcon />}
            />
            <CellRow
              tooltipTitle={i18n('tasks.owner.phone', 'crs')}
              title={ownerPhone}
              Icon={<ContactPhoneIcon />}
            />
          </Box>
        );
      },
    },
    {
      field: 'requesterName',
      headerName: i18n('tasks.requester.requester', 'crs'),
      flex: 1,
      renderCell: (params) => {
        const { requesterName, requesterEmail } = params.row as TaskResponse;
        return (
          <Box>
            <CellRow
              tooltipTitle={i18n('tasks.requester.fullName', 'crs')}
              title={requesterName}
              Icon={<PersonIcon />}
            />
            <CellRow
              tooltipTitle={i18n('tasks.requester.email', 'crs')}
              title={requesterEmail}
              Icon={<ContactMailIcon />}
            />
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: i18n('tasks.status', 'crs'),
      flex: 1,
      align: 'center',
      // hideSortIcons: true,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <TableSortMenuPopover
            headerColumnName={i18n('tasks.status', 'crs')}
            options={sortOptionsForStatusColumn}
            onSort={handleSortChange}
          />
        );
      },
      renderCell: (params) => {
        const { status, periodEnd } = params.row as TaskResponse;
        const end = moment(periodEnd ?? null);
        const { formattedDueDate: formattedEndDate } = getSeverityDueDateData(end);
        return (
          <Box sx={{ width: '100%', paddingX: 2 }}>
            <Tooltip title={i18n('tasks.taskStatus', 'crs')}>
              <span>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 0.5,
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ marginRight: 1 }}>
                    <UpdateIcon />
                  </Box>
                  <TaskStatusSeverity
                    sx={{ width: '100%' }}
                    status={status as TaskStatusValue}
                    taskStatuses={taskStatuses}
                  />
                </Box>
              </span>
            </Tooltip>
            <LinearProgress
              sx={{ marginTop: 2, width: '100%' }}
              variant="determinate"
              color="info"
              value={status === TaskStatusValue.Completed ? 100 : 0}
            />
            <CellRow
              tooltipTitle={i18n('tasks.dueDate', 'crs')}
              title={formattedEndDate !== NOTAPPLICABLE ? formattedEndDate : null}
              Icon={<EventAvailableIcon />}
            />
          </Box>
        );
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const { fhirId } = params.row as TaskResponse;
        return (
          <TaskActionMenu
            taskStatuses={taskStatuses ?? []}
            taskId={fhirId}
            onEditTask={onEditTask}
            onSearchMemberOpen={onSearchMemberOpen}
            onSuccessfulEdit={onSuccessfulEdit}
            onAssignProgram={onAssignProgram}
            onCopyTask={onCopyTask}
            onAddNoteToTask={onAddNoteToTask}
            isLoading={isLoading}
          />
        );
      },
    },
  ];
};

export default getTaskListColumns;
