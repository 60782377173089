export class Path {
  readonly value: string;

  private idField: string;

  constructor(...pathSegments: string[]) {
    this.value = pathSegments.join('/').split(/\/+/).join('/');
  }

  setIdField(idField: string) {
    this.idField = idField;
    return this;
  }

  forId(id: string | number): string {
    if (!this.idField) {
      throw new Error(`No ID field has been set for path "${this.value}".`);
    }
    return this.value.replace(`:${this.idField}`, String(id));
  }

  newChild(...pathSegments: string[]): Path {
    const path = new Path(this.value, ...pathSegments);
    if (this.idField) {
      return path.setIdField(this.idField);
    }
    return path;
  }

  getValue(): string {
    return this.value;
  }
}
