import { useState } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';

import CustomDrawer, { CustomDrawerProps } from 'src/components/CustomDrawer';
import useLocales from 'src/hooks/useLocales';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface EditDrawerProps extends CustomDrawerProps {
  isLoading?: boolean;
  onSaveButtonClick?: () => Promise<void>;
  saveButtonTitle?: string;
  saveButtonIcon?: React.ReactNode;
}

const EditDrawer = ({
  containerSx,
  onSaveButtonClick,
  saveButtonIcon,
  saveButtonTitle = 'Save',
  children,
  contentContainerSx,
  ...drawerProps
}: EditDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useLocales();
  return (
    <CustomDrawer
      containerSx={[{ width: '35vw' }, ...spreadSxProp(containerSx)]}
      contentContainerSx={[{ marginTop: 2.5 }, ...spreadSxProp(contentContainerSx)]}
      {...drawerProps}
    >
      <>
        {children}
        <Grid container marginTop={3} justifyContent={'center'}>
          <Grid item xs={6}>
            <LoadingButton
              sx={{ height: 48 }}
              fullWidth
              onClick={async () => {
                setIsLoading(true);
                try {
                  await onSaveButtonClick?.();
                } catch (error) {}
                setIsLoading(false);
              }}
              color="info"
              startIcon={saveButtonIcon ?? <SaveIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
            >
              {saveButtonTitle ?? i18n('Save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    </CustomDrawer>
  );
};

export default EditDrawer;
