/* eslint-disable */
import axios from 'axios';
// config
import { getCareflowToken, getTenantCodeFromCareflowToken } from './auth-utils';
import { getFrontendBaseUrl } from './domain-utils';

// ----------------------------------------------------------------------

// TODO: refactor to have less similar axios instances
const axiosInstance = axios.create({
  baseURL: getFrontendBaseUrl(),
});

// use interceptor to get the most recent careflow token
axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.headers === undefined) {
      config.headers = {};
    }

    const actualCareflowToken = getCareflowToken();

    if (actualCareflowToken) {
      config.headers['Authorization'] = 'Bearer ' + actualCareflowToken;
    }

    const tenantCode = getTenantCodeFromCareflowToken();

    if (tenantCode) {
      config.headers['X-TENANT-ID'] = tenantCode;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
