// @ts-nocheck
import { useEffect, useState } from 'react';
// @mui
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Dialog, 
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch, 
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
// hooks
import useTabs from '../../hooks/useTabs';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// @types
import { UserManager } from 'src/@types/user';
// _mock_
import { _userList } from '../../_mock';
// component
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  LoadingTableRow
} from '../../components/table';
// sections
import { UserTableToolbar, UserTableRow } from '../../sections/@dashboard/user/list';
import api from 'src/services/api';
import { OrganizationManager } from 'src/@types/organization';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { Role } from 'src/@types/role';
import { userOrgsRoles } from 'src/@types/user'
import { Stack } from '@mui/system';
import useObjectState from "src/hooks/useObjectState";
import FilterDrawer from "src/components/FilterDrawer";
import { useSnackbar } from 'notistack';
import uuidv4 from 'src/utils/uuidv4';
import agent from "src/api/agent";

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['All', 'Active', 'Inactive'];

const TABLE_HEAD = [
  { id: 'name', label: 'admin.name', align: 'left' },
  { id: 'organization', label: 'admin.organization', align: 'left' },
  { id: 'status', label: 'admin.status', align: 'left' },
  { id: '' },
];

type Props = {
  currentOrganization: OrganizationManager;
};

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  tableData: userOrgsRoles[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);
  
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        `${item.firstName} ${item.lastName}`.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'All') {
    tableData = tableData.filter((item: Record<string, any>) => item.status === filterStatus);
  }

  if (filterRole !== 0) {
    tableData = tableData.filter((item: Record<string, any>) => 
      item.userOrgRole.some((r: any) => 
        r.roles.some((s: any) => s.roleId === filterRole)));
  }
  
  return tableData;
}

interface SelectedUsers {
  userId: string | null;
  roleId: string | null;
};

export function Users({ currentOrganization }: Props) {
  const { i18n } = useLocales();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  
  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState(0);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('All');
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<any>([]);

  const [roles, setRoles] = useState<any[]>([]);
  const [tableData, setTableData] = useState<userOrgsRoles[]>([]);
  const [userByTenant, setUserByTenant] = useState<any>([]);

  const [{ userId, roleId },updateState ] = useObjectState<SelectedUsers>({
    userId: null,
    roleId: null,
  });

  useEffect(() => {
    getRoles();
    getUsers();
  }, []);

  useEffect(() => {
    setFilterName('');
    setFilterRole(0);
    setPage(0);
  },[filterStatus]);

  const getRoles = async () => {
    const rolesList = await api.roles.getRolesList();
    const mapRoles = rolesList?.map((item: Role) => ({
      code: item?.id,
      label: item?.name
    }))
    setRoles(mapRoles);
  };

  const getUsers = async () => {
    setIsLoading(true);
 
    if(currentOrganization?.fhirId){
      const users: userOrgsRoles[] = await 
        api.organizations.getUsersByOrganization([currentOrganization?.fhirId], true);
      const getAllUsers = await agent.User.getAllUsers();
      const mapUsersTenant = getAllUsers?.
        filter((item: any) => item?.status === 'Active' );
      setUserByTenant(mapUsersTenant);
      setTableData(users);
    }
    setIsLoading(false);
  }

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
    setPage(0);
  };

  const handleAddUserToOrg = async () => {
    try {
      if(!userId || !roleId) return;

      const getUser = userByTenant?.find((item: any) => item?.id === userId?.code);
      const payload = {
        ...getUser,
        user: userId?.code,
        role: roleId?.code,
        organization: currentOrganization?.id
      };

      const update = await api.users.addExistingUser(payload, getUser?.uuid);
      enqueueSnackbar('User has been added to the organization');
      getUsers()
    } catch (error) {
      console.log(error)
      enqueueSnackbar('An error has ocurred', { variant: 'error' });
    }
    updateState({
      userId: null,
      roleId: null,
    });
    setOpenDrawer(false);
  };

  const handleOpenDeleteModal = (row: userOrgsRoles) => {
    if(!row) return;
    setRowToDelete(row);
    setOpenModal(true)
  };

  const handleDeleteOrgRole = async (userOrgRoleUuid: string) => {
    try {
      if(!userOrgRoleUuid) return;
      const deleteExistingUsr = await api.users.deleteExistingUser(userOrgRoleUuid);
      enqueueSnackbar('Successfully eliminated');
      getUsers()
    } catch (error) {
      enqueueSnackbar('An error has ocurred', { variant: 'error' });
    }
    setOpenModal(false)
  };

  return (
    <Card>
      <Stack 
        direction={'row'} 
        justifyContent={'flex-end'}
        sx={{ m: 2 }}
      >
        <Button
          disabled={false}
          onClick={() => setOpenDrawer(true)}
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          Add User
        </Button>
      </Stack>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={filterStatus}
        onChange={onChangeFilterStatus}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Tab disableRipple key={tab} label={i18n(`admin.list.${tab.toLowerCase()}`)}
            value={tab} />
        ))}
      </Tabs>

      <Divider />

      <UserTableToolbar
        filterName={filterName}
        filterRole={filterRole}
        onFilterName={handleFilterName}
        onFilterRole={handleFilterRole}
        optionsRole={roles}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={translateTableHead(TABLE_HEAD)}
              rowCount={tableData.length}
              onSort={onSort}
            />

            {!isLoading ? (
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <UserTableRow
                      key={row.uuid}
                      row={row}
                      onDeleteRow={() => handleOpenDeleteModal(row)}
                      onEditRow={() => {}}
                      editOption={true}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            ): (
              <LoadingTableRow colSpan={TABLE_HEAD.length} />
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataFiltered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />

        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label="Dense"
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>

      <FilterDrawer 
        title={`Add User To ${currentOrganization?.name}`} 
        open={openDrawer}
        anchor={'right'} 
        onApplyButtonClick={handleAddUserToOrg} 
        onCloseIconButtonClick={() => {
          setOpenDrawer(false)
        }}
        onClearAllButtonClick={() => { 
          updateState({
            userId: null,
            roleId: null,
          })
        }} 
      >
        <br/>
        <Autocomplete
          id="combo-box-user"
          value={userId}
          fullWidth
          onChange={(_, newValue: any) => {
            updateState({ userId: newValue });
          }}
          options={userByTenant?.map((item: UserManager) => ({
            code: item?.id,
            label: `${item?.firstName} ${item?.lastName}`
          }))}
          renderInput={(params) => 
            <TextField  
              label="Users"
              {...params}
            />
          }
          renderOption={(props, option, { index }) => (
            <li {...props} key={option?.code + index}>
              {option?.label}
            </li>
          )}
        />
        <br/>
        <Autocomplete
          id="combo-box-role"
          value={roleId}
          fullWidth
          onChange={(_, newValue: any) => {
            updateState({ roleId: newValue });
          }}
          options={roles}
          renderInput={(params) => 
            <TextField  
              label="Role"
              {...params}
            />
          }
          renderOption={(props, option, { index }) => (
            <li {...props} key={option?.code + index}>
              {option?.label}
            </li>
          )}
        />
      </FilterDrawer>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="sm"
        keepMounted={false}
      >
        <DialogTitle id="confirm-dialog-title">Organizations and Roles</DialogTitle>
        <Card>
          <DialogContent sx={{ paddingTop: 2 }}>
            <>
              <Box sx={{ marginBottom: 1 }}>
                <Typography sx={{ fontSize: "0.90rem" }}>
                  <span style={{ fontWeight: "bold" }}>User: </span> 
                    {`${rowToDelete?.firstName} ${rowToDelete?.lastName}`}
                </Typography>
              </Box>
              <TableContainer sx={{ mt: 2, maxHeight: 300 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Organization</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowToDelete?.userOrgRole?.map((item: userOrgRole) => (
                      <TableRow key={uuidv4()}>
                        <TableCell>{item?.organizationName}</TableCell>
                        <TableCell>{item?.roleName}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleDeleteOrgRole(item?.userOrgRoleUuid)}
                            color="error"
                          >
                            <Iconify icon='material-symbols:delete' color="red"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          </DialogContent>
        </Card>
      </Dialog>
    </Card>
  );
}