import { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Box,
  Modal,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import Iconify from 'src/components/Iconify';
import { ActivitiesContext, ActivitiesContextType } from '../../Activities/ActivitiesContext';
import { shortFormat } from 'src/utils/formatTime';
import { EntityContextMenu } from 'src/sections/careflow/common';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import ReferralViewModel from '../ViewModel/ReferralViewModel';
import { ReferralStatuses } from '../constants';
import { CCM_ACLS, checkAclValidation } from 'src/utils/permissions/permission.utils';
import PatientWidgets from 'src/sections/careflow/rounding/components/ListTable/IntervalDetailSectionsModal/PatientWidgets';
import { SectionEnum } from 'src/sections/careflow/rounding/components/ListTable/constants';
import useObjectState from 'src/hooks/useObjectState';

type ReferralsListTableProps = {
  isLoading: boolean;
  referrals: ReferralViewModel[];
  onDeleteItem: (referral: ReferralViewModel) => void;
};

function ReferralsListTable({ isLoading, referrals, onDeleteItem }: ReferralsListTableProps) {
  const [
    { widgetsModalVisible, patientFhirId, showAddCommunicationModal, referralId },
    updateState,
  ] = useObjectState<{
    widgetsModalVisible: Boolean;
    patientFhirId: string | null;
    showAddCommunicationModal: Boolean;
    referralId: string;
  }>({
    widgetsModalVisible: false,
    patientFhirId: null,
    showAddCommunicationModal: false,
    referralId: '',
  });
  const activitiesContext: ActivitiesContextType = useContext(ActivitiesContext);
  const getColumns = () => [
    { label: 'Date' },
    {
      label: 'Name / DOB',
    },
    {
      label: 'Referred From',
    },
    {
      label: 'Referred To',
    },
    {
      label: 'Service',
    },
    {
      label: 'Status',
    },
    {
      label: 'Created By',
    },
    {
      label: 'Assigned To',
    },
    {
      label: 'Last Updated / Last Updated By',
    },
    {
      label: 'Links',
    },
  ];
  const columns = getColumns();

  const onDownloadAttachment = async (referral: any) => {
    const { data } = await axiosFhirInstance.get(`DocumentReference?related=${referral?.fhirId}`);
    const element = document.createElement('a');
    if (data.entry.length > 0) {
      const attachment = data?.entry[0]?.resource;
      const binaryId = attachment.content?.[0]?.attachment.url?.split('/').pop();

      if (binaryId) {
        const { data: binaryResponse } = await axiosFhirInstance.get(`/Binary/${binaryId}`);

        if (!binaryResponse) return;

        element.setAttribute(
          'href',
          `data:${attachment?.contentType};base64,${binaryResponse.data}`
        );
        element.setAttribute('download', `filename.${binaryResponse.contentType?.split('/')[1]}`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        element.setAttribute(
          'href',
          `data:${attachment.content?.[0]?.attachment.contentType};base64,${attachment.content?.[0]?.attachment?.data}`
        );
        element.setAttribute(
          'download',
          `filename.${attachment.content?.[0]?.attachment.contentType?.split('/')[1]}`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    }
  };

  const onShowAddNote = (referral: ReferralViewModel) => {
    activitiesContext.toggleAddCommunicationModal(referral);
  };

  const mapReferralStatus = (status: string) => {
    // TODO: check if is better to not use enum when mapping
    switch (status) {
      case 'draft':
        return ReferralStatuses.draft;
      case 'active':
        return ReferralStatuses.active;
      case 'completed':
        return ReferralStatuses.completed;
      case 'on-hold':
        return ReferralStatuses['on-hold'];
      case 'revoked':
        return ReferralStatuses.revoked;
      case 'entered-in-error':
        return ReferralStatuses['entered-in-error'];
    }
  };

  return (
    <TableContainer sx={{ padding: 2 }}>
      <Modal
        open={widgetsModalVisible as boolean}
        onClose={() => updateState({ widgetsModalVisible: false, patientFhirId: null })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            overflow: 'scroll',
            boxShadow: 24,
            borderRadius: 2,
            p: 0,
          }}
        >
          <PatientWidgets
            section={SectionEnum.carePlan}
            patient={{
              id: patientFhirId,
            }}
          />
        </Box>
      </Modal>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" sx={{ ml: 1, mb: 2, fontWeight: 'light' }}>
            Referrals
          </Typography>
        </Grid>
        {checkAclValidation({
          acls: [
            CCM_ACLS.CCM_ADMIN,
            CCM_ACLS.CCM_SYSTEM_ADMIN,
            CCM_ACLS.TASK_ACTIVITIES.REFERRALS.name,
            CCM_ACLS.TASK_ACTIVITIES.REFERRALS.WRITE,
          ],
        }) && (
          <Grid item>
            <IconButton onClick={() => activitiesContext.openAddEntityModal()}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col: any) => (
              <TableCell key={col.label}>
                <Typography>{col.label}</Typography>
              </TableCell>
            ))}
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            referrals.map((referral) => (
              <TableRow key={referral.id}>
                <TableCell>{shortFormat(moment(referral.date).toDate())}</TableCell>
                <TableCell>
                  {referral.patientName} -{' '}
                  {referral.patientBirthdate
                    ? shortFormat(moment(referral.patientBirthdate).toDate())
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {referral.referredFromPractitionerId ? (
                    <>
                      {referral.referredFromPractitionerName} -{' '}
                      {referral.referredFromPractitionerOrganizationName}
                    </>
                  ) : referral.referredFromOrganizationId ? (
                    <>{referral.referredFromOrganizationName}</>
                  ) : referral.referredFromDisplay ? (
                    <>{referral.referredFromDisplay}</>
                  ) : null}
                </TableCell>
                <TableCell>
                  {referral.referredToPractitionerId ? (
                    <>
                      {referral.referredToPractitionerName} -{' '}
                      {referral.referredToPractitionerOrganizationName}
                    </>
                  ) : referral.referredToOrganizationName ? (
                    <>{referral.referredToOrganizationName}</>
                  ) : referral.referredToDisplay ? (
                    <>{referral.referredToDisplay}</>
                  ) : null}
                </TableCell>
                <TableCell>{referral.serviceName}</TableCell>
                <TableCell>{mapReferralStatus(String(referral.status))}</TableCell>
                <TableCell>{referral.createdBy}</TableCell>
                <TableCell>{referral.assignedToName}</TableCell>
                <TableCell>
                  {referral.lastChangeDate
                    ? moment(referral.lastChangeDate).format('MMM DD, YYYY hh:mm a')
                    : '-'}
                  <br />
                  {referral.lastChangeBy}
                </TableCell>
                <TableCell sx={{ flexDirection: 'row' }}>
                  <Tooltip title={'Care Plan'}>
                    <IconButton
                      onClick={() =>
                        updateState({
                          widgetsModalVisible: true,
                          patientFhirId: referral.patientFhirId,
                        })
                      }
                    >
                      <Iconify icon="material-symbols:grid-view-outline-rounded" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <EntityContextMenu
                    entity={referral}
                    items={[
                      {
                        label: 'Edit',
                        icon: 'bi:calendar-check',
                        onPress: (currentReferral: ReferralViewModel) =>
                          activitiesContext.openAddEntityModal(currentReferral),
                        rules: () => true,
                      },
                      {
                        label: 'Referral Notes',
                        icon: 'bi:journal-plus',
                        onPress: (currentReferral: ReferralViewModel) =>
                          onShowAddNote(currentReferral),
                        rules: () => true,
                      },
                      {
                        label: 'Delete',
                        icon: 'bi:trash',
                        onPress: (currentReferral: ReferralViewModel) =>
                          onDeleteItem(currentReferral),
                        rules: () => true,
                      },
                      {
                        label: 'Attachments',
                        icon: 'bi:download',
                        onPress: (currentReferral: ReferralViewModel) =>
                          onDownloadAttachment(currentReferral),
                        rules: (currentReferral) => !!currentReferral?.lastDocumentReference,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!isLoading && referrals.length === 0 && (
        <Grid alignItems="center">
          <Typography style={{ textAlign: 'center', paddingTop: '20px' }}>No Referrals</Typography>
        </Grid>
      )}
      {isLoading && (
        <Box textAlign={'center'} marginTop={5}>
          <CircularProgress />
        </Box>
      )}
    </TableContainer>
  );
}

export default ReferralsListTable;
