import produce from 'immer';
import { isEmpty } from 'lodash';
import agent from 'src/api/agent';
import {
  useCommunications,
  useDocumentReferences,
  usePatient,
  useServiceRequest,
} from 'src/@nicheaim/fhir-react';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import Iconify from 'src/components/Iconify';
import useSettings from 'src/hooks/useSettings';
import ReferralSummary from '../ReferralSummary';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { memo, useEffect, useMemo, useState } from 'react';
import { TLink } from 'src/components/Breadcrumbs';
import { TableCustom } from 'src/components/TableCustom';
import { PatientSummary } from 'src/sections/crs/patient';
import { referralService } from 'src/crs/referral/services';
import NoteAddForm from 'src/sections/crs/common/NoteAddForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import ExpandableCard from 'src/components/common/ExpandableCard';
import { TABLE_HEAD_NOTES } from 'src/sections/crs/common/table-head';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ServiceRequest } from 'src/@nicheaim/fhir-base/mappings/ServiceRequest';
import { Box, Card, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { ServiceRequestWrapper } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { DocumentList } from 'src/sections/crs/patient/components/patientDocuments/DocumentList';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { mapCommunicationsToNotesDisplay, mapServiceRequesToReferralSummary } from 'src/sections/crs/common/common-utils';

const getIdFromReference = (reference: string): string => reference.split('/')?.[1];

const ChildReferralDetails = memo(() => {
  const { themeStretch } = useSettings();
  const { i18n } = useLocales();

  const { id: referralId = null } = useParams();

  const [patientId, setPatientId] = useState<string | null>(null);

  const [communicationsQuery, setCommunicationsQuery] = useState({});
  const [documentReferencesQuery, setDocumentReferencesQuery] = useState({});
  const [openNote, setOpenNote] = useState(false);
  const [parentReferral, setParentReferral] = useState('');

  const [allUsers, setAllUsers] = useState<any>([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [referralRecord, setReferralRecord] = useState();

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    setAllUsers(result);
  };

  const fetchReferralRecord = async (id: any) => {
    const result: any = await referralService.referralApiClient.getOne(id);

    setReferralRecord(result);
  };

  useEffect(() => {
    if (referralId) {
      setParentReferral(getIdFromReference(serviceRequest?.basedOn?.[0].reference || ''));
      getAllUsers();
      fetchReferralRecord(referralId);
    }
  }, [referralId]);

  const [
    serviceRequest,
    {
      isSuccess: isSuccessServiceRequest,
      refresh: refreshServiceRequest,
      update: updateServiceRequest,
    },
  ] = useServiceRequest(referralId, { map: ServiceRequestWrapper });

  const [patient] = usePatient(patientId, {
    map: PatientWrapper,
  });

  const [communications, { refresh: refreshCommunications, create: createCommunication }] =
    useCommunications({
      filter: communicationsQuery,
      map: CommunicationWrapper,
    });

  const [
    documentReferences,
    { refresh: refreshDocumentReference },
  ] = useDocumentReferences({ filter: documentReferencesQuery, map: DocumentReferenceWrapper });

  useEffect(() => {
    if (serviceRequest && isSuccessServiceRequest) {
      if (serviceRequest?.subject?.reference) {
        setPatientId(getIdFromReference(serviceRequest?.subject?.reference));
      }
      setCommunicationsQuery({ ...communicationsQuery, 'part-of': serviceRequest.id });
      setDocumentReferencesQuery({ ...documentReferencesQuery, related: serviceRequest.id });
    }
  }, [serviceRequest, isSuccessServiceRequest]);

  const handleReAssingOwned = async (ownedSelected: string) => {
    try {
      const performerServiceRequest =
        serviceRequest?.performer?.filter((p) => p.type !== 'Practitioner') || [];
      const getUser = allUsers?.find((u) => u?.id === Number(ownedSelected));

      if (!isEmpty(getUser)) {
        const practitioner = {
          reference: getUser?.fhirUri,
          display: `${getUser?.firstName} ${getUser?.lastName}`,
          type: 'Practitioner',
        };

        const performerEdit = [...performerServiceRequest, practitioner];

        const update = updateServiceRequest(
          produce(serviceRequest!, (draft) => {
            draft.performer = performerEdit;
          })
        );

        refreshServiceRequest();
      }
    } catch (err) {
      console.log('error on handleReAssingOwned');
    }
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};
    try {
      result = await createCommunication(data);
      handleCloseAddNote();
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const handleOpenNote = () => {
    setOpenNote((prev) => !prev);
  };

  const handleCloseAddNote = () => {
    setOpenNote(false);
  };

  const handleUpdateReferral = async (data: ServiceRequest) => {

    const id = data?.id;
    const isEditing = Boolean(id);

    let result: any = {};

   if(isEditing){
      result = await updateServiceRequest(
        produce(serviceRequest!, (draft) => {
          draft.supportingInfo = data.supportingInfo;
          draft.note = data.note;
        })
      );
    }

    refreshServiceRequest();
    
    return result;
  };

  const link = [
    { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
    { name: `${i18n('breadcrumbs.title', 'crs')}`},
    { name: `${i18n('referral.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.referral },
    parentReferral && {
      name: `${i18n('referral.details.secondaryReferrals.referralParent', 'crs')}`,
      href: `${PATH_DASHBOARD.crs.referral}/${parentReferral}`,
    },
    { name: `${i18n('referral.details.secondaryReferrals.details', 'crs')}` },
  ].filter((item) => item) as TLink[];

  const childReferralPermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.REFERRAL.CHILD_REFERRAL.EDIT] })
    }),
    []
  );

  return (
    <Page title={i18n('referral.details.secondaryReferrals.details', 'crs')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs title={i18n('referral.details.secondaryReferrals.details', 'crs')} heading="" links={link} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Typography sx={{ mt: 1.5 }} variant="overline">
                collapse all
              </Typography>
              <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                <Iconify
                  icon={openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ReferralSummary
              title="Referral Summary"
              workflowInstance={
                !isEmpty(serviceRequest) && mapServiceRequesToReferralSummary(serviceRequest)
              }
              referralRecord={referralRecord}
              users={allUsers}
              onOwnedAssign={handleReAssingOwned}
              fhirPatient={patient}
              handlers={{}}
              referralWorkflowData={{}}
              openCollapseExternal={openCollapse}
              children={
                !isEmpty(serviceRequest) ? (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Service</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {serviceRequest?.code?.coding?.[0]?.code}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Referred From</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {serviceRequest?.requester?.display}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Referred To</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {serviceRequest?.getPerfomerByType('Organization')?.display}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Location</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {serviceRequest?.locationReference?.[0]?.display}
                      </Typography>
                    </Stack>
                  </>
                ) : null
              }
              isAllowedToEdit={childReferralPermissions.isAllowedToEdit}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {patient && (
              <PatientSummary
                title={`${i18n('patients.title', 'crs')} Information`}
                patient={patient}
                openCollapseExternal={openCollapse}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard 
              title={'Notes'}
              openCollapseExternal={openCollapse}
            >
              <TableCustom
                data={mapCommunicationsToNotesDisplay(communications, ['notes_child_referral'])}
                tableHead={translateTableHead(TABLE_HEAD_NOTES, 'crs')}
                handleOpen={handleOpenNote}
                titleButton={'Add A Note'}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title={i18n('patients.details.attachments.title', 'crs')}>
              <Card style={{ boxShadow: 'none', position: 'static' }}>
                <DocumentList
                  patient={patient}
                  documentReferences={documentReferences}
                  resource={serviceRequest}
                  refreshDocumentReference={refreshDocumentReference}
                  openCollapseExternal={openCollapse}
                  handleUpdateResource={handleUpdateReferral}
                />
              </Card>
            </ExpandableCard>
          </Grid>
        </Grid>

        <NoteAddForm
          open={openNote}
          patient={patient}
          onCancel={handleCloseAddNote}
          resource={[serviceRequest]}
          typeNote={'notes_child_referral'}
          handleCreate={handleCreateCommunicationFhir}
        />
        
      </Container>
    </Page>
  );
});

export default ChildReferralDetails;
