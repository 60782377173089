import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { COLLECTION_PATH_SEPARATOR, defaultCollectionsPath } from '../utils/constants';
import { DocumentData, DocumentReference, doc, updateDoc } from 'firebase/firestore';
import { db } from 'src/crs/firebase/firebase-init';

import { MutationActions } from '../types';

export type MutationVariableType<T extends DocumentData> = {
  data: Partial<T>;
  id?: string;

  action: MutationActions;
};
export type MutationResult = DocumentReference | null;

export interface UseFirebaseCollectionMutationProps<TData extends DocumentData>
  extends UseMutationOptions<MutationResult, any, MutationVariableType<TData>, any> {
  collectionName: string;
}

const useFirebaseCollectionMutation = <TData extends DocumentData>({
  collectionName,
  ...queryProps
}: UseFirebaseCollectionMutationProps<TData>) =>
  useMutation<MutationResult, any, MutationVariableType<TData>, any>({
    mutationFn: async ({ data, id, action }) => {
      const docRef = doc(
        db,
        `${defaultCollectionsPath.join(COLLECTION_PATH_SEPARATOR)}`,
        `${collectionName}${COLLECTION_PATH_SEPARATOR}${id ?? ''}`
      );

      switch (action) {
        case MutationActions.MODIFY:
          await updateDoc(docRef, data as TData);
          return null;

        default:
          return null;
      }
    },
    ...(queryProps ? queryProps : {}),
  });

export default useFirebaseCollectionMutation;
