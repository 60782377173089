/* eslint-disable */
import makeStyles from "@mui/styles/makeStyles";
import KeyValueGrid from "../../crs/common/KeyValueGrid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RequestReportResponse from "./RequestReportResponse";
import { getRisk, getRiskColor, indicatorsList } from "../helpers/report";
import { getMeasureReportGroups, getSplitReference } from "src/utils/fhir";
import { Accordion, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import { 
  Coding, 
  MeasureReport,
  MeasureReportGroup, 
  MeasureReportGroupStratifier, 
  MeasureReportGroupStratifierStratum, 
  MeasureReportGroupStratifierStratumComponent, 
  Questionnaire
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { QuestionnaireResponse } from "src/@nicheaim/fhir-base/mappings/QuestionnaireResponse";
import { ColorIndicators, MeasureReportGroups, RiskComponentProps, Risks } from "src/@types/nat/report";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "40px",
  },
  rootExpanded: {
    background: "blue",
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
}));

function RiskComponent({
  mainText,
  riskText,
  riskCode,
  score,
}: RiskComponentProps) {
  const riskStr: Risks = getRisk(riskCode);
  const riskColor = getRiskColor(riskStr);

  return (
    <Grid container item>
      <Grid
        container
        item
        md={4}
        alignItems="center"
      >
        <Typography>{mainText}</Typography>
      </Grid>
      <Grid
        container
        item
        className={riskColor.backgroundClass}
        md={8}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item className={riskColor.normalClass} md={4}>
          <Typography style={{ paddingLeft: 8 }}>{`${riskCode}`}</Typography>
        </Grid>
        {riskText !== riskCode && (
          <Grid
            container
            item
            justifyContent="center"
            className={riskColor.normalClass}
            md={6}
          >
            <Typography>{riskText}</Typography>
          </Grid>
        )}
        <Grid
          container
          item
          justifyContent="center"
          className={riskColor.normalClass}
          md={2}
        >
          <Typography>{score}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface RequestReportProps {
  devMode: boolean;
  measureReport: MeasureReport | undefined;
  showMeasureQuestionnaireResponses: boolean;
  questionnaire: Questionnaire[] | undefined;
  questionnaireResponse: QuestionnaireResponse[] | undefined;
}

export default function RequestReport({
  questionnaire,
  questionnaireResponse,
  measureReport,
  devMode,
  showMeasureQuestionnaireResponses,
}: RequestReportProps) {
  const classes = useStyles();

  const getComponentRender = (
    index: number,
    component: MeasureReportGroupStratifierStratumComponent
  ) => {
    return (
      <>
        <KeyValueGrid
          label={"stratum.component.[" + index + "].value.text"}
          value={component.value.text === undefined ? "" : component.value.text}
        />

        <KeyValueGrid
          label={"stratum.component.[" + index + "].code.text"}
          value={component.code.text === undefined ? "" : component.code.text}
        />

        {component.value.coding?.map(
          (item2: Coding, index2: number) => {
            return getCodeableConceptRender(
              "stratum.component.[" + index + "].value.coding.[" + index2 + "]",
              item2
            );
          }
        )}

        {component.code.coding?.map(
          (item2: Coding, index2: number) => {
            return getCodeableConceptRender(
              "stratum.component.[" + index + "].code.coding.[" + index2 + "]",
              item2
            );
          }
        )}
      </>
    );
  };

  const getCodeableConceptRender = (
    preText: string,
    coding: Coding
  ) => {
    return (
      <>
        <KeyValueGrid
          label={preText + ".code"}
          value={coding.code ? coding.code : ""}
        />
        <KeyValueGrid
          label={preText + ".display"}
          value={coding.display ? coding.display : ""}
        />
        <KeyValueGrid
          label={preText + ".system"}
          value={coding.system ? coding.system : ""}
        />
      </>
    );
  };

  const getStratumRender = (
    stratum: MeasureReportGroupStratifierStratum,
  ) => {

    return (
      <Grid container item md={12}>
        <RiskComponent
          mainText={
            stratum.component?.[1]?.value?.text
              ? stratum.component?.[1]?.value?.text
              : ""
          }
          riskText={
            stratum.component?.[0]?.value?.coding?.[0]?.display
              ? stratum.component?.[0]?.value?.coding?.[0]?.display
              : ""
          }
          riskCode={
            stratum.component?.[0]?.code?.coding?.[0]?.code
              ? stratum.component?.[0]?.code?.coding?.[0]?.code
              : ""
          }
          score={
            stratum.measureScore?.value === undefined
              ? ""
              : String(stratum.measureScore?.value)
          }
        />
        {devMode && (
          <Grid container item md={12} direction="row">
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              {stratum.value?.text && (
                <KeyValueGrid
                  label={"stratum.value.text"}
                  value={stratum.value.text ? stratum.value.text : ""}
                />
              )}

              {stratum.measureScore && (
                <KeyValueGrid
                  label={"stratum.measureScore.value"}
                  value={
                    stratum.measureScore.value === undefined
                      ? ""
                      : String(stratum.measureScore.value)
                  }
                />
              )}

              {stratum.value?.coding?.map(
                (value: Coding, index: number) => {
                  return getCodeableConceptRender(
                    "stratum.value.coding.[" + index + "]",
                    value
                  );
                }
              )}

              {stratum.component?.map(
                (item: MeasureReportGroupStratifierStratumComponent, index: number) => {
                  return getComponentRender(index, item);
                }
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const getStratifiersRender = (
    stratifiers: MeasureReportGroupStratifier[]
  ): JSX.Element[] | undefined => {
    let stratums: JSX.Element[] = [];

    stratifiers.forEach((value: MeasureReportGroupStratifier) => {
      value.stratum?.forEach((value2: MeasureReportGroupStratifierStratum) => {
        stratums.push(getStratumRender(value2));
      });
    });

    return stratums;
  };

  const getRenderGroup = (group: MeasureReportGroup) => {
  
    const stratums =
      group.stratifier !== undefined
        ? getStratifiersRender(group.stratifier)
        : [];

    const questResponse = questionnaireResponse?.
      find((item) => item?.id === getSplitReference(
        group.code?.coding?.[0].code ?? '')?.uuid);

    const matchingQuestUuidFromUrl = (() => {
      if (questResponse?.questionnaire === undefined) return undefined;
    
      const url = questResponse?.questionnaire;
      const pathParts = url.split('/');
      const lastPart = pathParts[pathParts.length - 1];
    
      return lastPart;
    })();

    const questionnaireFromQuest = questionnaire?.find((item) => item?.id === matchingQuestUuidFromUrl);

    return (
      <Grid item md={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container item direction="row" md={12}>
              <Grid container item md={12}>
                <Grid container item md={4} alignItems="center">
                  <Typography>
                    {group.code?.text === undefined ? "" : group.code?.text}
                  </Typography>
                </Grid>
              </Grid>
              {devMode && (
                <Grid
                  className={classes.highlight}
                  container
                  item
                  md={12}
                  direction="column"
                >
                  <KeyValueGrid
                    label="group.code.text"
                    value={
                      group.code?.text === undefined ? "" : group.code?.text
                    }
                  />
                  <KeyValueGrid
                    label="group.code.coding.[0].code"
                    value={
                      group.code?.coding?.[0].code === undefined
                        ? ""
                        : group.code?.coding?.[0].code
                    }
                  />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <Grid
            container
            spacing={1}
            paddingLeft={2}
            paddingRight={4}
            paddingBottom={2}
          >
            {stratums?.map((item: JSX.Element, index: number) => {
              return (
                <Grid item md={12}>
                  {item}
                </Grid>
              );
            })}

            {showMeasureQuestionnaireResponses && 
              questResponse && questionnaireFromQuest && (
                <RequestReportResponse 
                  questionnaire={[questionnaireFromQuest]}
                  questionnaireResponse={[questResponse]}
                  devMode={devMode}
                />
            )}
          
          </Grid>
        </Accordion>
      </Grid>
    );
  };

  const getRenderIndicator = (displayText: string, risk: Risks) => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        className={getRiskColor(risk).normalClass}
        style={{
          height: 32,
        }}
        paddingLeft={2}
        paddingRight={2}
      >
        <Typography>
          <b>{displayText}</b>
        </Typography>
      </Grid>
    );
  };

  const getRenderComplexScoreGroup = (
    mainText: string,
    group: MeasureReportGroup,
    riskCode?: Risks
  ) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    const riskValue =
      group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code?.coding?.[0]
        ?.code;
    const riskCodeParsed =
      riskCode === undefined
        ? riskValue === undefined
          ? "none"
          : getRisk(riskValue)
        : riskCode;
    const indicatorText = "Value " + measureValue;
    return (
      <Paper elevation={3}>
        <Grid container direction="row" justifyContent="center" padding={2}>
          <Grid container item md={12} justifyContent="center" marginBottom={3}>
            <Typography variant="h5">{mainText}</Typography>
          </Grid>
  
          <Grid item md={12}>
            {getRenderIndicator(indicatorText, riskCodeParsed)}
          </Grid>
          {devMode && (
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="group.stratifier[0].stratum[0].measureScore.value"
                value={
                  group.stratifier?.[0].stratum?.[0].measureScore?.value ===
                  undefined
                    ? ""
                    : String(
                        group.stratifier?.[0].stratum?.[0].measureScore?.value
                      )
                }
              />
              <KeyValueGrid
                label="group.stratifier.[0].stratum.[0].component.[0].code.coding[0].code"
                value={
                  group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code
                    ?.coding?.[0]?.code === undefined
                    ? ""
                    : group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code
                        ?.coding?.[0]?.code
                }
              />
              <KeyValueGrid label="riskCodeParsed" value={riskCodeParsed} />
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  };

  const getRenderMedicalComplexGroup = (group: MeasureReportGroup) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    let riskCode: Risks = "none";
  
    if (measureValue !== undefined) {
      switch (measureValue) {
        case 1:
          riskCode = "low-risk";
          break;
        case 2:
          riskCode = "medium-risk";
          break;
        case 3:
          riskCode = "high-risk";
          break;
        default:
          riskCode = "none";
      }
    }
  
    return getRenderComplexScoreGroup("Medical complexity", group, riskCode);
  };
  
  const getRenderServiceIntegrationGroup = (
    group: MeasureReportGroup
  ) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    let riskCode: Risks = "none";
  
    if (measureValue !== undefined) {
      switch (measureValue) {
        case 1:
          riskCode = "low-risk";
          break;
        case 2:
          riskCode = "medium-risk";
          break;
        case 3:
          riskCode = "high-risk";
          break;
        default:
          riskCode = "none";
      }
    }
  
    return getRenderComplexScoreGroup(
      "Service integration levels",
      group,
      riskCode
    );
  };

  const mrGroups: MeasureReportGroups = getMeasureReportGroups(measureReport);

  return (
    <Grid item md={12} container spacing={3}>
      {mrGroups.medicalComplex && (
        <Grid item md={4}>
          {getRenderMedicalComplexGroup(mrGroups.medicalComplex)}
        </Grid>
      )}

      {mrGroups.socialComplex && (
        <Grid item md={4}>
          {getRenderComplexScoreGroup(
            "Social complexity",
            mrGroups.socialComplex
          )}
        </Grid>
      )}

      {mrGroups.serviceIntegration && (
        <Grid item md={4}>
          {getRenderServiceIntegrationGroup(mrGroups.serviceIntegration)}
        </Grid>
      )}

      <Grid item md={12}>
        <Paper elevation={3}>
          <Grid container direction="row" justifyContent="center">
            <Grid container item md={12} style={{ padding: 16 }}>
              <Grid
                container
                item
                flexDirection="row"
                justifyContent="space-around"
                style={{ marginBottom: 16 }}
              >
                {indicatorsList.map((item: ColorIndicators) => {
                  return (
                    <Grid item md={item.flex}>
                      {getRenderIndicator(item.text, item.riskValue)}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid
                container
                item
                md={12}
                flexDirection="row"
                alignItems="center"
                spacing={2}
              >
                {devMode && (
                  <Grid container item md={12} direction="row">
                    <Grid
                      className={classes.highlight}
                      container
                      item
                      md={12}
                      direction="column"
                    >
                      {measureReport?.id && (
                        <KeyValueGrid
                          label={"measureReport.id"}
                          value={
                            measureReport?.id === undefined
                              ? ""
                              : measureReport?.resourceType +
                                "/" +
                                measureReport?.id
                          }
                        />
                      )}
                      {measureReport?.measure && (
                        <KeyValueGrid
                          label={"measureReport.measure"}
                          value={
                            measureReport?.measure === undefined
                              ? ""
                              : measureReport?.measure
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                {mrGroups.normal?.map((group: MeasureReportGroup) => {
                  return getRenderGroup(group);
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
