import { Autocomplete, Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridFilterDrawerProps } from '../DataGridWithFilters/DataGridWithFilters';
import {
  TaskGridRowData,
  onSuccess,
  SeverityStatusDataWithDate,
} from '../../../../../@types/crs/case';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import {
  Reference,
  Task,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import PeriodFilter, { onDateChange } from '../../../../../components/PeriodFilter';
import CheckboxList from 'src/components/CheckboxList';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import useObjectState from 'src/hooks/useObjectState';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import TaskModal, { TaskStatus, getOwnerDisplayLabel } from './TaskModal';
import { WrappedPatient } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import TaskActionMenu, { OnInternalUrlClick, UrlData } from './TaskActionMenu';
import SearchMemberModal from '../SearchMember/SearchMemberModal';
import { useSnackbar } from 'notistack';
import { updateTask } from 'src/services/api/case';
import { onSelectResource } from '../SearchMember/SearchMember';
import { momentDateComparator } from 'src/utils/dates';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import Program from 'src/sections/crs/common/Program';
import TaskDetailDrawer from '../TaskDetailDrawer';
import './styles.css';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import ModalCommunication from 'src/sections/crs/common/ModalCommunication';
import useTaskContext from 'src/hooks/useTaskContext';
import useLocales from 'src/hooks/useLocales';
import useClientGrid from 'src/hooks/useClientGrid';
import CellRow from 'src/sections/crs/common/CellRow';
import CustomLink from 'src/sections/crs/common/CustomLink';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import { TaskPermissions } from 'src/sections/crs/types';
import IframeModal from 'src/sections/crs/common/IframeModal';
import CollapsibleDataGrid from '../CollapsibleDataGrid';
import CaseTaskGrid, {
  SeverityStatusCount,
  filterTaskGridRows,
  getGoalsByPathWay,
  getStatusCountByPathway,
} from '../CaseTaskGrid';
import { useEffect, useState } from 'react';
import { TaskByPathway } from '../TasksByPathway/TasksByPathway';
import { getTaskGridRows } from 'src/sections/crs/helpers/common';

export interface TaskFilters {
  selectedOwners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  status: TaskStatus[];
}
interface TasksGridProps extends GridFilterDrawerProps<TaskFilters> {
  tasks: TaskGridRowData[];
  owners: Reference[];
  isNestedGrid?: boolean;
  showPagination?: boolean;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  title?: string;
  taskBasedOn?: WrappedTask | null;
  onAssessmentSelect?: (task: Task) => void;
  pathwaysList?: TaskByPathway[];
  selectedPathway: string;
  handlePathwayTabChange: (event: React.SyntheticEvent, value: any) => void;
  includeSubTasks?: boolean;
}

export const statusMapping = {
  draft: 'in-progress',
  requested: 'in-progress',
  received: 'in-progress',
  accepted: 'in-progress',
  ready: 'in-progress',
  'in-progress': 'in-progress',
  'on-hold': 'in-progress',
};

export const initialTaskStatusFilter = [
  {
    code: 'draft',
    display: 'Draft',
  },
  {
    code: 'requested',
    display: 'Requested',
  },
  {
    code: 'received',
    display: 'Received',
  },
  {
    code: 'accepted',
    display: 'Accepted',
  },
  {
    code: 'ready',
    display: 'Ready',
  },
  {
    code: 'in-progress',
    display: 'In Progress',
  },
  {
    code: 'on-hold',
    display: 'On Hold',
  },
];

export const initialTaskFilters: TaskFilters = {
  selectedOwners: [],
  startDate: null,
  endDate: null,
  status: [...initialTaskStatusFilter],
};

const TasksGrid = ({
  tasks,
  owners,
  isNestedGrid,
  showPagination,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
  filterValues,
  onSuccessfulCreation,
  onSuccessfulEdit,
  patient,
  carePlan,
  goal,
  healthCareServices,
  title,
  taskBasedOn,
  onAssessmentSelect,
  pathwaysList,
  selectedPathway,
  handlePathwayTabChange,
  includeSubTasks = true,
}: TasksGridProps) => {
  const [taskGridRows, setTasksGridRows] = useState<TaskGridRowData[]>([]);
  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<TaskFilters>(
    filterValues,
    initialTaskFilters
  );

  const { i18n } = useLocales();

  const { sortModel, handleSortModelChange } = useClientGrid({
    defaultSort: [{ field: 'endDate', sort: 'asc' }],
  });

  const [
    {
      isTaskModalOpen,
      taskToEdit,
      taskToView,
      subTaskBasedOn,
      isTaskDetailDrawerOpen,
      isSearchMemberModalOpen,
      isAssignProgramModalOpen,
      addNoteToTask,
      isIframeModalOpen,
      urlData,
    },
    updateState,
  ] = useObjectState<{
    isTaskModalOpen: boolean;
    taskToEdit: WrappedTask | null;
    taskToView: WrappedTask | null;
    subTaskBasedOn: WrappedTask | null;
    isSearchMemberModalOpen: boolean;
    isAssignProgramModalOpen: boolean;
    isTaskDetailDrawerOpen: boolean;
    addNoteToTask: boolean;
    isIframeModalOpen: boolean;
    urlData: UrlData | null;
  }>({
    taskToEdit: null,
    taskToView: null,
    subTaskBasedOn: null,
    isTaskModalOpen: false,
    isSearchMemberModalOpen: false,
    isAssignProgramModalOpen: false,
    isTaskDetailDrawerOpen: false,
    addNoteToTask: false,
    isIframeModalOpen: false,
    urlData: null,
  });

  const { rowDblClickHandlers, basedOn } = useTaskContext();

  const { notes, isAllowedToEdit } = usePermissionsContext<TaskPermissions>() ?? {};

  const {
    valueSets: [taskStatuses],
  } = useValueSetsByIdentifiers(['ph-task-status']);

  const { enqueueSnackbar } = useSnackbar();

  const handleInternalUrlClick: OnInternalUrlClick = (urlData) => {
    updateState({ urlData, isIframeModalOpen: true });
  };

  const handleDateFilterChange =
    (dateProp: 'endDate' | 'startDate'): onDateChange =>
    (newValue) => {
      updateFilters({ [dateProp]: newValue });
    };

  const handleOwnerChange: onSelectResource = async (resource) => {
    updateState({ isSearchMemberModalOpen: false });
    const response = await updateTask(
      {
        ownerId: `${resource?.resourceType}/${resource?.id}`,
        ownerDisplay: getOwnerDisplayLabel(resource),
      },
      taskToEdit?.id as string
    );
    if (!response) {
      enqueueSnackbar('Unable to update Task Owner. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Task Owner Succesfully Updated');
    onSuccessfulEdit(response);
  };

  const handleOnTaskEdit = (task: WrappedTask) => {
    updateState({ taskToEdit: task, isTaskModalOpen: true });
  };

  const handleOnTaskCopy = (task: WrappedTask) => {
    delete task?.id;
    updateState({ taskToEdit: task, isTaskModalOpen: true });
  };

  const handleAddNoteToTask = (task: WrappedTask) => {
    updateState({ taskToEdit: task, addNoteToTask: true });
  };

  const handleTaskToProgram = async (task: WrappedTask) => {
    updateState({ isAssignProgramModalOpen: false });
    const response = await updateTask({ basedOn: task }, taskToEdit?.id as string);
    if (!response) {
      enqueueSnackbar('Unable to update task. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Task Succesfully Updated');
    onSuccessfulEdit(response);
  };

  const handleOpenTask = (task: WrappedTask | null) => {
    if (!task) return;
    const { handler } =
      rowDblClickHandlers?.find?.(({ taskType }) => taskType === task.getTaskType()) ?? {};
    if (handler) {
      handler(task);
      return;
    }
    updateState({ isTaskDetailDrawerOpen: true, taskToView: task });
  };

  const columns: GridColDef[] = [
    {
      field: 'task',
      headerName: `${i18n('patients.details.tasks.task', 'crs')}`,
      flex: 2,
      renderCell: (params) => {
        const { task, wrappedTask } = params.row as TaskGridRowData;
        return (
          <Box>
            <CustomLink
              to="#"
              onClick={() => {
                handleOpenTask(wrappedTask);
              }}
            >
              <CellRow
                tooltipTitle={i18n('tasks.taskNo', 'crs')}
                title={wrappedTask?.getInternalNumber?.()}
              />
            </CustomLink>

            <CellRow tooltipTitle={i18n('tasks.description', 'crs')} title={task} />
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: `${i18n('patients.details.tasks.status', 'crs')}`,
      flex: 1,
      renderCell: (params) => {
        const { status } = params.row as TaskGridRowData;
        return <CellRow shouldTruncateText={false} title={status} />;
      },
    },
    {
      field: 'owner',
      headerName: `${i18n('patients.details.tasks.owner', 'crs')}`,
      flex: 2,
      renderCell: (params) => {
        const { owner } = params.row as TaskGridRowData;
        return <CellRow shouldTruncateText={false} title={owner} />;
      },
    },
    {
      field: 'code',
      headerName: `${i18n('patients.details.tasks.code', 'crs')}`,
      flex: 1,
      renderCell: (params) => {
        const { codeDisplay } = params.row as TaskGridRowData;
        return <CellRow shouldTruncateText={false} title={codeDisplay} />;
      },
    },
    {
      field: 'endDate',
      headerName: `${i18n('patients.details.tasks.endDate', 'crs')}`,
      flex: 1,
      sortComparator: (_, __, cellParams1, cellParams2) => {
        const { date: date1 } = (cellParams1?.value as SeverityStatusDataWithDate) ?? {};
        const { date: date2 } = (cellParams2?.value as SeverityStatusDataWithDate) ?? {};
        return momentDateComparator(date1, date2);
      },
      renderCell: (params) => {
        const { endDate } = params.row as TaskGridRowData;
        return <SeverityStatus status={endDate} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        const { wrappedTask } = params.row as TaskGridRowData;
        return (
          <TaskActionMenu
            taskStatuses={taskStatuses?.asList?.() ?? []}
            taskExternal={wrappedTask}
            onEditTask={handleOnTaskEdit}
            onSearchMemberOpen={(task) => {
              updateState({ taskToEdit: task, isSearchMemberModalOpen: true });
            }}
            onSuccessfulEdit={onSuccessfulEdit}
            onAssignProgram={(task) => {
              updateState({ taskToEdit: task, isAssignProgramModalOpen: true });
            }}
            onCopyTask={handleOnTaskCopy}
            onAddNoteToTask={handleAddNoteToTask}
            onAssessmentSelect={onAssessmentSelect}
            onInternalUrlClick={handleInternalUrlClick}
            isNestedGrid={isNestedGrid}
            onSubTask={(task) => updateState({ subTaskBasedOn: task,  isTaskModalOpen: true })}
          />
        ); //TODO need to add functionality to open edit modal
      },
    },
  ];

  const { startDate, endDate, selectedOwners, status: selectedStatuses } = filters;

  useEffect(() => {
    setTasksGridRows(filterTaskGridRows(tasks, { filters: filterValues, searchTextFieldValue }));
  }, [tasks, filterValues, searchTextFieldValue]);

  return (
    <>
      {pathwaysList && (
        <Tabs
          scrollButtons={'auto'}
          variant={'scrollable'}
          value={selectedPathway}
          onChange={handlePathwayTabChange}
          aria-label="PathWay Tab"
          sx={{ ml: 2 }}
        >
          {pathwaysList?.map((pathway) => {
            const { code, id } = pathway;
            const getTasks = getTaskGridRows(
              getGoalsByPathWay(code, taskGridRows, id),
              taskStatuses?.asList?.() ?? []
            );
            const statuses = getStatusCountByPathway(getTasks);
            return (
              <Tab
                key={code}
                label={
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography sx={{ fontSize: '1em', whiteSpace: 'nowrap' }} variant="caption">
                      {code?.toUpperCase()}{' '}
                    </Typography>
                    {Object.entries(statuses).map(([severity, count]) => (
                      <SeverityStatusCount
                        key={severity}
                        count={count}
                        severity={severity as AlertSeverity}
                      />
                    ))}
                  </Box>
                }
                value={code}
              />
            );
          })}
        </Tabs>
      )}
      <CollapsibleDataGrid
        showExpandIcon={includeSubTasks}
        getRowHeight={() => 'auto'}
        addButtonTitle={i18n('patients.details.tasks.button', 'crs')}
        title={title ?? i18n('patients.details.tasks.title', 'crs')}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        showPagination={showPagination}
        onAddButtonClick={() => {
          updateState({
            isTaskModalOpen: true,
            taskToEdit: null,
          });
        }}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={
          taskBasedOn || basedOn
            ? taskGridRows
            : taskGridRows?.filter((x) => x?.basedOn?.length === 0)
        }
        columns={columns}
        getRowId={(row) => row.id}
        onRowDoubleClick={(params) => {
          const { wrappedTask } = params.row as TaskGridRowData;
          handleOpenTask(wrappedTask);
        }}
        renderCollapsibleContent={({ wrappedTask }: TaskGridRowData) => {
          if (!includeSubTasks) return null;
          const subTasks = tasks
            ?.filter((x) => x?.id === wrappedTask?.id)
            ?.map((r: TaskGridRowData) => r.subTasks as WrappedTask[])
            .flat();
          return (
            <CaseTaskGrid
              patient={patient}
              carePlan={carePlan}
              tasks={subTasks}
              taskBasedOn={wrappedTask}
              isNestedGrid={true}
              showPagination={false}
              goal={goal}
              onSuccessfulEdit={onSuccessfulEdit}
              onSuccessfulCreation={onSuccessfulCreation}
            />
          );
        }}
        subGridRowsExtractor={({ subTasks }: TaskGridRowData) => subTasks ?? []}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        isNestedGrid={isNestedGrid}
        Filters={
          <TaskFilter
            taskStatuses={taskStatuses?.asList?.() ?? []}
            owners={owners}
            startDate={startDate}
            endDate={endDate}
            selectedOwners={selectedOwners}
            selectedStatuses={selectedStatuses}
            onStartDateChange={handleDateFilterChange('startDate')}
            onEndDateChange={handleDateFilterChange('endDate')}
            onOwnerSelectionChange={(_, newValue) => {
              updateFilters({ selectedOwners: newValue });
            }}
            onStatusSelectionChange={(checkedItems) => {
              updateFilters({ status: [...checkedItems] });
            }}
          />
        }
        FilterDrawerProps={{
          title: 'Tasks Filters',
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />

      <TaskModal
        goal={goal}
        taskToEdit={taskToEdit as WrappedTask}
        taskBasedOn={taskBasedOn || subTaskBasedOn}
        onSuccessfulEdit={onSuccessfulEdit}
        onSuccessfulCreation={onSuccessfulCreation}
        patientExternal={patient}
        open={isTaskModalOpen}
        carePlan={carePlan}
        onClose={() => {
          updateState({
            isTaskModalOpen: false,
          });
        }}
      />
      <SearchMemberModal
        open={isSearchMemberModalOpen}
        onCancel={() => {
          updateState({ isSearchMemberModalOpen: false });
        }}
        patient={patient as WrappedPatient}
        onSelectResource={handleOwnerChange}
      />
      <Program
        healthCareServices={healthCareServices}
        open={isAssignProgramModalOpen}
        healthCareServiceRelated={taskToEdit?.basedOn as Reference[]}
        onCancel={() => {
          updateState({ isAssignProgramModalOpen: false });
        }}
        handleProgramAssigment={handleTaskToProgram}
      />
      <TaskDetailDrawer
        isAllowedToEdit={!!isAllowedToEdit}
        onEdit={(task) => {
          updateState({ isTaskDetailDrawerOpen: false });
          handleOnTaskEdit(task);
        }}
        open={isTaskDetailDrawerOpen}
        taskExternal={taskToView}
        goal={goal}
        onSuccesfulEdit={onSuccessfulEdit}
        onCloseIconButtonClick={() => {
          updateState({ isTaskDetailDrawerOpen: false });
        }}
      />
      <ModalCommunication
        isAllowedToAdd={!!notes?.isAllowedToAdd}
        open={addNoteToTask}
        patient={patient}
        onCancel={() => {
          updateState({ addNoteToTask: false });
        }}
        carePlan={carePlan}
        resource={taskToEdit}
        typeNote="notes_task"
      />
      <IframeModal
        open={isIframeModalOpen}
        url={urlData?.url}
        title={urlData?.label ?? ''}
        onClose={() => {
          updateState({ isIframeModalOpen: false, urlData: null });
        }}
      />
    </>
  );
};

export type OnOwnerSelectionChange = (event: React.SyntheticEvent, newValue: Reference[]) => void;
interface TaskFilterProps {
  owners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  selectedOwners: Reference[];
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  onOwnerSelectionChange: OnOwnerSelectionChange;
  onStatusSelectionChange: (checkedStatus: TaskStatus[]) => void;
  selectedStatuses: TaskStatus[];
  taskStatuses: TaskStatus[];
}

const TaskFilter = ({
  owners,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onOwnerSelectionChange,
  selectedOwners,
  onStatusSelectionChange,
  selectedStatuses,
  taskStatuses,
}: TaskFilterProps) => (
  <Box py={3}>
    <Box marginBottom={1}>
      <PeriodFilter
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
    </Box>

    <CheckboxList
      containerSx={{ marginBottom: 4, marginTop: 3 }}
      title="Status"
      keyExtractor={(item) => item.code as string}
      labelExtractor={(item) => item.display}
      onChange={onStatusSelectionChange}
      options={taskStatuses}
      externalCheckedItems={selectedStatuses}
    />
    <Box>
      <Typography mb={1} fontWeight={'bold'}>
        Owner
      </Typography>
      <Autocomplete
        value={selectedOwners}
        fullWidth
        multiple
        onChange={onOwnerSelectionChange}
        options={owners}
        getOptionLabel={(option) => option?.display || ''}
        defaultValue={[]}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Box>
  </Box>
);

export default TasksGrid;
