import { LoadingButton } from '@mui/lab';
import {
  Box,
  Fade,
  Modal,
  ModalProps,
  Typography,
  Backdrop,
  SxProps,
  Button,
  Grid,
  GridProps,
  Tooltip,
} from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';
import CancelButton from 'src/sections/crs/common/CancelButton';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface CustomModalBasicProps extends Pick<ModalProps, 'open' | 'onClose'> {}

export interface CustomModalProps
  extends Omit<ModalProps, 'slots' | 'slotProps' | 'closeAfterTransition'> {
  title: string;
  breadcrumbs: string[];
  onCancel: Function;
  onSave?: Function;
  saveButtonTitle?: string;
  saveButtonTooltip?: string;
  isLoading?: boolean;
  containerSx?: SxProps;
  showSaveButton?: boolean;
  showCancelButton?: boolean;
  isSaveButtonDisabled?: boolean;
  childrenWithoutPadding?: React.ReactNode;
  customActions?: React.ReactNode;
  showTitle?: boolean;
  showBreadcrumbs?: boolean;
}

const buttonStyles: SxProps = {
  height: 36,
};

const padding: SxProps = { paddingX: 3 };

const CustomModal = ({
  title,
  open,
  breadcrumbs,
  onSave,
  onCancel,
  children,
  isLoading,
  containerSx,
  showSaveButton = true,
  showCancelButton = true,
  childrenWithoutPadding = null,
  saveButtonTitle,
  saveButtonTooltip,
  isSaveButtonDisabled,
  customActions,
  showTitle = true,
  showBreadcrumbs = true,
  ...modalProps
}: CustomModalProps) => {
  const { i18n } = useLocales();
  return (
    <Modal
      closeAfterTransition
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      {...modalProps}
    >
      <Fade in={open}>
        <Box
          sx={[
            {
              position: 'absolute' as 'absolute',
              transform: 'translate(-50%, -50%)',
              width: '40vw',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '16px',
              paddingY: 3,
              height: 'auto',
              // overflow: 'scroll',
              overflow: 'none',
              maxHeight: '95vh',
            },
            ...spreadSxProp(containerSx),
          ]}
        >
          {showTitle || showBreadcrumbs ? (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={padding}>
              <Typography variant="h6">{title}</Typography>
              <Box
                sx={{ marginLeft: 1 }}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                {breadcrumbs.map((breadcrumbTitle, index) => (
                  <BreadCrumbTitle
                    key={breadcrumbTitle}
                    useSeparator={!!index}
                    title={breadcrumbTitle}
                  />
                ))}
              </Box>
              {!!customActions ? <Box sx={{ marginLeft: 'auto' }}>{customActions}</Box> : null}
            </Box>
          ) : null}

          {!!childrenWithoutPadding && <Box sx={{ width: '100%' }}>{childrenWithoutPadding}</Box>}
          <Box sx={{ width: '100%', ...padding }}>{children}</Box>
          <Box
            sx={padding}
            mt={4}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            {showCancelButton && (
              <CancelButton
                disabled={!!isLoading}
                onClick={() => {
                  onCancel?.();
                }}
              />
            )}
            {showSaveButton && (
              <Tooltip title={saveButtonTooltip ?? ''}>
                <span>
                  <LoadingButton
                    loading={!!isLoading}
                    disabled={!!isLoading || !!isSaveButtonDisabled}
                    sx={[buttonStyles, { width: 'auto' }]}
                    onClick={() => {
                      onSave?.();
                    }}
                    variant="contained"
                  >
                    {saveButtonTitle ?? i18n('saveChanges')}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export const gridItemColumnSize = 5.88;

export const GridSection = ({
  children,
  mt,
  spacing,
  ...gridProps
}: {
  children: React.ReactNode;
  mt?: number;
  spacing?: number;
} & GridProps) => (
  <Grid
    item
    mt={mt ?? 3}
    xs={12}
    display={'flex'}
    flexDirection={'row'}
    justifyContent={'space-between'}
    spacing={spacing}
    {...gridProps}
  >
    {children}
  </Grid>
);

export const GridItem = ({ children, xs, ...gridProps }: GridProps) => (
  <Grid item xs={xs ?? gridItemColumnSize} {...gridProps}>
    {children}
  </Grid>
);

export interface BreadCrumbTitleProps {
  title: string;
  useSeparator?: boolean;
}

export const BreadCrumbTitle = ({ title, useSeparator }: BreadCrumbTitleProps) => {
  const styles: SxProps = {
    color: '#919eab',
    mx: 0.4,
  };
  return (
    <>
      {!!useSeparator && <Typography sx={{ fontSize: '1.4rem', ...styles }}>•</Typography>}
      <Typography sx={{ fontSize: '0.8rem', ...styles }}>{title}</Typography>
    </>
  );
};

export default CustomModal;
