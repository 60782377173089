import {
  Alert,
  AlertColor,
  Box,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import useTable from 'src/hooks/useTable';
import { setStatus } from 'src/utils/fhir';
import Iconify from 'src/components/Iconify';
import Label from './Label';
import { getMimeIcon } from 'src/utils/getFileFormat';
import { referralService } from 'src/crs/referral/services';
import CloseIconButton from 'src/components/CloseIconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { getSeverityDueDateData } from '../sections/crs/helpers/getSeverityDueDateData';

const SummaryTable = ({ data, header, statusList }: any) => {
  const column = data?.length ? Object.keys(data[0]) : [];
  const [isModalSurveyOpen, setIsModalSurveyOpen] = useState(false);
  const [surveyTemplate, setSurveyTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const handleSurveyDetail = async (cell: any) => {
    setLoading(true);

    try {
      const responseId = cell.generic_id;
      const survey = await referralService.referralApiClient.surveyDetail(responseId);
      if (survey) {
        setSurveyTemplate(survey);
        setLoading(false);
        setIsModalSurveyOpen(true);
      } else {
        setLoading(false);
        setSurveyTemplate(null);
        setIsModalSurveyOpen(true);
      }
    } catch (err) {
      setSurveyTemplate(null);
      setIsModalSurveyOpen(true);
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              {loading == true && !header[0]?.id.previewassessments ? (
                <Box sx={{ display: 'block', position: 'absolute', top: '50%', left: '50%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <div></div>
              )}
              <TableHead>
                <TableRow>
                  {header?.map((col: any, index: number) => (
                    <TableCell key={`col-${index}`}>{col.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isEmpty(data) ? (
                <TableBody>
                  {data
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {


                      
                      return (
                      <TableRow style={{ cursor: 'pointer' }} key={`row-${index}`}>
                        {column.map((v: string) => {
                          if (v === 'generic_id') return null;

                          const severity: AlertColor = getSeverityDueDateData(moment((row && row[v] && row[v]?.data) ?? ''))
                          ?.severityStatus.severity as any;

                          return (
                            <TableCell
                              style={{ cursor: 'pointer' }}
                              key={`value-${v}`}
                              sx={{ fontWeight: 0 }}
                            >
                              <Label
                                color={
                                  (row[v] === 'completed' && 'success') ||
                                  (row[v] === 'in progress' && 'warning') ||
                                  (row[v] === 'Urgent Referral' && 'error') ||
                                  'default'
                                }
                              >
                                {(v === 'mimeType' && getMimeIcon(row[v])) ||
                                  (v === 'previewassessments' && (
                                    <IconButton onClick={() => handleSurveyDetail(row)}>
                                      <Iconify
                                        icon={'carbon:view'}
                                        sx={{ width: 28, height: 28 }}
                                      />
                                    </IconButton>
                                  )) ||
                                  (v === 'preview' && (
                                    <IconButton>
                                      <Iconify
                                        icon={'carbon:view'}
                                        sx={{ width: 28, height: 28 }}
                                      />
                                    </IconButton>
                                  )) ||
                                  (v === 'description' && (
                                    <Tooltip title={row[v]}>
                                      <Box sx={{ maxWidth: 200 }}>
                                        <Typography
                                          style={{ cursor: 'pointer' }}
                                          noWrap
                                          sx={{ fontSize: `0.75rem` }}
                                        >
                                          {row[v]}
                                        </Typography>
                                      </Box>
                                    </Tooltip>
                                  )) ||
                                  (v === 'status' && (
                                    <Typography
                                      style={{ cursor: 'pointer' }}
                                      noWrap
                                      sx={{ fontSize: `0.75rem` }}
                                    >
                                      {setStatus(statusList, row[v])}
                                    </Typography>
                                  )) ||
                                  (v === 'nextContact' &&
                                    (row[v]?.last ? (
                                      <Alert
                                        icon={false}
                                        severity={
                                          severity
                                        }
                                        sx={[
                                          {
                                            height: '30px',
                                            fontSize: '0.75rem',
                                            textTransform: 'capitalize',
                                            cursor: 'pointer',
                                            alignItems: 'center',
                                            padding: 1,
                                            ml: -1,
                                          },
                                        ]}
                                      >
                                        {getSeverityDueDateData(moment(row[v]?.data ?? null))
                                          ?.formattedDueDate ?? ''}
                                      </Alert>
                                    ) : (
                                      <Typography
                                        style={{ cursor: 'pointer' }}
                                        noWrap
                                        sx={{ fontSize: `0.75rem` }}
                                      >
                                        {getSeverityDueDateData(moment(row[v]?.data ?? null))
                                          ?.formattedDueDate ?? ''}
                                      </Typography>
                                    ))) || <div style={{ cursor: 'pointer' }}>{row[v]}</div>}
                              </Label>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )})}
                </TableBody>
              ) : (
                <TableBody>
                  <TableCell colSpan={header?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
        {!!surveyTemplate && (
          <Modal
            open={isModalSurveyOpen}
            onClose={() => setIsModalSurveyOpen(!isModalSurveyOpen)}
            className={!!surveyTemplate ? 'modal-full-center' : ''}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '80%', height: '80%' }}>
              <Box display={'flex'} justifyContent="flex-end">
                <CloseIconButton
                  sx={{ color: 'whitesmoke' }}
                  onClick={() => setIsModalSurveyOpen(false)}
                />
              </Box>
              {!!surveyTemplate && (
                <iframe
                  srcDoc={surveyTemplate}
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  title="survey"
                />
              )}
            </Box>
          </Modal>
        )}
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default SummaryTable;
