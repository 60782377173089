import { useEffect } from 'react';
import { Drawer, Box, DrawerProps, Typography, Divider, SxProps } from '@mui/material';
import CloseIconButton from './CloseIconButton';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface CustomDrawerProps extends DrawerProps {
  title: string;
  headerChildren?: React.ReactNode,
  onCloseIconButtonClick: Function;
  containerSx?: SxProps;
  contentContainerSx?: SxProps;
  showTitleDivider?: boolean;
}

export const px = 2.8;

const CustomDrawer = ({
  title,
  onCloseIconButtonClick,
  children,
  containerSx,
  contentContainerSx,
  headerChildren,
  showTitleDivider = true,
  ...props
}: CustomDrawerProps) => {
  useEffect(() => {
    const handleESCKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseIconButtonClick();
      }
    };

    window.addEventListener('keyup', handleESCKeyPress);
    return () => {
      window.removeEventListener('keyup', handleESCKeyPress);
    };
  }, [onCloseIconButtonClick]);

  return (
    <Drawer {...props}>
      <Box
        display={'flex'}
        flex={1}
        flexDirection={'column'}
        sx={[{ width: 320 }, ...spreadSxProp(containerSx)]}
      >
        <Box py={1} px={px}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography alignSelf={'center'} variant="h6">
              {title}
            </Typography>
            {headerChildren}
            <CloseIconButton
              onClick={(_) => {
                onCloseIconButtonClick();
              }}
            />
          </Box>
        </Box>
        {showTitleDivider && <Divider />}
        <Box
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          px={px}
          sx={[...spreadSxProp(contentContainerSx)]}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
