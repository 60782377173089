import { QueryDocumentSnapshot } from 'firebase/firestore';
import moment from 'moment';
import { FilterDrawerProps } from 'src/components/FilterDrawer';
import {
  Address,
  ContactPoint,
  HumanName,
  Identifier,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export enum UserType {
  ADMIN = 'admin',
  SYSTEM = 'system',
  PERSON = 'person',
}

export interface BaseFirebaseEntity {
  id: string;
  updatedBy?: string | null;
  updatedOn?: Timestamp | null;
  createdBy?: string | null;
  createdOn?: Timestamp | null;
  deletedBy?: string | null;
  deletedOn?: Timestamp | null;
}
export interface User extends BaseFirebaseEntity {
  email?: string;
  notifications_tokens?: any[];
  owned_person?: UserPerson | null;
  persons?: UserPerson[];
  phone_number?: PhoneNumber;
  profile_picture?: string | null;
  user_type?: UserType;
  username?: string;
}

export interface Person extends BaseFirebaseEntity, PersonBasicData {
  data_systems?: PersonDataSystem[] | null;
  address?: Address[] | null;
  photo?: any[] | null;
  status?: string | null;
  profilePicture?: string | null;
  gender?: string | null;
  identifier?: Identifier[] | null;
  telecom?: ContactPoint[] | null;
  isActive?: boolean | null;
}

export interface PersonDataSystem {
  based_on?: string | null;
  url?: string | null;
  token?: string | null;
  name?: string | null;
}
export interface PhoneNumber {
  country_code: string;
  phone_number: string;
  phone_number_extension: string;
}

export interface PersonBasicData {
  name?: HumanName[] | null;
  birthDate?: string | null;
}

export interface UserPerson {
  reference: string;
  type: string;
  personBasicData: PersonBasicData;
}

export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export interface Pagination {
  page: number;
  rowsPerPage: number;
}

export interface Sorting {
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
}

export interface GridQueryState extends Pagination, Sorting {
  startAfter?: QueryDocumentSnapshot | undefined;
}

export interface PaginationOptions extends Partial<Sorting> {
  startAfter?: QueryDocumentSnapshot;
  limit?: number;
}

export type FirebaseCollectionData<T> = [T[], QueryDocumentSnapshot<T>[]];

export type FilterDrawerListProps = Omit<FilterDrawerProps, 'title' | 'anchor'>;

export interface DateRange {
  start?: moment.Moment | null;
  end?: moment.Moment | null;
}

export enum MutationActions {
  ADD = 'add',
  MODIFY = 'modify',
  DELETE = 'delete',
}

export enum PersonStatus {
  AUTHORIZED = 'authorized',
  PENDING = 'pending',
}
