import { Box, SxProps, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface InputContainerProps {
  label: string;
  children: React.ReactNode;
  containerSx?: SxProps;
}

const InputContainer = ({ label, children, containerSx }: InputContainerProps) => (
  <Box sx={[{ marginBottom: 2.4 }, ...spreadSxProp(containerSx)]}>
    <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1.3, fontSize: '0.95rem' }}>
      {label}
    </Typography>
    {children}
  </Box>
);

export default InputContainer;
