import { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import { GridRowId, GridColDef, GridRowProps, GridRow } from '@mui/x-data-grid';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import DataGridWithFilters, {
  DataGridWithFiltersProps,
  OnVisibleRowsChange,
} from './DataGridWithFilters/DataGridWithFilters';
import { spreadSxProp } from 'src/utils/cssStyles';
import { createPortal } from 'react-dom';

export type RenderCollapsibleContent = (row: any) => React.ReactNode;

export interface CollapsibleDataGridProps extends DataGridWithFiltersProps {
  renderCollapsibleContent: RenderCollapsibleContent;
  subGridRowsExtractor?: (row: any) => any[];
  rowHeight?: number;
  noOfSubGrids?: number;
  showExpandIcon?: boolean;
}

const CollapsibleDataGrid = ({
  renderCollapsibleContent,
  components,
  dataGridSx,
  columns,
  rows,
  subGridRowsExtractor,
  rowHeight,
  onVisibleRowsChange: _,
  noOfSubGrids = 1,
  showExpandIcon = true,
  isNestedGrid,
  ...dataGridProps
}: CollapsibleDataGridProps) => {
  const [openRows, setOpenRows] = useState<GridRowId[]>([]);

  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);

  const height = useMemo(() => {
    if (!openRows.length) return 0;
    const dynamicRowHeight = rowHeight ? rowHeight : 80;

    const initialHeight = 80 * noOfSubGrids;
    let height = initialHeight;
    height += (visibleRows.length + 1) * dynamicRowHeight * (isNestedGrid ? 5 : 1);
    for (const rowId of visibleRows) {
      if (!openRows.includes(rowId)) continue;
      const row = rows.find(({ id }) => id === rowId);
      if (!row) continue;
      let noOfRows = subGridRowsExtractor?.(row)?.length ?? 0;
      noOfRows += 1;
      height += initialHeight + noOfRows * dynamicRowHeight;
    }
    return height;
  }, [rows, visibleRows, openRows, rowHeight, noOfSubGrids]);

  const toggleRow = (id: string) => {
    setOpenRows((openRows) => {
      if (openRows.includes(id)) {
        return openRows.filter((openRow) => openRow !== id);
      }
      return [...openRows, id];
    });
  };

  const onVisibleRowsChange: OnVisibleRowsChange = useCallback((visibleRows) => {
    setVisibleRows(visibleRows);
  }, []);

  const gridColumns: GridColDef[] = [
    {
      field: 'expand',
      headerName: '',
      align: 'center',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const { dontCollapsible } = params.row;

        return (
          <>
            {dontCollapsible !== undefined || !Boolean(showExpandIcon) ? (
              <></>
            ) : (
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => {
                  toggleRow(params?.row?.id);
                }}
              >
                {openRows.includes(params?.row?.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </>
        );
      },
    },
    ...columns,
  ];

  return (
    <DataGridWithFilters
      rows={rows}
      columns={gridColumns}
      autoHeight={!height}
      onVisibleRowsChange={onVisibleRowsChange}
      components={{
        Row: (rowProps: GridRowProps) => (
          <CollapsibleRow
            rowProps={rowProps}
            openRows={openRows}
            renderCollapsibleContent={renderCollapsibleContent}
          />
        ),
        ...components,
      }}
      isNestedGrid={isNestedGrid}
      dataGridSx={[{ height: !height ? 'auto' : height }, ...spreadSxProp(dataGridSx)]}
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 90}
      {...dataGridProps}
    />
  );
};

interface CollapsibleRowProps {
  rowProps: GridRowProps;
  openRows: GridRowId[];
  renderCollapsibleContent: RenderCollapsibleContent;
}

const CollapsibleRow = ({ rowProps, openRows, renderCollapsibleContent }: CollapsibleRowProps) => {
  const { row } = rowProps;
  const isOpen = openRows.includes(row?.id as GridRowId);

  const element = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <GridRow
        style={{ flexWrap: 'wrap', alignContent: 'flex-start', width: '100%' }}
        ref={element}
        {...rowProps}
      />
      {element.current && isOpen
        ? createPortal(
            <Collapse sx={{ width: '100%', height: '100%' }} in={isOpen} timeout="auto">
              <Box px={3}>{renderCollapsibleContent(row)}</Box>
            </Collapse>,
            element.current
          )
        : null}
    </>
  );
};

export default CollapsibleDataGrid;
