import uuidv4 from "src/utils/uuidv4";
import { IApiClient } from "src/api/clients/api_client";
import { convertFilterToPaginateQueryParams } from "src/utils/gridView";
import { PaginateQuery, paginateQueryToURLParams } from "src/api/pagination/dtos";

export class IncidentDto {
  id: number;

  uuid: string;

  createdBy: string | null;

  createdOn: string;

  updatedBy: string | null;

  updatedOn: string | null;

  deletedOn: string | null;

  deletedBy: string | null;

  status: IncidentStatus;

  assigned_to: string | null;

  assigned_on: string | null;

  reason_code: string | null;

  reason_text: string | null;

  scope_code: string;

  scope_reason: string;

  scope_text: string;

  scope_status: string | null;

  scope_data: any | null;
  
  source_type: string;

  source_reference_type: string;

  source_reference_name: string | null;

  source_reference_value: string;

  source_reason: string;

  source_data: string;

  disposition_by: string | null;

  disposition_on: Date | null;

  disposition_outcome: string | null;

  disposition_note: string | null;
  
  disposition_data: string | null;

  fromIncidentResponse(dto: IncidentResponse) {
    this.id = dto.id;
    this.uuid = dto.uuid;
    this.createdBy = dto.createdBy;
    this.createdOn = dto.createdOn;
    this.updatedBy = dto.updatedBy;
    this.updatedOn = dto.updatedOn;
    this.deletedOn = dto.deletedOn;
    this.deletedBy = dto.deletedBy;
    this.status = dto.status;
    this.assigned_to = dto.assigned_to;
    this.assigned_on = dto.assigned_on;
    this.reason_code = dto.reason_code;
    this.reason_text = dto.reason_text;
    this.scope_code = dto.scope_code;
    this.scope_reason = dto.scope_reason;
    this.scope_text = dto.scope_text;
    this.scope_status = dto.scope_status;
    this.scope_data = dto.scope_data;
    this.source_type = dto.source_type;
    this.source_reference_type = dto.source_reference_type;
    this.source_reference_name = dto.source_reference_name;
    this.source_reference_value = dto.source_reference_value;
    this.source_reason = dto.source_reason;
    this.source_data = dto.source_data;
    this.disposition_by = dto.disposition_by;
    this.disposition_on = dto.disposition_on;
    this.disposition_outcome = dto.disposition_outcome;
    this.disposition_note = dto.disposition_note;
    this.disposition_data = dto.disposition_data;
  }
}

export interface IncidentResponse {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  status: IncidentStatus;
  assigned_to: string | null;
  assigned_on: string | null;
  reason_code: string | null;
  reason_text: string | null;
  scope_code: string;
  scope_reason: string;
  scope_text: string;
  scope_status: string | null;
  scope_data: any | null;
  source_type: string;
  source_reference_type: string;
  source_reference_name: string | null;
  source_reference_value: string;
  source_reason: string;
  source_data: any | null;
  disposition_by: string | null;
  disposition_on: Date | null;
  disposition_outcome: string | null;
  disposition_note: string | null;
  disposition_data: string | null;
  unlink?: any;
}

export enum ScopeReasonType {
  PCP_ASSIGNMENT_GROUP_REASON = "nat.response.pcp.assigment",
  INVALID_ADDRESS = "invalid.address"
}

export enum IncidentStatus {
  IN_PROGRESS = "In-Progress",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  NEW = "New",
}

export const pcpAssignmentIncidentScopeCodes = {
  PCP_MULTI_MATCH: "nat.task.response.pcp_multi_match",
  HAVE_NO_PCP: "nat.task.response.have_no_pcp",
  MULTIPLE_PCP: "nat.task.response.multiple_pcp",
};

export interface PaginatedIncidentResponse {
  data: IncidentResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedIncidentDto {
  data: IncidentDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export class IncidentApiClient {
  apiBase: string;

  incidentApiClient: IApiClient;

  constructor(incidentApiClient: IApiClient){
    this.apiBase = 'data-quality/incident';
    this.incidentApiClient = incidentApiClient;
  }

  async getAll(
    queryParams: PaginateQuery,
  ): Promise<PaginatedIncidentResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);
    const filters = convertFilterToPaginateQueryParams(queryParams?.filter ?? {});
    const url = `${this.apiBase}/?${urlParams.toString()}${filters}`;
    
    try {
      const response = await this.incidentApiClient.get<PaginatedIncidentResponse | undefined>(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(uudi: string): Promise<IncidentResponse | undefined> {
    try {
      const response = await this.incidentApiClient.get<IncidentResponse | undefined>(
        `${this.apiBase}/${uudi}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getMatchService(payload: any): Promise<any> {
    try {
      const response = await this.incidentApiClient.post<any, undefined>(
        `${this.apiBase}/matchservice`, 
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async updateIncident( 
    incidentId: number | null,
    incident: Partial<IncidentResponse>
  ): Promise<IncidentResponse>{

    const existingLinkedRecords = incident.scope_data?.linked_records ?? [];
    const uuid = uuidv4();
    
    if (incident.source_reference_name === 'do_forms') {
      if (
        incident.scope_data?.linked_record_uuid != '' &&
        incident.scope_data?.linked_record_new == ''
      ) {
        incident.scope_data = JSON.stringify({
          matchard_records: incident.scope_data?.matchard_records ?? [],
          linked_record_uuid: `${incident.scope_data?.linked_record_uuid}`,
          linked_records: [...existingLinkedRecords],
        });
      } else {
        if (
          incident.scope_data?.linked_record_uuid == '' &&
          incident.scope_data?.linked_record_new == '' &&
          incident?.unlink
        ) {
          incident.scope_data = JSON.stringify({
            matchard_records: incident.scope_data?.matchard_records ?? [],
            linked_record_uuid: '',
            linked_records: [...existingLinkedRecords],
          });
        } else {
          incident.scope_data = JSON.stringify({
            matchard_records: incident.scope_data?.matchard_records ?? [],
            linked_record_uuid: `${uuid}`,
            linked_records: [
              {
                uuid: `${uuid}`,
                createdBy: '',
                createdOn: '',
                updatedBy: '',
                updatedOn: '',
                deletedBy: '',
                deletedOn: '',
                record: incident.scope_data?.linked_record_new,
              },
              ...existingLinkedRecords,
            ],
          });
        }
      }
    };

    const response = await this.incidentApiClient.put<any, IncidentResponse >(
      `${this.apiBase}/${incidentId}`,
      {
        ...incident,
        scope_data: incident.scope_data
          ? JSON.stringify(incident.scope_data)
          : incident.scope_data,
        source_data: incident.source_data
          ? JSON.stringify(incident.source_data)
          : incident.source_data,
        disposition_data: incident.disposition_data
          ? JSON.stringify(incident.disposition_data)
          : incident.disposition_data,
      }
    );
    return response!;
  }

  async bulkAssign(payload: any): Promise<IncidentResponse | undefined> {
    try {
      const response = await this.incidentApiClient.post<any, undefined>(
        `${this.apiBase}/bulkAssign`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class IncidentService {
  incidentApiClient: IncidentApiClient;

  constructor(incidentApiClient: IncidentApiClient){
    this.incidentApiClient = incidentApiClient;
  }

  async getAll(
    queryParams: PaginateQuery,
  ): Promise<PaginatedIncidentResponse | undefined> {
    const incidents = await this.incidentApiClient.getAll(queryParams);
    if(!incidents) return undefined;
    const dtos = incidents.data.map((i) => {
      const dto = new IncidentDto();
      dto.fromIncidentResponse(i);
      return dto;
    });
    const mapped: PaginatedIncidentDto = { ...incidents, data: dtos };
    return mapped;
  }

  async getOne(
    uuid: string,
  ): Promise<IncidentResponse | undefined> {
    const incident = await this.incidentApiClient.getOne(uuid);
    if(!incident) return undefined;
    const incidentDto = new IncidentDto();
    incidentDto.fromIncidentResponse(incident);
    return incidentDto;
  }

  async getMatchService(payload: any): Promise<any>{
    const matchservice = await this.incidentApiClient.getMatchService(payload);
    if(!matchservice) return undefined;
    return matchservice;
  }

  async updateIncident( 
    incidentId: number | null,
    incident: Partial<IncidentResponse> | null
  ): Promise<IncidentResponse | null>{
    if (!incident || !incidentId) return null;
    const response = await this.incidentApiClient.updateIncident(incidentId, incident);
    if(!response) return null;
    return response;
  }

  async bulkAssign(payload: any): Promise<any | undefined> {
    const bulkAssign = await this.incidentApiClient.bulkAssign(payload);
    if(!bulkAssign) return undefined;
    return bulkAssign;
  }
}