import { yupResolver } from '@hookform/resolvers/yup';
import { MobileDatePicker, MobileDateTimePicker } from '@mui/lab';
import {
  Backdrop,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  ContactAttemptOutcome,
  PatientEngagementContactAttemptDto,
} from 'src/engagement/patient/dto/patient-engagment-contact-attempt.dto';
import useAuth from 'src/hooks/useAuth';
import * as Yup from 'yup';

type ConfirmContactProps = {
  patient: WrappedPatient | null;
  patientEngagementId: string;
  isOpen: boolean;
  handlerIsOpen: Function;
  handlePatient: (data: any) => Promise<any>;
  handleConfirmContactAttempt: (data: any) => Promise<any>;
  refreshPatient: () => Promise<any>;
  checkListData: any;
};

export default function ConfirmContact({
  patient,
  patientEngagementId,
  isOpen,
  handlerIsOpen,
  handlePatient,
  handleConfirmContactAttempt,
  refreshPatient,
  checkListData,
}: ConfirmContactProps) {
  const nextContactOnIsRequired: boolean = useMemo(() => {
    if (!checkListData) {
      return false;
    }

    try {
      const metadata = JSON.parse(checkListData.metadata);
      const nextContactOnIsRequiredVaue = metadata.ui.data.nextContactOnIsRequired;

      if (typeof nextContactOnIsRequiredVaue === 'boolean') {
        return nextContactOnIsRequiredVaue;
      }
    } catch (err) {
      return false;
    }

    return false;
  }, [checkListData]);

  const user = useAuth().getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<any>(false);
  const [outcomeList] = useValueSet('ph-outcome-contact-form', { map: ValueSetWrapper });

  useEffect(() => {
    if (isOpen)
      reset({
        contactOn: '',
        nextContactOn: '',
        outcome: '',
        duration: '',
        reasonNote: '',
      });
  }, [isOpen]);

  const yupSchemaShape = useMemo(() => {
    let shape = {
      contactOn: Yup.date()
        .required('Contact On is required')
        .nullable()
        .transform((curr, orig) => (moment(orig).isValid() ? curr : null)),
      nextContactOn: Yup.date()
        .nullable()
        .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
        .min(Yup.ref('contactOn'), "Contact On can't be greater than Next Contact On"),
      outcome: Yup.string().required('Outcome is required'),
      duration: Yup.string().required('Duration is required'),
      reasonNote: Yup.string().required('Note is required'),
    };

    if (nextContactOnIsRequired) {
      shape.nextContactOn = shape.nextContactOn.required('nextContactOn is required');
    }

    return shape;
  }, [checkListData]);

  const schema = Yup.object().shape(yupSchemaShape);

  const methods = useForm({ resolver: yupResolver(schema) });

  const { control, reset, handleSubmit } = methods;

  const mapFormDataToCommunication = (data: any): PatientEngagementContactAttemptDto => {
    const { contactOn, nextContactOn, outcome, duration, reasonNote } = data;

    console.log('DEBUG nextContactOn: ', nextContactOn);

    const payload = {
      typeNote: 'Contact Note',
      reasonNote: reasonNote,
      outcome: outcome as ContactAttemptOutcome,
      id: Number(patientEngagementId),
      contactOn: new Date(contactOn).toISOString(),
      ...(nextContactOn && { nextContactOn: new Date(nextContactOn).toString() }),
      duration: duration,
    };

    console.log('DEBUG mapFormDataToCommunication payload: ', payload);

    return payload;
  };

  const onSubmit = async (values: any) => {
    setOpenBackdrop(true);
    setSaveBtnDisabled(true);
    try {
      const resultMap = mapFormDataToCommunication(values);
      console.log("DEBUG resultMap: ", resultMap);
      await handleConfirmContactAttempt(resultMap);
    } catch (error) {}
    setSaveBtnDisabled(false);
    handlerIsOpen(false);
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <List component="nav">
          <ListItem>
            <Grid width={800} container justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => {
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
              <Grid container spacing={3} sx={{ marginTop: 5 }}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Contact On*
                  </Typography>
                  <Controller
                    name="contactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDateTimePicker
                        {...field}
                        label="Contact On"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Next Contact On
                  </Typography>
                  <Controller
                    name="nextContactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDatePicker
                        {...field}
                        label="Next Contact On"
                        minDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Outcome*
                  </Typography>
                  <RHFSelect name="outcome" label="" fullWidth={true}>
                    {outcomeList?.asList().map((option: any) => (
                      <MenuItem key={option.code} value={option.display}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Duration*
                  </Typography>
                  <RHFTextField name="duration" label="" />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Add Note*
                  </Typography>
                  <RHFTextField name="reasonNote" label="Add Note" multiline rows={4} />
                </Grid>
                <Grid item md={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="success"
                      disabled={saveBtnDisabled}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </FormProvider>
    </Drawer>
  );
}
