export const itsCodeableConcept = (resource: any, codeArray: Array<string>): boolean => {
    const codes = resource?.code?.coding?.map((coding: any) => coding.code);
    if (codeArray.some((code) => codes?.includes(code))) {
        return true;
    }
    return false;
}



