import { Skeleton } from '@mui/material';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useMemo } from 'react';

const Map = ({ children, center, position }: any) => {
  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(JsOptions);
  const mapContainerStyle = {
    height: '100%',
    position: position ? position : 'initial',
  };

  return isLoaded ? (
    <GoogleMap
      zoom={9}
      center={
        center?.lat !== '' && !isNaN(center?.lat) && center?.lng !== '' && !isNaN(center?.lng)
          ? center
          : { lat: 38.8335633, lng: -77.1595717 }
      }
      mapContainerStyle={mapContainerStyle}
    >
      {children}
    </GoogleMap>
  ) : (
    <Skeleton height={400} width={400} />
  );
};

export const getLine = (addressArray: any) => {
  let street = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (
      (addressArray[i].types[0] && 'street_number' === addressArray[i].types[0]) ||
      'route' === addressArray[i].types[0]
    ) {
      street = street + addressArray[i].long_name + ' ';
    }
  }
  return street;
};

export const getCity = (addressArray: any) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const getState = (addressArray: any) => {
  let state = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
      state = addressArray[i].long_name;
      return state;
    }
  }
};

export const getDistrict = (addressArray: any) => {
  let district = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
      district = addressArray[i].long_name;
      return district;
    }
  }
};

export const getPostalCode = (addressArray: any) => {
  let postal = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
      postal = addressArray[i].long_name;
      return postal;
    }
  }
};

export const getCountry = (addressArray: any) => {
  let country = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
      country = addressArray[i].long_name;
      return country;
    }
  }
};

export default Map;
