import _ from 'lodash';
import 'moment-timezone';
import { widgets } from '@ui-schema/ds-material';
import { UIMetaProvider } from '@ui-schema/ui-schema/UIMeta';
import { storeUpdater } from '@ui-schema/ui-schema/storeUpdater';
import { isInvalid } from '@ui-schema/ui-schema/ValidityReporter';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { relTranslator } from '@ui-schema/ui-schema/Translate/relT';
import { GridContainer } from '@ui-schema/ds-material/GridContainer';
import { createOrderedMap } from '@ui-schema/ui-schema/Utils/createMap';
import { injectPluginStack } from '@ui-schema/ui-schema/applyPluginStack';
import { UIStoreProvider, createStore } from '@ui-schema/ui-schema/UIStore';

const GridStack = injectPluginStack(GridContainer);

type Props = {
  dataSchema: any;
  data: any;
  edit: any;
  defaultStructure: any;
  handleComplementaryInfo: (store: any) => Promise<any>;
};

const ComplementaryInfoEdit = ({
  dataSchema,
  data,
  edit,
  defaultStructure,
  handleComplementaryInfo
}: Props) => {
  const { scope = {} } = data;
  const { checklistItem = {} } = scope;

  const [showValidity, setShowValidity] = useState(false);
  const formattedData = _.merge(checklistItem, defaultStructure);
  const schema: any = createOrderedMap(dataSchema);
  const [store, setStore] = useState<any>(() => createStore(createOrderedMap(checklistItem)));

  useEffect(() => {
    setStore(createStore(createOrderedMap(updatedData)));
  }, [data]);

  const updatedData = useMemo(() => formattedData, [data]);

  const onChange = useCallback(
    (actions) => {
      setStore(storeUpdater(actions));
    },
    [setStore]
  );

  useEffect(() => {
    if(edit){
      if(isInvalid(store.getValidity())){
        setShowValidity(true)
      }else{
        handleComplementaryInfo(store.valuesToJS())
      }
    }
  }, [edit])

  return (
    <>
      <UIMetaProvider widgets={widgets} t={relTranslator}>
        <UIStoreProvider store={store} onChange={onChange} showValidity={showValidity}>
          <GridStack isRoot schema={schema} />
        </UIStoreProvider>
      </UIMetaProvider>
    </>
  );
};

export default ComplementaryInfoEdit;
