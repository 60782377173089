import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { AddLink } from "@mui/icons-material";
import CloseIconButton from "src/components/CloseIconButton";
import { PCPAssignmentScopeData } from "src/@types/crs/incident";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField, Typography } from "@mui/material";
import { IncidentResponse } from "src/services/api/data-quality/IncidentService";

export interface ResolutionConfirmationDialogProps extends DialogProps {
  patient: WrappedPatient | null;
  incident: IncidentResponse | undefined;
  onClose: () => void;
  onAccept: (notes: string) => Promise<void>;
  isLoading: boolean;
}

const ResolutionConfirmationDialog = ({
  patient,
  incident,
  onClose,
  onAccept,
  isLoading,
  ...dialogProps
}: ResolutionConfirmationDialogProps) => {
  const [dispositionNotes, setDispositionNotes] = useState("");
  const {
    linkedProviderId,
    linkedPractitionerRoleId,
    linkedProviders,
    linkedPractitionerRoles,
    linkedPractitionerId,
    linkedPractitioners,
  } = (incident?.scope_data ?? {}) as PCPAssignmentScopeData;
  
  const linkedProvider = linkedProviders?.find?.(
    ({ id }) => id === linkedProviderId
  );
  const linkedPractitionerRole = linkedPractitionerRoles?.find?.(
    ({ id }) => id === linkedPractitionerRoleId
  );
  const linkedPractitioner = linkedPractitioners?.find?.(
    ({ id }) => id === linkedPractitionerId
  );

  const resource = linkedProvider ?? linkedPractitioner;
  const fullName = `${resource?.firstName ?? ""} ${resource?.lastName ?? ""}`.trim();

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm PCP Incident Resolution
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingX: 3.4, marginTop: 3 }}>
          {!!(patient && resource && linkedPractitionerRole) && (
            <>
              <Typography sx={{ marginBottom: 2 }}>
                Are you sure you want to assign:
              </Typography>
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  {fullName}
                </span>
              </Typography>
              <Typography sx={{ marginY: 1 }}>as: </Typography>
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  {linkedPractitionerRole.role?.display ?? ""}{" "}
                </span>
              </Typography>
              {!!linkedPractitionerRole?.organization?.name && (
                <Typography>
                  Organization:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {linkedPractitionerRole?.organization?.name}{" "}
                  </span>
                </Typography>
              )}
              <Typography sx={{ marginTop: 2 }}>
                to Patient:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {patient.getFullName()}
                </span>
              </Typography>
              <Box sx={{ marginTop: 3, width: "100%" }}>
                <TextField
                  fullWidth
                  label="Disposition Notes"
                  variant="outlined"
                  value={dispositionNotes}
                  onChange={(event) => {
                    setDispositionNotes(event.target.value);
                  }}
                  sx={{
                    height: 90,
                    "& .MuiInputBase-input": {
                      height: 80,
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                />
              </Box>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          startIcon={<AddLink />}
          onClick={() => {
            onAccept(dispositionNotes);
          }}
          variant="contained"
        >
          Accept
        </LoadingButton>
        <CloseIconButton onClick={onClose} autoFocus />
      </DialogActions>
    </Dialog>
  );
};

export default ResolutionConfirmationDialog;
