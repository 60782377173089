import * as React from 'react';
import Box from '@mui/material/Box';
import InputMask from 'react-input-mask';
import {
  DataGrid,
  GridRowParams,
  GridRowModesModel,
  GridEventListener,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
  GridRowModel,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
  GridRenderEditCellParams,
  useGridApiContext,
  GridEditInputCell,
} from '@mui/x-data-grid';

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {
  Alert,
  AlertProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { GridColumns, MuiEvent } from '@mui/x-data-grid';
import {
  Identifier,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import CancelIcon from '@mui/icons-material/Close';
import { spreadSxProp } from 'src/utils/cssStyles';
import moment from 'moment';
import { isEmpty, isNil, upperCase } from 'lodash';
import * as Yup from 'yup';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface IdentifierDetailsProps {
  catalog: ValueSetComposeIncludeConcept[];
  identifiers: Identifier[];
  hiddenIdentifiers?: Identifier[];
  onUpdateIdentifiers: (newIdentifiers: Identifier[]) => void;
  isAllowedToEdit: boolean;
  isAllowedToAdd: boolean;
  isAllowedToDelete: boolean;
  sxContainer?: SxProps;
}

export function IdentifierDetails({
  catalog,
  identifiers,
  onUpdateIdentifiers,
  sxContainer,
  isAllowedToEdit,
  isAllowedToAdd,
  isAllowedToDelete,
  hiddenIdentifiers,
}: IdentifierDetailsProps) {
  const { i18n } = useLocales();
  const { componentsData } = useTenantConfigData();
  const { showMedicaID } = componentsData ?? {};

  const [disabledadd, setDisabledAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState('');

  const [newrow, setNewRow] = React.useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = React.useState(false);
  const [newRowModel, setNewRowModel] = React.useState<GridRowModel | null>(null);
  const [shouldPromptDuplicateWarning, setShouldPromptDuplicateWarning] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const handleDuplicateDialogClose = () => {
    setIsDuplicateDialogOpen(false);
  };

  const saveDeleteIdentifier = () => {
    setRows(rows.filter((row: any) => row.type != idDelete));
    handleClose();
    setSave(true);
  };
  const saveId = (id: any) => {
    const typeModal = rows.filter((row: any) => row.id === id)[0].type;
    setIdDelete(typeModal);
    handleOpen();
  };

  const FilterCat = (cat: any) => {
    for (let i = 0; i < rows.length; i++) {
      const filter = rows[i].type;

      if (cat.includes(filter)) {
        cat = cat.filter((cas: any) => cas != filter);
      }
    }
    return cat;
  };

  const stylemodal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)',
    p: 4,
  };

  const FilterIdent = (identifiers: any) => {
    const identifiersList = identifiers.filter(
      (identifier: any) => !hiddenIdentifiers?.includes?.(identifier)
    );
    const filteredIdentifiers = identifiersList
      ?.map((identifier: any, index: any) => {
        const ident = { ...identifier };
        if (!ident?.system) return null;
        const code = ident.type?.coding?.[0]?.code;
        if (typeof ident.type == 'object' && ident.type != ident.text) {
          ident.text = ident.type?.text;
          ident.type = ident.type?.text;
          if (code) ident.code = code;
        } else {
          if (ident.type != ident.text) {
            if (catalog?.filter((word: any) => word.code === ident.system).length > 0) {
              ident.type = catalog?.filter((word: any) => word.code === ident.system)[0].display;
            } else {
              ident.type = ident.system;
            }
          } else {
            if (!ident.hasOwnProperty('type')) {
              if (catalog?.filter((word: any) => word.code === ident.system).length > 0) {
                ident.type = catalog?.filter((word: any) => word.code === ident.system)[0].display;
              } else {
                ident.type = ident.system;
              }
            }
          }
        }
        ident.id = ident.system;
        return ident;
      })
      .filter((identifier: any) => !!identifier);
    if (identifiers == undefined) {
      return [];
    }
    return filteredIdentifiers;
  };

  const [rows, setRows] = React.useState(FilterIdent(identifiers));
  const [editmoderow, setEditModerow] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleSaveClick =
    (id: GridRowId, shouldPromptDuplicateWarning = true) =>
    () => {
      setSnackbar(null);
      setShouldPromptDuplicateWarning(shouldPromptDuplicateWarning);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

  const handleCancelClick = (id: GridRowId) => () => {
    setSnackbar(null);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }

    setDisabledAdd(false);
    setNewRow(false);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setDisabledAdd(true);
    setEditModerow(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const schema = Yup.object({
    type: Yup.string().required('Type is required'),
    value: Yup.string()
      .required('Value is required')
      .when('type', {
        is: 'Medicaid ID',
        then: Yup.string().matches(/^\d{9}$/, 'Must contain exactly 9 numbers'),
        otherwise: Yup.string().when('type', {
          is: 'Social Security Number(SSN)',
          then: Yup.string().matches(/^\d{9}$/, 'Must contain exactly 9 numbers'),
        }),
      }),
    end: Yup.date()
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
      .min(Yup.ref('start'), "Start Date can't be greater than End Date"),
  });

  const saveChanges = async () => {
    const identifiernews = rows?.map((list: any) => {
      let startDate = moment(list?.start).isValid() && !isNil(list?.start);
      let endDate = moment(list?.end).isValid() && !isNil(list?.end);

      let setPeriod: any = {
        ...(startDate
          ? { start: list?.start?.toISOString() }
          : !isNil(list?.period?.start) && { start: list?.period?.start }),
        ...(endDate
          ? { end: list?.end?.toISOString() }
          : !isNil(list?.period?.end) && { end: list?.period?.end }),
      };

      let setIdentifierType = identifiers?.find((e: any) => e?.system === list?.id)?.type ?? null;

      let identifiernew: any = {
        system: list.system || '',
        value: list?.value || '',
        ...(!isEmpty(setPeriod) && { period: setPeriod }),
        ...(!setIdentifierType && { use: 'official' }),
        type:
          list?.isNew && !setIdentifierType
            ? {
                coding: [
                  {
                    code: list?.code || upperCase(list.type).replace(/ /g, '_'),
                    display: list.type,
                    system: list.system || '',
                  },
                ],
                text: list.type,
              }
            : setIdentifierType ?? '',
      };

      let claves = Object.keys(identifiernew);
      for (let i = 0; i < claves?.length; i++) {
        let clave = claves[i];
        if (identifiernew[clave] == '') {
          delete identifiernew[clave];
        }
      }
      return identifiernew;
    });

    try {
      onUpdateIdentifiers(identifiernews);
      setNewRow(false);
    } catch {
      console.log('error');
    }
  };

  useEffect(() => {
    if (save == true) {
      saveChanges();
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (newrow == false) {
      setRows(FilterIdent(identifiers));
    }
  }, [catalog, identifiers]);

  const showEdit = isAllowedToEdit || isAllowedToDelete;

  function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field, hasFocus, row } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const maskTypeMapping: any = {
      ...(showMedicaID && { 'Medicaid ID': '*********' }),
      'Social Security Number(SSN)': '*********',
    };

    let maskType: any = null;

    if (row.type in maskTypeMapping) {
      maskType = maskTypeMapping[row.type];
    }

    if (!maskType) {
      return <GridEditInputCell {...props} />;
    } else {
      return (
        <InputMask mask={maskType} onChange={handleValueChange} value={value}>
          {(inputProps: any) => (
            <TextField
              fullWidth
              {...inputProps}
              onChange={inputProps.onChange}
              InputProps={{
                ...inputProps.InputProps,
              }}
            />
          )}
        </InputMask>
      );
    }
  }

  const columns: GridColumns = [
    {
      field: 'type',
      headerName: `${i18n('patients.details.identifierDetails.type', 'crs')} `,
      type: 'singleSelect',
      valueOptions:
        newrow == false
          ? rows?.map((dis: any) => dis.type)
          : FilterCat(catalog?.map((dis: any) => dis?.display)),
      editable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'value',
      headerName: `${i18n('patients.details.identifierDetails.number', 'crs')}`,
      type: 'string',
      minWidth: 100,
      editable: true,
      flex: 2,
      renderEditCell: CustomEditComponent,
    },
    {
      field: 'start',
      headerName: `${i18n('patients.details.identifierDetails.start', 'crs')}`,
      editable: true,
      type: 'date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const start = params.row.period && params.row.period.start;
        const startDate = moment.utc(start ? new Date(start) : null);
        return (
          <Typography variant="body2">
            {!startDate.isValid() ? '' : startDate.format('MM/DD/YYYY')}
          </Typography>
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        const start = params.row.period ? new Date(params.row.period.start) : null;
        return start;
      },
      valueSetter: (params: GridValueSetterParams) => {
        const start = params.value ?? null;
        return { ...params.row, start };
      },
    },
    {
      field: 'end',
      headerName: `${i18n('patients.details.identifierDetails.end', 'crs')}`,
      editable: true,
      type: 'date',
      flex: 1,

      renderCell: (params: GridRenderCellParams) => {
        const end = params.row.period && params.row.period.end;
        const endDate = moment.utc(end ? new Date(end) : null);
        return (
          <Typography variant="body2">
            {!endDate.isValid() ? '' : endDate.format('MM/DD/YYYY')}
          </Typography>
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        const end = params.row.period ? new Date(params.row.period.end) : null;
        return end;
      },
      valueSetter: (params: GridValueSetterParams) => {
        const end = params.value ?? null;
        return { ...params.row, end };
      },
    },
    {
      field: 'edit',
      headerName: '',
      type: 'actions',
      cellClassName: 'actions',
      hide: !showEdit,
      flex: 1,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={'grid-a-save-' + id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={'grid-a-cancel-' + id}
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }

        return [
          isAllowedToEdit ? (
            <GridActionsCellItem
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              showInMenu
              disabled={disabledadd}
            />
          ) : (
            <></>
          ),
          isAllowedToDelete ? (
            <GridActionsCellItem
              disabled={disabledadd}
              label="Delete"
              className="textPrimary"
              onClick={() => {
                saveId(id);
              }}
              color="inherit"
              showInMenu
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  const handleProcessRowUpdate = async (newRow: GridRowModel) => {
    setSnackbar(null);
    const validateFields = await schema.validate(newRow, { abortEarly: false }).catch((err) => {
      return err.errors;
    });

    if (validateFields?.length > 0) throw new Error(validateFields);

    if (
      rows.find(
        (row: any) =>
          (row.value === newRow.value || row.type === newRow.type) && row.id !== newRow.id
      ) &&
      shouldPromptDuplicateWarning
    ) {
      setNewRowModel(newRow);
      setIsDuplicateDialogOpen(true);
      throw new Error('Error');
    } else {
      setDisabledAdd(false);
      const updatedRow = await processRowUpdate(newRow);
      return updatedRow;
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (editmoderow == true) {
      newRow.id = rows?.filter((word: any) => word.type === newRow.type)[0].system;
      if (typeof newRow.text == 'string' && newRow.text != newRow.type) {
        delete newRow.text;
      }
      setEditModerow(false);
    }
    if (catalog?.filter((dis: any) => newRow.type == dis.display).length > 0) {
      newRow.system = catalog?.filter((dis: any) => newRow.type == dis.display)[0].code;
    }

    const updatedRow = { ...newRow };
    setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    setSave(true);
    return updatedRow;
  };

  const AddRow = () => {
    setSnackbar(null);
    setDisabledAdd(true);
    const id = rows.length + 1;
    setNewRow(true);
    setRows((oldRows: any) => [{ id, type: '', value: '', isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'value' },
    }));
  };
  return (
    <>
      <Stack direction="column" sx={{ padding: 3, pt: 0 }}>
        <Typography
          style={{ display: 'flex', justifyContent: 'space-between' }}
          variant="button"
          sx={{ textTransform: 'uppercase', pb: 2 }}
        >
          {isAllowedToAdd && (
            <Button
              disabled={disabledadd}
              color="primary"
              startIcon={<Add />}
              onClick={AddRow}
              style={{ position: 'absolute', top: '20px', right: '25px' }}
            >
              Add
            </Button>
          )}
        </Typography>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Box sx={[{ height: 400, width: '100%' }, ...spreadSxProp(sxContainer)]}>
            <DataGrid
              autoPageSize
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={handleProcessRowUpdate}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          </Box>
          {!!snackbar && <Alert {...snackbar} onClose={() => setSnackbar(null)} />}
        </Stack>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={stylemodal}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                <div style={{ textAlign: 'center', fontSize: '25px' }}>
                  Delete {idDelete} type identifier
                </div>
              </Typography>
              <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                <Button variant="contained" onClick={saveDeleteIdentifier}>
                  Confirm
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      </Stack>
      <Dialog
        open={isDuplicateDialogOpen}
        onClose={handleDuplicateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Potential Duplicated Identifier'}</DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText id="alert-dialog-description">
            There is an existing identifier with some of the values
            <br />
            <strong>{newRowModel?.value}</strong> or <strong>{newRowModel?.type}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDuplicateDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
