/* eslint-disable */
import axios, { AxiosInstance } from 'axios';
import { ApiConfiguration, IApiClient, RequestConfig } from './api_client';

export default class ApiClient implements IApiClient {
  private client: AxiosInstance;

  protected createAxiosClient(apiConfiguration: ApiConfiguration): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: apiConfiguration.baseUrl,
      responseType: 'json' as const,
      headers: {
        'Content-Type': apiConfiguration.contentType,
      },
    });

    // use interceptor to get the most recent careflow token
    axiosInstance.interceptors.request.use(
      async function (config) {
        if (config.headers === undefined) {
          config.headers = {};
        }

        if (apiConfiguration.getAccessToken) {
          const token = apiConfiguration.getAccessToken();

          if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
          }
        }

        if (apiConfiguration.getHeaderTenantIdCode) {
          const tenantCode = apiConfiguration.getHeaderTenantIdCode();

          if (tenantCode) {
            config.headers['X-TENANT-ID'] = tenantCode;
          }
        }

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  constructor(apiConfiguration: ApiConfiguration) {
    this.client = this.createAxiosClient(apiConfiguration);
  }

  async get<TResponse>(path: string): Promise<TResponse> {
    try {
      const response = await this.client.get<TResponse>(path);
      return response.data;
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<TResponse> {
    try {
      const response = config
        ? await this.client.post<TResponse>(path, payload, config)
        : await this.client.post<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    try {
      const response = await this.client.put<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }

  async patch<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    try {
      const response = await this.client.patch<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }
}
