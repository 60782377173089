import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export interface CustomDataGridProps extends DataGridProps {}

const CustomDataGrid = ({ ...props }: DataGridProps) => (
  <DataGrid
    sx={{
      width: '100% !important',
      '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
    }}
    autoHeight={true}
    getCellClassName={() => 'taskGridBodyCell'}
    getRowHeight={() => 100}
    isRowSelectable={() => false}
    disableColumnFilter
    disableColumnMenu
    paginationMode="server"
    sortingMode="server"
    rowsPerPageOptions={[5, 10, 25, 50, 100]}
    {...props}
  />
);

export default CustomDataGrid;
