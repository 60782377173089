import { useCallback } from 'react';
import useLocales from './useLocales';

const useTranslations = (prefix: string, namespace: string = 'crs') => {
  const { i18n } = useLocales();
  const translate = useCallback(
    (item: string) => i18n(`${prefix}.${item}`, namespace),
    [prefix, namespace]
  );
  return translate;
};

export default useTranslations;
