import InputMask from 'react-input-mask';
import { MobileDatePicker } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { Draggable } from 'react-beautiful-dnd';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import { ContactDetailsPermissionProps } from '../types';

export interface PhoneProps extends ContactDetailsPermissionProps {}

const Phone = ({ isAllowedToAdd, isAllowedToDelete }: PhoneProps) => {
  const { control } = useFormContext();
  const { i18n } = useLocales();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phone',
  });

  const handleAddPhone = () => {
    append({
      use: 'home',
      value: '',
      system: 'phone',
      period: { start: null, end: null },
      areaCode: '',
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Stack direction="row" sx={{ pb: 1 }} style={{ display: 'flex' }}>
        <Typography sx={{ ml: 1, mt: 0.7, py: 0.5 }}>{i18n('patients.phone', 'crs')}</Typography>
        {isAllowedToAdd && (
          <IconButton onClick={handleAddPhone} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={'eva:plus-fill'} />
          </IconButton>
        )}
      </Stack>
      {fields.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Stack key={item.id} direction="row" sx={{ mb: 2 }}>
                <Iconify icon={'material-symbols:drag-indicator'} width={32} height={50} />

                <Box>
                  <Controller
                    name={`phone.${index}.use`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        sx={{ mx: 1 }}
                        fullWidth={true}
                        SelectProps={{ native: true }}
                        error={!!error}
                        helperText={error?.message}
                      >
                        <option>home</option>
                        <option>work</option>
                        <option>mobile</option>
                      </TextField>
                    )}
                  />
                </Box>

                <Box sx={{ flexGrow: 1, ml: 3 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Controller
                        name={`phone.${index}.areaCode`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <InputMask mask="(999)" value={value} onChange={onChange}>
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                error={!!error?.message}
                                helperText={error?.message}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name={`phone.${index}.value`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <InputMask mask="999-9999" value={value} onChange={onChange}>
                            {(inputProps: any) => (
                              <TextField
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      {index === 0 ? (
                                        <div
                                          style={{
                                            width: '120px',
                                            height: '35px',
                                            padding: '6px 12px',
                                            borderRadius: '8px',
                                            backgroundColor: '#cafdf5',
                                            backgroundSize: 'cover',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#006c9c',
                                          }}
                                        >
                                          <StarIcon fontSize="small" />
                                          Primary
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ),
                                }}
                                fullWidth
                                {...inputProps}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Stack direction="row" spacing={2} sx={{ py: 2 }}>
                    <Controller
                      name={`phone.${index}.period.start`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <MobileDatePicker
                          {...field}
                          value={field.value ?? null}
                          label="Start"
                          views={['year', 'month']}
                          inputFormat="MMM yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error?.message}
                              helperText={error?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`phone.${index}.period.end`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <MobileDatePicker
                          {...field}
                          value={field.value ?? null}
                          label="End"
                          views={['year', 'month']}
                          inputFormat="MMM yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error?.message}
                              helperText={error?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                </Box>

                {isAllowedToDelete && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    size="medium"
                    sx={{
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} color="red" />
                  </IconButton>
                )}
              </Stack>
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default Phone;
