import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import RelatedPersonAction from './RelatedPersonAction';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { WrappedValueSet } from '../../../../../@nicheaim/fhir-base/wrappers/ValueSet';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import useLocales from 'src/hooks/useLocales';

type Props = {
  row: any;
  patient: WrappedPatient | null;
  handleRelatedPerson: (data: any) => Promise<any>;
  relationshipValueSet: WrappedValueSet | null;
};

export default function RelatedPersonRow({
  row,
  patient,
  handleRelatedPerson,
  relationshipValueSet,
}: Props) {

  const { i18n } = useLocales();

  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.getFullName()}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e.system === 'phone')?.[0]?.value}
            </Typography>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e.system === 'email')?.[0]?.value}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.RELATED_PERSONS.EDIT] })
                ? [
                    <MenuItem key={'menu-i-edit-' + row?.id} onClick={() => handleEdit(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : null
            }
          />
        </TableCell>
      </TableRow>
      <RelatedPersonAction
        title={i18n('patients.details.relatedPersons.titleAddRelatedPersons', 'crs')}
        patient={patient}
        relatedPerson={data}
        open={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
        handleRelatedPerson={handleRelatedPerson}
        relationshipValueSet={relationshipValueSet}
      />
    </>
  );
}
