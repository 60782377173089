import { useState } from 'react';
import PatientEngagementList from './PatientEngagementList';

const PatientEngagementListContainer = () => {
  const [rows, setRows] = useState<any>(null);
  const [search, setSearch] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assignedTo, setAssignedTo] = useState<any>(null);
  const [engagementWorkflowStage, setEngagementWorkflowStage] = useState<string>('');

  return (
    <PatientEngagementList
      includeBreadcrumbs={true}
      rows={rows}
      setRows={setRows}
      search={search}
      setSearch={setSearch}
      totalItems={totalItems}
      setTotalItems={setTotalItems}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      // initialGridState={initialGridState}
      assignedTo={assignedTo}
      setAssignedTo={setAssignedTo}
      show={true}
      workflowStage={engagementWorkflowStage}
      setWorkFlowStage={setEngagementWorkflowStage}
    />
  );
};

export default PatientEngagementListContainer;
