import { createContext } from 'react';

export type PermissionsBase = {
  [key: string]: boolean | PermissionsBase;
};

export const PermissionsContext = createContext<PermissionsBase | null>(null);

export interface PermissionsProviderProps<T extends PermissionsBase> {
  children: React.ReactNode;
  permissions: T | null;
}

export const PermissionsProvider = <T extends PermissionsBase>({
  children,
  permissions,
}: PermissionsProviderProps<T>) => (
  <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>
);

export default PermissionsContext;
