import { Box, Grid, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import FilterDrawer, { FilterContainer } from 'src/components/FilterDrawer';
import { DateRange, FilterDrawerListProps } from '../../types';
import DatePickerMoment from 'src/components/DatePickerMoment';
import CollapsibleComponent from '../../components/CollapsibleComponent';

export interface UserFilters {
  id: string;
  email: string;
  userName: string;
  createdBy: string;
  createdOn: DateRange | null;
  updatedBy: string;
  updatedOn: DateRange | null;
  name: string;
}

export interface UsersListFilterProps extends FilterDrawerListProps {
  formik: FormikProps<UserFilters>;
  onSetSelectedFilterName: (name: string) => void;
  selectedFilterName: string | null;
}

const UsersListFilter = ({
  formik,
  onSetSelectedFilterName,
  selectedFilterName,
  ...filterDrawerProps
}: UsersListFilterProps) => {
  const handleFilterSelection = (name: string) => () => {
    onSetSelectedFilterName(name);
  };
  return (
    <FilterDrawer
      {...filterDrawerProps}
      title="Users Filters"
      anchor={'right'}
      buttonsContainerSx={{ marginTop: 0 }}
    >
      <Box marginY={3}>
        <CollapsibleComponent
          title="ID"
          sectionName="id"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('id')}
        >
          <TextField
            fullWidth
            id="id"
            name="id"
            placeholder="Id"
            value={formik.values.id}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Name"
          sectionName="name"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('name')}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Email"
          sectionName="email"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('email')}
        >
          <TextField
            fullWidth
            id="email"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="User Name"
          sectionName="userName"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('userName')}
        >
          <TextField
            fullWidth
            id="userName"
            name="userName"
            placeholder="User Name"
            value={formik.values.userName}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Created By"
          sectionName="createdBy"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('createdBy')}
        >
          <TextField
            fullWidth
            id="createdBy"
            name="createdBy"
            placeholder="Created By"
            value={formik.values.createdBy}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Created On"
          sectionName="createdOn"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('createdOn')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePickerMoment
                ignoreInvalidInputs
                label="Start"
                value={formik.values.createdOn?.start ?? null}
                error={formik.errors?.createdOn ? formik.errors.createdOn : null}
                onChange={(value) => {
                  formik.setFieldValue('createdOn.start', value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerMoment
                ignoreInvalidInputs
                label="End"
                value={formik.values.createdOn?.end ?? null}
                onChange={(value) => {
                  formik.setFieldValue('createdOn.end', value);
                }}
              />
            </Grid>
          </Grid>
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Updated By"
          sectionName="updatedBy"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('updatedBy')}
        >
          <TextField
            fullWidth
            id="updatedBy"
            name="updatedBy"
            placeholder="Updated By"
            value={formik.values.updatedBy}
            onChange={formik.handleChange}
          />
        </CollapsibleComponent>
        <CollapsibleComponent
          title="Updated On"
          sectionName="updatedOn"
          selectedSectionName={selectedFilterName}
          onChange={handleFilterSelection('updatedOn')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePickerMoment
                disableMaskedInput
                label="Start"
                value={formik.values.updatedOn?.start ?? null}
                onChange={(value) => {
                  formik.setFieldValue('updatedOn.start', value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerMoment
                disableMaskedInput
                label="End"
                value={formik.values.updatedOn?.end ?? null}
                onChange={(value) => {
                  formik.setFieldValue('updatedOn.end', value);
                }}
              />
            </Grid>
          </Grid>
        </CollapsibleComponent>
      </Box>
    </FilterDrawer>
  );
};

export default UsersListFilter;
