/* eslint-disable */
import { useState } from 'react';
import { isEmpty } from 'lodash';
import useTable from 'src/hooks/useTable';
import { TableHeadCustom } from 'src/components/table';
import ContactAction from './contacts/ContactAction';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_RELATED_PERSON } from 'src/sections/crs/common/table-head';
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import ContactRow from './contacts/ContactRow';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from '../../helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { Add } from '@mui/icons-material';

type Props = {
  patient: WrappedPatient | null;
};

export function Contacts({ patient }: Props) {
  const [open, setOpen] = useState(false);
  const { i18n } = useLocales();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  return (
    <>
      <Stack sx={{ m: 1 }}>
        <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACTS.ADD] }) && (
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Button
                size="small"
                sx={{ height: '36px' }}
                startIcon={<Add />}
                style={{ position: 'absolute', top: '25px', right: '25px' }}
                onClick={() => setOpen(true)}
              >
                {i18n('patients.details.contacts.button', 'crs')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      <TableContainer>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_RELATED_PERSON, 'crs')} />
          <TableBody>
            {!isEmpty(patient?.contact) ? (
              patient?.contact
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => <ContactRow row={row} patient={patient} />)
            ) : (
              <TableCell colSpan={TABLE_HEAD_RELATED_PERSON?.length}>
                <Typography variant="body2" align="center">
                  No rows
                </Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={patient?.contact ? patient.contact.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <ContactAction patient={patient} contact={null} open={open} onClose={() => setOpen(false)} />
    </>
  );
}
