import { PatientEngagementResponse } from '../PatientService';

export class PatientEngagementDto {
  createdBy: string;

  createdOn: string;

  updatedBy: string;

  updatedOn: string;

  patientEngagementId: string;

  patientEngagementUUID: string;

  careflowIdentifierPatientEngagement: string;

  fhirId: string;

  workflowInstanceId: number;

  workflowStageId: string;

  workflowStageName: string;

  workflowStageOrder: number;

  workflowStatus: string;

  workflowSubstatus: string;

  workflowDispositionReason: string;

  workflowDispositionNote: string;

  workflowDispositionOn: string;

  workflowDispositionAt: string;

  workflowOwnedBy: string;

  workflowScope: string;

  patientFamily: string;

  patientGiven: string;

  patientName: string;

  patientFhirUUID: string;

  patientDOB: string;

  patientDOBFormatted: string;

  patientAge: string;

  patientEmail: string;

  patientPhone: string;

  patientMrn: string;

  patientLanguage: string;

  patientManagingOrgUUID: string;

  patientManagingOrgDisplay: string;

  patientLastSIL: string;

  contactAttemptContactOn: string;

  fromPatientEngagementResponse(dto: PatientEngagementResponse) {
    this.createdBy = dto.createdBy;
    this.createdOn = dto.createdOn;
    this.updatedBy = dto.updatedBy;
    this.updatedOn = dto.updatedOn;
    this.patientEngagementId = dto.patientEngagementId;
    this.patientEngagementUUID = dto.patientEngagementUUID;
    this.careflowIdentifierPatientEngagement = dto.careflowIdentifierPatientEngagement;
    this.fhirId = dto.fhirId;
    this.workflowInstanceId = dto.workflowInstanceId;
    this.workflowStageId = dto.workflowStageId;
    this.workflowStageName = dto.workflowStageName;
    this.workflowStageOrder = dto.workflowStageOrder;
    this.workflowStatus = dto.workflowStatus;
    this.workflowSubstatus = dto.workflowSubstatus;
    this.workflowDispositionReason = dto.workflowDispositionReason;
    this.workflowDispositionNote = dto.workflowDispositionNote;
    this.workflowDispositionOn = dto.workflowDispositionOn;
    this.workflowDispositionAt = dto.workflowDispositionAt;
    this.workflowOwnedBy = dto.workflowOwnedBy;
    this.workflowScope = dto.workflowScope;
    this.patientFamily = dto.patientFamily;
    this.patientGiven = dto.patientGiven;
    this.patientName = dto.patientName;
    this.patientFhirUUID = dto.patientFhirUUID;
    this.patientDOB = dto.patientDOB;
    this.patientDOBFormatted = dto.patientDOBFormatted;
    this.patientAge = dto.patientAge;
    this.patientEmail = dto.patientEmail;
    this.patientPhone = dto.patientPhone;
    this.patientMrn = dto.patientMrn;
    this.patientLanguage = dto.patientLanguage;
    this.patientManagingOrgUUID = dto.patientManagingOrgUUID;
    this.patientManagingOrgDisplay = dto.patientManagingOrgDisplay;
    this.patientLastSIL = dto.patientLastSIL;
    this.contactAttemptContactOn = dto.contactAttemptContactOn;
  }
}
