import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';

import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';
import {
  ContactPoint,
  ContactPointUse,
  Extension,
  PatientGender,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import DatePickerMoment from 'src/components/DatePickerMoment';
import moment from 'moment';
import * as yup from 'yup';
import GenderIdentityAutoComplete from 'src/components/GenderIdentityAutoComplete';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { usePatients, useValueSet } from 'src/@nicheaim/fhir-react';
import { Add } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import AddAddress from '../../referral/components/workflow-step/AddressChecklistItem/AddAddress';
import { useJsApiLoader } from '@react-google-maps/api';
import client from 'src/services/api/_client';
import { isEmpty } from 'lodash';
import { getGenderIdentityExtension, getIdentifier } from 'src/utils/fhir';
import { IdentifierCode, PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { SimpleOrgI } from 'src/contexts/JWTContext';
import useAuth from 'src/hooks/useAuth';
import { getUUID } from 'src/utils/url';
import { CreateFullPatientDto } from 'src/ccm/dto/create-full-patient.dto';
import { fullPatientService } from 'src/ccm/full-patient/services';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSnackbar } from 'notistack';

export interface AddPatientModalProps extends CustomModalBasicProps {}

export interface PatientForm {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: moment.Moment | null;
  gender: string;
  genderIdentity: ValueSetComposeIncludeConcept | null;
  emailType: string;
  email: string;
  phoneType: string;
  phone: string;
  areaCode: string;
  street: string;
  street2: string;
  city: string;
  district: string;
  state: string;
  zipCode: string;
}

const initialPatientForm: PatientForm = {
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: null,
  gender: '',
  genderIdentity: null,
  email: '',
  emailType: '',
  phoneType: '',
  phone: '',
  areaCode: '',
  street: '',
  street2: '',
  city: '',
  district: '',
  state: '',
  zipCode: '',
};

const AddPatientModal = ({ onClose, ...modalProps }: AddPatientModalProps) => {
  const { i18n } = useLocales();
  const navigate = useNavigate();

  const [genderIdentityVS, { isLoading: isLoadingGenderIdentityVS }] = useValueSet(
    'ph-gender-identity',
    {
      map: ValueSetWrapper,
    }
  );

  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [, { create }] = usePatients({ map: PatientWrapper });

  const { enqueueSnackbar } = useSnackbar();

  const auth = useAuth();

  const selectedOrg = useMemo<SimpleOrgI>(() => auth.getSelectedOrg(), [auth]);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(i18n('patients.addPatient.validationMessages.firstName.isRequired', 'crs'))
      .matches(
        /^[aA-zZ\s]+$/,
        i18n('patients.addPatient.validationMessages.firstName.onlyAlphabet', 'crs')
      )
      .max(25, i18n('patients.addPatient.validationMessages.firstName.maxLength', 'crs')),
    lastName: yup
      .string()
      .required(i18n('patients.addPatient.validationMessages.lastName.isRequired', 'crs'))
      .matches(
        /^[a-zA-Z\s-]+$/,
        i18n('patients.addPatient.validationMessages.lastName.onlyAlphabet', 'crs')
      )
      .max(25, i18n('patients.addPatient.validationMessages.lastName.maxLength', 'crs')),
    middleName: yup
      .string()
      .matches(
        /^[aA-zZ\s]+$/,
        i18n('patients.addPatient.validationMessages.middleName.onlyAlphabet', 'crs')
      )
      .max(25, i18n('patients.addPatient.validationMessages.middleName.maxLength', 'crs')),
    gender: yup
      .string()
      .required(i18n('patients.addPatient.validationMessages.gender.isRequired', 'crs')),
    dateOfBirth: yup
      .mixed()
      .required(i18n('patients.addPatient.validationMessages.dateOfBirth.isRequired', 'crs'))
      .test(
        'validDate',
        i18n('patients.addPatient.validationMessages.dateOfBirth.validDate', 'crs'),
        function (value) {
          const date = moment(value ?? null);
          return date.isValid();
        }
      )
      .test(
        'CantBeFuture',
        i18n('patients.addPatient.validationMessages.dateOfBirth.cantBeFuture', 'crs'),
        function (value) {
          const date = moment(value ?? null);
          if (!date.isValid()) return true;
          return date.isSameOrBefore(moment(), 'date');
        }
      ),
    email: yup.string().email('Email must be a valid email address'),
    emailType: yup.string().when('email', (email, schema) => {
      if (email) return schema.required('Email Type is required');
    }),
    areaCode: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[(]?[0-9]{3}[)]$/, 'Area Code must be 3 numbers')
      .when('phone', (value, schema) => {
        if (value) return schema.required('Area Code is required');
      }),
    phone: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Phone Number must be 7 numbers'),
    phoneType: yup.string().when('phone', (phone, schema) => {
      if (phone) return schema.required('Phone Type is required');
    }),
  });

  const handleMPISearch = async (
    dataForm: PatientForm,
    create_if_not_exist?: boolean
  ): Promise<any> => {
    const payload = {
      name: [{ family: dataForm.lastName, given: [dataForm.firstName] }],
      birthDate:
        dataForm.dateOfBirth?.isValid?.() &&
        dataForm.dateOfBirth.startOf('day').toISOString().split('T')[0],
      gender: dataForm.gender as PatientGender,
      createIfNotExist: create_if_not_exist,
    };

    const res = await client.post('/mpi/search', payload);
    const data = res?.data[0];

    return data;
  };

  const onSubmit = async (data: PatientForm) => {
    try {
      setIsLoading(true);
      const mpiSearchResult = await handleMPISearch(data, false);

      if (isEmpty(mpiSearchResult)) {
        const dataResult = await handleMPISearch(data, true);

        if (!isEmpty(dataResult)) {
          dataResult?.identifier?.push({
            use: 'official',
            type: 'master_patient_uuid',
            value: dataResult?.uuid,
            system: `${process.env.REACT_APP_MPI_SEARCH_URL}/patients`,
          });

          const mrn = getIdentifier(dataResult, IdentifierCode.MEDICAL_RECORD_NUMBER);
          const master_person_uuid = getIdentifier(dataResult, IdentifierCode.MASTER_PERSON_UUID);
          const master_patient_uuid = getIdentifier(dataResult, IdentifierCode.MASTER_PATIENT_UUID);

          const telecom: ContactPoint[] = [];

          if (!isEmpty(data.emailType) && !isEmpty(data.email))
            telecom.push({
              system: 'email',
              use: data.emailType as ContactPointUse,
              value: data.email,
            });
          if (!isEmpty(data.phoneType) && !isEmpty(data.phone)) {
            telecom.push({
              system: 'phone',
              use: data.phoneType as ContactPointUse,
              ...(data.areaCode
                ? { value: `${data.areaCode} ${data.phone}` }
                : { value: `${data.phone}` }),
            });
          }

          const genderIdentity = data.genderIdentity;

          const genderIdentityExtension: Extension | null =
            genderIdentity?.code && genderIdentity?.display
              ? getGenderIdentityExtension({
                  ...genderIdentity,
                  system: genderIdentityVS?.getConceptSystem?.(),
                })
              : null;

          if (!isEmpty(mrn) && !isEmpty(master_person_uuid) && !isEmpty(master_patient_uuid)) {
            const [fhirPatient] = await create({
              resourceType: 'Patient',
              active: true,
              identifier: [mrn, master_person_uuid, master_patient_uuid],
              name: [
                {
                  given: [data.firstName, ...(data.middleName ? [data.middleName] : [])],
                  family: data.lastName,
                },
              ],
              birthDate: data.dateOfBirth?.isValid?.()
                ? data.dateOfBirth.toISOString().split('T')[0]
                : undefined,
              ...(data.gender && { gender: data.gender as PatientGender }),
              ...(!isEmpty(telecom) && { telecom: telecom }),
              ...(!isEmpty(address) && { address: [address] }),
              managingOrganization: {
                display: selectedOrg?.name,
                reference: `Organization/${getUUID(selectedOrg?.fhir_uri)}`,
              },
              extension: genderIdentityExtension ? [genderIdentityExtension] : undefined,
            });

            const fullPatient = new CreateFullPatientDto();
            fullPatient.fhirRefUri = fhirPatient.id!;
            fullPatient.given = data.firstName;
            fullPatient.family = data.lastName;
            fullPatient.fullName = `${data.firstName} ${data.lastName}`;
            fullPatient.gender = data.gender;
            fullPatient.dateOfBirth = data.dateOfBirth?.toISOString?.()
              ? new Date(data.dateOfBirth.toISOString())
              : undefined;
            fullPatient.email = data.email;
            fullPatient.phone = data.phone;
            fullPatient.status = 'new';

            await fullPatientService.create(fullPatient);

            navigate(PATH_DASHBOARD.crs.patient.details.forId(fhirPatient.id || ''));
          }
        }
      } else {
        enqueueSnackbar('This patient was already registered', { variant: 'error' });
      }

      formik.resetForm();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar('An error has ocurred.', { variant: 'error' });
    }
  };
  const formik = useFormik<PatientForm>({
    initialValues: initialPatientForm,
    validationSchema,
    onSubmit,
  });

  const setAddressFields = async (data: any) => {
    formik.setFieldValue('street', data.line[0]);
    formik.setFieldValue('street2', data.line[1]);
    formik.setFieldValue('city', data.city);
    formik.setFieldValue('district', data.district);
    formik.setFieldValue('zipCode', data.postalCode);
    formik.setFieldValue('state', data.state);
    setAddress(data);
    setOpenAddAddress(false);
  };

  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(JsOptions);

  return (
    <>
      <CustomModal
        {...modalProps}
        title={i18n('patients.list.addPatient', 'crs')}
        breadcrumbs={[i18n('patients.list.title', 'crs'), i18n('patients.list.addPatient', 'crs')]}
        isLoading={isLoading}
        onSave={() => {
          formik.handleSubmit();
          if (Object.entries(formik.errors).length) return;
        }}
        onCancel={onClose as Function}
        onClose={onClose}
      >
        <Box sx={{ my: 3, width: '100%' }}>
          <Grid container sx={{ width: '100%' }} my={3}>
            <Grid item xs={12}>
              <GridSection mt={0}>
                <GridItem>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label={`${i18n('patients.firstName', 'crs')}*`}
                    placeholder={`${i18n('patients.firstName', 'crs')}*`}
                    error={formik.touched.firstName && !!formik.errors.firstName}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    variant="outlined"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    fullWidth
                    id="middleName"
                    name="middleName"
                    label={i18n('patients.middleName', 'crs')}
                    placeholder={i18n('patients.middleName', 'crs')}
                    error={formik.touched.middleName && !!formik.errors.middleName}
                    helperText={formik.touched.middleName && formik.errors.middleName}
                    variant="outlined"
                    value={formik.values.middleName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label={`${i18n('patients.lastName', 'crs')}*`}
                    placeholder={`${i18n('patients.lastName', 'crs')}*`}
                    error={formik.touched.lastName && !!formik.errors.lastName}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    variant="outlined"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem>
                  <DatePickerMoment
                    value={formik.values.dateOfBirth}
                    error={
                      formik.touched.dateOfBirth && formik.errors.dateOfBirth
                        ? formik.errors.dateOfBirth
                        : undefined
                    }
                    label={`${i18n('patients.dateOfBirth', 'crs')}*`}
                    onChange={(value) => {
                      formik.setFieldValue('dateOfBirth', value);
                    }}
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">{`${i18n('patients.gender', 'crs')}*`}</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      name="gender"
                      placeholder={`${i18n('patients.gender', 'crs')}*`}
                      value={formik.values.gender}
                      error={formik.touched.gender && !!formik.errors.gender}
                      label={`${i18n('patients.gender', 'crs')}*`}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                      <MenuItem value="unknown">Unknown</MenuItem>
                    </Select>
                    {formik.touched.gender && !!formik.errors.gender ? (
                      <FormHelperText error>{formik.errors.gender}</FormHelperText>
                    ) : null}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <GenderIdentityAutoComplete
                    value={formik.values.genderIdentity}
                    onValueChange={(gender) => {
                      formik.setFieldValue('genderIdentity', gender);
                    }}
                    genderIdentityVS={genderIdentityVS}
                    isLoading={isLoadingGenderIdentityVS}
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem xs={12}>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {i18n('patients.addPatient.contactDetails', 'crs')}
                  </Typography>
                </GridItem>
              </GridSection>
              <GridSection spacing={2}>
                <GridItem>
                  <FormControl fullWidth>
                    <InputLabel id="email-type-label">
                      {i18n('patients.emailType', 'crs')}
                    </InputLabel>
                    <Select
                      labelId="email-type-label"
                      id="emailType"
                      name="emailType"
                      value={formik.values.emailType}
                      placeholder={i18n('patients.emailType', 'crs')}
                      label={i18n('patients.emailType', 'crs')}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                    </Select>
                    {formik.touched.emailType && !!formik.errors.emailType ? (
                      <FormHelperText error>{formik.errors.emailType}</FormHelperText>
                    ) : null}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label={i18n('patients.email', 'crs')}
                    placeholder={i18n('patients.email', 'crs')}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem xs={2.8}>
                  <FormControl fullWidth>
                    <InputLabel id="phone-type-label">
                      {i18n('patients.phoneType', 'crs')}
                    </InputLabel>
                    <Select
                      labelId="phone-type-label"
                      id="phoneType"
                      name="phoneType"
                      value={formik.values.phoneType}
                      placeholder={i18n('patients.phoneType', 'crs')}
                      label={i18n('patients.phoneType', 'crs')}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                    </Select>
                    {formik.touched.phoneType && !!formik.errors.phoneType ? (
                      <FormHelperText error>{formik.errors.phoneType}</FormHelperText>
                    ) : null}
                  </FormControl>
                </GridItem>
                <GridItem xs={2.8}>
                  <InputMask
                    mask="(999)"
                    value={formik.values.areaCode}
                    onChange={(event) => {
                      formik.setFieldValue('areaCode', event.target.value);
                    }}
                  >
                    {(inputProps: any) => (
                      <TextField
                        fullWidth
                        label={i18n('patients.areaCode', 'crs')}
                        placeholder={i18n('patients.areaCode', 'crs')}
                        error={formik.touched.areaCode && !!formik.errors.areaCode}
                        helperText={formik.touched.areaCode && formik.errors.areaCode}
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </GridItem>
                <GridItem xs={6}>
                  <InputMask
                    mask="999-9999"
                    value={formik.values.phone}
                    onChange={(event) => {
                      formik.setFieldValue('phone', event.target.value);
                    }}
                  >
                    {(inputProps: any) => (
                      <TextField
                        fullWidth
                        label={i18n('patients.phone', 'crs')}
                        placeholder={i18n('patients.phone', 'crs')}
                        error={formik.touched.phone && !!formik.errors.phone}
                        helperText={formik.touched.phone && formik.errors.phone}
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </GridItem>
              </GridSection>
              <GridSection sx={{ alignItems: 'center' }}>
                <GridItem xs={6}>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {i18n('patients.details.addressDetails.title', 'crs')}
                  </Typography>
                </GridItem>
                <GridItem xs={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <Button
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => setOpenAddAddress(true)}
                    >
                      {i18n('patients.details.addressDetails.add', 'crs')}
                    </Button>
                  </Box>
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <TextField
                    name="street"
                    label={i18n('patients.details.addressDetails.streetOne', 'crs')}
                    value={formik.values.street}
                    fullWidth
                    disabled
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    name="street2"
                    label={i18n('patients.details.addressDetails.streetTwo', 'crs')}
                    value={formik.values.street2}
                    fullWidth
                    disabled
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <TextField
                    name="city"
                    label={i18n('patients.details.addressDetails.city', 'crs')}
                    value={formik.values.city}
                    fullWidth
                    disabled
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    name="district"
                    label={i18n('patients.details.addressDetails.county', 'crs')}
                    value={formik.values.district}
                    fullWidth
                    disabled
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <TextField
                    name="state"
                    label={i18n('patients.details.addressDetails.state', 'crs')}
                    value={formik.values.state}
                    fullWidth
                    disabled
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    name="zipCode"
                    label={i18n('patients.details.addressDetails.zipCode', 'crs')}
                    value={formik.values.zipCode}
                    fullWidth
                    disabled
                  />
                </GridItem>
              </GridSection>
              <GridSection>
                <GridItem>
                  <Button color="primary" variant="outlined" onClick={() => formik.resetForm()}>
                    Clear All
                  </Button>
                </GridItem>
              </GridSection>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <Dialog open={openAddAddress} fullWidth maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, mt: 2 }}>
          <AddAddress 
            isEditable={true}
            handleClose={() => setOpenAddAddress(false)} 
            handleSave={setAddressFields} 
          />
        </Card>
      </Dialog>
    </>
  );
};

export default AddPatientModal;
