import { useCallback, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import useAuth from 'src/hooks/useAuth';
import useObjectState from '../../../../../hooks/useObjectState';
import { RangeOption } from '../../utils/date-range';
import { Activity, ActivityStatus } from '../../model';
import { ACTIVITIES_VIEW_TYPES, ACTIVITIES_LIST_TYPES } from './constants';
import { EntityTypeStatesEnum, ENTITY_TYPES } from './QuickActions/constants';
import API from 'src/services/api';
import { useCareTeams, usePatients } from 'src/@nicheaim/fhir-react';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ConcreteActivity } from '../../model/Activity';

import {
  REFERRALS_LIST_PERSON,
  REFERRALS_LIST_STATUS,
  REFERRALS_LIST_TYPES,
} from '../Referrals/constants';
import { getFhirIdFromReferenceString } from './activities-utils';
import { PATEINTS_LIST_PERSON } from '../Patients/constants';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { isCareTeamLeader } from 'src/sections/careflow/health-record/patient-info-header/components/utils';
import { CareTeamParticipant } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { useTaskActivitiesStore } from './task-activities.store';
import { CCM_ACLS, checkAclValidation } from 'src/utils/permissions/permission.utils';

export type UseControllerStateType = {
  isLoading: boolean;
  entities?: any[];
  patients?: any[];
  showAddEntityModal?: boolean;
  showDeleteEntityModal?: boolean;
  disableDeleteButtons?: boolean;
  selectedEntity?: any | null | undefined;
  patientSearch?: string | null;
  activitiesViewType?: ACTIVITIES_VIEW_TYPES;
  activitiesListType?: ACTIVITIES_LIST_TYPES;
  currentReferralsListType?: REFERRALS_LIST_TYPES;
  currentReferralsListStatus?: REFERRALS_LIST_STATUS;
  currentReferralsListPerson?: REFERRALS_LIST_PERSON;
  patientsListPerson?: PATEINTS_LIST_PERSON;
  shouldCompletedActivity?: boolean;
  patientId?: string;
  selectedPatient?: { label: string; value: string } | null;
  currentRange?: any;
  selectedEntityType?: any;
  pagination?: {
    take: number;
    page: number;
    total: number;
  };
  activitiesSummary?: any;
  referralsSummary?: any;
  patientsSummary?: any;
  showAddCommunicationModal?: boolean;
  referralId?: string;
};

export type UseControllerActionsType = {
  updateState: Dispatch<SetStateAction<object>>;
  getEntities: (pagination: any) => any;
  onSaveEntity: (entity: any) => void;
  onSaveMultipleEntities: () => void;
  onDeleteEntity: () => void;
  setSelectedEntity: (selectedEntity: any) => void;
  acceptActivity: (entity: any) => void;
  startActivity: (entity: any) => void;
  cancelActivity: (entity: any) => void;
  setSelectedEntityAndState: (value: any) => void;
  openAddEntityModal: (entity?: any | null | undefined) => void;
  getSummaries: (staffId: string, updateState: any) => void;
  toggleAddCommunicationModal: (entity?: any) => void;
};

export type UseControllerReturnType = [UseControllerStateType, UseControllerActionsType];

const DEFAULT_PAGINATION_TAKE = 10;

const useController = (): UseControllerReturnType => {
  const user = useAuth();
  const loggedUser = useMemo(() => user.getCurrentUser(), [user]);
  const [careTeams] = useCareTeams({
    filter: {
      participant: getFhirIdFromReferenceString(loggedUser.user_fhir_uri),
    },
  });
  const careTeamIds = useMemo(
    () => careTeams.map((careTeam) => careTeam.id).join(','),
    [careTeams]
  );
  const [fhirPatients] = usePatients({
    map: PatientWrapper,
  });

  const setDefaultSelectedEntityType = () => {
    if (
      checkAclValidation({
        acls: [CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.READ],
      })
    ) {
      return ENTITY_TYPES.ACTIVITIES;
    }

    if (checkAclValidation({ acls: [CCM_ACLS.TASK_ACTIVITIES.REFERRALS.READ] })) {
      return ENTITY_TYPES.REFERRALS;
    }

    if (checkAclValidation({ acls: [CCM_ACLS.TASK_ACTIVITIES.PATIENTS.READ] })) {
      return ENTITY_TYPES.PATIENTS;
    }

    if (checkAclValidation({ acls: [CCM_ACLS.TASK_ACTIVITIES.ALERTS.READ] })) {
      return ENTITY_TYPES.ALERTS;
    }

    return ENTITY_TYPES.VOID;
  };

  const initialStateData: UseControllerStateType = {
    isLoading: true,
    entities: [],
    patients: [],
    showAddEntityModal: false,
    showDeleteEntityModal: false,
    disableDeleteButtons: false,
    selectedEntity: null,
    patientSearch: '',
    activitiesViewType: ACTIVITIES_VIEW_TYPES.LIST,
    activitiesListType: ACTIVITIES_LIST_TYPES.STAFF,
    currentReferralsListType: REFERRALS_LIST_TYPES.OUTBOUND,
    currentReferralsListStatus: REFERRALS_LIST_STATUS.ALL,
    currentReferralsListPerson: REFERRALS_LIST_PERSON.ME,
    patientsListPerson: PATEINTS_LIST_PERSON.ALL_PATIENTS,
    shouldCompletedActivity: false,
    patientId: '',
    selectedPatient: null,
    currentRange: {
      option: RangeOption.ALL,
      value: null,
    },
    selectedEntityType: {
      entity: setDefaultSelectedEntityType(),
      state: EntityTypeStatesEnum.ALL,
    },
    pagination: {
      take: DEFAULT_PAGINATION_TAKE,
      page: 0,
      total: 0,
    },
    activitiesSummary: null,
    referralsSummary: null,
    patientsSummary: null,
    showAddCommunicationModal: false,
    referralId: '',
  };

  const [
    {
      isLoading,
      selectedEntityType,
      entities: stateEntities,
      showAddEntityModal,
      showDeleteEntityModal,
      disableDeleteButtons,
      selectedEntity,
      patientSearch,
      activitiesViewType,
      activitiesListType,
      currentReferralsListType,
      currentReferralsListStatus,
      currentReferralsListPerson,
      patientsListPerson,
      shouldCompletedActivity,
      patientId,
      selectedPatient,
      currentRange,
      pagination,
      activitiesSummary,
      referralsSummary,
      patientsSummary,
      showAddCommunicationModal,
      referralId,
    },
    updateState,
  ] = useObjectState(initialStateData);
  console.log({
    patientId,
  });
  // change the status of a new activity after accept it
  const acceptActivity = (entity: any): void => {
    openAddEntityModal(entity);
  };

  const startActivity = async (entity: any): Promise<void> => {
    const response = await API.activities.start(entity.id);

    if (!response) {
      return;
    }

    updateState({
      entities:
        stateEntities?.map((e: any) => {
          if (e.id === entity.id) {
            e.status = ActivityStatus.IN_PROGRESS;
          }
          return e;
        }) || [],
    });
  };

  const toggleAddCommunicationModal = (entity?: any) => {
    updateState({
      patientId: !showAddCommunicationModal && entity?.patientFhirId ? entity?.patientFhirId : '',
      referralId: !showAddCommunicationModal && entity?.fhirId ? entity?.fhirId : '',
      selectedEntity: entity,
      showAddCommunicationModal: !showAddCommunicationModal,
    });
  };

  const cancelActivity = async (entity: any): Promise<void> => {
    if (!entity.id) {
      return;
    }

    // TODO: declare better, tried to hot fix
    const response = await API.activities.cancel(entity.id, {
      completedDate: '',
      completedTime: '',
    });

    if (!response) {
      return;
    }

    updateState({
      entities:
        stateEntities?.map((e: any) => {
          if (e.id === entity.id) {
            e.status = ActivityStatus.CANCELLED;
          }
          return e;
        }) || [],
    });
  };

  // set the selected activity state to edit in the schedule activity form
  const setSelectedEntity = (selectedEntity: any): void => {
    updateState({
      selectedEntity,
      showAddEntityModal: true,
    });
  };

  // change the selected entity/state type to change the type of list displayed on the screen
  const setSelectedEntityAndState = (value: any) => {
    updateState({
      patientsListPerson: PATEINTS_LIST_PERSON.MY_PATIENTS,
      selectedEntityType: value,
      selectedEntity: null,
      entities: [],
      currentRange:
        value.state === EntityTypeStatesEnum.PAST_DUE
          ? { option: RangeOption.ALL, value: null }
          : currentRange,
    });
  };

  const onDeleteReferral = async (id: any, referral: any) => {
    console.log('DEBUG onDeleteReferral referral: ', referral);
    try {
      const deleted = await API.referrals.deleteCcmReferral(id);
      updateState({
        entities: stateEntities?.filter((e: any) => e.fhirId !== id),
        showDeleteEntityModal: false,
        disableDeleteButtons: false,
      });
      console.log('DEBUG deleted: ', deleted);

      getSummaries();
    } catch (error) {
      updateState({
        disableDeleteButtons: false,
      });
    }
  };

  const onDeleteEntity = () => {
    updateState({
      disableDeleteButtons: true,
    });
    const id = selectedEntity?.fhirId;

    if (!id) return;

    switch (selectedEntityType.entity.identifier) {
      case ENTITY_TYPES.PATIENTS.identifier:
        updateState({
          entities: stateEntities?.filter((e: any) => e.id !== id) || [],
          showDeleteEntityModal: false,
          disableDeleteButtons: false,
        });
        break;
      case ENTITY_TYPES.ACTIVITIES.identifier:
        break;
      case ENTITY_TYPES.REFERRALS.identifier:
        onDeleteReferral(id, selectedEntity);
        break;
    }
  };

  const onSaveEntity = (entity: any) => {
    const tempEntities = [...(stateEntities ?? [])];
    const foundEntity = tempEntities.find((currentActivity) => currentActivity.id === entity.id);
    if (foundEntity) {
      const index = tempEntities.indexOf(foundEntity);
      tempEntities[index] = entity;
    } else {
      tempEntities.unshift(entity);
    }

    updateState({
      entities: tempEntities,
      showAddEntityModal: false,
      selectedEntity: null,
      shouldCompletedActivity: false,
    });

    getSummaries();
  };

  const onSaveMultipleEntities = () => {
    updateState({
      showAddEntityModal: false,
      selectedEntity: null,
      shouldCompletedActivity: false,
    });
    getEntities({
      pagination: { take: 10, page: 0 },
    });
    try {
      getSummaries();
    } catch (error) {
      console.log('error');
    }
  };

  // open the schedule activity modal and set the selected activity
  const openAddEntityModal = (activity?: any | null | undefined) => {
    updateState({
      showAddEntityModal: true,
      selectedEntity: activity,
    });
  };

  const { status: activityStatus, dueStatus: activityDueStatus } = useTaskActivitiesStore();

  const getEntities = useCallback(
    ({ pagination }: any) => {
      updateState({
        isLoading: true,
      });
      switch (selectedEntityType.entity.identifier) {
        case ENTITY_TYPES.PATIENTS.identifier:
          if (!careTeamIds) {
            updateState({
              isLoading: false,
            });
            break;
          }

          API.patients
            .getCcmPatients({
              search: patientSearch,
              status: selectedEntityType.state,
              careTeamIds:
                patientsListPerson === PATEINTS_LIST_PERSON.MY_PATIENTS ? careTeamIds : null,
              pagination,
            })
            .then(async (response: { patients: any[]; total: number }) => {
              const patientsWithLeader = await Promise.allSettled(
                response.patients.map(async (patient) => {
                  const query = `/CareTeam?subject=${patient.fhirId}`;
                  const { data } = await axiosFhirInstance.get(query);

                  const leader = data?.entry?.[0]?.resource?.participant?.find(
                    (m: CareTeamParticipant) => isCareTeamLeader(m)
                  );
                  if (!leader)
                    return {
                      ...patient,
                      careTeamLeaderName: '',
                    };
                  const { data: leaderInfo } = await axiosFhirInstance.get(
                    `/${leader.member.reference}`
                  );
                  return {
                    ...patient,
                    careTeamLeaderName: `${leaderInfo?.name?.[0]?.given?.[0]} ${leaderInfo?.name?.[0]?.family}`,
                    careTeamLeaderId: leaderInfo.id,
                  };
                })
              );
              return {
                total: response.total,
                patients: patientsWithLeader.map((p: any) => p.value),
              };
            })
            .then((response: { patients: any[]; total: number }) => {
              updateState({
                isLoading: false,
                entities: response.patients,
                pagination: {
                  take: pagination.take,
                  page: pagination.page,
                  total: response.total,
                },
              });
            })
            .catch(() => console.log('patients error'));
          break;
        case ENTITY_TYPES.ACTIVITIES.identifier:
          API.activities
            .getActivities({
              userEmail:
                activitiesListType === ACTIVITIES_LIST_TYPES.STAFF ? loggedUser.email : null,
              patientFhirId:
                activitiesListType === ACTIVITIES_LIST_TYPES.PATIENT && patientId
                  ? patientId
                  : null,
              status: activityStatus,
              dueStatus: activityDueStatus,
              currentRange: currentRange.value,
              pagination,
            })
            .then((response: { activities: Activity[]; total: number }) => {
              updateState({
                isLoading: false,
                entities: response.activities.map(
                  (entity: Activity) => new ConcreteActivity(entity)
                ),
                pagination: {
                  take: pagination.take,
                  page: pagination.page,
                  total: response.total,
                },
              });
            })
            .catch((error) => console.log('================= ERROR', error));
          break;
        case ENTITY_TYPES.REFERRALS.identifier:
          // console.log('DEBUG get Referrals type: ', currentReferralsListType);
          // console.log('DEBUG get Referrals person: ', currentReferralsListPerson);
          // console.log('DEBUG get Referrals status: ', currentReferralsListStatus);
          API.referrals
            .getCcmReferrals({
              type: currentReferralsListType,
              person: currentReferralsListPerson,
              status: currentReferralsListStatus,
              userEmail: loggedUser?.email,
              userFhirId: getFhirIdFromReferenceString(loggedUser?.user_fhir_uri),
              pagination,
            })
            .then((response: { referrals: any[]; total: number }) => {
              updateState({
                isLoading: false,
                entities: response.referrals,
                pagination: {
                  take: pagination.take,
                  page: pagination.page,
                  total: response.total,
                },
              });
            })
            .catch((error) => console.log(error));
          break;
      }
    },
    [
      activitiesListType,
      currentRange.value,
      currentReferralsListStatus,
      currentReferralsListPerson,
      currentReferralsListType,
      loggedUser.email,
      patientId,
      patientSearch,
      selectedEntityType.entity.identifier,
      selectedEntityType.state,
      activityStatus,
      activityDueStatus,
      updateState,
      careTeamIds,
      patientsListPerson,
    ]
  );

  const getSummaries = useCallback(() => {
    API.activities
      .activitiesSummary(undefined, getFhirIdFromReferenceString(loggedUser.user_fhir_uri) ?? '')
      .then((summary) => {
        if (summary) {
          updateState({
            activitiesSummary: summary,
          });
        }
      });

    API.referrals.getCcmReferralsSummary(loggedUser.email ?? '').then((summary) => {
      if (summary) {
        updateState({
          referralsSummary: summary,
        });
      }
    });

    if (careTeamIds) {
      API.patients.getSummary(careTeamIds).then((summary) => {
        if (summary) {
          updateState({
            patientsSummary: summary,
          });
        }
      });
    } else {
      updateState({
        patientsSummary: { newCount: '0', pendingCount: '0', activeCount: '0' },
      });
    }
  }, [loggedUser, updateState, careTeamIds]);

  useEffect(() => {
    getEntities({
      pagination: { take: DEFAULT_PAGINATION_TAKE, page: 0 },
    });
    getSummaries();
  }, [
    activitiesViewType,
    activitiesListType,
    currentRange.option,
    currentRange.value,
    currentReferralsListType,
    currentReferralsListStatus,
    currentReferralsListPerson,
    getEntities,
    loggedUser,
    patientId,
    selectedEntityType.state,
    patientSearch,
    getSummaries,
  ]);
  return [
    {
      isLoading,
      selectedEntityType,
      patients: fhirPatients,
      entities: stateEntities,
      showAddEntityModal,
      showDeleteEntityModal,
      disableDeleteButtons,
      selectedEntity,
      patientSearch,
      activitiesViewType,
      activitiesListType,
      currentReferralsListPerson,
      currentReferralsListStatus,
      currentReferralsListType,
      patientsListPerson,
      patientId,
      shouldCompletedActivity,
      selectedPatient,
      currentRange,
      pagination,
      activitiesSummary,
      referralsSummary,
      patientsSummary,
      showAddCommunicationModal,
      referralId,
    },
    // actions
    {
      updateState,
      getEntities,
      acceptActivity,
      startActivity,
      cancelActivity,
      setSelectedEntity,
      setSelectedEntityAndState,
      onSaveEntity,
      onSaveMultipleEntities,
      onDeleteEntity,
      openAddEntityModal,
      getSummaries,
      toggleAddCommunicationModal,
    },
  ];
};

export default useController;
