import { 
  Autocomplete, 
  Card, 
  Container, 
  Grid, 
  IconButton, 
  Stack, 
  Table, 
  TableBody, 
  TableContainer, 
  TablePagination, 
  TextField, 
  Tooltip
} from "@mui/material";
import { debounce } from "lodash";
import agent from "src/api/agent";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import useTable from "src/hooks/useTable";
import { GroupAdd } from "@mui/icons-material";
import useSettings from "src/hooks/useSettings";
import { transformScope } from "src/utils/string";
import { PATH_DASHBOARD } from "src/routes/paths";
import IncidentRow from "./components/IncidentRow";
import useObjectState from "src/hooks/useObjectState";
import FilterDrawer from "src/components/FilterDrawer";
import { PaginateQuery } from "src/api/pagination/dtos";
import { useCallback, useEffect, useState } from "react";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { FilterList as FilterListIcon } from '@mui/icons-material';
import LoadingTableRow from "src/components/table/LoadingTableRow";
import { TableHeadCustom, TableNoData } from "src/components/table";
import useLocales from "src/hooks/useLocales";
import crsAcls from "src/utils/permissions/crs/crsAcls";
import { getRelatedAcls } from "src/utils/permissions/permission.utils";
import TableToolbar from "src/components/table/TableToolbar";
import { TABLE_HEAD_INCIDENT_LIST } from "src/sections/crs/common/table-head";
import { IncidentResponse } from "src/services/api/data-quality/IncidentService";
import { incidentService } from "src/services/api/data-quality";

interface filters {
  status: string[] | undefined;
  assigned: string[] | undefined;
  scope: string[] | undefined;
  type: string[] | undefined;
};
  
export default function IncidentList() {
  const { 
    page, 
    rowsPerPage, 
    selected, 
    setSelected, 
    onSelectRow, 
    onSelectAllRows, 
    onChangePage, 
    onChangeRowsPerPage, 
    setPage 
  } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });
  
  const { themeStretch } = useSettings();
  const { i18n } = useLocales();
  const user = useAuth().getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any>(null);
  const [allUsers, setAllUsers] = useState([]);
  const [scopes, setScopes] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [statuses, setStatuses] = useState<any>([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [valueAuto, setValueAuto] = useState<any>(null);
  const [openAssignTo, setOpenAssignTo] = useState(false);
  const [typeCatalog, setTypeCatalog] = useState<any>([]);

  const [{ status,assigned,scope,type },updateState ] = useObjectState<filters>({
    status: [],
    assigned: [],
    scope: [],
    type: [],
  });

  const getPaginateQuery = (
    page: number, 
    rowsPerPage: number, 
    search: string, 
    status: string[] | undefined,
    assigned: string[] | undefined,
    scope: string[] | undefined,
    type: string[] | undefined
  ) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    paginateQuery.filter = {};

    if(status && status.length > 0 ) {
      paginateQuery.filter.status = `$in:${status?.join(',')}`;
    }

    if (assigned && assigned.length > 0 && user?.userName) {
      paginateQuery.filter.assigned_to = `$in:${assigned?.join(',')}`;
    }

    if (scope && scope.length > 0) {
      const setScope = scope?.map((item: any) => item?.code);
      paginateQuery.filter.scope_code = `$in:${setScope?.join(',')}`;
    }

    if (type && type.length > 0) {
      const setType = type?.map((item: any) => item?.code);
      paginateQuery.filter.source_type = `$in:${setType?.join(',')}`;
    }

    return paginateQuery;
  };

  const getIncidents = async (
    page: number, 
    rowsPerPage: number, 
    search: string, 
    status: string[] | undefined,
    assigned: string[] | undefined,
    scope: string[] | undefined,
    type: string[] | undefined
  ) => {
    try {
      setIsLoading(true);
      const incidents = await incidentService.getAll(
        getPaginateQuery(page, rowsPerPage, search, status, assigned, scope, type)
      );
      setRows(incidents);
      setIsLoading(false);
    } catch (e) {
      console.log(
        `Something failed while trying to load incident list ${e}`
      );
    }
  };

  const getIncidentList = useCallback(debounce(getIncidents, 600), []);

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.getAllUsersByAcls(
        getRelatedAcls([crsAcls.DATA_QUALITY.INCIDENT.EDIT])
      );
      const uniqueUsers :any  = [];
      const userNamesSet = new Set();

      getAllUsers?.forEach((user :any ) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        if (!userNamesSet.has(fullName)) {
          uniqueUsers.push(user);
          userNamesSet.add(fullName);
        }
      });
      uniqueUsers.sort((a:any, b:any) => {
        const fullNameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const fullNameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return fullNameA.localeCompare(fullNameB);
      });

      setAllUsers(uniqueUsers);
    } catch (err) {
      console.log("error on fetching all users", err);
      setAllUsers([]);
    }
  };

  const fetchCatalogType = async () => {
    try {
      const catalog = await agent.Catalog.getCatalogByCode('INCIDENT_GROUP_TYPES');
      setTypeCatalog(catalog);
    } catch (error) {
      setTypeCatalog([]);
    }
  };

  const getStatusScope = () => {
    try {
      const statuss = rows?.data?.map((item: IncidentResponse) => item?.status) ?? [];
      const scopess = rows?.data?.map((item: IncidentResponse) => {
          const isNewScopeCodeFormat = (item.scope_code?.split?.('.')?.length ?? 0) > 1;
          if (isNewScopeCodeFormat) return { code: item.scope_code, label: item.scope_text };
          return { code: item.scope_code, label: transformScope(item.scope_code) };
      }) ?? [];
      setStatuses([...new Set(statuss)]);
      setScopes([...new Set(scopess)]);
    } catch (error) {
      setScopes([]);
      setStatuses([]);
    }
  };

  useEffect(() => {
    getStatusScope();
  }, [rows]);

  useEffect(() => {
    fetchAllUsers();
    fetchCatalogType();
  }, []);

  useEffect(() => {
      getIncidentList(page, rowsPerPage, search, status, assigned, scope, type);
  }, [page, rowsPerPage, search]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  const handlerBulkAssignTo = async(userToAssign: any) => {
    try {
      if(!userToAssign) return;
      await incidentService.bulkAssign({ dataSelected: selected, userToAssign });
      enqueueSnackbar('User was assigned');
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    } finally {
      setOpenAssignTo(false);
      setValueAuto(null);
      setSelected([])
      getIncidentList(page, rowsPerPage, search, status, assigned, scope, type);
    }
  };

  return (
    <Page title={i18n('incident.list.title', 'crs')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('incident.list.title', 'crs')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('menu.dataQuality', 'crs')}`},
            { name: `${i18n('incident.list.title', 'crs')}`, href: PATH_DASHBOARD.dataQuality.incident },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                <Grid container xs={6} justifyContent="flex-end">
                  <Tooltip title="Assign To">
                    <IconButton
                      sx={{ my: 2 }}
                      onClick={() => setOpenAssignTo(true)}
                      disabled={selected.length > 0 ? false : true}
                    >
                      <GroupAdd sx={{ color: selected.length > 0 ? '#00AB55' : '' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Filters">
                    <IconButton
                      onClick={() => setOpenFilters(true)}
                      sx={{ my: 2 }}
                    >
                      <FilterListIcon htmlColor="#8f9993" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom 
                    headLabel={TABLE_HEAD_INCIDENT_LIST} 
                    rowCount={rows ? rows.meta.totalItems : 0}
                    numSelected={selected.length}
                    isLoading={isLoading}
                    onSelectAllRows={(checked) => {
                      onSelectAllRows(
                        checked,
                        rows?.data?.map((row: any) => row.id)
                      );
                    }}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows?.data?.length ? (
                        rows?.data?.map((row: any) => 
                          <IncidentRow 
                            key={row.id} 
                            row={row} 
                            selected={selected.includes(row.id)}
                            onSelectRow={() => onSelectRow(row.id)}
                          />
                        )
                      ) : (
                        <TableNoData isNotFound={true}/>
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_INCIDENT_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid xs={12}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={rows ? rows.meta.totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Card>
          </Grid>
          <FilterDrawer 
            title='Assign To' 
            open={openAssignTo}
            anchor={'right'} 
            onApplyButtonClick={() => {
              handlerBulkAssignTo(valueAuto);
            }} 
            onCloseIconButtonClick={() => {
              setOpenAssignTo(false)
            }}
            onClearAllButtonClick={() => { 
              setValueAuto(null) 
            }} 
          >
            <br/>
            <Autocomplete
              id="combo-box-assign-to"
              value={valueAuto}
              onChange={(_, user) => {
                setValueAuto(user);
              }}
              getOptionLabel={(user: any) => user ?? ''}
              options={ allUsers.map((user: any) => user?.email) }
              renderInput={(params) => 
                <TextField  
                  label="Users"
                  {...params}
                />
              }
            />
          </FilterDrawer>
          <FilterDrawer 
            title='Filters' 
            open={openFilters}
            anchor={'right'} 
            onApplyButtonClick={() => {
              getIncidentList(page, rowsPerPage, search, status, assigned, scope, type)
            }} 
            onCloseIconButtonClick={() => {
              setOpenFilters(false)
            }}
            onClearAllButtonClick={() => { 
              updateState({
                status: [],
                assigned: [],
                scope: [],
                type: [],
              }) 
            }} 
          >
            <br/>
            <Autocomplete
              id="combo-box-status"
              value={status}
              fullWidth
              multiple
              onChange={(_, newValue: any) => {
                updateState({ status: newValue });
              }}
              getOptionLabel={(status: any) => status ?? ''}
              options={statuses ?? []}
              defaultValue={[]}
              renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
            />
            <br/>
            <Autocomplete
              id="combo-box-assigned"
              value={assigned}
              fullWidth
              multiple
              onChange={(_, newValue: any) => {
                updateState({ assigned: newValue });
              }}
              getOptionLabel={(user: any) => user ?? ''}
              options={allUsers.map((user: any) => user?.email) ?? []}
              defaultValue={[]}
              renderInput={(params) => <TextField {...params} label="Assigned" variant="outlined" />}
            />
            <br/>
            <Autocomplete
              id="combo-box-scope"
              value={scope}
              fullWidth
              multiple
              onChange={(_, newValue: any) => {
                updateState({ scope: newValue });
              }}
              getOptionLabel={(scope: any) => scope?.label ?? ''}
              options={scopes ?? []}
              defaultValue={[]}
              renderInput={(params) => <TextField {...params} label="Scope" variant="outlined" />}
            />
            <br/>
            <Autocomplete
              id="combo-box-type"
              value={type}
              fullWidth
              multiple
              onChange={(_, newValue: any) => {
                updateState({ type: newValue });
              }}
              getOptionLabel={(type: any) => type?.label ?? ''}
              options={typeCatalog?.map((type: any) => 
                ({ code: type?.valueCode, label: type?.valueDisplayName })) ?? []}
              defaultValue={[]}
              renderInput={(params) => <TextField {...params} label="Type" variant="outlined" />}
            />
          </FilterDrawer>
        </Grid>
      </Container>
    </Page>
  );
}