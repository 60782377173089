import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { DirectoryProviderLookUp } from "src/@types/crs/providerDirectory";
import { OnProviderAction, OnProviderAsyncAction, getColumns } from "./getColumns";

const useStyles = makeStyles({
  rowCursor: {
    "& .MuiDataGrid-row:hover": {
      cursor: "pointer",
    },
  },
});

export interface DirectoryProviderGridProps {
  providers: DirectoryProviderLookUp[];
  isLoading: boolean;
  isAdding: boolean;
  onPreview: OnProviderAction;
  onAdd: OnProviderAsyncAction;
  onProviderAction?: OnProviderAsyncAction;
  providerActionButtonIcon?: React.ReactNode;
  providerActionButtonTitle?: string;
  isCreationMode: boolean;
}

const DirectoryProviderGrid = ({
  providers,
  isLoading,
  isAdding,
  onAdd,
  onPreview,
  onProviderAction,
  providerActionButtonIcon,
  providerActionButtonTitle,
  isCreationMode,
}: DirectoryProviderGridProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const styles = useStyles();
  return (
    <>
      <DataGrid
        style={{ height: "92vh" }}
        rowHeight={160}
        className={styles.rowCursor}
        rows={providers}
        localeText={{ noRowsLabel: "No matches found" }}
        getRowId={({ uuid }) => uuid}
        onRowDoubleClick={(params) => {
          onPreview(params?.row as DirectoryProviderLookUp);
        }}
        columns={getColumns({
          isLoading: isAdding,
          isDisabled: isLoading,
          onAdd,
          onProviderAction,
          providerActionButtonIcon,
          providerActionButtonTitle,
          isCreationMode,
        })}
        isRowSelectable={() => false}
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[25, 50, 100]}
        pageSize={rowsPerPage}
        onPageSizeChange={(pageChange: any) => {
          setRowsPerPage(pageChange.pageSize);
        }}
        loading={isLoading}
        pagination
      />
    </>
  );
};

export default DirectoryProviderGrid;