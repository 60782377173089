import { WrappedPatient } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { CustomModalBasicProps } from '../../../../../components/CustomModal';
import { WrappedServiceRequest } from '../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedTask } from '../../../../../@nicheaim/fhir-base/wrappers/Task';
import TaskModal from '../../../case/components/TasksGrid/TaskModal';

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  serviceRequest?: WrappedServiceRequest | null;
  task?: WrappedTask | null;
  open: boolean;
  handleSave: (data: any) => void;
  onClose: () => void;
}

const EditTaskDialog = ({ patient, serviceRequest, task, open, onClose, handleSave }: Props) => (
  <>
    <TaskModal
      taskToEdit={task as WrappedTask}
      onSuccessfulEdit={handleSave}
      onSuccessfulCreation={handleSave}
      patientExternal={patient}
      open={open}
      onClose={onClose}
    />
  </>
);

export default EditTaskDialog;
