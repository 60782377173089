// @ts-nocheck
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogTitle,
    DialogActions,
    Grid,
    Stack,
    TextField,
    Typography,
    Chip,
    Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import produce from 'immer';
import { camelCase } from 'lodash';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { MenuItem } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import { FHIR_CATEGORIES } from 'src/config';
import Iconify from 'src/components/Iconify';
import { Languages } from 'src/@types/crs/patient';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Language from 'src/sections/crs/common/Language';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { Group, PatientGender } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { checkAclValidation, ViewPersonalDetails, getRelatedAcls, EditPersonalDetails } from 'src/utils/permissions/permission.utils';
import { useGroup, useGroups, usePatients, usePatient, useValueSet } from 'src/@nicheaim/fhir-react';
import { GroupWrapper } from 'src/@nicheaim/fhir-base/wrappers/Group';
import { Option } from 'src/@types/crs/case'
import useLocales from 'src/hooks/useLocales';

type FormValue = {
  name: string;
  code: string[];
  identifier: string;
  type: string;
  identifiervalue: string;
};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  onClose: VoidFunction;
};

export default function AddGroup({ patient, open, onClose }: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const [, { create: createGroup }] = useGroups({ map: GroupWrapper });
  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });
  const [ethnicityCategories] = useValueSet('omb-ethnicity-category', { map: ValueSetWrapper });
  const [raceCategories] = useValueSet('omb-race-category', { map: ValueSetWrapper });
  const [genderCategories] = useValueSet('us-core-birthsex', { map: ValueSetWrapper });
  const [simpleLanguage] = useValueSet('simple-language', { map: ValueSetWrapper });
  const [codeGroup] = useValueSet('ph-group-codes', { map: ValueSetWrapper });
  const [codeIdentifier] = useValueSet('ph-group-identifiers', { map: ValueSetWrapper });
  const { i18n } = useLocales();

  const defaultValues = useMemo(
    () =>
    ({
      name: '',
      code: [],
      identifier: '',
      identifiervalue: '',
      type: 'person',
    } as FormValue),
    [patient]
  );

  const EventSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.array().of(Yup.string()),
    identifier: Yup.string(),
    type: Yup.string(),
    identifiervalue: Yup.string(),
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, watch } = methods;

  const { code } = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: FormValue) => {

    const arrayCode: any = codeGroup?.asListAll() ?? [];
    const filterCode = arrayCode?.filter(obj => code?.includes(obj.display));
    const filterCodeUnique: any[] = filterCode.reduce((result, obj) => {
      if (!result.some((item) => item.code === obj.code)) {
        result.push(obj);
      }
      return result;
    }, []);
    try {
      await createGroup({
        resourceType: 'Group',
        name: data.name,
        active: true,
        actual: true,
        type: 'person',
        ...(filterCodeUnique?.length > 0 && 
          { code: { coding: filterCodeUnique}}
        ),
        member: [
          {
            entity: {
              reference: `Patient/${patient.id}`,
              type: "Patient"
            },
            inactive: false
          }
        ],
        identifier: [
          {
            system: `https://careflow2.nicheaim.com/group/${data?.identifier?.toLowerCase().replace(/\s+/g, '-')}`,
            type: {
              text: `${data?.identifier}`
            },
            value: `${data.identifiervalue}`
          }
        ],
      });
      handleClose();
      enqueueSnackbar('Group Created Successfully');
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };

  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{i18n('patients.details.groups.titleAddGroup', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">{i18n('patients.details.groups.name', 'crs')}</Typography>
                <RHFTextField name="name" label={`${i18n('patients.details.groups.typename', 'crs')}*`} />

                <Typography variant="body2">{i18n('patients.details.groups.code', 'crs')}</Typography>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={codeGroup?.asListAll()?.map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="Code" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">{i18n('patients.details.groups.title', 'crs')}</Typography>
                <RHFSelect name="identifier" label="Identifier">
                  <MenuItem disabled></MenuItem>
                  {codeIdentifier?.asListAll()?.map((option) => (
                    <MenuItem key={option?.code} value={option?.display}>
                      {option?.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">{i18n('patients.details.groups.identifier', 'crs')}</Typography>
                <RHFTextField name="identifiervalue" label={i18n('patients.details.groups.identifierValue', 'crs')} />

                <Typography variant="body2">{i18n('patients.details.groups.type', 'crs')}</Typography>
                <RHFTextField disabled name="type" label={i18n('patients.details.groups.groupType', 'crs')} />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>

              <Button variant="contained" color="info" type="submit">
                {i18n('submit')}
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};