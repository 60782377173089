import { createWrapper } from '../../fhir-react/base';
import { WrappedPractitioner } from './Practitioner';
import { WrappedOrganization } from './Organization';

export const PractitionerRoleWrapper = createWrapper('PractitionerRole', (practitionerRole) => ({
  getPractitionerName: (practitioners?: WrappedPractitioner[]) => {
    const practitioner = practitioners?.find?.(
      ({ id }) => id === practitionerRole?.practitioner?.reference?.split?.('/')?.[1]
    );
    if (practitioner) return practitioner.getFullName() ?? '';
    return practitionerRole?.practitioner?.display ?? '';
  },
  getPractitionerId: () => practitionerRole?.practitioner?.reference?.split?.('/')?.[1] ?? '',

  getOrganizationName: (organizations?: WrappedOrganization[]) => {
    const organization = organizations?.find?.(
      ({ id }) => id === practitionerRole?.organization?.reference?.split?.('/')?.[1]
    );
    if (organization) return organization?.name ?? '';
    return practitionerRole?.organization?.display ?? '';
  },

  getOrganizationId: () => practitionerRole?.organization?.reference?.split?.('/')?.[1] ?? '',
  getRoleName: () => practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
  getRoleId: () => practitionerRole?.code?.[0]?.coding?.[0]?.code ?? '',
  getIdentifier: () => ({
    identifier: practitionerRole?.identifier?.[0]?.value ?? null,
    type: practitionerRole?.identifier?.[0]?.system ?? null,
  }),
}));

export type WrappedPractitionerRole = ReturnType<typeof PractitionerRoleWrapper>;
