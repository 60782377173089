import { Card } from "@mui/material";
import ExpandableCard from "src/components/common/ExpandableCard";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ListTable } from "../patient/components/patient-engagement-list-table/ListTable";
import useLocales from "src/hooks/useLocales";

type Props = {
  patient: WrappedPatient | null;
};

export default function EngagementHub({ patient }: Props) {
  const { i18n } = useLocales();
  return (
    <Card sx={{ py: 2, boxShadow: 'none' }}>
      <ExpandableCard 
        title={`${i18n('patients.title', 'crs')} Engagement`} 
        sxProp={{ m: 1 }} 
        openCollapseExternal={true}
      >
        <ListTable patient={patient} />
      </ExpandableCard>
    </Card>
  );
}
