import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSnackbar } from 'notistack';
import useTable from 'src/hooks/useTable';
import { TableHeadCustom } from 'src/components/table';
import RelatedPersonRow from './relatedPersons/RelatedPersonRow';
import RelatedPersonAction from './relatedPersons/RelatedPersonAction';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_RELATED_PERSON } from 'src/sections/crs/common/table-head';
import { useRelatedPersons, useValueSet } from '../../../../@nicheaim/fhir-react';
import { ValueSetWrapper } from '../../../../@nicheaim/fhir-base/wrappers/ValueSet';
import { RelatedPerson } from '../../../../@nicheaim/fhir-base/mappings/RelatedPerson';
import { RelatedPersonWrapper } from '../../../../@nicheaim/fhir-base/wrappers/RelatedPerson';
import { translateTableHead } from '../../helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import useLocales from 'src/hooks/useLocales';
import { Add } from '@mui/icons-material';

type Props = {
  patient: WrappedPatient | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export function RelatedPersons({ patient, open, setOpen }: Props) {

  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [
    relatedPersons,
    { create: createRelatedPerson, update: updateRelatedPerson, refresh: refreshRelatedPerson },
  ] = useRelatedPersons({
    filter: {
      patient: patient?.id,
    },
    map: RelatedPersonWrapper,
  });

  const [relationshipValueSet] = useValueSet('relatedperson-relationshiptype', {
    map: ValueSetWrapper,
  });

  const handleRelatedPerson = async (data: RelatedPerson) => {
    try {
      if (data?.id) {
        await updateRelatedPerson(data);
      } else {
        await createRelatedPerson(data);
      }

      enqueueSnackbar(data?.id ? 
        `${i18n('patients.relatedPersons.titleAddRelatedPersons', 'crs')} was updated` : 
        `${i18n('patients.relatedPersons.titleAddRelatedPersons', 'crs')} was created`);
      refreshRelatedPerson();
    } catch (error) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    }
  };

  return (
    <>
      <Stack sx={{ m: 1 }}>
        <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.RELATED_PERSONS.ADD] }) && (
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button
                size="small"
                sx={{ height: '36px' }}
                onClick={() => setOpen(true)}
                startIcon={<Add />}
                style={{
                  position: 'absolute',
                  top: '25px',
                  right: '25px',
                }}
              >
                Add {i18n('patients.details.relatedPersons.titleAddRelatedPersons', 'crs')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      <TableContainer>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_RELATED_PERSON, 'crs')} />
          <TableBody>
            {!isEmpty(relatedPersons) ? (
              relatedPersons
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <RelatedPersonRow
                    key={'rel-p-' + row?.id}
                    row={row}
                    patient={patient}
                    handleRelatedPerson={handleRelatedPerson}
                    relationshipValueSet={relationshipValueSet}
                  />
                ))
            ) : (
              <TableCell colSpan={TABLE_HEAD_RELATED_PERSON?.length}>
                <Typography variant="body2" align="center">
                  No rows
                </Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={relatedPersons?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <RelatedPersonAction
        title={i18n('patients.details.relatedPersons.titleAddRelatedPersons', 'crs')}
        patient={patient}
        relatedPerson={null}
        open={open}
        onClose={() => setOpen(false)}
        handleRelatedPerson={handleRelatedPerson}
        relationshipValueSet={relationshipValueSet}
      />
    </>
  );
}
