import { Box, Button, CircularProgress, Container, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import CustomDrawer, { CustomDrawerProps } from '../../../../../components/CustomDrawer';

import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { CodeableConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getFormattedDatePeriod } from 'src/utils/dates';
import { WrappedGoal } from '../../../../../@nicheaim/fhir-base/wrappers/Goal';
import { Option } from '../../../../../@types/crs/case';
import { spreadSxProp } from '../../../../../utils/cssStyles';

export type OnObservationUnlink = (
  observation: WrappedObservation,
  goal: WrappedGoal
) => Promise<boolean>;

interface GoalObservationDrawerProps extends Omit<CustomDrawerProps, 'title' | 'anchor'> {
  observationExternal?: WrappedObservation | null;
  goalExternal?: WrappedGoal | null;

  observationId?: string;
  onUnlink?: OnObservationUnlink;
}

const { valueTextFontSize, blackTextColor }: { [k: string]: SxProps } = {
  valueTextFontSize: {
    fontSize: '0.8rem',
  },
  blackTextColor: {
    color: '#212b36',
  },
};

const GoalObservationDrawer = ({
  onUnlink,
  observationExternal,
  goalExternal,
  onCloseIconButtonClick,
  ...props
}: GoalObservationDrawerProps) => {
  const observation = useMemo(() => {
    if (observationExternal) return observationExternal;
    return null;
  }, [observationExternal]);

  const goal = useMemo(() => {
    if (goalExternal) return goalExternal;
    return null;
  }, [goalExternal]);

  function getValueFromCodeableConcept(codeableConcept: CodeableConcept) {
    if (codeableConcept.text) {
      return codeableConcept.text;
    }

    if (codeableConcept.coding) {
      const res = codeableConcept.coding.find((item) => item.display || item.code);

      if (res?.display) {
        return res?.display;
      } else if (res?.code) {
        return res.code;
      }
    }

    return '-';
  }

  const observationValue = useMemo(() => {
    if (
      !observation ||
      (!observation.valueInteger &&
        !observation.valueString &&
        !observation.valueBoolean &&
        !observation.valueCodeableConcept)
    ) {
      return '-';
    }

    if (observation.valueInteger) {
      return String(observation.valueInteger);
    } else if (observation.valueBoolean) {
      return String(observation.valueBoolean);
    } else if (observation.valueString) {
      return String(observation.valueString);
    } else if (observation.valueCodeableConcept) {
      return getValueFromCodeableConcept(observation.valueCodeableConcept);
    }

    return '-';
  }, [observation]);

  const observationDetails: LabelValueProps[] = [
    {
      label: 'Code',
      value: observation?.code?.coding?.[0]?.code ?? '',
    },
    {
      label: 'Category',
      value: observation?.category?.[0]?.coding?.[0]?.display ?? '',
    },
    {
      label: 'Value',
      value: observationValue,
    },
    {
      label: 'Period',
      value: getFormattedDatePeriod({
        start: observation?.effectivePeriod?.start,
        end: observation?.effectivePeriod?.end,
      }),
    },
  ];

  return (
    <CustomDrawer
      {...props}
      title="Observation Details"
      anchor="right"
      containerSx={{ width: '25vw' }}
      contentContainerSx={{ padding: 0 }}
      onCloseIconButtonClick={onCloseIconButtonClick}
    >
      {observation ? (
        <>
          <Container sx={{ backgroundColor: '#f4f6f8' }}>
            <Section title="Observation">
              <Typography sx={[blackTextColor, valueTextFontSize]}>
                {observation?.code?.text ?? ''}
              </Typography>
            </Section>
            <Section title="Details">
              {observationDetails.map((detail) => (
                <>
                  {detail?.label !== 'Status' && detail?.label !== 'View Detail' ? (
                    <LabelValue key={detail.label} {...detail} />
                  ) : null}
                </>
              ))}
            </Section>
          </Container>
          {!!(onUnlink && goal) && (
            <Container sx={{ mt: 3 }}>
              <Button
                sx={{ height: 48 }}
                fullWidth
                onClick={async () => {
                  const response = await onUnlink?.(
                    observation as WrappedObservation,
                    goal as WrappedGoal
                  );
                  if (response) onCloseIconButtonClick();
                }}
                variant="contained"
                color="error"
              >
                Unlink
              </Button>
            </Container>
          )}
        </>
      ) : (
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      )}
    </CustomDrawer>
  );
};

type LabelValueKeys = Option<string | React.ReactNode>;

interface LabelValueProps extends LabelValueKeys {
  labelSx?: SxProps;
}

const LabelValue = ({ label, value, labelSx }: LabelValueProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: 2 }}>
    <Typography sx={[valueTextFontSize, { flex: 1, color: '#637381' }, ...spreadSxProp(labelSx)]}>
      {label}
    </Typography>
    <Typography sx={[valueTextFontSize, blackTextColor, { flex: 2 }]}>{value}</Typography>
  </Box>
);

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <Box sx={{ my: 3 }}>
    <Typography sx={{ fontWeight: 'bold', mb: 3 }}>{title}</Typography>
    {children}
  </Box>
);

export default GoalObservationDrawer;
