import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { Row } from './Row';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';
import useTable from 'src/hooks/useTable';
import { AppointmentForm } from './AppointmentForm';
import { TableHeadCustom } from 'src/components/table';
import { useAppointments } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Appointment } from 'src/@nicheaim/fhir-base/mappings/Appointment';
import { TABLE_HEAD_APPOINTMENT } from 'src/sections/crs/common/table-head';
import {
  AppointmentWrapper,
  WrappedAppointment,
} from 'src/@nicheaim/fhir-base/wrappers/Appointment';
import { v4 as uuidv4 } from 'uuid';

import { translateTableHead } from 'src/sections/crs/helpers/common';
import useLocales from 'src/hooks/useLocales';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  patient: WrappedPatient | null;
};

export function AppointmentList({ patient }: Props) {
  const { i18n } = useLocales();

  const { enqueueSnackbar } = useSnackbar();
  const [add, setOpenAdd] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});
  const [
    appointments,
    { create: createAppointment, update: updateAppointment, refresh: refreshAppointment },
  ] = useAppointments({
    filter: { patient: patient?.id },
    autofetch: !!patient?.id,
    map: AppointmentWrapper,
  });

  const handleAppointment = async (data: Appointment) => {
    try {
      if (data?.id) {
        await updateAppointment(data);
      } else {
        await createAppointment(data);
      }

      enqueueSnackbar(data?.id ? 'Appointment was updated' : 'Appointment was created');
      refreshAppointment();
    } catch (error) {
      enqueueSnackbar('An occurred an error', { variant: 'error' });
    }
  };

  return (
    <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 0 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                <Grid item xs={10}>
                  {/* <Typography variant="subtitle1">Appointments</Typography> */}
                </Grid>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.APPOINTMENT.ADD] }) && (
                  <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                      onClick={() => setOpenAdd(true)}
                    >
                      {i18n('patients.details.appointments.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_APPOINTMENT, 'crs')} />
              <TableBody>
                {!isEmpty(appointments) ? (
                  appointments
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: WrappedAppointment) => (
                      <Row
                        key={uuidv4()}
                        row={row}
                        patient={patient}
                        handleAppointment={handleAppointment}
                      />
                    ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_APPOINTMENT?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={appointments?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <AppointmentForm
        patient={patient}
        appointment={null}
        open={add}
        onClose={() => setOpenAdd(false)}
        handleAppointment={handleAppointment}
      />
    </Card>
  );
}
