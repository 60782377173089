import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { app } from 'src/crs/firebase/firebase-init';

const Example = () => {
  /* Code exmaple to retrieve a collection in firestore */
  useEffect(() => {
    const getUsersCollection = async () => {
      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, 'YoCare', 'dev', 'Users'));
      querySnapshot.forEach((doc) => {
        console.log('Firebase User:', doc.id, ' => ', doc.data());
      });
    };
    getUsersCollection();
  }, []);
  return <Typography variant="body2">Site under construction</Typography>;
};

export default Example;
