import { useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { GridQueryState, Sorting } from '../types';
import useObjectState, { UpdateState } from 'src/hooks/useObjectState';
import { GridSortModel } from '@mui/x-data-grid';
import { QueryConstraint, QueryDocumentSnapshot } from 'firebase/firestore';
import { FormikProps, FormikValues, useFormik } from 'formik';

export interface GridStateHandlers {
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handlePageChange: (page: number) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  onStartAfterInternalChange: (startAfter: QueryDocumentSnapshot | undefined) => void;
  onRowsTotalCountChange: (rowCount: number) => void;
  setIsFilterDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setQueryFilters: Dispatch<SetStateAction<QueryConstraint[]>>;
  setSelectedFilterName: Dispatch<SetStateAction<string | null>>;
}

export interface GridState<T> extends GridQueryState {
  rowsTotalCount: number | undefined;
  isFilterDrawerOpen: boolean;
  queryFilters: QueryConstraint[];
  filtersForm: FormikProps<T>;
  selectedFilterName: string | null;
}

export interface CursorDirectory {
  id: number;
  cursor: QueryDocumentSnapshot | undefined;
}

const useGridState = <T extends FormikValues>(
  defaultSorting?: Sorting,
  filtersInitialValues?: T
): [GridState<T>, GridStateHandlers, UpdateState<GridState<T>>] => {
  const [state, updateState] = useObjectState<GridQueryState>({
    page: 1,
    rowsPerPage: 25,
    orderBy: defaultSorting?.orderBy ?? null,
    orderDirection: defaultSorting?.orderDirection ?? null,
    startAfter: undefined,
  });

  const [cursorDirectory, setCursorDirectory] = useState<CursorDirectory[]>([]);
  const [startAfterInternal, setStartAfterInternal] = useState<QueryDocumentSnapshot>();
  const [rowsTotalCount, setRowsTotalCount] = useState<number>();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedFilterName, setSelectedFilterName] = useState<string | null>(null);

  const filtersForm = useFormik<T>({
    initialValues: filtersInitialValues ?? ({} as T),
    onSubmit: () => {},
  });
  const [queryFilters, setQueryFilters] = useState<QueryConstraint[]>([]);

  const handleChangeRowsPerPage: GridStateHandlers['handleChangeRowsPerPage'] = useCallback(
    (rowsPerPage) => {
      updateState({ rowsPerPage });
    },
    [updateState]
  );

  const handlePageChange: GridStateHandlers['handlePageChange'] = useCallback(
    (page) => {
      const newPage = page + 1;
      const cursorFromDirectory = cursorDirectory.find(({ id }) => id === newPage);
      let cursorStartAfter: QueryDocumentSnapshot | undefined = startAfterInternal;
      if (!cursorFromDirectory) {
        setCursorDirectory([...cursorDirectory, { id: newPage, cursor: startAfterInternal }]);
      } else {
        cursorStartAfter = cursorFromDirectory.cursor;
      }
      if (newPage === 1) {
        cursorStartAfter = undefined;
        setStartAfterInternal(cursorStartAfter);
      }

      updateState({ page: newPage, startAfter: cursorStartAfter });
    },
    [updateState, startAfterInternal, cursorDirectory]
  );

  const handleSortModelChange: GridStateHandlers['handleSortModelChange'] = useCallback(
    (sortModel) => {
      const { field, sort } = sortModel?.[0] ?? {};
      updateState({
        orderBy: field ?? null,
        orderDirection: sort ?? null,
      });
    },
    [updateState]
  );

  return [
    {
      ...state,
      rowsTotalCount,
      isFilterDrawerOpen,
      queryFilters,
      filtersForm,
      selectedFilterName,
    },
    {
      setIsFilterDrawerOpen,
      handleChangeRowsPerPage,
      handlePageChange,
      handleSortModelChange,
      setQueryFilters,
      setSelectedFilterName,
      onStartAfterInternalChange: setStartAfterInternal,
      onRowsTotalCountChange: setRowsTotalCount,
    },
    updateState,
  ];
};

export default useGridState;
