import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Card,
} from '@mui/material';
import { useEffect, useState } from 'react';

const DispositionDialog = ({
  isOpen,
  handlerDisposition,
  handlerIsOpenDispositionDialog,
  dataHandler,
  data,
  type,
}: any) => {
  const [openDialog, setOpenDialog] = useState(isOpen);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  const handleCloseForm = () => {
    setOpenDialog(false);
    handlerIsOpenDispositionDialog(false);
  };

  const handleOnSaveDisposition = (e: any) => {
    handlerDisposition({
      e,
      ...dataHandler
    });
    setTimeout(() => {
      handleCloseForm();
    }, 1500);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseForm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="confirm-dialog-title">{dataHandler?.title}</DialogTitle>
      <Card sx={{ m: 2 }}>
        <DialogContent>{dataHandler?.message}</DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseForm} variant="contained">
            Cancel
          </Button>        
          <Button
            onClick={(e) => {
              if (type === 'CASE') {
                handleOnSaveDisposition(e);
                return;
              }
              handleOnSaveDisposition(data?.data?.[0] ?? 'new');
            }}
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default DispositionDialog;
