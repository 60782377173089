import Catalog from 'src/sections/careflow/rounding/Model/Catalog';
import client from '../_client';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import BedAssignment from 'src/sections/careflow/rounding/Model/BedAssignment';

export type BedPaginationType = {
  take: number;
  skip: number;
}

export type getBedsPropsType = {
  search: string | null;
  status?: string;
  locationFhirId?: string | null;
  pagination: BedPaginationType
}

export type CensusSaveBedPropsType = {
  id?: number;
  status: boolean;
  locationFhirId: string;
  locationFhirName: string;
  identifier: string;
  createdByName: string;
}

export async function getBeds({
  search,
  status,
  locationFhirId,
  pagination, 
}: getBedsPropsType) {
  const { data } = await client.get<{ beds: Bed[]; total: number }>(
    `/ccm/rounding/bed/list?status=${status}&search=${search}&take=${pagination?.take}&skip=${pagination?.skip}&location=${locationFhirId}`
  );

  return data;
}

export async function getBedExitDestinations() {
  const { data } = await client.get<Catalog[]>(`/ccm/rounding/bed/exit-destionations`);

  return data;
}

export async function getCurrentBedAssignment(patientFhirId: string) {
  const { data } = await client.get<BedAssignment>(`/ccm/rounding/bed/current-assignment/${patientFhirId}`);

  return data;
}

export async function getLocationHistory(patientFhirId: string) {
  const { data } = await client.get<BedAssignment[]>(`/ccm/rounding/bed/location-history/${patientFhirId}`);

  return data;
}

export async function censusSaveBed(params: CensusSaveBedPropsType) {
  let url = `ccm/rounding/bed/save`
  if (params.id) {
    url = `ccm/rounding/bed/save/${params.id}`;
  }
  const { data } = await client.post<Bed>(url, {
    ...params 
  });

  return data;
}