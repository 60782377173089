import { ButtonBase, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { getResizedText } from '../../../utils/utilities';
import {
  AUTHOR_CHARACTER_LIMIT,
  DATE_CHARACTER_LIMIT,
  getFormattedDate,
  TEXT_CHARACTER_LIMIT,
} from './annotation-utils';

interface AnnotationsModalListCardProps {
  annotation: any;
  handleSelectAnnotation: (annotation: any) => void;
}

const AnnotationsModalListCard = ({
  annotation,
  handleSelectAnnotation,
}: AnnotationsModalListCardProps) => {
  const handleClick = (e) => {
    handleSelectAnnotation(annotation);
  };

  return (
    <Card
      sx={{
        minWidth: '100%',
        minHeight: '90%',
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        alignContent: 'center',
      }}
    >
      <CardActionArea onClick={handleClick} sx={{ width: '100%', height: '100%' }}>
        <CardContent sx={{ width: '100%', height: '100%' }}>
          <Grid container item xs={12} mr={2} pt={0.5}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {getResizedText(annotation?.authorString, AUTHOR_CHARACTER_LIMIT)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ color: (theme) => theme.palette.grey[500], textAlign: 'right' }}
            >
              <Typography variant="caption">{getFormattedDate(annotation?.time)}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mr={2}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[500] }}>
              {getResizedText(annotation?.text, TEXT_CHARACTER_LIMIT)}
            </Typography>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AnnotationsModalListCard;
