import { createContext } from 'react';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { MenuTaskHandler, TaskHandlerByType } from 'src/sections/crs/types';

export interface TaskContextData {
  rowDblClickHandlers?: TaskHandlerByType[];
  menuTaskHandlers?: MenuTaskHandler[];
  basedOn?: Reference[];
}

const initialTaskContextData: TaskContextData = {
  rowDblClickHandlers: [],
  menuTaskHandlers: [],
};

const TaskContext = createContext<TaskContextData>(initialTaskContextData);

export default TaskContext;
