import { Box, ListItemText, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Grid, List, ListItem } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import useLocales from '../../../hooks/useLocales';
import { Button } from '@mui/material';
import { isString } from 'lodash';
import { getResizedText, scrollbarStyle } from '../../../utils/utilities';
import { getFormattedDate, LIST_CHARACTER_LIMIT, LIST_SIZE } from './annotation-utils';
import moment from 'moment';

interface AnnotationsListProps {
  onViewAll: () => void;
  annotations: any[];
}

const CenteredListItemText = withStyles({
  root: {
    textAlign: 'center',
  },
})(ListItemText);

const AnnotationsList = ({ annotations, onViewAll }: AnnotationsListProps) => {
  const { i18n } = useLocales();
  const latestAnnotations = useMemo(() => annotations.slice(0, LIST_SIZE), [annotations]);

  return (
    // <Grid container sx={{ maxHeight: '10vh', overflowY: 'scroll', ...scrollbarStyle }}>
    <Grid container sx={{ maxHeight: '10vh' }}>
      {/* <Grid item xs={12}>
        {`${i18n('annotations.list.title', 'crs')} (${annotations.length})`}
      </Grid> */}
      {/* <Grid
        item
        xs={6}
        marginLeft={'auto'}
        textAlign={'right'}
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        <Typography variant="subtitle2">{`${i18n(
          'annotations.list.latest',
          'crs'
        )} ${LIST_SIZE}`}</Typography> */}
      {/* </Grid> */}
      {/* <Grid item xs={12}>
        <List>
          {latestAnnotations.map((annotation, index) => (
            <ListItem
              key={`${annotation?.authorString}${annotation?.text}${annotation?.time}${index}`}
            >
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <ListItemText
                    secondaryTypographyProps={{
                      color: (theme) => theme.palette.grey[500],
                      variant: 'caption',
                    }}
                    secondary={getResizedText(annotation?.authorString, LIST_CHARACTER_LIMIT)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItemText
                    sx={{ textAlign: 'left', marginRight: 'auto' }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      color: (theme) => theme.palette.grey[500],
                    }}
                    secondary={`${getResizedText(
                      getFormattedDate(annotation?.time),
                      LIST_CHARACTER_LIMIT
                    )}:`}
                  />
                  <ListItemText
                    secondaryTypographyProps={{
                      variant: 'caption',
                      color: (theme) => theme.palette.grey[900],
                    }}
                    secondary={`${getResizedText(annotation?.text, LIST_CHARACTER_LIMIT)}`}
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Grid> */}
      <Grid item xs={12} textAlign={'center'}>
        <Button onClick={onViewAll}>{`${i18n('annotations.list.viewAll', 'crs')} (${
          annotations.length
        })`}</Button>
      </Grid>
    </Grid>
  );
};

export default AnnotationsList;
