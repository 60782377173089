import React, { useMemo } from 'react';
import { useCarePlans } from '../../../../../@nicheaim/fhir-react';
import useTable from '../../../../../hooks/useTable';
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Grid,
  Stack,
  Typography,
  Table,
} from '@mui/material';
import { TableHeadCustom } from '../../../../../components/table';
import { isEmpty } from 'lodash';
import { TABLE_HEAD_CARE_PLAN_PATIENT } from '../../../common/table-head';
import { useSnackbar } from 'notistack';
import { PatientGenericCaseRow } from './PatientGenericCarePlanRow';
import { CarePlanWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import {
  PatientGenericCarePlanDto,
  wrappedCarePlanToPatientGenericCarePlanDto,
} from './patientGenericCarePlan.dto';
import { translateTableHead } from 'src/sections/crs/helpers/common';

type Props = {
  patientId?: string;
};

export default function PatientGenericCarePlan({ patientId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});
  const [carePlans, { create: createCarePlan, refresh: refreshCarePlans }] = useCarePlans({
    filter: { patient: patientId },
    autofetch: !!patientId,
    map: CarePlanWrapper,
  });

  const data = useMemo(() => {
    if (carePlans) {
      return carePlans?.map((x) => wrappedCarePlanToPatientGenericCarePlanDto(x));
    }
    return [];
  }, [carePlans]);

  return (
    <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 1, mt: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 2 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                <Grid item xs={10}>
                  {/* <Typography variant="subtitle1">Care Plans</Typography> */}
                </Grid>
              </Grid>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel=
                {translateTableHead(TABLE_HEAD_CARE_PLAN_PATIENT, 'crs')}
              />
              <TableBody>
                {!isEmpty(data) ? (
                  data
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: PatientGenericCarePlanDto) => (
                      <PatientGenericCaseRow key={row?.id} row={row} />
                    ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_CARE_PLAN_PATIENT?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={carePlans?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}
