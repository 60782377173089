import { createContext, useState, ReactNode, useCallback } from 'react';

type RegisteredComponents = Record<string, JSX.Element>;

type DashboardRegistryContextType = {
  components: RegisteredComponents;
  registerComponent: (id: string, component: JSX.Element) => void;
};

const DashboardRegistryContext = createContext<DashboardRegistryContextType | undefined>(undefined);

type DashboardRegistryProviderProps = {
  children: ReactNode;
};

function DashboardRegistryProvider({ children }: DashboardRegistryProviderProps) {
  const [components, setComponents] = useState<RegisteredComponents>({});

  const registerComponent = useCallback(
    (id: string, component: JSX.Element) => {
      setComponents((prev) => ({ ...prev, [id]: component }));
    },
    []
  );

  return (
    <DashboardRegistryContext.Provider value={{ components, registerComponent }}>
      {children}
    </DashboardRegistryContext.Provider>
  );
};

export { DashboardRegistryContext, DashboardRegistryProvider};