import { useContext, useMemo } from 'react';
import { format, isSameDay } from 'date-fns';
import { Activity, ActivityStatus } from '../../../../model';
import { isRenderer } from '../../ActivityRenderers';
import { shortFormat } from '../../../../../../../utils/formatTime';
import { ActivityFilter } from './ActivityFilter';
import { getColumns, mapActivitiesToRows, Row } from './activity-list-table-utils';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import { ActivitiesContext, ActivitiesContextType } from '../../ActivitiesContext';
import ActivityContextMenu, { activityContextMenuItems } from '../../ActivityContextMenu';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import { useNavigate } from 'react-router';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { CCM_ACLS, checkAclValidation } from 'src/utils/permissions/permission.utils';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import { ContextMenuItemType } from 'src/sections/careflow/common/EntityContextMenu';

export function ActivityListTable({
  isLoading,
  activities,
  total,
  date,
  legend,
  now,
  isPatientView,
  patientId,
}: ActivityFilter & { now: Date }) {
  const user = useAuth();
  const navigate = useNavigate();
  const [codeSystem] = useCodeSystem('ccm-activity-contact-types');
  const patientStore = usePatientTabsStore();

  const activitiesContext: ActivitiesContextType = useContext(ActivitiesContext);
  const menuItems: ContextMenuItemType<ConcreteActivity>[] = useMemo(
    () => [
      {
        ...activityContextMenuItems.find((item) => item.id === 'accept'),
        onPress: (activity: any) => activitiesContext.acceptActivity(activity),
      },
      {
        ...activityContextMenuItems.find((item) => item.id === 'start'),
        onPress: async (activity: ConcreteActivity) => {
          const { patientFhirId, scriptId, taskId, id: activityId } = activity;

          if (!patientFhirId) return;
          await activitiesContext.startActivity(activity);

          if (!scriptId) return;

          patientStore.setCurrentPatient(patientFhirId);
          patientStore.openPatient(patientFhirId);

          navigate(`/dashboard/patient/${patientFhirId}/care-record/assessments/new/${scriptId}`, {
            state: {
              taskId,
              activityId,
            },
          });
        },
        rules: (activity: Activity) =>
          activity.status === ActivityStatus.READY && activity.scriptId ? true : false,
      },
      {
        ...activityContextMenuItems.find((item) => item.id === 'edit'),
        disabled: (activity) => {
          const roleCondition = !checkAclValidation({ acls: [CCM_ACLS.CCM_SYSTEM_ADMIN] });
          if (activity.status === ActivityStatus.COMPLETED) {
            return roleCondition && moment(activity?.completedDate).add(1, 'day') < moment();
          }
          return roleCondition && moment(activity?.createdAt).add(1, 'day') < moment();
        },
        onPress: (selectedActivity: any) =>
          activitiesContext.setSelectedEntity(new ConcreteActivity(selectedActivity)),
      },
    ],
    [activitiesContext, navigate, patientStore, user]
  );
  const rows = mapActivitiesToRows({
    activities,
    date,
    total,
    legend,
    isPatientView: isPatientView,
    patientId,
    contactTypes: codeSystem?.concept ?? [],
  });
  const columns = getColumns(isPatientView, date);

  return (
    <TableContainer sx={{ padding: 2 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" sx={{ ml: 1, fontWeight: 'bold' }}>
            {getTitle(now, date)}
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, mb: 2, fontWeight: 'light' }}>
            {getSubtitle(total)}
          </Typography>
        </Grid>
        {checkAclValidation({
          acls: [
            CCM_ACLS.CCM_SYSTEM_ADMIN,
            CCM_ACLS.CCM_ADMIN,
            CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.name,
            CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.WRITE,
          ],
        }) && (
          <Grid item>
            <IconButton onClick={() => activitiesContext.openAddEntityModal()}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Table>
        {columns && (
          <TableHead>
            <TableRow>
              {columns.map((col, idx) => (
                <TableCell key={idx}>
                  <Typography>{col.label}</Typography>
                </TableCell>
              ))}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {!isLoading &&
            rows?.map((row: Row, rowIdx) => (
              <TableRow key={rowIdx}>
                {row.values.map((value: any, colIdx) => (
                  <TableCell key={colIdx}>
                    <Typography>{value && renderValue(value)}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <ActivityContextMenu
                    activity={row.activity}
                    items={menuItems}
                    onUpdateActivity={activitiesContext.onCreateOrUpdateEntity}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!isLoading && activities.length === 0 && (
        <Grid alignItems="center">
          <Typography style={{ textAlign: 'center', paddingTop: '20px' }}>No Activities</Typography>
        </Grid>
      )}
      {isLoading && (
        <Box textAlign={'center'} marginTop={5}>
          <CircularProgress />
        </Box>
      )}
    </TableContainer>
  );
}

function getTitle(now: Date, date?: Date) {
  return date
    ? [isSameDay(date, now) ? 'Today' : format(date, 'eeee'), `(${shortFormat(date)})`].join(' ')
    : 'Activities';
}

function getSubtitle(total: number) {
  return [total, total === 1 ? 'Activity' : 'Activities'].join(' ');
}

function renderValue(value: any) {
  return isRenderer(value) ? value.render() : <Typography variant="body2">{value}</Typography>;
}
function openPatient(patientFhirId: any) {
  throw new Error('Function not implemented.');
}
