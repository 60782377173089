import client from './_client';
import { Permissions, Role, RoleAPI } from '../../sections/careflow/permissions/types';

const baseUrl = '/permissions';

export const getPermissions = async (): Promise<Permissions | null> => {
  try {
    const { data } = await client.get(baseUrl);
    return data;
  } catch (error) {
    return null;
  }
};

export const renameRole = async (id: number, name: string): Promise<Role | null> => {
  try {
    const { data } = await client.post(`${baseUrl}/role/rename`, {
      id,
      name,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const removeRole = async (id: number): Promise<boolean> => {
  try {
    const { status } = await client.delete(`${baseUrl}/role/${id}`);
    if (status !== 200) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const createRole = async (name: string, aclIds: number[]): Promise<RoleAPI | null> => {
  try {
    const { data } = await client.post(`${baseUrl}/role`, {
      name,
      aclIds,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const updateRoleAcl = async (id: number, aclIds: number[]): Promise<boolean> => {
  try {
    const { status } = await client.patch(`${baseUrl}/role/${id}`, {
      id,
      aclIds,
    });
    if (status !== 200) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const aclsLocalStorageKey = 'userACLs';

export const getAcls = async (id?: number) => {
  try {
    const { data } = await client.get(`auth/acls`, {
      params: {
        id,
      },
    });
    if (!data?.acls) return;
    localStorage.setItem(aclsLocalStorageKey, JSON.stringify(data?.acls));
  } catch (e) {
    console.log('error:', e);
  }
};

export const getAclsFromStorage = (): string[] => {
  try {
    const acls = localStorage.getItem(aclsLocalStorageKey);
    if (!acls) return [];
    return JSON.parse(acls);
  } catch (error) {
    return [];
  }
};

export const organizationsLocalStorageKey = 'userOrgs';

export const getUserOrganizations = async (id?: number) => {
  try {
    const { data } = await client.get(`organizations/user-organizations`);
    if (!data?.organizations) return;
    localStorage.setItem(organizationsLocalStorageKey, JSON.stringify(data?.organizations));
  } catch (e) {
    console.log('error:', e);
  }
};

export const getUserOrganizationsFromStorage = (): string[] => {
  try {
    const organizations = localStorage.getItem(organizationsLocalStorageKey);
    if (!organizations) return [];
    return JSON.parse(organizations);
  } catch (error) {
    return [];
  }
};
