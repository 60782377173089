import { 
  Box, 
  Button, 
  CircularProgress, 
  Drawer, 
  Grid, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  capitalize 
} from "@mui/material";
import { v4 as uuidV4 } from 'uuid';
import { useEffect, useState } from "react";
import { formatUSAddress } from "src/utils/fhir";
import { getFormattedDatePeriod } from "src/utils/dates";
import { practitionerService } from "src/crs/practitioner";
import { trimMultipleWhiteSpaces } from "src/utils/string";
import CloseIconButton from "src/components/CloseIconButton";
import { dateYearFormatWithAge } from "src/utils/formatTime";
import { NPI_PROVIDER_IDENTIFIER_CODE } from "../../constants";
import PracticeDetailPreviewDialog from "../PracticeDetailPreviewDialog";
import { PractitionerWrapper } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { Business, ContactMail, ContactPhone, OpenInNew, Place } from "@mui/icons-material";
import { Address, DirectoryProvider, Practice, Telecom } from "src/@types/crs/providerDirectory";
import { InfoItem, InfoSection, getPractitionersDetailLink } from "../GeneralPractitionerPreviewDrawer";
import { getGenderFromProvider, getPractitionersLinkedToProvider, mapProviderAddressToFhir } from "../helpers";

export interface ProviderDetailPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  providerId: string | null;
}

const ProviderDetailPreviewDialog = ({
  isOpen,
  onClose,
  providerId,
}: ProviderDetailPreviewDialogProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState<DirectoryProvider | null>(null);
  const [practitionerId, setPractitionerId] = useState<string | null>(null);
  const [isDetailsDialogOpen, setisDetailsDialogOpen] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState<Practice | null>(
    null
  );

  useEffect(() => {
    if (!providerId) {
      setIsLoading(false);
      return
    };
    const getDetails = async () => {
      setIsLoading(true);
      const providerDetails = await practitionerService.getProviderDetails(providerId);
      setProvider(providerDetails);
      setIsLoading(false);
    };
    getDetails();
  }, [providerId]);

  useEffect(() => {
    if (!providerId) return;
    const getLinkedPractitioner = async () => {
      const linkedPractitioners = await getPractitionersLinkedToProvider(
        providerId
      );
      const practitioner = PractitionerWrapper(linkedPractitioners[0]);
      if (practitioner?.id) setPractitionerId(practitioner.id);
    };
    getLinkedPractitioner();
  }, [providerId]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        sx={{ zIndex: 10000 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "auto",
            flex: 1,
          }}
        >
          <Box
            sx={{
              boxShadow: "8px -30px 24px rgba(22,28,36,0.16)",
              display: "flex",
              flexDirection: "column",
              width: "30vw",
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: "#E5E8EB",
                paddingY: 1,
              }}
            >
              <Grid
                width={"100%"}
                height={"100%"}
                container
                sx={{ paddingX: 3 }}
              >
                <Stack
                  style={{
                    width: "100%",
                  }}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  direction="row"
                >
                  <Typography variant="h6" color="text.primary">
                    {`Provider Details`}
                  </Typography>
                  <CloseIconButton
                    onClick={() => {
                      onClose?.();
                    }}
                  />
                </Stack>
              </Grid>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingX: 3,
                marginTop: 2.4,
              }}
            >
              {!isLoading ? (
                <Box>
                  <InfoItem label="NPI" value={provider?.npi} />
                  <InfoItem
                    label="Name"
                    value={trimMultipleWhiteSpaces(
                      `${provider?.first_name ?? ""} ${
                        provider?.middle_name ?? ""
                      } ${provider?.last_name ?? ""}`
                    ).trim()}
                  />
                  <InfoItem
                    label="Gender"
                    value={capitalize(getGenderFromProvider(provider?.gender))}
                  />
                  <InfoItem
                    label="Date of Birth"
                    value={provider?.dob && dateYearFormatWithAge(provider.dob)}
                  />
                  <InfoItem label="SSN" value={provider?.ssn} />
                  <InfoItem
                    label="Classification"
                    value={provider?.classification?.[0]?.name}
                  />
                  <Box sx={{ marginTop: 3 }} />
                  <InfoSection
                    title="Phones"
                    shouldShow
                    titleIcon={<ContactPhone />}
                  >
                    <Grid item xs={12}>
                      <TelecomTable
                        type={"phone"}
                        telecom={
                          provider?.telecom?.filter?.(
                            ({ type }) => type === "phone"
                          ) ?? []
                        }
                      />
                    </Grid>
                  </InfoSection>
                  <Box sx={{ marginBottom: 1.3 }} />
                  <InfoSection
                    title="Email"
                    shouldShow
                    titleIcon={<ContactMail />}
                  >
                    <Grid item xs={12}>
                      <TelecomTable
                        type={"email"}
                        telecom={
                          provider?.telecom?.filter?.(
                            ({ type }) => type === "email"
                          ) ?? []
                        }
                      />
                    </Grid>
                  </InfoSection>
                  <Box sx={{ marginBottom: 1.3 }} />
                  <InfoSection
                    title="Addresses"
                    shouldShow
                    titleIcon={<Place />}
                  >
                    <Grid item xs={12}>
                      <AddressTable addresses={provider?.address ?? []} />
                    </Grid>
                  </InfoSection>
                  <Box sx={{ marginBottom: 1.3 }} />
                  <InfoSection
                    title="Practices"
                    shouldShow
                    titleIcon={<Business />}
                  >
                    <Grid item xs={12}>
                      <PracticeTable
                        practices={provider?.practice ?? []}
                        onRowDblClick={(practice) => {
                          setisDetailsDialogOpen(true);
                          setSelectedPractice(practice);
                        }}
                      />
                    </Grid>
                  </InfoSection>
                  {/* {!!practitionerId && (
                    <Grid
                      container
                      justifyContent={"center"}
                      sx={{ marginTop: 4 }}
                    >
                      <Grid item xs={10}>
                        <Button
                          sx={{ marginBottom: 4 }}
                          fullWidth
                          onClick={() => {
                            window.open(
                              getPractitionersDetailLink(practitionerId),
                              "_blank"
                            );
                          }}
                          variant="contained"
                          startIcon={<OpenInNew />}
                        >
                          Go to Details
                        </Button>
                      </Grid>
                    </Grid>
                  )} */}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress sx={{ marginTop: 4 }} size={65} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <PracticeDetailPreviewDialog
        practice={selectedPractice}
        isOpen={isDetailsDialogOpen}
        onClose={() => {
          setisDetailsDialogOpen(false);
        }}
      />
    </>
  );
};

export interface TelecomTableProps {
  telecom: Telecom[];
  type: "phone" | "email";
}

export const TelecomTable = ({ telecom, type }: TelecomTableProps) => (
  <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Use</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Period</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {telecom.length ? (
          telecom.map(
            ({ use, uuid, value, period_end, period_start }) =>
              !!value?.trim?.() && (
                <TableRow
                  key={`telecom-${uuid ?? uuidV4()}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {use}
                  </TableCell>
                  <TableCell>{value}</TableCell>
                  <TableCell>
                    {getFormattedDatePeriod({
                      end: period_end ?? undefined,
                      start: period_start ?? undefined,
                    })}
                  </TableCell>
                </TableRow>
              )
          )
        ) : (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={3} align="center">
              No {type}s registered
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export interface AddressTableProps {
  addresses: Address[];
}

export const AddressTable = ({ addresses }: AddressTableProps) => (
  <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Use</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Period</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {addresses.length ? (
          addresses.map((address) => {
            const { use, uuid, period_end, period_start } = address;
            const formattedAddress = formatUSAddress(
              mapProviderAddressToFhir(address)
            );
            if (!formattedAddress) return null;
            return (
              <TableRow
                key={`address-${uuid ?? uuidV4()}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {use}
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
                >
                  {formattedAddress}
                </TableCell>
                <TableCell>
                  {getFormattedDatePeriod({
                    end: period_end ?? undefined,
                    start: period_start ?? undefined,
                  })}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={3} align="center">
              No addresses registered
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export interface PracticeTableProps {
  practices: Practice[];
  onRowDblClick: (practice: Practice) => void;
}

export const PracticeTable = ({
  practices,
  onRowDblClick,
}: PracticeTableProps) => (
  <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>NPI</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Period</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {practices.length ? (
          practices
            .filter(({ name }) => !!name?.trim?.())
            .map((practice) => {
              const {
                uuid,
                indentifier,
                name,
                period_end,
                period_start,
              } = practice;
              const npi =
                indentifier?.find?.(
                  ({ code }) => code === NPI_PROVIDER_IDENTIFIER_CODE
                )?.value ?? "";

              return (
                <TableRow
                  key={`practice-${uuid ?? uuidV4()}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { cursor: "pointer" },
                  }}
                  onDoubleClick={() => {
                    onRowDblClick(practice);
                  }}
                >
                  <TableCell>{npi}</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {name}
                  </TableCell>
                  <TableCell>
                    {getFormattedDatePeriod({
                      end: period_end ?? undefined,
                      start: period_start ?? undefined,
                    })}
                  </TableCell>
                </TableRow>
              );
            })
        ) : (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={3} align="center">
              No practices found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ProviderDetailPreviewDialog;