import { where, QueryConstraint } from 'firebase/firestore';
import moment from 'moment';
import { highCodeChar } from './constants';

export const getStartsWithCollectionFilter = (fieldName: string, value: string) =>
  value ? [where(fieldName, '>=', value), where(fieldName, '<', value + highCodeChar)] : [];

export const getDateBetweenCollectionFilter = (
  fieldName: string,
  startDate: Date | null,
  endDate: Date | null
) => [
  ...(startDate ? [where(fieldName, '>=', startDate)] : []),
  ...(endDate ? [where(fieldName, '<=', endDate)] : []),
];

export const filterByString = (
  fieldName: string,
  value: string | null | undefined,
  stringCase: 'lower' | 'upper' = 'lower'
): QueryConstraint[] => {
  if (!value || typeof value !== 'string') return [];
  let trimmedValue = value.trim().toLowerCase();
  if (stringCase === 'upper') trimmedValue = trimmedValue.toUpperCase();
  if (!trimmedValue) return [];
  return getStartsWithCollectionFilter(fieldName, trimmedValue);
};

export const filterByStringExactMatch = (
  fieldName: string,
  value: string | null | undefined
): QueryConstraint[] => {
  if (!value || typeof value !== 'string') return [];
  let trimmedValue = value.trim();
  if (!trimmedValue) return [];
  return [where(fieldName, '==', trimmedValue)];
};

export const filterByDateRange = (
  fieldName: string,
  startDate: moment.Moment | null | undefined,
  endDate: moment.Moment | null | undefined
): QueryConstraint[] | null => {
  const start = moment(startDate ?? null);
  const end = moment(endDate ?? null);
  if (start.isValid() && end.isValid() && end.isBefore(start, 'date')) {
    return null;
  }
  return getDateBetweenCollectionFilter(
    fieldName,
    start.isValid() ? start.toDate() : null,
    end.isValid() ? end.toDate() : null
  );
};
