import { useState } from 'react';
import { Avatar, Box, Button, SxProps } from '@mui/material';

import ImageUploadDialog from './ImageUploadDialog';
import { FileWithPath } from 'react-dropzone';
import ImagePreviewDialog from './ImagePreviewDialog';
import useLocales from 'src/hooks/useLocales';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface EditableAvatarProps {
  imageSourceUrl?: string | null;
  isLoading?: boolean;
  onUploadImage: (image: FileWithPath) => Promise<boolean> | boolean;
  isEditable?: boolean;
  avatarSx?: SxProps;
}

const EditableAvatar = ({
  imageSourceUrl,
  isLoading,
  onUploadImage,
  avatarSx,
  isEditable = true,
}: EditableAvatarProps) => {
  const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
  const [isImagePreviewDialogOpen, setIsImagePreviewDialogOpen] = useState(false);
  const { i18n } = useLocales();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={imageSourceUrl ?? ''}
          onClick={() => {
            imageSourceUrl && setIsImagePreviewDialogOpen(true);
          }}
          sx={[
            {
              width: 90,
              height: 90,
              '&:hover': {
                cursor: 'pointer',
              },
            },
            ...spreadSxProp(avatarSx),
          ]}
        />
        {!!isEditable && (
          <Box sx={{ marginTop: 1 }}>
            <Button
              sx={{
                border: 0,
                '&:hover': {
                  border: 0,
                },
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setIsImageUploadDialogOpen(true);
              }}
              variant="outlined"
            >
              {i18n('changePicture')}
            </Button>
          </Box>
        )}
      </Box>
      <ImageUploadDialog
        open={isImageUploadDialogOpen}
        isLoading={isLoading}
        onClose={() => {
          setIsImageUploadDialogOpen(false);
        }}
        onImageUpload={async (file) => {
          const isUploaded = await onUploadImage(file);
          if (isUploaded) setIsImageUploadDialogOpen(false);
        }}
      />
      <ImagePreviewDialog
        open={isImagePreviewDialogOpen}
        imgSrc={imageSourceUrl ?? ''}
        onClose={() => {
          setIsImagePreviewDialogOpen(false);
        }}
      />
    </>
  );
};

export default EditableAvatar;
