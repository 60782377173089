import { 
  Alert, 
  Backdrop, 
  Box, 
  Button, 
  Card, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  Grid, 
  Stack, 
  Step, 
  Stepper, 
  Typography 
} from '@mui/material';
import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { isEmpty, isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { Option } from 'src/@types/crs/case'
import { styled } from "@mui/material/styles";
import getFileData from 'src/utils/getFileData';
import { useEffect, useMemo, useState } from 'react';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'src/components/hook-form';
import Iconify from '../../../../../components/Iconify';
import OutboundReferralAttach from './OutboundReferralAttach';
import OutboundReferralDetails from './OutboundReferralDetails';
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import OutboundReferralAssignments from './OutboundReferralAssigments';
import { useOrganizations, useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from "src/sections/crs/common/common-utils";
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { 
  DocumentReference, ServiceRequest, 
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';

type FormValues = {
  code: string; 
  reasonCode: string;
  priority: boolean;
  organization: string;
  files: Blob[];
  documents: string[];
  documentsPHIN: DocumentReference[];
};

type Props = {
  open: boolean;
  onCancel: VoidFunction;
  patient: WrappedPatient | null;
  carePlan: WrappedCarePlan | null;
  serviceRequest?: WrappedServiceRequest | null;
  documentReferences: WrappedDocumentReference[] | null;
  rowCopy?: boolean;
  title: string;
  serviceRequestId?: string;
  handleOutboundReferral: (data: any) => Promise<any>;
  handleCreateBynary: (data: any) => Promise<any[] | null>;
  handleCreateDocumentReference: (data: any) => Promise<any>;
  handleUpdateDocumentReference: (data: any) => Promise<any>;
};

const BACKEND_URL = getBackEndBaseUrl();
const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;
const STEPS = ['Details', 'Attachments', 'Assignments' ];

const StepLabelColor = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    marginTop: -70,
    [`&.${stepLabelClasses.completed}`]: {
      color: 'grey'
    },
    [`&.${stepLabelClasses.active}`]: {
      color: '#1890FF'
    }
  }
}));

function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? '#1890FF': 'text.disabled',
        backgroundColor: 'rgb(243, 243, 243)',
        borderRadius: '50%',
      }}
    >
      {completed ? (
        <Iconify
          icon={'eva:checkmark-fill'}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'text.disabled' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            color:'currentColor'
          }}
        />
      )}
    </Box>
  );
}

export type ServiceRequestStatus = Option<ServiceRequest['status']>;

export default function OutbounReferralAdd ({ 
  patient, 
  carePlan, 
  serviceRequest,
  documentReferences,
  open,
  rowCopy,
  title,
  serviceRequestId,
  onCancel, 
  handleOutboundReferral,
  handleCreateBynary,
  handleCreateDocumentReference,
  handleUpdateDocumentReference
 }: Props) {

  const [ activeStep, setActiveStep ] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const isComplete = activeStep === STEPS.length;

  const EventSchema = Yup.object().shape({
    code: Yup.string().required('Service request is required'),
    reasonCode: Yup.string().max(5000),
    priority: Yup.boolean().default(false),
    organization: Yup.string().required('Organization is required'),
  });

  const documentsRelated = useMemo(
    () => documentReferences?.
      filter(d => d.context?.related?.find( r => r.reference === `${serviceRequest?.resourceType}/${serviceRequest?.id}`)).map((e) => e.id),
      [documentReferences, open, serviceRequest]
  );
  
  const documentLink = useMemo(
    () => documentReferences?.
      filter(d => d.context?.related?.find( r => r.reference === `${carePlan?.resourceType}/${carePlan?.id}`)), 
      [documentReferences, open, serviceRequest]
  );

  const defaultValues = useMemo(
    () =>
      ({
        code: serviceRequest?.code?.coding?.[0].code || '',
        reasonCode: serviceRequest?.reasonCode?.[0].text || '',
        priority: serviceRequest?.priority === 'urgent' ? true : false,
        organization: serviceRequest?.performer?.[0].reference?.toString().split('/')[1] || '',
        documents: documentsRelated || []
      } as FormValues),
    [documentReferences, open, serviceRequest]
  );

  const methods = useForm<FormValues>({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    reset,
    formState: { errors },
    handleSubmit,
  } = methods;

  const [ organization, ] = useOrganizations({ filter: { type: 'mint' }, map: OrganizationWrapper });
  const [ serviceRequestTypeCodeList ] = useValueSet('ph-service-request-type', { map: ValueSetWrapper });
  const [ serviceRequestCategoryCodeList ] = useValueSet('ph-service-request-category', { map: ValueSetWrapper });

  const handleNextStep = () => {
    const newActiveStep = isComplete ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  const handleClose = () => {
    setActiveStep(0);
    onCancel();
  };

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
  });

  const createBinaryCustom = async (data: FormValues): Promise<any> => {
    if (!data?.files?.length) return null;

    const resourceType = 'Binary';

    const mappedFile =  await Promise.all( 
      data.files.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBlobToBase64(file);
        const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
        const name = getFileData(Object.assign(file)).name;
        const obj = { resourceType, contentType, securityContext, data};
        const binary = await handleCreateBynary(obj);

        const objNew = {...binary?.[0], name: name};
        return objNew;
      })
    );

    return mappedFile;
  };

  const createDocumentReference = async (binaryList: any) => {

    const organizationFhir = organization?.
    filter(e => e.id === authUser.organization_fhir_uri).
    map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    const documentReference = binaryList?.map((binary:any) => ({ 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      context: {related: [{ reference: `${carePlan?.resourceType}/${carePlan?.id}`}]},
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary.contentType}`, 
        creation: new Date().toISOString(),
        title: binary.name, 
        url: `${FHIR_API}/Binary/${binary.id}` 
      }}],
     }));

     const createdDocumentReference = await handleCreateDocumentReference(documentReference);
     
    return createdDocumentReference;
  };
  
  const createDocumentReferencePHIN = async (data: DocumentReference[]) : Promise<any> => {
    
    if(data === undefined) return null;
    
    try{
      const document = data.map((doc) => {
        const documentUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'documentUniqueId')?.value;
        const repositoryUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'repositoryUniqueId')?.value;
        const hcid = doc?.identifier?.find((i) => i?.type?.text === 'hcid')?.value;

        doc.content[0].attachment.url = `${BACKEND_URL}/crs/document/${documentUniqueId}?repositoryId=${repositoryUniqueId}&hcid=${hcid}`;
        doc.subject = { reference: `${patient?.resourceType}/${patient?.id}` };
  
        if(doc.context?.period?.end === null) delete doc.context?.period?.end;

        const context = doc.context;
        const related = doc?.context?.related || [];

        doc.context = {...context, related: [ ...related, { reference: `${carePlan?.resourceType}/${carePlan?.id}`}]};

        delete doc.content[0].attachment.data;
        delete doc.id;
  
        const { ...result } = doc;
        return result;
      })
  
      const documentReference = await handleCreateDocumentReference(document);

      return documentReference;

    }catch(e) {
      enqueueSnackbar('An error has ocurred.', { variant:'error', autoHideDuration: 6000 });
    }
  };

  const updateDocuments = ( data: FormValues ) => {
    const { documents, files, documentsPHIN } = data;

    const arraysDocsEqual = isEqual(documents, documentsRelated);

    if((documentsRelated && 
      (documents.length !== documentsRelated.length)) || 
      !arraysDocsEqual ||
      files?.length > 0 ||
      documentsPHIN?.length > 0
    ){
      return true;
    }
    return false;
  };

  const updateDocumentsSelected = async (
    data: FormValues, 
    createdDocs: DocumentReference[] | undefined, 
    createdPhinDocs: DocumentReference[] | null, 
    resourceToRelate: any
  ): Promise<any> => {

    const { documents } = data;

    const delRelatedResource = documentsRelated?.filter((e:any) => !documents?.some((s:any) => s === e));

    const documentsNotRelated = documentReferences?.filter(p => delRelatedResource?.
      some((f:any) => p.id === f)).
        map((l) =>{

          const related = l.context?.related || [];  

          const notRelated = related?.
            filter(r => r.reference !== `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`) || [];
          const notRelatedCarePlan = notRelated?.
            filter(r => r.reference !== `${carePlan?.resourceType}/${carePlan?.id}`) || [];
          
          if(l.context?.related && !isEmpty(notRelatedCarePlan)){ 
            l.context.related = [ ...notRelatedCarePlan ];
          }else{
            delete l.context?.related;
          }

          if(isEmpty(l.context)){
            delete l.context;
          }

          const {...result} = l;
          return result;
    })|| [];

    let documentsSelected: any;
    
    if(rowCopy){
      documentsSelected = documents?.
        filter((e:any) => documentsRelated?.
          some((s:any) => s == e));
    }else{
      documentsSelected = documents?.
        filter((e:any) => !documentsRelated?.
          some((s:any) => s == e));
    }

    const documentReferenceSelected = documentReferences?.filter(d => documentsSelected?.
      some((r: any) => d.id === r)).
        map((e) => {
          const context = e.context;
          const related = e.context?.related || [];  

          const resourceRelated = related.
            find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
          const carePlanRelated = related.
            find(r => r.reference === `${carePlan?.resourceType}/${carePlan?.id}`);

          if(isEmpty(carePlanRelated)){
            related?.push({reference: `${carePlan?.resourceType}/${carePlan?.id}`});
          }

          if(isEmpty(resourceRelated)){
            e.context = { 
              ...context, 
              related: [ 
                ...related, 
                { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}
            ]};
          }else {
            e.context = {...context, related: [ ...related ]};
          }

          const {...result} = e;
          return result;
    }) || [];

    const createdDocuments = createdDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const phinDocuments = createdPhinDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const allDocuments = [ ...documentsNotRelated, ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];

    const updateDocumentReference = await handleUpdateDocumentReference(allDocuments);
    return updateDocumentReference;
  };

  const mapOutboundReferral = (data: FormValues) => {

    const { code, reasonCode, priority } = data;

    const carePlanSupportingInfo = { reference: `${carePlan?.resourceType}/${carePlan?.id}` };
    const id = { reference: `ServiceRequest/${serviceRequestId}` };

    const categoryValueSet = !isEmpty(serviceRequestId) ? 
       convertValueToValueSet('outbound_referral_child', serviceRequestCategoryCodeList) : 
       convertValueToValueSet('outbound_referral', serviceRequestCategoryCodeList);

    const codeValueSet = convertValueToValueSet(code, serviceRequestTypeCodeList);

    const organizationFhir = organization?.filter(e => e.id === data.organization).map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    if(rowCopy) delete serviceRequest?.id;
  
    const mapServiceRequest = {
      id: serviceRequest?.id,
      resourceType: 'ServiceRequest',
      intent: 'original-order',
      status: 'active',
      basedOn: [carePlanSupportingInfo, serviceRequestId && id],
      authoredOn: new Date().toISOString(),
      category: [{
        ...(categoryValueSet && { coding: [categoryValueSet] }),
        text: categoryValueSet?.display,
      }],
      subject: { reference: `${patient?.resourceType}/${patient?.id}`, display: patient?.getFullName() || '' },
      supportingInfo: [carePlanSupportingInfo],
      code: {
        ...(codeValueSet && { coding: [codeValueSet] }),
        text: codeValueSet?.display,
      },
      ...(reasonCode && { reasonCode : [{ text: reasonCode }] }),
      ...(priority && { priority: 'urgent' }),
      ...(organization && { performer: [organizationFhir] }),
      ...(serviceRequest?.meta && {meta: { versionId: serviceRequest.meta.versionId}})
    };

    return mapServiceRequest;
  };

  const onSubmit = async (data: FormValues) => {

    try{

      let documentReference;
      let documentPHIN;
      let resourceToRelated: any;

      setOpenBackdrop(true);
      
      if(data.files !== undefined){
        const createBinary = await createBinaryCustom(data);
        documentReference = await createDocumentReference(createBinary);
      }

      if(data?.documentsPHIN?.length){
        documentPHIN = await createDocumentReferencePHIN(data.documentsPHIN) || [];
      }

      const mapOutbounReferralResult = mapOutboundReferral(data);
      resourceToRelated = await handleOutboundReferral(mapOutbounReferralResult);

      if(serviceRequest && !rowCopy){
        resourceToRelated = serviceRequest;
      }

      const update = updateDocuments(data);

      if(update){
        const updateDocuments = await updateDocumentsSelected(data, documentReference, documentPHIN, resourceToRelated);
      }

      setOpenBackdrop(false);
    }catch(e){
      handleClose();
      setOpenBackdrop(false);
      enqueueSnackbar('An error has occurred.', { variant: 'error' });
    }finally{
      handleClose();
      onCancel();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle>{serviceRequest?.id ? 'View': 'Add'} {title}</DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container sx={{ p:2 }}>
        <Grid item xs={12}>
          <Stack spacing={2} sx={{ p: 4 }} >
            <Stepper alternativeLabel activeStep={activeStep} >
              {STEPS.map((label, index) => (
                <Step key={label}>
                  <StepLabelColor
                    StepIconComponent={QontoStepIcon}
                    optional={
                      <Typography 
                        sx={{ 
                            textAlign: 'center', 
                            fontSize: 12,
                            color: activeStep === index ? '#1890FF' : 'grey'
                          }}> 
                          Step {index + 1} 
                      </Typography>
                    }
                  >
                    {label}
                  </StepLabelColor>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ borderWidth: 2 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!isComplete ? (
                <Card>
                  {activeStep === 0 && 
                    <OutboundReferralDetails 
                      onCancel={handleClose} 
                      nextStep={handleNextStep}
                    />}
                  {activeStep === 1 && 
                    <OutboundReferralAttach 
                      documentLink={documentLink}
                      docRelated={documentsRelated}
                      documentReferences={documentReferences}
                      patient={patient}
                      nextStep={handleNextStep}
                      backStep={handleBackStep}
                    />}
                  {activeStep === 2 && 
                    <OutboundReferralAssignments 
                      patient={patient}
                      performerOrganizations={organization}
                      backStep={handleBackStep}
                      nextStep={handleNextStep}
                      handleCreateOutboundReferral={handleOutboundReferral}
                    />}
                </Card>
              ):(
                <Stack alignItems="center">
                  <Typography gutterBottom variant="h4" sx={{ whiteSpace: 'pre-line' }}>
                    Save Outbound Referral? 
                  </Typography>

                  {!!errors.organization && <Alert severity="error">{errors.organization.message}</Alert>}
                  {!!errors.code && <Alert severity="error">{errors.code.message}</Alert>}
                  
                  <DialogActions>
                    <Box sx={{ flexGrow: 1 }} />
                      <Button variant="outlined" color="info" onClick={handleBackStep}>
                        Back
                      </Button>
                      <Button variant="outlined" color="info" type="submit">
                        Save
                      </Button>
                  </DialogActions>
                 
                </Stack>
              )
            }
          </FormProvider>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export const serviceRequestStatuses: ServiceRequestStatus[] = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Active',
    value: 'active',
  },
  { 
    label: 'On Hold', 
    value: 'on-hold'
  },
  {
    label: 'Revoked',
    value: 'revoked',
  },
  { 
    label: 'Completed', 
    value: 'completed' 
  },
  { 
    label: 'Entered In Error', 
    value: 'entered-in-error' 
  },
  {
    label: 'Unknown',
    value: 'unknown',
  }
];