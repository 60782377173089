import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import useLocales from 'src/hooks/useLocales';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export type OnValueChange = (gender: ValueSetComposeIncludeConcept | null) => void;

export interface GenderIdentityAutoCompleteProps {
  genderIdentityVS: WrappedValueSet | null;
  value: ValueSetComposeIncludeConcept | null;
  onValueChange: OnValueChange;
  isLoading?: boolean;
  label?: string;
}

const GenderIdentityAutoComplete = ({
  genderIdentityVS,
  value,
  onValueChange,
  isLoading,
  label,
}: GenderIdentityAutoCompleteProps) => {
  const genderIdentityTypes = genderIdentityVS?.asListAll?.() ?? [];
  const { i18n } = useLocales();
  return (
    <Autocomplete
      fullWidth
      disabled={!!isLoading}
      value={value}
      onChange={(_: React.SyntheticEvent, gender) => {
        const setGender: any = {
          ...gender,
          system: value?.system,
        };
        onValueChange(setGender);
      }}
      noOptionsText={i18n('patients.noGenderIdentitiesForSelection', 'crs')}
      options={genderIdentityTypes ?? []}
      getOptionLabel={(genderIdentity: ValueSetComposeIncludeConcept) =>
        capitalize(genderIdentity?.display as string)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? i18n('patients.genderIdentity', 'crs')}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!!isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default GenderIdentityAutoComplete;
