import { IApiClient } from 'src/api/clients/api_client';
import { PatientEngagementDto } from './dto/patient-engagement.dto';
import { convertFilterToPaginateQueryParams } from 'src/utils/gridView';
import {
  PaginateQuery,
  paginateQueryToURLParams,
  PaginateQueryWithStats,
} from 'src/api/pagination/dtos';
import { PatientEngagementContactAttemptDto } from './dto/patient-engagment-contact-attempt.dto';
import { CreatePatientEngagementDto } from './dto/create-patient-engagement.dto';

export interface PatientEngagementResponse {
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  patientEngagementId: string;
  patientEngagementUUID: string;
  careflowIdentifierPatientEngagement: string;
  fhirId: string;
  workflowInstanceId: number;
  workflowStageId: string;
  workflowStageName: string;
  workflowStageOrder: number;
  workflowStatus: string;
  workflowSubstatus: string;
  workflowDispositionReason: string;
  workflowDispositionNote: string;
  workflowDispositionOn: string;
  workflowDispositionAt: string;
  workflowOwnedBy: string;
  workflowScope: string;
  patientFamily: string;
  patientGiven: string;
  patientName: string;
  patientFhirUUID: string;
  patientDOB: string;
  patientDOBFormatted: string;
  patientAge: string;
  patientEmail: string;
  patientPhone: string;
  patientMrn: string;
  patientLanguage: string;
  patientManagingOrgUUID: string;
  patientManagingOrgDisplay: string;
  patientLastSIL: string;
  contactAttemptContactOn: string;
}

export interface PatientEngagementContactAttemptResponse {
  patientEngagementId: string;
  fhirId: string;
  patientEngagementContactAttemtpId: string;
  patientEngagementContactAttemptUuid: string;
  patientEngagementContactAttemptOutcome: string;
  patientEngagementContactAttemptContactOn: string;
  patientEngagementContactAttemptNextContactOn: string;
  patientEngagementContactAttemptNoteId: string;
  patientEngagementContactAttemptDuration: string;
  noteId: string;
  noteUuid: string;
  noteType: string;
  noteText: string;
}

export interface PaginatedPatientEngagementResponse {
  data: PatientEngagementResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedPatientEngagementDto {
  data: PatientEngagementDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  stats?: any;
}

export class PatientApiClient {
  apiBase: string;

  patientApiClient: IApiClient;

  constructor(patientApiClient: IApiClient) {
    this.apiBase = 'engagement/patient';
    this.patientApiClient = patientApiClient;
  }

  async getAll(
    queryParams: PaginateQuery
  ): Promise<PaginatedPatientEngagementResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);

    const filters = convertFilterToPaginateQueryParams(queryParams?.filter ?? {});

    const url = `${this.apiBase}/?${urlParams.toString()}${filters}`;

    try {
      const response = await this.patientApiClient.get<
        PaginatedPatientEngagementResponse | undefined
      >(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(id: string): Promise<PatientEngagementResponse | undefined> {
    try {
      const response = await this.patientApiClient.get<PatientEngagementResponse | undefined>(
        `${this.apiBase}/${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllContactAttempt(
    patientEngagementId: string
  ): Promise<PatientEngagementContactAttemptResponse[] | undefined> {
    try {
      const response = await this.patientApiClient.get<
        PatientEngagementContactAttemptResponse[] | undefined
      >(`${this.apiBase}/contact-attempt/${patientEngagementId}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async createPatientEngagement(
    payload: CreatePatientEngagementDto
  ): Promise<PaginatedPatientEngagementResponse | undefined> {
    try {
      const response = await this.patientApiClient.post<
        CreatePatientEngagementDto,
        any | undefined
      >(`${this.apiBase}`, payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async createContactAttempt(
    payload: PatientEngagementContactAttemptDto
  ): Promise<any | undefined> {
    try {
      const response = await this.patientApiClient.post<
        PatientEngagementContactAttemptDto,
        any | undefined
      >(`${this.apiBase}/contact-attempt`, payload);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async bulkAssignOwner(payload: any): Promise<any | undefined> {
    try {
      const response = await this.patientApiClient.post<any, undefined>(
        `${this.apiBase}/bulkAssignOwner`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class PatientService {
  patientApiClient: PatientApiClient;

  constructor(patientApiClient: PatientApiClient) {
    this.patientApiClient = patientApiClient;
  }

  async getAll(
    queryParams: PaginateQueryWithStats
  ): Promise<PaginatedPatientEngagementDto | undefined> {
    const patientEngagements = await this.patientApiClient.getAll(queryParams);
    if (!patientEngagements) return undefined;
    const dtos = patientEngagements.data.map((r) => {
      const dto = new PatientEngagementDto();
      dto.fromPatientEngagementResponse(r);
      return dto;
    });
    const mapped: PaginatedPatientEngagementDto = { ...patientEngagements, data: dtos };
    return mapped;
  }

  async getContactAttempt(
    fhirId: string
  ): Promise<PatientEngagementContactAttemptResponse[] | undefined> {
    const contactAttempts = await this.patientApiClient.getAllContactAttempt(fhirId);
    if (!contactAttempts) return undefined;
    return contactAttempts;
  }

  async createPatientEngagement(
    payload: CreatePatientEngagementDto
  ): Promise<PatientEngagementResponse | undefined> {
    const patientEngagement = await this.patientApiClient.createPatientEngagement(payload);
    if (patientEngagement) return undefined;
    return patientEngagement;
  }

  async createContactAttempt(
    payload: PatientEngagementContactAttemptDto
  ): Promise<any | undefined> {
    const contactAttempt = await this.patientApiClient.createContactAttempt(payload);
    if (!contactAttempt) return undefined;
    return contactAttempt;
  }

  async bulkAssignOwner(payload: any): Promise<any | undefined> {
    const bulkAssignOwner = await this.patientApiClient.bulkAssignOwner(payload);
    if (!bulkAssignOwner) return undefined;
    return bulkAssignOwner;
  }
}
