import { 
  Backdrop, 
  Box, 
  Button, 
  Card, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  DialogActions, 
  Grid, 
  Stack, 
  Autocomplete, 
  Chip, 
  TextField, 
  MenuItem 
} from '@mui/material';
import Viewer from './Viewer';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import getFileData from 'src/utils/getFileData';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { Controller, useForm } from 'react-hook-form';
import { convertValueToValueSet } from './common-utils';
import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import BlockContent from 'src/components/upload/BlockContent';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { useBinarys, useOrganizations, useValueSet } from 'src/@nicheaim/fhir-react';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { DocumentReference } from 'src/@nicheaim/fhir-base/mappings/DocumentReference';

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

type Props = {
  open: boolean;
  patient: WrappedPatient | null;
  resource?: any;
  createDocumentReference: any;
  refreshDocumentReference: any;
  handleUpdateResource: (data: any) => Promise<any>;
  onCancel: VoidFunction;
};

type FormValues = {
  file: any;
  name?: string;
  type?: string;
  fileType?: string;
  preview?: string;
  author?: string;
  category?: string[];
  custodian: string;
};

export default function AttachmentAddForm({ 
  open, 
  patient, 
  resource, 
  createDocumentReference,
  refreshDocumentReference,
  handleUpdateResource,
  onCancel
}: Props) {

  const defaultValues = {
    file: '',
    name: '',
    type: '',
    fileType: '',
    preview: '',
    author: '',
    category: [],
    custodian: ''
  };

  const methods = useForm<FormValues>({ 
    defaultValues
  });

  const {
    control,
    clearErrors,
    reset,
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { errors }
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      
      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const fileUp = watch('file');
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useAuth().getCurrentUser();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [, { create: createBinary }] = useBinarys({ autofetch: false });
  const [organizations] = useOrganizations({ map: OrganizationWrapper });
  const [documentType] = useValueSet('ph-document-type-codes', { map: ValueSetWrapper });
  const [documentCategory] = useValueSet('ph-document-category-codes', { map: ValueSetWrapper });

  useEffect(() => {
    if(fileUp){
      clearErrors('name');
      setValue('name',getFileData(fileUp).name);
      setValue('fileType',getFileData(fileUp).type);
      setValue('author', authUser.name);
    }
  },[fileUp]);

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);
    return result;
  };

  const convertBlobToBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
    };
    reader.readAsDataURL(file);
  });

  const createBinaryCustom = async (file: FormValues): Promise<any> => {

    if(!file?.file) return null;

    const resourceType = 'Binary';
    const contentType = file?.fileType;
    const data = await convertBlobToBase64(file?.file);
    const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
    const name = file.name;
    const obj = { resourceType, contentType, securityContext, data};
    const binary = await handleCreateBynary(obj);
    const objNew = {...binary[0], name: name};
    return objNew;

  };

  const mapFormDataToDocumentReference = (binary: any, data: any) => {

    const codeValueCategory = data.category?.map((e) => {
      let valueSet = convertValueToValueSet(e, documentCategory);

      if (!valueSet) {
        valueSet = { code: e, display: e };
      }

      return {
        coding: [{ ...valueSet }],
        text: e,
      };
    }) || [];

    const dataCustodian = data.custodian || getFhirIdFromEntity(authUser.organization_fhir_uri);

    const newCustodian = organizations
      ?.filter((e) => e.id === dataCustodian)
      .map((t) => {
        const reference = `Organization/${t.id}`;
        const display = t.name;
        return { reference, display };
      });

    const codeValueType = convertValueToValueSet(data.type, documentType);

    const documentReference = { 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      ...(!isEmpty(codeValueCategory) && { category: [...codeValueCategory] }),
      ...(!isEmpty(codeValueType) && { type: { coding: [{ ...codeValueType }] } }),
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(newCustodian[0] && {
        custodian: newCustodian[0]
      }),
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary?.contentType}`, 
        creation: new Date().toISOString(), 
        title: binary.name,
        url: `${FHIR_API}/Binary/${binary?.id}` 
      }}],
      ...(resource && {context:{ related: [{ reference: `${resource?.resourceType}/${resource?.id}` }]}})
     };
     
    return documentReference;
  };

  const mapDocumentReferenceToResource = (
    documentReference: DocumentReference[], 
  ) => {
    const document = documentReference?.map((doc) => (`${doc?.resourceType}/${doc?.id}`));
    const supportingInfo = resource?.supportingInfo?.map((sd) => (`${sd?.reference}`)) ?? [];
    const setDocument = [...new Set([...supportingInfo, ...document])];
    const allDocuments = setDocument?.map((x3) => ({reference: x3 })); 
    return allDocuments;
  };

  const onSubmit = async (data: FormValues) => {

    try {

      if(!data?.name){
        setError('name', {
          type: 'manual',
          message: "File Name is required.",
        });
        return;
      }

      setOpenBackdrop(true);

      const resultBinary = await createBinaryCustom(data);
      const mapBinaryToDocumentReference = mapFormDataToDocumentReference(resultBinary, data);
      const saveDocumentReference = await createDocumentReference(mapBinaryToDocumentReference);
      const mapToResource = mapDocumentReferenceToResource(saveDocumentReference);

      if(mapToResource && resource){
        resource.supportingInfo = mapToResource;
        if(handleUpdateResource !== null) handleUpdateResource(resource);
      }
      refreshDocumentReference();
      enqueueSnackbar('Attachment was created');
      setOpenBackdrop(false);
      onClose();

    } catch (error) {
      enqueueSnackbar('Attachment was not created', { variant:'error' });
      setOpenBackdrop(false);
      onClose();
    }
  };

  const onClose = () => {
    onCancel();
    reset();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Add Attachment</DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              {fileUp === ''  ? ( <UploadFile onDrop={handleDrop} /> ) : (
                <Stack sx={{ m: 1 }}>
                  <Box
                    sx={{
                      m: 2,
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField 
                      name="name" 
                      label="File Name" 
                      error={!!errors?.name?.message}
                      helperText={errors?.name?.message}
                    />

                    <Controller
                      name="category"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          freeSolo
                          onChange={(event, newValue) => field.onChange(newValue)}
                          options={documentCategory?.asList().map((option) => option.display) || []}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                key={option}
                                size="small"
                                label={option}
                              />
                            ))
                          }
                          renderInput={(params) => <TextField label="Category" {...params} />}
                        />
                      )}
                    />
                    <RHFTextField disabled name="author" label="Author" />
                    <RHFSelect name="custodian" label="Custodian">
                      {organizations?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <RHFTextField disabled name="fileType" label="File Type" />
                    <RHFSelect name="type" label="Type">
                      {documentType?.asList().map((option) => (
                        <MenuItem key={option.code} value={option.display}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Box>

                  <Stack sx={{ m: 1 }}>
                    {fileUp && (
                      <Viewer file={fileUp} typeResource='blob' />
                    )}
                  </Stack>

                  <Stack spacing={2} alignItems="center">
                    <DialogActions>
                      <Box sx={{ flexGrow: 1 }} />

                      <Button variant="outlined" color="info" onClick={onClose} >
                        Cancel
                      </Button>

                      <Button variant="outlined" color="info" type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Card>
      </FormProvider>
    </Dialog>
  );
};


const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

type UploadFileProps = DropzoneOptions;

function UploadFile({ ...other }: UploadFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    ...other,
  });

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject) && {
          color: 'error.main',
          borderColor: 'error.light',
          bgcolor: 'error.lighter',
        })
      }}
    >
      <input {...getInputProps()} />
      <BlockContent />
    </DropZoneStyle>
  );
}