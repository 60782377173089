import { SxProps } from '@mui/material';
import { useState } from 'react';
import { Attachment } from 'src/@nicheaim/fhir-base/mappings/Attachment';

import EditableAvatar, {
  EditableAvatarProps,
} from 'src/sections/YoCare/components/EditableAvatar/EditableAvatar';
import { readFileAsBase64 } from 'src/utils/file';

export type OnImageUpload = (attachment: Attachment) => Promise<boolean>;

export interface PractitionerProfileImageProps {
  practitionerProfileImage?: Attachment;
  onImageUpload?: OnImageUpload;
  isEditable?: boolean;
  avatarSx?: SxProps;
}

const PractitionerProfileImage = ({
  practitionerProfileImage,
  onImageUpload,
  isEditable,
  avatarSx,
}: PractitionerProfileImageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadImage: EditableAvatarProps['onUploadImage'] = async (file) => {
    try {
      setIsLoading(true);
      const fileAsBase64 = await readFileAsBase64(file as File);
      const attachment: Attachment = {
        contentType: file.type,
        data: fileAsBase64,
        title: '',
        creation: new Date().toISOString(),
      };
      const isUploaded = onImageUpload ? await onImageUpload(attachment) : true;
      setIsLoading(false);
      return isUploaded;
    } catch (error) {}
    setIsLoading(false);
    return false;
  };

  const imageSourceUrl =
    practitionerProfileImage?.contentType && practitionerProfileImage?.data
      ? `data:${practitionerProfileImage.contentType};base64,${practitionerProfileImage.data}`
      : null;

  return (
    <EditableAvatar
      isEditable={isEditable}
      imageSourceUrl={imageSourceUrl}
      onUploadImage={handleUploadImage}
      isLoading={isLoading}
      avatarSx={avatarSx}
    />
  );
};
export default PractitionerProfileImage;
