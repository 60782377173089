import { useCallback } from 'react';
import { IconButton, InputAdornment, InputProps, TextField, TextFieldProps } from '@mui/material';
import { InsertInvitation as CalendarIcon } from '@mui/icons-material';

export interface UseRenderDefaultInputParams {
  onCalendarIconPress?: VoidFunction;
  errorMessage?: string;
  inputProps?: Partial<InputProps>;
  textFieldProps?: Partial<TextFieldProps>;
}

export const useRenderDefaultInput = ({
  onCalendarIconPress,
  errorMessage,
  inputProps,
  textFieldProps,
}: UseRenderDefaultInputParams) => {
  const renderInputDefault = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...params}
        error={!!errorMessage}
        helperText={errorMessage ? errorMessage : undefined}
        {...(textFieldProps ?? {})}
        InputProps={{
          ...params.InputProps,
          ...(inputProps ?? {}),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  onCalendarIconPress?.();
                }}
                edge="end"
              >
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ),
    [onCalendarIconPress, errorMessage, inputProps, textFieldProps]
  );

  return renderInputDefault;
};
