import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useMemo } from 'react';
import { WrappedOrganization } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import useLocales from 'src/hooks/useLocales';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export type OnValueChange = (selectedOrganizationRef: Reference | null) => void;

export interface PropsI {
  organizations: WrappedOrganization[] | null;
  selectedOrganizationRef: Reference | null;
  onValueChange: OnValueChange;
  isLoading?: boolean;
}

const OrganizationSelect = ({
  organizations,
  selectedOrganizationRef,
  onValueChange,
  isLoading,
}: PropsI) => {
  const { i18n } = useLocales();
  const selectedOrg = useMemo(() => {
    if (!selectedOrganizationRef) {
      return null;
    }

    const sOrg = organizations?.find(
      (item) => item.id && selectedOrganizationRef.reference?.includes(item.id)
    );

    if (!sOrg) {
      return null;
    }

    return sOrg;
  }, [selectedOrganizationRef]);

  return (
    <Autocomplete
      fullWidth
      disabled={!!isLoading}
      value={selectedOrg}
      onChange={(_: React.SyntheticEvent, organization) => {
        const sOrganizationRef: Reference = {
          ...selectedOrganizationRef,
          reference: 'Organization/' + organization?.id,
          display: organization?.name,
        };
        onValueChange(sOrganizationRef);
      }}
      noOptionsText={i18n('patients.noManagingOrganizationForSelection', 'crs')}
      options={organizations ?? []}
      getOptionLabel={(organization: WrappedOrganization) => organization.name || '-'}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!!isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default OrganizationSelect;
