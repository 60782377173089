import moment from 'moment';
import { DefaultDateType } from 'src/components/DateTimePicker/components';
import { Period } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { defaultDateFormat, defaultDateTimeFormat } from 'src/sections/crs/constants';

export const dateFormat = 'MMM DD, YYYY';

export const isBetweenDates = (
  start1: moment.Moment,
  end1: moment.Moment,
  start2: moment.Moment,
  end2: moment.Moment
): boolean => start1.isSameOrBefore(end2, 'date') && end1.isSameOrAfter(start2, 'date');

export const momentDateComparator = (date1: moment.Moment, date2: moment.Moment): number => {
  if (date1.isSame(date2, 'date')) return 0;
  if (date1.isBefore(date2, 'date')) return -1;
  return 1;
};

export const checkIfDateIsAfterAnother = (
  date1: moment.Moment | null,
  date2: moment.Moment | null
) => date1?.isValid?.() && date2?.isValid?.() && date1?.isAfter?.(date2, 'date');

export const formatDate = (date: moment.Moment | null | undefined): string =>
  date?.isValid?.() ? date.format(dateFormat) : '';

export const getAge = (birthDate: moment.Moment): number =>
  moment().diff(moment(birthDate), 'years');

export const getFormattedDatePeriod = (period: Period | null | undefined): string => {
  let formattedDate = '';
  if (!period) return formattedDate;
  const dateFormat = defaultDateFormat;
  const { start, end } = period ?? {};
  const startDate = moment(start ?? null);
  const endDate = moment(end ?? null);
  if (startDate.isValid()) {
    formattedDate = startDate.format(dateFormat);
    formattedDate += ` to ${endDate.isValid() ? endDate.format(dateFormat) : 'Current'}`;
  }

  return formattedDate;
};

export const isTimeIncludedInDate = (value: DefaultDateType): boolean => {
  const dateMoment = moment(value ?? '');
  if (!dateMoment.isValid()) return false;

  return (
    dateMoment.toISOString() !==
    moment(dateMoment.format('YYYY-MM-DD')).startOf('day').toISOString()
  );
};

export const formatDateTimeToDefault = (
  date: moment.Moment | string | null | undefined
): string => {
  const momentDate = moment(date ?? null);
  if (!momentDate.isValid()) return '';
  return momentDate.format(
    isTimeIncludedInDate(momentDate ?? null) ? defaultDateTimeFormat : defaultDateFormat
  );
};

export const getTimeDiff = (start: moment.Moment, end: moment.Moment) => {
  if (!start.isValid() || !end.isValid()) return null;
  const startDate = moment(start).startOf('day');
  const endDate = moment(end).startOf('day');

  const duration = moment.duration(endDate.diff(startDate));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  let [timeFactor, timeDiff]: [string, number] = years
    ? ['year', years]
    : months
    ? ['month', months]
    : ['day', days];
  if (timeDiff === 0 && timeFactor === 'day' && startDate.isSame(endDate)) timeDiff = 1;
  return {
    timeDiff,
    timeFactor,
    diffText: `${timeDiff} ${timeFactor}${Math.abs(timeDiff) > 1 ? 's' : ''}`,
    diff: {
      years,
      months,
      days,
    },
  };
};

export const formattedDateTimeZone = (date: string) => {
  if(!moment(date).isValid()) return;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = moment(date).tz(timeZone).format('MM/DD/YYYY HH:mm:ss');
  return formattedDate;
};

export const begginingOfTime = '1000-01-01';
export const endOfTime = '9999-12-31';
