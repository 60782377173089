import { NoteDto } from 'src/@types/note';
import client from './_client';

export async function getGenericNotes(recordId: string) {
  const { data } = await client.get(`/notes/${recordId}`);

  return data;
}

export async function createNote(payload: NoteDto) {
  const { data } = await client.post(`/notes`, payload);

  return data;
}

export async function updateGenericNote(payload: NoteDto, entityId?: string) {
  const { data } = await client.patch(`/notes/${entityId}`, payload);

  return data;
}
