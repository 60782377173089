import produce from 'immer';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import Item from 'src/sections/crs/common/Item';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useDialogStore } from 'src/stores/dialog';
import RequestReport from '../report/RequestReport';
import { taskService } from 'src/nat/task/services';
import { getPrintableTask } from '../../nat/helpers/report';
import DetailsSummary from '../../crs/common/DetailsSummary';
import RequestReportResponse from '../report/RequestReportResponse';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { HJAssessment, mapRequester, memberDetails } from '../helpers/task';
import { useBinarys, useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { TaskWrapper, WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import {
  Binary,
  DocumentReference,
  MeasureReport,
  Questionnaire,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { QuestionnaireResponse } from 'src/@nicheaim/fhir-base/mappings/QuestionnaireResponse';
import {
  getMeasureReportGroups,
  getMeasureReportGroupValue,
  getObservationtypeValue,
} from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

interface Props {
  patient: WrappedPatient | undefined;
  task: WrappedTask | undefined;
  serviceRequest: WrappedServiceRequest | null;
  planDefinition: WrappedPlanDefinition | null;
  questionnaire: Questionnaire[] | undefined;
  questionnaireResponse: QuestionnaireResponse[] | undefined;
  measureReport: MeasureReport | undefined;
  ownerTask: any;
  requesterTask: any;
  observations: WrappedObservation[] | undefined;
  updateServiceRequest: any;
  refreshServiceRequest: any;
  devMode: boolean;
  showMeasureQuestionnaireResponses: boolean;
  selectedTab: number;
  index: number;
  handleBackdrop: (open: boolean) => void;
}

const TaskDetails = ({
  patient,
  task,
  serviceRequest,
  planDefinition,
  questionnaire,
  questionnaireResponse,
  measureReport,
  ownerTask,
  requesterTask,
  observations,
  updateServiceRequest,
  refreshServiceRequest,
  devMode,
  showMeasureQuestionnaireResponses,
  selectedTab,
  index,
  handleBackdrop,
}: Props) => {
  const user = useAuth().getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { confirmDialog } = useDialogStore();

  const [, { create: createBinary }] = useBinarys({ autofetch: false });
  const [, { create: createDocumentReference }] = useDocumentReferences({ autofetch: false });

  const taskWrapper = task && TaskWrapper(task);

  const getMemberDetails = patient && memberDetails(patient);
  const getHJAssessment = useMemo(
    () => HJAssessment(serviceRequest, planDefinition, taskWrapper, measureReport, ownerTask),
    [serviceRequest]
  );
  const getRequester = requesterTask && task && mapRequester(requesterTask, task);

  const taskStatus = useMemo(
    () => task && (task?.status === 'requested' || task?.status === 'in-progress'),
    [task]
  );

  const serviceRequestStatus = useMemo(
    () => serviceRequest && serviceRequest?.status === 'active',
    [serviceRequest]
  );

  const natAnswer = () => {
    const taskNumber = task?.getTaskNumberNAT();
    if (taskNumber === undefined || user?.userName === undefined) return;
    const link = document.createElement('a');
    const url = `${process.env.REACT_APP_MOBILE_APP_URL}/?requestNo=${taskNumber}&request-no=${taskNumber}&id=${user.userName}`;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const resendTaskNotification = async () => {
    if (task?.id === undefined) return;

    const response = await taskService.resendTask(task?.id);

    enqueueSnackbar(`${response?.status === 'OK' ? 'Message Sent' : 'An error has ocurred'}`, {
      variant: `${response?.status === 'OK' ? 'default' : 'error'}`,
    });
  };

  const closeRequest = async () => {
    try {
      const confirms = await confirmDialog({
        confirmText: 'Close it',
        title: 'Close HJ Assessment',
        description: (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Are you sure you want to close this HJ Assessment?
          </Typography>
        ),
      });

      if (confirms) {
        await updateServiceRequest(
          produce(serviceRequest!, (draft) => {
            draft.status = 'revoked';
          })
        );
        enqueueSnackbar('Request has been updated.');
      }
    } catch (error) {
      enqueueSnackbar('An error has ocurred.', { variant: 'error' });
    }
    refreshServiceRequest();
  };

  const getMeasure = useMemo(
    () => getMeasureReportGroupValue(getMeasureReportGroups(measureReport)),
    [measureReport]
  );
  const getObservations = useMemo(() => getObservationtypeValue(observations), [observations]);

  const reprocessMeasureReport = async () => {
    if (measureReport?.id === undefined) return;

    const confirms = await confirmDialog({
      confirmText: 'Agree',
      title: 'Are you sure you want to reprocess the measure report?',
      description: (
        <>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            Current Values
          </Typography>
          {getMeasure?.medicalComplex && (
            <Typography>
              <Item text="Medical complexity" color="success" /> {getMeasure?.medicalComplex}
            </Typography>
          )}
          {getMeasure?.socialComplex && (
            <Typography>
              <Item text="Social complexity" color="info" /> {getMeasure?.socialComplex}
            </Typography>
          )}
          {getMeasure?.serviceIntegration && (
            <Typography>
              <Item text="Service integration levels" color="warning" />{' '}
              {getMeasure?.serviceIntegration}
            </Typography>
          )}

          <br></br>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            New Values
          </Typography>
          {getObservations?.medicalComplex && (
            <Typography>
              <Item text="Medical complexity" color="success" /> {getObservations?.medicalComplex}
            </Typography>
          )}
          {getObservations?.socialComplex && (
            <Typography>
              <Item text="Social complexity" color="info" /> {getObservations?.socialComplex}
            </Typography>
          )}
          {getObservations?.serviceIntegration && (
            <Typography>
              <Item text="Service integration levels" color="warning" />{' '}
              {getObservations?.serviceIntegration}
            </Typography>
          )}
        </>
      ),
    });

    let response: any;
    if (confirms) {
      response = await taskService.reprocessMeasureReport(measureReport?.id);
      enqueueSnackbar(`${response?.status === 'OK' ? 'Reprocess' : 'Error Reprocess'}`, {
        variant: `${response?.status === 'OK' ? 'default' : 'error'}`,
      });
    }
  };

  const bodyPrintable = useMemo(
    () =>
      getPrintableTask(
        {
          patient,
          task,
          serviceRequest,
          planDefinition,
          questionnaire,
          questionnaireResponse,
          measureReport,
          ownerTask,
          requesterTask,
        },
        showMeasureQuestionnaireResponses
      ),
    [
      patient,
      task,
      serviceRequest,
      planDefinition,
      questionnaire,
      questionnaireResponse,
      measureReport,
      ownerTask,
      requesterTask,
      showMeasureQuestionnaireResponses,
    ]
  );

  const getPrintableReport = async () => {
    handleBackdrop(true);
    try {
      if (taskWrapper?.id !== undefined) {
        const response = await taskService.getPrintable(bodyPrintable);

        const url = window.URL.createObjectURL(
          new Blob([response?.data, { type: 'application/pdf' }])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.log(error);
    }
    handleBackdrop(false);
  };

  const saveAttachment = async () => {
    handleBackdrop(true);
    try {
      if (taskWrapper?.id !== undefined) {
        const response = await taskService.getPrintable(bodyPrintable);

        const base64PDF = btoa(String.fromCharCode(...new Uint8Array(response?.data)));

        const PDFPayload = {
          contentType: 'application/pdf',
          data: base64PDF,
          resourceType: 'Binary',
        } as Binary;

        const binary = await createBinary(PDFPayload);

        if (binary?.length > 0) {
          const mapDocumentReference = {
            content: [
              {
                attachment: {
                  contentType: `${binary?.[0]?.contentType}`,
                  creation: new Date().toISOString(),
                  title: `${taskWrapper?.getTaskNumberNAT()}`,
                  url: `${process.env.REACT_APP_FHIR_API_BASE_URL}/Binary/${binary?.[0]?.id}`,
                },
              },
            ],
            masterIdentifier: {
              use: 'official',
              value: 'value document',
            },
            resourceType: 'DocumentReference',
            status: 'current',
            subject: {
              reference: `Patient/${patient?.id}`,
            },
            type: {
              coding: [
                {
                  code: '34133-9',
                  system: 'CodeSystem/4',
                },
              ],
            },
          } as DocumentReference;

          const documentReference = await createDocumentReference(mapDocumentReference);

          if (documentReference?.length > 0) {
            enqueueSnackbar('Attachment has been saved.');
          }
        }
      }
    } catch (error) {
      enqueueSnackbar('An error has ocurred.', { variant: 'error' });
    }
    handleBackdrop(false);
  };

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {selectedTab === index && (
        <Stack sx={{ m: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={{ sm: 'row' }} justifyContent="flex-end" sx={{ mb: 3 }}>
                {taskStatus && (
                  <Tooltip title="Resend Notification">
                    <IconButton onClick={() => resendTaskNotification()}>
                      <Iconify icon={'mdi:email-resend'} color="#3dab2b" />
                    </IconButton>
                  </Tooltip>
                )}
                {serviceRequestStatus &&
                  natAnswer &&
                  checkAclValidation({ acls: [crsAcls.CRS.PATIENT.NAT_TASK.ANSWER_TASK] }) && (
                    <Tooltip title="Answer HJ">
                      <IconButton onClick={natAnswer}>
                        <Iconify icon={'ri:survey-fill'} color="#3dab2b" />
                      </IconButton>
                    </Tooltip>
                  )}

                {serviceRequestStatus &&
                  checkAclValidation({ acls: [crsAcls.CRS.PATIENT.NAT_TASK.CLOSE_REQUEST] }) && (
                    <Tooltip title="Close HJ Assessment">
                      <IconButton onClick={() => closeRequest()}>
                        <Iconify icon={'carbon:close-filled'} color="#ff4842" />
                      </IconButton>
                    </Tooltip>
                  )}
                {task && task?.status === 'completed' && (
                  <>
                    {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.NAT_TASK.PRINT_TASK] }) && (
                      <Tooltip title="Print">
                        <IconButton onClick={() => getPrintableReport()}>
                          <Iconify icon={'material-symbols:print'} color="#3dab2b" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Reprocess Measure">
                      <IconButton onClick={() => reprocessMeasureReport()}>
                        <Iconify icon={'material-symbols:cached'} color="#3dab2b" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save as Attachment">
                      <IconButton onClick={() => saveAttachment()}>
                        <Iconify icon={'material-symbols:file-save-rounded'} color="#3dab2b" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <DetailsSummary
                children={
                  <Link
                    to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id ?? '')}
                    target="_blank"
                  >
                    Member Details
                  </Link>
                }
                data={getMemberDetails}
                devMode={devMode}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <DetailsSummary title="HJ Assessment" data={getHJAssessment} devMode={devMode} />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <DetailsSummary title="Requester" data={getRequester} />
            </Grid>

            <Grid item xs={12}>
              <RequestReport
                questionnaire={questionnaire}
                questionnaireResponse={questionnaireResponse}
                measureReport={measureReport}
                devMode={devMode}
                showMeasureQuestionnaireResponses={showMeasureQuestionnaireResponses}
              />
            </Grid>

            <Grid item xs={12}>
              <RequestReportResponse
                questionnaire={questionnaire}
                questionnaireResponse={questionnaireResponse}
                devMode={devMode}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
    </div>
  );
};

export default TaskDetails;
