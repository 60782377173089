import { group } from 'console';
import { createWrapper } from '../../fhir-react/base';
import { Code } from '@mui/icons-material';

export const GroupWrapper = createWrapper('Group', (group) => ({
    resourceType: group?.resourceType,
    identifier: group?.identifier,
    active: group?.active,
    type: group?.type,
    actual: true,
    code: group?.code,
    name: group?.name,
    quantity: group?.quantity,
    managingEntity: group?.managingEntity,
    characteristic: group?.characteristic,
    member: group?.member,
    id: group?.id,
}));

export type WrappedGoal = ReturnType<typeof GroupWrapper>;