import * as Yup from 'yup';
import api from 'src/services/api';
import { useForm } from 'react-hook-form';
import { NoteDto } from 'src/@types/note';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { Button, CardContent, Dialog, DialogContent, DialogTitle, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { getPredefinedNote } from 'src/sections/crs/helpers/common';
import useLocales from 'src/hooks/useLocales';
import { isEmpty } from 'lodash';

type FormValues = {
  predefinedNote: string;
  text: string;
};

type Props = {
  openDialog: boolean;
  engagementWorkflowData: any;
  onCreate: () => void;
  onClose: () => void;
};

export default function AddNote({
  openDialog,
  engagementWorkflowData,
  onCreate,
  onClose
}: Props) {
  
  const { i18n } = useLocales();
  
  const [predefinedNotes, setPredefinedNotes] = useState<any>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const schema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });

  const defaultValues = {
    text: '',
    predefinedNote: '',
  }

  const methods = useForm<FormValues>({ resolver: yupResolver(schema), defaultValues});

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { text } = watch();

  useEffect(() => {
   let regex = /[{}]/;
    setDisabledButton(true);
   if(!regex.test(text) && !isEmpty(text)) 
     setDisabledButton(false);
 }, [text])

  const handlerClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const newNote: NoteDto = {
        referenceId: Number(engagementWorkflowData.recordId),
        text: values.text,
        type: "MemberEngagement",
      };

      const response = await api.notes.createNote(newNote);
      onCreate();
      
    } catch (error) {
      console.log(error);
    } finally {
      handlerClose();
    }
  };

  const getPredefinedNoteCustom = async () => { 
    const data = await getPredefinedNote(engagementWorkflowData, 'notes','engagement-predefined-notes', false);
    setPredefinedNotes(data);
  };

  useEffect(() => {
    getPredefinedNoteCustom()
  },[engagementWorkflowData])

  const handleChangePredefinedNote = (event: SelectChangeEvent<unknown>) => {
    setDisabledButton(true);
    const setDescription = 
      predefinedNotes?.find((e) => e.code === event.target.value )?.display ?? "";
    setValue('predefinedNote',String(event.target.value));
    setValue('text',setDescription);
   }

  return (
    <Dialog
      open={openDialog}
      onClose={handlerClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title"> Add Note</DialogTitle>
      <DialogContent>
        <CardContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3}>
              <Grid item md={12}>
                {predefinedNotes?.length > 0 && (
                  <RHFSelect 
                    name="predefinedNote" 
                    label={i18n('patients.details.notes.predefinedNote', 'crs')} 
                    onChange={(event) => handleChangePredefinedNote(event)}
                    fullWidth
                  >
                    <MenuItem disabled />
                      {predefinedNotes?.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option?.display}
                        </MenuItem>
                      ))}
                  </RHFSelect>
                )}
              </Grid>

              <Grid item md={12}>
                <RHFTextField name="text" label="Text*" multiline rows={2} fullWidth/>
              </Grid>

              <Grid item md={12} container direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || disabledButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
}
