import { Box, Grid, Tooltip, Typography } from '@mui/material';
import KeyValueGrid from 'src/components/KeyValueGrid';
import { capitalCase } from 'change-case';
import EditIcon from '@mui/icons-material/Edit';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';

interface Props {
  onOpenAssigned: any;
  patientLanguage: string | undefined;
  patientEngagementPermissions: any;
  workflowInstance: any;
  showOptInStatus?: boolean;
  tasks: WrappedTask[];
  consent: WrappedConsent[] | null;
}

export default function PatientEngagementInformation({
  onOpenAssigned,
  patientLanguage,
  workflowInstance,
  patientEngagementPermissions,
  showOptInStatus,
  tasks,
  consent
}: Props) {

  return (
    <Box sx={{ px: 3, pb: 2 }}>
      <KeyValueGrid label="Status" value={workflowInstance?.status || ''} />
      <KeyValueGrid
        label="Sub Status"
        value={workflowInstance?.stage?.name || ''}
      />
      <KeyValueGrid label="Language" value={patientLanguage || ''} />

      <Grid container item spacing={1} wrap="nowrap">
        <Grid container item xs={4} sm={4} md={4} lg={4} xl={4} justifyContent="flex-end">
          <Typography variant="body2" align="right">
            Assigned To
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          zeroMinWidth
          sx={{ alignItems: 'center' }}
        >
          <Tooltip
            title={
              workflowInstance?.ownedBy
                ? capitalCase(
                  workflowInstance?.ownedBy?.split('@')[0].replace('.', ' ')
                  )
                : ''
            }
          >
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {workflowInstance?.ownedBy
                ? capitalCase(
                  workflowInstance?.ownedBy?.split('@')[0].replace('.', ' ')
                  )
                : ''}
            </Typography>
          </Tooltip>
          {patientEngagementPermissions.isAllowedToEdit && (
            <Tooltip title="Edit Assigned To" placement="bottom">
              <EditIcon
                className="font-small margin-xs-right-btn text-primary div-hand"
                sx={{
                  color: '#1890FF',
                  cursor: 'pointer',
                  marginLeft: '8px',
                  fontSize: '1.2rem !important',
                }}
                onClick={onOpenAssigned}
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {showOptInStatus && (
        <KeyValueGrid
          label="Opt In Status"
          value={
            consent?.[0]?.getOptInStatus()
              ? consent?.[0]?.getOptInStatus()
              : 'Not available'
          }
        />
      )}

      {tasks?.length > 0 && (
        <Grid container item justifyContent="center" alignItems="center">
          <KeyValueGrid
            label="Interview Status"
            value={capitalCase(
              tasks
                ?.slice(-1)
                ?.map((item) => item?.status)
                ?.toString()
            )}
          />
        </Grid>
      )}
    </Box>
  );
}
