import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import useTabs from 'src/hooks/useTabs';
import { capitalCase } from 'change-case';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import Iconify from 'src/components/Iconify';
import { defaultSettings } from 'src/config';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'src/components/hook-form';
import { SettingForm, TenantForm, ThemeForm } from './TabsForms';
import { createRegistry, Registry, updateRegistry } from 'src/services/api/registry';

type Props = {
  row: Registry | undefined;
  open: boolean;
  onClose: VoidFunction;
  handleRefresh: VoidFunction;
};

export default function Form({ 
  row, 
  open, 
  onClose, 
  handleRefresh 
}: Props) {

  const user = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { currentTab, onChangeTab } = useTabs('setting');

  const SETTING_TABS = [
    {
      value: 'setting',
      icon: <Iconify icon={'ci:settings-filled'} width={20} height={20} />,
      component: <SettingForm/>,
    },
    {
      value: 'tenant',
      icon: <Iconify icon={'mdi:user'} width={20} height={20} />,
      component: <TenantForm/>,
    },
    {
      value: 'theme',
      icon: <Iconify icon={'ri:paint-brush-fill'} width={20} height={20} />,
      component: <ThemeForm/>,
    },
  ];

  const mapKeyValue = useMemo(() => {
    const keyValue = row?.keyValue && JSON.parse(row?.keyValue);
    return {
      realm: keyValue?.realm ?? '',
      logo: keyValue?.logo ?? '',
      tenantName: keyValue?.tenantName ?? '',
      pagePrefix: keyValue?.pagePrefix ?? '',
      tenantFavicon: keyValue?.tenantFavicon ?? '' ,
      tenantSpinnerLogo: keyValue?.tenantSpinnerLogo ?? '',
      url: keyValue?.url ?? '',
      themeMode: keyValue?.settings?.themeMode ?? '',
      themeDirection: keyValue?.settings?.themeDirection ?? '',
      themeContrast: keyValue?.settings?.themeContrast ?? '',
      themeLayout: keyValue?.settings?.themeLayout ?? '',
      themeColorPresets: keyValue?.settings?.themeColorPresets ?? ''
    }
  }, [row]);

  const defaultValues = useMemo(
    () =>
      ({
        keyRegistry: row?.keyRegistry ?? '',
        ...mapKeyValue,
        groupIdentifier: row?.groupIdentifier ?? 'tenant-theme',
        keyDescription: row?.keyDescription ?? '',
      } as any),
    [row]
  );

  const EventSchema = Yup.object().shape({
    keyRegistry: Yup.string().max(255).required('Registry is required'),
    keyDescription: Yup.string().max(5000),
    realm: Yup.string().max(255).required('Realm is required'),
    tenantName: Yup.string().max(255).required('Name is required'),
    pagePrefix: Yup.string().max(255).required('Page Prefix is required'),
    url: Yup.string().required('URL is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues
  });


  const { reset, control, handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    try {

      const payload = {
        ...(row?.id && {uuid: row?.uuid}),
        keyRegistry: data?.keyRegistry,
        groupIdentifier: data?.groupIdentifier,
        keyDescription: data?.keyDescription,
        keyValue: JSON.stringify({
          realm: data?.realm,
          logo: data?.logo ?? '',
          tenantName: data?.tenantName,
          pagePrefix: data?.pagePrefix,
          tenantFavicon: data?.tenantFavicon ?? '',
          tenantSpinnerLogo: data?.tenantSpinnerLogo ?? '',
          url: data?.url,
          settings: {
            themeMode: defaultSettings.themeMode,
            themeDirection: defaultSettings.themeDirection,
            themeContrast: defaultSettings.themeContrast,
            themeLayout: defaultSettings.themeLayout,
            themeColorPresets: data?.themeColorPresets ?? defaultSettings?.themeColorPresets,
          }
        }),
        inputMode: null,
        valueType: null
      }

      if(row?.id){
        await updateRegistry(payload);
      }else{
        await createRegistry(payload);
      }

      handleRefresh();

      enqueueSnackbar(row?.id ? 'Setting was updated' : 'Setting was created');
      onClose();
    } catch (e) {
      enqueueSnackbar('An occurred an error', { variant: 'error' })
      onClose();
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, row]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth='sm'>
      <DialogTitle>Setting Details</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {SETTING_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          {SETTING_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}

          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
