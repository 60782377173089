import { Button, Grid } from '@mui/material';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
interface ExternalLinkProps {
  externalLinkKeysForData: string[];
}

export default function ExternalLink({ externalLinkKeysForData }: ExternalLinkProps) {
  const { componentsData } = useTenantConfigData();

  function getExternalButtons() {
    return (
      <Grid container spacing={1}>
        {externalLinkKeysForData
          .filter(
            (key) =>
              componentsData.externalLink?.[key]?.buttonTitle &&
              componentsData.externalLink?.[key]?.url
          )
          .map((key, index) => (
            <Grid item key={index + key}>
              <Button
                variant="contained"
                onClick={() => {
                  window.open(componentsData.externalLink?.[key]?.url, '_blank');
                }}
              >
                {componentsData.externalLink?.[key]?.buttonTitle}
              </Button>
            </Grid>
          ))}
      </Grid>
    );
  }

  return externalLinkKeysForData && externalLinkKeysForData.length > 0
    ? getExternalButtons()
    : null;
}
