import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Stack,
  Typography,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGroup, useValueSet } from 'src/@nicheaim/fhir-react';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { GroupWrapper } from 'src/@nicheaim/fhir-base/wrappers/Group';
import useLocales from 'src/hooks/useLocales';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';

type FormValue = {
  name: string;
  code: string[];
  identifier: string;
  type: string;
  identifiervalue: string;
};

type Props = {
  open: boolean;
  onClose: VoidFunction;
  id: string;
  refresh: any;
};

export default function EditGroup({ refresh, id, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const [codeGroup] = useValueSet('ph-group-codes', { map: ValueSetWrapper });
  const [codeIdentifier] = useValueSet('ph-group-identifiers', { map: ValueSetWrapper });
  const [group, { update: UpdateGroup }] = useGroup(id, { map: GroupWrapper });
  const [codeRow, setCodeRow]: any = useState([]);

  const defaultValues = useMemo(
    () =>
    ({
      name: group?.name || '',
      code: group?.code?.coding?.map(item => item.display) || [],
      identifier: group?.identifier?.[0]?.type?.text || '',
      type: 'person',
      identifiervalue: group?.identifier != undefined ? group?.identifier[0]?.value : '' || '',
    } as FormValue),
    [group]
  );

  const EventSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    identifier: Yup.string(),
    type: Yup.string(),
    managingEntity: Yup.string(),
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit } = methods;

  useEffect(() => {
    const arrayCode: any = codeGroup?.asListAll() ?? [];

    const transformedArray: any[] = (group?.code?.coding ?? []).map((item) => ({
      code: item.code,
      display: item.display,
    }));

    const combinedArray: any[] = arrayCode?.concat(transformedArray);
    const filterCode: ValueSetComposeIncludeConcept[] = combinedArray?.reduce((result, obj) => {
      if (!result.some((item) => item.code === obj.code)) {
        result.push(obj);
      }
      return result;
    }, []);
    setCodeRow(filterCode)
  }, [group, codeGroup]);


  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    refresh();
    onClose();
  };

  const onSubmit = async (data: FormValue) => {
    const filterCode = codeRow?.filter(obj => data?.code?.includes(obj.display));
    const filterCodeUnique: ValueSetComposeIncludeConcept[] = filterCode.reduce((result, obj) => {
      if (!result.some((item) => item.code === obj.code)) {
        result.push(obj);
      }
      return result;
    }, []);
    try {
      await UpdateGroup({
        id: group?.id,
        resourceType: 'Group',
        name: data.name,
        active: true,
        actual: true,
        type: 'person',
        ...(filterCodeUnique?.length > 0 ? 
          { code: { coding: filterCodeUnique}}
        : null),
        identifier: [
          {
            system: `https://careflow2.nicheaim.com/group/${data?.identifier?.toLowerCase().replace(/\s+/g, '-')}`,
            type: {
              text: data.identifier
            },
            value: `${data.identifiervalue}`
          }
        ],
      }
      );

      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was updated.`);
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };

  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Group</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">{i18n('patients.details.groups.name', 'crs')}</Typography>
                <RHFTextField name="name" label={`${i18n('patients.details.groups.typename', 'crs')}*`} />

                <Typography variant="body2">{i18n('patients.details.groups.code', 'crs')}</Typography>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={codeRow?.map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="Code" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">{i18n('patients.details.groups.title', 'crs')}</Typography>
                <RHFSelect name="identifier" label="Identifier">
                  <MenuItem disabled></MenuItem>
                  {codeIdentifier?.asListAll()?.map((option) => (
                    <MenuItem key={option?.code} value={option?.display}>
                      {option?.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">{i18n('patients.details.groups.identifier', 'crs')}</Typography>
                <RHFTextField name="identifiervalue" label={i18n('patients.details.groups.identifierValue', 'crs')} />

                <Typography variant="body2">{i18n('patients.details.groups.type', 'crs')}</Typography>
                <RHFTextField disabled name="type" label={i18n('patients.details.groups.groupType', 'crs')} />

              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>

            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};






