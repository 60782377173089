import { Stack, Button, Box, DialogActions, DialogTitle } from '@mui/material';
import { useCurrentDialog } from 'src/stores/dialog';

interface ConfirmDialogProps {
  title?: string;
  description?: string | React.ReactNode;
  confirmText?: string;
  denyText?: string;
  cancelText?: string;
  cancellable?: boolean;
  disabledConfirm?: boolean;
}

function ConfirmDialog({
  title,
  description,
  confirmText,
  denyText,
  cancelText,
  cancellable = false,
  disabledConfirm = false,
}: ConfirmDialogProps) {
  const { close } = useCurrentDialog();

  return (
    <Box>
      <DialogTitle>{title || 'Are you sure?'}</DialogTitle>

      <Stack sx={{ p: 3 }}>{description || 'Are you sure you want to proceed?'}</Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        {cancellable && (
          <Button variant="text" color="error" onClick={() => close(null)}>
            {cancelText ?? 'Cancel'}
          </Button>
        )}

        <Button variant="outlined" color="inherit" onClick={() => close(false)}>
          {denyText ?? 'Cancel'}
        </Button>

        <Button type="submit" variant="contained" disabled={disabledConfirm} onClick={() => close(true)}>
          {confirmText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Box>
  );
}

export default ConfirmDialog;
