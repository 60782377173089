import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import useLocales from 'src/hooks/useLocales';
import { ErrorFieldState } from './ReferralInformation';

type ReferralOptionCode = 'internal' | 'external' | 'parent-guardian' | 'self';

export interface ReferralAutocompleteOption {
  code: ReferralOptionCode;
  label: string;
}

interface ReferralAutocompleteProps {
  optionLabel: string | undefined;
  patient: WrappedPatient | null;
  errors: ErrorFieldState;
  onChangeOption: (option: ReferralAutocompleteOption | undefined) => void;
}

export interface ReferralAutocompleteOptionTrigger {
  code: string;
  id: string;
}

function getOptions(showSelf: boolean) {
  let options: ReferralAutocompleteOption[] = [
    {
      code: 'internal',
      label: 'Internal Referral',
    },
    {
      code: 'external',
      label: 'External Referral',
    },
    {
      code: 'parent-guardian',
      label: 'Parent / Guardian',
    },
  ];

  if (showSelf) {
    options.push({
      code: 'self',
      label: 'Self-Referral',
    });
  }

  return options;
}

export default function ReferralAutocomplete({
  optionLabel,
  patient,
  errors,
  onChangeOption,
}: ReferralAutocompleteProps) {
  const opList = useMemo(() => getOptions(!!patient), [patient]);
  const [optionCode, setOptionCode] = useState<string>();

  const sOptionMemo = useMemo(() => {
    if (!optionCode) {
      return null;
    }

    return opList.find((item) => item.code === optionCode);
  }, [optionCode, opList]);

  const { i18n } = useLocales();

  function handleClearTextField() {
    setOptionCode(undefined);
    onChangeOption(undefined);
  }

  function getReadOnlyTextField() {
    return (
      <TextField
        label={`${i18n(
          'patientEngagement.details.workflow.checklist.referralInformation.referred',
          'engagement'
        )}*`}
        placeholder={`Select ${i18n(
          'patientEngagement.details.workflow.checklist.referralInformation.referred',
          'engagement'
        )}...`}
        variant="outlined"
        error={!!errors?.referralReferred}
        helperText={errors?.referralReferred}
        fullWidth
        value={optionLabel}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear selection"
                onClick={handleClearTextField}
                edge="end"
                size="small"
              >
                <ClearIcon />.
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  function getAutocomplete() {
    return (
      <Autocomplete
        fullWidth
        options={opList}
        value={sOptionMemo}
        onChange={(
          event: React.SyntheticEvent,
          value: string | ReferralAutocompleteOption | null
        ) => {
          if (!value || typeof value === 'string') {
            onChangeOption(undefined);
            setOptionCode(undefined);
            return;
          }

          setOptionCode(value.code);
          onChangeOption(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${i18n(
              'patientEngagement.details.workflow.checklist.referralInformation.referred',
              'engagement'
            )}*`}
            placeholder={`Select ${i18n(
              'patientEngagement.details.workflow.checklist.referralInformation.referred',
              'engagement'
            )}...`}
            variant="outlined"
            error={!!errors?.referralReferred}
            helperText={errors?.referralReferred}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {!patient ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }

  return optionLabel ? getReadOnlyTextField() : getAutocomplete();
}
