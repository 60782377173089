import { Button, Card, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import NoteAddForm from '../../common/NoteAddForm';
import { SummaryTable } from 'src/sections/crs/common';
import { referralService } from 'src/crs/referral/services';
import { DocumentList } from './patientDocuments/DocumentList';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_ASSESSMENTS, TABLE_HEAD_NOTES } from '../../common/table-head';
import { useCommunications, useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { AssessmentsFormsResponse } from 'src/crs/referral/services/ReferralService';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { mapAssessmentsForms, mapCommunicationsToNotesDisplay } from '../../common/common-utils';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import StartAssessmentsButton from './StartAssessmentsButton';
import ExpandableCard from 'src/components/common/ExpandableCard';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from '../../helpers/common';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { Add } from '@mui/icons-material';

type Props = {
  patient: WrappedPatient | null;
};

export default function PatientDocumentSummary({ patient }: Props) {
  const [communications, { create: createCommunication, refresh: refreshCommunications }] =
    useCommunications({ filter: { subject: patient?.id }, map: CommunicationWrapper });
  const [documentReferences, { refresh: refreshDocumentReference }] = useDocumentReferences({
    filter: { subject: patient?.id, _sort: '-date' },
  });

  const [assessmentsForms, setAssessmentsForms] = useState<AssessmentsFormsResponse[]>([]);
  const [openNote, setOpenNote] = useState(false);
  const { i18n } = useLocales();

  const handleCloseAddNote = () => {
    setOpenNote(false);
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};
    try {
      result = await createCommunication(data);
      handleCloseAddNote();
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const fetchAssessments = async () => {
    const result: any = await referralService.referralApiClient.assessmentsByPatient(patient?.id);
    setAssessmentsForms(mapAssessmentsForms(result));
  };

  useEffect(() => {
    fetchAssessments();
  }, [patient]);

  return (
    <Card sx={{ py: 2, boxShadow: 'none' }}>
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.ASSESSMENTS.VIEW] }) && (
        <ExpandableCard title={i18n('patients.details.assessments.title', 'crs')} sxProp={{ m: 1 }}>
          <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 0 }}>
            <Stack sx={{ m: 0 }}>
              {checkAclValidation({
                acls: [crsAcls.CRS.PATIENT.ASSESSMENTS.START],
              }) && (
                <StartAssessmentsButton
                  keyRegistry="crs-patient-assessments"
                  patientId={patient?.id ?? ''}
                />
              )}
            </Stack>
            <SummaryTable
              header={translateTableHead(TABLE_HEAD_ASSESSMENTS, 'crs')}
              data={assessmentsForms}
            />
          </Card>
        </ExpandableCard>
      )}
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.ATTACHMENTS.VIEW] }) && (
        <ExpandableCard title={i18n('patients.details.attachments.title', 'crs')} sxProp={{ m: 1 }}>
          <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 1 }}>
            <DocumentList
              patient={patient}
              documentReferences={documentReferences}
              showFilters={true}
              refreshDocumentReference={refreshDocumentReference}
              openCollapseExternal={true}
              handleUpdateResource={null}
              permissions={{
                isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.ATTACHMENTS.ADD] }),
                isAllowedToEdit: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.ATTACHMENTS.EDIT],
                }),
              }}
            />
          </Card>
        </ExpandableCard>
      )}

      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.NOTES.VIEW] }) && (
        <ExpandableCard title={i18n('patients.details.notes.title', 'crs')} sxProp={{ m: 1 }}>
          <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 1 }}>
            <Stack sx={{ m: 0 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.NOTES.ADD] }) && (
                  <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      onClick={() => setOpenNote(true)}
                    >
                      {i18n('patients.details.notes.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <SummaryTable
              header={translateTableHead(TABLE_HEAD_NOTES, 'crs')}
              data={mapCommunicationsToNotesDisplay(communications, [])}
            />
          </Card>
        </ExpandableCard>
      )}

      <NoteAddForm
        open={openNote}
        patient={patient}
        resource={[]}
        onCancel={handleCloseAddNote}
        handleCreate={handleCreateCommunicationFhir}
        typeNote="notes_patient"
      />
    </Card>
  );
}
