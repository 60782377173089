import { useEffect } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { User } from '../../types';
import useGridState from '../../hooks/useGridState';
import useFirebaseCollectionList from '../../hooks/useFirebaseCollectionList';
import UsersListFilter, { UserFilters } from './UsersListFilter';
import { trimMultipleWhiteSpaces } from 'src/utils/string';
import { capitalizeWords } from 'src/sections/careflow/permissions/permissions.helpers';
import CellRow from '../../components/CellRow';
import { QueryConstraint } from 'firebase/firestore';
import { collectionNames, dateRangeErrorMessage, defaultSorting } from '../../utils/constants';
import { getUserDetailPath } from '../../utils/helpers';

import ListContainer from '../../components/ListContainer';
import CustomDataGrid from '../../components/CustomDataGrid';
import { getCreatedOnGridColDef, getUpdatedOnGridColDef } from '../../utils/UI/gridColumns';
import OpenInNewTabIconButton from '../../components/OpenInNewTabIconButton';
import { filterByDateRange, filterByString, filterByStringExactMatch } from '../../utils/firestore';
import useLocales from 'src/hooks/useLocales';

const UsersList = () => {
  const { i18n } = useLocales();
  const [
    {
      orderBy,
      orderDirection,
      rowsPerPage,
      page,
      startAfter,
      rowsTotalCount,
      isFilterDrawerOpen,
      filtersForm,
      queryFilters,
      selectedFilterName,
    },
    {
      handleChangeRowsPerPage,
      handlePageChange,
      handleSortModelChange,
      onStartAfterInternalChange,
      onRowsTotalCountChange,
      setQueryFilters,
      setSelectedFilterName,
      setIsFilterDrawerOpen,
    },
  ] = useGridState<UserFilters>(defaultSorting, {
    id: '',
    email: '',
    userName: '',
    name: '',
    createdBy: '',
    createdOn: null,
    updatedBy: '',
    updatedOn: null,
  });

  const {
    data,
    isFetching: isLoading,
    count: totalUsersCount,
  } = useFirebaseCollectionList<User>(
    collectionNames.USERS,
    {
      orderBy,
      orderDirection,
      startAfter,
      limit: rowsPerPage,
    },
    queryFilters
  );

  const [users, usersDocs] = data ?? [];

  useEffect(() => {
    onStartAfterInternalChange(usersDocs?.[usersDocs?.length - 1]);
  }, [usersDocs]);

  useEffect(() => {
    if (!totalUsersCount) return;
    if (totalUsersCount === rowsTotalCount) return;
    onRowsTotalCountChange(totalUsersCount);
  }, [totalUsersCount]);

  const handleFiltering = () => {
    let queryFilters: QueryConstraint[] = [];

    switch (selectedFilterName) {
      case 'id':
        queryFilters = filterByStringExactMatch('id', filtersForm.values.id);
        break;
      case 'userName':
        queryFilters = filterByString('username', filtersForm.values.userName);
        break;
      case 'email':
      case 'createdBy':
      case 'updatedBy':
        queryFilters = filterByString(selectedFilterName, filtersForm.values?.[selectedFilterName]);
        break;
      case 'createdOn':
      case 'updatedOn':
        const filter = filterByDateRange(
          selectedFilterName,
          filtersForm.values?.[selectedFilterName]?.start,
          filtersForm.values?.[selectedFilterName]?.end
        );
        if (!filter) {
          filtersForm.setFieldError('createdOn', dateRangeErrorMessage);
          return;
        }
        queryFilters = filter;
        break;
      default:
        queryFilters = [];
        break;
    }

    if (queryFilters.length) handleSortModelChange([{ field: '', sort: null }]);
    setQueryFilters(queryFilters);
    setIsFilterDrawerOpen(false);
  };

  return (
    <>
      <ListContainer
        listTitle={i18n('yocare.userList.title')}
        pageTitle={i18n('yocare.userList.title')}
        breadCrumbsLinks={[
          { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
          { name: `${i18n('yocare.userList.yocareTitle')}` },
          { name: `${i18n('yocare.userList.title')}`, href: PATH_DASHBOARD.yoCare.users },
        ]}
        onFilterIconPress={() => {
          setIsFilterDrawerOpen(true);
        }}
        isFiltering={!!queryFilters.length}
      >
        <CustomDataGrid
          rows={users ?? []}
          getRowId={({ id }: User) => id}
          onRowDoubleClick={(params) => {
            window.open(getUserDetailPath((params.id ?? '') as string), '_blank');
          }}
          columns={usersGridColumns}
          loading={isLoading}
          rowCount={rowsTotalCount}
          page={page - 1}
          sortModel={orderBy ? [{ field: orderBy, sort: orderDirection ?? 'asc' }] : []}
          onSortModelChange={handleSortModelChange}
          pageSize={rowsPerPage}
          onPageSizeChange={handleChangeRowsPerPage}
          onPageChange={handlePageChange}
        />
      </ListContainer>
      <UsersListFilter
        selectedFilterName={selectedFilterName}
        onSetSelectedFilterName={setSelectedFilterName}
        containerSx={{ width: '23vw' }}
        open={isFilterDrawerOpen}
        onCloseIconButtonClick={() => {
          setIsFilterDrawerOpen(false);
        }}
        onApplyButtonClick={() => {
          handleFiltering();
        }}
        formik={filtersForm}
        onClearAllButtonClick={() => {
          setSelectedFilterName(null);
          filtersForm.resetForm();
        }}
      />
    </>
  );
};

const usersGridColumns: GridColDef[] = [
  {
    flex: 1,
    field: 'id',
    headerName: 'Id',
    renderCell: (params) => {
      const { id } = params.row as User;
      return <CellRow title={id} />;
    },
  },
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    sortable: false, //TODO: need to flatten personbasicdata struct to have name as primitive property
    valueGetter: (params) => {
      const { owned_person } = params.row as User;
      if (!owned_person) return '';
      const name = owned_person?.personBasicData?.name?.[0];

      return capitalizeWords(
        trimMultipleWhiteSpaces(`${name?.given?.join?.(' ') ?? ''} ${name?.family ?? ''}`).trim()
      );
    },
  },
  {
    flex: 1,
    field: 'email',
    headerName: 'Email',
  },
  {
    flex: 1,
    field: 'username',
    headerName: 'User Name',
  },
  { flex: 1, ...getCreatedOnGridColDef() },
  { flex: 1, ...getUpdatedOnGridColDef() },
  {
    flex: 0.3,
    field: 'action',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { id } = params.row as User;
      return <OpenInNewTabIconButton url={getUserDetailPath(id)} />;
    },
  },
];

export default UsersList;
