import { BACKEND_PREFIX_ROUTE, DEV_BACKEND_URL } from 'src/config';

function getProtocol() {
  const protocol = 'https:' == window.location.protocol ? 'https:' : 'http:';
  return protocol + '//';
}

export function getBackEndBaseUrl() {
  if (DEV_BACKEND_URL) {
    return DEV_BACKEND_URL;
  }

  return getProtocol() + window.location.host + BACKEND_PREFIX_ROUTE;
}

export function getFrontendBaseUrl() {
  return getProtocol() + window.location.host;
}
