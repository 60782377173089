import { useCallback, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirebaseCustomToken } from 'src/services/api/firebase';

const useFirebaseAuth = () => {
  const signInToFirebase = useCallback(async () => {
    const auth = getAuth();
    const { currentUser } = getAuth();
    if (currentUser) return;
    const firebaseToken = await getFirebaseCustomToken();
    if (!firebaseToken) return;
    signInWithCustomToken(auth, firebaseToken).catch((error) => {
      console.warn('Unable to sign in to Firebase', error.message);
    });
  }, []);

  useEffect(() => {
    signInToFirebase();
  }, [signInToFirebase]);
};

export default useFirebaseAuth;
