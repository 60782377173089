// @mui
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
// assets
import { useEffect, useState } from 'react';
import agent from 'src/api/agent';
import { REDIRECT_AUTHORIZE_URL } from 'src/config';
import { TenantI } from 'src/utils/auth-utils';
import MessagePage from './MessagePage';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SelectTenant() {
  const [tenantList, setTenantList] = useState<TenantI[]>([]);
  const [selectedTenantCode, setSelectedTenantCode] = useState<String>();

  async function loadTenants() {
    const tList = await agent.Tenant.getAll();
    setTenantList(tList);
  }

  useEffect(() => {
    loadTenants();
  }, []);

  const handleChangeTenant = (ev: any) => {
    const { value } = ev.target;
    const tenant = tenantList.find((tenant: TenantI) => tenant.code === value);

    if (tenant !== undefined) {
      setSelectedTenantCode(tenant.code);
    }
  };

  const selectTenantPage = (
    <Page title="Select Tenant">
      <Container sx={{ display: tenantList.length === 0 ? 'none' : 'inherit' }}>
        <ContentStyle sx={{ alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Please select your tenant
          </Typography>

          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            action={REDIRECT_AUTHORIZE_URL}
            method="post"
            id="context-form"
          >
            <FormControl fullWidth>
              <InputLabel id="tenant">Tenant</InputLabel>
              <Select
                labelId="tenant"
                label="tenant"
                id="tenant-select"
                name="tenant-code"
                value={selectedTenantCode || ''}
                onChange={handleChangeTenant}
              >
                {tenantList.map((tenant: TenantI, index: number) => (
                  <MenuItem value={tenant.code} key={index + '-' + tenant.code}>
                    {tenant.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button type="submit" variant="contained" size="large" disabled={!selectedTenantCode}>
              Submit
            </Button>
          </form>
        </ContentStyle>
      </Container>
    </Page>
  );

  const noTenantMessagePage = (
    <MessagePage message="No tenants available. Please contact your administrator." />
  );

  return tenantList.length === 0 ? noTenantMessagePage : selectTenantPage;
}
