import { SvgIconComponent } from '@mui/icons-material';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface CellRowProps {
  tooltipTitle?: string | null;
  Icon?: SvgIconComponent | null;
  title: string | null | undefined;
  titleSx?: SxProps;
}

const CellRow = ({ tooltipTitle = null, Icon, title, titleSx }: CellRowProps) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 0.5,
          alignItems: 'center',
        }}
      >
        {!!Icon && <Icon sx={{ marginRight: 0.5 }} />}
        <Typography
          sx={[{ whiteSpace: 'normal', wordBreak: 'break-word' }, ...spreadSxProp(titleSx)]}
          variant="body2"
        >
          {title ?? ''}
        </Typography>
      </Box>
    </span>
  </Tooltip>
);

export default CellRow;
