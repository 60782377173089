import moment from 'moment';
import { useForm } from 'react-hook-form';
import { capitalCase } from 'change-case';
import { getAgeGroup } from 'src/utils/fhir';
import { taskService } from 'src/nat/task/services';
import { useEffect, useMemo, useState } from "react";
import { getMonthsSince } from 'src/utils/formatTime';
import { useCodeSystems } from 'src/@nicheaim/fhir-react';
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedRelatedPerson } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { Backdrop, Button, CircularProgress, Drawer, Grid, List, ListItem, MenuItem, Stack, Typography } from "@mui/material";
import useLocales from 'src/hooks/useLocales';

type FormValues = {
  isForMember: string;
  guardian: string;
};

type Props = {
  isOpen: boolean;
  handlerIsOpen: Function;
  patient: WrappedPatient | null;
  relatedPersons: WrappedRelatedPerson[] | null;
  handleInitiateHJRequest: (data:any) => Promise<any>;
}
export default function CreateTask({
  isOpen,
  patient,
  relatedPersons,
  handlerIsOpen,
  handleInitiateHJRequest,
}: Props) {

  const { i18n } = useLocales();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [validation, setValidation] = useState<any>({
    button: true,
    guardian: true,
    message: ''
  });
  const [recipient, setRecipient] = useState<any>(null);

  const [ codeSystemAgeRange, ] = useCodeSystems({
    filter: {
      name: 'Needs Assesment Tool Age Groups'
    }
  });

  const defaultValues = useMemo(
    () =>
    ({
      isForMember: '',
      guardian: '',
    } as FormValues),
    [patient]
  );

  const methods = useForm({defaultValues,});

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      setRecipient(null);
    }
  }, [isOpen]);

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
  } = methods;

  const { isForMember, guardian } = watch();

  useEffect(() => {

    let validateContact;
    setRecipient(null);

    if(isForMember === 'yes'){
      setValue('guardian', '')
      validateContact = (patient?.getPrimaryEmail()?.value || patient?.getPrimaryPhone()) ?? null;
      if(validateContact){ 
        setValidation({
          button: false,
          guardian: true,
        })
      }else{
        setValidation({
          button: true,
          guardian: true,
          message: `${i18n('patients.title', 'crs')} does not have email or phone number, please, enter at least one`
        })
      }
    }else {
      const getGuardian = relatedPersons?.find((item) => item?.id === guardian);
      validateContact = (getGuardian?.getPrimaryEmail() || getGuardian?.getPrimaryPhone()) ?? null;
      if(getGuardian && validateContact){ 
        setValidation({
          button: false,
          guardian: false,
        })
        setRecipient(`${getGuardian.resourceType}/${getGuardian?.id}`);
      }else if(!getGuardian) 
      {
        setValidation({
          button: true,
          guardian: false,
        })
      }else if(getGuardian && !validateContact){
        setValidation({
          button: true,
          guardian: true,
          message: 'Guardian does not have email or phone number, please, enter at least one'
        })
      }
    }
  }, [isForMember, guardian]);

  const patientAgeRange = useMemo(() => 
    patient?.birthDate ? getAgeGroup(getMonthsSince(new Date(patient.birthDate)), codeSystemAgeRange?.[0]) : undefined
  , [patient, codeSystemAgeRange]);

  const onSubmit = async (data: FormValues) => {

    setOpenBackdrop(true);

    const payloadTask = { 
      patientId: patient?.id,
      ageRange: patientAgeRange?.[0]?.code,
      guarantor: recipient,
    };
    
    const createTask = await taskService.createTask(payloadTask);

    try {
      await handleInitiateHJRequest(createTask);
    } catch (error) {
      console.log('error',error)
    }
    handlerIsOpen(false);
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <List component="nav">
          <ListItem>
            <Grid width={800} container justifyContent="space-between">
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                    {i18n('patients.title', 'crs')} Details
                  </Typography>
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item xs={6}>
                      <Typography key="3" color="text.primary" variant='subtitle1'>
                        Name
                      </Typography>
                      <Typography key="3" style={{ color: "#919eab" }} >
                        {patient?.getFullName()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography key="3" color="text.primary" variant='subtitle1'>
                        Gender
                      </Typography>
                      <Typography key="3" style={{ color: "#919eab" }} >
                        {patient?.gender && capitalCase(patient?.gender)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography key="3" color="text.primary" variant='subtitle1'>
                        Birth Date
                      </Typography>
                      <Typography key="3" style={{ color: "#919eab" }} >
                        {patient?.birthDate ?
                          moment.utc(new Date(patient?.birthDate)).format('MM/DD/YYYY') : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <Typography variant='button'>The HJ Assessment is for the {i18n('patients.title', 'crs')}?</Typography>
                    <RHFSelect name="isForMember" label="">
                      <MenuItem key={'yes'} value={'yes'}>
                        Yes
                      </MenuItem>
                      <MenuItem key={'no'} value={'no'}>No</MenuItem>
                    </RHFSelect>  
                    {isForMember === 'yes' && validation.message && (
                      <Typography variant="caption" color="red">
                        {i18n('patients.title', 'crs')} does not have email or phone number, please, enter at least one
                      </Typography>
                    )}
                    <Typography variant='button' sx={{ pt: 1 }}>
                      Choose Available Guardian
                    </Typography>
                    <RHFSelect name="guardian" label="" fullWidth={true} disabled={validation.guardian}>
                      {relatedPersons?.map((option: WrappedRelatedPerson) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.getFullName()}
                        </MenuItem>
                      ))}
                    </RHFSelect> 
                    {isForMember === 'no' && guardian && validation.message && (
                      <Typography variant="caption" color="red">
                        Guardian does not have email or phone number, please, enter at least one
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ my: 2, p:2 }}>
                <Button 
                  color="primary" 
                  variant="contained" 
                  type="submit" 
                  disabled={validation.button}
                >
                  Confirm
                </Button>
              </Stack>
            </Grid>
          </ListItem>
        </List>
      </FormProvider>
    </Drawer>
  )
}