import { useState, useEffect, useCallback } from 'react';
import { Box, SxProps, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router';
import PageContainer from '../components/PageContainer';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Person, User } from '../types';

import useFirebaseCollection from '../hooks/useFirebaseCollection';
import { getIdFromURI, getUserDetailPath } from '../utils/helpers';

import { collectionNames } from '../utils/constants';

import { filterByStringExactMatch } from '../utils/firestore';

import PersonBanner from '../components/PersonBanner';
import LoadingScreen from 'src/components/LoadingScreen';
import { Person as PersonIcon } from '@mui/icons-material';
import TabPanel from '../components/TabPanel';

import PersonDetailsContainer from '../components/PersonDetailsContainer';
import useLocales from 'src/hooks/useLocales';

const UsersDetails: any = () => {
  const { id } = useParams();
  const { i18n } = useLocales();
  const [user, setUser] = useState<User | null>(null);
  const [personId, setPersonId] = useState<string | null>(null);
  const [selectedTabIndex, setTabIndexSelected] = useState(0);

  const { data, isLoading: isUserLoading } = useFirebaseCollection<User>({
    collectionName: collectionNames.USERS,
    queryConstraints: filterByStringExactMatch('id', id),
  });

  const {
    data: personData,
    isLoading: isPersonLoading,
    refetch: refetchPerson,
  } = useFirebaseCollection<Person>({
    collectionName: collectionNames.PERSONS,
    queryConstraints: filterByStringExactMatch('id', personId),
    queryProps: {
      enabled: !!personId,
    },
  });

  useEffect(() => {
    setUser(data?.[0]?.[0] ?? null);
  }, [data]);

  useEffect(() => {
    if (!user?.owned_person?.reference) return;
    const personId = getIdFromURI(user?.owned_person?.reference);
    if (!personId) return;
    setPersonId(personId);
  }, [user]);

  const OnPersonDetailsSave = useCallback(() => {
    refetchPerson();
  }, [refetchPerson]);

  const person = personData?.[0]?.[0];

  return (
    <PageContainer
      pageTitle={i18n('yocare.userDetails.title')}
      title={i18n('yocare.userDetails.title')}
      breadCrumbsLinks={[
        { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
        { name: `${i18n('yocare.userList.yocareTitle')}` },
        { name: `${i18n('yocare.userList.title')}`, href: PATH_DASHBOARD.yoCare.users },
        { name: `${i18n('yocare.userDetails.title')}`, href: user?.id ? getUserDetailPath(user.id) : '' },
      ]}
    >
      <>
        <Box sx={{ width: '100%', height: '100%' }}>
          {!isUserLoading && !isPersonLoading ? (
            <>
              <PersonBanner
                person={person}
                containerSx={commonSeparator}
                onImageUploadSucess={OnPersonDetailsSave}
              />
              <Tabs
                sx={commonSeparator}
                value={selectedTabIndex}
                onChange={(_, newValue) => {
                  setTabIndexSelected(newValue);
                }}
              >
                <Tab icon={<PersonIcon />} iconPosition="start" label="General Information" />
              </Tabs>
              <Box sx={commonSeparator}>
                <TabPanel index={0} selectedTabIndex={selectedTabIndex}>
                  <PersonDetailsContainer
                    person={person ?? null}
                    onPersonDetailsSave={OnPersonDetailsSave}
                    user={user}
                  />
                </TabPanel>
              </Box>
            </>
          ) : (
            <LoadingScreen />
          )}
        </Box>
      </>
    </PageContainer>
  );
};

const commonSeparator: SxProps = {
  marginTop: 2.5,
};

export default UsersDetails;
