import { Stack, Typography, Grid, Card, Collapse, IconButton, SxProps, Theme, Box } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Iconify from '../Iconify';

export interface ExpandableCardProps {
  openCollapseExternal?: boolean;
  title?: string;
  sxProp?: SxProps<Theme>;
  children?: ReactNode | undefined;
  inlineChildren?: ReactNode | undefined;
  isCollapsible?: boolean;
}

export default function ExpandableCard({
  openCollapseExternal = false,
  title,
  sxProp,
  children,
  inlineChildren,
  isCollapsible = true,
}: ExpandableCardProps) {
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    setOpenCollapse(openCollapseExternal);
  }, [openCollapseExternal]);

  return (
    <Card sx={sxProp}>
      <Stack sx={{ m: isCollapsible ? 2 : 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ ml: 1 }}>
            {title}
            {isCollapsible && (
              <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                <Iconify
                  icon={openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </IconButton>
            )}
          </Typography>
          {inlineChildren}
        </Box>
      </Stack>
      <Collapse in={openCollapse}>{children ? children : null}</Collapse>
    </Card>
  );
}
