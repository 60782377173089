import { Box, SxProps } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface TabPanelProps {
  id?: string;
  index: number;
  selectedTabIndex: number;
  children?: React.ReactNode;
  containerSx?: SxProps;
}

const TabPanel = ({ id, index, selectedTabIndex, children, containerSx }: TabPanelProps) => {
  const isSelected = index === selectedTabIndex;
  return (
    <Box
      sx={[{}, ...spreadSxProp(containerSx)]}
      role="tabpanel"
      hidden={!isSelected}
      id={`${id ? `${id}-` : ''}tabpanel-${index}`}
    >
      {isSelected && children}
    </Box>
  );
};

export default TabPanel;
