import { 
  Add, 
  Business, 
  Check, 
  ContactMail, 
  ContactPhone, 
  LabelImportant, 
  MedicalInformationOutlined, 
  Person, 
  Place, 
  Preview, 
  Work 
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { formatUSAddress } from "src/utils/fhir";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import VerifiedDecorator from "src/sections/crs/provider/VerifiedDecorator";
import { Box, Button, IconButton, Radio, Tooltip, Typography } from "@mui/material";
import GeneralPractitionerPreviewDrawer from "src/sections/crs/provider/GeneralPractitionerPreviewDrawer";
import { AssignableGeneralPractitioner, GeneralPractitionerData, OnPractitionerRoleSelect } from "src/@types/crs/providerDirectory";

export interface AssignableGeneralPractitionerWithSorting
  extends AssignableGeneralPractitioner {
  sortNumber?: number;
}

export interface RoleGridProps {
  practitionerRoles: AssignableGeneralPractitioner[];
  selectedPractitionerRole: AssignableGeneralPractitioner | null;
  sectionEnabled: boolean;
  isLoading: boolean;
  onPractitionerRoleSelect: OnPractitionerRoleSelect;
  isIncidentDispositionDone: boolean;
  hsAssignedPractitionerRoleId?: string | null;
  onCreateRoleButtonClick?: () => void;
  showCreateRoleButton?: boolean;
  showPractitionerColumn?: boolean;
  isButtonMode?: boolean;
}

const RoleGrid = ({
  practitionerRoles,
  sectionEnabled,
  isLoading,
  onPractitionerRoleSelect,
  selectedPractitionerRole,
  onCreateRoleButtonClick,
  isIncidentDispositionDone,
  hsAssignedPractitionerRoleId,
  showCreateRoleButton = true,
  showPractitionerColumn = false,
  isButtonMode = false,
}: RoleGridProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [
    selectedPractitionerRoleForPreview,
    setSelectedPractitionerRoleForPreview,
  ] = useState<AssignableGeneralPractitioner | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [practitionerRolesRows, setPractitionerRolesRows] = useState<
    AssignableGeneralPractitioner[]
  >([]);

  const previewPractitionerRole: OnPractitionerRoleSelect = useCallback(
    (practitionerRole: AssignableGeneralPractitioner) => {
      setSelectedPractitionerRoleForPreview(practitionerRole);
      setIsPreviewOpen(true);
    },
    []
  );

  useEffect(() => {
    const practitionerRolesSorting: AssignableGeneralPractitionerWithSorting[] = [
      ...practitionerRoles,
    ];
    const firstPractitionerRoleToShow = practitionerRolesSorting.find(
      ({ resourceId }) => resourceId === selectedPractitionerRole?.resourceId
    );
    if (firstPractitionerRoleToShow) firstPractitionerRoleToShow.sortNumber = 1;

    const secondPractitionerRoleToShow = practitionerRolesSorting.find(
      ({ resourceId }) => resourceId === hsAssignedPractitionerRoleId
    );
    if (
      secondPractitionerRoleToShow &&
      firstPractitionerRoleToShow !== secondPractitionerRoleToShow
    )
      secondPractitionerRoleToShow.sortNumber = 2;

    setPractitionerRolesRows(
      practitionerRolesSorting.sort((a, b) => {
        if (a.sortNumber === undefined) {
          return 1;
        } else if (b.sortNumber === undefined) {
          return -1;
        } else {
          return a.sortNumber - b.sortNumber;
        }
      })
    );
  }, [practitionerRoles, selectedPractitionerRole]);

  const handlePractitionerRoleSelect: OnPractitionerRoleSelect = useCallback(
    (practitionerRole) => {
      setPage(0);
      onPractitionerRoleSelect(practitionerRole);
    },
    [onPractitionerRoleSelect]
  );

  return (
    <Box sx={{ width: "100%" }}>
      {!isIncidentDispositionDone && showCreateRoleButton && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: 2,
          }}
        >
          <Tooltip
            title={
              sectionEnabled ? "Create Role" : "Must select a provider first"
            }
          >
            <span>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  onCreateRoleButtonClick?.();
                }}
                disabled={!sectionEnabled}
              >
                Create Role
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
      <Box>
        <DataGrid
          rowHeight={160}
          autoHeight={true}
          density="standard"
          className="providerList pointerRow"
          getRowClassName={(params) => {
            const practitionerRole = params.row as AssignableGeneralPractitioner;
            const { resourceId } = practitionerRole;
            const isHSPractitionerRole =
              resourceId && resourceId === hsAssignedPractitionerRoleId;
            if (isHSPractitionerRole) return "HSPractitionerRole";
            return "";
          }}
          rows={practitionerRolesRows}
          getRowId={({ resourceId }) => resourceId}
          onRowClick={(params) => {
            if (!sectionEnabled || isIncidentDispositionDone || isButtonMode)
              return;
            const practitionerRole = params.row as AssignableGeneralPractitioner;
            if (
              selectedPractitionerRole?.resourceId ===
              practitionerRole.resourceId
            )
              return;
            handlePractitionerRoleSelect(practitionerRole);
          }}
          onRowDoubleClick={(params) => {
            if (!sectionEnabled) return;
            previewPractitionerRole(
              params.row as AssignableGeneralPractitioner
            );
          }}
          columns={getRoleGridColumns({
            isButtonDisabled: !sectionEnabled || isIncidentDispositionDone,
            isPreviewButtonDisabled: !sectionEnabled,
            onPreviewPractitionerRole: previewPractitionerRole,
            selectedPractitionerRole,
            onPractitionerRoleSelect: handlePractitionerRoleSelect,
            showPractitionerColumn,
            isButtonMode,
            isLoading,
            hsAssignedPractitionerRoleId,
          })}
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnMenu
          loading={isLoading}
          rowsPerPageOptions={[5, 10, 25]}
          pageSize={rowsPerPage}
          page={page}
          onPageChange={(pageChange: any) => {
            setPage(pageChange.page);
          }}
          onPageSizeChange={(pageChange: any) => {
            setRowsPerPage(pageChange.pageSize);
          }}
          pagination
        />
        <GeneralPractitionerPreviewDrawer
          open={isPreviewOpen}
          generalPractitioner={selectedPractitionerRoleForPreview}
          onClose={() => {
            setIsPreviewOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export interface GetRoleGridColumns {
  selectedPractitionerRole: AssignableGeneralPractitioner | null;
  onPractitionerRoleSelect: OnPractitionerRoleSelect;
  onPreviewPractitionerRole: OnPractitionerRoleSelect;
  isButtonDisabled: boolean;
  isPreviewButtonDisabled: boolean;
  showPractitionerColumn: boolean;
  isButtonMode: boolean;
  isLoading: boolean;
  hsAssignedPractitionerRoleId: string | null | undefined;
}

export const getRoleGridColumns = ({
  selectedPractitionerRole,
  onPractitionerRoleSelect,
  onPreviewPractitionerRole,
  isButtonDisabled,
  isPreviewButtonDisabled,
  showPractitionerColumn,
  isButtonMode,
  isLoading,
  hsAssignedPractitionerRoleId,
}: GetRoleGridColumns): GridColDef[] => [
  ...(showPractitionerColumn
    ? [
        {
          field: "hsPractitioner",
          sortable: false,
          headerName: "Health Story Practitioner Role Identifier",
          align: "center",
          flex: 0.2,
          renderCell: (params) => {
            const practitionerRole = params.row as AssignableGeneralPractitioner;
            const { resourceId } = practitionerRole;
            const isHSPractitionerRole =
              resourceId && resourceId === hsAssignedPractitionerRoleId;
            if (!isHSPractitionerRole) return null;

            return (
              <Tooltip sx={{}} title={"Practitioner Role from Health Story"}>
                <LabelImportant fontSize="large" htmlColor="#5cb85c" />
              </Tooltip>
            );
          },
        } as GridColDef,
      ]
    : []),
  {
    field: "role",
    headerName: "Role",
    flex: 0.6,
    sortable: false,
    renderCell: (params) => {
      const { role } = params.row as AssignableGeneralPractitioner;

      return (
        <Box justifyContent={"center"}>
          {!!role && (
            <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
              <Tooltip title="Role">
                <Work sx={{ marginRight: 1 }} fontSize={"small"} />
              </Tooltip>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <>
                  <Typography
                    sx={{
                      marginRight: 1,
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {role}
                  </Typography>
                </>
              </Box>
            </Box>
          )}
        </Box>
      );
    },
  },
  ...(showPractitionerColumn
    ? [
        {
          field: "Practitioner",
          sortable: false,
          headerName: "Practitioner",
          flex: 1.5,
          renderCell: (params) => {
            const {
              practitioner: resource,
            } = params.row as AssignableGeneralPractitioner;
            if (!resource) return null;
            return (
              <GeneralPractitionerResourceColumn
                resource={resource}
                resourceType="Practitioner"
              />
            );
          },
        } as GridColDef,
      ]
    : []),
  {
    field: "Organization",
    sortable: false,
    headerName: "Organization",
    flex: 1.5,
    renderCell: (params) => {
      const {
        organization: resource,
      } = params.row as AssignableGeneralPractitioner;
      if (!resource) return null;
      return (
        <GeneralPractitionerResourceColumn
          resource={resource}
          resourceType="Organization"
        />
      );
    },
  },
  {
    field: "action",
    headerName: " ",
    align: "center",

    flex: 0.3,
    renderCell: (params) => {
      const practitionerRole = params.row as AssignableGeneralPractitioner;
      const { resourceId } = practitionerRole;
      const isSelected = resourceId === selectedPractitionerRole?.resourceId;
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {!isButtonMode ? (
            <>
              <Tooltip title="Preview Role">
                <span>
                  <IconButton
                    disabled={isPreviewButtonDisabled}
                    onClick={(event) => {
                      onPreviewPractitionerRole(practitionerRole);
                      event.stopPropagation();
                    }}
                  >
                    <Preview />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Select Role">
                <span>
                  <Radio
                    checked={isSelected}
                    disabled={isButtonDisabled}
                    onChange={() => {
                      onPractitionerRoleSelect(practitionerRole);
                    }}
                    value={resourceId}
                    name="practitionerRoleRadioButton"
                    inputProps={{ "aria-label": resourceId }}
                  />
                </span>
              </Tooltip>
            </>
          ) : practitionerRole.practitioner ? (
            <LoadingButton
              variant="contained"
              disabled={isSelected || isButtonDisabled || isLoading}
              startIcon={isSelected ? <Check /> : undefined}
              loading={isLoading}
              onClick={() => {
                if (isSelected) return;
                onPractitionerRoleSelect(practitionerRole);
              }}
            >
              {isSelected ? "Selected" : "Select"}
            </LoadingButton>
          ) : null}
        </Box>
      );
    },
  },
];

const GeneralPractitionerResourceColumn = ({
  resource,
  resourceType,
}: {
  resource: GeneralPractitionerData;
  resourceType: "Practitioner" | "Organization";
}) => {
  const address = formatUSAddress(resource?.address);
  return (
    <Box justifyContent={"center"}>
      <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
        <Tooltip title="NPI">
          <MedicalInformationOutlined
            sx={{ marginRight: 1 }}
            fontSize={"small"}
          />
        </Tooltip>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <>
            <Typography sx={{ marginRight: 1 }}>
              {resource?.npi ?? ""}
            </Typography>
            <VerifiedDecorator isVerified={!!resource?.isVerified} />
          </>
        </Box>
      </Box>
      {!!resource?.fullName && (
        <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
          <Tooltip title={resourceType}>
            {resourceType === "Organization" ? (
              <Business sx={{ marginRight: 1 }} fontSize={"small"} />
            ) : (
              <Person sx={{ marginRight: 1 }} fontSize={"small"} />
            )}
          </Tooltip>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: 1,
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {resource?.fullName}
            </Typography>
          </Box>
        </Box>
      )}
      {!!resource?.email && (
        <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
          <Tooltip title="Email">
            <ContactMail sx={{ marginRight: 1 }} fontSize={"small"} />
          </Tooltip>
          <Typography
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {resource?.email ?? ""}
          </Typography>
        </Box>
      )}
      {!!resource?.phone && (
        <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
          <Tooltip title="Phone">
            <ContactPhone sx={{ marginRight: 1 }} fontSize={"small"} />
          </Tooltip>
          <Typography
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {resource?.phone ?? ""}
          </Typography>
        </Box>
      )}
      {!!address && (
        <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
          <Tooltip title="Organization's Address">
            <Place sx={{ marginRight: 1 }} fontSize={"small"} />
          </Tooltip>
          <Typography
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {address}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RoleGrid;
