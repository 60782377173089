import { useCallback, useEffect, useMemo } from 'react';
import { TextField, Grid, Autocomplete, CircularProgress } from '@mui/material';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from '../../../../../components/CustomModal';
import useObjectState from 'src/hooks/useObjectState';
import {
  CodeSystemConcept,
  Reference,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import {
  PatientWrapper,
  WrappedPatient,
} from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCareTeam } from '../../../../../@nicheaim/fhir-base/wrappers/CareTeam';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import { onSuccess } from '../../../../../@types/crs/case';
import DatePickerMoment from 'src/components/DatePickerMoment';
import moment, { DurationInputArg2 } from 'moment';
import { useActivityDefinitions, usePatient, usePractitioners } from 'src/@nicheaim/fhir-react';
import {
  ActivityDefinitionWrapper,
  WrappedActivityDefinition,
} from 'src/@nicheaim/fhir-base/wrappers/ActivityDefinition';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { debounce } from 'src/utils/timers';
import SearchMember, {
  SearchMemberProps,
  getMemberResourceTypes,
  MemberResourceTypeOption,
} from '../SearchMember/SearchMember';
import { WrappedPractitionerRole } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { useSnackbar } from 'notistack';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { ActionData } from '../GoalsGrid/GoalModal';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { createTask, updateTask } from 'src/services/api/case';
import useLocales from 'src/hooks/useLocales';
import { getDynamicValueExpressionByPath } from 'src/utils/fhir';
import { WrappedOrganization } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { WrappedRelatedPerson } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import useTaskContext from 'src/hooks/useTaskContext';
import { isEmpty } from 'lodash';
import { capitalCase } from 'change-case';
import GoalInfoRibbon from '../GoalsGrid/GoalInfoRibbon';

export interface TaskModalProps extends CustomModalBasicProps {
  patientExternal?: WrappedPatient | null;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  taskToEdit?: WrappedTask;
  taskBasedOn?: WrappedTask | null;
}

export type TaskSelectedMemberOption = MemberResourceTypeOption;

export type TaskType = CodeSystemConcept;

export type TaskSelectedMemberValue =
  | WrappedPractitioner
  | WrappedCareTeam
  | WrappedPractitionerRole
  | WrappedPatient
  | WrappedOrganization
  | WrappedRelatedPerson;

export interface TaskFormState {
  isLoading: boolean;
  activityDefinition: WrappedActivityDefinition | null;
  taskType: TaskType | null;
  taskStatus: TaskStatus | null;
  taskPriority: TaskPriority | null;
  taskIntent: TaskIntent | null;
  requester: WrappedPractitioner | null;
  taskOwner: TaskSelectedMemberValue | null;
  requesterPerformer: TaskSelectedMemberValue | null;
  performer: TaskSelectedMemberValue | null;
  requestedStart: moment.Moment | null;
  requestedEnd: moment.Moment | null;
  requestedBy: string | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  description: string | null;
  comments: string | null;
}

export interface ErrorFieldState {
  taskStatus?: string | null;
  taskIntent?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  requestedStart?: string | null;
  requestedEnd?: string | null;
  description?: string | null;
}

export type TaskStatus = ValueSetComposeIncludeConcept;

export type TaskPriority = ValueSetComposeIncludeConcept;

export type TaskIntent = ValueSetComposeIncludeConcept;

export interface TaskSelectedMemberState {
  isSearchMemberOpen: boolean;
  inputSelected: string | null;
  selectedTaskOwner: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedRequesterPerformer: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedPerformer: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  taskOwnerOptions: (TaskSelectedMemberOption | TaskSelectedMemberValue)[];
}

const TaskModal = ({
  patientExternal,
  onSuccessfulCreation,
  onSuccessfulEdit,
  carePlan,
  taskToEdit,
  open,
  onClose,
  goal,
  taskBasedOn,
  ...taskModalProps
}: TaskModalProps) => {
  const [
    {
      isLoading,
      activityDefinition,
      taskType,
      taskStatus,
      taskPriority,
      taskIntent,
      requester,
      startDate,
      endDate,
      description,
      comments,
      taskOwner,
      requesterPerformer,
      performer,
      requestedBy,
      requestedStart,
      requestedEnd,
    },
    updateState,
  ] = useObjectState<TaskFormState>(
    getInitialState({
      taskToEdit,
      taskTypes: [],
      taskIntents: [],
      taskPriorities: [],
      taskStatuses: [],
      activityDefinitions: [],
    })
  );
  const { i18n } = useLocales();

  const { basedOn } = useTaskContext();

  const { enqueueSnackbar } = useSnackbar();

  const [activityDefinitionsRecords] = useActivityDefinitions({
    map: ActivityDefinitionWrapper,
    filter: {
      status: 'active',
    },
  });

  const compareByDescription = function( a, b ) {
    if ( a.description < b.description ){
      return -1;
    }
    if ( a.description > b.description ){
      return 1;
    }
    return 0;
  };  

  const activityDefinitions = useMemo(
    () =>
      activityDefinitionsRecords.reduce<WrappedActivityDefinition[]>(
        (distinctActivityDefinitions, activityDefinition) => {
          if (distinctActivityDefinitions.find(({ title }) => title === activityDefinition.title))
            return distinctActivityDefinitions;

          return [...distinctActivityDefinitions, activityDefinition];
        },
        []
      ).sort(compareByDescription),
    [activityDefinitionsRecords]
  );
  
  const {
    valueSets: [taskTypes, taskStatuses, taskIntents, taskPriorities],
  } = useValueSetsByIdentifiers([
    'ph-task-type-codes',
    'ph-task-status',
    'ph-task-intent',
    'ph-task-priority',
  ]);

  const [patientRecord, { isFetching: isPatientLoading }] = usePatient(
    taskToEdit?.getPatientId?.(),
    {
      map: PatientWrapper,
      autofetch: !!(taskToEdit?.getPatientId?.() && !patientExternal),
    }
  );

  const patient: WrappedPatient | null = useMemo(() => {
    if (patientExternal) return patientExternal;
    if (patientRecord) return patientRecord;
    return null;
  }, [patientRecord, patientExternal]);

  const [{ requesterFieldValue, requesterFilter }, updateRequesterData] = useObjectState<{
    requesterFieldValue: string;
    requesterFilter: any;
  }>({ requesterFieldValue: '', requesterFilter: {} });

  const [errors, updateErrorState] = useObjectState<ErrorFieldState>(errorsInitialState);

  useEffect(() => {
    if (!open) return;
    updateState(
      getInitialState({
        taskToEdit,
        taskTypes: taskTypes?.asList?.(),
        taskIntents: taskIntents?.asList?.(),
        taskPriorities: taskPriorities?.asList?.(),
        taskStatuses: taskStatuses?.asList(),
        activityDefinitions: activityDefinitions,
      })
    );
    updateRequesterData({ requesterFieldValue: '', requesterFilter: {} });
    updateErrorState(errorsInitialState);
    updateSelectedMember(getInitialSelectedState(taskToEdit, !!patient));
  }, [open, taskToEdit, taskTypes, patient, taskStatuses, taskIntents, taskPriorities]);

  const [
    {
      isSearchMemberOpen,
      selectedTaskOwner,
      selectedRequesterPerformer,
      selectedPerformer,
      inputSelected,
      taskOwnerOptions,
    },
    updateSelectedMember,
  ] = useObjectState<TaskSelectedMemberState>(getInitialSelectedState(taskToEdit, !!patient));

  const [
    practitioners,
    {
      isLoading: isPractitionersLoading,
      isFetching: isPractitionersFetching,
      isRefetching: isPractitionersRefetching,
    },
  ] = usePractitioners({
    map: PractitionerWrapper,
    filter: requesterFilter,
    pagination: {
      pageSize: 10,
    },
  });

  const handleRequesterFilterChange = useCallback(
    debounce((value: string) => {
      updateRequesterData({
        requesterFilter: value
          ? {
              name: value,
            }
          : null,
      });
    }, 500),
    []
  );

  const handleOnRequesterFieldChange = (text: string) => {
    updateRequesterData({
      requesterFieldValue: text,
    });
    handleRequesterFilterChange(text ?? null);
  };

  const handleOnSave = async () => {
    const errors: ErrorFieldState = {};
    if (!description?.trim?.()?.length) errors.description = 'Must specify a description';
    if (!taskStatus) errors.taskStatus = 'Must select a valid status';
    if (!taskIntent) errors.taskIntent = 'Must select a valid intent';
    if (startDate && endDate && endDate?.isBefore(startDate, 'date'))
      errors.startDate = "Start Date can't be after End Date";
    if (startDate && !startDate?.isValid()) errors.startDate = 'Must specify a valid date';
    if (endDate && !endDate?.isValid()) errors.endDate = 'Must specify a valid date';
    if (requestedStart && requestedEnd && requestedEnd?.isBefore(requestedStart, 'date'))
      errors.requestedStart = "Requested Start can't be after Requested End";
    if (Object.keys(errors).length) {
      updateErrorState({ ...errors });
      return;
    }

    updateErrorState(errorsInitialState);
    const isCreating = !taskToEdit?.id;
    const { actionCall, payload: taskData, successfulMessage } = getTaskPayload(isCreating);
    updateState({ isLoading: true });

    const response = !taskToEdit?.id
      ? await createTask(taskData)
      : await updateTask(taskData, taskToEdit?.id as string);
    updateState({ isLoading: false });
    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }

    enqueueSnackbar(successfulMessage);
    actionCall?.(response);
    onClose?.({}, 'backdropClick');
  };

  const getTaskPayload = (isCreating: boolean): ActionData => ({
    payload: {
      description,
      comments,
      status: taskStatus?.code ?? null,
      intent: taskIntent?.code ?? null,
      priority: taskPriority?.code ?? null,
      taskType: taskType?.code ?? null,
      taskTypeDisplay: taskType?.display ?? null,
      startDate: startDate?.toISOString() ?? null,
      endDate: endDate?.toISOString() ?? null,
      requesterId: requester?.id ? `${requester?.resourceType}/${requester?.id}` : null,
      requesterDisplay: requester?.getFullName() ?? '',
      ownerId: taskOwner?.id ? `${taskOwner?.resourceType}/${taskOwner?.id}` : null,
      ownerDisplay: getOwnerDisplayLabel(taskOwner),
      requesterPerformerId: requesterPerformer?.id
        ? `${requesterPerformer?.resourceType}/${requesterPerformer?.id}`
        : null,
      requesterPerformerDisplay: getOwnerDisplayLabel(requesterPerformer),
      performerId: performer?.id ? `${performer?.resourceType}/${performer?.id}` : null,
      performerDisplay: getOwnerDisplayLabel(performer),
      requestedStart: requestedStart?.toISOString() ?? null,
      requestedEnd: requestedEnd?.toISOString() ?? null,
      ...(isCreating
        ? {
            patientId: patient?.id,
            patientName: patient?.getFullName(),
            activityDefinitionId: activityDefinition?.id,
            carePlanId: carePlan?.id,
            goalId: goal?.id,
            basedOn: !!basedOn?.length ? basedOn : undefined,
            ...(taskBasedOn?.id && { basedOn: [{ reference: `Task/${taskBasedOn?.id}` }] }),
          }
        : {}),
    },
    actionCall: isCreating ? onSuccessfulCreation : onSuccessfulEdit,
    successfulMessage: isCreating ? 'Task Succesfully Created' : 'Task Successfully Modified',
  });

  const title = !taskToEdit
    ? `${i18n('patients.details.tasks.titleAddTasks', 'crs')}`
    : 'Edit Task';

  const updateSelection = (updateKey: string, stateKey: string, resource: any) => {
    const updateObj: any = { isSearchMemberOpen: false };
    updateObj[updateKey] = resource;
    updateSelectedMember(updateObj);
    updateState({ [stateKey]: resource });
  };

  return (
    <CustomModal
      keepMounted
      open={open}
      title={title}
      breadcrumbs={['List of Tasks', title]}
      onSave={handleOnSave}
      onCancel={onClose as Function}
      onClose={onClose}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll' }, isSearchMemberOpen ? { width: '94vw' } : {}]}
      childrenWithoutPadding={goal ? <GoalInfoRibbon goal={goal} /> : null}
      {...taskModalProps}
    >
      <Grid container my={3} justifyContent={'space-between'}>
        <Grid item xs={isSearchMemberOpen ? 6 : 12}>
          <GridSection mt={0}>
            {!!taskToEdit && (
              <GridItem>
                <TextField
                  fullWidth
                  label={i18n('tasks.taskNo', 'crs')}
                  value={taskToEdit?.getInternalNumber?.() ?? ''}
                  type="text"
                  placeholder={i18n('tasks.taskNo', 'crs')}
                  variant="outlined"
                  disabled
                />
              </GridItem>
            )}
          </GridSection>
          <GridSection>
            <GridItem>
              <TextField
                fullWidth
                label={i18n('patients.details.tasks.organization', 'crs')}
                value={patient?.getOrganization() ?? ''}
                type="text"
                placeholder="Organization"
                variant="outlined"
                disabled
              />
            </GridItem>
            <GridItem>
              <TextField
                fullWidth
                label={i18n('patients.details.tasks.patientName', 'crs')}
                value={patient?.getFullName() ?? ''}
                type="text"
                placeholder="Patient Name"
                variant="outlined"
                disabled
              />
            </GridItem>
          </GridSection>
          <GridSection>
            {!!taskToEdit && (
              <GridItem>
                <TextField
                  fullWidth
                  label={i18n('patients.details.tasks.requestedBy', 'crs')}
                  value={requestedBy ?? ''}
                  type="text"
                  placeholder={i18n('patients.details.tasks.requestedBy', 'crs')}
                  variant="outlined"
                  disabled
                />
              </GridItem>
            )}
            <GridItem>
              <Autocomplete
                disabled={isLoading || !!taskToEdit}
                value={activityDefinition}
                fullWidth
                onChange={(_: React.SyntheticEvent, activityDefinition) => {
                  const priority = getValueSetConceptValue(
                    taskPriorities?.asList?.() ?? [],
                    activityDefinition?.priority
                  );
                  const intent = getValueSetConceptValue(
                    taskIntents?.asList?.() ?? [],
                    getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.intent')
                  );

                  const status = getValueSetConceptValue(
                    taskStatuses?.asList?.() ?? [],
                    getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.status')
                  );

                  const taskType = activityDefinition?.code?.coding?.[0] ?? null;
                  let taskStartDate: moment.Moment | null = null;
                  let taskEndDate: moment.Moment | null = null;
                  if (
                    activityDefinition?.timingDuration?.value &&
                    activityDefinition?.timingDuration?.unit
                  ) {
                    taskStartDate = startDate?.isValid?.() ? startDate : moment();
                    taskEndDate = taskStartDate
                      .clone()
                      .add(
                        activityDefinition?.timingDuration?.value,
                        activityDefinition?.timingDuration?.unit as DurationInputArg2
                      );
                  }
                  updateState({
                    activityDefinition,
                    ...(status ? { taskStatus: status } : {}),
                    ...(taskEndDate && taskStartDate
                      ? { endDate: taskEndDate, startDate: taskStartDate }
                      : {}),
                    ...(taskType ? { taskType: taskType as CodeSystemConcept } : {}),
                    ...(priority ? { taskPriority: priority } : {}),
                    ...(intent ? { taskIntent: intent } : {}),
                    ...(activityDefinition?.description
                      ? {
                          description:
                            activityDefinition?.description ??
                            activityDefinition?.title ??
                            activityDefinition?.name,
                        }
                      : {}),
                  });
                }}
                options={activityDefinitions}
                getOptionLabel={({ title }: WrappedActivityDefinition) => title as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.activityDefinition', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskType}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskType) => {
                  updateState({ taskType });
                }}
                options={(taskTypes?.asList() ?? []) as TaskType[]}
                getOptionLabel={({ display }: TaskType) => display as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.taskType', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskStatus) => {
                  if (taskStatus) updateErrorState({ taskStatus: null });
                  updateState({ taskStatus });
                }}
                options={taskStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskStatus) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.taskStatus}
                    helperText={errors?.taskStatus}
                    label={`${i18n('patients.details.tasks.status', 'crs')}*`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskPriority}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskPriority) => {
                  updateState({ taskPriority });
                }}
                options={taskPriorities?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskPriority) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.priority', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskIntent}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskIntent) => {
                  if (taskIntent) updateErrorState({ taskIntent: null });
                  updateState({ taskIntent });
                }}
                options={taskIntents?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskIntent) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.taskIntent}
                    helperText={errors?.taskIntent}
                    label={`${i18n('patients.details.tasks.intent', 'crs')}*`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={requester}
                fullWidth
                onBlur={() => {
                  handleOnRequesterFieldChange('');
                }}
                filterOptions={(x) => x}
                onChange={(_: React.SyntheticEvent, practitioner) => {
                  updateState({ requester: practitioner });
                }}
                options={practitioners}
                getOptionLabel={(requester: TaskFormState['requester']) =>
                  requester?.getFullName() ?? ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={requesterFieldValue}
                    label={i18n('patients.details.tasks.requester', 'crs')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const text = event.target.value;
                      handleOnRequesterFieldChange(text);
                    }}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isPractitionersLoading ||
                          isPractitionersFetching ||
                          isPractitionersRefetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={isLoading || (isPatientLoading && !patient)}
                value={selectedTaskOwner}
                onChange={(_: React.SyntheticEvent, taskOwnerOption) => {
                  const optionAsValue = taskOwnerOption as TaskSelectedMemberValue;
                  const optionAsOption = taskOwnerOption as TaskSelectedMemberOption;
                  if (
                    !optionAsValue?.resourceType &&
                    optionAsOption?.value !== 'Patient' &&
                    optionAsOption
                  ) {
                    updateSelectedMember({
                      isSearchMemberOpen: true,
                      selectedTaskOwner: optionAsOption,
                      inputSelected: 'taskOwner',
                    });
                    return;
                  }
                  if (optionAsOption?.value === 'Patient') {
                    updateSelectedMember({
                      selectedTaskOwner: patient,
                      inputSelected: 'taskOwner',
                    });
                    updateState({ taskOwner: patient });
                    return;
                  }
                  updateSelectedMember({ selectedTaskOwner: null, inputSelected: null });
                  updateState({ taskOwner: null });
                }}
                options={taskOwnerOptions}
                getOptionLabel={(option) => {
                  const optionAsValue = option as TaskSelectedMemberValue;
                  const optionAsOption = option as TaskSelectedMemberOption;

                  if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                  let display = getOwnerDisplayLabel(optionAsValue);
                  if (optionAsValue?.resourceType === 'Patient') {
                    display = `${display} - Current Patient`;
                  }

                  return display;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.taskOwner', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isPatientLoading && !patient ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={isLoading || (isPatientLoading && !patient)}
                value={selectedRequesterPerformer}
                onChange={(_: React.SyntheticEvent, taskOwnerOption) => {
                  const optionAsValue = taskOwnerOption as TaskSelectedMemberValue;
                  const optionAsOption = taskOwnerOption as TaskSelectedMemberOption;
                  if (
                    !optionAsValue?.resourceType &&
                    optionAsOption?.value !== 'Patient' &&
                    optionAsOption
                  ) {
                    updateSelectedMember({
                      isSearchMemberOpen: true,
                      selectedRequesterPerformer: optionAsOption,
                      inputSelected: 'requesterPerformer',
                    });
                    return;
                  }
                  if (optionAsOption?.value === 'Patient') {
                    updateSelectedMember({
                      selectedRequesterPerformer: patient,
                      inputSelected: 'requesterPerformer',
                    });
                    updateState({ requesterPerformer: patient });
                    return;
                  }
                  updateSelectedMember({ selectedRequesterPerformer: null, inputSelected: null });
                  updateState({ requesterPerformer: null });
                }}
                options={taskOwnerOptions}
                getOptionLabel={(option) => {
                  const optionAsValue = option as TaskSelectedMemberValue;
                  const optionAsOption = option as TaskSelectedMemberOption;

                  if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                  let display = getOwnerDisplayLabel(optionAsValue);
                  if (optionAsValue?.resourceType === 'Patient') {
                    display = `${display} - Current Patient`;
                  }

                  return display;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.requesterPerformer', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isPatientLoading && !patient ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={isLoading || (isPatientLoading && !patient)}
                value={selectedPerformer}
                onChange={(_: React.SyntheticEvent, taskOwnerOption) => {
                  const optionAsValue = taskOwnerOption as TaskSelectedMemberValue;
                  const optionAsOption = taskOwnerOption as TaskSelectedMemberOption;
                  if (
                    !optionAsValue?.resourceType &&
                    optionAsOption?.value !== 'Patient' &&
                    optionAsOption
                  ) {
                    updateSelectedMember({
                      isSearchMemberOpen: true,
                      selectedPerformer: optionAsOption,
                      inputSelected: 'performer',
                    });
                    return;
                  }
                  if (optionAsOption?.value === 'Patient') {
                    updateSelectedMember({
                      selectedPerformer: patient,
                      inputSelected: 'performer',
                    });
                    updateState({ performer: patient });
                    return;
                  }
                  updateSelectedMember({ selectedPerformer: null, inputSelected: null });
                  updateState({ performer: null });
                }}
                options={taskOwnerOptions}
                getOptionLabel={(option) => {
                  const optionAsValue = option as TaskSelectedMemberValue;
                  const optionAsOption = option as TaskSelectedMemberOption;

                  if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                  let display = getOwnerDisplayLabel(optionAsValue);
                  if (optionAsValue?.resourceType === 'Patient') {
                    display = `${display} - Current Patient`;
                  }

                  return display;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.performer', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isPatientLoading && !patient ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                value={startDate}
                error={errors?.startDate}
                disabled={isLoading}
                label={i18n('patients.details.tasks.startDate', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      startDate: null,
                    });
                  }
                  updateState({
                    startDate: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                error={errors?.endDate}
                value={endDate}
                disabled={isLoading}
                label={i18n('patients.details.tasks.endDate', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      endDate: null,
                    });
                  }
                  updateState({
                    endDate: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                value={requestedStart}
                error={errors?.requestedStart}
                disabled={isLoading}
                label={i18n('patients.details.tasks.requestedStart', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      requestedStart: null,
                    });
                  }
                  updateState({
                    requestedStart: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                error={errors?.requestedEnd}
                value={requestedEnd}
                disabled={isLoading}
                label={i18n('patients.details.tasks.requestedEnd', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      requestedEnd: null,
                    });
                  }
                  updateState({
                    requestedEnd: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>
          {!!taskToEdit && (
            <GridSection>
              <GridItem>
                <DatePickerMoment
                  disabled
                  label={i18n('patients.details.tasks.authoredOn', 'crs')}
                  value={
                    moment(taskToEdit?.authoredOn).isValid()
                      ? moment(taskToEdit?.authoredOn)
                      : !taskToEdit
                      ? moment()
                      : null
                  }
                  onChange={() => {}}
                />
              </GridItem>
              <GridItem>
                <DatePickerMoment
                  disabled
                  label={'Last Modified Date'}
                  value={
                    moment(taskToEdit?.lastModified).isValid()
                      ? moment(taskToEdit?.lastModified)
                      : null
                  }
                  onChange={() => {}}
                />
              </GridItem>
            </GridSection>
          )}
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              value={description ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                if (value.trim().length) updateErrorState({ description: null });
                updateState({
                  description: event.target.value,
                });
              }}
              error={!!errors.description}
              helperText={errors.description}
              label={`${i18n('patients.details.tasks.description', 'crs')}*`}
              placeholder={`${i18n('patients.details.tasks.description', 'crs')}*`}
              variant="outlined"
            />
          </GridSection>
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateState({
                  comments: event.target.value,
                });
              }}
              value={comments ?? ''}
              label={i18n('patients.details.tasks.comments', 'crs')}
              placeholder={i18n('patients.details.tasks.comments', 'crs')}
              variant="outlined"
            />
          </GridSection>
        </Grid>
        {isSearchMemberOpen && (
          <Grid item xs={5.9}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                if (inputSelected === 'taskOwner') {
                  updateSelection('selectedTaskOwner', 'taskOwner', resource);
                } else if (inputSelected === 'requesterPerformer') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', resource);
                } else if (inputSelected === 'performer') {
                  updateSelection('selectedPerformer', 'performer', resource);
                }
              }}
              onClear={() => {
                if (inputSelected === 'taskOwner') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', null);
                  updateState({ taskOwner: null });
                } else if (inputSelected === 'requesterPerformer') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', null);
                  updateState({ requesterPerformer: null });
                } else if (inputSelected === 'performer') {
                  updateSelection('selectedPerformer', 'performer', null);
                }
              }}
              externalResourceType={(() => {
                const selectedOption =
                  (inputSelected === 'taskOwner'
                    ? getExternalResourceType(selectedTaskOwner)
                    : undefined) ??
                  (inputSelected === 'requesterPerformer'
                    ? getExternalResourceType(selectedRequesterPerformer)
                    : undefined) ??
                  (inputSelected === 'performer'
                    ? getExternalResourceType(selectedPerformer)
                    : undefined);
                return selectedOption;
              })()}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

const errorsInitialState: ErrorFieldState = {
  taskStatus: null,
  taskIntent: null,
  startDate: null,
  endDate: null,
  description: null,
};

export const getResourceDataFromFhirId = (fhirId: string | undefined) => fhirId?.split('/') ?? [];
export const getTaskOwnerByReference = (
  reference: Reference | undefined
): TaskFormState['taskOwner'] => {
  let taskOwner: TaskFormState['taskOwner'] = null;
  const display = reference?.display ?? '';
  const [resourceType, taskOwnerId] = getResourceDataFromFhirId(reference?.reference);
  if (taskOwnerId) {
    switch (resourceType) {
      case 'Practitioner':
      case 'Patient':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          getFullName: () => display,
        } as WrappedPractitioner | WrappedPatient;
        break;
      case 'PractitionerRole':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          getPractitionerName: (_: WrappedPractitioner[]) => display,
        } as WrappedPractitionerRole;
        break;
      case 'CareTeam':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          name: display,
        } as WrappedCareTeam;
        break;
      case 'Organization':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          name: display,
        } as WrappedOrganization;
        break;
    }
  }
  return taskOwner;
};

export const getOwnerDisplayLabel = (option: TaskFormState['taskOwner']): string => {
  switch (option?.resourceType) {
    case 'Practitioner':
    case 'Patient':
      return option?.getFullName() as string;
    case 'PractitionerRole':
      return option?.getPractitionerName() as string;
    case 'CareTeam':
      return option?.name as string;
    case 'Organization':
      return option?.name as string;
    case 'RelatedPerson':
      return option?.getFullName() as string;
    default:
      return '';
  }
};

export const getPractitionerFromReference = (
  reference: Reference | undefined
): WrappedPractitioner | null => {
  if (!reference?.reference) return null;
  const [resourceType, practitionerId] = getResourceDataFromFhirId(reference?.reference);
  return {
    id: practitionerId,
    resourceType: resourceType as 'Practitioner',
    getFullName: () => reference?.display ?? '',
  } as WrappedPractitioner;
};

const getInitialState = ({
  taskToEdit,
  taskTypes,
  taskStatuses,
  taskIntents,
  taskPriorities,
  activityDefinitions,
}: {
  taskToEdit: WrappedTask | undefined;
  taskTypes: TaskType[] | undefined;
  taskStatuses: TaskStatus[] | undefined;
  taskIntents: TaskIntent[] | undefined;
  taskPriorities: TaskPriority[] | undefined;
  activityDefinitions: WrappedActivityDefinition[] | null;
}): TaskFormState => {
  const taskType = getValueSetConceptValue(taskTypes ?? [], taskToEdit?.getTaskType?.());
  const taskStatus = getValueSetConceptValue(taskStatuses ?? [], taskToEdit?.status);
  const taskPriority = getValueSetConceptValue(taskPriorities ?? [], taskToEdit?.priority);
  const taskIntent = getValueSetConceptValue(taskIntents ?? [], taskToEdit?.intent);
  let startDate: moment.Moment | null = moment(taskToEdit?.executionPeriod?.start ?? null);
  let endDate: moment.Moment | null = moment(taskToEdit?.executionPeriod?.end ?? null);
  if (!startDate.isValid()) startDate = !taskToEdit ? moment() : null;
  if (!endDate.isValid()) endDate = null;
  const requester = getPractitionerFromReference(taskToEdit?.requester);
  const taskOwner = getTaskOwnerByReference(taskToEdit?.owner);
  const activityDefinition = activityDefinitions?.find(
    (e) => e?.id === taskToEdit?.instantiatesCanonical?.split('/')?.[1]
  );
  const getExtensionTask = taskToEdit?.extension?.find(
    (e) => e?.url === process.env.REACT_APP_TASK_EXTENSION
  )?.extension;
  const requesterPerformer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'requesterPerformer')?.valueReference
  );
  const performer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'performer')?.valueReference
  );
  const requestedStart = moment(
    getExtensionTask?.find((e) => e?.url === 'requestedPeriod')?.valuePeriod?.start ?? null
  );
  const requestedEnd = moment(
    getExtensionTask?.find((e) => e?.url === 'requestedPeriod')?.valuePeriod?.end ?? null
  );
  const requestedByMetaTag =
    taskToEdit?.meta?.tag?.find((e) => e.code === 'ph-created-by')?.display ?? null;
  const requestedBy = requestedByMetaTag
    ? capitalCase(requestedByMetaTag?.split('@')[0].replace('.', ' '))
    : null;

  return {
    taskType,
    taskStatus,
    taskPriority,
    taskIntent,
    startDate,
    endDate,
    requester,
    taskOwner,
    description: taskToEdit?.description ?? null,
    comments: taskToEdit?.getComments?.() ?? null,
    isLoading: false,
    activityDefinition: activityDefinition ?? null,
    requesterPerformer,
    performer,
    requestedBy,
    requestedStart,
    requestedEnd,
  };
};

const getInitialSelectedState = (
  taskToEdit: WrappedTask | null | undefined,
  showPatient: boolean
) => {
  const getExtensionTask = taskToEdit?.extension?.find(
    (e) => e?.url === process.env.REACT_APP_TASK_EXTENSION
  )?.extension;
  const requesterPerformer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'requesterPerformer')?.valueReference
  );
  const performer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'performer')?.valueReference
  );
  return {
    isSearchMemberOpen: false,
    selectedTaskOwner: taskToEdit ? getTaskOwnerByReference(taskToEdit?.owner) : null,
    selectedRequesterPerformer: taskToEdit ? requesterPerformer : null,
    selectedPerformer: taskToEdit ? performer : null,
    inputSelected: null,
    taskOwnerOptions: [...getMemberResourceTypes(showPatient)],
  };
};

export const getExternalResourceType = (
  option: any
): SearchMemberProps['externalResourceType'] | undefined => {
  if (!isEmpty(option)) {
    const selectedOption = option as TaskSelectedMemberOption;
    return selectedOption?.value as SearchMemberProps['externalResourceType'];
  }
  return undefined;
};

export default TaskModal;
