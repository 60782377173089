import { 
  Container, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Tooltip, 
  Typography 
} from "@mui/material";
import { useMemo, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { NPI_IDENTIFIER_SYSTEM } from "../../constants";
import CloseIconButton from "src/components/CloseIconButton";
import { Practitioner } from "src/@nicheaim/fhir-base/mappings/Practitioner";
import { AssignableGeneralPractitioner } from "src/@types/crs/providerDirectory";
import GeneralPractitionerPreviewDrawer from "../GeneralPractitionerPreviewDrawer";
import { FhirResource } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export interface ExistingPractitionerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  practitioners: Practitioner[];
}

const ExistingPractitionerDialog = ({
  isOpen,
  onClose,
  practitioners,
}: ExistingPractitionerDialogProps) => {
  const [selectedResource, setSelectedResource] = useState<Practitioner | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const assignablePractitionerData = useMemo(
    (): AssignableGeneralPractitioner | null =>
      !!selectedResource
        ? {
            resourceId: selectedResource.id as string,
            resourceType: "Practitioner",
            practitioner: {
              id: selectedResource.id as string,
              address: {},
              fullName: "",
              isVerified: true,
              resource: {
                ...selectedResource,
              } as FhirResource,
            },
          }
        : null,
    [selectedResource]
  );

  const handlePreviewPractitioner = (practitioner: Practitioner) => {
    setSelectedResource(practitioner);
    setIsPreviewOpen(true);
  };
  
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Container>Practitioners Related</Container>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginTop: 2, marginLeft: 1 }}>
            <Typography>
              There are practitioners linked to the provider selected:
            </Typography>
          </DialogContentText>
          <TableContainer sx={{ mt: 2, maxHeight: 400 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>NPI</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {practitioners.map((practitioner) => {
                  const { id, name } = practitioner;
                  const firstName = name?.[0]?.given?.[0] ?? "";
                  const lastName = name?.[0]?.family ?? "";
                  const fullName = `${firstName} ${lastName}`.trim();
                  return (
                    <TableRow
                      onDoubleClick={() => {
                        handlePreviewPractitioner(practitioner);
                      }}
                      key={id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        {practitioner?.identifier?.find?.(
                          ({ system }) => system === NPI_IDENTIFIER_SYSTEM
                        )?.value ?? ""}
                      </TableCell>
                      <TableCell>{fullName}</TableCell>
                      <TableCell>
                        <Tooltip title={"Preview Practitioner"}>
                          <IconButton
                            onClick={() => {
                              handlePreviewPractitioner(practitioner);
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <CloseIconButton onClick={onClose} autoFocus />
        </DialogActions>
      </Dialog>
      <GeneralPractitionerPreviewDrawer
        open={isPreviewOpen}
        generalPractitioner={assignablePractitionerData}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
      />
    </>
  );
};

export default ExistingPractitionerDialog;