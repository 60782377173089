import { GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import moment from 'moment';
import { Person as PersonIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import { BaseFirebaseEntity, Timestamp } from '../../types';
import CellRow from '../../components/CellRow';
import { NOTAPPLICABLE } from 'src/sections/crs/constants';

export type PartialGridColDef = Partial<GridColDef>;

export const getCreatedOnGridColDef = (options?: PartialGridColDef): GridColDef => ({
  field: 'createdOn',
  headerName: 'Created On',
  renderCell: (params) => {
    const { createdOn, createdBy } = params.row as BaseFirebaseEntity;
    return renderActionInfoRow(createdOn, createdBy, 'Created By', 'Created On');
  },
  ...(options ? options : {}),
});

export const getUpdatedOnGridColDef = (options?: PartialGridColDef): GridColDef => ({
  field: 'updatedOn',
  headerName: 'Updated On',
  renderCell: (params) => {
    const { updatedOn, updatedBy } = params.row as BaseFirebaseEntity;
    return renderActionInfoRow(updatedOn, updatedBy, 'Updated By', 'Updated On');
  },
  ...(options ? options : {}),
});

export const renderActionInfoRow = (
  executedOn: Timestamp | null | undefined,
  executedBy: string | null | undefined,
  toolTipBy?: string | null,
  toolTipOn?: string | null
) => {
  const date = moment(executedOn?.seconds ? executedOn.seconds * 1000 : null);
  const { formattedDueDate: formattedDate } = getSeverityDueDateData(date);
  return (
    <Box>
      {!!executedBy && (
        <CellRow title={executedBy} tooltipTitle={toolTipBy ?? null} Icon={PersonIcon} />
      )}
      {formattedDate !== NOTAPPLICABLE ? (
        <CellRow title={formattedDate} tooltipTitle={toolTipOn ?? null} Icon={CalendarMonthIcon} />
      ) : null}
    </Box>
  );
};
