import { EntityContextMenu } from 'src/sections/careflow/common';
import { Note } from 'src/services/api/ccm-notes';
import { CCM_ACLS, checkAclValidation } from 'src/utils/permissions/permission.utils';
import moment from 'moment';

export interface NotesContextMenuProps {
  item: Note;
  onEditPress?: () => void;
  onDeletePress?: () => void;
}

export default function NotesContextMenu({
  item,
  onEditPress,
  onDeletePress,
}: NotesContextMenuProps) {
  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-2-outline',
          disabled(entity) {
            return (
              !checkAclValidation({ acls: [CCM_ACLS.CCM_SYSTEM_ADMIN] }) &&
              entity &&
              typeof entity.source !== 'string' &&
              moment(entity.date).add(1, 'day') < moment()
            );
          },
          onPress: () => onEditPress?.(),
        },
        {
          label: 'Delete',
          icon: 'bi:trash',
          disabled(entity) {
            return entity && typeof entity.source !== 'string';
          },
          onPress: () => onDeletePress?.(),
        },
      ]}
    />
  );
}
