import PatientViewModel from 'src/sections/careflow/tasks-activities/components/Patients/ViewModel/PatientViewModel';
import client from '../_client';

export type GetPatientsQueryFilters = {
  careTeamIds?: string | null;
  search?: string | null;
  status: string | null;
  pagination: {
    take: number;
    page: 0;
  };
};

export async function getPatients() {
  const { data } = await client.get(`/ccm/patient/list`);

  return data;
}

export async function getPrograms(fhirPatientId: string) {
  const { data } = await client.get(`/ccm/patient/${fhirPatientId}/programs`);

  return data;
}

export async function getSummary(careTeamIds: string) {
  const { data } = await client.get(`/ccm/patient/summary/${careTeamIds ?? ''}`);

  return data;
}

export async function getCcmPatients({
  search,
  status,
  careTeamIds,
  pagination: { take, page },
}: GetPatientsQueryFilters) {
  let url = `/ccm/patient/ccm-list?take=${take}&skip=${page * take}`;

  if (search) {
    url = `${url}&search=${search}`;
  }

  if (status) {
    url = `${url}&status=${status}`;
  }

  if (careTeamIds) {
    url = `${url}&careTeamIds=${careTeamIds}`;
  }

  const { data } = await client.get<{ patients: PatientViewModel[]; total: number }>(url);

  return data;
}

export async function changeStatus(id: string, status: string) {
  let url = `/ccm/change-status/${id}`;

  const { data } = await client.post(url, {
    status,
  });

  return data;
}

export async function addedToProgram(patientId: string, program: string, membership: string) {
  const { data } = await client.post(`/ccm/patient/${patientId}/programs`, {
    program,
    membership,
  });

  return data;
}

export * as programs from './programs';
