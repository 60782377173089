import { ElementType, lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { DASHBOARD_ROUTE, PATH_AFTER_LOGIN, REDIRECT_AUTHORIZE_ROUTE } from '../config';
// components
import CareRecordIndex from 'src/sections/careflow/care-plan/CarePlan';
import HealthRecord from 'src/sections/careflow/health-record/HealthRecord';
import { PatientDemographics } from 'src/sections/careflow/patient-demographics';
import Debug from 'src/sections/debug';
import CarePlanAutomationIndex from 'src/sections/settings/care-plan-automation';
import LoadingScreen from '../components/LoadingScreen';
import { Activities } from '../sections/careflow/tasks-activities';

//crs components
import OrganizationCreate from 'src/pages/organization/OrganizationCreate';
import OrganizationList from 'src/pages/organization/OrganizationList';
import { Census } from 'src/sections/careflow/census';
import { GroupSessions } from 'src/sections/careflow/group-sessions';
import { MedPass } from 'src/sections/careflow/med-pass';
import NewPatients from 'src/sections/careflow/new-patients.tsx/components/NewPatients';
import NoPatientSelected from 'src/sections/careflow/patient';
import { Reports } from 'src/sections/careflow/reports';
import { Rounding } from 'src/sections/careflow/rounding';
import CaseList from 'src/sections/crs/case/CaseList';
import CaseDetails from 'src/sections/crs/case/components/CaseDetails';
import NewPatient from 'src/sections/crs/patient/NewPatient';
import ReferralDetails from 'src/sections/crs/referral/components/ReferralDetails';
import ReferralList from 'src/sections/crs/referral/ReferralList';
import ActivityListAutomationIndex from 'src/sections/settings/activity-list-automation';
import AssessmentsSettingsIndex from 'src/sections/settings/assessments';
import ClientsSettings from 'src/sections/settings/clients';
import { PatientDetails } from '../sections/crs/patient';
import { CRS_PATH, PATH_DASHBOARD } from './paths';
// import ProgramDisenrollmentReasons from 'src/sections/settings/program-disenrollment-reasons';
import SelectOrgNRole from 'src/pages/SelectOrgNRole';
import SelectTenant from 'src/pages/SelectTenant';
import ChildReferralDetails from 'src/sections/crs/referral/components/child-referral/ChildReferralDetails';
import PatientEngagementDetails from 'src/sections/engagement/patient/PatientEngagementDetails';
import TaskTab from 'src/sections/nat/task/TaskTab';
import CensusSettings from 'src/sections/settings/census/CensusSettings';
import ProgramSettingsIndex from 'src/sections/settings/program-settings';
import { ThemeSettingList } from 'src/sections/settings/theme-settings/ThemeSettingList';
import UsersOrganizationSettings from 'src/sections/settings/users-organization-settings/UsersOrganizationSettings';
import Example from 'src/sections/YoCare/Example';
import PersonDetails from 'src/sections/YoCare/Persons/PersonsDetails/PersonsDetails';
import PersonsList from 'src/sections/YoCare/Persons/PersonsList/PersonsList';
import UsersDetails from 'src/sections/YoCare/Users/UsersDetails';
import UsersList from 'src/sections/YoCare/Users/UsersList/UsersList';
import { getCareflowToken } from 'src/utils/auth-utils';
import TaskListContainer from '../sections/crs/task/TaskListContainer';
import PatientListContainer from '../sections/crs/patient/PatientListContainer';
import PatientEngagementListContainer from '../sections/engagement/patient/PatientEngagementListContainer';
import { AclBasedGuard } from '../guards/AclBasedGuard';
import crsAcls from '../utils/permissions/crs/crsAcls';
import IncidentDetails from 'src/modules/data-quality/incident/components/IncidentDetails';
import IncidentList from 'src/modules/data-quality/incident/IncidentList';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function getRouter(baseFrontUrl: string, backendBaseUrl: string) {
  return createBrowserRouter([
    {
      path: '/',
      loader: () => {
        const careflowToken = getCareflowToken(true);

        if (careflowToken) {
          window.location.replace(baseFrontUrl + DASHBOARD_ROUTE);
        } else {
          window.location.replace(backendBaseUrl + REDIRECT_AUTHORIZE_ROUTE);
        }
      },
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'logout',
          element: (
            <GuestGuard>
              <div>logout</div>
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app/*', element: <GeneralApp /> },
        // Patient Screens Fallback
        {
          path: 'patient-demographics',
          element: <NoPatientSelected title="Patient Demographics" />,
        },
        { path: 'health-record', element: <NoPatientSelected title="Health Record" /> },
        { path: 'care-record', element: <NoPatientSelected title="Care Record" /> },
        { path: 'reports', element: <NoPatientSelected title="Reports" /> },
        // Patient Screens
        {
          path: 'patient/:id',
          children: [
            { path: 'patient-demographics', element: <PatientDemographics /> },
            { path: 'health-record/*', element: <HealthRecord /> },
            { path: 'care-record/*', element: <CareRecordIndex /> },
            { path: 'reports', element: <Reports /> },
          ],
        },
        { path: 'activities', element: <Activities /> },
        {
          path: 'roundings/*',
          children: [
            { path: 'list', element: <Rounding /> },
            { path: 'census', element: <Census /> },
          ],
        },
        {
          path: 'group-sessions/*',
          element: <GroupSessions />,
        },
        {
          path: 'med-pass/*',
          element: <MedPass />,
        },
        {
          path: 'organization',
          children: [
            {
              path: 'list',
              element: (
                <AclBasedGuard
                  children={<OrganizationList />}
                  acls={[crsAcls.ADMIN.ORGANIZATIONS.MENU]}
                />
              ),
            },
            {
              path: 'new',
              element: (
                <AclBasedGuard
                  children={<OrganizationCreate />}
                  acls={[crsAcls.ADMIN.ORGANIZATIONS.EDIT]}
                />
              ),
            },
            {
              path: ':uuid/edit',
              element: (
                <AclBasedGuard
                  children={<OrganizationCreate />}
                  acls={[crsAcls.ADMIN.ORGANIZATIONS.EDIT]}
                />
              ),
            },
          ],
        },
        {
          path: 'settings',
          children: [{ path: 'list', element: <ThemeSettingList /> }],
        },
        {
          path: 'referral',
          element: <AclBasedGuard children={<ReferralList />} acls={[crsAcls.CRS.REFERRAL.MENU]} />,
        },
        {
          path: 'task',
          element: (
            <AclBasedGuard children={<TaskListContainer />} acls={[crsAcls.CRS.TASK.MENU]} />
          ),
        },
        { path: 'permissions', element: <Permissions /> },
        {
          path: 'case',
          element: <AclBasedGuard children={<CaseList />} acls={[crsAcls.CRS.CASE.MENU]} />,
        },
        {
          path: 'yocare/*',
          children: [
            {
              path: 'users',
              element: (
                <AclBasedGuard children={<UsersList />} acls={[crsAcls.YOCARE.USERS.MENU]} />
              ),
            },
            {
              path: 'persons',
              element: (
                <AclBasedGuard children={<PersonsList />} acls={[crsAcls.YOCARE.PERSONS.MENU]} />
              ),
            },
            { path: 'dataSystems', element: <Example /> },
            {
              path: 'userDetails/:id',
              element: (
                <AclBasedGuard children={<UsersDetails />} acls={[crsAcls.YOCARE.USERS.ALL]} />
              ),
            },
            {
              path: 'personDetails/:id',
              element: (
                <AclBasedGuard children={<PersonDetails />} acls={[crsAcls.YOCARE.PERSONS.ALL]} />
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.crs.patient.list.value,
          handle: 'Patients',
          children: [
            {
              index: true,
              element: (
                <AclBasedGuard
                  children={<PatientListContainer />}
                  acls={[crsAcls.CRS.PATIENT.VIEW]}
                />
              ),
            },
            {
              path: PATH_DASHBOARD.crs.patient.details.value,
              element: (
                <AclBasedGuard
                  children={<PatientDetails />}
                  acls={[crsAcls.CRS.PATIENT.TABS.GENERAL]}
                />
              ),
              handle: 'Patient Details',
              children: [
                {
                  path: 'general',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.GENERAL]}
                    />
                  ),
                },
                {
                  path: 'groups',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.GROUPS]}
                    />
                  ),
                },
                {
                  path: 'referrals-cases',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.REFERRAL_CASES]}
                    />
                  ),
                },
                {
                  path: 'health',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.HEALTH]}
                    />
                  ),
                },
                {
                  path: 'insurance',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.INSURANCE]}
                    />
                  ),
                },
                {
                  path: 'documents',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.DOCUMENTS]}
                    />
                  ),
                },
                {
                  path: 'tasks',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.TASKS]}
                    />
                  ),
                },
                {
                  path: 'engagement-hub',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.ENGAGEMENT_HUB]}
                    />
                  ),
                },
                {
                  path: 'consents',
                  element: (
                    <AclBasedGuard
                      children={<PatientDetails />}
                      acls={[crsAcls.CRS.PATIENT.TABS.CONSENTS]}
                    />
                  ),
                },
              ],
            },
            {
              path: PATH_DASHBOARD.crs.patient.new.value,
              element: <AclBasedGuard children={<NewPatient />} acls={[crsAcls.CRS.PATIENT.ADD]} />,
              handle: 'New Patient',
            },
          ],
        },
        {
          path: 'nat/',
          children: [{ path: 'task/:id', element: <TaskTab /> }],
        },
        {
          path: 'engagementhub/',
          children: [
            {
              path: 'patientengagement',
              element: (
                <AclBasedGuard
                  children={<PatientEngagementListContainer />}
                  acls={[crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.VIEW]}
                />
              ),
            },
            {
              path: 'patientengagement/:id',
              element: (
                <AclBasedGuard
                  children={<PatientEngagementDetails />}
                  acls={[crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.VIEW]}
                />
              ),
            }
          ],
        },
        {
          path: 'referral',
          children: [
            {
              path: ':id',
              element: (
                <AclBasedGuard children={<ReferralDetails />} acls={[crsAcls.CRS.REFERRAL.EDIT]} />
              ),
            },
            {
              path: ':name/edit',
              element: (
                <AclBasedGuard children={<ReferralDetails />} acls={[crsAcls.CRS.REFERRAL.EDIT]} />
              ),
            },
          ],
        },
        {
          path: 'child-referral',
          children: [
            {
              path: ':id',
              element: (
                <AclBasedGuard
                  children={<ChildReferralDetails />}
                  acls={[crsAcls.CRS.REFERRAL.CHILD_REFERRAL.EDIT]}
                />
              ),
            },
          ],
        },
        {
          path: 'case',
          children: [
            {
              path: ':id',
              element: <AclBasedGuard children={<CaseDetails />} acls={[crsAcls.CRS.CASE.VIEW]} />,
            },
            {
              path: ':name/edit',
              element: <AclBasedGuard children={<CaseDetails />} acls={[crsAcls.CRS.CASE.EDIT]} />,
            },
          ],
        },

        {
          path: 'new-patients',
          element: <AclBasedGuard children={<NewPatients />} acls={[crsAcls.CRS.PATIENT.ADD]} />,
        },

        {
          path: 'dataquality/',
          children: [
            {
              path: 'incident',
              element: ( 
                <AclBasedGuard 
                  children={<IncidentList />} 
                  acls={[crsAcls.DATA_QUALITY.INCIDENT.MENU]}
                />
              ),
            },
            {
              path: 'incident/:id',
              element: (
                <AclBasedGuard
                  children={<IncidentDetails />}
                  acls={[crsAcls.DATA_QUALITY.INCIDENT.EDIT]}
                />
              ),
            },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':uuid/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    {
      path: CRS_PATH.provider.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={CRS_PATH.provider.list} replace />, index: true },
        {
          path: CRS_PATH.provider.list,
          element: (
            <AclBasedGuard children={<PractitionerList />} acls={[crsAcls.CRS.PRACTITIONER.MENU]} />
          ),
        },
        {
          path: ':practitionerId',
          element: (
            <AclBasedGuard
              children={<PractitionerDetails />}
              acls={[crsAcls.CRS.PRACTITIONER.VIEW]}
            />
          ),
        },
      ],
    },
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'care-plan-automation/*',
          element: <CarePlanAutomationIndex />,
        },
        {
          path: 'activity-list-automation/*',
          element: <ActivityListAutomationIndex />,
        },
        {
          path: 'care-member',
          element: <ComingSoon />,
        },
        {
          path: 'assessments/*',
          element: <AssessmentsSettingsIndex />,
        },
        {
          path: 'clients/*',
          element: <ClientsSettings />,
        },
        {
          path: 'programs/*',
          element: <ProgramSettingsIndex />,
        },
        {
          path: 'care-member/assign',
          element: <ComingSoon />,
        },
        {
          path: 'script',
          element: <ComingSoon />,
        },
        {
          path: 'provider',
          element: <ComingSoon />,
        },
        {
          path: 'template',
          element: <ComingSoon />,
        },
        {
          path: 'consent-forms',
          element: <ComingSoon />,
        },
        {
          path: 'census',
          element: <CensusSettings />,
        },
        {
          path: 'users-organization',
          element: <UsersOrganizationSettings />,
        },
      ],
    },

    // Debug
    {
      path: '_debug/*',
      element: <Debug />,
    },
    // Misc
    {
      path: 'survey-saved',
      element: <SurveySaved />,
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '*', element: <Navigate to="/404" replace /> },
        {
          path: 'select-organization-n-role',
          element: (
            <AuthGuard>
              <SelectOrgNRole />
            </AuthGuard>
          ),
        },
        { path: 'select-tenant', element: <SelectTenant /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'single-logout', element: <SingleLogout /> },
        { path: 'sso-logout', element: <SSOLogout /> },
        { path: 'inactive-user', element: <InactiveUser /> },
        { path: 'session-expired', element: <SessionExpired /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

//ADMIN
const Permissions = Loadable(lazy(() => import('../sections/careflow/permissions/Permissions')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const SingleLogout = Loadable(lazy(() => import('../pages/SingleLogout')));
const SSOLogout = Loadable(lazy(() => import('../pages/SSOLogout')));
const SessionExpired = Loadable(lazy(() => import('../pages/SessionExpired')));
const InactiveUser = Loadable(lazy(() => import('../pages/InactiveUser')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const SurveySaved = Loadable(lazy(() => import('../sections/misc/SurveySaved')));
const PractitionerList = Loadable(
  lazy(() => import('../sections/crs/provider/PractitionerList/PractitionerList'))
);
const PractitionerDetails = Loadable(
  lazy(() => import('../sections/crs/provider/PractitionerDetails/PractitionerDetails'))
);
