import { ReferralResponse } from '../referral/services/ReferralService';

export enum ReferralIntent {
  PROPOSAL = 'proposal',
  PLAN = 'plan',
  DIRECTIVE = 'directive',
  ORDER = 'order',
  ORIGINAL_ORDER = 'original-order',
  REFLEX_ORDER = 'reflex-order',
  FILLER_ORDER = 'filler-order',
  INSTANCE_ORDER = 'instance-order',
  OPTION = 'option',
}
export enum ReferralPriority {
  ROUTINE = 'routine',
  URGENT = 'urgent',
}

export enum ReferralStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ON_HOLD = 'on-hold',
  REVOKED = 'revoked',
  COMPLETED = 'completed',
  ENTERED_IN_ERROR = 'entered-in-error',
  UNKNOWN = 'unknown',
}

export class ReferralDto {
  createdBy: string;

  createdOn: string;

  updatedBy: string;

  updatedOn: string;

  referralId: string;

  referralUUID: string;

  fhirId: string;

  workflowInstanceId: number;

  workflowStageId: string;

  workflowStageName: string;

  workflowStageOrder: number;

  workflowStatus: string;

  workflowSubstatus: string;

  workflowDispositionReason: string;

  workflowDispositionNote: string;

  workflowDispositionOn: string;

  workflowDispositionAt: string;

  workflowOwnedBy: string;

  workflowScope: string;

  indexedFhirRefUri: string;

  indexedMintReferralId: string;

  indexedsubjectMrn: string;

  indexedsubjectName: string;

  indexedsubjectPhone: string;

  indexedsubjectEmail: string;

  indexedsubjectDob: Date;

  indexedSubjectGender: string;

  indexedSubjectRace: string;

  indexedSubjectEthnicity: string;

  indexedRegistrationDate: Date;

  indexedStatus: string;

  indexedRequesterName: string;

  indexedRequesterFhirRefUri: string;

  indexedPerformerName: string;

  internalNumber: string;

  createdOnFormatted: string;

  subjectDOBFormatted: string;

  subjectAge: string;

  fromReferralResponse(dto: ReferralResponse) {
    this.createdBy = dto.createdBy;
    this.createdOn = dto.createdOn;
    this.updatedBy = dto.updatedBy;
    this.updatedOn = dto.updatedOn;
    this.referralId = dto.referralId;
    this.referralUUID = dto.referralUUID;
    this.fhirId = dto.fhirId;
    this.workflowInstanceId = dto.workflowInstanceId;
    this.workflowStageId = dto.workflowStageId;
    this.workflowStageName = dto.workflowStageName;
    this.workflowStageOrder = dto.workflowStageOrder;
    this.workflowStatus = dto.workflowStatus;
    this.workflowSubstatus = dto.workflowSubstatus;
    this.workflowDispositionReason = dto.workflowDispositionReason;
    this.workflowDispositionNote = dto.workflowDispositionNote;
    this.workflowDispositionOn = dto.workflowDispositionOn;
    this.workflowDispositionAt = dto.workflowDispositionAt;
    this.workflowOwnedBy = dto.workflowOwnedBy;
    this.indexedFhirRefUri = dto.indexedFhirRefUri;
    this.indexedMintReferralId = dto.indexedMintReferralId;
    this.indexedsubjectMrn = dto.indexedsubjectMrn;
    this.indexedsubjectName = dto.indexedsubjectName;
    this.indexedsubjectPhone = dto.indexedsubjectPhone;
    this.indexedsubjectEmail = dto.indexedsubjectEmail;
    this.workflowScope = dto.workflowScope;

    if (dto.indexedRequesterName) {
      this.indexedRequesterName = dto.indexedRequesterName;
    }

    if (dto.indexedPerformerName) {
      this.indexedPerformerName = dto.indexedPerformerName;
    }

    if (dto.indexedsubjectDob) {
      this.indexedsubjectDob = new Date(dto.indexedsubjectDob);
    }

    this.indexedSubjectGender = dto.indexedSubjectGender;
    this.indexedSubjectRace = dto.indexedSubjectRace;
    this.indexedSubjectEthnicity = dto.indexedSubjectEthnicity;

    if (dto.indexedRegistrationDate) {
      this.indexedRegistrationDate = new Date(dto.indexedRegistrationDate);
    }

    this.indexedStatus = dto.indexedStatus;
    this.internalNumber = dto.internalNumber;
    this.createdOnFormatted = dto.createdOnFormatted;
    this.subjectDOBFormatted = dto.subjectDOBFormatted;
    this.subjectAge = dto.subjectAge;
    this.indexedRequesterFhirRefUri = dto.indexedRequesterFhirRefUri;
  }
}
