import { Box, Container, Grid, Typography } from '@mui/material';
import { TLink } from 'src/components/Breadcrumbs';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

export interface PageContainerProps {
  pageTitle: string;
  title: string;
  breadCrumbsLinks?: TLink[];
  children?: React.ReactNode | null;
}

const PageContainer = ({ pageTitle, title, breadCrumbsLinks, children }: PageContainerProps) => {
  const { themeStretch } = useSettings();
  return (
    <Box sx={{ width: '100%' }}>
      <Page title={pageTitle}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs title={title} heading="" links={breadCrumbsLinks ?? []} />
          {children}
        </Container>
      </Page>
    </Box>
  );
};

export default PageContainer;
