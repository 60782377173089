import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  Grid,
  Link,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { CRS_PATH, PATH_DASHBOARD } from 'src/routes/paths';
import VerifiedDecorator from './VerifiedDecorator';
import { spreadSxProp } from 'src/utils/cssStyles';
import CloseIconButton from 'src/components/CloseIconButton';
import { Business, OpenInNew, Person, Work } from '@mui/icons-material';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import { Practitioner } from 'src/@nicheaim/fhir-base/mappings/Practitioner';
import { AssignableGeneralPractitioner } from 'src/@types/crs/providerDirectory';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';

export interface GeneralPractitionerPreviewDrawerProps extends DrawerProps {
  generalPractitioner: AssignableGeneralPractitioner | null;
}

const GeneralPractitionerPreviewDrawer = ({
  onClose,
  generalPractitioner,
  ...drawerProps
}: GeneralPractitionerPreviewDrawerProps) => {
  const practitioner =
    generalPractitioner?.practitioner?.resource &&
    PractitionerWrapper(generalPractitioner.practitioner.resource as Practitioner);
  const organization =
    generalPractitioner?.organization?.resource &&
    OrganizationWrapper(generalPractitioner.organization.resource as Organization);
  const isPractitionerRole = generalPractitioner?.resourceType === 'PractitionerRole';
  const resourceTypeDisplay = isPractitionerRole
    ? 'Practitioner Role'
    : generalPractitioner?.resourceType;
  const resourceLink = !isPractitionerRole
    ? generalPractitioner?.resourceType === 'Practitioner'
      ? getPractitionersDetailLink(generalPractitioner.resourceId)
      : getOrganizationDetailLink(generalPractitioner?.resourceId)
    : null;
  return (
    <Drawer {...drawerProps} onClose={onClose} anchor="right" sx={{ zIndex: 10000 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          width: 'auto',
          flex: 1,
        }}
      >
        <Box
          sx={{
            boxShadow: '8px -30px 24px rgba(22,28,36,0.16)',
            display: 'flex',
            flexDirection: 'column',
            width: '30vw',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: '#E5E8EB',
              paddingY: 1,
            }}
          >
            <Grid width={'100%'} height={'100%'} container sx={{ paddingX: 3 }}>
              <Stack
                style={{
                  width: '100%',
                }}
                alignItems={'center'}
                justifyContent={'space-between'}
                direction="row"
              >
                <Typography variant="h6" color="text.primary">
                  {`${resourceTypeDisplay}'s Detail`}
                </Typography>
                <CloseIconButton
                  onClick={() => {
                    onClose?.({}, 'backdropClick');
                  }}
                />
              </Stack>
            </Grid>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              paddingX: 3,
              marginTop: 2.4,
            }}
          >
            <InfoSection
              shouldShow={
                generalPractitioner?.resourceType === 'PractitionerRole' &&
                !!generalPractitioner.role
              }
              title="Role"
              titleIcon={<Work />}
            >
              <InfoItem label="Role" value={generalPractitioner?.role} />
            </InfoSection>
            <InfoSection
              // link={
              //   !resourceLink
              //     ? getPractitionersDetailLink(practitioner?.id)
              //     : undefined
              // }
              shouldShow={!!generalPractitioner?.practitioner}
              title="Practitioner"
              titleIcon={<Person />}
            >
              <InfoItem
                label="NPI"
                value={
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ValueText>{practitioner?.getNPI() ?? ''}</ValueText>
                    <Box sx={{ marginLeft: 0.4 }}>
                      <VerifiedDecorator
                        isVerified={!!generalPractitioner?.practitioner?.isVerified}
                      />
                    </Box>
                  </Box>
                }
              />
              <InfoItem label="Name" value={practitioner?.getFullName()} />
              <InfoItem label="Date of Birth" value={practitioner?.getBirthDate()} />
              <InfoItem
                label="Gender"
                value={practitioner?.gender}
                valueTextSx={{ textTransform: 'capitalize' }}
              />
              {practitioner?.getEmails()?.map((email, index) => (
                <InfoItem
                  key={`email-${index}`}
                  label={`Email ${index === 0 ? '' : index + 1}`.trim()}
                  value={email?.value}
                />
              ))}
              {practitioner?.getPhones()?.map((phone, index) => (
                <InfoItem
                  key={`phone-${index}`}
                  label={`Phone ${index === 0 ? '' : index + 1}`.trim()}
                  value={phone?.value}
                />
              ))}
              {practitioner?.getFormattedAddresses()?.map((address, index) => (
                <InfoItem
                  key={`address-${index}`}
                  label={`Address ${index === 0 ? '' : index + 1}`.trim()}
                  value={address}
                  valueTextSx={{
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word',
                  }}
                />
              ))}
            </InfoSection>
            <InfoSection
              link={!resourceLink ? getOrganizationDetailLink(organization?.id) : undefined}
              shouldShow={!!generalPractitioner?.organization}
              title="Organization"
              titleIcon={<Business />}
            >
              <InfoItem
                label="NPI"
                value={
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ValueText>{organization?.getNPI() ?? ''}</ValueText>
                    <Box sx={{ marginLeft: 0.4 }}>
                      <VerifiedDecorator
                        isVerified={!!generalPractitioner?.organization?.isVerified}
                      />
                    </Box>
                  </Box>
                }
              />
              <InfoItem label="Name" value={organization?.name} />
              {organization?.getEmails().map((email, index) => (
                <InfoItem
                  key={`organization-email-${index}`}
                  label={`Email ${index === 0 ? '' : index + 1}`.trim()}
                  value={email}
                />
              ))}
              {organization?.getPhones().map((phone, index) => (
                <InfoItem
                  key={`organization-phone-${index}`}
                  label={`Phone ${index === 0 ? '' : index + 1}`.trim()}
                  value={phone}
                />
              ))}
              {organization?.getFormattedAddresses().map((address, index) => (
                <InfoItem
                  key={`organization-address-${index}`}
                  label={`Address ${index === 0 ? '' : index + 1}`.trim()}
                  value={address}
                  valueTextSx={{
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word',
                  }}
                />
              ))}
              <InfoItem label={`Type`} value={organization?.getType()} />
            </InfoSection>
            {!!resourceLink && (
              <Grid container justifyContent={'center'} sx={{ marginTop: 4 }}>
                <Grid item xs={10}>
                  <Button
                    fullWidth
                    onClick={() => {
                      window.open(resourceLink, '_blank');
                    }}
                    variant="contained"
                    startIcon={<OpenInNew />}
                  >
                    Go to Details
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export interface InfoSectionProps {
  link?: string;
  shouldShow: boolean;
  title: string;
  titleIcon?: React.ReactNode;
  children?: React.ReactNode;
}

export const InfoSection = ({ title, titleIcon, shouldShow, children, link }: InfoSectionProps) => (
  <>
    {shouldShow && (
      <Grid container sx={{ marginBottom: 3 }}>
        <Grid
          container
          justifyContent={'space-between'}
          sx={{ marginBottom: 1.2 }}
          alignItems={'center'}
        >
          <Grid item>
            <Box display={'flex'} flexDirection={'row'}>
              <Box sx={{ marginRight: 1 }}>{!!titleIcon && titleIcon}</Box>
              <Typography fontSize={'1.1rem'} fontWeight={'bold'}>
                {title}
              </Typography>
            </Box>
          </Grid>
          {!!link && (
            <Grid item>
              <Tooltip title={`Go to ${title} Details`}>
                <Link
                  sx={{ top: -0.5 }}
                  component={Button}
                  href={link}
                  target="_blank"
                  underline="always"
                  startIcon={<OpenInNew />}
                >
                  Details
                </Link>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        {children}
      </Grid>
    )}
  </>
);

export interface InfoItemProps {
  label: string;
  value: string | React.ReactNode | undefined | null;
  valueTextSx?: SxProps;
}

export const InfoItem = ({ label, value, valueTextSx }: InfoItemProps) => (
  <Grid container spacing={0} sx={{ marginBottom: 2 }}>
    <Grid item xs={4}>
      <Typography color="#000000" variant="subtitle1">
        {label}:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      {value && typeof value !== 'string' ? (
        <>{value}</>
      ) : (
        <ValueText sx={valueTextSx}>{value ?? ''}</ValueText>
      )}
    </Grid>
  </Grid>
);

export const ValueText = ({ sx, ...props }: TypographyProps) => (
  <Typography color="#637381" {...props} sx={[{}, ...spreadSxProp(sx)]} />
);

export const getPractitionersDetailLink = (id: string | undefined) =>
  id ? `${CRS_PATH.provider.root}/${id}?type=edit` : '';

export const getOrganizationDetailLink = (id: string | undefined) =>
  id ? `${PATH_DASHBOARD.crs.organizations}/${id}?type=edit` : '';

export default GeneralPractitionerPreviewDrawer;
