import { Alert } from "@mui/material";

export interface PracticeStatusAlertProps {
  status: string | null | undefined;
}

const PracticeStatusAlert = ({ status }: PracticeStatusAlertProps) => (
  <Alert
    icon={false}
    severity={status === "active" ? "info" : "warning"}
    sx={[
      {
        height: "30px",
        fontSize: "0.9em",
        textTransform: "capitalize",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 1,
      },
    ]}
  >
    {status}
  </Alert>
);

export default PracticeStatusAlert;