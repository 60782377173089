import { Box, SxProps, Typography } from '@mui/material';
import UserProfileImage from './UserProfileImage';
import { Person } from '../types';
import { getFormattedPersonDOB, getPersonFullName } from '../utils/personHelpers';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface PersonBannerProps {
  person: Person | null | undefined;
  onImageUploadSucess?: VoidFunction;
  containerSx: SxProps;
}

const PersonBanner = ({ person, onImageUploadSucess, containerSx }: PersonBannerProps) => (
  <Box
    sx={[
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      ...spreadSxProp(containerSx),
    ]}
  >
    <UserProfileImage
      personId={person?.id ?? null}
      personProfileImage={person?.profilePicture ?? null}
      onImageUploadSuccess={() => {
        onImageUploadSucess?.();
      }}
    />
    <Box sx={{ top: -25, position: 'relative' }}>
      <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
        {getPersonFullName(person?.name)}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
        <PersonBasicInfoBannerItem label="Gender" value={person?.gender ?? ''} />
        <PersonBasicInfoBannerItem
          label="Birth Date"
          value={getFormattedPersonDOB(person?.birthDate) ?? ''}
        />
      </Box>
    </Box>
  </Box>
);

export interface PersonBasicInfoBannerItemProps {
  label: string;
  value: string;
}

export const PersonBasicInfoBannerItem = ({ label, value }: PersonBasicInfoBannerItemProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
    <Typography
      variant="body2"
      sx={{ fontWeight: 'bold', color: 'text.secondary', marginRight: 0.7 }}
    >
      {label}:
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

export default PersonBanner;
