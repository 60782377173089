import { SxProps, Alert, Typography, Tooltip, Box } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export enum AlertSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
  PURPLE = 'purple',
}

export type SeverityStatusData = {
  message: string | undefined;
  severity: AlertSeverity | undefined;
};

interface SeverityStatusProps {
  status: SeverityStatusData;
  sx?: SxProps;
}

const SeverityStatus = ({
  status: { severity, message },
  sx,
}: SeverityStatusProps): JSX.Element => {
  const basicAlerts = [
    AlertSeverity.ERROR,
    AlertSeverity.SUCCESS,
    AlertSeverity.INFO,
    AlertSeverity.WARNING,
  ];

  if (!severity) return <Typography>{message ?? ''}</Typography>;

  const isCustomAlert = !basicAlerts.includes(severity);
  let customAlertStyles: {
    [k in AlertSeverity]?: SxProps;
  } = {
    [AlertSeverity.DEFAULT]: { backgroundColor: '#f4f6f8', color: '#637381' },
    [AlertSeverity.PURPLE]: { backgroundColor: 'rgba(142, 51, 255, 0.16)', color: '#5119b7' },
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Tooltip title={message}>
        <span>
          <Alert
            title={message}
            icon={false}
            severity={
              (!isCustomAlert ? severity : AlertSeverity.INFO) as
                | AlertSeverity.ERROR
                | AlertSeverity.SUCCESS
                | AlertSeverity.INFO
                | AlertSeverity.WARNING
            }
            sx={[
              {
                maxHeight: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',
                width: '100%',
                padding: 1,
                ...(customAlertStyles?.[severity] ?? {}),
              },
              ...spreadSxProp(sx),
            ]}
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontSize: '0.9em',
                width: '100%',
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {message}
            </Typography>
          </Alert>
        </span>
      </Tooltip>
    </Box>
  );
};

export default SeverityStatus;
