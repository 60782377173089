import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  ContactAttemptDto,
  ContactAttemptOutcome,
  TypeContactAttempt,
} from 'src/crs/dto/contact-attempt.dto';
import { MobileDatePicker } from '@mui/lab';
import useLocales from 'src/hooks/useLocales';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';

type Props = {
  option: string;
  referral: any;
  open: boolean;
  countRecord: any;
  onCancel: VoidFunction;
  handleCreateContactForm: (data: any) => Promise<any>;
};

export default function ContactForm({
  option,
  referral,
  open,
  countRecord,
  handleCreateContactForm,
  onCancel,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();

  const [outcomeList] = useValueSet('ph-outcome-contact-form', { map: ValueSetWrapper });

  type FormValues = {
    contactOn: Date;
    nextContactOn: Date;
    outcome: string;
    reasonNote: string;
  };

  const EventSchema = Yup.object().shape({
    contactOn: Yup.date().required('Contact On is required'),
    nextContactOn: Yup.date().required('Next Contact On is required'),
    outcome: Yup.string().required('Outcome is required'),
    reasonNote: Yup.string(),
  });

  const defaultValues = {
    contactOn: new Date(),
    nextContactOn: new Date(),
    outcome: '',
    reasonNote: '',
  };

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const { control, handleSubmit, reset } = methods;

  const mapFormDataToCommunication = (data: FormValues): ContactAttemptDto => {
    const { contactOn, nextContactOn, outcome, reasonNote } = data;

    const topicCoding = convertValueToValueSet(outcome, outcomeList);

    return {
      typeNote: TypeContactAttempt.CASE_CONTACT_ITEM,
      reasonNote: reasonNote,
      outcome: topicCoding?.display as ContactAttemptOutcome,
      id: Number(referral.caseId),
      contactOn: new Date(contactOn).toISOString(),
      nextContactOn: new Date(nextContactOn).toISOString()
    };
  };

  const handleClose = () => {
    reset(defaultValues);
    onCancel();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const resultMap = mapFormDataToCommunication(data);
      const result = await handleCreateContactForm(resultMap);
      handleClose();
      if (!isEmpty(result)) enqueueSnackbar('Register was created.');
    } catch (e) {
      handleClose();
      enqueueSnackbar('Register was not created.', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        {option}&nbsp;
        {countRecord !== undefined ? `[${countRecord}/5]` : ''}
      </DialogTitle>

      <Card sx={{ m: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Controller
                  name="contactOn"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label={`${i18n('case.details.contactAttempts.contactOn', 'crs')}*`}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="nextContactOn"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label={i18n('case.details.contactAttempts.nextContactOn', 'crs')}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <RHFSelect name="outcome" label={`${i18n('case.details.contactAttempts.outcome', 'crs')}*`}>
                  <MenuItem />
                  {outcomeList?.asList().map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <RHFTextField name="reasonNote" label={i18n('case.details.contactAttempts.reasonNote', 'crs')} multiline rows={4} />
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </FormProvider>
      </Card>
    </Dialog>
  );
}
