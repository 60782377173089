import { FormControl, FormHelperText, SxProps, TextField, Typography } from '@mui/material';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface DatePickerMomentProps
  extends Omit<DatePickerProps<moment.Moment, moment.Moment>, 'renderInput'> {
  error?: null | undefined | string;
  containerSx?: SxProps;
}

const DatePickerMoment = ({ error, containerSx, ...props }: DatePickerMomentProps) => (
  <FormControl fullWidth sx={[...spreadSxProp(containerSx)]} error={!!error}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableMaskedInput
        inputFormat="MM/DD/YYYY"
        renderInput={(params) => <TextField {...params} fullWidth error={!!error} />}
        {...props}
      />
    </LocalizationProvider>
    {!!error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export default DatePickerMoment;
