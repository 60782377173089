import { Link } from '@mui/material';

const LinkModalComponent = (props: any) => {
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    metasResponse
  } = props?.others;

  const { message } = metasResponse;

  return (
    <Link
      component="button"
      onClick={() =>
        props?.others?.handler({
          record,
          checklistItem,
          refreshChecklistHandler,
          refreshRecordHandler,
        })}
      sx={{ color: 'black', fontSize: 12 }}
    >
      {message}
    </Link>
  );
};

export default LinkModalComponent;