import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { capitalCase } from 'capital-case';
import { useEffect, useMemo } from 'react';
import { MobileDateTimePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getReferenceResourceId } from 'src/sections/crs/helpers/common';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import { CaseIntent, CaseStatus } from '../../../../../crs/dto/case/case.dto';
import useLocales from 'src/hooks/useLocales';

type FormValue = {
  patientId: string;
  intent: string;
  status: string;
};

type Props = {
  patient: WrappedPatient | null;
  carePlan: WrappedCarePlan | null;
  open: boolean;
  onClose: VoidFunction;
  handleCase: (data: any) => Promise<any>;
};

export function CaseForm({ patient, carePlan, open, onClose, handleCase }: Props) {
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);
  const { i18n } = useLocales();

  const EventSchema = Yup.object().shape({
    patientId: Yup.string().required('PatientId is required'),
    intent: Yup.string().required('Intent is required'),
    status: Yup.string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () =>
    ({
      patientId: patient?.id || '',
      intent: carePlan?.intent || CaseIntent.PROPOSAL,
      status: carePlan?.status || CaseStatus.ACTIVE,
    } as FormValue),
    [carePlan]
  );

  const methods = useForm<FormValue>({ resolver: yupResolver(EventSchema) });

  const { reset, control, handleSubmit } = methods;

  const mapCarePlan = (dataForm: FormValue) => ({
    resourceType: 'CarePlan',
    ...dataForm,
    created: new Date().toISOString(),
    scope: [
      {
        coding: [
          {
            system: process.env.REACT_APP_CASE_CATEGORY_SYSTEM,
            code: process.env.REACT_APP_MCC_CASE_SCOPE_CODE,
            display: process.env.REACT_APP_MCC_CASE_SCOPE_DISPLAY,
          },
        ],
        text: process.env.REACT_APP_MCC_CASE_SCOPE_DISPLAY,
      },
    ],
    ...(carePlan !== null && { id: carePlan?.id }),
  });

  const onSubmit = async (dataForm: FormValue) => {
    const resultMap = mapCarePlan(dataForm);
    handleCase(resultMap);
    handleClose();
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>{carePlan ? 'Edit Case' : `${i18n('patients.details.cases.titleAddCases', 'crs')}`} </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect name="status" label={i18n('patients.details.cases.statusAddCases', 'crs')}>
                  <MenuItem disabled />
                  {Object.entries(CaseStatus).map((option) => (
                    <MenuItem key={option[0]} value={option[1]}>
                      {capitalCase(option[1])}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect name="intent" label={i18n('patients.details.cases.intent', 'crs')}>
                  <MenuItem disabled />
                  {Object.entries(CaseIntent).map((option) => (
                    <MenuItem key={option[0]} value={option[1]}>
                      {capitalCase(option[1])}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>

              <Button variant="contained" color="info" type="submit">
                {i18n('submit')}
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
