import { Box, IconButton, Modal } from '@mui/material';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo, useCallback, useEffect, useContext } from 'react';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import useObjectState from 'src/hooks/useObjectState';
import { checkAclValidation, CCM_ACLS } from 'src/utils/permissions/permission.utils';
import { SummaryTable, ActionBar, EntityContextMenu } from '../../common';
import AddModal from '../../common/AddModal';
import ActivityContextMenu from '../../tasks-activities/components/Activities/ActivityContextMenu';
import ScheduleActivity from '../../tasks-activities/components/Activities/ScheduleActivity';
import { Activity, ActivityStatus } from '../../tasks-activities/model';
import { ConcreteActivity } from '../../tasks-activities/model/Activity';
import ActivityDetailsModal from '../care-plan/components/ActivityDetailsModal';
import { EXPANDED_ACTIVITY_TABLE_COLUMNS, SUMMARY_ACTIVITY_TABLE_COLUMNS } from '../constants';
import {
  ActivitiesContextType,
  ActivitiesContext,
} from '../../tasks-activities/components/Activities/ActivitiesContext';
import API from 'src/services/api';
import { useNavigate } from 'react-router';

type ActivitiesSummaryProps = {
  patient: any;
  isExpanded: boolean;
  onExpand: () => void;
};

const ActivitiesSummary = ({ patient, isExpanded, onExpand }: ActivitiesSummaryProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const activitiesContext: ActivitiesContextType = useContext(ActivitiesContext);
  const [
    { showScheduleActivityModal, showActivityDetails, selectedActivity, shouldCompleteActivity },
    updateState,
  ] = useObjectState({
    selectedActivity: null as ConcreteActivity | null,
    activities: [],
    showScheduleActivityModal: false,
    showActivityDetails: false,
    shouldCompleteActivity: false,
  });
  const [contactTypesCodeSystem] = useCodeSystem('ccm-activity-contact-types');

  const {
    data: activitiesQuery,
    fetchNextPage: loadMoreActivities,
    refetch: refreshActivities,
  } = useInfiniteQuery(
    ['activities', patient?.id],
    async ({ pageParam = 0 }) => {
      const pageSize = 10;
      const { activities } = await API.activities.getActivities({
        patientFhirId: patient?.id,
        currentRange: null,
        status: null,
        pagination: {
          take: pageSize,
          page: pageParam,
        },
        userEmail: null,
        dueStatus: null,
      });

      return Object.assign(
        (activities || []).map((activity: Activity) => new ConcreteActivity(activity)),
        {
          page: pageParam,
          pageSize,
        }
      );
    },
    {
      enabled: !!patient?.id,
      getNextPageParam: (lastPage) =>
        lastPage.length === lastPage.pageSize ? lastPage.page + 1 : undefined,
    }
  );

  const {
    data: activitiesSummary,
    refetch: refreshActivitiesSummary,
    isFetching,
  } = useQuery(
    ['activities-summary', patient?.id],
    async () => {
      const summary = await API.activities.activitiesSummary(patient!.id);

      return summary;
    },
    {
      enabled: !!patient?.id,
    }
  );

  const activities = useMemo<ConcreteActivity[]>(
    () => activitiesQuery?.pages.reduce((acc, page) => [...acc, ...page], []) ?? [],
    [activitiesQuery]
  );

  const onCreateOrUpdateActivity = useCallback(() => {
    refreshActivities();
    refreshActivitiesSummary();
    queryClient.refetchQueries({
      predicate(query) {
        return ['ccm-notes', 'Communication', 'Task', 'activities', 'activities-summary'].includes(
          query.queryKey[0] as string
        );
      },
    });
    updateState(({ activities: stateActivities }: any) => ({
      showScheduleActivityModal: false,
      selectedActivity: null,
    }));
  }, [refreshActivities, refreshActivitiesSummary, queryClient, updateState]);

  useEffect(() => {
    onCreateOrUpdateActivity();
  }, []);

  const renderScheduleActivityModal = () => (
    <AddModal
      open={showScheduleActivityModal}
      onClose={() =>
        updateState({
          showScheduleActivityModal: false,
        })
      }
      Component={
        <ScheduleActivity
          activity={selectedActivity}
          onSaveCallback={onCreateOrUpdateActivity}
          patient={{
            fhirId: patient?.id,
          }}
          shouldCompletedActivity={shouldCompleteActivity}
        />
      }
      containerStyle={{
        width: 450,
      }}
    />
  );

  const closeActivityDetails = () => {
    updateState({
      selectedActivity: null,
      showActivityDetails: false,
    });
  };

  const renderActivityDetails = () => (
    <Modal
      open={showActivityDetails}
      onClose={closeActivityDetails}
      onBackdropClick={closeActivityDetails}
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <ActivityDetailsModal
          activity={selectedActivity!}
          actionBar={<ActionBar onCollapse={closeActivityDetails} />}
        />
      </Box>
    </Modal>
  );

  console.log({
    activities,
  });

  return (
    <>
      {renderScheduleActivityModal()}
      {renderActivityDetails()}
      <SummaryTable
        title="Activities"
        columns={
          isExpanded
            ? EXPANDED_ACTIVITY_TABLE_COLUMNS(contactTypesCodeSystem?.concept ?? [])
            : SUMMARY_ACTIVITY_TABLE_COLUMNS(contactTypesCodeSystem?.concept ?? [])
        }
        data={activities}
        summary={activitiesSummary}
        isFetching={isFetching}
        fetchMore={async () => {
          const activities = await loadMoreActivities();
          await refreshActivitiesSummary();

          return activities.data?.pages.at(-1) ?? [];
        }}
        rowContextMenu={(activity) => (
          <ActivityContextMenu
            activity={activity}
            items={[
              {
                id: 'accept',
                label: 'Accept',
                icon: 'bi:calendar-check',
                rules: (activity: ConcreteActivity) => activity.status === ActivityStatus.REQUESTED,
                onPress: (selectedActivity: any) =>
                  updateState({
                    showScheduleActivityModal: true,
                    selectedActivity,
                  }),
              },
              {
                id: 'start',
                label: 'Start',
                icon: 'bi:play-fill',
                rules: (activity: ConcreteActivity) =>
                  activity.status === ActivityStatus.READY && activity.scriptId ? true : false,
                onPress: async (selectedActivity: ConcreteActivity) => {
                  const {
                    patientFhirId: patientId,
                    scriptId,
                    taskId,
                    id: activityId,
                  } = selectedActivity;

                  if (!patientId) return;

                  activitiesContext.startActivity(selectedActivity);

                  if (!scriptId) return;

                  navigate(
                    `/dashboard/patient/${patientId}/care-record/assessments/new/${scriptId}`,
                    {
                      state: {
                        activityId,
                        taskId,
                      },
                    }
                  );
                },
              },
              {
                id: 'edit',
                label: 'Edit',
                icon: 'eva:edit-2-outline',
                onPress: (selectedActivity: ConcreteActivity) =>
                  updateState({
                    selectedActivity,
                    showScheduleActivityModal: true,
                    shouldCompleteActivity: false,
                  }),
                disabled: (activity) => {
                  const roleCondition = !checkAclValidation({
                    acls: [CCM_ACLS.CCM_SYSTEM_ADMIN],
                  });
                  if (activity.status === ActivityStatus.COMPLETED) {
                    return (
                      roleCondition && moment(activity?.completedDate).add(1, 'day') < moment()
                    );
                  }
                  return roleCondition && moment(activity?.createdAt).add(1, 'day') < moment();
                },
              },
              {
                id: 'details',
                label: 'View Details',
                icon: 'eva:eye-outline',
                onPress: (selectedActivity: any) => {
                  updateState({
                    selectedActivity: selectedActivity,
                    showActivityDetails: true,
                  });
                },
              },
            ]}
            onUpdateActivity={onCreateOrUpdateActivity}
          />
        )}
        actionBar={
          <>
            <EntityContextMenu
              icon="eva:plus-fill"
              items={[
                {
                  label: 'Schedule Activity',
                  icon: 'bi:calendar-check',
                  onPress: () =>
                    updateState({
                      showScheduleActivityModal: true,
                      showActivityDetails: false,
                      selectedActivity: null,
                      shouldCompleteActivity: false,
                    }),
                  rules: () => true,
                },
                {
                  label: 'Create Activity',
                  icon: 'carbon:task-complete',
                  onPress: () =>
                    updateState({
                      showScheduleActivityModal: true,
                      showActivityDetails: false,
                      selectedActivity: null,
                      shouldCompleteActivity: true,
                    }),
                  rules: () => true,
                },
              ]}
              entity={null}
            />
            <IconButton onClick={onExpand}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ActivitiesSummary;
