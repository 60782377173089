import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Observation } from 'src/@nicheaim/fhir-base/mappings/Observation';
import {
  ObservationWrapper,
  WrappedObservation,
} from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useObservations, useValueSet } from 'src/@nicheaim/fhir-react';
import { TableHeadCustom } from 'src/components/table';
import useLocales from 'src/hooks/useLocales';
import useTable from 'src/hooks/useTable';
import { TABLE_HEAD_OBSERVATION } from 'src/sections/crs/common/table-head';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { v4 as uuidv4 } from 'uuid';
import Row from './Row';
import { ObservationForm } from './ObservationForm';

type Props = {
  patient: WrappedPatient | null;
};

export default function ObservationDetails({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [editRow, setEditRow] = useState<any>({});
  
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [valueSetCategory] = useValueSet('ph-category-observation', { map: ValueSetWrapper });
  const { i18n } = useLocales();

  const observationsFilter = useMemo(() => {
    const cats = valueSetCategory?.asList()?.map((item) => item?.code) ?? [];
    const types = [];
    const filters = [...cats, ...types];

    const combinedFilter = filters && filters.length > 0 ? filters?.join(',') : '';

    const filter = {
      ...(patient?.id && { patient: patient?.id }),
      ...(combinedFilter && { category: combinedFilter }),
    };

    return filter;
  }, [patient?.id, valueSetCategory]);

  const canFetch = useMemo(() => {
    if (
      patient?.id &&
      observationsFilter &&
      observationsFilter?.patient &&
      observationsFilter?.category
    ) {
      return true;
    }
    return false;
  }, [patient?.id, observationsFilter]);

  const [observations, { create: createObservation, update: updateObservation }] = useObservations({
    filter: observationsFilter,
    map: ObservationWrapper,
    autofetch: !!patient?.id && canFetch,
  });

  const saveObservation = async (observation: Observation) => {
    try {
      if (isEmpty(editRow)) {
        if (observation) {
          const result = await createObservation(observation);
          enqueueSnackbar('Observation was created.');
          handleClose();
        }
      } else {
        const observationEdit = editRow as Observation;
        observationEdit.status = 'amended';
        const createDerivedObservation = observation as Observation;
        if (createDerivedObservation) {
          const resultCreate = await createObservation(createDerivedObservation);
          const resultUpdate = await updateObservation(observationEdit);
          enqueueSnackbar('Observation was updated.');
          handleClose();
        }
      }
    } catch (error) {
      enqueueSnackbar('Observation was not created.', { variant: 'error' });
      handleClose();
    }
  };

  const handleClose = () => {
    setEditRow({});
    setOpen(false);
  };

  const handleEdit = (data: any) => {
    setEditRow(data);
    setOpen(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.OBSERVATION.ADD] }) && (
                  <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                      onClick={() => setOpen(true)}
                    >
                      {i18n('patients.details.observations.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_OBSERVATION, 'crs')} />
              <TableBody>
                {!isEmpty(observations) ? (
                  observations
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: WrappedObservation) => (
                      <Row key={uuidv4()} row={row} handleEdit={handleEdit} />
                    ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_OBSERVATION?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={observations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <ObservationForm
        patient={patient}
        open={open}
        observation={editRow}
        onClose={handleClose}
        handleObservation={saveObservation}
      />
    </>
  );
}
