import { useCallback } from "react";
import uuidv4 from "src/utils/uuidv4";
import { Grid, Typography } from "@mui/material";
import { QuestionnaireResponseAnswer } from "src/@types/nat/report";
import { pcpHealthStoryQuestionIds } from "src/@types/crs/providerDirectory";
import { getQuestionnaireResponseAnswer } from "src/sections/crs/provider/helpers";
import useLocales from "src/hooks/useLocales";
import { pcpAssignmentIncidentScopeCodes } from "src/services/api/data-quality/IncidentService";

export interface PCPHealthStoryInformationProps {
  healthStoryAnswers: QuestionnaireResponseAnswer[];
  scopeCode: string;
}

const HealthStoryInformation = ({
  healthStoryAnswers,
  scopeCode,
}: PCPHealthStoryInformationProps) => {

  const { i18n } = useLocales();

  const renderDataColumn = useCallback(
    (questionId: string) => {
      const answer = getQuestionnaireResponseAnswer(
        questionId,
        healthStoryAnswers
      );
      if (!answer) return null;
      return (
        <ProviderInfoLine label={answer?.label ?? ""} value={answer?.value} />
      );
    },

    [healthStoryAnswers]
  );

  const hasPCP = scopeCode !== pcpAssignmentIncidentScopeCodes.HAVE_NO_PCP;

  return (
    <Grid container>
      <Grid item xs={12}>
        {hasPCP ? (
          <Grid container>
            {[
              pcpHealthStoryQuestionIds.LAST_NAME,
              pcpHealthStoryQuestionIds.CITY,
              pcpHealthStoryQuestionIds.FIRST_NAME,
              pcpHealthStoryQuestionIds.ADDRESS,
              pcpHealthStoryQuestionIds.PRACTICE.NAME,
              pcpHealthStoryQuestionIds.PHONE,
            ].map((questionId) => (
              <Grid item xs={6} key={uuidv4()}>
                {renderDataColumn(questionId)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {i18n('patients.title', 'crs')} doesn't have PCP
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export interface ProviderInfoLineProps {
  label: string;
  value: string | null | undefined;
}

export const ProviderInfoLine = ({ label, value }: ProviderInfoLineProps) => (
  <Grid container>
    <Grid xs={2} item>
      <Typography sx={{ fontWeight: "bold" }}>{label}:</Typography>
    </Grid>
    <Grid xs={10} item>
      <Typography>{value ?? ""}</Typography>
    </Grid>
  </Grid>
);
export default HealthStoryInformation;
