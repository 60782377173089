import Item from './Item';
import { useEffect, useMemo } from 'react';
import { useDialogStore } from 'src/stores/dialog';
import { Button, Typography } from '@mui/material';
import useSmartLaunch from 'src/hooks/useSmartLaunch';
import { PROVIDER_WINDOW_LAUNCH_URL } from 'src/config';

import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import useLocales from 'src/hooks/useLocales';
import { requireMintProvisioning } from '../../../modules/mint/smart-clients';

interface ProviderWindowProps {
  userId: string;
  fhirUserId: string;
  patientId: string | null;
  patientData: boolean;
  patientMiNTIdentifier: boolean;
}

const getFhirIdFromUri = (uri: string): string => {
  const fhirIdParts = uri.split('/');
  return fhirIdParts?.[1] ? fhirIdParts[1] : '';
};

function ProviderWindow({
  userId,
  fhirUserId,
  patientId,
  patientData,
  patientMiNTIdentifier,
}: ProviderWindowProps) {
  const { confirmDialog } = useDialogStore();

  const { i18n } = useLocales();

  const { getSmartUrl, readyToBuild } = useSmartLaunch({
    userId,
    fhirUserId: getFhirIdFromUri(fhirUserId),
    patientId,
    smartAppBaseLaunchUrl: PROVIDER_WINDOW_LAUNCH_URL,
  });

  const canOpenMintNonExistentPatient = checkAclValidation({
    acls: [crsAcls.PROVIDER_WINDOW.OPEN_NON_EXISTENT_PATIENT],
  });
  const canViewProviderWindowButton = checkAclValidation({
    acls: [crsAcls.PROVIDER_WINDOW.VIEW],
  });

  const canOpenProviderWindow = useMemo(() => {
    return true;
    // if (patientId && patientData) {
    //   if (requireMintProvisioning(patientMiNTIdentifier)) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return false;
    // }
  }, [patientId, patientData, patientMiNTIdentifier]);

  const handleVerify = async () => {
    if (canOpenProviderWindow) {
      handleOpenProviderWindow();
    } else if (!patientId) {
      await confirmDialog({
        title: 'Warning',
        confirmText: 'Ok',
        description: (
          <>
            <Typography gutterBottom>Unable to open Provider Window</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <strong>NOTE:</strong> {i18n('patients.title', 'crs')} MRN is missing.
            </Typography>
          </>
        ),
      });
    } else if (requireMintProvisioning(patientMiNTIdentifier) || !patientData) {
      const confirms = await confirmDialog({
        confirmText: canOpenMintNonExistentPatient ? 'Open' : 'OK',

        title: 'Warning',
        description: (
          <>
            {!!canOpenMintNonExistentPatient && (
              <Typography gutterBottom>
                {i18n('patients.details.bannerSummary.providerwindow.subtitle', 'crs')}
              </Typography>
            )}
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <strong>NOTE : </strong>
              {i18n('patients.details.bannerSummary.providerwindow.note', 'crs')}
            </Typography>

            {!patientData && (
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Please make sure to fill in this information, so that the {i18n('patients.title', 'crs')} can be
                  provisioned in MiNT.
                </Typography>
                <Item text="First Name / Last Name" color="success" />
                <Item text="Date of Birth" color="info" />
                <Item text="Gender" color="warning" />
                <Item text="Address (at least one record)" color="error" />
              </>
            )}
          </>
        ),
      });

      if (confirms && canOpenMintNonExistentPatient) {
        handleOpenProviderWindow();
      }
    }
  };

  const handleOpenProviderWindow = async () => {
    const url = await getSmartUrl();
    if (url) {
      window.open(url);
    }
  };

  return canViewProviderWindowButton?(
    <Button color="inherit" variant="outlined" size="small" onClick={handleVerify}>
      {i18n('patients.details.bannerSummary.providerwindow.buttontitle', 'crs')}
    </Button>
  ):(<></>);
}

export default ProviderWindow;
