import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchTextField from 'src/components/SearchTextField';
import useObjectState from 'src/hooks/useObjectState';
import { DataGrid, GridColDef, GridRow } from '@mui/x-data-grid';
import {
  useCareTeams,
  useOrganizations,
  usePractitioners,
  useRelatedPersons,
  useValueSets,
} from 'src/@nicheaim/fhir-react';
import { CareTeamWrapper, WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  PractitionerRoleWrapper,
  WrappedPractitionerRole,
} from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { IdentifierCode, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import {
  CareTeamMember,
  Option,
  CareTeamMemberWithEditableData,
} from 'src/@types/crs/case';
import { GridItem, GridSection } from 'src/components/CustomModal';
import {
  Bundle,
  FhirResource,
  Organization,
  Practitioner,
  PractitionerRole,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { debounce } from 'src/utils/timers';
import { getReference } from 'src/sections/crs/helpers/common';
import { NOTAPPLICABLE, iconGreyFillColor, isActiveCondition } from 'src/sections/crs/constants';
import { capitalize, cleanSearchInput } from 'src/utils/string';
import { getResourcesAndRelatedResources } from 'src/utils/fhir';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import {
  RelatedPersonWrapper,
  WrappedRelatedPerson,
} from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { fhirClient } from 'src/App';
import { ResourceWithIncludedResources } from 'src/sections/crs/types';
import CellRow from 'src/sections/crs/common/CellRow';
import { Cake, Mail, Phone, Wc, InsertLink, Remove } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import { CSSProperties } from '@mui/styles';
import { formatUSAddress } from 'src/utils/address';

interface SearchMemberState {
  searchTextField: string;
  filter: string | null;
  resourceType: string | null;
  filterExternalRole: string | null;
}

export type MemberResourceTypes =
  | 'Patient'
  | 'Practitioner'
  | 'PractitionerRole'
  | 'CareTeam'
  | 'Organization'
  | 'RelatedPerson';

export type onSelectResource = (
  resource: CareTeamMemberWithEditableData[] | null,
  includedResources?: FhirResource[]
) => void;

type MemberWrappedResourceTypes =
  | WrappedPatient
  | WrappedCareTeam
  | WrappedPractitioner
  | WrappedPractitionerRole
  | WrappedOrganization
  | WrappedRelatedPerson;

interface GridRow extends Partial<CareTeamMember> {
  id: string;
  memberType: string;
  resource?: MemberWrappedResourceTypes;
  includedResources?: FhirResource[];
}

export interface SearchMemberProps {
  patient?: WrappedPatient;
  onSelectResource?: onSelectResource;
  externalResourceType?: MemberResourceTypes;
  filterRole?: string | null;
  onClear?: () => void;
  careTeamMembers?: CareTeamMember[];
  isLoading?: boolean;
}

export type MemberResourceTypeOption = Option<MemberResourceTypes>;

const pagination = {
  pageSize: 50,
};

const columnsDefaultWidth = 250;

const iconSize: CSSProperties = { height: 15, width: 15 };

const getDefaultColumnWidth = (resourceType: string | null, columnWidth = columnsDefaultWidth) => {
  if (!resourceType) return { width: columnWidth, flex: 0 };
  return {};
};

const SearchMember = ({
  externalResourceType,
  filterRole,
  onSelectResource,
  onClear,
  patient,
  careTeamMembers,
  isLoading,
}: SearchMemberProps) => {
  const { i18n } = useLocales();
  const [{ filter, searchTextField, resourceType, filterExternalRole }, updateState] =
    useObjectState<SearchMemberState>({
      searchTextField: '',
      filter: null,
      resourceType: null,
      filterExternalRole: null,
    });

  const [{ practitionerRoles, isPractitionerRolesLoading }, updatePractitionerRoleState] =
    useObjectState<{
      isPractitionerRolesLoading: boolean;
      practitionerRoles: ResourceWithIncludedResources[];
    }>({
      practitionerRoles: [],
      isPractitionerRolesLoading: false,
    });

  const practitionerFilter = useMemo(() => {
    const cleansedSearch = filter ? cleanSearchInput(filter) : '';
    if (!cleansedSearch.length) return isActiveCondition;
    const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
    const givenName = splittedSearch?.[0] ?? '';
    const familyName = splittedSearch.slice(1).join(' ');

    return splittedSearch.length === 1
      ? {
          name: givenName,
          ...isActiveCondition,
        }
      : {
          given: givenName,
          ...(familyName ? { family: familyName } : {}),
          ...isActiveCondition,
        };
  }, [filter]);

  const getPractitionerRoles = useCallback(async () => {
    if (resourceType !== 'PractitionerRole') return;
    try {
      updatePractitionerRoleState({ isPractitionerRolesLoading: true });
      const cleansedSearch = filter ? cleanSearchInput(filter) : '';
      const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
      const givenName = splittedSearch?.[0] ?? '';
      const familyName = splittedSearch.slice(1).join(' ');

      const includeQuery = `_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;

      const filterPRByPractitioners = {
        ...(givenName && familyName
          ? {
              'practitioner.given': givenName,
              ...(familyName
                ? {
                    'practitioner.family': familyName,
                  }
                : {}),
            }
          : {
              'practitioner.name': cleansedSearch,
            }),
        ...isActiveCondition,
      };
      const filterPRByOrganizations = {
        'organization.name': cleansedSearch,
        ...isActiveCondition,
      };
      const filterPR = {
        ...(cleansedSearch ? { 'role:text': cleansedSearch } : {}),
        ...isActiveCondition,
      };

      const practitionerRolesResources = await fhirClient.get<Bundle>(
        `PractitionerRole?${new URLSearchParams(filterPR).toString()}&${includeQuery}`
      );
      const pRolesByPractitioner = cleansedSearch
        ? await fhirClient.get<Bundle>(
            `PractitionerRole?${new URLSearchParams(
              filterPRByPractitioners
            ).toString()}&${includeQuery}`
          )
        : null;

      const pRolesByOrganization = cleansedSearch
        ? await fhirClient.get<Bundle>(
            `PractitionerRole?${new URLSearchParams(
              filterPRByOrganizations
            ).toString()}&${includeQuery}`
          )
        : null;

      const [practitionerRoles, relatedResources] = getResourcesAndRelatedResources(
        [
          ...(practitionerRolesResources?.entry ?? []),
          ...(pRolesByPractitioner?.entry ?? []),
          ...(pRolesByOrganization?.entry ?? []),
        ].reduce<FhirResource[]>((resources, { resource }) => {
          if (resource) return [...resources, resource];
          return resources;
        }, []),
        'PractitionerRole'
      );
      updatePractitionerRoleState({
        practitionerRoles: (practitionerRoles as PractitionerRole[]).map((practitionerRole) => {
          const organization = relatedResources.find(
            ({ id }) => id === practitionerRole?.organization?.reference?.split?.('/')?.[1]
          );
          const practitioner = relatedResources.find(
            ({ id }) => id === practitionerRole?.practitioner?.reference?.split?.('/')?.[1]
          );
          return {
            resource: PractitionerRoleWrapper(practitionerRole),
            includedResources: [
              ...(organization ? [OrganizationWrapper(organization as Organization)] : []),
              ...(practitioner ? [PractitionerWrapper(practitioner as Practitioner)] : []),
            ],
          };
        }),
      });
    } catch (error) {}

    updatePractitionerRoleState({ isPractitionerRolesLoading: false });
  }, [filter, resourceType]);

  useEffect(() => {
    getPractitionerRoles();
  }, [getPractitionerRoles]);

  const [membersSelected, setMembersSelected] = useState<CareTeamMember[]>([]);

  useEffect(() => {
    if (!externalResourceType) return;
    updateState({ resourceType: externalResourceType });
  }, [externalResourceType]);

  useEffect(() => {
    if (!filterRole) return;
    updateState({ filterExternalRole: filterRole });
    setMembersSelected(careTeamMembers ?? [])
  }, [filterRole, careTeamMembers]);

  useEffect(() => {
    onSelectResource?.(
      membersSelected
    );
  },[membersSelected])

  const [rolesRecords] = useValueSets({
    filter: {
      identifier: 'ph-careteam-roles',
    },
    map: ValueSetWrapper,
  });

  const roles = useMemo(() => rolesRecords?.[0] ?? null, [rolesRecords]);

  const [careTeams, { isFetching: isCareTeamFetching }] = useCareTeams({
    map: CareTeamWrapper,
    autofetch: resourceType === 'CareTeam',
    ...pagination,
  });
  
  const [practitioners, { isFetching: isPractitionerFetching }] = usePractitioners({
    filter: practitionerFilter,
    map: PractitionerWrapper,
    autofetch: resourceType === 'Practitioner',
    ...pagination,
  });

  const [organizations, { isFetching: isOrganizationsFetching }] = useOrganizations({
    filter: {
      '_content': filter || undefined
    },

    map: OrganizationWrapper,
    autofetch: resourceType === 'Organization',
    ...pagination,
  });

  const [relatedPersons, { isFetching: isRelatedPerson }] = useRelatedPersons({
    filter: {
      name: filter || undefined,
      patient: patient?.id ?? undefined,
    },
    map: RelatedPersonWrapper,
    autofetch: resourceType === 'RelatedPerson',
    ...pagination,
  });

  const isFetching =
    isCareTeamFetching ||
    isPractitionerFetching ||
    isPractitionerRolesLoading ||
    isOrganizationsFetching ||
    isRelatedPerson;

  const getResourceByResourceType = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'CareTeam':
          return careTeams?.filter((e) => 
            !e?.subject || 
            e?.subject?.reference === `${patient?.resourceType}/${patient?.id}`
          );       
        case 'Practitioner':
          return practitioners;
        case 'PractitionerRole':
          return practitionerRoles;
        case 'Organization':
          return organizations;
        case 'RelatedPerson':
          return relatedPersons;
        case 'Patient':
          return [patient];
      }
    },
    [patient, careTeams, practitioners, practitionerRoles, organizations, relatedPersons]
  );

  const handleFilterChange = useCallback(
    debounce((value) => {
      updateState({ filter: value });
    }, 600),
    []
  );

  const handleMemberSelection = useCallback(
    (member: CareTeamMember, isMemberSelected: boolean): void => {
      if (isMemberSelected) {
        setMembersSelected((membersSelected) =>
          membersSelected.filter(
            ({ id, memberType }) =>
              getReference(memberType, id) !== getReference(member.memberType, member.id)
          )
        );
      } else {
        setMembersSelected((membersSelected) => [...membersSelected, member]);
      }
    },
    []
  );

  const translate = useCallback((item: string) => i18n(`practitioner.${item}`, 'crs'), [i18n]);

  const defaultColumns: Record<string, GridColDef> = useMemo(
    () => ({
      name: {
        field: 'name',
        headerName: 'Name',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      memberType: {
        field: 'memberType',
        headerName: 'Member Type',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      identifier: {
        field: 'identifier',
        headerName: 'Identifier',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      associatedOrg: {
        field: 'associatedOrg',
        headerName: 'Associated Organization(s)',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      role: {
        field: 'role',
        headerName: 'Role',
        sortable,
        ...getDefaultColumnWidth(resourceType)
      },
      npi: {
        sortable: false,
        field: 'npi',
        headerName: translate('npi'),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const practitioner = row?.includedResources?.
            find((e: FhirResource) => e?.resourceType === 'Practitioner') as WrappedPractitioner;
          return <CellRow shouldTruncateText={false} title={practitioner?.getNPI()} />;
        },
      },
      details:  {
        sortable: false,
        field: 'details',
        headerName: translate('details'),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const practitioner = row?.includedResources?.
            find((e: FhirResource) => e?.resourceType === 'Practitioner') as WrappedPractitioner;
  
          return (
            <Box>
              <CellRow
                tooltipTitle={translate('practitionerName')}
                shouldTruncateText={false}
                title={capitalize(practitioner?.getFullName() ?? '')}
                Icon={<Iconify icon="mdi:stethoscope" sx={{ ...iconSize }} color={iconGreyFillColor} />}
              />
  
              <CellRow
                tooltipTitle={translate('gender')}
                shouldTruncateText={false}
                title={capitalize(practitioner?.gender ?? '')}
                Icon={<Wc sx={{ ...iconSize }} htmlColor={iconGreyFillColor} />}
              />
              <CellRow
                tooltipTitle={translate('dob')}
                shouldTruncateText={false}
                title={
                  practitioner?.birthDate
                    ? practitioner?.getBirthDate()
                    : null
                }
                Icon={
                  <Cake
                    sx={{ ...iconSize, top: -2, position: 'relative' }}
                    htmlColor={iconGreyFillColor}
                  />
                }
              />
            </Box>
          );
        },
      },
      contactDetails: {
        flex: 2,
        sortable: false,
        field: 'contactDetails',
        headerName: translate('contactDetails'),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const practitioner = row?.includedResources?.
            find((e: FhirResource) => e?.resourceType === 'Practitioner') as WrappedPractitioner;

          return (
            <Box>
              <CellRow
                tooltipTitle={translate('phone')}
                shouldTruncateText={false}
                title={practitioner?.getPrimaryPhone()?.value}
                Icon={<Phone sx={{ ...iconSize }} htmlColor={iconGreyFillColor} />}
              />
              <CellRow
                tooltipTitle={translate('email')}
                shouldTruncateText={false}
                title={practitioner?.getPrimaryEmail()?.value}
                Icon={<Mail sx={{ ...iconSize }} htmlColor={iconGreyFillColor} />}
              />
            </Box>
          );
        },
      },
      address: {
        flex: 2,
        sortable: false,
        field: 'address',
        headerName: translate('address'),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const practitioner = row?.includedResources?.
            find((e: FhirResource) => e?.resourceType === 'Practitioner') as WrappedPractitioner;
          const organization = row?.resource as WrappedOrganization;

          const address = practitioner ? 
            practitioner?.getPrimaryAddressFormatted() : formatUSAddress(organization?.getPrimaryAddress()) ?? '';

          return (
            <CellRow
              tooltipTitle={translate('address')}
              shouldTruncateText={false}
              title={address}
            />
          );
        },
      }
    }),
    [resourceType, roles]
  );

  const columnsMapping: ResourceMapping<GridColDef[]> = useMemo(
    () => ({
      Practitioner: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      Patient: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      CareTeam: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.associatedOrg,
          flex: 1,
        },
      ],
      PractitionerRole: [
        {
          ...defaultColumns.npi,
          flex: 1,
        },
        {
          ...defaultColumns.details,
          flex: 2,
        },
        {
          ...defaultColumns.contactDetails,
          flex: 2,
        },
        {
          ...defaultColumns.address,
          flex: 2,
        },
      ],
      Organization: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
        {
          ...defaultColumns.address,
          flex: 2,
        },
      ],
      RelatedPerson: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
    }),
    [defaultColumns]
  );

  const actionButtonColumn: GridColDef = useMemo(
    () => ({
      field: 'edit',
      headerName: '',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const row = params.row as GridRow;
        const isMemberSelected = !!membersSelected.find(
          (memberSelected) =>
            memberSelected.id === row?.id && memberSelected.memberType === row?.memberType
        );

        return (
          <Button
            disabled={!!isLoading}
            color={isMemberSelected ? 'error' : 'primary'}
            startIcon={isMemberSelected ? <Remove /> : <InsertLink />}
            onClick={() => {
              handleMemberSelection(row as CareTeamMember, isMemberSelected);
              return;
            }}
            variant="contained"
          >
            {isMemberSelected ? 'Remove' : 'Add'}
          </Button>
        );
      },
    }),
    [membersSelected, handleMemberSelection]
  );

  const getRows = useCallback(() => {

    const resources = getResourceByResourceType(resourceType as string);

    let activeResources = []
    if (resources) {
      // @ts-ignore
      activeResources = resources?.filter((x) => {
        if (resourceType === 'PractitionerRole') {
          // @ts-ignore
          if (x?.includedResources && x?.includedResources?.length > 0) {
            // @ts-ignore
            const practitioner = x?.includedResources.find(
              (prac) => prac?.resourceType === 'Practitioner'
            );
            if (practitioner && practitioner?.active === true) {
              return true;
            }
          }
        } else {
          return true;
        }
      });
    }

    if(filterExternalRole && resourceType === 'PractitionerRole'){
      return activeResources?.map?.((resource) =>
        getRowsMapping?.[resourceType as MemberResourceTypes]?.(
          resource as MemberWrappedResourceTypes
        )
      ).filter((e) => e?.roleId === filterExternalRole);
    }

    if(resourceType === 'PractitionerRole'){
      return activeResources?.map?.((resource) =>
        getRowsMapping?.[resourceType as MemberResourceTypes]?.(
          resource as MemberWrappedResourceTypes
        )
      ).filter((e) => e?.roleId === filterExternalRole);
    }

    if(resourceType === 'CareTeam'){
      return activeResources?.map?.((resource) =>
        getRowsMapping?.[resourceType as MemberResourceTypes]?.(
          resource as MemberWrappedResourceTypes
        )
      );
    }
  }, [resourceType, getResourceByResourceType, filter, filterExternalRole]);

  const getColumns = useCallback(() => {
    const columnsByResourceType = (columnsMapping?.[resourceType as MemberResourceTypes] ??
      []) as GridColDef[];

    if (resourceType)
      return [...columnsByResourceType, actionButtonColumn];

    return [...Object.values(defaultColumns), actionButtonColumn];
  }, [
    defaultColumns,
    actionButtonColumn,
    resourceType,
    columnsMapping
  ]);

  const rows = useMemo(() => getRows(), [getRows]);

  const columns = useMemo(() => getColumns(), [getColumns]);
  return (
    <>
      <GridSection mt={0}>
        <GridItem
          xs={12}
          display={'flex'}
          justifyContent={'row'}
        >
          <SearchTextField
            disabled={!!isLoading}
            value={searchTextField}
            onChange={(event) => {
              const { value } = event.target;
              updateState({
                searchTextField: value,
              });
              handleFilterChange(value);
            }}
            placeholder={`Search ${
              titleMapping?.[resourceType as MemberResourceTypes]
                ? `by ${titleMapping[resourceType as MemberResourceTypes]}`
                : ''
            }...`}
            fullWidth
          />
          <Button
            disabled={!!isLoading}
            sx={{
              ml: 2,
              paddingY: 2,
              paddingX: 3,
              alignSelf: 'center',
              color: '#ff5630',
              width: 78,
              height: 26,
              border: 0,
              '&:hover': { border: 0, backgroundColor: 'rgba(255, 86, 48, 0.16)' },
            }}
            onClick={() => {
              updateState({
                searchTextField: '',
                resourceType: null,
                filter: null,
              });
              onClear?.();
            }}
            variant="outlined"
            color="inherit"
          >
            <DeleteOutlineIcon htmlColor={'#ff5630'} sx={{ mr: '1px' }} />
            <Typography
              color={'#ff5630'}
              fontSize={'0.9rem'}
              fontWeight={'bold'}
              sx={{ mr: '2px' }}
            >
              Clear
            </Typography>
          </Button>
        </GridItem>
      </GridSection>
      <GridSection>
        <GridItem xs={12}>
          <Box sx={{ width: '100%', height: 450 }}>
            {(!!resourceType) && (
              <DataGrid
                rows={rows ?? []}
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-cell': {
                    border: 0,
                  },
                }}
                loading={isFetching}
                getRowSpacing={() => ({ bottom: 5 })}
                rowHeight={145}
                columns={columns}
                rowSpacingType="margin"
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                density={'compact'}
                components={{
                  Footer: () => <></>,
                }}
              />
            )}
          </Box>
        </GridItem>
      </GridSection>
    </>
  );
};

type ResourceMapping<T> = {
  [k in MemberResourceTypes]?: T;
};

const titleMapping: ResourceMapping<string> = {
  PractitionerRole: 'Practitioner Role',
};

const sortable = false;

const getRowsMapping: ResourceMapping<(resource: MemberWrappedResourceTypes) => GridRow> = {
  Practitioner: (resource) => {
    const { getFullName, id, getIdentifier, resourceType, meta } = resource as WrappedPractitioner;
    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getFullName() ?? '',
      associatedOrg: NOTAPPLICABLE,
      memberType: resourceType,
      versionId: meta?.versionId ?? '',
      resource,
    };
  },
  Patient: (resource) => {
    const { getFullName, id, getIdentifier, resourceType, meta } = resource as WrappedPatient;
    return {
      id: id ?? '',
      identifier: getIdentifier(IdentifierCode.MEDICAL_RECORD_NUMBER)?.value ?? '',
      name: getFullName() ?? '',
      memberType: resourceType,
      versionId: meta?.versionId ?? '',
      resource,
    };
  },
  CareTeam: (resource) => {
    const { name, id, resourceType, meta } = resource as WrappedCareTeam;
    return {
      id: id ?? '',
      name,
      resource,
      memberType: resourceType,
      associatedOrg: NOTAPPLICABLE,
      role: "N/A",
      roleId: "care-team",
      roleName: "Care Team",
      versionId: meta?.versionId ?? '',
    };
  },
  PractitionerRole: (resource) => {
    const { resource: practitionerRole, includedResources } = (resource ??
      {}) as unknown as ResourceWithIncludedResources;

    const {
      getIdentifier,
      getPractitionerName,
      getRoleName,
      getRoleId,
      resourceType,
      getOrganizationId,
      getOrganizationName,
      meta
    } = (practitionerRole ?? {}) as WrappedPractitionerRole;

    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: practitionerRole?.id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getPractitionerName((includedResources ?? []) as WrappedPractitioner[]),
      memberType: resourceType,
      role: getRoleName() ?? getRoleId(),
      roleId: getRoleId(),
      roleName: getRoleName(),
      associatedOrg: getOrganizationName() ?? getOrganizationId(),
      associatedOrgId: getOrganizationId(),
      associatedOrgName: getOrganizationName((includedResources ?? []) as WrappedOrganization[]),
      versionId: meta?.versionId ?? '',
      resource: practitionerRole as WrappedPractitionerRole,
      includedResources,
    };
  },
  Organization: (resource) => {
    const { id, resourceType, name } = resource as WrappedOrganization;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: name ?? '',
      memberType: resourceType,
      resource,
    };
  },
  RelatedPerson: (resource) => {
    const { id, resourceType, getFullName } = (resource ?? {}) as WrappedRelatedPerson;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: getFullName?.() ?? '',
      memberType: resourceType,
      resource,
    };
  },
};

export const getMemberResourceTypes = (showPatient: boolean = true): MemberResourceTypeOption[] => [
  ...(showPatient ? [{ label: 'Patient', value: 'Patient' } as MemberResourceTypeOption] : []),
  { label: 'Practitioner', value: 'Practitioner' },
  { label: 'Practitioner Role', value: 'PractitionerRole' },
  { label: 'Care Team', value: 'CareTeam' },
];

export default SearchMember;