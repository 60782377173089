import { FhirSystem } from 'src/utils/fhirSystem';

const fhirSystem = new FhirSystem('https://careflow2.nicheaim.com')
  .withSubsystem('client')
  .withSubsystem('healthPlan', 'health-plan')
  .withSubsystem('lineOfBusiness', 'line-of-business')
  .withSubsystem('program', (system) =>
    system.withSubsystem('enrolledPatients', 'enrolled-patients')
  )
  .withSubsystem('systemUser', 'system-user')
  .withSubsystem('activity', (system) =>
    system.withSubsystem('completedWithAssessment', 'completed-with-assessment')
  )
  .withSubsystem('automation', (system) =>
    system //
      .withSubsystem('carePlan', 'care-plan', (system) =>
        system //
          .withSubsystem('rule', 'rule')
      )
      .withSubsystem('activity', (system) =>
        system //
          .withSubsystem('rule', 'rule')
      )
      .withSubsystem('status')
  )
  .withSubsystem('assessments', (system) =>
    system //
      .withSubsystem('assessment')
      .withSubsystem('response')
  )
  .withSubsystem('note', (system) =>
    system //
      .withSubsystem('type')
  )
  .withSubsystem('healthRecord', (system) =>
    system //
      .withSubsystem('vitalsType', 'vitals-type')
  )
  .withSubsystem('extension', (system) =>
    system
      .withSubsystem('PlanDefinition', (system) =>
        system.withSubsystem('createsAppointmentAlert', 'creates-appointment-alert')
      )
      .withSubsystem('CarePlan', (system) =>
        system
          .withSubsystem('createsAppointmentAlert', 'creates-appointment-alert')
          .withSubsystem('createdByAutomation', 'created-by-automation')
          .withSubsystem('currentStatus', 'current-status')
          .withSubsystem('lastUpdated', 'last-updated')
      )
      .withSubsystem('ActivityDefinition', (system) =>
        system //
          .withSubsystem('dst')
          .withSubsystem('contactType', 'contact-type')
      )
      .withSubsystem('Group', (system) =>
        system //
          .withSubsystem('member', (system) =>
            system //
              .withSubsystem('inactiveReason', 'inactive-reason')
              .withSubsystem('exitDestination', 'exit-destination')
          )
      )
  )
  .withSubsystem('resourceHash', 'resource-hash')
  .withSubsystem('carePlan', 'care-plan')
  .withSubsystem('codeSystems', (system) =>
    system
      .withSubsystem('ccmActivityInputTypes', 'ccm-activity-input-types')
      .withSubsystem('ccmActivityOutputTypes', 'ccm-activity-output-types')
  )
  .withSubsystem('note', (system) =>
    system //
      .withSubsystem('type'),
  )
  .withSubsystem('medication', (system) =>
    system
    .withSubsystem('dosageForm', 'dosage-form')
    .withSubsystem('routeName', 'route-name')
    .withSubsystem('snomedForm', 'http://snomed.info/sct')
  )
  .seal();

export default fhirSystem;
