import { useCallback, useEffect } from 'react';
import { Autocomplete, Box, Button, Chip, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import {
  DocumentReferenceWrapper,
  WrappedDocumentReference,
} from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useDocumentReferences, useValueSet } from 'src/@nicheaim/fhir-react';
import CustomModal, { GridSection, GridItem } from 'src/components/CustomModal';
import DatePickerMoment from 'src/components/DatePickerMoment';
import SearchTextField from 'src/components/SearchTextField';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import CustomDataGrid from 'src/sections/YoCare/components/CustomDataGrid';
import CellRow from './CellRow';
import PDFIcon from '../../../assets/icons/ic_pdf.svg';
import useClientGrid from 'src/hooks/useClientGrid';
import { begginingOfTime, endOfTime, formatDate, momentDateComparator } from 'src/utils/dates';
import { cleanSearchInput } from 'src/utils/string';
import { Link } from '@mui/icons-material';
import Viewer from './Viewer';
import AttachmentAddForm from './AttachmentAddForm';
import CancelButton from './CancelButton';
import CustomButton from './CustomButton';
import { defaultRowsPerPageOptions } from '../constants';

export interface SelectAttachmentProps {
  patient: WrappedPatient | null;
  onSelect: (documentReference: WrappedDocumentReference) => void;
  onClose: VoidFunction;
}

export interface SelectAttachmentState {
  startDateError: string | null;
  documentReferences: WrappedDocumentReference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  categories: ValueSetComposeIncludeConcept[] | undefined;
  nameInput: string;
  isDocumentPreviewDialogOpen: boolean;
  isAddAttachmentDialogOpen: boolean;
  selectedDocumentReference: WrappedDocumentReference | null;
}
const SelectAttachment = ({ patient, onSelect, onClose }: SelectAttachmentProps) => {
  const [
    {
      nameInput,
      startDate,
      endDate,
      categories,
      startDateError,
      documentReferences,
      isDocumentPreviewDialogOpen,
      selectedDocumentReference,
      isAddAttachmentDialogOpen,
    },
    updateState,
  ] = useObjectState<SelectAttachmentState>({
    nameInput: '',
    startDate: null,
    endDate: null,
    categories: [],
    startDateError: null,
    documentReferences: [],
    isDocumentPreviewDialogOpen: false,
    selectedDocumentReference: null,
    isAddAttachmentDialogOpen: false,
  });
  const [documentCategories] = useValueSet('ph-document-category-codes', { map: ValueSetWrapper });
  const { i18n } = useLocales();

  const [
    documentReferencesRecords,
    {
      isFetching: isDocumentReferencesLoading,
      create: createDocumentReference,
      refresh: refreshDocumentReferences,
    },
  ] = useDocumentReferences({
    map: DocumentReferenceWrapper,
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient?.id,
    pagination: {
      pageSize: 1000,
    },
  });

  const { rowsPerPage, handlePageSizeChange, sortModel, handleSortModelChange } = useClientGrid({
    initialRowsPerPage: 10,
    defaultSort: [{ field: 'date', sort: 'desc' }],
  });

  const filterDocumentReferences = useCallback(
    () =>
      documentReferencesRecords?.filter?.((documentReference) => {
        const nameInputSearch = cleanSearchInput(nameInput).toLowerCase();
        const nameFile = documentReference?.content?.[0]?.attachment?.title ?? '';
        if (nameInputSearch && !nameFile.toLowerCase().includes(nameInputSearch)) return false;

        const categoriesArr = (categories ?? []).map(({ code }) => code);
        if (
          categoriesArr.length &&
          !documentReference?.category?.some?.((category) =>
            categoriesArr.includes(category?.coding?.[0]?.code ?? '')
          )
        )
          return false;

        if (startDate?.isValid?.() && endDate?.isValid?.() && startDate.isAfter(endDate, 'date'))
          return true;

        const documentDate = moment(documentReference?.date ?? null);

        const start = moment(startDate?.isValid?.() ? startDate : begginingOfTime);
        const end = moment(endDate?.isValid?.() ? endDate : endOfTime);

        if (!documentDate.isBetween(start, end, 'date')) return false;

        return true;
      }) ?? [],
    [documentReferencesRecords, startDate, endDate, categories, nameInput]
  );

  useEffect(() => {
    updateState({ documentReferences: filterDocumentReferences() });
  }, [filterDocumentReferences, updateState]);

  useEffect(() => {
    updateState({ startDateError: null });
    if (!startDate?.isValid?.() || !endDate?.isValid?.()) return;
    if (startDate.isAfter(endDate, 'date'))
      updateState({ startDateError: i18n('errorMessages.startDateGreater', 'crs') });
  }, [startDate, endDate, updateState]);

  const handlePreviewDocumentClose = () => {
    updateState({
      isDocumentPreviewDialogOpen: false,
      selectedDocumentReference: null,
    });
  };

  const attachmentsGridColumns: GridColDef[] = [
    {
      flex: 2,
      field: 'name',
      valueGetter: (params) =>
        (params.row as WrappedDocumentReference)?.content?.[0]?.attachment?.title,
      headerName: i18n('selectAttachment.name', 'crs'),
      renderCell: (params) => {
        const documentReference = params.row as WrappedDocumentReference;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Tooltip title={i18n('selectAttachment.previewDocument', 'crs')}>
              <span>
                <IconButton
                  sx={{
                    marginRight: '16px',
                  }}
                  onClick={() => {
                    updateState({
                      selectedDocumentReference: documentReference,
                      isDocumentPreviewDialogOpen: true,
                    });
                  }}
                >
                  <PDFIcon />
                </IconButton>
              </span>
            </Tooltip>
            <CellRow
              shouldTruncateText={false}
              title={documentReference?.content?.[0]?.attachment?.title}
            />
          </Box>
        );
      },
    },
    {
      sortable: false,
      flex: 2,
      field: 'category',
      headerName: i18n('selectAttachment.category', 'crs'),
      renderCell: (params) => {
        const documentReference = params.row as WrappedDocumentReference;
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
            {documentReference?.category?.map?.((category, index) => (
              <Chip
                sx={{ marginRight: 1 }}
                key={`grid-chip-category-${
                  category?.coding?.[0]?.code ?? category?.coding?.[0]?.display ?? index
                }`}
                size="small"
                label={category?.coding?.[0]?.display ?? ''}
              />
            )) ?? null}
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'date',
      sortComparator: (_, __, date1, date2) =>
        momentDateComparator(moment(date1.value ?? null), moment(date2.value ?? null)),
      headerName: i18n('selectAttachment.date', 'crs'),
      renderCell: (params) => {
        const documentReference = params.row as WrappedDocumentReference;
        const dateMoment = moment(documentReference?.date ?? null);
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
            {dateMoment.isValid() ? formatDate(dateMoment) : ''}
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'action',
      headerName: ' ',
      sortable: false,
      renderCell: (params) => {
        const documentReference = params.row as WrappedDocumentReference;
        return (
          <Button
            startIcon={<Link />}
            sx={{
              height: 36,
            }}
            onClick={() => {
              onSelect(documentReference);
            }}
            variant="contained"
          >
            {i18n('selectAttachment.select', 'crs')}
          </Button>
        );
      },
    },
  ] as GridColDef[];

  return (
    <>
      <GridSection mt={0}>
        <Typography variant="h6">
        {i18n('selectAttachment.title', 'crs')}
        </Typography>
      </GridSection>
      <Divider sx={{ my: 2 }} />
      <GridSection mt={1}>
        <Typography variant="caption" sx={{ color: 'gray'}}>
          {i18n('selectAttachment.comment', 'crs')}
        </Typography>
      </GridSection>
      <GridSection >
        <GridItem xs={12}>
          <SearchTextField
            value={nameInput}
            onChange={(event) => {
              updateState({ nameInput: event.target.value });
            }}
            placeholder={i18n('selectAttachment.searchByName', 'crs')}
            fullWidth
          />
        </GridItem>
      </GridSection>
      <GridSection mt={3}>
        <GridItem xs={12}>
          <CustomDataGrid
            rows={documentReferences ?? []}
            getRowId={({ id }: WrappedDocumentReference) => id as string}
            getRowHeight={() => 'auto'}
            getRowSpacing={() => ({ bottom: 2, top: 2 })}
            autoHeight={true}
            columns={attachmentsGridColumns}
            loading={isDocumentReferencesLoading}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            pageSize={rowsPerPage}
            onPageSizeChange={handlePageSizeChange}
            pagination
            paginationMode="client"
            sortingMode="client"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </GridItem>
      </GridSection>
      <GridSection mt={0}>
        <GridItem
          xs={12}
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <CancelButton
            onClick={() => {
              onClose();
            }}
          />
          <CustomButton
            sx={{
              marginLeft: 1,
            }}
            onClick={() => {
              updateState({
                isAddAttachmentDialogOpen: true,
              });
            }}
          >
            {i18n('selectAttachment.uploadNew', 'crs')}
          </CustomButton>
        </GridItem>
      </GridSection>

      <CustomModal
        title={i18n('selectAttachment.previewDocument', 'crs')}
        open={isDocumentPreviewDialogOpen}
        breadcrumbs={[]}
        showSaveButton={false}
        onClose={handlePreviewDocumentClose}
        onCancel={handlePreviewDocumentClose}
      >
        <Viewer
          documentReference={selectedDocumentReference}
          typeResource="documentReference"
          patientId={patient?.id}
        />
      </CustomModal>
      <AttachmentAddForm
        open={isAddAttachmentDialogOpen}
        patient={patient}
        onCancel={() => {
          updateState({
            isAddAttachmentDialogOpen: false,
          });
        }}
        createDocumentReference={createDocumentReference}
        refreshDocumentReference={refreshDocumentReferences}
        handleUpdateResource={async (_) => {}}
      />
    </>
  );
};

export default SelectAttachment;
