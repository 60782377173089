import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from '@mui/material';

export interface LoadableAutocompleteProps
  extends Omit<AutocompleteProps<any, undefined, undefined, undefined>, 'renderInput'> {
  isLoading: boolean;
  label?: string;
  error?: string;
}

const LoadableAutocomplete = ({
  isLoading,
  label,
  error,
  ...autoCompleteProps
}: LoadableAutocompleteProps) => (
  <Autocomplete
    renderInput={(params) => (
      <TextField
        {...params}
        disabled={!!isLoading}
        label={label}
        error={!!error}
        helperText={error}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {!!isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
    {...autoCompleteProps}
  />
);

export default LoadableAutocomplete;
