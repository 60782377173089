import { Container, Grid } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useMatches } from 'react-router';
import { TLink } from '../../components/Breadcrumbs';
import { Wrapper } from '../../utils/layout';
import useLocales from 'src/hooks/useLocales';

interface PatientLayoutProps extends Wrapper {
  title: string;
  includeBreadcrumbs?: boolean;
}

export function PatientLayout({
  title,
  children,
  button,
  includeBreadcrumbs = true,
}: PatientLayoutProps) {
  const { themeStretch } = useSettings();
  const { i18n } = useLocales();
  const matches = useMatches();

  const links = [
    { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
    { name: `${i18n('breadcrumbs.title', 'crs')}`},
    ...matches
      .filter((match) => match.handle)
      .map((match) => ({ name: i18n('breadcrumbs.'+match.handle, 'crs'), href: match.pathname } as TLink)),
  ];

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {includeBreadcrumbs ? (
          <HeaderBreadcrumbs title={title} heading="" links={links} action={button} />
        ) : null}
        <Grid container spacing={3}>
          {children}
        </Grid>
      </Container>
    </Page>
  );
}
