import { v4 as uuidv4 } from 'uuid';
import { ACL, ACLRow, RoleAPI, Role, RoleACLRow } from './types';

export const createACLTree = (acl: ACL[]) =>
  acl.reduce((aclTree: any, acl) => {
    if(acl.code!=='root.*') 
      acl.code='root.'+acl.code;
    const sections = acl.code.split('.');
    let currTreeObj = aclTree;
    let previousParentId = '';
    let previousComposedCode = '';
    sections.forEach((section) => {
      if (!currTreeObj[section]) currTreeObj[section] = {};
      if (!currTreeObj[section].id) currTreeObj[section].id = uuidv4();
      currTreeObj[section].code = section;
      currTreeObj[section].label = section;
      currTreeObj[section].parentId = previousParentId;
      if (!previousComposedCode) {
        previousComposedCode = section;
      } else {
        previousComposedCode += `.${section}`;
      }

      currTreeObj[section].composedCode = previousComposedCode;

      previousParentId = currTreeObj[section].id;
      if (!currTreeObj[section].children) currTreeObj[section].children = {};
      currTreeObj = currTreeObj[section].children;
    });
    return aclTree;
  }, {});

export const getChildrenIds = (acl: any): string[] =>
  Object.entries(acl).reduce<string[]>(
    (children: string[], [_, acl]: [string, any]) => [...children, acl?.id],
    []
  );

export const getDescendantIds = (acl: any): string[] => {
  let ids: string[] = [];
  Object.entries(acl).forEach(([_, acl]: [string, any]) => {
    ids.push(acl?.id);

    ids = [...ids, ...getDescendantIds(acl?.children)];
  });
  return ids;
};

export const findAclRecord = (aclList: ACL[], code: string): ACL | undefined =>
  aclList.find(({ code: aclCode }) => aclCode === code);

export const flattenACLTree = (
  aclTree: any,
  aclList: ACL[],
  ascendants: string[] = []
): ACLRow[] => {
  let aclRows: ACLRow[] = [];
  Object.entries(aclTree).forEach(([_, acl]: [string, any]) => {
    const allAscendants = [...ascendants];
    if (acl?.parentId) allAscendants.push(acl?.parentId);
    const haveChildren = !!Object.keys(acl?.children).length;
    const aclRecord = findAclRecord(aclList, acl?.composedCode);
    aclRows.push({
      label: acl?.composedCode !== 'root.root' ? capitalizeWords(aclRecord?.name ?? acl?.label) : 'All',
      id: acl?.id,
      parentId: acl?.parentId,
      ascendants: allAscendants,
      directDescendantsWithAclRecord: [],
      lastDescendantsWithAclRecord: [],
      descendantsWithAclRecord: [],
      descendants: getDescendantIds(acl?.children),
      children: getChildrenIds(acl?.children),
      composedCode: acl?.composedCode,
      code: acl?.code,
      toolTip: aclRecord?.description,
      aclRecord,
      haveChildren,
    });

    if (!haveChildren) return;

    aclRows = [...aclRows, ...flattenACLTree(acl?.children, aclList, allAscendants)];
  });

  return aclRows;
};

export const mergeRows = (aclRows: ACLRow[]): ACLRow[] => {
  const rowsToFilterOut: string[] = [];
  aclRows.forEach((aclRow) => {
    const mergeableRow = aclRows.find(
      ({ composedCode }) => composedCode === `${aclRow.composedCode}.*`
    );
    if (mergeableRow && mergeableRow?.aclRecord) {
      aclRow.aclRecord = mergeableRow.aclRecord;
      aclRow.label = capitalizeWords(aclRow.aclRecord.name);
      aclRow.toolTip = aclRow.aclRecord.description;
      aclRow.composedCode = mergeableRow.composedCode;
      aclRow.code = mergeableRow.code;
      aclRow.descendants = aclRow.descendants.filter(
        (descendantId) => descendantId !== mergeableRow.id
      );
      aclRow.children = aclRow.children.filter((childrenId) => childrenId !== mergeableRow.id);
      rowsToFilterOut.push(mergeableRow.id);
    }
  });
  return aclRows.filter(({ id }) => !rowsToFilterOut.includes(id));
};

export const getDescendantsWithAclRecord = (aclRows: ACLRow[]): ACLRow[] => {
  aclRows.forEach((aclRow) => {
    aclRow.lastDescendantsWithAclRecord = aclRow.descendants.reduce<string[]>(
      (lastDescendants, descendantId) => {
        const descendant = aclRows.find(({ id }) => id === descendantId);
        if (!descendant?.children?.length && descendant?.aclRecord?.id)
          return [...lastDescendants, descendantId];
        return lastDescendants;
      },
      []
    );
    aclRow.descendantsWithAclRecord = aclRow.descendants.reduce<string[]>(
      (lastDescendants, descendantId) => {
        const descendant = aclRows.find(({ id }) => id === descendantId);
        if (descendant?.aclRecord?.id) return [...lastDescendants, descendantId];
        return lastDescendants;
      },
      []
    );
    recursiveChildrenProcessing(aclRows, aclRow, aclRow.children);
  });
  return aclRows;
};

export const recursiveChildrenProcessing = (
  aclRows: ACLRow[],
  aclRow: ACLRow,
  children: string[]
): void => {
  children.forEach((childAclRowId) => {
    const childAclRow = aclRows.find(({ id }) => id === childAclRowId);
    if (!childAclRow) return;
    if (childAclRow?.aclRecord?.id) {
      aclRow.directDescendantsWithAclRecord.push(childAclRowId);
      return;
    }
    recursiveChildrenProcessing(aclRows, aclRow, childAclRow.children);
  });
};

export const getACLRows = (acl: ACL[]): ACLRow[] => {
  if (!acl) return [];
  const aclTree = createACLTree(acl);
  const aclRows = flattenACLTree(aclTree, acl);
  const mergedAclRows = mergeRows(aclRows);

  return getDescendantsWithAclRecord(mergedAclRows);
};

export const isAscendantsACLVisible = (aclRow: ACLRow, openRows: string[]): boolean => {
  //All ascendants must be visible in order to be shown
  const ascendantsOpen = aclRow.ascendants.filter((ascendant) => openRows.includes(ascendant));
  return ascendantsOpen.length === aclRow.ascendants.length;
};

export const getRoles = (rolesAPI: RoleAPI[]): Role[] =>
  rolesAPI.reduce<Role[]>((roles, roleAPI) => {
    const roleAcl =
      roleAPI?.aclRoleRef?.reduce<ACL[]>((aclCats, aclRef) => [...aclCats, aclRef.aclCat], []) ||
      [];
    return [
      ...roles,
      {
        ...roleAPI,
        acl: [...roleAcl],
      },
    ];
  }, []);

export const getRoleAclRowList = (roles: Role[], aclRows: ACLRow[]): RoleACLRow[] => {
  const rolesWithACL = roles.filter(({ acl }) => acl.length);
  return rolesWithACL.reduce<RoleACLRow[]>(
    (roleList, role) => [
      ...roleList,
      ...role.acl.map(
        (acl): RoleACLRow => ({
          role,
          acl,
          aclRow: aclRows.find((aclRow) => aclRow?.aclRecord?.id === acl.id),
        })
      ),
    ],
    []
  );
};

export const tradeAllRoleAclRowChildrenForParent = (
  roleAclRows: RoleACLRow[],
  role: Role,
  aclRow: ACLRow
) => [
  ...roleAclRows.filter(
    (roleAclRow) =>
      !(
        roleAclRow.role.id === role.id &&
        aclRow.descendantsWithAclRecord.includes(roleAclRow?.aclRow?.id ?? '')
      )
  ),
  {
    role: role,
    aclRow: aclRow,
    acl: aclRow.aclRecord,
  },
];

export const getAclIds = (roleAclRows: RoleACLRow[]): number[] =>
  roleAclRows.reduce<number[]>(
    (aclIds, role) => [...aclIds, role.aclRow?.aclRecord?.id as number],
    []
  );

export const capitalizeWords = (str: string): string => {
  try {
    return str.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  } catch (error) {
    return str;
  }
};
