import { useState, useCallback, useEffect } from 'react';
import { Box, Button, TextField, TextFieldProps } from '@mui/material';
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
  MobileDateTimePicker
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AccessTime as ClockIcon } from '@mui/icons-material';
import { DefaultDateTimePickerProps, DefaultDateType, MobileMonthYearPicker } from './components';
import useLocales from 'src/hooks/useLocales';
import { useRenderDefaultInput } from './hooks';
import moment from 'moment';
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultMonthPickerFormat,
  defaultTimeFormat,
  defaultYearPickerFormat,
} from 'src/sections/crs/constants';

const sxMobileDateTime = { 
  '& .css-1tcwmcw-MuiPickersToolbar-root-MuiDateTimePickerToolbar-root .MuiPickersToolbar-penIconButton': {
    display: 'none'
  }
};

const sxMobileDate = {
  '& .css-d6b7q2-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton': {
    display: 'none'
  }
}

export interface DateTimePickerProps extends DefaultDateTimePickerProps {
  isTimePicker?: boolean;
  isTimeOptional?: boolean;
  isMonthPicker?: boolean;
  isYearPicker?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  monthFormat?: string;
  yearFormat?: string;
  isTimeIncluded?: boolean;
  errorMessage?: string;
  textFieldProps?: Partial<TextFieldProps>;
  onTimeInclusionChange?: (isTimeIncluded: boolean) => void;
}

const DateTimePicker = ({
  onChange,
  value,
  dateFormat,
  timeFormat,
  monthFormat,
  yearFormat,
  isTimePicker,
  isMonthPicker,
  isYearPicker,
  inputFormat,
  ToolbarComponent,
  InputProps,
  onTimeInclusionChange,
  isTimeOptional = false,
  isTimeIncluded = true,
  errorMessage,
  textFieldProps,
  ...dateProps
}: DateTimePickerProps) => {
  const [isTimeIncludedInternal, setIsTimeIncludedInternal] = useState(
    isTimeIncluded || !isTimeOptional
  );
  const [previousIsTimeIncludedInternal, setPreviousIsTimeIncludedInternal] = useState(
    isTimeIncluded || !isTimeOptional
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setIsTimeIncludedInternal(isTimeIncluded || !isTimeOptional);
  }, [isTimeIncluded, isTimeOptional]);

  const handleOnChange = useCallback(
    (date: DefaultDateType) => {
      if (!isTimeIncludedInternal) return onChange?.(moment(date).startOf('day'));
      onChange?.(date);
    },
    [onChange, isTimeIncludedInternal]
  );

  useEffect(() => {
    setPreviousIsTimeIncludedInternal(isTimeIncludedInternal);
    if (isTimeIncludedInternal === previousIsTimeIncludedInternal) return;
    handleOnChange(value);
  }, [isTimeIncludedInternal, value, previousIsTimeIncludedInternal, handleOnChange]);

  useEffect(() => {
    onTimeInclusionChange?.(isTimeIncludedInternal);
  }, [isTimeIncludedInternal, onTimeInclusionChange]);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const renderDefaultInput = useRenderDefaultInput({
    errorMessage,
    textFieldProps,
    onCalendarIconPress: handleOpenDialog,
    inputProps: InputProps,
  });

  const renderHiddenInput = useCallback(
    (params: TextFieldProps) => <TextField {...params} sx={{ display: 'none' }} />,
    []
  );

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const CustomToolbar = () => {
    const { i18n } = useLocales();
    if (!isTimeOptional) return null;
    return (
      <Box sx={{ width: '100%' }}>
        <Button
          fullWidth
          onClick={() => {
            setIsTimeIncludedInternal((currValue) => !currValue);
          }}
          startIcon={<ClockIcon />}
        >
          {i18n(isTimeIncludedInternal ? 'excludeTime' : 'includeTime', 'crs')}
        </Button>
      </Box>
    );
  };
  const isDateTime = !isTimePicker && !isMonthPicker && !isYearPicker;
  const format = getDefaultDateFormat({
    defaultFormat: inputFormat,
    isMonthPicker,
    isTimeIncluded: isTimeIncludedInternal,
    isTimePicker,
    isYearPicker,
  });
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} a>
      {isDateTime ? (
        !isTimeIncludedInternal ? (
          <MobileDatePicker
            label={dateProps.label}
            toolbarTitle={<CustomToolbar />}
            value={value}
            onChange={handleOnChange}
            renderInput={renderDefaultInput}
            inputFormat={format}
            open={isDialogOpen}
            onOpen={handleOpenDialog}
            onClose={handleCloseDialog}
            DialogProps={{sx: sxMobileDate }}
          />
        ) : (
          <MobileDateTimePicker
            label={dateProps.label}
            value={value}
            onChange={handleOnChange}
            toolbarTitle={<CustomToolbar />}
            renderInput={renderDefaultInput}
            inputFormat={format}
            open={isDialogOpen}
            onOpen={handleOpenDialog}
            onClose={handleCloseDialog}
            DialogProps={{sx: sxMobileDateTime }}   
          />
        )
      ) : isTimePicker ? (
        <MobileTimePicker
          renderInput={renderHiddenInput}
          inputFormat={format}
          value={value}
          onChange={handleOnChange}
        />
      ) : isMonthPicker || isYearPicker ? (
        <MobileMonthYearPicker
          value={value}
          onChange={handleOnChange}
          inputFormat={format}
          isYearPicker={isYearPicker}
          InputProps={InputProps}
        />
      ) : null}
    </LocalizationProvider>
  );
};

export interface GetDefaultDateFormatParams {
  defaultFormat: string | undefined;
  isTimeIncluded: boolean | undefined;
  isTimePicker: boolean | undefined;
  isMonthPicker: boolean | undefined;
  isYearPicker: boolean | undefined;
}
export const getDefaultDateFormat = ({
  defaultFormat,
  isTimeIncluded,
  isTimePicker,
  isMonthPicker,
  isYearPicker,
}: GetDefaultDateFormatParams): string => {
  if (defaultFormat) return defaultFormat;
  if (isMonthPicker) return defaultMonthPickerFormat;
  if (isYearPicker) return defaultYearPickerFormat;
  if (isTimePicker) return defaultTimeFormat;
  if (isTimeIncluded) return defaultDateTimeFormat;
  return defaultDateFormat;
};

export default DateTimePicker;
