import { capitalizeWords } from 'src/sections/careflow/permissions/permissions.helpers';
import { Resource, createWrapper } from '.';
import { Person } from '../../types';
import { trimMultipleWhiteSpaces } from 'src/utils/string';

const personWrapperCallback = (person: Resource<Person>) => ({
  getFullName: (): string => {
    const name = person?.name?.[0];
    return capitalizeWords(
      trimMultipleWhiteSpaces(`${name?.given?.join?.(' ') ?? ''} ${name?.family ?? ''}`).trim()
    );
  },
});

export const wrapPerson = createWrapper<Person, ReturnType<typeof personWrapperCallback>>(
  personWrapperCallback
);

export type WrappedPerson = ReturnType<typeof wrapPerson>;
