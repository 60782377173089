import { IconButtonProps, IconButton, SvgIconProps } from '@mui/material';
import {
  FiberManualRecord as FiberManualRecordIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';

export interface FilterIconButtonProps extends IconButtonProps {
  iconProps?: SvgIconProps;
  isFiltering?: boolean;
}

const FilterIconButton = ({ isFiltering = false, iconProps, ...props }: FilterIconButtonProps) => (
  <IconButton {...props}>
    {isFiltering && (
      <FiberManualRecordIcon
        sx={{ position: 'absolute', height: 18, width: 18, right: 3, top: 3 }}
        htmlColor="#1a73e8"
      />
    )}
    <FilterListIcon htmlColor="#919eab" sx={{ height: 30, width: 30 }} {...iconProps} />
  </IconButton>
);

export default FilterIconButton;
