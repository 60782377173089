import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import client from './_client';

const baseUrl = '/fhirutil';

export const getResourceUniqueIdentifierPayload = async (
  resourceType
): Promise<Identifier | null> => {
  try {
    const { data } = await client.get<Identifier | null>(`${baseUrl}/resourceUniqueIdentifier`, {
      params: {
        resourceType,
      },
    });
    return data;
  } catch (error) {
    return null;
  }
};
