import useLocales from 'src/hooks/useLocales';
import CustomButton, { CustomButtonProps } from './CustomButton';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface CancelButtonProps extends CustomButtonProps {}

const CancelButton = ({ children, sx, ...buttonProps }: CancelButtonProps) => {
  const { i18n } = useLocales();
  return (
    <CustomButton
      sx={[
        {
          color: '#212b36',
          width: 78,
          border: 0,
          mr: 1.2,
          '&:hover': { border: 0 },
        },
        ...spreadSxProp(sx),
      ]}
      variant="outlined"
      color="inherit"
      {...buttonProps}
    >
      {children ? children : i18n('cancel')}
    </CustomButton>
  );
};

export default CancelButton;
