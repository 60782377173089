import { MouseEventHandler, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import Iconify from 'src/components/Iconify';

export interface CollapsibleCardProps {
  title: string;
  isOpenExternal?: boolean;
  headerButtonComponent?: React.ReactNode;
  headerButtonTitle?: string;
  onHeaderButtonPress?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  titleSx?: SxProps;
  titleVariant?: TypographyProps['variant'];
  margin?: number;
}

const CollapsibleCard = ({
  title,
  children,
  headerButtonComponent,
  headerButtonTitle,
  onHeaderButtonPress,
  titleSx,
  titleVariant,
  isOpenExternal = false,
  margin
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useState(isOpenExternal);
  useEffect(() => {
    if (isOpenExternal === undefined) return;
    setIsOpen(isOpenExternal);
  }, [isOpenExternal]);

  return (
    <Card>
      <Stack sx={{ m: margin ? margin : 4 }}>
        <Grid container display={'flex'}>
          <Grid item xs={headerButtonComponent ? 8 : 10}>
            <Typography sx={titleSx} variant={titleVariant}>
              {title}
              <IconButton onClick={() => setIsOpen((currIsOpen) => !currIsOpen)}>
                <Iconify
                  icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </IconButton>
            </Typography>
          </Grid>
          {headerButtonComponent}
          {!!headerButtonTitle && !headerButtonComponent && (
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button size="small" sx={{ height: '36px' }} onClick={onHeaderButtonPress}>
                {headerButtonTitle}
              </Button>
            </Grid>
          )}
        </Grid>
        <Collapse in={isOpen}>{children}</Collapse>
      </Stack>
    </Card>
  );
};

export default CollapsibleCard;
