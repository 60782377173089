import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { BACKEND_PREFIX_ROUTE } from 'src/config';
import { getBackEndBaseUrl, getFrontendBaseUrl } from 'src/utils/domain-utils';

interface ITenantProviderContextData {
  tenant: string;
  frontendBaseUrl: string;
  backendBaseUrl: string;
  ingressDomain: string;
  isLoading: boolean;
}

const initialValue: ITenantProviderContextData = {
  tenant: 'MAIN',
  frontendBaseUrl: 'http://replace.front.tenant1.com',
  backendBaseUrl: 'http://replace.back.tenant1.com',
  ingressDomain: 'http://replace.front.tenant1.com',
  isLoading: true,
};

const TenantProviderContext = createContext<ITenantProviderContextData>(initialValue);

export function useTenant() {
  const { ingressDomain, frontendBaseUrl, backendBaseUrl, isLoading } =
    useContext<ITenantProviderContextData>(TenantProviderContext);
  return { ingressDomain, frontendBaseUrl, backendBaseUrl, isLoading };
}

interface ITenantProviderProps {
  children: ReactNode;
  meta?: ReactNode;
}

export default function TenantProvider({ children, meta }: ITenantProviderProps) {
  const [ingressDomain, setIngressDomain] = useState(initialValue.ingressDomain);
  const [frontendBaseUrl, setFrontendBaseUrl] = useState(initialValue.frontendBaseUrl);
  const [backendBaseUrl, setBackendBaseUrl] = useState(initialValue.backendBaseUrl);
  const [isLoading, setIsLoading] = useState(true);

  async function getBackEndDomain() {
    const backEndUrl = getBackEndBaseUrl();
    // const response = await HttpAgent.Domain.getBackendUrl(domain);

    // if (response['backend_base_url']) {
    //   setBackendBaseUrl(response['backend_base_url']);
    // }

    setBackendBaseUrl(backEndUrl);
  }

  function loadDomain() {
    const domain = window.location.hostname;
    console.debug('Tenant domain', domain);
    setIngressDomain(domain);

    const fBaseUrl = getFrontendBaseUrl();
    console.debug('Tenant frontendBaseUrl', fBaseUrl);
    setFrontendBaseUrl(fBaseUrl);
  }

  useEffect(() => {
    loadDomain();
    getBackEndDomain();
  }, []);

  useEffect(() => {
    if (
      frontendBaseUrl !== initialValue.frontendBaseUrl &&
      backendBaseUrl !== initialValue.backendBaseUrl
    ) {
      setIsLoading(false);
    }
  }, [frontendBaseUrl, backendBaseUrl]);

  return (
    <TenantProviderContext.Provider
      value={{
        ...initialValue,
        ingressDomain: ingressDomain,
        frontendBaseUrl: frontendBaseUrl,
        backendBaseUrl,
        isLoading,
      }}
    >
      <div>{children}</div>
    </TenantProviderContext.Provider>
  );
}
