// @ts-nocheck
import { Numbers as NumbersIcon } from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { HTMLInputTypeAttribute, useEffect, useMemo, useState } from 'react';
import { Registry, RegistryInputMode, RegistryValueType } from 'src/services/api/registry';
import { capitalize } from 'src/utils/string';

export type onChange = (value: ValueType, valueType: RegistryValueType | null) => void;

interface RegistryComponentProps {
  label: string;
  registries: Registry[];
  valueSet: ValueSetComposeIncludeConcept[];
  keyRegistry: string;
  disabled?: boolean;
  onValueChange?: onChange;
  error: string | null;
  initialValue?: ValueType;
  onRegistryLoad?: (valueType: RegistryValueType | null) => void;
}

export type ValueType = Option | string | number | null;

interface Option {
  code: string | number;
  display: string;
  system: string;
  category_code?: string;
}

interface ObservationRegistryValue {
  options: Option[];
}

const RegistryComponent = ({
  registries,
  keyRegistry,
  valueSet,
  label,
  disabled = false,
  onValueChange,
  onRegistryLoad,
  initialValue,
  error,
}: RegistryComponentProps) => {
  const [registry, setRegistry] = useState<Registry | null>(null);
  const [value, setValue] = useState<ValueType>('');

  const inputValueType = getInputTypeByValueType(registry?.valueType ?? RegistryValueType.STRING);

  const isNumericInput = inputValueType === 'number';

  useEffect(() => {
    const registryInputValues = registries?.find((e) => e?.keyRegistry === keyRegistry);
    const valueSetRecordsFilterByKeyRegistry = valueSet?.filter(
      ({ system: kr }) => kr.split('/').pop() === keyRegistry
    );
    const registry = {
      valueType: registryInputValues?.valueType,
      keyValue: {
        options: valueSetRecordsFilterByKeyRegistry,
      },
      inputMode: registryInputValues?.inputMode,
    } as Registry;

    setRegistry(registry ?? null);
  }, [registries, keyRegistry]);

  useEffect(() => {
    if (!registry) {
      return;
    }

    onRegistryLoad(registry.valueType);
  }, [registry]);

  useEffect(() => {
    if (!registry || !initialValue) {
      return;
    }

    handleValueChange(initialValue);
  }, [registry, initialValue]);

  useEffect(() => {
    if (isNumericInput) {
      setValue(0);
      return;
    }
    setValue('');
  }, [registry]);

  useEffect(() => {
    onValueChange?.(value, registry?.valueType ?? null);
  }, [value]);

  const options = useMemo((): Option[] => {
    try {
      if (!registry?.keyValue) throw new Error('Invalid Value');
      const parsedValue = registry?.keyValue as ObservationRegistryValue;
      if (!parsedValue?.options?.length) return [];
      return parsedValue?.options;
    } catch (error) {
      return [];
    }
  }, [registry]);

  const handleValueChange = (value: ValueType) => {
    if (isEmpty(value)) return setValue('');

    if (registry?.valueType === RegistryValueType.CodeableConcept) {
      if (typeof value === 'string' || typeof value === 'number') {
        let option = options.find(({ code }) => code === value);
        if (!option) {
          option = { code: value, display: capitalize(String(value) ?? '') };
        }
        setValue(option);
      }
      return;
    }
    if (typeof value === 'object') return setValue(value.code);
    setValue(value);
  };

  return (
    <>
      {registry?.inputMode === RegistryInputMode.SELECTION && options.length ? (
        <FormControl fullWidth error={!!error}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={typeof value === 'object' ? value?.code : ''}
            onChange={(event) => {
              handleValueChange(event.target.value);
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Autocomplete
          disabled={disabled}
          fullWidth
          freeSolo={registry?.inputMode === RegistryInputMode.MANUAL || !registry?.inputMode}
          value={value ?? ''}
          options={options}
          onChange={(_, value) => {
            handleValueChange(value);
          }}
          onInputChange={(_, value) => {
            handleValueChange(value);
          }}
          getOptionLabel={(data: ValueType) => {
            if (typeof data === 'string') return data;
            if (typeof data === 'number') return String(data);
            return data?.display ?? '';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                type: inputValueType,
                startAdornment: (
                  <>
                    {isNumericInput && (
                      <Tooltip title={'Must enter numeric integer value'}>
                        <NumbersIcon />
                      </Tooltip>
                    )}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              error={!!error}
            />
          )}
        />
      )}
    </>
  );
};

const getInputTypeByValueType = (valueType: string): HTMLInputTypeAttribute => {
  switch (valueType) {
    case RegistryValueType.NUMBER:
      return 'number';
    default:
      return 'text';
  }
};

export default RegistryComponent;
