import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Stack,
  TextField,
  Typography,
  Accordion,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { AccordionDetails } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { useGroups, usePatients } from 'src/@nicheaim/fhir-react';
import { fSearchPatient } from 'src/utils/formatTime';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { GroupWrapper } from 'src/@nicheaim/fhir-base/wrappers/Group';
import useLocales from 'src/hooks/useLocales';

type FormValue = {
  lastName: string;
  firstName: string;
  dateOfBirth: Date;
  identifier: string;
  middleName: string;
  race: string;
  addressone: string;
  addresstwo: string;
  zipCode: string;
  city: string;
  state: string;
};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  onClose: VoidFunction;
  id: string;
};

export default function AddNewMemberGroup({ id, patient, open, onClose }: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [family, setFamily] = useState('')
  const [given, setGiven] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [autofetch, setAutoFetch] = useState(false)
  const [booleanSearch, setBooleanSearch] = useState(false)
  const [ethnicityCategories] = useValueSet('omb-ethnicity-category', { map: ValueSetWrapper });
  const [patientsListSearch, { isLoading }] = usePatients({ 
    filter: { given: `${given}`, family: `${family}`, birthdate: `${birthdate}` },
    autofetch: autofetch 
  });
  const { i18n } = useLocales();

  const [, { patch: updateGroup }] = useGroups({ map: GroupWrapper });
  const defaultValues = useMemo(
    () =>
    ({
      lastName: '',
      firstName: '',
      dateOfBirth: new Date(),
      identifier: '',
      middleName: '',
      race: '',
      addressone: '',
      addresstwo: '',
      zipCode: '',
      state: '',
      city: '',
    } as FormValue),
    [patient]
  );

  const EventSchema = Yup.object().shape({
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[a-zA-Z\s-]+$/, 'Last Name cannot contain numbers'),
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[a-zA-Z\s]+$/, 'First Name cannot contain numbers'),
    dateOfBirth: Yup.date()
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setError } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    onClose();
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'firstName',
      headerName: 'First Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row?.name[0].given[0]}`,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row?.name[0].family}`,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row?.birthDate}`,
    },
  ];
  const onSubmit = async (data: FormValue) => {
    try {
      setFamily(data.lastName)
      setBirthdate(fSearchPatient(data.dateOfBirth))
      setGiven(data.firstName)
      setAutoFetch(true)
      setBooleanSearch(true);
      enqueueSnackbar('Seaching');
      console.log(patientsListSearch);
    } catch {
      handleClose();
      enqueueSnackbar('Patient was not updated.', { variant: 'error' });
    }
  };
  const updateGroupFunction = async () => {
    try {

      await updateGroup(

        [
          {
            id: `${id}`,
            patches: [
              {
                op: 'add', path: ['member/-'], value: {
                  entity: {
                    id: "627df988-d1b1-40cd-8a98-6ffdbc1e93f6",
                    reference: `Patient/${selectedRowId}`,
                    type: "Patient"
                  },
                  period: {
                    end: "2023-07-31T07:00:00.000Z"
                  }
                }

              }


            ],
          }
        ]
      );
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was updated.`);
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };
  if (!open) return null;
  const handleRowSelection = (params: any) => {
    if (params.id == selectedRowId) {
      setSelectedRowId(null)
    } else {
      setSelectedRowId(params.id);
    }
  };
  return (
    <Dialog keepMounted={false} open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      {booleanSearch === false ? <>
          <DialogTitle>{i18n('patients.details.titleAddMember', 'crs')}</DialogTitle>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ m: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Stack spacing={2} sx={{ p: 2 }}>

                    <Typography variant="body2">{i18n('patients.lastName', 'crs')}*</Typography>
                    <RHFTextField name="lastName" label={i18n('patients.details.groups.typeLastName', 'crs')} />

                    <Typography variant="body2">{i18n('patients.firstName', 'crs')}*</Typography>
                    <RHFTextField name="firstName" label={i18n('patients.details.groups.typeFirstName', 'crs')} />

                    <Typography variant="body2">{i18n('patients.dateOfBirth', 'crs')}*</Typography>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <MobileDatePicker
                          {...field}
                          label=""
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error?.message}
                              helperText={error?.message}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        View Lest
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        <Stack spacing={2} sx={{ p: 0 }}>
                          <Grid style={{ display: "flex" }} item xs={12}>
                            <Grid item xs={6}>
                              <Typography variant="body2">{i18n('patients.details.groups.identifier', 'crs')}</Typography>
                              <Stack spacing={2} sx={{ p: 1 }}>
                                <RHFSelect style={{ width: "80%" }} name="identifier" label="Select One">
                                  <MenuItem disabled></MenuItem>
                                  {ethnicityCategories?.asList().map((option) => (
                                    <MenuItem key="" value={option.display}>
                                      {option.display}
                                    </MenuItem>
                                  ))}
                                </RHFSelect>
                              </Stack>

                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">{i18n('patients.details.groups.value', 'crs')}</Typography>
                              <Stack spacing={2} sx={{ p: 1 }}>
                                <RHFTextField name="identifier" label={i18n('patients.details.groups.groupCode', 'crs')} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Typography variant="body2">{i18n('patients.middleName', 'crs')}</Typography>
                          <RHFTextField name="middleName" label={i18n('patients.details.groups.typeMiddleName', 'crs')} />



                          <Typography variant="body2">{i18n('patients.details.groups.race', 'crs')}</Typography>
                          <RHFSelect name="race" label="Select One">
                            <MenuItem disabled></MenuItem>
                            {ethnicityCategories?.asList().map((option) => (
                              <MenuItem key={option.code} value={option.display}>
                                {option.display}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                          <Typography variant="body2">{i18n('patients.details.groups.addressOne', 'crs')}</Typography>
                          <RHFTextField name="addressone" label={i18n('patients.details.groups.typeAddressOne', 'crs')} />
                          <Typography variant="body2">{i18n('patients.details.groups.addressTwo', 'crs')}</Typography>
                          <RHFTextField name="addresstwo" label={i18n('patients.details.groups.typeAddressTwo', 'crs')} />
                          <Grid style={{ display: "flex" }} item xs={12}>
                            <Grid item xs={4}>
                              <Typography variant="body2">{i18n('patients.details.groups.zipCode', 'crs')}</Typography>
                              <Stack spacing={2} sx={{ p: 1 }}>
                                <RHFTextField name="zipCode" label={i18n('patients.details.groups.typeZipCode', 'crs')} />
                              </Stack>

                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">{i18n('patients.details.groups.city', 'crs')}</Typography>
                              <Stack spacing={2} sx={{ p: 1 }}>
                                <RHFSelect style={{ width: "105%" }} name="city" label="Select One">
                                  <MenuItem disabled></MenuItem>
                                  {ethnicityCategories?.asList().map((option) => (
                                    <MenuItem key="" value={option.display}>
                                      {option.display}
                                    </MenuItem>
                                  ))}
                                </RHFSelect>
                              </Stack>

                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">{i18n('patients.details.groups.state', 'crs')}</Typography>
                              <Stack spacing={2} sx={{ p: 1 }}>
                                <RHFSelect style={{ width: "105%" }} name="state" label="Select One">
                                  <MenuItem disabled></MenuItem>
                                  {ethnicityCategories?.asList().map((option) => (
                                    <MenuItem key="" value={option.display}>
                                      {option.display}
                                    </MenuItem>
                                  ))}
                                </RHFSelect>
                              </Stack>

                            </Grid>
                          </Grid>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>


                  </Stack>
                </Grid>
              </Grid>
              <Stack spacing={2} alignItems="center">
                <DialogActions>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button variant="contained" color="info" onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button variant="contained" color="info" type="submit">
                    Search
                  </Button>

                </DialogActions>
              </Stack>
            </Card>
          </FormProvider>
        </> : <>
          <DialogTitle>Result Search</DialogTitle>
          <Card style={{ margin: "30px" }}>
            <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={patientsListSearch}
                loading={isLoading}
                columns={columns}
                onCellClick={handleRowSelection}
                selectionModel={selectedRowId ? [selectedRowId] : []}
                checkboxSelection
                initialState={{
                  pagination: {
                    pageSize: 5,
                  },
                }}
              />
            </Box>
            <Stack spacing={2} alignItems="center">
              <DialogActions>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" color="info" onClick={() => {
                  setBooleanSearch(false);
                }}>
                  Cancel
                </Button>

                <Button disabled={selectedRowId == null} variant="contained" color="info" onClick={updateGroupFunction}>
                  Accept
                </Button>

              </DialogActions>
            </Stack>
          </Card>
        </>
      }
    </Dialog>
  );
};