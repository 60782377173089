import { IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';

const EditComponent = (props: any) => {
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
  } = props?.others;
  
  const items = props?.others?.data && props?.others?.data.length > 0 ? props?.others?.data : [{ title: '', value: '' }];

  return (
    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
      <Stack direction="column" alignItems="flex-start">
        {items?.map((e) => (
         <Stack direction="row" key={uuidv4()}>
            <Typography sx={{ fontSize: 12 }}>{e?.title}: </Typography>&nbsp;
            <span style={{ fontWeight: 'bold', color: 'green', fontSize: 12 }}>{e?.value}</span>
          </Stack>
        ))}
      </Stack>
      <Stack direction="column" alignItems="center">
        <IconButton 
          size='small'
          onClick={ () =>
            props?.others?.handler({
            record,
            checklistItem,
            refreshChecklistHandler,
            refreshRecordHandler,
          }) }
        >
          <Iconify icon="eva:edit-outline" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default EditComponent;