import { useEffect, useState } from 'react';
import { Autocomplete, IconButton, Stack, TextField, Typography } from "@mui/material";
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';

const CustomSelectComponent = (props: any) => {
  const items = props?.others?.data && props?.others?.data.length > 0 ? props?.others?.data.map((i: any) => ({ label: `${i?.firstName} ${i?.lastName}`, id: i?.userName, })) : [{ label: '', id: '' }];
  const [inputValue, setInputValue] = useState('');
  const [edit, setEdit] = useState(false);
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
  } = props?.others;
  
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    const getValue: any = items?.find((e) => e?.id === record?.workflowInstance?.ownedBy);
    setValue(getValue)
  }, [record]);

  return (
    <>
      {!edit ? (
        <Stack direction="row">
          <Stack direction="column" alignItems="flex-start">
            <Stack direction="row">
              <Typography sx={{ fontSize: 12 }}>Assigned Owner:</Typography>&nbsp;
              <span style={{ fontWeight: 'bold', color: 'green', fontSize: 12 }}>{value?.label}</span>
            </Stack>
          </Stack>
          <Stack direction="column" alignItems="center">
            <IconButton 
              size='small'
              onClick={ () => setEdit(true)}
            >
              <Iconify icon="eva:edit-outline" />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-demo"
            value={value}
            inputValue={inputValue}
            onInputChange={(event: any, newInputValue: any) => {
              setInputValue(newInputValue);
            }}
            options={items}
            renderInput={(params) => <TextField {...params} fullWidth label={props?.name || props?.elementName} />}
            renderOption={(props: any, option: any) => (
              <li {...props} key={uuidv4()}>
                {option?.label}
              </li>
            )}
            onChange={(event: any, username: any | null) => {
              if(!username) return;
              setValue(username);
              setEdit(false);
              props?.others?.handler({
                record,
                checklistItem,
                refreshChecklistHandler,
                refreshRecordHandler,
                more: { selectedUser: username?.id },
              })
            }}
          />
          <Stack direction="column" alignItems="center">
            <IconButton 
              size='small'
              onClick={ () => setEdit(false)}
            >
              <Iconify icon="material-symbols:close-rounded" />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CustomSelectComponent;
