/* eslint-disable */
import { 
  MenuItem, 
  TableCell, 
  TableRow
} from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { Registry } from 'src/services/api/registry';

type Props = {
  row: Registry;
  handleSetting: (row: Registry) => any;
};

export default function Row({ row, handleSetting }: Props) {

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleRow = (row: any) => {
    handleSetting(row);
  };

  return (
    <>
      <TableRow >
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.keyRegistry}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.groupIdentifier}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.keyDescription}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.createdOn && (format(new Date(row.createdOn), 'MMM dd, yyyy'))}
        </TableCell>
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={[
                <MenuItem onClick={() => handleRow(row)}>
                  <Iconify icon={'mdi:pencil'} />
                  Edit
                </MenuItem>
              ]}
            />
          </TableCell>
      </TableRow>
    </>
  );
};
