import { useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import EditableCard from '../EditableCard';
import { MutationActions, Person, User } from '../../types';
import { getFormattedPersonDOB, getPersonNames } from '../../utils/personHelpers';
import { capitalize } from 'lodash';

import { useSnackbar } from 'notistack';
import useFirebaseCollectionMutation from '../../hooks/useFirebaseCollectionMutation';
import { collectionNames } from '../../utils/constants';
import { ContactPoint } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getFormattedDatePeriod } from 'src/utils/dates';
import { getTelecomBySystem, getTelecomsBySystem, getTelecomsSystems } from 'src/utils/fhir';
import {
  EditMode as ContactDetailsDialog,
  OnTelecomUpdate,
} from 'src/sections/crs/patient/components/details/ContactDetails';

export interface PersonContactDetailsProps {
  person: Person | null;
  onPersonSave?: VoidFunction;
}

const PersonContactDetails = ({ person, onPersonSave }: PersonContactDetailsProps) => {
  const [isContactDetailsDialogOpen, setIsContactDetailsDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: mutatePerson } = useFirebaseCollectionMutation<Person>({
    collectionName: collectionNames.PERSONS,
  });
  const systems = getTelecomsSystems(person?.telecom);

  const handleOnTelecomUpdate: OnTelecomUpdate = useCallback(
    async (newTelecom) => {
      const telecom = [...newTelecom].map(({ system, use, rank, value, period }) => ({
        ...(system !== undefined ? { system } : {}),
        ...(use !== undefined ? { use } : {}),
        ...(rank !== undefined ? { rank } : {}),
        ...(value !== undefined ? { value } : {}),
        ...(period !== undefined ? { period } : {}),
      }));
      try {
        await mutatePerson({
          data: { telecom: [...telecom] },
          action: MutationActions.MODIFY,
          id: person?.id as string,
        });
        enqueueSnackbar('Contact Details updated succesfully', {
          variant: 'success',
        });
        onPersonSave?.();
        setIsContactDetailsDialogOpen(false);
        return true;
      } catch (error) {
        enqueueSnackbar(
          'There was an issue while trying to update the Contact Details . If the problem persist please contact support',
          {
            variant: 'error',
          }
        );
        return false;
      }
    },
    [person, mutatePerson, enqueueSnackbar, onPersonSave]
  );

  return (
    <>
      <EditableCard
        title="Contact Details"
        onEditButtonPress={() => {
          setIsContactDetailsDialogOpen(true);
        }}
      >
        {systems.map((system) => (
          <ContactInfoItem
            key={`${system}-container`}
            system={system}
            telecom={person?.telecom ?? []}
          />
        ))}
      </EditableCard>
      <ContactDetailsDialog
        emailPermissions={{
          isAllowedToAdd: true,
          isAllowedToDelete: true,
        }}
        phonePermissions={{
          isAllowedToAdd: true,
          isAllowedToDelete: true,
        }}
        isAllowedToEdit={true}
        open={isContactDetailsDialogOpen}
        telecom={person?.telecom ?? []}
        onClose={() => {
          setIsContactDetailsDialogOpen(false);
        }}
        onTelecomUpdate={handleOnTelecomUpdate}
      />
    </>
  );
};

export interface ContactInfoItemProps {
  system: ContactPoint['system'];
  telecom: ContactPoint[];
}

export const ContactInfoItem = ({ system, telecom }: ContactInfoItemProps) => {
  const telecomsBySystem = getTelecomsBySystem(telecom, system as string);
  return (
    <Grid container spacing={3} sx={{ marginBottom: 2.5 }}>
      <Grid item xs={12} sx={{ margin: 0 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', fontWeight: 'bold', textTransform: 'capitalize' }}
        >
          {system}
        </Typography>
      </Grid>
      {telecomsBySystem.map(({ value, use, period }, index) => (
        <Grid
          key={`${system}-${index}-item`}
          item
          xs={6}
          sx={{ marginTop: 1, '&.MuiGrid-item': { paddingTop: 0 } }}
        >
          <Typography variant="body2" color="#637381" sx={{ textTransform: 'capitalize' }}>
            {use ?? 'work'}
          </Typography>

          <Typography variant="body2" color="#000000">
            {value ?? ''}
          </Typography>

          <Typography variant="body2">{getFormattedDatePeriod(period)}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PersonContactDetails;
