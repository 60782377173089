import { Grid, Button, SxProps, Box, Typography } from '@mui/material';
import CustomDrawer, { CustomDrawerProps } from './CustomDrawer';
import { spreadSxProp } from 'src/utils/cssStyles';
import useLocales from 'src/hooks/useLocales';

export interface FilterDrawerProps extends CustomDrawerProps {
  onApplyButtonClick: Function;
  onClearAllButtonClick: Function;
  buttonsContainerSx?: SxProps;
}

const FilterDrawer = ({
  onApplyButtonClick,
  onClearAllButtonClick,
  children,
  buttonsContainerSx,
  ...props
}: FilterDrawerProps) => {
  const { i18n } = useLocales();
  return (
    <CustomDrawer {...props}>
      <>
        {children}
        <Grid container marginTop={3} justifyContent={'space-between'}>
          <Grid item xs={5.8}>
            <Button
              sx={{ height: 48 }}
              fullWidth
              onClick={() => {
                onApplyButtonClick();
              }}
              variant="contained"
            >
              {i18n('apply')}
            </Button>
          </Grid>
          <Grid item xs={5.8} marginBottom={3}>
            <Button
              fullWidth
              sx={{ height: 48, color: '#212b36', borderColor: 'rgba(145, 158, 171, 0.32)' }}
              onClick={() => {
                onClearAllButtonClick();
              }}
              variant="outlined"
            >
              {i18n('clearAll')}
            </Button>
          </Grid>
        </Grid>
      </>
    </CustomDrawer>
  );
};

export interface FilterContainerProps {
  children: React.ReactNode;
  label: string;
  sx?: SxProps;
}
export const FilterContainer = ({ children, label, sx }: FilterContainerProps) => (
  <Box sx={[{ marginBottom: 2, width: '100%' }, ...spreadSxProp(sx)]}>
    <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1}>
      {label}
    </Typography>
    {children}
  </Box>
);

export default FilterDrawer;
