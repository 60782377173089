import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  List,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  ListItem,
  Link,
} from '@mui/material';
// utils
import useLocales from 'src/hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function SettingsPopover() {
  const { i18n: __ } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen: React.MouseEventHandler<HTMLElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Iconify icon="ci:settings-filled" width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 490, py: 2, px: 2.5, mt: 1.5, ml: 0.75, display: 'flex' }}
      >
        <SettingsSection title={__('Permission Settings')}>
          <SettingsLink to="/settings/permissions" label={__('Permissions')} />
          <SettingsLink to="/settings/roles" label={__('Roles')} />
          <SettingsLink to="/settings/views" label={__('Views')} />
        </SettingsSection>

        <SettingsSection title={__('Configuration Settings')}>
          <SettingsLink
            to="/settings/care-plan-automation"
            label={__('Manage Care Plan Settings')}
          />
          <SettingsLink
            to="/settings/activity-list-automation"
            label={__('Manage Activity List')}
          />
          <SettingsLink to="/settings/assessments" label={__('Manage Assessments')} />
          <SettingsLink to="/settings/clients" label={__('Manage Clients')} />
          <SettingsLink to="/settings/programs" label={__('Manage Program Settings')} />
          <SettingsLink to="/settings/care-member" label={__('Manage Care Member')} />
          <SettingsLink to="/settings/care-member/assign" label={__('Assign Care Member')} />
          <SettingsLink to="/settings/script" label={__('Manage Script')} />
          <SettingsLink to="/settings/provider" label={__('Manage Provider')} />
          <SettingsLink to="/settings/template" label={__('Manage Template')} />
          <SettingsLink to="/settings/consent-forms" label={__('Manage Consent Forms')} />
          <SettingsLink to="/settings/census" label={__('Manage Census')} />
          <SettingsLink to="/settings/users-organization" label={__('Manage Users Organization')} />
        </SettingsSection>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

interface SettingsSectionProps {
  title: string;
  children: React.ReactNode;
}

function SettingsSection({ title, children }: SettingsSectionProps) {
  return (
    <List sx={{ flexGrow: 1 }}>
      <ListSubheader>
        <ListItemIcon sx={{ width: '1rem' }}>&mdash;</ListItemIcon>
        <Typography variant="overline" color="black">
          {title}
        </Typography>
      </ListSubheader>

      {children}
    </List>
  );
}

// ----------------------------------------------------------------------

interface SettingsLinkProps {
  to: string;
  label: string;
}

function SettingsLink({ to, label }: SettingsLinkProps) {
  return (
    <ListItem>
      <ListItemIcon sx={{ width: '1rem' }}>&bull;</ListItemIcon>
      <Link
        component={RouterLink}
        to={to}
        sx={{
          color: 'GrayText',
        }}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            variant: 'body2',
          }}
        />
      </Link>
    </ListItem>
  );
}
