import { CommonFields } from 'src/sections/careflow/permissions/types';
import client from './_client';
import { AxiosResponse } from 'axios';

export enum RegistryValueType {
  STRING = 'string',
  NUMBER = 'number',
  CodeableConcept = 'codeableConcept',
}

export enum RegistryInputMode {
  MANUAL = 'manual',
  SELECTION = 'selection',
}

export interface RegistryFilter {
  keyRegistry?: string;
  groupIdentifier?: string;
}

export interface Registry extends Partial<CommonFields> {
  keyRegistry: string;
  keyValue: string;
  groupIdentifier: string;
  inputMode: RegistryInputMode | null;
  valueType: RegistryValueType | null;
  keyDescription: string;
}

const baseUrl = '/registry';

export const getRegistries = async (
  groupIdentifier?: string,
  keyRegistry?: string
): Promise<AxiosResponse<Registry[]> | never> => {
  const searchParams = new URLSearchParams();
  groupIdentifier && searchParams.append('groupIdentifier', groupIdentifier);
  keyRegistry && searchParams.append('keyRegistry', keyRegistry);

  const response = await client.get(baseUrl, { params: searchParams });
  return response;
};

export const createRegistry = async (
  payload: Registry,
): Promise<AxiosResponse<Registry> | never> => {
  const response = await client.post(baseUrl, payload);
  return response;
}

export const updateRegistry = async (
  payload: Registry,
): Promise<AxiosResponse<Registry> | never> => {
  const response = await client.patch(`${baseUrl}/${payload?.uuid}`, payload);
  return response;
}