import { useState } from 'react';
import { isEmpty } from 'lodash';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { FormProvider } from 'src/components/hook-form';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { fToNow, parseShortFormat } from 'src/utils/formatTime';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { WrappedCommunication } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Stack,
  Tab,
  Typography,
} from '@mui/material';

type FormValues = {
  sms: boolean;
  email: boolean;
};

type Props = {
  patient: WrappedPatient | null;
  communication: WrappedCommunication[] | null;
  serviceRequest: WrappedServiceRequest | null;
  handleCreateCommunication: (data: any) => Promise<void>;
  open: boolean;
  onCancel: VoidFunction;
};

export default function ConsentForm({
  patient,
  communication,
  serviceRequest,
  handleCreateCommunication,
  open,
  onCancel,
}: Props) {
  const user = useAuth().getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('1');

  const [participationMode] = useValueSet('v3-ParticipationMode', { map: ValueSetWrapper });
  const [categoryForm] = useValueSet('ph-workflow-step-form', { map: ValueSetWrapper });
  const [isLoading, setIsLoading] = useState(false);

  const categoryCoding = convertValueToValueSet('Consent Form', categoryForm);

  const defaultValues = {
    sms: false,
    email: false,
  };

  const methods = useForm({ defaultValues });

  const { control, handleSubmit, reset } = methods;

  const mapFormDataToCommunication = (data: any) => {
    const { sms, email } = data;

    const smsCoding = {
      coding: [convertValueToValueSet('SMSWRIT', participationMode)],
      text: convertValueToValueSet('SMSWRIT', participationMode)?.display,
    };

    const emailCoding = {
      coding: [convertValueToValueSet('EMAILWRIT', participationMode)],
      text: convertValueToValueSet('EMAILWRIT', participationMode)?.display,
    };

    let OptionSelected = {};

    if (sms && email) {
      OptionSelected = [smsCoding, emailCoding];
    } else if (email) {
      OptionSelected = [emailCoding];
    } else if (sms) {
      OptionSelected = [smsCoding];
    }

    return {
      resourceType: 'Communication',
      status: 'in-progress',
      partOf: [
        {
          reference: `ServiceRequest/${serviceRequest?.id}`,
        },
      ],
      subject: {
        reference: `Patient/${patient?.id}`,
        display: patient?.getFullName(),
      },
      category: [
        {
          coding: [categoryCoding],
          text: categoryCoding?.display,
        },
      ],
      medium: OptionSelected,
      sent: new Date().toISOString(),
      sender: {
        ...(!isEmpty(user.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(user.user_fhir_uri)}`,
        }),
        display: user.name,
      },
    };
  };

  const handleClose = () => {
    reset(defaultValues);
    onCancel();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      const resultMap = mapFormDataToCommunication(data);
      await handleCreateCommunication(resultMap);
      handleClose();
      setIsLoading(false);
      enqueueSnackbar('Resend Consent Form was created.');
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Resend Consent Form was not created.', { variant: 'error' });
    }
  };

  const primaryPhone = patient?.getPrimaryPhone()?.value;
  const primaryEmail = patient?.getPrimaryEmail()?.value;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Resend Consent Form</DialogTitle>
      <Card sx={{ m: 2 }}>
        <TabContext value={value}>
          <Box sx={{ px: 3 }}>
            <TabList onChange={(e, value) => setValue(value)}>
              <Tab disableRipple value="1" label="Resend" />
              <Tab
                disableRipple
                value="2"
                label="Activity Log"
                sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
              />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ p: 2 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="body2" sx={{ my: 1 }}>
                Resend Consent Form?
              </Typography>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          disabled={primaryEmail === undefined}
                        />
                      )}
                    />
                  }
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography variant="inherit" sx={{ color: 'text.secondary' }}>
                        By EMAIL &nbsp;
                      </Typography>
                      {primaryEmail && (
                        <Typography variant="subtitle2">
                          ({patient?.getPrimaryEmail()?.value})
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </Stack>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Controller
                      name="sms"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          disabled={primaryPhone === undefined}
                        />
                      )}
                    />
                  }
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography variant="inherit" sx={{ color: 'text.secondary' }}>
                        By SMS &nbsp;
                      </Typography>
                      {primaryPhone && (
                        <Typography variant="subtitle2">
                          ({patient?.getPrimaryPhone()?.value})
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </Stack>

              {!primaryEmail && !primaryPhone && (
                <Stack direction="row" sx={{ mt: 0.7 }}>
                  <Typography variant="body2" paragraph>
                    <strong>Note: </strong> &nbsp;Please, in the patient details&nbsp;
                    <Link
                      to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)}
                      target="_blank"
                    >
                      <Iconify icon={'ic:outline-open-in-new'} width={16} height={16} />
                    </Link>
                    &nbsp; enter the contact details to perform this step.
                  </Typography>
                </Stack>
              )}

              <Stack spacing={2} alignItems="center">
                <DialogActions>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button variant="contained" color="info" onClick={handleClose}>
                    Cancel
                  </Button>
                  {(primaryPhone || primaryEmail) && (
                    <LoadingButton
                      variant="contained"
                      color="info"
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Submit
                    </LoadingButton>
                  )}
                </DialogActions>
              </Stack>
            </FormProvider>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 2 }}>
            <Scrollbar sx={{ height: 300, width: 500 }}>
              {communication
                ?.filter((e) => e?.category?.[0].text === 'Consent Form')
                .map((item, index) => (
                  <ActivityItem key={index} communicationItem={item} />
                ))}
            </Scrollbar>
          </TabPanel>
        </TabContext>
      </Card>
    </Dialog>
  );
}

type ActivityItemProps = {
  communicationItem: WrappedCommunication;
};

function ActivityItem({ communicationItem }: ActivityItemProps) {
  return (
    <Stack direction="row" spacing={2} sx={{ my: 2 }}>
      <Iconify width={32} height={32} color="#73C2FB" icon="eva:message-square-outline" />
      <Stack direction="column">
        <Stack direction="row">
          <Typography variant="body2" component="span">
            Consent Form sent via &nbsp;{communicationItem?.getMedium()}
          </Typography>
          <Typography variant="subtitle2" component="span">
            &nbsp;({communicationItem.status === 'completed' ? 'Viewed' : 'Not viewed'})
          </Typography>
        </Stack>
        <Typography variant="body2" component="span" noWrap sx={{ color: 'text.secondary' }}>
          {parseShortFormat(communicationItem?.sent || '')} &nbsp;(
          {fToNow(communicationItem.sent || '')} ago)
        </Typography>
      </Stack>
    </Stack>
  );
}
