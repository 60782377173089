import moment, { DurationInputArg2 } from 'moment';
import { ActivityDefinition } from 'src/@nicheaim/fhir-base/mappings/ActivityDefinition';
import { Task } from 'src/@nicheaim/fhir-base/mappings/Task';
import { fhirClient } from 'src/App';
import {
  Coding,
  TaskInput,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { getDynamicValueExpressionByPath } from 'src/utils/fhir';
import useValueSetsByIdentifiers from './useValueSetsByIdentifier';

export interface ICheckNTaskLinkerProps {
  patientId: string | null;
  activityDefinitionId: string | null;
}

export default function useCheckNTaskLinker({
  patientId,
  activityDefinitionId,
}: ICheckNTaskLinkerProps) {
  const {
    valueSets: [taskTypes, taskStatuses, taskIntents, taskPriorities],
  } = useValueSetsByIdentifiers([
    'ph-task-type-codes',
    'ph-task-status',
    'ph-task-intent',
    'ph-task-priority',
  ]);

  function getIntentFromStr(value: string | undefined) {
    if (!value) {
      return 'unknown';
    }

    switch (value) {
      case 'proposal':
        return 'proposal';
      case 'plan':
        return 'plan';
      case 'order':
        return 'order';
      case 'original-order':
        return 'original-order';
      case 'reflex-order':
        return 'reflex-order';
      case 'filler-order':
        return 'filler-order';
      case 'instance-order':
        return 'instance-order';
      case 'option':
        return 'option';
      default:
        return 'unknown';
    }
  }

  function getStatusFromStr(value: string | undefined) {
    if (!value) {
      return 'draft';
    }

    switch (value) {
      case 'draft':
        return 'draft';
      case 'requested':
        return 'requested';
      case 'received':
        return 'received';
      case 'accepted':
        return 'accepted';
      case 'rejected':
        return 'rejected';
      case 'ready':
        return 'ready';
      case 'cancelled':
        return 'cancelled';
      case 'in-progress':
        return 'in-progress';
      case 'on-hold':
        return 'on-hold';
      case 'failed':
        return 'failed';
      case 'completed':
        return 'completed';
      case 'entered-in-error':
        return 'entered-in-error';
      default:
        return 'draft';
    }
  }

  function getPriorityFromStr(value: string | undefined) {
    if (!value) {
      return undefined;
    }

    switch (value) {
      case 'routine':
        return 'routine';
      case 'urgent':
        return 'urgent';
      case 'stat':
        return 'stat';
      default:
        return 'asap';
    }
  }

  async function getActivityDefinition(activityDefinitionId: string) {
    let query = `/ActivityDefinition/${activityDefinitionId}`;
    let queryRes;

    try {
      queryRes = await fhirClient.get<any>(query);
    } catch (error) {
      console.error(error);
      throw new Error('failed to get Task data to get assessment');
    }

    if (!queryRes) {
      return undefined;
    }

    return queryRes as ActivityDefinition;
  }

  function getTaskDataBasedOnActivityDefinition(activityDefinition: ActivityDefinition): Task {
    const priority = getValueSetConceptValue(
      taskPriorities?.asList?.() ?? [],
      activityDefinition?.priority
    );
    const intent = getValueSetConceptValue(
      taskIntents?.asList?.() ?? [],
      getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.intent')
    );

    const status = getValueSetConceptValue(
      taskStatuses?.asList?.() ?? [],
      getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.status')
    );

    let taskStartDate: moment.Moment | null = null;
    let taskEndDate: moment.Moment | null = null;

    let startDate: moment.Moment | null = moment();

    if (activityDefinition?.timingDuration?.value && activityDefinition?.timingDuration?.unit) {
      taskStartDate = startDate?.isValid?.() ? startDate : moment();
      taskEndDate = taskStartDate
        .clone()
        .add(
          activityDefinition?.timingDuration?.value,
          activityDefinition?.timingDuration?.unit as DurationInputArg2
        );
    }

    let input: TaskInput[] = [];

    if (activityDefinition.relatedArtifact?.[0]) {
      const relatedArtifact = activityDefinition.relatedArtifact?.[0];
      const rAValuableCCCoding = relatedArtifact.extension?.[0].valueCodeableConcept?.coding;

      const taskInput: TaskInput = {
        type: {
          coding: rAValuableCCCoding,
        },
        valueUrl: relatedArtifact.url,
      };

      input.push(taskInput);
    }

    let newTask: Task = {
      resourceType: 'Task',
      intent: getIntentFromStr(intent?.code),
      status: getStatusFromStr(status?.code),
      priority: getPriorityFromStr(priority?.code),
      executionPeriod: { start: startDate.toISOString(), end: taskEndDate?.toISOString() },
      description: activityDefinition.description,
      input: input,
      instantiatesCanonical: 'ActivityDefinition/' + activityDefinition.id,
      code: activityDefinition.code,
    };

    return newTask;
  }

  async function createAssessmentTask(activityDefinitionId: string) {
    let task: Task = {
      resourceType: 'Task',
      intent: 'unknown',
      status: 'draft',
    };

    let activity = await getActivityDefinition(activityDefinitionId);

    if (activity) {
      task = getTaskDataBasedOnActivityDefinition(activity);
    }

    task.for = {
      reference: 'Patient/' + patientId,
    };

    try {
      const resp = await fhirClient.createOne('Task', task);
      return resp;
    } catch (err) {
      console.error('failed to create task', err);
    }
  }

  return { createAssessmentTask };
}
