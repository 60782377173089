import { Box } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import ConsentGrid from './Consent/ConsentGrid/ConsentGrid';

export interface PatientConsentsProps {
  patient: WrappedPatient | null;
}
const PatientConsents = ({ patient }: PatientConsentsProps) => (
  <Box sx={{ marginTop: 4 }}>
    <ConsentGrid patient={patient} title="patients.details.consents.title"/>
  </Box>
);

export default PatientConsents;
