import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Tooltip, Typography } from "@mui/material";
import { practitionerService } from "src/crs/practitioner";
import { trimMultipleWhiteSpaces } from "src/utils/string";
import { MatchedProvider, PCPIncidentSourceData } from "src/@types/crs/incident";
import { HowToReg, MedicalInformationOutlined, Person } from "@mui/icons-material";
import ProviderDetailPreviewDialog from "src/sections/crs/provider/new/ProviderDetailPreviewDialog";
import { DirectoryProvider, DirectoryProviderLookUp, OnProviderAsyncAction } from "src/@types/crs/providerDirectory";
import { IncidentResponse } from "src/services/api/data-quality/IncidentService";

export interface PCPMatchedProvidersGridProps {
  selectedProvider: DirectoryProvider | null;
  incident: IncidentResponse;
  onProviderSelect: OnProviderAsyncAction;
  isIncidentDispositionDone: boolean;
}

const MatchedProvidersGrid = ({
  selectedProvider,
  incident,
  onProviderSelect,
  isIncidentDispositionDone,
}: PCPMatchedProvidersGridProps) => {
  const [
    isProviderPreviewDialogOpen,
    setIsProviderPreviewDialogOpen,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    selectedMatchedProvider,
    setSelectedMatchedProvider,
  ] = useState<MatchedProvider | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [providers, setProviders] = useState<MatchedProvider[]>([]);

  useEffect(() => {
    const { matchedProviders } = (incident?.source_data ??
      {}) as PCPIncidentSourceData;
    const providers = matchedProviders?.length ? matchedProviders : [];
    if (selectedProvider && providers.length) {
      const firstProviderToShow = providers.find(
        ({ id }) => id === selectedProvider.uuid
      );
      if (firstProviderToShow) {
        setProviders([
          firstProviderToShow,
          ...providers.filter(({ id }) => id !== selectedProvider.uuid),
        ]);
        return;
      }
    }

    setProviders(providers);
  }, [selectedProvider, incident]);

  const handleMatchedProviderSelection = async (providerId: string) => {
    setIsLoading(true);
    const providerDetails = await practitionerService.getProviderDetails(providerId);
    setIsLoading(false);
    if (!providerDetails) return;
    setPage(0);
    onProviderSelect((providerDetails as unknown) as DirectoryProviderLookUp);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight={true}
        rowHeight={130}
        density="standard"
        className="providerList pointerRow"
        rows={providers}
        getRowId={({ id }) => id}
        onRowDoubleClick={(params) => {
          setIsProviderPreviewDialogOpen(true);
          setSelectedMatchedProvider(params.row as MatchedProvider);
        }}
        columns={MatchedProvidersGridColumns({
          isLoading,
          onProviderSelect: handleMatchedProviderSelection,
          selectedProviderId: selectedProvider?.uuid,
          showActionButton: !isIncidentDispositionDone,
        })}
        isRowSelectable={() => false}
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[5, 10, 25]}
        pageSize={rowsPerPage}
        page={page}
        onPageChange={(pageChange: any) => {
          setPage(pageChange.page);
        }}
        onPageSizeChange={(pageChange: any) => {
          setRowsPerPage(pageChange.pageSize);
        }}
        pagination
      />
      <ProviderDetailPreviewDialog
        isOpen={isProviderPreviewDialogOpen}
        onClose={() => {
          setIsProviderPreviewDialogOpen(false);
        }}
        providerId={selectedMatchedProvider?.id ?? null}
      />
    </Box>
  );
};
  
export const MatchedProvidersGridColumns = ({
  isLoading,
  selectedProviderId,
  onProviderSelect,
  showActionButton,
}: {
  isLoading: boolean;
  selectedProviderId: string | null | undefined;
  onProviderSelect: (providerId: string) => Promise<void>;
  showActionButton: boolean;
}): GridColDef[] => [
  {
    field: "PersonalInfo",
    headerName: "Personal Info",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const {
        npi,
        firstName,
        lastName,
        middleName,
      } = params.row as MatchedProvider;
      const fullName = trimMultipleWhiteSpaces(
        `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""}`
      ).trim();
      return (
        <Box justifyContent={"center"}>
          {!!npi && (
            <Box marginTop={0.4}>
              <Tooltip title="NPI">
                <Box display={"flex"} flexDirection={"row"}>
                  <MedicalInformationOutlined
                    sx={{ marginRight: 1 }}
                    fontSize={"small"}
                  />
                  <Typography sx={{ marginRight: 1 }}>{npi ?? ""}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!fullName && (
            <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
              <Tooltip title="Provider">
                <Person sx={{ marginRight: 1 }} fontSize={"small"} />
              </Tooltip>

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    marginRight: 1,
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {fullName}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: "matchingScore",
    headerName: "Matching Score",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => {
      const { matchingScore } = params.row as MatchedProvider;
      return (
        <Box justifyContent={"center"}>
          {matchingScore !== null && matchingScore !== undefined && (
            <Typography sx={{ textAlign: "center" }}>
              {(matchingScore * 100).toFixed(2)}%
            </Typography>
          )}
        </Box>
      );
    },
  },
  {
    field: "action",
    headerName: " ",
    align: "center",

    flex: 0.5,
    renderCell: (params) => {
      const provider = params.row as MatchedProvider;
      const { id } = provider;
      const isSelected = id === selectedProviderId;
      if (!showActionButton) return null;

      return (
        <LoadingButton
          variant="contained"
          disabled={isLoading || isSelected}
          startIcon={isSelected ? <HowToReg /> : undefined}
          loading={isLoading}
          onClick={() => {
            if (isSelected) return;
            if (!provider?.id) return;
            onProviderSelect(provider.id);
          }}
        >
          {isSelected ? "Selected" : "Select"}
        </LoadingButton>
      );
    },
  },
];
  
export default MatchedProvidersGrid;