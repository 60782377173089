import has from 'lodash/has';

export interface PaginateQuery {
  page?: number;
  limit?: number;
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: {
    [column: string]: string | string[];
  };
}

export interface PaginateQueryWithStats {
  page?: number;
  limit?: number;
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: {
    [column: string]: string | string[];
  };
  includeStats?: boolean;
}

export interface PaginateQueryResponse<T, F = Record<string, any>> {
  data: T[];
  meta: {
    itemsPerPage: number;
    totalItems: number;
    currentPage: number;
    totalPages: number;
    sortBy: [string, string][];
    search: string;
    filter: F;
  };
}

export const paginateQueryToURLParams = (
  paginateQuery: PaginateQueryWithStats
): URLSearchParams => {
  const paginationObj: any = {};
  const filters = {};
  if (has(paginateQuery, 'page')) {
    paginationObj.page = paginateQuery.page;
  }
  if (has(paginateQuery, 'limit')) {
    paginationObj.limit = paginateQuery.limit;
  }
  if (has(paginateQuery, 'search')) {
    paginationObj.search = paginateQuery.search;
  }
  if (has(paginateQuery, 'sortBy')) {
    const sortingFields =
      paginateQuery?.sortBy?.map?.(([field, order]) => `${field}:${order.toUpperCase()}`) ?? [];
    if (sortingFields.length) paginationObj.sortBy = sortingFields;
  }
  if (paginateQuery?.filter) {
    Object.entries(paginateQuery.filter).map(([key, value]) => {
      filters[`filter.${key}`] = value;
    });
    delete paginateQuery.filter;
  }
  if (has(paginateQuery, 'includeStats')) {
    paginationObj.includeStats = true;
  }

  return new URLSearchParams({ ...paginationObj, ...filters });
};
