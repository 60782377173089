import { Box, Card, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useParams } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import ProviderWindow from 'src/sections/crs/common/ProviderWindow';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { PatientWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { useBinary, usePatient } from '../../../../../@nicheaim/fhir-react';
import { RejectionFiles, UploadAvatar } from '../../../../../components/upload';
import { PatientLayout } from '../../../PatientLayout';
import PatientTabs from '../PatientTabs';
import { PatientBasicInfo } from './PatientBasicInfo';
import StandardDialogProvider from 'src/sections/careflow/common/StandardDialogProvider';

const MiNTSystemIdentifier = process.env.REACT_APP_MINT_SYSTEM_IDENTIFIER;

type FileRejectionByPatient = {
  [key: string]: FileRejection[];
};

export function PatientDetails() {
  const [fileimg, setfileImg] = useState('');
  const [fileRejectionsByPatient, setFileRejectionsByPatient] =
    useState<FileRejectionByPatient | null>({});
  const { patientId } = useParams();
  const [patient, { isSuccess, refresh: refreshPatient }] = usePatient(patientId, {
    map: PatientWrapper,
  });
  const [binary] = useBinary(getFhirIdFromEntity(patient?.photo?.[0].url || ''));
  const { i18n } = useLocales();
  useEffect(() => {
    setfileImg('');
    if (binary?.data) {
      setfileImg(`data:${binary?.contentType};base64,${binary?.data}`);
    }
  }, [patient, binary]);

  const user = useAuth();

  const [userId, setUserId] = useState<string>();
  const [fhirUserId, setFhirUserId] = useState<string>();
  const [patientMrn, setPatientMrn] = useState<string>();

  const handlFileRejections = useCallback(
    (fileRejections: FileRejection[]) => {
      if (!patientId) return;
      setFileRejectionsByPatient((currFileRejectionsByPatient) => ({
        ...currFileRejectionsByPatient,
        [patientId]: fileRejections,
      }));
    },
    [patientId]
  );

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);

  const MiNTIdentifier = patient?.identifier?.find((e) => e.system === MiNTSystemIdentifier)
    ? true
    : false;

  const patientDetails =
    patient?.gender &&
    patient?.birthDate &&
    patient?.name?.[0]?.given?.[0] &&
    patient?.name?.[0]?.family &&
    patient?.getAddresses()?.length! > 0
      ? true
      : false;

  useEffect(() => {
    if (!patient) return;
    const tempMrn = patient.getMRN()?.value;
    setPatientMrn(tempMrn);
  }, [patient]);

  return (
    <PatientLayout title={i18n('patients.details.title', 'crs')}>
      <StandardDialogProvider>
        <Grid container>
          <Grid item xs={12}>
            <Card
              sx={{
                m: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#F1F4F7',
                border: `1px solid #008ECC`,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Grid item xs>
                  <Stack direction="column" sx={{ m: 1 }}>
                    <UploadAvatar
                      file={fileimg.toString()}
                      patient={patient}
                      sx={{ border: `1px solid #008ECC`, height: 80, width: 80 }}
                      onFileRejections={handlFileRejections}
                      showFileRejections={false}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={9}>
                  {patient && isSuccess && <PatientBasicInfo patient={patient} />}
                </Grid>
                <Grid item xs>
                  <Stack sx={{ m: 3 }}>
                    <ProviderWindow
                      userId={userId ?? ''}
                      fhirUserId={fhirUserId ?? ''}
                      patientId={patientId ?? null}
                      patientMiNTIdentifier={MiNTIdentifier}
                      patientData={patientDetails}
                    />
                  </Stack>
                </Grid>
              </Box>

              {!!patientId && !!fileRejectionsByPatient?.[patientId]?.length && (
                <Grid item xs={10} sm={8} sx={{ marginX: 2, marginBottom: 1, marginTop: -3 }}>
                  <RejectionFiles
                    variant="Alert"
                    onClose={() => {
                      handlFileRejections([]);
                    }}
                    fileRejections={fileRejectionsByPatient[patientId]}
                  />{' '}
                </Grid>
              )}
            </Card>
            <Card sx={{ mx: 2 }}>
              {patient && isSuccess && (
                <PatientTabs patient={patient} onRefresh={() => refreshPatient()} />
              )}
            </Card>
          </Grid>
        </Grid>
      </StandardDialogProvider>
    </PatientLayout>
  );
}
