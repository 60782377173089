import {
  Container,
  Grid,
  Box,
  Modal,
} from "@mui/material";
import Breadcrumbs from 'src/components/Breadcrumbs';
import BedAssignment from "../../careflow/rounding/Model/BedAssignment";
import { useCensusBeds } from 'src/services/api/census/hooks';
import { useEffect } from "react";
import useObjectState from "src/hooks/useObjectState";
import { CensusProvider } from "src/sections/careflow/census/CensusContext";
import AddBed from "./components/AddBed";
import { Filters } from "src/sections/careflow/census/components/Filters/Filters";
import ListTable from "./components/ListTable";
import Bed from "src/sections/careflow/rounding/Model/Bed";
import Page from "src/components/Page";
import { CensusStateType } from "src/sections/careflow/census/census";

const CensusSettings = () => {
  const [{
    selectedBed,
    showAddBedModal,
    searchValue,
    locationFhirId,
    pagination,
  }, updateState] = useObjectState<CensusStateType>({
    showAddBedModal: false,
    selectedBed: null,
    searchValue: null,
    locationFhirId: null,
    pagination: {
      take: 10,
      skip: 0,
    }
  })
  const { data: response, isLoading, refetch } = useCensusBeds({
    search: searchValue,
    locationFhirId: locationFhirId,
    pagination: {
      take: pagination?.take,
      skip: pagination?.skip
    },
  });
  const beds = response?.beds
  const total = response?.total;

  const onSaveEntity = (bed: Bed, bedAssignment: BedAssignment | null) => {
    refetch();
  };

  const onChangeLocation = (locationId: string | null) => updateState({
    locationFhirId: locationId,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const onChangeSearch = (search: string | null) => updateState({
    searchValue: search,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const openAddBedModal = (bed: Bed | null) => {
    updateState({
      selectedBed: bed,
      showAddBedModal: true,
    })
  };

  const closeAddBedModal = () => {
    updateState({
      selectedBed: null,
      showAddBedModal: false,
    })
  };

  useEffect(() => {
    refetch();
  }, [locationFhirId, refetch, searchValue, pagination])

  return (
    <Page title="Manage Census">
    <CensusProvider
      value={{
        openAddBedModal,
        closeAddBedModal,
        onSaveEntity,
      }}
    >
      <Modal
        open={showAddBedModal}
        onClose={closeAddBedModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: 0,
            width: 400,
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedBed && (
            <AddBed
              bed={selectedBed}
              onSaveCallback={(data) => onSaveEntity(data, null)}
              onCancelPress={closeAddBedModal}
            />
          )}
          
        </Box>
      </Modal>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <Breadcrumbs
              title="Census Management"
              links={[
                {
                  href: '/dashboard',
                  name: 'Dashboard',
                },
                {
                  href: '',
                  name: 'Settings',
                },
                {
                  href: '',
                  name: 'Manage Census',
                }
              ]}
            />
          </Grid>
        </Grid>
        <Filters
          onChangeLocation={onChangeLocation}
          onChangeSearch={onChangeSearch}
        />
        <ListTable
          beds={beds ?? []}
          isLoading={isLoading}
          pagination={{
            take: pagination.take,
            page: pagination.skip,
            total,
          }}
          changePage={(newPage) => {
            updateState({
              pagination: {
                take: pagination.take,
                skip: newPage,
              },
            });
          }}
        />
      </Container>
    </CensusProvider>
    </Page>
  );
}

export default CensusSettings;

