// @ts-nocheck
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { SummaryTable } from '../../common';
import { ImmunizationAddForm } from '../../referral';
import { useImmunizations } from 'src/@nicheaim/fhir-react';
import { Button, Card, Grid, Stack } from '@mui/material';
import { mapImmunizationsToDisplay } from '../../common/common-utils';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { AppointmentList } from './patientHealth/appointments/AppointmentList';
import ObservationDetails from './patientHealth/observations/ObservationDetails';
import { STATUS_OPTION_IMMUNIZATION, TABLE_HEAD_IMMUNIZATIONS } from '../../common/table-head';
import { ConditionsList } from './patientHealth/conditions/ConditionsList';
import ExpandableCard from '../../../../components/common/ExpandableCard';
import { OBSERVATION_INCK_SCORES_CODE_SYSTEM } from '../../../../config';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from '../../helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { Add } from '@mui/icons-material';
import { EpisodeOfCareList } from './patientHealth/episodeOfCare/EpisodeOfCareList';
type Props = {
  patient: WrappedPatient | null;
};

export default function PatientHealth({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [openImmunization, setOpenImmunization] = useState(false);
  const { i18n } = useLocales();

  const [immunizations, { create: createImmunization, refresh: refreshImmunizations }] =
    useImmunizations({ filter: { patient: patient?.id } });

  const handleCloseAddImmunization = () => {
    setOpenImmunization(false);
  };

  const handleCreateImmunization = async (data: any) => {
    const result = await createImmunization(data);
    refreshImmunizations();
    enqueueSnackbar('Immunization created successfully!');
    handleCloseAddImmunization();
  };

  return (
    <>
      <Card sx={{ py: 2, boxShadow: 'none' }}>
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.IMMUNIZATION.VIEW] }) && (
          <ExpandableCard
            title={i18n('patients.details.immunizations.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <Card style={{ boxShadow: 'none', position: 'static' }}>
              <Stack sx={{ m: 2, mt: 0 }}>
                <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                  {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.IMMUNIZATION.ADD] }) && (
                    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        size="small"
                        sx={{ height: '36px' }}
                        startIcon={<Add />}
                        style={{ position: 'absolute', top: '25px', right: '25px' }}
                        onClick={() => setOpenImmunization(true)}
                      >
                        {i18n('patients.details.immunizations.button', 'crs')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Stack>

              <SummaryTable
                header={translateTableHead(TABLE_HEAD_IMMUNIZATIONS, 'crs')}
                data={mapImmunizationsToDisplay(immunizations)}
                statusList={STATUS_OPTION_IMMUNIZATION}
                sx={{ padding: 0 }}
              />
            </Card>
          </ExpandableCard>
        )}
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.OBSERVATION.VIEW] }) && (
          <ExpandableCard
            title={i18n('patients.details.observations.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <ObservationDetails patient={patient} systems={[OBSERVATION_INCK_SCORES_CODE_SYSTEM]} />
          </ExpandableCard>
        )}
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.APPOINTMENT.VIEW] }) && (
          <ExpandableCard
            title={i18n('patients.details.appointments.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <AppointmentList patient={patient} />
          </ExpandableCard>
        )}
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONDITION.VIEW] }) && (
          <ExpandableCard
            title={i18n('patients.details.conditions.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <ConditionsList patient={patient} />
          </ExpandableCard>
        )}
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.EPISODE_OF_CARE.VIEW] }) && (
          <ExpandableCard
            title={i18n('patients.details.episodeOfCare.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <EpisodeOfCareList patient={patient} />
          </ExpandableCard>
        )}
      </Card>
      <ImmunizationAddForm
        open={openImmunization}
        onCancel={handleCloseAddImmunization}
        handleCreateImmunization={handleCreateImmunization}
        patient={patient}
      />
    </>
  );
}
