import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const ACCESS_TOKEN_COOKIE_KEY = 'accessCode';
const REFRESH_TOKEN_COOKIE_KEY = 'refreshToken';
const CAREFLOW_TOKEN_COOKIE_KEY = 'careflowToken';

export interface TenantI {
  name: string;
  code: string;
}

export interface AclSimpleI {
  aclCode: string;
  aclName: string;
}
export interface RoleAclSimpleI {
  roleName: string;
  roleCode: string;
  roleId: number;
  acls: AclSimpleI[];
}
export interface UserOrganizationSimpleI {
  organizationCode: string;
  organizationName: string;
  organizationId: number;
  fhirUri: string;
  roles: RoleAclSimpleI[];
}

export function getAccessTokenStr(): string {
  const accessTokenCookie = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  if (accessTokenCookie === undefined) {
    throw new Error(ACCESS_TOKEN_COOKIE_KEY + ' cookie is not set');
  } else if (typeof accessTokenCookie !== 'string') {
    throw new Error(ACCESS_TOKEN_COOKIE_KEY + ' cookie value is not a string');
  }

  return accessTokenCookie;
}

export function getRefreshTokenStr(): string {
  const refreshTokenCookie = Cookies.get(REFRESH_TOKEN_COOKIE_KEY);

  if (refreshTokenCookie === undefined) {
    throw new Error(REFRESH_TOKEN_COOKIE_KEY + ' cookie is not set');
  } else if (typeof refreshTokenCookie !== 'string') {
    throw new Error(REFRESH_TOKEN_COOKIE_KEY + ' cookie value is not a string');
  }

  return refreshTokenCookie;
}

export function getCareflowToken(disableExceptions?: boolean): string | undefined {
  const cookie = Cookies.get(CAREFLOW_TOKEN_COOKIE_KEY);

  if (disableExceptions) {
    return cookie;
  }

  if (cookie === undefined) {
    throw new Error(CAREFLOW_TOKEN_COOKIE_KEY + ' cookie is not set');
  } else if (typeof cookie !== 'string') {
    throw new Error(CAREFLOW_TOKEN_COOKIE_KEY + ' cookie value is not a string');
  }

  return cookie;
}

export function getDecodedCareflowToken(): any {
  const cToken = getCareflowToken();

  if (cToken === undefined) {
    throw new Error(CAREFLOW_TOKEN_COOKIE_KEY + ' cookie value is undefined');
  }

  const dToken = jwtDecode<any>(cToken);

  if (!dToken) {
    throw new Error('unable to decode careflowToken');
  }

  return dToken;
}

export function getTenantCodeFromCareflowToken(): string | undefined {
  try {
    const dCareflowToken = getDecodedCareflowToken();

    if (
      !dCareflowToken ||
      dCareflowToken.tenant_code === undefined ||
      typeof dCareflowToken.tenant_code !== 'string'
    ) {
      return;
    }

    return dCareflowToken.tenant_code;
  } catch (error) {}
}
