import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { DocumentReference } from 'src/@nicheaim/fhir-base/mappings/DocumentReference';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { useBinarys, useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { config } from 'src/config';
import usePrintableCarePlan from 'src/crs/printable/usePrintableCarePlan';
import useWebMessageListener from 'src/hooks/useWebMessageListener';
import { useStandardDialog } from 'src/sections/careflow/common/StandardDialogProvider';
import { useStandardLoadingBackdrop } from 'src/sections/careflow/common/StandardLoadingBackdropProvider';
import Viewer from 'src/sections/crs/common/Viewer';
import { useCurrentPatient } from 'src/stores/patient-tabs';

interface PrintableCarePlanPropsI {
  carePlanId: string | undefined;
  renderButton?: (onClick: () => void) => JSX.Element;
}

export function PrintableCarePlan({ carePlanId, renderButton }: PrintableCarePlanPropsI) {
  const printableCarePlan = usePrintableCarePlan();
  const loadingBackdrop = useStandardLoadingBackdrop();
  const [openPrintableCarePlan, setOpenPrintableCarePlan] = useState<boolean>(false);
  const [useJasperReports, setUseJasperReports] = useState<boolean>(true);
  const standardDialog = useStandardDialog();
  const webMessageListener = useWebMessageListener({ handleMessage: handleWebMessage });
  const [printableCarePlanDocument, setPrintableCarePlanDocument] = useState<string>('');
  const [, { create: createBinary }] = useBinarys({ autofetch: false });
  const [documentReferencesRecords, { create: createDocumentReference }] = useDocumentReferences({
    map: DocumentReferenceWrapper,
  });
  const { enqueueSnackbar } = useSnackbar();
  const patient = useCurrentPatient();
  const printableHtmlRef = useRef();

  function handleWebMessage(e: MessageEvent<any>) {
    const allowedOriginsToHandleIframeMessages = [''];

    if (!allowedOriginsToHandleIframeMessages.includes(e.origin)) {
      return;
    }

    if (!e.data || e.data.topic !== 'iframe-printable-care-plan') {
      return;
    }
  }

  useEffect(() => {
    webMessageListener.enable();
  }, []);

  function downloadIframe() {
    let fileName = useJasperReports ? 'printable.pdf' : 'printable.html';

    if (!printableHtmlRef.current) {
      enqueueSnackbar('failed to download', { variant: 'error' });
      return;
    }

    const blob = new Blob([printableHtmlRef.current], { type: 'text/html' });
    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.click();

    document.location = 'data:text/attachment;,' + printableCarePlanDocument;
  }

  function printIframe() {
    let message: any = {
      topic: 'iframe-printable-care-plan',
      task: 'print',
    };

    if (useJasperReports) {
      message = {
        topic: 'document-viewer',
        action: 'print-pdf',
      };
    }

    //@ts-ignore
    let win = window.frames.printable_care_plan_iframe;

    if (useJasperReports) {
      //@ts-ignore
      win = window.frames.documentPreview;
    }

    if (!win) {
      enqueueSnackbar('failed to print care plan', { variant: 'error' });
      return;
    }

    webMessageListener.send(message, win);
  }

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);
    return result;
  };

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
    });

  const createBinaryCustom = async (fileName: string, file: Blob): Promise<any> => {
    const resourceType = 'Binary';
    const contentType = useJasperReports ? 'application/pdf' : 'text/html';
    console.debug('DEBUG createBinaryCustom after contentType');
    const data = await convertBlobToBase64(file);
    console.debug('DEBUG createBinaryCustom after convertBlobToBase64');
    const name = fileName;
    const obj = { resourceType, contentType, data };
    const binary = await handleCreateBynary(obj);
    const objNew = { ...binary[0], name: name };
    return objNew;
  };

  const mapFormDataToDocumentReference = (binary: any) => {
    const documentReference: DocumentReference = {
      resourceType: 'DocumentReference',
      status: 'current',
      date: new Date().toISOString(),
      subject: {
        reference: `Patient/${patient?.id}`,
        type: 'Patient',
      },
      content: [
        {
          attachment: {
            contentType: `${binary?.contentType}`,
            creation: new Date().toISOString(),
            title: binary.name,
            url: `${config.fhirServerUrl}/Binary/${binary?.id}`,
          },
        },
      ],
    };

    return documentReference;
  };

  async function saveAttachment() {
    try {
      const fileName = 'printableCarePlan';
      console.debug('DEBUG after fileName');

      if (!printableHtmlRef.current) {
        enqueueSnackbar('failed to save attachment', { variant: 'error' });
        return;
      }

      const blob = new Blob([printableHtmlRef.current], {
        type: useJasperReports ? 'application/pdf' : 'text/html',
      });
      const resultBinary = await createBinaryCustom(fileName, blob);
      console.debug('DEBUG after resultBinary');
      const mapBinaryToDocumentReference = mapFormDataToDocumentReference(resultBinary);
      const saveDocumentReference = await createDocumentReference(mapBinaryToDocumentReference);

      enqueueSnackbar('Attachment was created');
    } catch (error) {
      enqueueSnackbar('Attachment was not created', { variant: 'error' });
    }
  }

  function getShowElement(iframeSrc) {
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          {useJasperReports ? (
            <Viewer file={iframeSrc} typeResource="blob" />
          ) : (
            <iframe
              name="printable_care_plan_iframe"
              title="Printable CarePlan"
              style={{ minHeight: '70vh', width: '100%', height: '100%' }}
              srcDoc={iframeSrc}
              sandbox="allow-scripts allow-modals"
            ></iframe>
          )}
        </Grid>

        <Grid
          container
          padding={2}
          spacing={1}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              type="button"
              onClick={() => {
                setOpenPrintableCarePlan(false);
                standardDialog.close();
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item>
            <Button type="button" variant="contained" onClick={downloadIframe}>
              Download
            </Button>
          </Grid>

          <Grid item>
            <Button type="button" variant="contained" onClick={printIframe}>
              Print
            </Button>
          </Grid>

          <Grid item>
            <Button type="button" variant="contained" onClick={saveAttachment}>
              Save as Attachment
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  async function loadPrintable() {
    if (!carePlanId) {
      return;
    }

    const result = await printableCarePlan.getJasperPrintableCareplan(carePlanId);
    printableHtmlRef.current = result;
    setPrintableCarePlanDocument(result);

    loadingBackdrop.close();
    const process = result;

    standardDialog.open(getShowElement(process));
  }

  useEffect(() => {
    if (!openPrintableCarePlan) {
      return;
    }

    loadingBackdrop.open();

    loadPrintable();
  }, [openPrintableCarePlan]);

  return renderButton ? (
    renderButton(() => {
      setOpenPrintableCarePlan(true);
    })
  ) : (
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        setOpenPrintableCarePlan(true);
      }}
    >
      Generate Printable Case
    </Button>
  );
}
