// @ts-nocheck
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Card, Container, Divider, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { OrganizationManager } from 'src/@types/organization';
import api from 'src/services/api';
import OrganizationNewEditForm from 'src/sections/organization/OrganizationNewEditForm';
import useLocales from 'src/hooks/useLocales';
import { ChildList } from 'src/sections/organization/child/ChildList';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { Users } from 'src/sections/organization/Users';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

// ----------------------------------------------------------------------

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function OrganizationCreate() {
  const { themeStretch } = useSettings();

  const { i18n } = useLocales();

  const { pathname } = useLocation();

  const { uuid = '' } = useParams();

  const isEdit = pathname.includes('edit');

  const [currentOrganization, setCurrentOrganization] = useState<OrganizationManager>();
  const [currentTab, setCurrentTab] = useState(0);
  const isAllowedToChildOrgs = checkAclValidation({
    acls: [crsAcls.ADMIN.ORGANIZATIONS.CHILD_ORGANIZATIONS.VIEW],
  });

  const handleTabChange = (e: React.SyntheticEvent, selectedTab: number) => {
    setCurrentTab(selectedTab);
  };

  useEffect(() => {
    (async () => {
      const organization = await api.organizations.getOrganization(uuid);
      setCurrentOrganization(organization);
    })();
  }, []);

  return (
    <Page 
      title={!isEdit 
        ? i18n('admin.newOrganization.titlePage') 
        : i18n('admin.newOrganization.titlePageEdit')
      }
    >
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          title={
            !isEdit
              ? `${i18n('admin.newOrganization.titleCreate')}`
              : `${i18n('admin.newOrganization.titleEdit')}`
          }
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('admin.listTitle')}`, href: PATH_DASHBOARD.organization.list },
            { name: !isEdit ? `${i18n('admin.newOrganization.title')}` : uuid },
          ]}
        />

        <Card>
          <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
            <Tab label={i18n('admin.newOrganization.general')} />
            {isAllowedToChildOrgs && isEdit && <Tab label={i18n('admin.newOrganization.child')} />}
            {isEdit && <Tab label={i18n('admin.newOrganization.users')} />}
          </Tabs>

          <Divider />

          <TabPanel value={currentTab} index={0}>
            <OrganizationNewEditForm isEdit={isEdit} currentOrganization={currentOrganization} />
          </TabPanel>

          {isAllowedToChildOrgs && isEdit && (
            <TabPanel value={currentTab} index={1}>
              <ChildList isEdit={isEdit} currentOrganization={currentOrganization} />
            </TabPanel>
          )}

          <TabPanel value={currentTab} index={2}>
            <Users currentOrganization={currentOrganization}/>
          </TabPanel>
        </Card>
      </Container>
    </Page>
  );
}
