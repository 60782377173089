import makeStyles from "@mui/styles/makeStyles";
import { Grid, Tooltip, Typography } from "@mui/material";

const useStyles = makeStyles({
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
});

interface KeyValueGridProps {
  label: string;
  value: string;
  tooltipString?: string;
  highlight?: boolean;
  children?: JSX.Element;
}

export default function KeyValueGrid({
  label,
  value,
  tooltipString,
  highlight,
  children
}: KeyValueGridProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      className={highlight ? classes.highlight : undefined}
    >
      <Grid
        container
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        justifyContent="flex-start"
        alignItems='center'
      >
        <Typography variant="body2" align="left">
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid container item xs={8} sm={8} md={8} lg={8} xl={8} zeroMinWidth alignItems='center'>
        <Tooltip title={tooltipString ? tooltipString : value}>
          {children ?
            <>
              {children}
            </> :
            <Typography 
              variant="body2" 
              sx={{ 
                wordWrap: 'break-word', 
                overflow: 'hidden', 
                color: 'text.secondary'
              }}
            >
              {value}
            </Typography>
          }
        </Tooltip>
      </Grid>
    </Grid>
  );
}
