import { trimMultipleWhiteSpaces } from 'src/utils/string';
import { Person } from '../types';
import { capitalize } from 'lodash';
import { calculateAge } from './helpers';
import moment from 'moment';

export const getPersonFullName = (name: Person['name'] | undefined | null): string =>
  capitalize(
    trimMultipleWhiteSpaces(
      `${name?.[0]?.given?.join?.(' ') ?? ''} ${name?.[0]?.family ?? ''}`
    ).trim()
  );

export const getFormattedPersonDOB = (dateString: string | undefined | null): string => {
  const date = moment(dateString ?? null);
  if (!date.isValid()) return '';
  const age = calculateAge(date);
  return `${date.format('MMM DD, YYYY')} (${age} yrs)`;
};

export const getPersonNames = (
  name: Person['name'] | undefined | null
): {
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
} => ({
  firstName: getPersonFirstName(name),
  middleName: getPersonMiddleName(name),
  lastName: getPersonLastName(name),
  fullName: getPersonFullName(name),
});

export const getPersonMiddleName = (name: Person['name'] | undefined | null): string =>
  name?.[0]?.given?.slice(1)?.join?.(' ') ?? '';

export const getPersonFirstName = (name: Person['name'] | undefined | null): string =>
  name?.[0]?.given?.[0] ?? '';

export const getPersonLastName = (name: Person['name'] | undefined | null): string =>
  name?.[0]?.family ?? '';
