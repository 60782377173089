// ----------------------------------------------------------------------

import { Path } from './Path';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logouts'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

const patientRoot = new Path(ROOTS_DASHBOARD, '/crs/patient');
const patientDetails = patientRoot.newChild(':patientId').setIdField('patientId');

const yoCareRootPath = new Path(ROOTS_DASHBOARD, '/yocare');
const natPath = new Path(ROOTS_DASHBOARD, '/nat');
const engagementHubPath = new Path(ROOTS_DASHBOARD, '/engagementhub');
const APP_ROOT_PATH = '/app';
const providerPath = new Path(APP_ROOT_PATH, '/provider');
const dataQualityPath = new Path(ROOTS_DASHBOARD, '/dataquality');

export const CRS_PATH = {
  provider: {
    root: providerPath.getValue(),
    list: providerPath.newChild('/list').getValue(),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    patientDemographics: path(ROOTS_DASHBOARD, '/patient-demographics'),
    healthRecords: path(ROOTS_DASHBOARD, '/health-record'),
    careRecord: path(ROOTS_DASHBOARD, '/care-record'),
    activities: path(ROOTS_DASHBOARD, '/activities'),
    organization: path(ROOTS_DASHBOARD, '/organization'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    newPatients: path(ROOTS_DASHBOARD, '/new-patients'),
    permissions: path(ROOTS_DASHBOARD, '/permissions'),
    roundings: {
      list: path(ROOTS_DASHBOARD, '/roundings/list'),
      census: path(ROOTS_DASHBOARD, '/roundings/census'),
    },
    groupSessions: path(ROOTS_DASHBOARD, '/group-sessions'),
    medPass: path(ROOTS_DASHBOARD, '/med-pass'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
  },
  crs: {
    patient: {
      list: patientRoot,
      details: patientDetails,
      referralsAndCases: patientDetails.newChild('/referrals-cases'),
      health: patientDetails.newChild('/health'),
      groups: patientDetails.newChild('/groups'),
      insurance: patientDetails.newChild('/insurance'),
      documents: patientDetails.newChild('/documents'),
      engagementHub: patientDetails.newChild('/engagement-hub'),
      new: patientRoot.newChild('/new'),
    },
    case: path(ROOTS_DASHBOARD, '/case'),
    // workflow: path(ROOTS_DASHBOARD, '/workflow'),
    referral: path(ROOTS_DASHBOARD, '/referral'),
    childReferral: path(ROOTS_DASHBOARD, '/child-referral'),
    task: path(ROOTS_DASHBOARD, '/task'),
    organizations: path(ROOTS_DASHBOARD, '/app/organizations'),
  },
  dataQuality: {
    incident: dataQualityPath.newChild('/incident').value,
  },
  nat: {
    task: natPath.newChild('/task').value,
  },
  engagementHub: {
    patientEngagement: engagementHubPath.newChild('/patientengagement').value,
    intakeEngagement: engagementHubPath.newChild('/intakeengagement').value,
  },
  yoCare: {
    userDetails: yoCareRootPath.newChild('/userDetails').value,
    users: yoCareRootPath.newChild('/users').value,
    persons: yoCareRootPath.newChild('/persons').value,
    dataSystems: yoCareRootPath.newChild('/dataSystems').value,
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  organization: {
    root: path(ROOTS_DASHBOARD, '/organization'),
    list: path(ROOTS_DASHBOARD, '/organization/list'),
    new: path(ROOTS_DASHBOARD, '/organization/new'),
    edit: (uuid: string) => path(ROOTS_DASHBOARD, `/organization/${uuid}/edit`),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    list: path(ROOTS_DASHBOARD, '/settings/list'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (uuid: string) => path(ROOTS_DASHBOARD, `/user/${uuid}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  sbha: {
    root: path(ROOTS_DASHBOARD, 'home')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
