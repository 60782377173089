import { DeleteOutline, LocalHospital, Mail, MedicalInformation, OpenInNew, Phone, Work } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { fhirClient } from 'src/App';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import InfoRibbon, { InfoTypography } from 'src/components/InfoRibbon';
import SearchTextField from 'src/components/SearchTextField';
import useTranslations from 'src/hooks/useTranslations';
import {
  Bundle,
  Coding,
  FhirResource,
  Organization,
  Practitioner,
  PractitionerRole,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { cleanSearchInput } from 'src/utils/string';
import CellRow from '../common/CellRow';
import StethoscopeIcon from '../../../assets/icons/stethoscope.svg';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GeneralPractitionerGrid } from '../types';

import { CustomLoadingButton } from '../common/CustomButton';
import { isActiveCondition } from '../constants';

import { getReferenceId, getReferenceType, getResourcesAndRelatedResources } from 'src/utils/fhir';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';

import { formatUSAddress } from 'src/utils/address';
import { debounce } from 'src/utils/timers';
import { usePatients, useValueSet } from 'src/@nicheaim/fhir-react';
import useNotiMessage from 'src/hooks/useNotiMessage';
import CustomLink from '../common/CustomLink';
import { CRS_PATH } from 'src/routes/paths';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';

interface AssignGeneralPractitionerModalProps extends CustomModalBasicProps {
  patient: WrappedPatient | null;
}

const iconSize: CSSProperties = { height: 15, width: 15 };
const iconColor: CSSProperties['color'] = '#919eab';

const noResultsImage = require('../../../assets/no_results.png');

const AssignGeneralPractitionerModal = ({
  onClose,
  patient,
  open,
  ...modalProps
}: AssignGeneralPractitionerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const translate = useTranslations(`patients.generalPractitioners`);

  const [searchTextField, setSearchTextField] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [roleValue, setRoleValue] = useState<Coding | null>(null);
  const [generalPractitioners, setGeneralPractitioners] = useState<GeneralPractitionerGrid[]>([]);
  const [patientGeneralPractitioners, setPatientGeneralPractitioners] = useState<Reference[]>([]);

  const [_, { update: updatePatient }] = usePatients({
    autofetch: false,
  });

  const [rolesCodesVs] = useValueSet('ph-roles-codes', {
    map: ValueSetWrapper,
  });

  const { showErrorMessage, showSuccessMessage } = useNotiMessage();

  const [filterType, setFilterType] = useState<string | null>('name');

  const getPractitionerRoles = useCallback(
    async (searchString: string) => {
      try {
        setIsLoading(true);
        const cleansedSearch = searchString ? cleanSearchInput(searchString) : '';
        const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
        const givenName = splittedSearch?.[0] ?? '';
        const familyName = splittedSearch.slice(1).join(' ');

        const filtersByName = {
          ...(givenName && familyName
            ? {
                'practitioner.given': givenName,
                ...(familyName
                  ? {
                      'practitioner.family': familyName,
                    }
                  : {}),
              }
            : {
                'practitioner.name': cleansedSearch,
              }),
        };

        const filterByIdentifier = {
          'practitioner.identifier': cleansedSearch,
        };

        const filterByPractitionerRoleCode = {
          'role': roleValue?.code,
        };

        const urlSearchParams = new URLSearchParams({
          ...(filterType === 'npi' && cleansedSearch ? { ...filterByIdentifier } : {}),
          ...(filterType === 'name' && cleansedSearch ? { ...filtersByName } : {}),
          ...(filterType === 'role' && roleValue?.code ? { ...filterByPractitionerRoleCode } : {}),
          ...isActiveCondition,
          _count: String(50),
        }).toString();

        const prResponse = await fhirClient.get<Bundle<PractitionerRole>>(
          `PractitionerRole?_include=PractitionerRole:practitioner&_include=PractitionerRole:organization&${urlSearchParams}`
        );

        const patientPractitionerRoleIds =
          patient?.generalPractitioner?.reduce?.<string[]>((prIds, { reference }) => {
            const id = getReferenceId(reference);
            const resourceType = getReferenceType(reference);
            if (!id || resourceType !== 'PractitionerRole') return prIds;
            return [...prIds, id];
          }, []) ?? [];

        const resourceEntries =
          prResponse?.entry?.reduce?.<FhirResource[]>((resources, { resource }) => {
            if (
              !resource ||
              (resource?.resourceType === 'PractitionerRole' &&
                patientPractitionerRoleIds.includes(resource?.id ?? ''))
            )
              return resources;
            return [...resources, resource];
          }, []) ?? [];

        const [practitionerRoles, relatedResources] = getResourcesAndRelatedResources(
          resourceEntries,
          'PractitionerRole'
        );

        const generalPractitioners = (practitionerRoles as PractitionerRole[]).reduce<
          GeneralPractitionerGrid[]
        >((generalPractitioners, practitionerRole) => {
          let practitioner = relatedResources.find(
            ({ id, resourceType }) =>
              id === getReferenceId(practitionerRole?.practitioner?.reference) &&
              resourceType === 'Practitioner'
          );
          if (practitioner) practitioner = PractitionerWrapper(practitioner as Practitioner);
          const organization = relatedResources.find(
            ({ id, resourceType }) =>
              id === getReferenceId(practitionerRole?.organization?.reference) &&
              resourceType === 'Organization'
          ) as Organization;

          return [
            ...generalPractitioners,
            {
              id: practitionerRole.id as string,
              npi: (practitioner as WrappedPractitioner)?.getNPI?.(),
              internal: (practitioner as WrappedPractitioner)?.getInternalIdenfitier?.(),
              role: practitionerRole?.code?.[0],
              practitioner: {
                id: practitioner?.id,
                fullName: (practitioner as WrappedPractitioner)?.getFullName?.(),
              },
              organization: {
                id: organization?.id,
                fullName: organization?.name,
              },
              phone: (practitioner as WrappedPractitioner)?.getPhones?.()?.[0]?.value,
              email: (practitioner as WrappedPractitioner)?.getEmails?.()?.[0]?.value,
              address: formatUSAddress(
                (practitioner as WrappedPractitioner)?.getPrimaryAddress?.()
              ),
            },
          ];
        }, []);
        setGeneralPractitioners(generalPractitioners);
      } catch (error) {}
      setIsLoading(false);
    },
    [patient?.generalPractitioner, filterType, roleValue]
  );

  const handleSearchInputChange = useCallback(
    debounce((value: string) => {
      setSearchFilter(value);
    }, 600),
    []
  );

  useEffect(() => {
    getPractitionerRoles(searchFilter);
  }, [getPractitionerRoles, searchFilter]);

  const handleClearFields = useCallback(() => {
    setSearchTextField('');
    setSearchFilter('');
    setFilterType('name');
    setPatientGeneralPractitioners([]);
    setRoleValue(null);
  }, []);

  useEffect(() => {
    if (!open) return;
    handleClearFields();
  }, [open, handleClearFields]);

  const handleGeneralPractitionerAssigment = (
    isAdding: boolean,
    generalPractitioner: GeneralPractitionerGrid
  ) => {
    if (isAdding) {
      setPatientGeneralPractitioners([
        ...patientGeneralPractitioners,
        {
          reference: `PractitionerRole/${generalPractitioner.id}`,
          display: generalPractitioner?.practitioner?.fullName ?? undefined,
        },
      ]);
      return;
    }
    setPatientGeneralPractitioners(
      patientGeneralPractitioners.filter(
        ({ reference }) => getReferenceId(reference) !== generalPractitioner.id
      )
    );
  };

  const handleOnSave = async () => {
    if (!patient) return;
    try {
      setIsSaving(true);
      await updatePatient({
        ...patient,
        generalPractitioner: [
          ...(patient?.generalPractitioner ?? []),
          ...patientGeneralPractitioners,
        ],
      });

      showSuccessMessage(translate('successMessage.linkGeneralPractitioner'));
      onClose?.({}, 'backdropClick');
    } catch (error) {
      showErrorMessage(translate('errorMessage.linkGeneralPractitioner'));
    }
    setIsSaving(false);
  };

  const filterTypes: Coding[] = [
    { code: 'npi', display: `${translate('npi')} OR ${translate('internal')}`},
    { code: 'name', display: translate('name') },
    { code: 'role', display: translate('role') }
  ];

  const filterTypeSelected = filterTypes.find(({ code }) => code === filterType);

  const gridColumns: GridColDef<GeneralPractitionerGrid>[] = [
    {
      flex: 1,
      sortable: false,
      field: 'npi',
      headerName: translate('practitioner'),
      renderCell: (params) => {
        const { npi, internal } = params.row;

        return (
          <Box>
            <CellRow
              tooltipTitle={translate('npi')}
              shouldTruncateText={false}
              title={npi ?? ''}
              Icon={<MedicalInformation sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
            <CellRow
              tooltipTitle={translate('internal')}
              shouldTruncateText={false}
              title={internal ?? ''}
              Icon={<Work sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
          </Box>
        )
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'practitionerAndOrg',
      headerName: translate('practitionerAndOrg'),
      renderCell: (params) => {
        const { practitioner, organization } = params.row;
        return (
          <Box>
            {!!practitioner?.fullName && (
              <CellRow
                tooltipTitle={translate('practitioner')}
                Icon={<StethoscopeIcon style={{ ...iconSize, color: iconColor }} />}
                shouldTruncateText={false}
                title={practitioner?.fullName}
              />
            )}
            {!!organization?.fullName && (
              <CellRow
                tooltipTitle={translate('organization')}
                Icon={<LocalHospital sx={iconSize} htmlColor={iconColor} />}
                shouldTruncateText={false}
                title={organization?.fullName}
              />
            )}
          </Box>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'role',
      headerName: translate('role'),
      renderCell: (params) => {
        const { role } = params.row;
        return (
          <CellRow shouldTruncateText={false} title={role?.coding?.[0]?.display ?? role?.text} />
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'contactDetails',
      headerName: translate('contactDetails'),
      renderCell: (params) => {
        const { phone, email } = params.row;
        return (
          <Box>
            <CellRow
              tooltipTitle={translate('phone')}
              shouldTruncateText={false}
              title={phone}
              Icon={<Phone sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
            <CellRow
              tooltipTitle={translate('email')}
              shouldTruncateText={false}
              title={email}
              Icon={<Mail sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
          </Box>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'address',
      headerName: translate('address'),
      renderCell: (params) => {
        const { address } = params.row;
        return (
          <CellRow
            shouldTruncateText={false}
            titleSx={{
              whiteSpace: 'pre-line',
              lineHeight: 1.5,
            }}
            title={address}
          />
        );
      },
    },
    {
      flex: 0.3,
      field: 'action',
      sortable: false,
      align: 'center',
      headerName: ' ',
      renderCell: (params) => {
        const { id } = params.row;
        const isAdded = !!patientGeneralPractitioners.find(
          ({ reference }) => getReferenceId(reference) === id
        );
        return (
          <CustomLoadingButton
            loading={isSaving}
            disabled={isSaving}
            color={!isAdded ? 'primary' : 'error'}
            onClick={() => {
              handleGeneralPractitionerAssigment(!isAdded, params.row);
            }}
          >
            {!isAdded ? translate('link') : translate('unlink')}
          </CustomLoadingButton>
        );
      },
    },
  ];

  return (
    <CustomModal
      open={open}
      title={translate('linkExistingPractitioner')}
      breadcrumbs={[
        translate('patient'),
        patient?.getFullName?.() ?? '',
        translate('groups'),
        translate('linkExistingPractitioner'),
      ]}
      isSaveButtonDisabled={!patientGeneralPractitioners.length}
      saveButtonTooltip={!patientGeneralPractitioners.length ? translate('mustAddOneToSave') : ''}
      saveButtonTitle={translate('save')}
      onSave={handleOnSave}
      onClose={onClose}
      onCancel={onClose as Function}
      isLoading={isSaving}
      containerSx={[{ overflow: 'scroll', width: '70vw', minWidth: 800 }]}
      childrenWithoutPadding={
        <Box mt={3}>
          {!!patient && (
            <InfoRibbon containerSx={{ marginTop: 2 }}>
              <InfoTypography>Patient {patient?.getFullName?.() ?? ''}</InfoTypography>
              {!!patient?.birthDate && (
                <InfoTypography>
                  Date of Birth {patient?.getBirthDateForDisplay?.()} ({patient?.getAgeInYears?.()}{' '}
                  years)
                </InfoTypography>
              )}

              {!!patient?.getMRN?.()?.value && (
                <InfoTypography>MRN {patient.getMRN()?.value}</InfoTypography>
              )}
              {!!patient?.getPrimaryPhone?.()?.value && (
                <InfoTypography>Phone {patient.getPrimaryPhone()?.value}</InfoTypography>
              )}
              {!!patient?.getPrimaryEmail?.()?.value && (
                <InfoTypography>Email {patient.getPrimaryEmail()?.value}</InfoTypography>
              )}
            </InfoRibbon>
          )}
        </Box>
      }
      {...modalProps}
    >
      <>
        <GridSection spacing={2}>
          <GridItem xs={2.8}>
            <FormControl fullWidth>
              <InputLabel> {translate('searchBy')}</InputLabel>
              <Select
                disabled={!!isLoading}
                value={filterType}
                label={translate('searchBy')}
                onChange={(event) => {
                  setFilterType(event.target.value);
                }}
              >
                {filterTypes.map(({ code, display }) => (
                  <MenuItem key={`filter-type-${code}`} value={code}>
                    {display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={9} display={'flex'} justifyContent={'row'}>
            {filterTypeSelected?.code === 'role' ? (
              <Autocomplete
                fullWidth
                disabled={!rolesCodesVs?.asListAll?.()?.length}
                value={roleValue}
                onChange={(_: React.SyntheticEvent, role) => {
                  setRoleValue(role);
                }}
                options={rolesCodesVs?.asListAll?.() ?? []}
                getOptionLabel={(role: Coding) => role?.display as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Role'}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!rolesCodesVs?.asListAll?.()?.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ): (
              <SearchTextField
                value={searchTextField}
                onChange={(event) => {
                  const { value } = event.target;
                  setSearchTextField(cleanSearchInput(value));
                  handleSearchInputChange(value);
                }}
                placeholder={`${translate('searchBy')} ${
                  filterTypeSelected ? filterTypeSelected.display : ''
                }`}
                fullWidth
              />
            )}
            
            <Button
              disabled={isLoading || isSaving}
              sx={{
                ml: 2,
                paddingY: 2,
                paddingX: 3,
                alignSelf: 'center',
                color: '#ff5630',
                width: 78,
                height: 26,
                border: 0,
                '&:hover': { border: 0, backgroundColor: 'rgba(255, 86, 48, 0.16)' },
              }}
              onClick={() => {
                handleClearFields();
              }}
              variant="outlined"
              color="inherit"
            >
              <DeleteOutline htmlColor={'#ff5630'} sx={{ mr: '1px' }} />
              <Typography
                color={'#ff5630'}
                fontSize={'0.9rem'}
                fontWeight={'bold'}
                sx={{ mr: '2px' }}
              >
                {translate('clearFields')}
              </Typography>
            </Button>
          </GridItem>
        </GridSection>
        <GridSection
          marginTop={2}
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '50vh',
            overflow: 'hidden',
            overflowY: 'scroll',
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
          }}
        >
          <GridItem xs={12}>
            {!!generalPractitioners.length || isLoading ? (
              <DataGrid
                loading={isLoading}
                getRowSpacing={() => ({ bottom: 1, top: 2 })}
                rows={generalPractitioners}
                getRowHeight={() => 'auto'}
                columns={gridColumns}
                autoHeight={true}
                className="dataGridWithFilter"
                sx={[{ width: '100%' }]}
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                components={{
                  Footer: () => null,
                  Pagination: () => null,
                }}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: 300,
                  flexDirection: 'column',
                }}
              >
                <img
                  style={{
                    width: 213,
                    height: 160,
                  }}
                  src={noResultsImage}
                  alt="noResultsImage"
                />
                <Typography sx={{ textAlign: 'center', marginTop: 2 }} variant="h4">
                  {translate('noResultsFound')}
                </Typography>
                <CustomLink
                  to="#"
                  onClick={() => {
                    window.open(`${CRS_PATH.provider.list}`, '_blank');
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {translate('doYouWantToCreateAPractitioner')}
                    <OpenInNew sx={{ marginLeft: 1 }} />
                  </Box>
                </CustomLink>
              </Box>
            )}
          </GridItem>
        </GridSection>
      </>
    </CustomModal>
  );
};

export default AssignGeneralPractitionerModal;
