import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { FileWithPath } from 'react-dropzone';
import { UploadMultiFile } from 'src/components/upload';

export type OnImageUpload = (image: FileWithPath) => Promise<void> | void;

export interface ImageUploadDialogProps extends DialogProps {
  onClose: VoidFunction;
  onImageUpload: OnImageUpload;
  isLoading?: boolean;
}

const ImageUploadDialog = ({
  onClose,
  onImageUpload,
  isLoading,
  ...dialogProps
}: ImageUploadDialogProps) => (
  <Dialog
    {...dialogProps}
    onClose={() => {
      onClose();
    }}
  >
    <DialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      Choose Picture
      {!!isLoading && <CircularProgress sx={{ marginLeft: 1, marginTop: 0.3 }} size={28} />}
    </DialogTitle>
    <DialogContent sx={{ marginTop: 4 }}>
      <UploadMultiFile
        files={[]}
        maxFiles={1}
        accept="image/*"
        onDropAccepted={(files) => {
          onImageUpload(files?.[0]);
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button disabled={!!isLoading} onClick={onClose}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImageUploadDialog;
