import { Box, Button, Card, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Organization } from "src/@nicheaim/fhir-base/mappings/Organization";
import { useOrganizations } from "src/@nicheaim/fhir-react";
import { OrganizationManager } from "src/@types/organization";
import HttpAgent from "src/api/agent";
import axiosFhirInstance from "src/application/adapters/out/repositories/axiosFhirInstance";
import Iconify from 'src/components/Iconify';
import Scrollbar from "src/components/Scrollbar";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import useTable, { emptyRows, getComparator } from "src/hooks/useTable";
import { Bundle } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { applySortFilter } from "src/pages/organization/OrganizationList";
import { translateTableHead } from "src/sections/crs/helpers/common";
import { OrganizationTableRow, OrganizationTableToolbar } from "../list";

const TABLE_HEAD = [{ id: '' }, { id: 'name', label: 'Name', align: 'left' }, { id: '' }];

type Props = {
  currentOrganization?: OrganizationManager;
};

export function ChildList ({ currentOrganization }: Props) {

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    setPage,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<OrganizationManager[]>([]);

  const [childOrgs, setChildOrgs] = useState<any>([]);

  const [filterName, setFilterName] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const [, { update: updateOrganizations }] = useOrganizations({
    autofetch: false
  })

  useEffect(() => {
    getChildOrganization();
  }, []);

  const getChildOrganization = async () => {
    let setData: OrganizationManager[] = [];

    const userOrg = currentOrganization?.fhirId;

    const getAllOrgs = await HttpAgent.Organization.getAll();
    const mapOrgs = getAllOrgs?.map(({ fhirId }) => fhirId );

    const getChilOrganization =(( await axiosFhirInstance.get(
      `Organization?partof=${userOrg}`,
    ))?.data as Bundle<Organization>)?.entry?.map((entry) => entry?.resource);

    const mapChildOrganization =  getChilOrganization?.map((item) => ({ fhirId: item?.id ?? '' })) ?? [];
    setChildOrgs(mapChildOrganization);

    onSelectAllRows(
      true,
      mapChildOrganization.map((row) => row.fhirId)
    )

    const mapAllOrgs = [...new Set([...mapOrgs, ...mapChildOrganization])]

    setData = getAllOrgs?.filter(
      (item: OrganizationManager) => item?.fhirId !== currentOrganization?.fhirId && 
        mapAllOrgs?.find((x) => item?.fhirId === x)
    ) ?? [];

    setTableData(setData);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleChild = async (fhirId: any) => {
    try {
      const filterSelected = selected?.filter((x:string) => !childOrgs?.find((y: any) => x === y.fhirId));

      const ids = fhirId ? [fhirId] : filterSelected;

      const getFhirOrganizations =(( await axiosFhirInstance.get(
        `Organization?_id=${ids}`,
      ))?.data as Bundle<Organization>)?.entry?.map((entry) => entry?.resource);
  
      const mapOrganization: any = getFhirOrganizations && getFhirOrganizations.map((item) => {

        let partOf: any = {};
        if(fhirId){
          partOf = { partOf: null }
        }else{
          partOf = { partOf: { reference: `Organization/${currentOrganization?.fhirId}` }}
        }

        const org = {
          ...item, 
          ...partOf
        }
        return org;
      });
  
      if(mapOrganization?.length > 0){
        await updateOrganizations(mapOrganization)
        await getChildOrganization();
      }

      enqueueSnackbar(fhirId ? 'Child deleted' : `Organizations are child of ${currentOrganization?.name}`);
    } catch (error) {
      enqueueSnackbar('An error has ocurred', { variant: 'error' });
    }
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !dataFiltered.length && !!filterName;

  return(
    <Card>
      {selected.length > 0 && (
        <Stack 
          direction={'row'} 
          justifyContent={'flex-end'}
          sx={{ mt: 2, mx: 2 }}
        >
          <Button
            disabled={false}
            onClick={() =>  handleChild(undefined)}
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Add Child 
          </Button>
        </Stack>
      )}

      <OrganizationTableToolbar filterName={filterName} onFilterName={handleFilterName} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={
                translateTableHead(TABLE_HEAD)}
              onSort={onSort}
            />

            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <OrganizationTableRow
                    key={row.uuid}
                    row={row}
                    selected={selected.includes(row.fhirId)}
                    onSelectRow={() => onSelectRow(row.fhirId)}
                    onDeleteRow={() => handleChild(row.fhirId)}
                    onEditRow={() => {}}
                    editOption={true}
                    deleteOption={childOrgs?.find((x: any) => x.fhirId === row.fhirId) ? false : true}
                  />
                ))}

              <TableEmptyRows
                height={72}
                emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataFiltered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  )
}