import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import Row from './Row';
import Form from './Form';
import { isEmpty } from 'lodash';
import Page from 'src/components/Page';
import useTable from 'src/hooks/useTable';
import { useEffect, useState } from 'react';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TableHeadCustom } from 'src/components/table';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { getRegistries, Registry } from 'src/services/api/registry';
import { TABLE_HEAD_THEME_SETTINGS } from 'src/sections/crs/common/table-head';

export function ThemeSettingList() {

  const { themeStretch } = useSettings();
  const [row, setRow] = useState<Registry>();
  const [data, setData] = useState<Registry[]>();
  const [refresh, setRefresh] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const fetchSettingsTheme = async () => {
    try {
      const registries = await getRegistries('tenant-theme');
      const registriesData = registries?.data ?? [];
      setData(registriesData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchSettingsTheme();
  }, []);

  useEffect(() => {
    fetchSettingsTheme();
  }, [refresh]);

  const handleClose = () => {
    setRow(undefined);
    setOpenForm(false);
    setRefresh(false);
  };

  const handleSetting = (row: Registry) => {
    setRow(row);
    if(row?.id) setOpenForm(true);
  };

  const handleRefresh = () => {
    setRefresh(true);
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <Page title="Settings">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs
            title=""
            heading=""
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Settings', href: PATH_DASHBOARD.user.root },
              { name: 'List' },
            ]}
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h4">Settings List</Typography>
            </Grid>
             <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
              <Button variant='contained' onClick={() => setOpenForm(true)}>
                Add Setting
              </Button>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sx={{ marginTop: 4 }}>
            <Card sx={{ width: '100%', paddingTop: 4, paddingX: 1 }}>
              <TableContainer>
                <Table size="small" sx={{ mb: 2 }}>
                  <TableHeadCustom headLabel={TABLE_HEAD_THEME_SETTINGS} />
                  <TableBody>
                    {!isEmpty(data) ? (
                      data
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <Row
                            key={`document-row-${index}`}
                            row={row}
                            handleSetting={handleSetting}
                          />
                        ))
                    ) : (
                      <TableCell colSpan={TABLE_HEAD_THEME_SETTINGS?.length}>
                        <Typography variant="body2" align="center">
                          No rows
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data ? data.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              </Box>
            </Card>
          </Grid>
        </Container>
      </Page>
      <Form 
        row={row}
        open={openForm}
        onClose={handleClose}
        handleRefresh={handleRefresh}
      />
    </Box>
  );
}
