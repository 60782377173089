import { useCallback, useRef, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import InfoRibbon, { InfoTypography } from 'src/components/InfoRibbon';
import SearchMember from '../SearchMember/SearchMember';
import {
  CareTeamMember,
  CareTeamMemberWithEditableData,
  CareTeamPermissions,
} from 'src/@types/crs/case';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { updateCareTeamMembers } from 'src/services/api/case';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import useLocales from 'src/hooks/useLocales';

interface CareTeamMemberModalProps extends CustomModalBasicProps {
  patient?: WrappedPatient;
  careTeamMembers?: CareTeamMember[];
  careTeamId: string;
  onMemberUpdateSuccess: Function;
}

const CareTeamMemberModal = ({
  patient,
  open,
  onClose,
  careTeamMembers,
  careTeamId,
  onMemberUpdateSuccess,
}: CareTeamMemberModalProps) => {

  const { i18n } = useLocales();
  const membersSelected = useRef<CareTeamMemberWithEditableData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isAllowedToEdit, isAllowedToDelete, isAllowedToAdd } =
    usePermissionsContext<CareTeamPermissions['members']>() ?? {};

  const handleOnSave = useCallback(async () => {
    let error = false;

    if(membersSelected?.current?.length === 0) {
      enqueueSnackbar("Must select at least one member", { variant: 'warning' });
      return;
    }

    const careTeamMembers = membersSelected.current?.map?.((member) => {
      if (member.editableData?.error?.startDate) error = true;
      const memberAssociatedOrgIdEmpty = 
        (member.associatedOrgId === 'N/A' || !member.associatedOrgId);
      return {
        id: member.id,
        resourceType: member.memberType,
        name: member.name,
        identifier: member.identifier,
        identifierType: member.identifierType,
        associatedOrg: member.associatedOrgName,
        associatedOrgId: 
          !memberAssociatedOrgIdEmpty ? 
            member?.associatedOrgId?.includes('Organization') ? 
              member.associatedOrgId : `Organization/${member.associatedOrgId}` : undefined,
        role: member.editableData?.role?.display,
        roleId: member.editableData?.role?.code,
        startDate: member.editableData?.startDate?.toISOString?.(),
        endDate: member.editableData?.endDate?.toISOString?.(),
      };
    });
    if (error) {
      enqueueSnackbar("There's errors in member selection. Please fix them, before proceeding", {
        variant: 'error',
      });
      return;
    }

    const response = await updateCareTeamMembers({ careTeamMembers }, careTeamId);

    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar(`${i18n('patients.details.careteams.message', 'crs')} Members succesfully updated`);
    return true;
  }, [careTeamId]);

  const title = [
    i18n('case.details.careteams.titleModal', 'crs'),
    i18n('case.details.careteams.addNewMemberModal', 'crs')
  ];

  return (
    <CustomModal
      showSaveButton={!!(isAllowedToEdit || isAllowedToDelete || isAllowedToAdd)}
      open={open}
      title={title[1]}
      breadcrumbs={title}
      onSave={async () => {
        setIsLoading(true);
        const wasSaved = await handleOnSave();
        setIsLoading(false);
        if (!wasSaved) return;
        onMemberUpdateSuccess();
        onClose?.({}, 'backdropClick');
      }}
      onClose={onClose}
      onCancel={onClose as Function}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll', width: '70vw' }]}
      childrenWithoutPadding={
        <Box mt={3}>
          {!!patient && (
            <InfoRibbon containerSx={{ marginTop: 2 }}>
              <InfoTypography>Patient {patient.getFullName()}</InfoTypography>
              <InfoTypography>
                Date of Birth {patient.getBirthDateForDisplay()} ({patient.getAgeInYears()} years)
              </InfoTypography>
              <InfoTypography>Identifier {patient.getMRN()?.value ?? ''}</InfoTypography>
              <InfoTypography>Phone {patient.getPrimaryPhone()?.value ?? ''}</InfoTypography>
              <InfoTypography>Email {patient.getPrimaryEmail()?.value ?? ''}</InfoTypography>
            </InfoRibbon>
          )}
        </Box>
      }
    >
      <GridSection mt={4}>
        <GridItem xs={12}>
          <SearchMember
            isLoading={isLoading}
            isEditable={true}
            patient={patient as WrappedPatient}
            careTeamMembers={careTeamMembers}
            membersSelectionRef={membersSelected}
          />
        </GridItem>
      </GridSection>
    </CustomModal>
  );
};

export default CareTeamMemberModal;
