import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';

import CancelButton from '../common/CancelButton';
import { CustomLoadingButton } from '../common/CustomButton';

export interface ConfirmDialogProps extends Omit<DialogProps, 'onClose'> {
  onConfirm: () => void;
  onDialogClose: () => void;
  dialogTitle?: string;
  confirmButtonTitle?: string;
  ConfirmButtonIcon?: React.ReactNode;
  isLoading?: boolean;
}

const ConfirmDialog = ({
  onConfirm,
  children,
  onDialogClose,
  ConfirmButtonIcon,
  confirmButtonTitle = 'Confirm',
  dialogTitle = 'Confirmation',
  isLoading,
  ...dialogProps
}: ConfirmDialogProps) => (
  <Dialog
    onClose={() => {
      onDialogClose();
    }}
    {...dialogProps}
  >
    <DialogTitle>{dialogTitle}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <CustomLoadingButton
        variant="contained"
        onClick={() => {
          onConfirm();
        }}
        startIcon={ConfirmButtonIcon}
        color="primary"
        loading={!!isLoading}
        disabled={!!isLoading}
      >
        {confirmButtonTitle}
      </CustomLoadingButton>
      <CancelButton
        onClick={() => {
          onDialogClose();
        }}
      />
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
