import { IApiClient } from 'src/api/clients/api_client';
import {
  PaginateQuery,
  paginateQueryToURLParams,
  PaginateQueryWithStats,
} from 'src/api/pagination/dtos';
import { CreateReferralDto } from 'src/crs/dto/create-referral.dto';
import { PatientDto } from 'src/crs/dto/patient/patient.dto';
import { UpdateReferralDto } from 'src/crs/dto/update-referral.dto';

export interface PatientResponse {
  indexedCreatedBy: string;
  indexedCreatedAt: string;
  indexedUpdatedBy: string;
  indexedUpdatedAt: string;
  indexedPatienId: string;
  indexedPatientUUID: string;
  indexedFhirRefUri: string;
  indexedGivenName: string;
  indexedFamilyName: string;
  indexedFullName: string;
  indexedGender: string;
  indexedMrn: string;
  indexedPhone: string;
  indexedEmail: string;
  indexedDob: string;
  indexedRace: string;
  indexedEthnicity: string;
  indexedDOBFormatted: string;
  indexedAge: string;
  indexedCreatedAtFormatted: string;
  indexedCustomLastSil: string;
  indexedCustomIdentifierMedicaidId: string;
  indexedMetaLastUpdatedOnUtc: string;
  indexedStatus: string;
  indexedCustomEpisodeOfCare: object | null;
}

export interface PaginatedPatientResponse {
  data: PatientResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedPatientDto {
  data: PatientDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  stats?: any;
}

export class PatientApiClient {
  apiBase: string;

  patientApiClient: IApiClient;

  constructor(patientApiClient: IApiClient) {
    this.apiBase = 'crs/patient';
    this.patientApiClient = patientApiClient;
  }

  async getAll(queryParams: PaginateQueryWithStats): Promise<PaginatedPatientResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);
    const url = `${this.apiBase}/?${urlParams.toString()}`;
    try {
      const response = await this.patientApiClient.get<PaginatedPatientResponse | undefined>(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(fhirId: string): Promise<PatientResponse | undefined> {
    try {
      const response = await this.patientApiClient.get<PatientResponse | undefined>(
        `${this.apiBase}/${fhirId}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async create(payload: CreateReferralDto): Promise<PatientResponse | undefined> {
    try {
      const response = await this.patientApiClient.post<
        CreateReferralDto,
        PatientResponse | undefined
      >(`${this.apiBase}/`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async update(fhirId: string, payload: UpdateReferralDto): Promise<PatientResponse | undefined> {
    try {
      const response = await this.patientApiClient.put<
        UpdateReferralDto,
        PatientResponse | undefined
      >(`${this.apiBase}/${fhirId}`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async patch(fhirId: string, payload: UpdateReferralDto): Promise<PatientResponse | undefined> {
    try {
      const response = await this.patientApiClient.patch<
        UpdateReferralDto,
        PatientResponse | undefined
      >(`${this.apiBase}/${fhirId}`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class ReferralService {
  patientApiClient: PatientApiClient;

  constructor(patientApiClient: PatientApiClient) {
    this.patientApiClient = patientApiClient;
  }

  async getAll(queryParams: PaginateQueryWithStats): Promise<PaginatedPatientDto | undefined> {
    const patients = await this.patientApiClient.getAll(queryParams);
    if (!patients) return undefined;
    const dtos = patients.data.map((r) => {
      const dto = new PatientDto();
      dto.fromPatientResponse(r);
      return dto;
    });
    const mapped: PaginatedPatientDto = { ...patients, data: dtos };
    return mapped;
  }

  async create(payload: CreateReferralDto): Promise<PatientDto | undefined> {
    const patient = await this.patientApiClient.create(payload);
    if (!patient) return undefined;
    const dto = new PatientDto();
    dto.fromPatientResponse(patient);
    return dto;
  }

  async update(fhirId: string, payload: UpdateReferralDto): Promise<PatientDto | undefined> {
    const referral = await this.patientApiClient.update(fhirId, payload);
    if (!referral) return undefined;
    const dto = new PatientDto();
    dto.fromPatientResponse(referral);
    return dto;
  }
}
