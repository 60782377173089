import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid";
import { formatUSAddress } from "src/utils/fhir";
import { getGenderFromProvider } from "./helpers";
import { Box, Tooltip, Typography } from "@mui/material";
import { trimMultipleWhiteSpaces } from "src/utils/string";
import { dateYearFormatWithAge } from "src/utils/formatTime";
import { DirectoryProviderLookUp } from "src/@types/crs/providerDirectory";
import { Add, Badge, Business, Cake, MedicalInformationOutlined, Person, Phone, Place, Work } from "@mui/icons-material";

export type OnProviderAction = (provider: DirectoryProviderLookUp) => void;
export type OnProviderAsyncAction = (
  provider: DirectoryProviderLookUp
) => Promise<void>;

export interface GetColumnsProps {
  isLoading: boolean;
  isDisabled: boolean;
  isCreationMode: boolean;
  onAdd: OnProviderAsyncAction;
  phoneNumber?: string;
  onProviderAction?: OnProviderAsyncAction;
  providerActionButtonIcon?: React.ReactNode;
  providerActionButtonTitle?: string;
}

export const getColumns = ({
  isLoading,
  onAdd,
  phoneNumber,
  onProviderAction,
  providerActionButtonIcon,
  providerActionButtonTitle,
  isDisabled,
  isCreationMode,
}: GetColumnsProps): GridColDef[] => [
  {
    field: "generalInfo",
    headerName: "General Info",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const {
        first_name,
        last_name,
        middle_name,
        npi,
        ssn,
        gender,
        dob,
      } = params.row as DirectoryProviderLookUp;

      const genderLabel = getGenderFromProvider(gender);
      const fullName = trimMultipleWhiteSpaces(
        `${first_name} ${middle_name} ${last_name}`
      ).trim();

      return (
        <Box justifyContent={"center"} flexDirection={"column"}>
          {!!npi && (
            <Box marginTop={0.4}>
              <Tooltip title="NPI">
                <Box display={"flex"} flexDirection={"row"}>
                  <MedicalInformationOutlined
                    sx={{ marginRight: 1 }}
                    fontSize={"small"}
                  />
                  <Typography>{npi}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          <Box marginTop={0.4}>
            <Tooltip title="Name">
              <Box display={"flex"} flexDirection={"row"}>
                <Person sx={{ marginRight: 1 }} fontSize={"small"} />
                <Typography>{fullName}</Typography>
              </Box>
            </Tooltip>
          </Box>
          {!!genderLabel && (
            <Box marginTop={0.4}>
              <Tooltip title="Gender">
                <span>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {genderLabel}
                  </Typography>
                </span>
              </Tooltip>
            </Box>
          )}
          {!!dob && (
            <Box marginTop={0.4}>
              <Tooltip title="Date of Birth">
                <Box display={"flex"} flexDirection={"row"}>
                  <Cake sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>
                    {dateYearFormatWithAge(dob ?? null)}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )}

          {!!ssn && (
            <Box marginTop={0.4}>
              <Tooltip title="SSN">
                <Box display={"flex"} flexDirection={"row"}>
                  <Badge sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>{ssn}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: "additionalInfo",
    headerName: "Additional Info",
    flex: 1,
    sortable: false,

    renderCell: (params) => {
      const {
        address: addresses,
        classification,
        telecom,
        practice,
      } = params.row as DirectoryProviderLookUp;
      const address =
        addresses?.find?.(({ is_preferred }) => !!is_preferred) ??
        addresses?.[0];

      const primaryClassification = classification?.name;

      const phone =
        telecom?.find?.(
          ({ is_preferred, value, type }) =>
            !!is_preferred &&
            type === "phone" &&
            (phoneNumber ? value.includes(phoneNumber) : true)
        )?.value ??
        telecom?.find?.(
          ({ type, value }) =>
            type === "phone" &&
            (phoneNumber ? value.includes(phoneNumber) : true)
        )?.value;

      const practiceAddress =
        practice?.address?.find?.(({ is_preferred }) => !!is_preferred) ??
        practice?.address?.[0];
      return (
        <Box justifyContent={"center"} flexDirection={"column"}>
          {!!primaryClassification && (
            <Box marginTop={0.4}>
              <Tooltip title="Classification">
                <Box display={"flex"} flexDirection={"row"}>
                  <Work sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>{primaryClassification}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!phone && (
            <Box marginTop={0.4}>
              <Tooltip title="Phone Number">
                <Box display={"flex"} flexDirection={"row"}>
                  <Phone sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>{phone}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!address && (
            <Box marginTop={0.4}>
              <Tooltip title="Address">
                <Box display={"flex"} flexDirection={"row"}>
                  <Place sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography
                    sx={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {formatUSAddress({
                      city: address?.city ?? "",
                      line: [
                        ...(address?.line_1 ? [address?.line_1] : []),
                        ...(address?.line_2 ? [address?.line_2] : []),
                      ],
                      state: address?.state ?? "",
                      postalCode: address?.postal_code ?? "",
                      country: address?.country ?? "",
                    })}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!practice && (
            <>
              {!!practice?.npi && (
                <Box marginTop={0.4}>
                  <Tooltip title="Practice's NPI">
                    <Box display={"flex"} flexDirection={"row"}>
                      <MedicalInformationOutlined
                        sx={{ marginRight: 1 }}
                        fontSize={"small"}
                      />
                      <Typography>{practice?.npi}</Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {!!practice?.name && (
                <Box marginTop={0.4}>
                  <Tooltip title="Practice's Name">
                    <Box display={"flex"} flexDirection={"row"}>
                      <Business sx={{ marginRight: 1 }} fontSize={"small"} />
                      <Typography>{practice?.name}</Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {!!practiceAddress && (
                <Box marginTop={0.4}>
                  <Tooltip title="Practice's Address">
                    <Box display={"flex"} flexDirection={"row"}>
                      <Place sx={{ marginRight: 1 }} fontSize={"small"} />
                      <Typography
                        sx={{
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {formatUSAddress({
                          city: practiceAddress?.city ?? "",
                          line: [
                            ...(practiceAddress?.line_1
                              ? [practiceAddress?.line_1]
                              : []),
                            ...(practiceAddress?.line_2
                              ? [practiceAddress?.line_2]
                              : []),
                          ],
                          state: practiceAddress?.state ?? "",
                          postalCode: practiceAddress?.postal_code ?? "",
                          country: practiceAddress?.country ?? "",
                        })}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Box>
      );
    },
  },
  //COMMENTED OUT BY dduran. we might need this soon
  // {
  //   field: "practice",
  //   headerName: "Practice",
  //   flex: 1,
  //   renderCell: (params) => {
  //     const { practice } = params.row as DirectoryProvider;

  //     const primaryOrganization =
  //       practice?.find?.(
  //         ({ is_primary, status }) => !!is_primary && status === "active"
  //       ) ?? practice?.find?.(({ status }) => status === "active");
  //     const primaryOrganizationAddress =
  //       primaryOrganization?.address?.find?.(
  //         ({ is_preferred }) => !!is_preferred
  //       ) ?? primaryOrganization?.address?.[0];
  //     const npi =
  //       primaryOrganization?.indentifier?.find(({ code }) => code === "NPI")
  //         ?.value ?? "";

  //     if (!primaryOrganization) return <></>;

  //     return (
  //       <Box justifyContent={"center"}>
  //         {!!npi && (
  //           <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
  //             <Tooltip title="NPI">
  //               <Badge sx={{ marginRight: 1 }} fontSize={"small"} />
  //             </Tooltip>
  //             <Typography>{npi}</Typography>
  //           </Box>
  //         )}
  //         <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
  //           <Tooltip title="Practice">
  //             <Business sx={{ marginRight: 1 }} fontSize={"small"} />
  //           </Tooltip>
  //           <Typography>{primaryOrganization?.name}</Typography>
  //         </Box>
  //         <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
  //           <Tooltip title="Practice">
  //             <Place sx={{ marginRight: 1 }} fontSize={"small"} />
  //           </Tooltip>
  //           <Typography
  //             sx={{
  //               whiteSpace: "normal",
  //               wordBreak: "break-word",
  //             }}
  //           >
  //             {formatUSAddress({
  //               city: primaryOrganizationAddress?.city ?? "",
  //               line: [
  //                 ...(primaryOrganizationAddress?.line_1
  //                   ? [primaryOrganizationAddress?.line_1]
  //                   : []),
  //                 ...(primaryOrganizationAddress?.line_2
  //                   ? [primaryOrganizationAddress?.line_2]
  //                   : []),
  //               ],
  //               state: primaryOrganizationAddress?.state ?? "",
  //               postalCode: primaryOrganizationAddress?.postal_code ?? "",
  //               country: primaryOrganizationAddress?.state ? "US" : "",
  //             })}
  //           </Typography>
  //         </Box>
  //       </Box>
  //     );
  //   },
  // },
  {
    field: "action",
    headerName: " ",
    flex: 0.3,
    renderCell: (params) => {
      const provider = params.row as DirectoryProviderLookUp;

      return (
        <LoadingButton
          variant="contained"
          color="info"
          disabled={isLoading || isDisabled}
          startIcon={
            isCreationMode || !providerActionButtonIcon ? (
              <Add />
            ) : (
              providerActionButtonIcon
            )
          }
          loading={isLoading}
          onClick={() => {
            if (isCreationMode) return onAdd(provider);
            onProviderAction?.(provider);
          }}
        >
          {isCreationMode || !providerActionButtonTitle
            ? "Add"
            : providerActionButtonTitle}
        </LoadingButton>
      );
    },
  },
];