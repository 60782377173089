import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, CardContent, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { GoalWrapper } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { useGoals, usePlanDefinitions } from 'src/@nicheaim/fhir-react';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import useObjectState from 'src/hooks/useObjectState';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { GoalGridRowData } from '../../../../../@types/crs/case';

import { getGoalsIds, getParentGoalGridRows } from 'src/sections/crs/helpers/common';
import './styles.css';
import {
  PlanDefinitionWrapper,
  WrappedPlanDefinition,
} from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';

import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedCondition } from '../../../../../@nicheaim/fhir-base/wrappers/Condition';
import useLocales from 'src/hooks/useLocales';
import CollapsibleCard from 'src/sections/crs/common/CollapsibleCard';
import GoalSuggestionsGrid, { GoalSuggestion } from './GoalSuggestionsGrid';
import GoalModal from '../GoalsGrid/GoalModal';
import { GoalsPermissions } from 'src/sections/crs/types';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import { CollapsibleProvider } from 'src/contexts/CollapsibleContext';
import GoalsGridWithFilters from '../GoalsGrid/GoalsGridWithFilters';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface GoalsByPathwayProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  carePlan: WrappedCarePlan | null;
  refreshEntities: Function;
  planDefinitions: WrappedPlanDefinition[];
  conditions: WrappedCondition[];
  observations: WrappedObservation[];
  refreshTasks: Function;
  openCollapseExternal: boolean;
  permissions: GoalsPermissions;
  title?: string;
}
interface GoalsByPathwayState {
  selectedPathway: string;
}

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

const GoalsByPathway = React.memo(
  ({
    patient,
    carePlan,
    tasks,
    refreshEntities,
    planDefinitions,
    conditions,
    observations,
    refreshTasks,
    openCollapseExternal,
    permissions,
    title,
  }: GoalsByPathwayProps) => {
    const [{ selectedPathway }, updateState] = useObjectState<GoalsByPathwayState>({
      selectedPathway: 'all',
    });

    const { i18n } = useLocales();

    const { componentsData } = useTenantConfigData();
    const { showSubGoals } = componentsData ?? {};

    const {
      valueSets: [pathways, goalStatuses],
      isLoading: isPathWaysLoading,
    } = useValueSetsByIdentifiers(['ph-path-ways', 'ph-goal-lifecycle-status']);

    const [plandefinitionFilterTags, setPlandefinitionFilterTags] = useState<string[]>([]);
    const [goalsSuggestions, setGoalSuggestions] = useState<GoalSuggestion[]>([]);
    const [selectedGoalSuggestion, setSelectedGoalSuggestion] = useState<GoalSuggestion | null>(
      null
    );
    const [isGoalModalOpen, setIsGoalModalOpen] = useState(false);

    const [planDefinitionSuggestions] = usePlanDefinitions({
      map: PlanDefinitionWrapper,
      filter: {
        _tag: plandefinitionFilterTags.join(','),
      },
      autofetch: !!plandefinitionFilterTags.length,
    });

    const getGoalsSuggestions = useCallback((): GoalSuggestion[] => {
      if (!planDefinitionSuggestions || !planDefinitionSuggestions.length) return [];
      const usedPlanDefinitions =
        carePlan?.activity?.reduce?.<string[]>((planDefinitions, activity) => {
          const { detail } = activity;
          const planDefinitionsReferences =
            detail?.instantiatesCanonical?.filter?.(
              (reference) => reference?.split?.('/')?.[0] === 'PlanDefinition'
            ) ?? [];
          if (!planDefinitionsReferences.length) return planDefinitions;
          return [
            ...planDefinitions,
            ...planDefinitionsReferences.map((reference) => reference.split('/')[1]),
          ];
        }, []) ?? [];

      const goalSuggestions: GoalSuggestion[] = [];

      conditions.map((condition) => {
        const { code, system } = condition?.code?.coding?.[0] ?? {};
        if (!code || !system) return;
        const matchingPlanDefinitions = planDefinitionSuggestions.filter(
          ({ goal }) =>
            !!goal?.some?.(
              ({ addresses }) =>
                !!addresses?.some?.(
                  ({ coding }) =>
                    !!coding?.find?.((coding) => coding?.code === code && coding.system === system)
                )
            )
        );
        if (!matchingPlanDefinitions.length) return;
        matchingPlanDefinitions.map((matchingPlanDefinition) => {
          if (!matchingPlanDefinition.id) return;
          if (usedPlanDefinitions.includes(matchingPlanDefinition.id)) return;
          const goalSuggestion = goalSuggestions.find(
            ({ planDefinition }) => planDefinition === matchingPlanDefinition
          );
          if (goalSuggestion) {
            if (
              !!goalSuggestion.addresses.find((condition) => {
                const { code: suggestionConditionCode, system: suggestionConditionSystem } =
                  condition?.code?.coding?.[0] ?? {};
                return code === suggestionConditionCode && system === suggestionConditionSystem;
              })
            )
              return;
            goalSuggestion.addresses.push(condition);
            return;
          }
          goalSuggestions.push({ addresses: [condition], planDefinition: matchingPlanDefinition });
        });
      });

      observations.map((observation) => {
        const { code, system } = observation?.valueCodeableConcept?.coding?.[0] ?? {};
        if (!code || !system) return;
        const matchingPlanDefinitions = planDefinitionSuggestions.filter(
          ({ goal }) =>
            !!goal?.some?.(
              ({ addresses }) =>
                !!addresses?.some?.(
                  ({ coding }) =>
                    !!coding?.find?.((coding) => coding?.code === code && coding.system === system)
                )
            )
        );
        if (!matchingPlanDefinitions.length) return;
        matchingPlanDefinitions.map((matchingPlanDefinition) => {
          if (!matchingPlanDefinition.id) return;
          if (usedPlanDefinitions.includes(matchingPlanDefinition.id)) return;
          const goalSuggestion = goalSuggestions.find(
            ({ planDefinition }) => planDefinition === matchingPlanDefinition
          );
          if (goalSuggestion) {
            if (
              !!goalSuggestion.addresses.find((observation) => {
                const coding = observation?.getSugestionCoding();
                const { code: suggestionConditionCode, system: suggestionConditionSystem } = coding ?? {};
                return code === suggestionConditionCode && system === suggestionConditionSystem;
              })
            )
              return;
            goalSuggestion.addresses.push(observation);
            return;
          }
          goalSuggestions.push({
            addresses: [observation],
            planDefinition: matchingPlanDefinition,
          });
        });
      });

      return goalSuggestions;
    }, [planDefinitionSuggestions, carePlan, conditions, observations]);

    useEffect(() => {
      const goalsSuggestions = getGoalsSuggestions();
      setGoalSuggestions(goalsSuggestions);
    }, [getGoalsSuggestions]);

    useEffect(() => {
      if (!conditions.length && !observations.length) return;

      let tagsToFilterBy: string[] = [];

      if (conditions.length) {
        const conditionsTagsToFilterBy = conditions.reduce<string[]>((tagFilters, condition) => {
          const { code, system } = condition?.code?.coding?.[0] ?? {};
          if (!code || !system) return tagFilters;

          const tag = `${system}|${code}`;
          if (tagFilters.includes(tag)) return tagFilters;
          return [...tagFilters, tag];
        }, []);

        tagsToFilterBy = tagsToFilterBy.concat(conditionsTagsToFilterBy);
      }

      if (observations.length) {
        const conditionsTagsToFilterBy = observations.reduce<string[]>(
          (tagFilters, observation) => {
            const { code, system } = observation?.valueCodeableConcept?.coding?.[0] ?? {};
            if (!code || !system) return tagFilters;

            const tag = `${system}|${code}`;
            if (tagFilters.includes(tag)) return tagFilters;
            return [...tagFilters, tag];
          },
          []
        );

        tagsToFilterBy = tagsToFilterBy.concat(conditionsTagsToFilterBy);
      }

      setPlandefinitionFilterTags(tagsToFilterBy);
    }, [conditions, observations]);

    // Get goals
    const groupedGoals = useMemo(() => carePlan?.getGroupedGoals(), [carePlan]);
    const [goals, { refresh: refreshGoals }] = useGoals({
      filter: {
        _id: getGoalsIds(groupedGoals),
      },
      map: GoalWrapper,
      autofetch: !!getGoalsIds(groupedGoals),
    });

    const goalGridRows = useMemo(
      () =>
        getParentGoalGridRows(
          goals,
          tasks,
          conditions,
          groupedGoals,
          goalStatuses?.asList?.() ?? []
        ),
      [goals, tasks, groupedGoals, goalStatuses, conditions]
    );

    const handlePathwayTabChange = (_: React.SyntheticEvent, pathway: string) => {
      updateState({
        selectedPathway: pathway,
      });
    };

    const pathwaysList: ValueSetComposeIncludeConcept[] = [
      pathwayAll,
      ...(pathways?.asList?.() ?? []),
    ];

    const statusesList = useMemo(() => {
      const gridGoals = getStatusCountByPathway(goalGridRows);
      const statuses = {
        ...gridGoals,
        ...(goalsSuggestions.length > 0 && {
          info: `${goalsSuggestions.length} ${
            goalsSuggestions.length > 0
              ? i18n('case.details.suggestions', 'crs')
              : i18n('case.details.suggestion', 'crs')
          }`,
        }),
      };
      return statuses;
    }, [goalGridRows, goalsSuggestions]);

    return (
      <PermissionsProvider permissions={permissions}>
        {!isPathWaysLoading ? (
          <CollapsibleProvider
            title={i18n('case.details.listofGoals.title', 'crs')}
            openCollapseExternal={openCollapseExternal}
            isCollapsible={true}
          >
            <Tabs
              scrollButtons={'auto'}
              variant={'scrollable'}
              value={selectedPathway}
              onChange={handlePathwayTabChange}
              aria-label="PathWay Tab"
            >
              {pathwaysList.map(({ code, display }) => {
                const statuses = getStatusCountByPathway(getGoalsByPathWay(code, goalGridRows));
                return (
                  <Tab
                    key={code}
                    label={
                      <Box display={'flex'} flexDirection={'row'} sx={{ width: '100%' }}>
                        <Typography sx={{ fontSize: '1em', width: '100%', whiteSpace: 'nowrap' }}>
                          {display}
                        </Typography>
                        {Object.entries(statuses).map(([severity, count]) => (
                          <SeverityStatusCount
                            key={severity}
                            count={count}
                            severity={severity as AlertSeverity}
                          />
                        ))}
                      </Box>
                    }
                    value={code}
                  />
                );
              })}
            </Tabs>
            <CardContent className={'cardContent'} sx={{ padding: 0 }}>
              {!!goalsSuggestions.length && (
                <Box sx={{ marginTop: 2, paddingX: 3, marginBottom: 6 }}>
                  <CollapsibleCard
                    isOpenExternal={true}
                    title={i18n('case.details.goalsSuggestions', 'crs')}
                    titleSx={{ fontWeight: 'bold', fontSize: '1em' }}
                    titleVariant="body2"
                  >
                    <GoalSuggestionsGrid
                      goalSuggestions={goalsSuggestions}
                      onGoalSuggestionAction={(goalSuggestion) => {
                        setIsGoalModalOpen(true);
                        setSelectedGoalSuggestion(goalSuggestion);
                      }}
                    />
                  </CollapsibleCard>
                </Box>
              )}
              <Box sx={{ marginTop: 2, paddingBottom: 0 }}>
                {pathwaysList.map((pathway) => {
                  const { code } = pathway;
                  const goalGridRowsByPathway = getGoalsByPathWay(code, goalGridRows);
                  return (
                    <TabPanel key={code} pathway={code} selectedPathway={selectedPathway}>
                      <GoalsGridWithFilters
                        includeSubGoals={showSubGoals?.includeSubGoals}
                        noOfSubGrids={showSubGoals?.noOfSubGrids}
                        onSuccessfulEdit={() => {
                          refreshGoals();
                        }}
                        onSuccessfulCreation={() => {
                          refreshEntities();
                        }}
                        showCategory={code === pathwayAll.code}
                        planDefinitions={planDefinitions}
                        conditions={conditions}
                        observations={observations}
                        patient={patient as WrappedPatient}
                        goals={goalGridRowsByPathway}
                        defaultPathway={code !== pathwayAll.code ? pathway : undefined}
                        pathways={(pathways?.asList?.() ?? []) as ValueSetComposeIncludeConcept[]}
                        showPathwayFilter={selectedPathway === pathwayAll.code}
                        carePlan={carePlan as WrappedCarePlan}
                        onTaskSuccessfulCreation={() => {
                          refreshEntities();
                        }}
                        onTaskSuccessfulEdit={() => {
                          refreshTasks();
                        }}
                        onConditionUnlinkSuccess={refreshGoals}
                        statusesList={statusesList}
                        title={title}
                      />
                    </TabPanel>
                  );
                })}
              </Box>
            </CardContent>
            <GoalModal
              planDefinitions={planDefinitions}
              conditions={conditions}
              observations={observations}
              goalToEdit={null}
              onSuccessfulEdit={() => {
                refreshGoals();
              }}
              onSuccessfulCreation={() => {
                refreshEntities();
              }}
              defaultPathway={
                selectedGoalSuggestion?.planDefinition?.getGoalCategoryCoding?.() as ValueSetComposeIncludeConcept
              }
              pathways={(pathways?.asList?.() ?? []) as ValueSetComposeIncludeConcept[]}
              patient={patient}
              open={isGoalModalOpen}
              carePlan={carePlan}
              onClose={() => {
                setIsGoalModalOpen(false);
                setSelectedGoalSuggestion(null);
              }}
              defaultAddresses={selectedGoalSuggestion?.addresses}
              defaultPlanDefinition={selectedGoalSuggestion?.planDefinition}
            />
          </CollapsibleProvider>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              my: 2,
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}
      </PermissionsProvider>
    );
  }
);

interface TabPanelProps {
  children: React.ReactNode;
  selectedPathway: string;
  pathway: string;
}

const TabPanel = ({ children, selectedPathway, pathway }: TabPanelProps): JSX.Element => (
  <>{selectedPathway === pathway ? <>{children}</> : null}</>
);

type DueDateSeverities = AlertSeverity.SUCCESS | AlertSeverity.ERROR | AlertSeverity.WARNING;

export type StatusSeverityCount = Record<DueDateSeverities, number>;

const getStatusCountByPathway = (goals: GoalGridRowData[]): StatusSeverityCount => {
  const statuses: StatusSeverityCount = {
    [AlertSeverity.ERROR]: 0,
    [AlertSeverity.WARNING]: 0,
    [AlertSeverity.SUCCESS]: 0,
  };
  for (const {
    endDate: { severity },
  } of goals) {
    if (statuses.hasOwnProperty(severity as AlertSeverity))
      statuses[severity as DueDateSeverities]++;
  }
  return statuses;
};

const getGoalsByPathWay = (pathwayCode: string | null, goals: GoalGridRowData[]) => {
  if (!pathwayCode || pathwayCode === pathwayAll.code) return goals;
  return goals.filter(({ pathway }) => pathway === pathwayCode);
};

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export default GoalsByPathway;
