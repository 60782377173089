import { OrganizationAclsArray, OrganizationManager } from 'src/@types/organization';
import client from '../../../services/api/_client';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import HttpAgent from 'src/api/agent';
import { getUUID } from 'src/utils/url';

export async function getOrganization(uuid: string) {
  const { data } = await client.get(`/organizations/${uuid}`);

  return data;
}

export async function getUsersByOrganization(organizationIds: string[], isNull?: boolean) {
  const query = isNull ? '?isNull=true' : '';
  const { data } = await client.get(`/organizations/${organizationIds}/users${query}`);

  return data;
}

export async function createOrganization(payload: OrganizationManager) {
  const { data } = await client.post('/organizations', payload);

  return data;
}

export async function updateOrganization(payload: OrganizationManager, uuid?: string) {
  const { data } = await client.put(`/organizations/${uuid}`, payload);

  return data;
}

export async function deleteOrganization(uuid: string) {
  const { data } = await client.delete(`/organizations/${uuid}`);

  return data;
}

export async function getOrganizationsByIds(organizationIds: string[]) {
  const { data } = await client.get(`/organizations/list/${organizationIds}`);

  return data;
}

export async function getOrganizationByAcl(currentUser: any, acls: Partial<OrganizationAclsArray>) {

  const userOrg = getUUID(currentUser?.organization_fhir_uri) ?? "";

  const getChilOrganization = (acls.ownAndChildOrg || acls.childOrg) && 
    currentUser?.organizations?.filter((item:string) =>  item !== userOrg);

  let getOrgByAcl: string[] = []; 

  if (acls.all && checkAclValidation({ acls: acls.all })) {
    const allOrgsUsers = await HttpAgent.Organization.getAll();
    getOrgByAcl = allOrgsUsers?.map((item) => item?.fhirId) ?? [];
  } else {
    const aclActions = [
      { acl: acls.ownAndChildOrg, action: () =>
        [...new Set([userOrg, ...getChilOrganization])]
      },
      { acl: acls.ownOrg, action: () => [userOrg] },
      { acl: acls.childOrg, action: () => getChilOrganization }
    ];
  
    getOrgByAcl = aclActions.reduce((acc, { acl, action }) => {
      if (acc.length === 0 && acl && checkAclValidation({ acls: acl })) {
        return action();
      }
      return acc;
    }, []);
  }

  return getOrgByAcl;
}
