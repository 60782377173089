import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useBinary, usePatient } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import useRouteHanderByTenant from 'src/hooks/useRouteHanderByTenant';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { imageFromAttachment } from 'src/utils/formatPhoto';

interface PatientCardSearchProps {
  id: string;
}

const PatientCardSearch = ({ id }: PatientCardSearchProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fileimg, setfileImg] = useState({});
  const closePatient = usePatientTabsStore((s) => s.closePatient);
  const [patient, { isFetching, isError }] = usePatient(id, {
    map: PatientWrapper,
  });
  const [binary] = useBinary(getFhirIdFromEntity(patient?.photo?.[0].url || ''));

  const urlMatch = useMatch('/dashboard/crs/patient/:patientId');
  const isActive = usePatientTabsStore((s) => s.currentPatientId === id && urlMatch);
  const routeHandlerByTenant = useRouteHanderByTenant();

  useEffect(() => {
    if (binary) {
      setfileImg(binary);
    }
  }, [patient, binary]);

  function onClick() {
    const patientRoute = routeHandlerByTenant.redirectRouteAfterCreatePatient(id || '');
    navigate(patientRoute);
  }

  function onClose() {
    const nextPatient = closePatient(id);

    if (!nextPatient) {
      navigate(`/dashboard/app`);
    } else {
      const patientRoute = routeHandlerByTenant.redirectRouteAfterCreatePatient(nextPatient || '');
      navigate(patientRoute);
    }
  }

  useEffect(() => {
    if (isError) closePatient(id);
  }, [id, isError]);

  return (
    <Card
      variant="outlined"
      sx={{
        background: '#f4f6f8',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        borderColor: isActive ? theme.palette.primary.main : undefined,
      }}
    >
      {isFetching ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: '16px 8px',
            width: '60px',
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Stack
            alignItems="center"
            direction="row"
            onClick={onClick}
            spacing={2}
            sx={{ p: '16px 8px' }}
          >
            <Avatar src={imageFromAttachment(fileimg)} />
            <Stack>
              <Tooltip title={patient?.getFullName()}>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 150,
                  }}
                  variant="subtitle2"
                >
                  {patient?.getFullName()}
                </Typography>
              </Tooltip>
              <Typography variant="caption">{patient?.getMRN()?.value}</Typography>
            </Stack>
          </Stack>
          <IconButton
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
              padding: 0,
            }}
            onClick={onClose}
          >
            <Iconify
              icon="eva:close-circle-outline"
              sx={{
                color: 'red',
                height: 20,
                width: 20,
              }}
            />
          </IconButton>
        </>
      )}
    </Card>
  );
};

export default PatientCardSearch;
