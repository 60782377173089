import { Box, Card, Grid } from '@mui/material';

import FilterIconButton from 'src/components/FilterIconButton';

import PageContainer, { PageContainerProps } from './PageContainer';

export interface ListContainerProps extends Omit<PageContainerProps, 'title'> {
  children?: React.ReactNode | null;
  listTitle: string;
  showFilterIcon?: boolean;
  onFilterIconPress?: () => void;
  isFiltering?: boolean;
}

const ListContainer = ({
  pageTitle,
  listTitle,
  onFilterIconPress,
  children,
  showFilterIcon = true,
  breadCrumbsLinks = [],
  isFiltering = false,
}: ListContainerProps) => (
  <PageContainer pageTitle={pageTitle} title={listTitle} breadCrumbsLinks={breadCrumbsLinks}>
    <Grid item xs={12} sx={{ marginTop: 4 }}>
      <Card sx={{ width: '100%', paddingTop: 4, paddingX: 1 }}>
        <Box
          sx={{
            marginBottom: 4,
            paddingX: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {showFilterIcon && (
            <Box>
              <FilterIconButton
                isFiltering={isFiltering}
                onClick={() => {
                  onFilterIconPress?.();
                }}
              />
            </Box>
          )}
        </Box>
        {children ?? null}
      </Card>
    </Grid>
  </PageContainer>
);

export default ListContainer;
