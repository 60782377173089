import { WrappedCondition } from '../../../../../@nicheaim/fhir-base/wrappers/Condition';
import CustomDrawer, { CustomDrawerProps } from '../../../../../components/CustomDrawer';
import { Box, Button, CircularProgress, Container, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import moment from 'moment';

import { Option } from '../../../../../@types/crs/case';
import { spreadSxProp } from '../../../../../utils/cssStyles';
import { WrappedGoal } from '../../../../../@nicheaim/fhir-base/wrappers/Goal';
import { getFormattedDatePeriod } from 'src/utils/dates';

export type OnConditionUnlink = (
  condition: WrappedCondition,
  goal: WrappedGoal
) => Promise<boolean>;

interface GoalConditionDrawerProps extends Omit<CustomDrawerProps, 'title' | 'anchor'> {
  conditionExternal?: WrappedCondition | null;
  goalExternal?: WrappedGoal | null;

  conditionId?: string;
  onUnlink?: OnConditionUnlink;
}

const { valueTextFontSize, blackTextColor }: { [k: string]: SxProps } = {
  valueTextFontSize: {
    fontSize: '0.8rem',
  },
  blackTextColor: {
    color: '#212b36',
  },
};

const GoalConditionDrawer = ({
  onUnlink,
  conditionExternal,
  goalExternal,
  onCloseIconButtonClick,
  ...props
}: GoalConditionDrawerProps) => {
  const condition = useMemo(() => {
    if (conditionExternal) return conditionExternal;
    return null;
  }, [conditionExternal]);

  const goal = useMemo(() => {
    if (goalExternal) return goalExternal;
    return null;
  }, [goalExternal]);

  const registrationDate = moment(condition?.recordedDate ?? null);

  const conditionDetails: LabelValueProps[] = [
    {
      label: 'Code',
      value: condition?.code?.coding?.[0]?.code ?? '',
    },
    {
      label: 'Category',
      value: condition?.category?.[0]?.coding?.[0]?.display ?? '',
    },
    {
      label: 'Registration Date',
      value: registrationDate.isValid() ? registrationDate.format('MM/DD/YYYY') : '',
    },
    {
      label: 'Period',
      value: getFormattedDatePeriod({
        start: condition?.onsetPeriod?.start,
        end: condition?.onsetPeriod?.end,
      }),
    },
  ];

  return (
    <CustomDrawer
      {...props}
      title="Condition Details"
      anchor="right"
      containerSx={{ width: '25vw' }}
      contentContainerSx={{ padding: 0 }}
      onCloseIconButtonClick={onCloseIconButtonClick}
    >
      {condition ? (
        <>
          <Container sx={{ backgroundColor: '#f4f6f8' }}>
            <Section title="Condition">
              <Typography sx={[blackTextColor, valueTextFontSize]}>
                {condition?.code?.text ?? ''}
              </Typography>
            </Section>
            <Section title="Details">
              {conditionDetails.map((conditionDetail) => (
                <>
                  {conditionDetail?.label !== 'Status' &&
                  conditionDetail?.label !== 'View Detail' ? (
                    <LabelValue key={conditionDetail.label} {...conditionDetail} />
                  ) : null}
                </>
              ))}
            </Section>
          </Container>
          {!!(onUnlink && goal) && (
            <Container sx={{ mt: 3 }}>
              <Button
                sx={{ height: 48 }}
                fullWidth
                onClick={async () => {
                  const response = await onUnlink?.(
                    condition as WrappedCondition,
                    goal as WrappedGoal
                  );
                  if (response) onCloseIconButtonClick();
                }}
                variant="contained"
                color="error"
              >
                Unlink Condition
              </Button>
            </Container>
          )}
        </>
      ) : (
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      )}
    </CustomDrawer>
  );
};

type LabelValueKeys = Option<string | React.ReactNode>;

interface LabelValueProps extends LabelValueKeys {
  labelSx?: SxProps;
}

const LabelValue = ({ label, value, labelSx }: LabelValueProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: 2 }}>
    <Typography sx={[valueTextFontSize, { flex: 1, color: '#637381' }, ...spreadSxProp(labelSx)]}>
      {label}
    </Typography>
    <Typography sx={[valueTextFontSize, blackTextColor, { flex: 2 }]}>{value}</Typography>
  </Box>
);

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <Box sx={{ my: 3 }}>
    <Typography sx={{ fontWeight: 'bold', mb: 3 }}>{title}</Typography>
    {children}
  </Box>
);

export default GoalConditionDrawer;
