import { 
  Backdrop,
  Button, 
  CircularProgress, 
  Divider, 
  Drawer, 
  Grid, 
  List, 
  ListItem, 
  MenuItem, 
  Stack,
  Typography } 
  from "@mui/material";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import useLocales from 'src/hooks/useLocales';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from "src/@nicheaim/fhir-react";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";

type Props = {
  isOpen: boolean;
  handlerIsOpen: Function;
  handlerConfirmNATOptIn: (data:any) => Promise<any>;
}

export default function ConfirmNatOptIn({
  isOpen,
  handlerIsOpen,
  handlerConfirmNATOptIn
}: Props) {
  const { i18n } = useLocales();
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const [optMethods] = useValueSet('opt-in-methods', { map: ValueSetWrapper });
  const [optDecisions] = useValueSet('opt-in-decisions', { map: ValueSetWrapper });

  const schema = Yup.object().shape({
    opt_out_decision: Yup.string().required("Opt-Out Decision is required"),
    method: Yup.string().required("Method is required"),
  });

  const defaultValues = {
    opt_out_decision: "",
    method: "",
  };

  const methods = useForm({ resolver: yupResolver(schema), defaultValues });
 
  const {
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isOpen) {
      reset({
        opt_out_decision: '',
        method: '',
      })
    }
  }, [isOpen]);

  const onSubmit = async(values: any) => {
    setOpenBackdrop(true);
    try {
      await handlerConfirmNATOptIn(values);
      handlerIsOpen(false);
    } catch (error) {
      console.log("failed confirming HJ Opt-Out");
    }
    setOpenBackdrop(false);
  };
  
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <List component="nav">
          <ListItem>
            <Grid container justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  {i18n('patientEngagement.details.workflow.confirmConsent.title',{ns:'engagement'})}
                </Button>
                <Button
                  onClick={() => {
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container direction="column" marginTop={2} width={800}>
              <Grid
                container
                direction="row"
                alignItems="center"
                minHeight={60}
              >
                <Grid container item xs={4}>
                  <Typography>{i18n('patientEngagement.details.workflow.confirmConsent.decision',{ns:'engagement'})}</Typography>
                </Grid>

                <Grid container item xs={8}>
                  <RHFSelect name="opt_out_decision" label="" fullWidth={true}>
                    <MenuItem key={""} value={""} disabled></MenuItem>
                    {optDecisions?.asList().map((opt_out_decision) => (
                      <MenuItem
                        key={opt_out_decision.code}
                        value={opt_out_decision.display}
                      >
                        {opt_out_decision.display}
                      </MenuItem>
                    ))}
                  </RHFSelect> 
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                minHeight={60}
              >
                <Grid container item xs={4}>
                  <Typography>{i18n('patientEngagement.details.workflow.confirmConsent.method',{ns:'engagement'})}</Typography>
                </Grid>

                <Grid container item xs={8}>
                  <RHFSelect name="method" label="" fullWidth={true}>
                    <MenuItem key={""} value={""} disabled></MenuItem>
                    {optMethods?.asList().map((method) => (
                      <MenuItem
                        key={method.code}
                        value={method.display}
                      >
                        {method.display}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </FormProvider>
    </Drawer>
  );
}