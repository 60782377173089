import {
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { WrappedPractitioner } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import Viewer from 'src/sections/crs/common/Viewer';
import { getMimeIcon } from 'src/utils/getFileFormat';
import DocumentEditForm from './DocumentEditForm';

type Props = {
  row: any;
  patientId?: string;
  editAttachment: boolean;
  refreshDocumentReference: any;
  wrappedPractionersIndex?: {};
};

export default function DocumentRow({
  row,
  patientId,
  refreshDocumentReference,
  editAttachment,
  wrappedPractionersIndex,
}: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenModalEdit(false);
    refreshDocumentReference();
  };

  const handleOpenViewer = () => {
    setOpenViewer((prev) => !prev);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => handleOpenViewer()}>
            <Iconify icon={'carbon:view'} sx={{ width: 28, height: 28 }} />
            <Iconify
              sx={{ width: 18, height: 18, mt: 1 }}
              icon={
                row?.content?.[0].attachment.contentType &&
                getMimeIcon(row.content?.[0].attachment.contentType).props.icon
              }
            />
          </IconButton>
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row?.content?.[0].attachment.title}
        </TableCell>
        <TableCell>
          {row.author?.map((e: any, index: number) => {
            let authorDisplay = e.display ? e.display : e.text;

            if (
              !authorDisplay &&
              wrappedPractionersIndex &&
              e.reference &&
              e.reference.includes('Practitioner/')
            ) {
              const splitRef = e.reference.split('/');
              const practitionerId = splitRef[splitRef.length - 1];
              const wPractitioner = wrappedPractionersIndex[practitionerId] as WrappedPractitioner;

              if (wPractitioner) {
                authorDisplay = wPractitioner.getFullName();
              }
            }

            return (
              <Chip
                clickable={false}
                size="small"
                key={index}
                label={authorDisplay}
                sx={{ m: 0.2 }}
              />
            );
          })}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row.custodian?.display}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row.type?.coding?.[0].display}
        </TableCell>
        <TableCell>
          {row.category?.map((e: any, index: number) => (
            <Chip
              clickable={false}
              size="small"
              key={index}
              label={e.coding?.[0].display ? e.coding?.[0].display : e.text}
              sx={{ m: 0.2 }}
            />
          ))}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {row.date && format(new Date(row.date), 'MMM dd, yyyy hh:mm')}
        </TableCell>
        {editAttachment && (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={[
                <MenuItem key={'menu-i-edit-'} onClick={() => handleEdit(row)}>
                  <Iconify icon={'mdi:pencil'} />
                  Edit
                </MenuItem>,
              ]}
            />
          </TableCell>
        )}
      </TableRow>
      <DocumentEditForm open={openModalEdit} onClose={handleCloseEdit} document={data} wrappedPractionersIndex={wrappedPractionersIndex} />
      <Dialog fullWidth maxWidth="md" open={openViewer} onClose={handleCloseViewer}>
        <DialogTitle>Preview Document</DialogTitle>
        <Viewer documentReference={row} typeResource="documentReference" patientId={patientId} />
      </Dialog>
    </>
  );
}
