import { Assignment as AssignmentIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TASK_TYPES } from 'src/sections/crs/constants';
import { MenuTaskHandler } from 'src/sections/crs/types';
import { getAssessmentFromTask, isAssessmentReadyToStart } from '../../../../helpers/common';

export const assessmentHandler: MenuTaskHandler = {
  icon: <AssignmentIcon />,
  taskType: TASK_TYPES.ASSESSMENT,
  label: 'Start Assessment',
  conditionToShow: (task) => {
    const inputAssessment = getAssessmentFromTask(task);
    return !!inputAssessment;
  },
  handler: (task, externalHandler) => {
    if (externalHandler) {
      return externalHandler(task);
    }

    const inputAssessment = getAssessmentFromTask(task);
    if (!inputAssessment) return;

    if (isAssessmentReadyToStart(inputAssessment)) {
      window.open(inputAssessment?.valueUrl, '_blank');
    }
  },
};

export const taskNATHandler: MenuTaskHandler = {
  icon: <OpenInNewIcon />,
  taskType: TASK_TYPES.NAT,
  label: 'Detail',
  conditionToShow: (task) => {
    const taskCode = task?.code?.coding?.find(({ code }) => code === 'completed-nat-assessment');

    return taskCode ? true : false;
  },
  handler: (task) => {
    window.open(`${PATH_DASHBOARD.nat.task}/${task?.id}`, '_blank');
  },
};

export const commonMenuTaskHandlers: MenuTaskHandler[] = [assessmentHandler, taskNATHandler];
