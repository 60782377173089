import moment from 'moment';
import { getRisk, getRiskColorPalette } from 'src/sections/nat/helpers/report';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { MeasureReportGroups, SplitReference, Stratifier } from 'src/@types/nat/report';
import {
  IdentifierCode,
  IDENTIFIER_MAP,
  URLS_GENDER_IDENTITY,
  WrappedPatient,
} from 'src/@nicheaim/fhir-base/wrappers/Patient';
import {
  Coding,
  ContactPoint,
  Identifier,
  Reference,
  MeasureReport,
  MeasureReportGroup,
  MeasureReportGroupStratifierStratum,
  CodeSystem,
  Extension,
  Period,
  Practitioner,
  Address,
  ActivityDefinition,
  FhirResource,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import {
  GENDER_IDENTITY_CODE_SYSTEM_URL,
  GENDER_IDENTITY_EXTENSION_DEFINITION_URL,
  PROVIDER_CERTIFICATION_IDENTIFIER,
  PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE,
  PROVIDER_LICENSE_IDENTIFIER,
  PROVIDER_LICENSE_IDENTIFIER_CODING_CODE,
} from 'src/config';
import {
  GOAL_RELATIONSHIP_EXTENSION_URL,
  NPI_IDENTIFIER_SYSTEM,
  PROVIDER_LINK_IDENTIFIER,
} from 'src/sections/crs/constants';
import { trimMultipleWhiteSpaces } from './string';
import { WrappedPractitioner } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { WrappedOrganization } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { WrappedPractitionerRole } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { WrappedRelatedPerson } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { QualificationType, SimpleQualification } from 'src/@types/crs/providerDirectory';

export function getIdFromReference(reference: string) {
  if (reference) {
    const fhirIdArray = reference?.split('/');
    return fhirIdArray?.length > 0 ? fhirIdArray.slice(-1)[0] : null;
  }
  return null;
}

export function getFhirIdFromEntity(fhirId: string) {
  const fhirIdArray = fhirId?.split('/');
  return fhirIdArray?.length > 0 ? fhirIdArray.slice(-1)[0] : null;
}

export function referenceEquals(
  reference: Reference,
  resource:
    | Reference
    | {
        resourceType: string;
        id?: string;
      }
) {
  const isResource = 'resourceType' in resource;
  const [resourceType, id] = isResource
    ? [resource.resourceType, resource.id]
    : resource.reference?.split('/') || [resource.type, resource.id];

  return (
    reference.reference === `${resourceType}/${id}` ||
    (reference.type === resourceType && reference.id === id)
  );
}

export function setStatus(statusList: any, statusValue: any) {
  const statusLabel = statusList
    .filter((e: any) => e.value === statusValue)
    .map((s: any) => s.label);
  return statusLabel;
}

export function getAddressFormated(address: any) {
  const objectAddress: { [key: string]: string } = {};
  for (let i: number = 0; i < address?.line?.length; i++) {
    objectAddress[`line_${i}`] = address?.line?.[i];
  }

  delete address?.line;
  if (address?.period) {
    address.period.start = moment.utc(new Date(address.period.start)).format('MM/DD/YYYY');
    address.period.end = moment.utc(new Date(address.period.end)).format('MM/DD/YYYY');
  }

  address = { ...address, ...objectAddress };

  return address;
}

export function mergeLineAddress(address: any) {
  const line = [address?.line_0, address?.line_1];

  delete address.line_0;
  delete address.line_1;

  const originalAddress = address;
  address = { ...originalAddress, line };

  return address;
}

export function getPrimaryPhone(telecom: any): ContactPoint | null {
  let mainPhone = null;
  if (telecom && telecom?.length > 0) {
    const phones = telecom.filter((t: any) => t?.system === 'phone');
    if (phones && phones?.length > 0) {
      mainPhone = phones[0];
    }
  }
  return mainPhone;
}

export function getIdentifier(patient: any, identifierCode: IdentifierCode) {
  let identifierType = IDENTIFIER_MAP.get(identifierCode);
  const type = identifierType?.type?.coding?.[0].code?.toLowerCase();
  const system = identifierType?.system;
  if (system) {
    const value =
      patient?.identifier
        ?.filter((identifier: any) => identifier.type === type)
        .map((e: any) => ({
          ...identifierType,
          use: e.use,
          value: e.value,
          period: { start: new Date().toISOString() },
        }))[0] || null;
    return value;
  }
}

export function getSplitReference(value: string): SplitReference | null {
  if (!value) return null;
  const splitedValue = value.split('/');
  const splitRe: SplitReference = {
    resourceType: splitedValue[0],
    uuid: splitedValue[1],
  };

  return splitRe;
}

export function getMeasureReportGroups(measureReport: MeasureReport | undefined) {
  let res: MeasureReportGroups = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  measureReport?.group?.forEach((item: MeasureReportGroup) => {
    if (isMedicalComplexityGroup(item)) {
      res.medicalComplex = item;
    } else if (isSocialComplexityGroup(item)) {
      res.socialComplex = item;
    } else if (isServiceIntegrationLevelsGroup(item)) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
}

function isSystemInCoding(list: Coding[] | undefined, matchingSystem: string | undefined) {
  if (matchingSystem === undefined || list === undefined) return false;

  for (let i = 0; i < list.length; i++) {
    if (list[i].system === matchingSystem) return true;
  }

  return false;
}

export function isMedicalComplexityGroup(group: MeasureReportGroup) {
  const matchingSystem = process.env.REACT_APP_SYSTEM_MEDICAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isSocialComplexityGroup(group: MeasureReportGroup) {
  const matchingSystem = process.env.REACT_APP_SYSTEM_SOCIAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isServiceIntegrationLevelsGroup(group: MeasureReportGroup) {
  const matchingSystem = process.env.REACT_APP_SYSTEM_SERVICE_INTEGRATION_LEVELS;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export const getMeasureReportGroupValue = (group: MeasureReportGroups) => {
  const medicalComplex =
    group?.medicalComplex?.stratifier?.[0].stratum?.[0].measureScore?.value || '';
  const socialComplex =
    group?.socialComplex?.stratifier?.[0].stratum?.[0].measureScore?.value || '';
  const serviceIntegration =
    group?.serviceIntegration?.stratifier?.[0].stratum?.[0].measureScore?.value || '';

  return {
    medicalComplex,
    socialComplex,
    serviceIntegration,
  };
};

const getObsevationBySystem = (group: WrappedObservation, system: any) => {
  return group?.code?.coding?.[0]?.system === system;
};

export const getObservationtypeValue = (observations: WrappedObservation[] | undefined) => {
  let res: MeasureReportGroups = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  observations?.forEach((item: any) => {
    if (getObsevationBySystem(item, process.env.REACT_APP_SYSTEM_MEDICAL_COMPLEXITY)) {
      res.medicalComplex = item;
    } else if (getObsevationBySystem(item, process.env.REACT_APP_SYSTEM_SOCIAL_COMPLEXITY)) {
      res.socialComplex = item;
    } else if (
      getObsevationBySystem(item, process.env.REACT_APP_SYSTEM_SERVICE_INTEGRATION_LEVELS)
    ) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
};

export const getIdentifierByType = (identifier: Identifier[] | undefined, typeText: string) => {
  return identifier?.find((item) => item?.type?.text === typeText)?.value;
};

export const getStratumData = (stratum: MeasureReportGroupStratifierStratum) => {
  const riskColorPalette = getRiskColorPalette(
    getRisk(stratum.component?.[0]?.code?.coding?.[0]?.code ?? 'indeterminate-risk')
  );

  const printableStratumData: Stratifier = {
    stratifier_text: stratum.component?.[1]?.value?.text ? stratum.component?.[1]?.value?.text : '',
    risk_text: stratum.component?.[0]?.code?.coding?.[0]?.display || '',
    risk_color: riskColorPalette?.internalBackgroundColor || '#ffffff',
    risk_note:
      (stratum?.component?.[0]?.value?.coding?.[0]?.display !==
        stratum?.component?.[0]?.code?.coding?.[0]?.code &&
        stratum?.component?.[0]?.value?.coding?.[0]?.display) ||
      '',
    score: stratum.measureScore?.value === undefined ? '' : String(stratum.measureScore?.value),
  };

  return printableStratumData;
};

export const getMeasureReportGroupPrintable = (group: MeasureReportGroups) => {
  const { medicalComplex, socialComplex, serviceIntegration } = group;

  return {
    medical_complexity: {
      color: getComplexityScoreRiskColorPalette(medicalComplex)?.internalBackgroundColor,
      value:
        medicalComplex?.stratifier?.[0].stratum?.[0].measureScore?.value === undefined
          ? ''
          : String(medicalComplex?.stratifier?.[0].stratum?.[0].measureScore?.value),
    },
    social_complexity: {
      color: getComplexityScoreRiskColorPalette(socialComplex)?.internalBackgroundColor,
      value:
        socialComplex?.stratifier?.[0].stratum?.[0].measureScore?.value === undefined
          ? ''
          : String(socialComplex?.stratifier?.[0].stratum?.[0].measureScore?.value),
    },
    service_integration_levels: {
      color: getComplexityScoreRiskColorPalette(serviceIntegration)?.internalBackgroundColor,
      value:
        serviceIntegration?.stratifier?.[0].stratum?.[0].measureScore?.value === undefined
          ? ''
          : String(serviceIntegration?.stratifier?.[0].stratum?.[0].measureScore?.value),
    },
  };
};

const getComplexityScoreRiskColorPalette = (group?: MeasureReportGroup) => {
  const riskValue = group?.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code?.coding?.[0]?.code;

  const riskCodeParsed = riskValue === undefined ? 'none' : getRisk(riskValue);

  return getRiskColorPalette(riskCodeParsed);
};

export const getTelecomBySystem = (telecom: ContactPoint[] | null | undefined, system: string) =>
  telecom?.find?.(({ system: telecomSystem }) => system === telecomSystem);

export const getTelecomsBySystem = (
  telecom: ContactPoint[] | null | undefined,
  system: string
): ContactPoint[] =>
  telecom?.filter?.(({ system: telecomSystem }) => system === telecomSystem) ?? [];

export const getTelecomsSystems = (
  telecom: ContactPoint[] | null | undefined
): ContactPoint['system'][] => [...new Set(telecom?.map?.(({ system }) => system) ?? [])];

export function phoneFormat(phoneString: any) {
  let cleaned = ('' + phoneString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }

  return phoneString;
}

export function getAgeGroup(patientAgeInMonths: number, codeSystem: CodeSystem) {
  const concept = codeSystem?.property?.filter((item) => {
    const [minStr, maxStr] = item?.code?.split('-') || [null, null];
    const min = Number(minStr);
    const max = Number(maxStr);

    return (
      patientAgeInMonths !== null &&
      !isNaN(min) &&
      !isNaN(max) &&
      patientAgeInMonths >= min &&
      patientAgeInMonths <= max
    );
  });
  return concept;
}

export function setSILDisplay(sil: string) {
  let result: string = '';

  switch (sil) {
    case '1':
      result = 'Service Integration Level 1';
      break;
    case '2':
      result = 'Service Integration Level 2';
      break;
    case '3':
      result = 'Service Integration Level 3';
      break;
    default:
      result = sil;
  }

  return result;
}

export const getGenderIdentityExtension = ({ code, display, system }: Coding): Extension => {
  const show = system === 'ValueSet/administrative-gender' ?? false;
  return {
    url: show ? URLS_GENDER_IDENTITY[0] : GENDER_IDENTITY_EXTENSION_DEFINITION_URL,
    valueCodeableConcept: {
      coding: [
        {
          code,
          ...(!show && { display }),
          system: system ?? GENDER_IDENTITY_CODE_SYSTEM_URL,
        },
      ],
      ...(!show && { text: display }),
    },
  };
};

export const checkIfIdentifierPeriodIsValid = ({ start, end }: Period): boolean => {
  const periodStart = moment(start ?? null);

  const periodEnd = moment(end ?? '9999-12-31');

  return !!(
    periodStart.isValid() &&
    periodEnd.isValid() &&
    moment().isBetween(periodStart, periodEnd, undefined, '[]')
  );
};

export const getValidProviderDirectoryLinkIdentifiers = (
  identifiers: Identifier[]
): Identifier[] => {
  const providerDirectoryLinks =
    identifiers?.filter?.(
      ({ type, system }) =>
        type?.coding?.[0]?.code === PROVIDER_LINK_IDENTIFIER.type.coding[0].code &&
        system === PROVIDER_LINK_IDENTIFIER.system
    ) ?? [];

  return providerDirectoryLinks.reduce<Identifier[]>(
    (validProviderDirectoryLinks, providerDirectoryLink) => {
      const isVerified = checkIfIdentifierPeriodIsValid(providerDirectoryLink?.period ?? {});
      if (isVerified) return [...validProviderDirectoryLinks, providerDirectoryLink];
      return validProviderDirectoryLinks;
    },
    []
  );
};

export const getPractitionerProviderDirectoryId = (
  practitioner: Practitioner | undefined | null
): string | null => {
  const providerDirectoryIdentifiers = getValidProviderDirectoryLinkIdentifiers(
    practitioner?.identifier ?? []
  );
  if (providerDirectoryIdentifiers?.[0]?.value) return providerDirectoryIdentifiers[0].value;

  return null;
};

export const getNPIFromIdentifier = (identifiers: Identifier[]): string =>
  identifiers.find(({ system }) => system === NPI_IDENTIFIER_SYSTEM)?.value ?? '';

export const formatUSAddress = (address: Address): string => {
  let formattedAddress = '';

  if (address?.line && address?.line?.length > 0) {
    formattedAddress += address.line.join(', ') + '\n';
  }

  if (address?.city || address?.state || address?.postalCode) {
    formattedAddress +=
      `${address.city || ''}${address.city && address.state ? ', ' : ''}${address.state || ''} ${
        address.postalCode || ''
      }`.trim() + '\n';
  }

  if (address?.country) {
    formattedAddress += address.country;
  }

  return formattedAddress.trim();
};

export const getTelecomValues = (
  system: ContactPoint['system'],
  telecom: ContactPoint[]
): string[] =>
  telecom.filter((telecom) => telecom.system === system).map(({ value }) => value ?? '') ?? [];

export const verifyIfPractitionerIsVerified = (
  practitioner: Practitioner | undefined | null
): boolean => {
  const providerDirectoryIdentifiers = getValidProviderDirectoryLinkIdentifiers(
    practitioner?.identifier ?? []
  );
  return !!providerDirectoryIdentifiers.length;
};

export const getValuesFromReference = (
  reference: string | null | undefined
): [string | null, string | null] => {
  if (!reference) return [null, null];
  const splitted = reference.split('/');
  return [splitted?.[0] ?? null, splitted?.[1] ?? null];
};
export const getDynamicValueExpressionByPath = (
  dynamicValues: ActivityDefinition['dynamicValue'],
  path: string
): string | undefined =>
  dynamicValues?.find?.(({ path: dynamicValuePath }) => path === dynamicValuePath)?.expression
    ?.expression;

export const getResourcesAndRelatedResources = (
  resources: FhirResource[] | null | undefined,
  resourceType: string
) => [
  getDistinctResources(resources?.filter?.(({ resourceType: rt }) => resourceType === rt) ?? []),
  getDistinctResources(resources?.filter?.(({ resourceType: rt }) => resourceType !== rt) ?? []),
];

export const getDistinctResources = (resources: FhirResource[]) =>
  resources.reduce<FhirResource[]>((distinctResources, resource) => {
    if (distinctResources.find(({ id }) => resource.id === id)) return distinctResources;
    return [...distinctResources, resource];
  }, []);

export const orderTelecomByRank = (telecom: ContactPoint[] | null | undefined) =>
  telecom?.sort?.(({ rank: rankA }, { rank: rankB }) => (rankA ?? 0) - (rankB ?? 0)) ?? [];

export const getExtensionArrayByURLs = (resource: any, urls: string[]): Coding[] | any => {
  let array: any[] = [];

  const filter = resource?.extension
    ? resource.extension.filter((e) => urls.some((x) => x === e?.url))
    : null;

  filter?.forEach((obj) => {
    if (obj.extension) {
      obj.extension.forEach((ext) => {
        if (ext.valueCoding) {
          array.push(ext.valueCoding);
        }
      });
    }

    if (obj.valueCodeableConcept && obj.valueCodeableConcept.coding) {
      array.push(...obj.valueCodeableConcept.coding);
    }
  });

  return array;
};

export const getReferenceId = (reference: string | null | undefined) =>
  reference?.split?.('/')?.[1];

export const getReferenceType = (reference: string | null | undefined) =>
  reference?.split?.('/')?.[0];

export const getReferenceDisplayByResource = (
  resource: FhirResource,
  includedResources: FhirResource[]
) => {
  let display = '';
  switch (resource.resourceType) {
    case 'Practitioner':
      display = trimMultipleWhiteSpaces(
        (resource as WrappedPractitioner)?.getFullName?.() ?? ''
      ).trim();
      break;
    case 'Organization':
      display = (resource as WrappedOrganization)?.name ?? '';
      break;
    case 'PractitionerRole':
      const practitionerRole = resource as WrappedPractitionerRole;

      const practitionerName = practitionerRole?.getPractitionerName?.(
        (includedResources ?? []) as WrappedPractitioner[]
      );
      const roleName = practitionerRole?.getRoleName?.();
      const organizationName = practitionerRole?.getOrganizationName?.(
        (includedResources ?? []) as WrappedOrganization[]
      );
      display = [practitionerName, roleName, organizationName]
        .filter((data) => !!data?.trim?.())
        .join(' | ');
      break;
    case 'RelatedPerson':
      display = (resource as WrappedRelatedPerson)?.getFullName?.() ?? '';
      break;
    case 'Patient':
      display = (resource as WrappedPatient)?.getFullName?.() ?? '';
      break;
  }

  return display;
};

export const getGoalRelationExtensionPayload = (type: Coding, goalId: string): Extension => ({
  url: GOAL_RELATIONSHIP_EXTENSION_URL,
  extension: [
    {
      url: 'type',
      valueCodeableConcept: {
        coding: [type],
      },
    },
    {
      url: 'target',
      valueReference: {
        reference: `Goal/${goalId}`,
      },
    },
  ],
});

export const getQualificationTypeFromIdentifier = (
  code: string,
  display: string
): SimpleQualification['type'] => {
  switch (code) {
    case PROVIDER_CERTIFICATION_IDENTIFIER.type.coding[0].code:
      return QualificationType.CERTIFICATION;
    case PROVIDER_LICENSE_IDENTIFIER_CODING_CODE:
      return QualificationType.LICENSE;
    case PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE:
      return QualificationType.TAXONOMY;
    default:
      return display ?? 'Other';
  }
};
