import moment from 'moment';
import {
  Edit as EditIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  AppRegistration as AssignProgramIcon,
  CopyAllRounded as CopySeviceRequestIcon,
} from '@mui/icons-material';
import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import { Option } from 'src/@types/crs/case';
import { NestedMenuItem } from 'mui-nested-menu';
import useObjectState from 'src/hooks/useObjectState';
import ActionButton from 'src/components/ActionButton';
import { Box, MenuItem, SxProps } from '@mui/material';
import { getOption } from 'src/sections/crs/helpers/common';
import DatePickerMoment from 'src/components/DatePickerMoment';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import { serviceRequestStatuses, ServiceRequestStatus } from '../outbound/OutboundReferralAdd';

export type onResourceAction = (serviceRequest: WrappedServiceRequest) => void;

export interface OutboundActionMenuProps {
  onEditServiceRequest: onResourceAction;
  serviceRequest: WrappedServiceRequest;
  onAssignProgram?: onResourceAction;
  updateServiceRequest: any;
  onCopyServiceRequest?: onResourceAction;
}

interface ServiceRequestActionState {
  status: string | null;
  endDate: moment.Moment | null;
  serviceRequestStatusOptions: Option<string>[];
}

const OutboundActionMenu = ({
  serviceRequest,
  onEditServiceRequest,
  onAssignProgram,
  updateServiceRequest,
  onCopyServiceRequest,
}: OutboundActionMenuProps) => {
  const user = useAuth();

  const [{ status, endDate }, updateState] = useObjectState<ServiceRequestActionState>({
    status: serviceRequest?.status,
    serviceRequestStatusOptions: [],
    endDate: null,
  });

  useEffect(() => {
    const selectedStatus = getOption<ServiceRequestStatus>(
      serviceRequestStatuses,
      serviceRequest?.status as ServiceRequestStatus['value']
    );

    updateState({
      status: selectedStatus?.value ?? null,
      endDate: moment(serviceRequest.occurrencePeriod?.end ?? null).isValid()
        ? moment(serviceRequest.occurrencePeriod?.end)
        : null,
      serviceRequestStatusOptions: [
        ...serviceRequestStatuses,
        ...(selectedStatus &&
        !serviceRequestStatuses.find(({ value }) => value === selectedStatus?.value)
          ? [selectedStatus]
          : []),
      ],
    });
  }, [serviceRequest]);

  return (
    <ActionButton
      renderChildren={(isMenuOpen, setIsMenuOpen) => (
        <Box>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            onClick={() => {
              onEditServiceRequest(serviceRequest);
            }}
            leftIcon={<EditIcon htmlColor="#00ab55" />}
            rightIcon={null}
            label="Edit"
            parentMenuOpen={isMenuOpen}
          />
          {onAssignProgram && (
            <NestedMenuItem
              sx={nestedMenuItemStyles}
              onClick={() => {
                onAssignProgram(serviceRequest);
              }}
              leftIcon={<AssignProgramIcon />}
              rightIcon={null}
              label="Assign Program"
              parentMenuOpen={isMenuOpen}
            />
          )}
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            leftIcon={<AccessTimeIcon />}
            rightIcon={<ChevronRightIcon />}
            label="Status"
            parentMenuOpen={isMenuOpen}
          >
            {serviceRequestStatuses.map((serviceRequestStatus) => (
              <NestedMenuItem
                onClick={async () => {
                  serviceRequest.status = serviceRequestStatus.value;
                  updateState({ status: serviceRequestStatus.value });
                  const response = await updateServiceRequest(serviceRequest);
                }}
                key={serviceRequestStatus.value}
                sx={nestedMenuItemStyles}
                leftIcon={serviceRequestStatus.value === status ? <CheckIcon /> : null}
                rightIcon={null}
                label={serviceRequestStatus.label}
                parentMenuOpen={isMenuOpen}
              />
            ))}
          </NestedMenuItem>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            leftIcon={<CalendarTodayIcon />}
            rightIcon={<ChevronRightIcon />}
            label="Due Date"
            parentMenuOpen={isMenuOpen}
          >
            <NestedMenuItem
              sx={nestedMenuItemStyles}
              leftIcon={<CheckIcon />}
              rightIcon={null}
              label={
                getSeverityDueDateData(moment(endDate?.toISOString() ?? null))?.formattedDueDate ??
                ''
              }
              parentMenuOpen={isMenuOpen}
            />
            <MenuItem>
              <DatePickerMoment
                value={endDate}
                label={'Select New Due Date'}
                onChange={async (dueDate) => {
                  updateState({ endDate: dueDate });
                  if (!dueDate?.isValid?.()) return;
                  if (!serviceRequest.occurrencePeriod)
                    serviceRequest.occurrencePeriod = { end: undefined };
                  serviceRequest.occurrencePeriod.end = dueDate?.toISOString();
                  const response = await updateServiceRequest(serviceRequest);
                  setIsMenuOpen(false);
                }}
              />
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            onClick={() => {
              setIsMenuOpen(false);

              if (onCopyServiceRequest) {
                onCopyServiceRequest(serviceRequest);
              }
            }}
            leftIcon={<CopySeviceRequestIcon />}
            rightIcon={null}
            label="Copy"
            parentMenuOpen={isMenuOpen}
          />
        </Box>
      )}
    />
  );
};

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};

export default OutboundActionMenu;
