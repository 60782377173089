import { IApiClient } from "src/api/clients/api_client";

export interface DoFormRecordNJInckRegistrationResponse {
  doform_record_id: string;
  form_key: string;
  form_name: string;
  name: string;
  age: number;
  assigned_CIM: string;
  nat_assigned_CIM: string;
  beneficiary_address: string;
  beneficiary_cell_phone_number: string;
  beneficiary_contact_email: string;
  beneficiary_date_of_birth: Date;
  beneficiary_first_name: string;
  beneficiary_gender: string;
  beneficiary_last_name: string;
  beneficiary_medicaid_ID: string;
  beneficiary_middle_name: string;
  beneficiary_PCP: string;
  beneficiary_s_primary_care_pro: string;
  best_contact_for_parent: string;
  child_on_medicaid: string;
  city: string;
  computer_access: string;
  contact_status: string;
  email_cell_of_referral: string;
  get_total_years: number;
  get_hours: number;
  guarantor_cell_phone_number: string;
  guarantor_contact_email: string;
  guarantor_first_name: string;
  guarantor_last_name: string;
  health_plan_name: string;
  internet_access: string;
  is_your_child_between_the_ages: string;
  is_this_your_permanent_address: string;
  last_outreach: Date;
  last_outreach_Count: string;
  medicaid_id: string;
  more_info: string;
  name_of_referral_source: string;
  parent_guardian_cell_phone: string;
  notes: string;
  parent_guardian_email: string;
  preferred_language: string;
  request_no: string;
  state: string;
  status: string;
  source_id: string;
  source_name: string;
  source_type: string;
  todayx: Date;
  type_of_referral: string;
  welcome_message_email: string;
  welcome_message_message: string;
  which_county_are_you_located_in: string;
  zip_code: string;
  njinck_lastupdate_on: string;
  njinck_status_code: string;
  njinck_status_text: string;
  njinck_natweb_patient_url: string;
  njinck_natweb_request_task_url: string;
  njinck_natweb_incident_url: string;
}

export class DoFormApiClient {
  apiBase: string;

  doFormApiClient: IApiClient;

  constructor(doFormApiClient: IApiClient) {
    this.apiBase = 'nj/doform';
    this.doFormApiClient = doFormApiClient;
  }

  async getDoFormRecordNJInckRegistration(id: string): 
    Promise<DoFormRecordNJInckRegistrationResponse | undefined> 
  {
    try {
      const response = await this.doFormApiClient.get<DoFormRecordNJInckRegistrationResponse>(
        `${this.apiBase}/${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class DoFormService {

  doFormApiClient: DoFormApiClient;

  constructor(doFormApiClient: DoFormApiClient) {
    this.doFormApiClient = doFormApiClient;
  }

  async getDoFormRecordNJInckRegistration(id: string): 
    Promise<DoFormRecordNJInckRegistrationResponse | undefined> 
  {
    const response = await this.doFormApiClient.getDoFormRecordNJInckRegistration(id);
    if(!response) return undefined;
    return response;
  }
}