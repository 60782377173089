import { InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify';

type Props = {
  filterMrn: string;
  onFilterMrn: (value: string) => void;
};

export default function TableToolbar({
  filterMrn,
  onFilterMrn
}: Props) {
  return (
    <Stack direction="row" spacing={2}>

      <TextField
        value={filterMrn}
        onChange={(event) => onFilterMrn(event.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
