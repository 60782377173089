import OpenInNew from '@mui/icons-material/OpenInNew';
import { IconButton, IconButtonProps, SvgIconProps, Tooltip } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface OpenInNewTabIconButtonProps extends IconButtonProps {
  iconProps?: SvgIconProps;
  url?: string;
  toolTipText?: string;
}

const OpenInNewTabIconButton = ({
  iconProps,
  url,
  onClick,
  toolTipText = 'Go to Details',
  ...iconButtonProps
}: OpenInNewTabIconButtonProps) => (
  <Tooltip title={toolTipText}>
    <span>
      <IconButton
        {...iconButtonProps}
        onClick={() => {
          if (!url) return;
          window.open(url, '_blank');
        }}
      >
        <OpenInNew
          sx={[
            {
              width: 25,
              height: 25,
            },
            ...spreadSxProp(iconProps?.sx),
          ]}
        />
      </IconButton>
    </span>
  </Tooltip>
);

export default OpenInNewTabIconButton;
