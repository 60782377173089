import { useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import parse from 'autosuggest-highlight/parse';
import useLocales from 'src/hooks/useLocales';

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

export interface AddressAutoCompleteProps
  extends Omit<AutocompleteProps<any, undefined, undefined, undefined>, 'renderInput' | 'options'> {
  label?: string;
  address: PlaceType | null;
  onAddressChange: (value: PlaceType) => void;
}

const AddressAutoComplete = ({
  label,
  onAddressChange,
  address,
  ...autocompleteProps
}: AddressAutoCompleteProps) => {
  const { i18n } = useLocales();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({});

  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);

  useEffect(() => {
    setAutocompleteOptions(placePredictions);
  }, [JSON.stringify(placePredictions)]);

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      renderInput={(params: TextFieldProps) => (
        <TextField {...params} label={label ?? i18n('addAddress.enterAddress', 'crs')} fullWidth />
      )}
      onChange={(_: any, newValue: PlaceType) => {
        onAddressChange(newValue);
      }}
      onInputChange={(event: any, newInputValue: string) => {
        getPlacePredictions({
          input: newInputValue,
        });
      }}
      value={address}
      options={autocompleteOptions}
      style={{ width: '100%' }}
      loading={isPlacePredictionsLoading}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches?.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...autocompleteProps}
    />
  );
};

export default AddressAutoComplete;
