import { useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import EditableCard from '../EditableCard';
import { MutationActions, Person, User } from '../../types';
import { getFormattedPersonDOB, getPersonNames } from '../../utils/personHelpers';
import { capitalize } from 'lodash';
import PersonDetailsEditDrawer, { OnPersonDetailsSave } from './PersonDetailsEditDrawer';
import { useSnackbar } from 'notistack';
import useFirebaseCollectionMutation from '../../hooks/useFirebaseCollectionMutation';
import { collectionNames } from '../../utils/constants';

export interface PersonGeneralInfoEditableCardProps {
  person: Person | null;
  user?: User | null;
  onPersonSave?: VoidFunction;
}

const PersonGeneralInfoEditableCard = ({
  person,
  onPersonSave,
  user,
}: PersonGeneralInfoEditableCardProps) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: mutatePerson } = useFirebaseCollectionMutation<Person>({
    collectionName: collectionNames.PERSONS,
  });
  const { mutateAsync: mutateUser } = useFirebaseCollectionMutation<User>({
    collectionName: collectionNames.USERS,
  });

  const handleOnPersonSave: OnPersonDetailsSave = useCallback(
    async (newPersonData) => {
      try {
        await mutatePerson({
          data: { ...newPersonData },
          action: MutationActions.MODIFY,
          id: person?.id as string,
        });
        if (user) {
          await mutateUser({
            data: {
              owned_person: {
                ...(user?.owned_person ?? {}),
                personBasicData: {
                  ...(user?.owned_person?.personBasicData ?? {}),
                  ...newPersonData,
                },
              },
            } as User,
            action: MutationActions.MODIFY,
            id: user.id as string,
          });
        }
        enqueueSnackbar('Person Details updated succesfully', {
          variant: 'success',
        });
        onPersonSave?.();
        setIsEditDrawerOpen(false);
      } catch (error) {
        enqueueSnackbar(
          'There was an issue while trying to update the Person . If the problem persist please contact support',
          {
            variant: 'error',
          }
        );
      }
    },
    [person, enqueueSnackbar, onPersonSave, mutatePerson, mutateUser, user]
  );

  const { firstName, middleName, lastName } = getPersonNames(person?.name);

  return (
    <>
      <EditableCard
        title="Personal Details"
        onEditButtonPress={() => {
          setIsEditDrawerOpen(true);
        }}
      >
        <Grid container spacing={3} sx={{ marginBottom: 1 }}>
          <Grid item xs={4}>
            <InfoItem label="First Name" value={firstName} />
          </Grid>
          <Grid item xs={4}>
            <InfoItem label="Middle Name" value={middleName} />
          </Grid>
          <Grid item xs={4}>
            <InfoItem label="Last Name" value={lastName} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InfoItem label="Gender" value={person?.gender} />
          </Grid>
          <Grid item xs={4}>
            <InfoItem label="Date of Birth" value={getFormattedPersonDOB(person?.birthDate)} />
          </Grid>
        </Grid>
      </EditableCard>
      <PersonDetailsEditDrawer
        isOpen={isEditDrawerOpen}
        person={person}
        onDrawerClose={() => {
          setIsEditDrawerOpen(false);
        }}
        onPersonSave={handleOnPersonSave}
      />
    </>
  );
};

export interface InfoItemProps {
  label: string;
  value: string | null | undefined;
}

export const InfoItem = ({ label, value }: InfoItemProps) => (
  <Box>
    <Typography
      variant="body2"
      sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: 0.4 }}
    >
      {label}
    </Typography>
    <Typography variant="body2">{capitalize(value ?? '')}</Typography>
  </Box>
);

export default PersonGeneralInfoEditableCard;
