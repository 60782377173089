import { useEffect, useState } from 'react';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import GoalsGrid, { GoalFilters, GoalsGridBaseProps, initialGoalFilters } from './GoalsGrid';
import { GoalGridRowData } from 'src/@types/crs/case';
import { cleanSearchInput } from 'src/utils/string';
import { searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import moment from 'moment';
import { isBetweenDates } from 'src/utils/dates';
import { OnConditionUnlink } from './GoalConditionDrawer';
import useNotiMessage from 'src/hooks/useNotiMessage';
import * as goalApiHelpers from 'src/services/api/case';
import useLocales from 'src/hooks/useLocales';

export interface GoalsGridWithFiltersProps extends Omit<GoalsGridBaseProps, 'onConditionUnlink'> {
  onConditionUnlinkSuccess?: VoidFunction;
}

const GoalsGridWithFilters = ({
  goals,
  onConditionUnlinkSuccess,
  ...gridProps
}: GoalsGridWithFiltersProps) => {
  const {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    searchTextFieldValue,
    filters,
    onApplyFilters,
  } = useGridFilters<GoalFilters>(initialGoalFilters);

  const [filteredGoals, setFilteredGoals] = useState<GoalGridRowData[]>(goals);

  const { showErrorMessage, showSuccessMessage } = useNotiMessage();

  const { i18n } = useLocales();

  useEffect(() => {
    const searchInput = cleanSearchInput(searchTextFieldValue ?? '');
    if (
      searchInput.length < 3 &&
      !filters.endDate &&
      !filters.startDate &&
      !filters.selectedPathways.length &&
      !filters.status.length
    )
      return setFilteredGoals(goals);

    setFilteredGoals(
      filterGoalGridRows(goals, {
        filters,
        searchTextFieldValue: searchTextFieldValue,
      })
    );
  }, [searchTextFieldValue, filters, goals]);

  const handleConditionUnlink: OnConditionUnlink = async (condition, goal) => {
    let conditionIds: string[] = [];

    if (goal.addresses) {
      conditionIds = goal.addresses
        .map((x) => x?.reference?.split('/')?.[1] ?? '')
        .filter((x) => x !== condition?.id && !!x);
    }

    const payload = {
      conditionIds,
    };

    try {
      await goalApiHelpers?.editGoal(payload, goal?.id as string);
      showSuccessMessage(i18n('case.details.listofGoals.conditionUnlinkSuccess', 'crs'));
      onConditionUnlinkSuccess?.();
      return true;
    } catch (error) {
      showErrorMessage(i18n('case.details.listofGoals.conditionUnlinkError', 'crs'));
    }
    return false;
  };

  return (
    <GoalsGrid
      {...gridProps}
      onSearchTextFieldChange={onSearchTextFieldChange}
      goals={filteredGoals}
      onFilterDrawerOpen={onFilterDrawerOpen}
      searchTextFieldValue={searchTextFieldValue}
      filterValues={filters}
      isFilterDrawerOpen={isFilterDrawerOpen}
      onApplyFilters={onApplyFilters}
      onFilterDrawerClose={onFilterDrawerClose}
      onConditionUnlink={handleConditionUnlink}
    />
  );
};

const filterGoalGridRows = (
  goalGridRows: GoalGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<GoalFilters>
): GoalGridRowData[] => {
  let filteredGoalGridRows = [...goalGridRows];
  const searchByString = cleanSearchInput(searchTextFieldValue ?? '').toLowerCase();
  if (searchByString.length >= 3) {
    filteredGoalGridRows = filteredGoalGridRows.filter((goalGridRow) =>
      searchIfContainedInObj(
        goalGridRow,
        ['goal', 'endDate.message', 'status', 'pathwayDisplay'],
        searchByString
      )
    );
  }
  const { startDate, endDate, selectedPathways, status } = filters;
  if (!endDate && !startDate && !selectedPathways.length && !status.length)
    return filteredGoalGridRows;

  return filteredGoalGridRows.filter((goalRow) => {
    const goal = goalRow.wrappedGoal;
    const goalStartDate = moment(goal?.startDate ?? null);
    const goalEndDate = moment(goal?.getEndDate?.() ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!goalStartDate.isValid() || !goalEndDate.isValid()) return false;

      if (!isBetweenDates(goalStartDate, goalEndDate, startDate, endDate)) return false;
    }

    if (startDate?.isValid?.()) {
      if (!goalStartDate.isValid()) return false;
      if (!goalStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!goalEndDate.isValid()) return false;
      if (!goalEndDate.isSameOrBefore(endDate)) return false;
    }

    if (selectedPathways.length) {
      if (!selectedPathways.find((pathway) => pathway.code === goal?.getPathway?.())) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.code === goal?.lifecycleStatus)) return false;
    }
    return true;
  });
};

export default GoalsGridWithFilters;
