import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import uuidv4 from 'src/utils/uuidv4';
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';

export interface TableSortMenuPopoverOptions {
  label: string;
  value: string;
  icon: JSX.Element;
}

interface TableSortMenuPopoverProps {
  headerColumnName: string;
  options: TableSortMenuPopoverOptions[];
  onSort: (field: string, order: 'asc' | 'desc') => void;
}

export default function TableSortMenuPopover({
  headerColumnName,
  options,
  onSort,
}: TableSortMenuPopoverProps) {
  const generateInitialState = () => {
    const initialState = {};
    options?.forEach((option) => {
      initialState[option.value] = 'asc';
    });
    return initialState;
  };

  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const [sortBy, setSortBy] = useState('');

  const [switchStates, setSwitchStates] = useState(generateInitialState);

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeSortBy = (value: any) => {
    if (sortBy === value) {
      setSortBy(''); 
      setSwitchStates(generateInitialState());
    } else {
      setSortBy(value);
    }
    onSort(sortBy === value ? null : value, 'asc');
  };

  const handleSwitchChange = (value) => (event) => {
    const newOrder = event.target.checked ? 'desc' : 'asc';
    setSwitchStates((prevState) => ({
      ...prevState,
      [value]: newOrder,
    }));
    onSort(sortBy, newOrder);
  };

  return (
    <>
      <Button
        color="inherit"
        disableFocusRipple
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<ExpandCircleDownOutlinedIcon />}
        sx={{
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {headerColumnName}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 'auto' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disabledArrow={true}
      >
        {options?.map((option) => {
          const { icon, label, value } = option;
          const isChecked = switchStates[value] === 'desc';

          return (
            <Box sx={{ flexGrow: 1 }} key={uuidv4()}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ m: 1 }}
              >
                <Typography variant="subtitle1">
                  {icon}
                  {label}
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<Iconify icon="material-symbols:circle-outline" color="#637381" />}
                      checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
                      checked={sortBy === value}
                      onChange={() => handleChangeSortBy(value)}
                    />
                  }
                  label={null}
                  sx={{ marginLeft: 'auto', marginRight: 0 }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography variant="caption" sx={{ mr: 1 }}>
                  ASC
                </Typography>

                <Switch
                  disabled={value !== sortBy}
                  checked={isChecked}
                  onChange={handleSwitchChange(value)}
                />
                <Typography variant="caption">DESC</Typography>
              </Stack>
            </Box>
          );
        })}
      </MenuPopover>
    </>
  );
}
