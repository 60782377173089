// @ts-nocheck
import { IconButton, Box, Typography, Link, Modal } from '@mui/material';
import { ActionBar, SummaryTable } from 'src/sections/careflow/common';
import { useCarePlans } from 'src/@nicheaim/fhir-react';
import { CarePlan } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useObjectState from 'src/hooks/useObjectState';
import Iconify from 'src/components/Iconify';
import AddCommunication from '../../communication/AddCommunication';
import NotesContextMenu from './components/NotesContextMenu';
import { useDialogStore } from 'src/stores/dialog';
import { DataTableColumn } from 'src/components/table/DataTable';
import moment from 'moment';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import parse from 'html-react-parser';
import AddModal from '../../common/AddModal';
import { Note, useNotes } from 'src/services/api/ccm-notes';
import fhirSystem from 'src/fhir/system';
import ActivityDetailsModal from '../care-plan/components/ActivityDetailsModal';
import api from 'src/services/api';
import ScheduleActivity from '../../tasks-activities/components/Activities/ScheduleActivity';
import { useQueryClient } from '@tanstack/react-query';

type NotesSummaryProps = {
  patient: {
    id: string;
  };
  isExpanded: boolean;
  onExpand: (index: number) => void;
  onActivityNotesEdit?: (taskId: string) => void;
};

const NotesSummary = ({
  patient,
  isExpanded,
  onExpand,
}: NotesSummaryProps) => {
  const queryClient = useQueryClient();
  const { confirmDialog } = useDialogStore();
  const { data: _notes = [], refetch: refreshNotes } = useNotes(patient.id, {
    enabled: !!patient?.id,
  });
  const [, { update: updateCarePlan }] = useCarePlans({
    autofetch: false,
  });
  const [{ selectedCommunication, showAddCommunicationModal, showEditActivityModal, activityToSelect, showActivityDetails }, updateState] = useObjectState({
    selectedCommunication: null as Note | null,
    showAddCommunicationModal: false,
    showActivityDetails: false,
    showEditActivityModal: false,
    activityToSelect: null,
  });

  const columns: DataTableColumn[] = [
    {
      header: 'Date',
      field: 'date',
      render(date: Date | null) {
        return date
          ? `${moment(date).format('MMM DD Y')} - ${moment(date).format('hh:mm A')}`
          : 'N/A';
      },
    },
    {
      header: 'Type/Tool',
      field: 'source',
      render(source: Note['source']) {
        const contactType = source.concept?.coding?.find(
          (c) => c.system === fhirSystem.extension.ActivityDefinition.contactType.asString()
        );
        const assessment = source.concept?.coding?.find(
          (c) => c.system === fhirSystem.extension.ActivityDefinition.dst.asString()
        );

        return assessment || contactType ? (
          <>
            <Typography>
              {`${contactType ? contactType.display : 'N/A'} ${
                assessment ? `/ ${assessment.display}` : ''
              }`}
            </Typography>
          </>
        ) : (
          'N/A'
        );
      },
    },
    {
      header: 'Source',
      field: 'source',
      render(source: Note['source']) {
        if (source.reference) {
          if (source.reference.type === 'Task') {
            return (
              <Box>
                <Typography>Activity</Typography> (
                <Link
                  onClick={async () => {
                    const activity = await api.activities.getActivityByFhirId(source.reference!.id!);

                    if (!activity) return;

                    updateState({
                      activityToSelect: activity,
                      showActivityDetails: true,
                    })
                    // onActivityDetailsClick?.(source.reference!.id!);
                  }}
                >
                  View details
                </Link>
                )
              </Box>
            );
          }
        }

        return source.concept?.text ?? 'General Note';
      },
    },
    {
      header: 'Note',
      field: 'text',
      visible: isExpanded,
      render(value: string) {
        return parse(value);
      },
    },
    {
      header: 'Author',
      field: 'author.display',
      visible: isExpanded,
    },
  ];

  const onCreateCommunicationCallback = () => {
    updateState({
      selectedCommunication: null,
      showAddCommunicationModal: false,
    });
    refreshNotes();
  };

  const renderAddCommunicationModal = () => (
    <AddModal
      open={showAddCommunicationModal}
      onClose={() =>
        updateState({
          showAddCommunicationModal: false,
          selectedCommunication: null,
        })
      }
      Component={
        <AddCommunication
          communication={selectedCommunication}
          onSaveCallback={onCreateCommunicationCallback}
          onCancelPress={() =>
            updateState({
              showAddCommunicationModal: false,
              selectedCommunication: null,
            })
          }
          patient={{
            fhirId: patient?.id,
          }}
        />
      }
    />
  );

  const closeEditActivityModal = () =>
      updateState({
        showEditActivityModal: false,
        activityToSelect: null,
      })

  const renderScheduleActivityModal = () => (
    <AddModal
      open={showEditActivityModal}
      onClose={closeEditActivityModal}
      Component={
        <ScheduleActivity
          activity={activityToSelect}
          onSaveCallback={() => {
            queryClient.refetchQueries({
              predicate(query: any) {
                return ['activities'].includes(
                  query.queryKey[0] as string
                );
              },
            });

            closeEditActivityModal();
          }}
          patient={{
            fhirId: patient?.id,
          }}
          shouldCompletedActivity={false}
        />
      }
      containerStyle={{
        width: 450
      }}
    />
  );

  const closeActivityDetails = () => {
    updateState({
      activityToSelect: null,
      showActivityDetails: false,
    })
  }

  const renderActivityDetails = () => (
    <Modal
      open={showActivityDetails}
      onClose={closeActivityDetails}
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <ActivityDetailsModal
          activity={activityToSelect!}
          actionBar={<ActionBar onCollapse={closeActivityDetails} />}
        />
      </Box>
    </Modal>
  )

  return (
    <>
      {renderScheduleActivityModal()}
      {renderAddCommunicationModal()}
      {renderActivityDetails()}
      <SummaryTable
        title="Notes"
        columns={columns}
        data={_notes}
        rowContextMenu={(note: Note) => (
          <NotesContextMenu
            item={note}
            onEditPress={async () => {
              if (!note.source.reference) {
                updateState({
                  selectedCommunication: note,
                  showAddCommunicationModal: true,
                });
                return;
              }

              const activity = await api.activities.getActivityByFhirId(note.source.reference.id ?? '');

              if (!activity) return;

              updateState({
                activityToSelect: activity,
                showEditActivityModal: true,
                showAddCommunicationModal: false,
                showActivityDetails: false,
              })
            }}
            onDeletePress={async () => {
              const confirmed = await confirmDialog({
                title: 'Are you sure you want to delete this record?',
                description: 'This action cannot be undone.',
                confirmText: 'Yes, Delete',
                denyText: 'No, Go Back',
                cancellable: false,
              });

              if (!confirmed) {
                return;
              }

              const carePlansResponse = await axiosFhirInstance.get(
                `CarePlan?subject=${patient?.id}`
              );

              if (carePlansResponse.data.entry.length > 0) {
                const carePlans: CarePlan[] = carePlansResponse.data.entry.map(
                  (e: any) => e.resource
                );
                await Promise.all(
                  carePlans.map(async (resource: CarePlan) => {
                    if (resource.note) {
                      await updateCarePlan({
                        ...resource,
                        note:
                          resource.note?.filter(
                            (currentNote: any) => currentNote.text !== note.text
                          ) ?? [],
                      });
                    } else {
                      return Promise.resolve();
                    }
                  })
                );
              }

              // await deleteCommunication(note.id || '').then(() => {
              //   refreshCommunications();
              // });
            }}
          />
        )}
        // fetchMore={() => loadMoreCommunications()}
        actionBar={
          <>
            <IconButton
              onClick={() =>
                updateState({
                  showAddCommunicationModal: true,
                })
              }
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand(1)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default NotesSummary;
