import { isObject } from 'lodash';
import KeyValueGrid from './KeyValueGrid';
import { Box, Card, Stack, Typography } from '@mui/material';

type Props = {
  title?: string;
  data: any;
  devMode?: boolean;
  children?: JSX.Element;
};

export default function DetailsSummary({
  title,
  data,
  devMode,
  children
}: Props) {

  return (
    <Card sx={{ p: 1, display: 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ mt: 1 }}>{title}</Typography>
      </Box>
      {children}
      <Stack spacing={1} sx={{ m: 2 }}>
        {data &&
          Object.keys(data).length > 0 &&
          Object.entries(data).map((elem: any) => {
            return elem?.[1] && Object.keys(elem?.[1]).length > 0 && isObject(elem?.[1]) ? (
              Object.entries(elem?.[1]).map((item) =>{
                return item?.[1] && !!devMode ? 
                  <KeyValueGrid highlight key={item?.[0]} label={item?.[0]} value={item?.[1]} /> : null
              })
            ) : (
              <KeyValueGrid key={elem?.[0]} label={elem?.[0]} value={elem?.[1]} />
            ) 
        })}
      </Stack>
    </Card>
  );
}