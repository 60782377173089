import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PatientCase from './patientReferralCases/patientCase';
import PatientGenericServiceRequest from './patientGenericReferralCases/PatientGenericServiceRequest';
import PatientGenericCarePlan from './patientGenericReferralCases/PatientGenericCarePlan';
import { checkAclValidation } from '../../../../utils/permissions/permission.utils';

import ExpandableCard from 'src/components/common/ExpandableCard';
import useLocales from 'src/hooks/useLocales';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

export default function PatientReferralCases({ patient }: any) {
  const { i18n } = useLocales();
  const { patientId } = useParams();

  return (
    <Card sx={{ py: 2, boxShadow: 'none' }}>
      {checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.VIEW],
      }) ? (
        <ExpandableCard
          title={i18n('patients.details.serviceRequests.title', 'crs')}
          sxProp={{ m: 1 }}
        >
          <PatientGenericServiceRequest patient={patient} />
        </ExpandableCard>
      ) : null}
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CASES.VIEW] })}
      <ExpandableCard title={i18n('patients.details.cases.title', 'crs')} sxProp={{ m: 1 }}>
        <PatientCase patientId={patientId} />
      </ExpandableCard>

      {checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.CARE_PLAN.VIEW],
      }) ? (
        <ExpandableCard title={i18n('patients.details.carePlans.title', 'crs')} sxProp={{ m: 1 }}>
          <PatientGenericCarePlan patientId={patientId} />
        </ExpandableCard>
      ) : null}
    </Card>
  );
}
