// Importa useTranslation correctamente
import { useTranslation } from 'react-i18next';
import useSettings from './useSettings';
import { allLangs, defaultLang } from '../config';
import humanizeString from 'humanize-string';
import { capitalCase } from 'change-case';

export type i18nFunction = (text: any, options?: any) => any;

export default function useLocales() {
  const { i18n, t } = useTranslation();

  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    i18n: (text: any, options?: any): any => {
      if (text !== undefined && typeof text === 'string' && text.trim() !== '') {
        const nsOptions = typeof options === 'object' ? options : { ns: options };

        const translationExists = i18n.exists(text, nsOptions);
        const hasDefaultValue = nsOptions && nsOptions.defaultValue;
        return translationExists || hasDefaultValue
          ? t(text, nsOptions)
          : capitalCase(humanizeString(text.replace('.', ' ')));
      }
    },
    currentLang,
    allLangs,
  };
}
