import { Box } from '@mui/material';
import PeriodFilter from 'src/components/PeriodFilter';
import { UpdateState } from 'src/hooks/useObjectState';

export interface ConsentGridFilters {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface ConsentGridFiltersProps {
  filterValues: ConsentGridFilters;
  updateFilters: UpdateState<ConsentGridFilters>;
}

const ConsentGridFilter = ({ filterValues, updateFilters }: ConsentGridFiltersProps) => (
  <Box py={3}>
    <Box marginBottom={1}>
      <PeriodFilter
        startDate={filterValues.startDate}
        endDate={filterValues.endDate}
        onStartDateChange={(value) => {
          updateFilters({
            startDate: value,
          });
        }}
        onEndDateChange={(value) => {
          updateFilters({
            endDate: value,
          });
        }}
      />
    </Box>
  </Box>
);
export default ConsentGridFilter;
