import {
  Box,
  Stack,
  Button,
  Grid,
  TextField,
  DialogActions,
  Typography,
  DialogTitle,
  Dialog,
} from '@mui/material';
import * as Yup from 'yup';
import { MenuItem } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import useLocales from 'src/hooks/useLocales';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { usePractitioners, useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { WrappedValueSet, ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';

const STATUS_OPTION = [
  { label: 'In Progress', value: 'not-done' },
  { label: 'Completed', value: 'completed' },
  { label: 'Canceled', value: 'entered-in-error' },
];

const doseQuantityMeasureList = [
  { label: 'ml', value: 'ml' },
  { label: 'cc', value: 'cc' },
];

type Props = {
  open: boolean;
  onCancel: VoidFunction;
  handleCreateImmunization: (data: any) => void;
  patient: WrappedPatient | null;
};

export default function ImmunizationAddForm({
  open,
  onCancel,
  handleCreateImmunization,
  patient,
}: Props) {
  const { i18n } = useLocales();

  const [vaccineCodeList] = useValueSet('vaccine-code', { map: ValueSetWrapper });
  const [immunizationSiteList] = useValueSet('immunization-site', { map: ValueSetWrapper });
  const [immunizationRouteList] = useValueSet('immunization-route', { map: ValueSetWrapper });

  const [performerPractitioners] = usePractitioners({ map: PractitionerWrapper });

  const convertValueToValueSet = (value: string, valueSet: WrappedValueSet | null) => {
    if (!valueSet?.compose?.include?.[0]?.concept) return null;
    const data = valueSet.compose.include[0].concept.find((c) => c.code === value);
    return data ? data : null;
  };

  const getPerformers = () => {
    const performers = performerPractitioners.sort((a, b) => {
      const firstValue = a.getFullName();
      const secondValue = b.getFullName();
      if (firstValue && secondValue) {
        if (firstValue < secondValue) {
          return -1;
        }
        if (firstValue > secondValue) {
          return 1;
        }
      }
      return 0;
    });

    return performers;
  };

  const EventSchema = Yup.object().shape({
    status: Yup.string().required('Status is required'),
    vaccineCode: Yup.string().required('Vaccine code is required'),
    occurrence: Yup.date().required('Occurrence is required'),
    dosesApplied: Yup.number(),
    maxDoses: Yup.number(),
    doseQuantity: Yup.number(),
    doseQuantityMeasure: Yup.string(),
    lotNumber: Yup.string(),
    site: Yup.string(),
    route: Yup.string(),
    note: Yup.string(),
    performer: Yup.string(),
  });

  type FormValues = {
    status: string;
    vaccineCode: string;
    occurrence: string | undefined;
    dosesApplied?: number;
    maxDoses?: number;
    doseQuantity?: number;
    doseQuantityMeasure?: string;
    lotNumber?: string;
    site?: string;
    route?: string;
    note?: string;
    performer?: string;
  };

  const methods = useForm<FormValues>({
    resolver: yupResolver(EventSchema),
  });

  const { control, handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const immunizationData = mapFormDataToImmunization(data);
    handleCreateImmunization(immunizationData);
    handleClose();
  };

  const mapFormDataToImmunization = (data: FormValues): any => {
    const mappedData: any = { resourceType: 'Immunization' };
    if (data?.status) {
      mappedData.status = data.status;
    }

    if (data?.vaccineCode) {
      mappedData.vaccineCode = {
        coding: [
          {
            ...convertValueToValueSet(data?.vaccineCode, vaccineCodeList),
          },
        ],
        text: convertValueToValueSet(data?.vaccineCode, vaccineCodeList)?.display,
      };
    }

    if (data?.site) {
      mappedData.site = {
        coding: [
          {
            ...convertValueToValueSet(data?.site, immunizationSiteList),
          },
        ],
        text: convertValueToValueSet(data?.site, immunizationSiteList)?.display,
      };
    }

    if (data?.route) {
      mappedData.route = {
        coding: [
          {
            ...convertValueToValueSet(data?.route, immunizationRouteList),
          },
        ],
        text: convertValueToValueSet(data?.route, immunizationRouteList)?.display,
      };
    }

    if (data?.occurrence) {
      mappedData.occurrenceDateTime = new Date(data.occurrence).toISOString();
    }

    if (data?.dosesApplied || data?.maxDoses) {
      mappedData.protocolApplied = [{}];
      if (data?.dosesApplied) {
        mappedData.protocolApplied[0].doseNumberPositiveInt = data.dosesApplied;
      }
      if (data?.maxDoses) {
        mappedData.protocolApplied[0].seriesDosesPositiveInt = data.maxDoses;
      }
    }

    if (data?.doseQuantity && data?.doseQuantityMeasure) {
      mappedData.doseQuantity = {
        system: 'http://unitsofmeasure.org',
        value: data.doseQuantity,
        code: data.doseQuantityMeasure,
      };
    }

    if (data?.lotNumber) {
      mappedData.lotNumber = data.lotNumber;
    }

    if (data?.note) {
      mappedData.note = [
        {
          text: data.note,
        },
      ];
    }

    if (patient?.id) {
      mappedData.patient = {
        reference: `Patient/${patient?.id}`,
      };
    }

    if (data?.performer) {
      const performerResource = getPerformers().find(
        (p) => p.getIdAsReference() === data?.performer
      );
      if (performerResource) {
        mappedData.performer = [{ actor: { reference: performerResource.getIdAsReference() } }];
      }
    }

    return mappedData;
  };

  const handleClose = () => {
    reset();
    onCancel();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>{i18n('patients.details.immunizations.titleAddImmunization', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFSelect 
                name="vaccineCode" 
                label={`${i18n('patients.details.immunizations.vaccineCode', 'crs')}*`}
              >
                <MenuItem disabled />
                {vaccineCodeList?.asList().map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option?.display}
                  </MenuItem>
                ))}
              </RHFSelect>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={{ width: 1 }}>
                <RHFTextField
                  name="dosesApplied"
                  label={i18n('patients.details.immunizations.dose', 'crs')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    type: 'number',
                  }}
                />
                <Typography sx={{ pt: 2 }}>of</Typography>
                <RHFTextField
                  name="maxDoses"
                  label={i18n('patients.details.immunizations.doses', 'crs')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    type: 'number',
                  }}
                />
                <RHFTextField
                  name="doseQuantity"
                  label={i18n('patients.details.immunizations.quantity', 'crs')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    type: 'number',
                  }}
                />
                <RHFSelect name="doseQuantityMeasure" label={i18n('patients.details.immunizations.measure', 'crs')} fullWidth>
                  <MenuItem disabled />
                  {doseQuantityMeasureList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>

              <RHFSelect name="site" label={i18n('patients.details.immunizations.site', 'crs')}>
                <MenuItem disabled />
                {immunizationSiteList?.asList().map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option?.display}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFSelect name="performer" label={i18n('patients.details.immunizations.performer', 'crs')}>
                <MenuItem disabled />
                {getPerformers().map((option) => (
                  <MenuItem key={option.getIdAsReference()} value={option.getIdAsReference()}>
                    {option?.getFullName()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFSelect 
                name="status" 
                label={`${i18n('patients.details.immunizations.status', 'crs')}*`}
              >
                <MenuItem disabled />
                {STATUS_OPTION.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField name="lotNumber" label={i18n('patients.details.immunizations.loteNumber', 'crs')} />
              <RHFTextField name="manufacturer" label={i18n('patients.details.immunizations.manufacturer', 'crs')} />

              <RHFSelect name="route" label={i18n('patients.details.immunizations.route', 'crs')}>
                <MenuItem disabled />
                {immunizationRouteList?.asList().map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option?.display}
                  </MenuItem>
                ))}
              </RHFSelect>

              <Controller
                name="occurrence"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <MobileDatePicker
                    {...field}
                    value={field.value !== undefined ? field.value : null}
                    label={`${i18n('patients.details.immunizations.occurrence', 'crs')}*`}
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!error?.message}
                        helperText={error?.message}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFTextField name="note" label={i18n('patients.details.immunizations.note', 'crs')} multiline rows={4} />
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={2} alignItems="center">
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />

            <Button variant="outlined" color="info" onClick={handleClose}>
              {i18n('cancel')}
            </Button>

            <Button type="submit" color="info" variant="outlined">
              {i18n('submit')}
            </Button>
          </DialogActions>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}
