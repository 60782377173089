import AddNote from "./AddNote";
import api from "src/services/api";
import { mapNotes } from "../common-map";
import { useEffect, useState } from "react";
import { INoteRowInfo } from "src/@types/note";
import { TABLE_HEAD_NOTES } from "../table-head";
import { TableCustom } from "src/components/TableCustom";

type Props = {
  engagementWorkflowData: any;
  isAllowedToAdd: boolean;
}

export default function NotesList({ engagementWorkflowData, isAllowedToAdd }: Props) {

  const [notesRowInfoList, setNotesRowInfoList] = useState<INoteRowInfo[]>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (engagementWorkflowData) {
      getNotes(engagementWorkflowData.recordId);
    }
  }, [engagementWorkflowData]);

  const getNotes = async (patientEngagementId: string) => {
    const notesList = await api.notes.getGenericNotes(patientEngagementId);
    setNotesRowInfoList(notesList);
  };

  return (
    <>
      <TableCustom
        titleButton={isAllowedToAdd ? "Add Note" : undefined}
        data={Array.isArray(notesRowInfoList) && mapNotes(notesRowInfoList, ["MemberEngagement"])}
        tableHead={TABLE_HEAD_NOTES}
        handleOpen={()=> setOpen(true)}
      />
      <AddNote
        openDialog={open}
        engagementWorkflowData={engagementWorkflowData}
        onCreate={() => {
          getNotes(engagementWorkflowData.recordId);
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
