import { Backdrop, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  open: boolean;
  url: string | null;
  onCancel: VoidFunction;
};

const AssessmentFrame = ({ open, url, onCancel }: Props) => {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const onClose = () => {
    onCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      PaperProps={{
        sx: {
          width: '100%',
          height: `calc(100vh - 200px)`,
        },
      }}
    >
      <DialogTitle>Assessment</DialogTitle>
      {url ? (
        <iframe
          src={url}
          title="Assessment"
          style={{ border: 0, width: '100%', height: '100%' }}
        />
      ) : null}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default AssessmentFrame;
