import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import CancelButton from 'src/sections/crs/common/CancelButton';

export interface ImagePreviewDialogProps extends DialogProps {
  imgSrc: string;
  onClose: VoidFunction;
  title?: string;
}

const ImagePreviewDialog = ({
  imgSrc,
  title,
  onClose,
  ...dialogProps
}: ImagePreviewDialogProps) => {
  const { i18n } = useLocales();
  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      {...dialogProps}
    >
      <DialogTitle>{title || i18n('previewImage')}</DialogTitle>
      <DialogContent sx={{ marginTop: 4 }}>
        <img alt={title || 'imageToPreview'} src={imgSrc} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default ImagePreviewDialog;
