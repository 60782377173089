import moment from 'moment';
import {
  Cake as CakeIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  MedicalInformation as MedicalInformationIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import { getAge } from 'src/utils/formatTime';
import Label from '../../../../components/Label';
import { ReferralManager } from 'src/@types/crs/referral';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableRow, TableCell, Typography, IconButton, Box } from '@mui/material';
import CustomLink from '../../common/CustomLink';
import { PATH_DASHBOARD } from 'src/routes/paths';

type Props = {
  row: ReferralManager;
};

export default function ReferralTable({ row }: Props) {
  const {
    mrn,
    referral,
    phone,
    email,
    gender,
    race,
    demographics,
    referringProvider,
    requesterOrganization,
    workflowStageName,
    status,
    id,
    internalNumber,
    subjectDOBFormatted,
    subjectAge,
    createdOnFormatted,
  } = row;

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnRowDoubleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const navigateType = id;
    navigate(`${location.pathname}/${navigateType}`, {
      replace: false,
    });
  };

  function onClick() {
    navigate(`/dashboard/referral/${id}`);
  }

  return (
    <TableRow onDoubleClick={handleOnRowDoubleClick}>
      <TableCell>
        <CustomLink to={`${PATH_DASHBOARD.crs.referral}/${id}`}>
          <Typography noWrap variant="subtitle2">
            {internalNumber}
          </Typography>
        </CustomLink>
      </TableCell>
      <TableCell>
        <Box display={'flex'} justifyContent={'row'}>
          <MedicalInformationIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="subtitle2">{mrn}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <PersonIcon fontSize={'small'} sx={{ marginRight: 1 }} />
          <Typography variant="subtitle2">{referral}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {email}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <ContactPhoneIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {phone}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <CakeIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography noWrap variant="body2">
            {subjectDOBFormatted ? `${subjectDOBFormatted} (${subjectAge})` : null}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ textTransform: 'capitalize' }}>
        <Typography variant="body2">{gender}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {race}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {demographics}
        </Typography>
      </TableCell>
      <TableCell sx={{ textTransform: 'capitalize' }}>
        <Typography variant="body2">{referringProvider}</Typography>
      </TableCell>
      <TableCell sx={{ textTransform: 'capitalize' }}>
        <Typography variant="body2">{requesterOrganization}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{!!createdOnFormatted && createdOnFormatted}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{workflowStageName && workflowStageName}</Typography>
      </TableCell>
      <TableCell>
        <Label
          color={status === 'Completed' || status === 'Open' ? 'success' : 'error'}
          sx={{ textTransform: 'uppercase' }}
        >
          {status}
        </Label>
      </TableCell>
      <TableCell>
        <IconButton onClick={onClick}>
          <Iconify icon={'ic:outline-open-in-new'} width={24} height={24} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
