import { useMemo } from 'react';
import GoalContext, { GoalContextData } from './context';

export interface GoalContextProviderProps extends GoalContextData {
  children: React.ReactNode;
}

const GoalContextProvider = ({ children, ...goalContextData }: GoalContextProviderProps) => {
  const memoizedGoalContextData = useMemo(() => goalContextData, [goalContextData]);
  return <GoalContext.Provider value={memoizedGoalContextData}>{children}</GoalContext.Provider>;
};

export default GoalContextProvider;
