import { useMemo } from 'react';
import TaskContext, { TaskContextData } from './context';

export interface TaskContextProviderProps extends TaskContextData {
  children: React.ReactNode;
}

const TaskContextProvider = ({ children, ...taskContextData }: TaskContextProviderProps) => {
  const memoizedTaskContextData = useMemo(() => taskContextData, [taskContextData]);
  return <TaskContext.Provider value={memoizedTaskContextData}>{children}</TaskContext.Provider>;
};

export default TaskContextProvider;
