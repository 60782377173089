import { has } from 'lodash';
import { createWrapper } from '../../fhir-react/base';
import {
  ActivityDefinition,
  Condition,
  Goal,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { GroupedGoals } from '../../../@types/crs/case';
import { getReferenceResourceId } from '../../../sections/crs/helpers/common';
import { getReferenceId, getReferenceType } from 'src/utils/fhir';

export const CarePlanWrapper = createWrapper('CarePlan', (carePlan) => {
  const _goal = carePlan.contained?.find(
    (resource): resource is Goal => resource.resourceType === 'Goal'
  );
  const _condition = carePlan.contained?.find(
    (resource): resource is Condition => resource.resourceType === 'Condition'
  );
  const _activityDefinition = carePlan.contained?.find(
    (resource): resource is ActivityDefinition => resource.resourceType === 'ActivityDefinition'
  );

  return {
    _goal: _goal,
    _condition: _condition,
    _activityDefinition: _activityDefinition,

    parsed: {
      goal: _goal?.description.text,
      goalGroup: _goal?.category?.[0].text,
      condition: _condition?.code?.coding?.[0].display,
      opportunity: _activityDefinition?.purpose,
      intervention: _activityDefinition?.title,
      priority: _goal?.priority?.coding?.[0].display,
      appointmentAlert: carePlan.extension?.[0].valueBoolean,
    },

    getCareTeamIds: (): string[] => {
      if (!Array.isArray(carePlan?.careTeam)) return [];
      return carePlan.careTeam.reduce<string[]>((careTeamIds, careTeam) => {
        const id = getReferenceResourceId(careTeam?.reference ?? '');
        if (id) return [...careTeamIds, id];
        return careTeamIds;
      }, []);
    },

    //gets goals with its tasks
    getGroupedGoals: (): GroupedGoals | undefined => {
      if (!carePlan.activity?.reduce) return;
      return carePlan.activity.reduce<GroupedGoals>(
        (groupedGoals, { detail, outcomeReference, reference }) => {
          if (detail?.goal) {
            const goalId = detail?.goal?.[0]?.reference?.split('/')[1] as string;
            if (!goalId) return groupedGoals;
            if (!groupedGoals[goalId]) groupedGoals[goalId] = [];
            const tasks =
              outcomeReference
                ?.filter((outcomeReference) => outcomeReference?.reference?.includes('Task'))
                .reduce<string[]>(
                  (taskIds, outcomeReference) => [
                    ...taskIds,
                    outcomeReference?.reference?.split('/')?.[1] as string,
                  ],

                  []
                ) ?? [];
            const taskId = getReferenceId(reference?.reference);
            const referenceType = getReferenceType(reference?.reference);
            if (referenceType === 'Task' && taskId) tasks.push(taskId);
            groupedGoals[goalId] = [...groupedGoals[goalId], ...tasks];
          }
          return groupedGoals;
        },
        {}
      );
    },

    getRelatedTaskIds: () =>
      carePlan?.activity?.reduce<string[]>((taskIds, { outcomeReference, reference }) => {
        const tasksByOutcomeReference =
          outcomeReference
            ?.filter((outcomeReference) => outcomeReference?.reference?.includes('Task'))
            .reduce<string[]>(
              (taskIds, outcomeReference) => [
                ...taskIds,
                outcomeReference?.reference?.split('/')?.[1] as string,
              ],

              []
            ) ?? [];

        const taskReference = reference?.reference?.includes('Task')
          ? reference.reference.split('/')?.[1]
          : null;

        return [...taskIds, ...tasksByOutcomeReference, ...(taskReference ? [taskReference] : [])];
      }, []),

    getIdFromReference(reference: string): string {
      return reference.split('/')?.[1];
    },

    getTaskIds(): string[] {
      if (carePlan?.activity && carePlan?.activity.length > 0) {
        const task = carePlan.activity
          .filter((s) => has(s, 'reference') && s?.reference?.reference)
          .filter((s) => s?.reference?.reference?.startsWith('Task/'))
          .map((s) =>
            s.reference?.reference ? this.getIdFromReference(s.reference?.reference) : ''
          );
        return task;
      }
      return [];
    },
  };
});

export type WrappedCarePlan = ReturnType<typeof CarePlanWrapper>;
