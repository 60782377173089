import { getBackEndBaseUrl } from "src/utils/domain-utils";

const getBaseUrlNative = (eUrl : string | undefined, ext : string | undefined) => {
  try {
      if (!eUrl) throw new Error("Error .")
      return ext ? eUrl+ext : eUrl;
  } catch (err) {
      console.log(err)
  }
}

export const urlBackNestApp :string = getBaseUrlNative(getBackEndBaseUrl(),'') as string || '';