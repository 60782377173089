import { CarePlanWrapper } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { ConsentWrapper } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { GoalWrapper } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { PractitionerRoleWrapper } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { FhirResource } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
export class SealedFhirSystem {
  constructor(protected system: string) {
    this.toString = function () {
      return this.asString();
    };
  }

  forCode(code: string = '') {
    return `${this.system}|${code}`;
  }

  asString(): string {
    return this.system;
  }

  withId(id: string) {
    return new FhirSystem(`${this.system}/${id}`);
  }
}

export class FhirSystem extends SealedFhirSystem {
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem };
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    subname: string,
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem };
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    subname?: string | ((subsystem: FhirSystem) => FhirSubsystem),
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem } {
    const subsystem =
      typeof subname === 'string'
        ? new FhirSystem(`${this.system}/${subname || name}`)
        : new FhirSystem(`${this.system}/${name}`);

    Object.assign(this, {
      [name]: creator
        ? creator(subsystem)
        : typeof subname === 'function'
        ? subname(subsystem)
        : subsystem,
    });

    return this as this & { [key in Name]: FhirSubsystem };
  }

  seal() {
    const sealed = new SealedFhirSystem(this.system);
    Object.assign(sealed, this);
    return sealed as SealedFhirSystem & Omit<this, keyof FhirSystem>;
  }
}

export const wrappersByResourceType = {
  Practitioner: PractitionerWrapper,
  Organization: OrganizationWrapper,
  PractitionerRole: PractitionerRoleWrapper,
  RelatedPerson: RelatedPersonWrapper,
  Consent: ConsentWrapper,
  Task: TaskWrapper,
  Goal: GoalWrapper,
  CarePlan: CarePlanWrapper,
  Patient: PatientWrapper,
};

export const wrapResource = (resource: FhirResource | undefined | null) => {
  if (!resource?.resourceType) return resource;
  if (wrappersByResourceType?.[resource.resourceType as keyof typeof wrappersByResourceType])
    return wrappersByResourceType[resource.resourceType](resource);
  return resource;
};
