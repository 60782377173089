import { CircularProgress, Box, SxProps, CircularProgressProps } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface LoadingComponentProps extends CircularProgressProps {
  containerSx?: SxProps;
}

const LoadingComponent = ({ containerSx, ...circularProgressProps }: LoadingComponentProps) => (
  <Box
    sx={[
      {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        my: 2,
      },
      ...spreadSxProp(containerSx),
    ]}
  >
    <CircularProgress size={50} {...circularProgressProps} />
  </Box>
);

export default LoadingComponent;
