import { Grid, SxProps, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface InfoRibbonProps {
  children: React.ReactNode;
  containerSx?: SxProps;
  xs?: number;
}

const InfoRibbon = ({ children, containerSx }: InfoRibbonProps) => (
  <Grid
    container
    alignItems={'center'}
    justifyContent={'space-evenly'}
    flexShrink={0}
    flexWrap={'nowrap'}
    sx={[
      {
        height: '42px',
        backgroundColor: '#f4f6f8',
      },
      ...spreadSxProp(containerSx),
    ]}
  >
    {children}
  </Grid>
);

export const InfoTypography = ({ children, containerSx, xs }: InfoRibbonProps) => (
  <Typography
    noWrap
    textAlign={'center'}
    sx={[
      {
        color: '#212b36',
        fontSize: '0.9rem',
      },
      ...spreadSxProp(containerSx),
    ]}
  >
    {children}
    {'  '}
  </Typography>
);

export default InfoRibbon;
