import { Box, Button, Grid, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import Map, { getCity, getCountry, getDistrict, getLine, getPostalCode, getState } from './Map';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import useLocales from 'src/hooks/useLocales';
import uuidv4 from 'src/utils/uuidv4';
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import AddressAutoComplete, { PlaceType } from './AddressAutoComplete';

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

const AddAddress = ({
  handleClose,
  handleSave,
  isEditable,
  externalAddress,
  children,
  disabled,
}: any) => {
  const [addressData, setAddressData] = useState<Address | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: '',
    lng: '',
  });
  const [{ error }, { setError }] = useAddEntityRequestStates();
  const [useApi, setUseApi] = useState<boolean>(false);
  const { i18n } = useLocales();
  const { placesService } = usePlacesService({});

  const updateAddressData = (callback: (prevState: Address | null) => Address) => {
    if (!isEditable) return;
    if (callback)
      setAddressData((data) => ({
        ...data,
        ...callback(data),
      }));
  };

  useEffect(() => {
    if (isEmpty(externalAddress)) return;
    const { id, city, district, postalCode, state, country, line, use, extension } =
      externalAddress ?? {};
    const latitude = extension?.[0]?.extension?.find((e) => e.url === 'latitude').valueDecimal;
    const longitude = extension?.[0]?.extension?.find((e) => e.url === 'longitude').valueDecimal;

    if (latitude && longitude) setUseApi(true);

    setAddressData({
      id: id || '',
      city: city || '',
      district: district || '',
      line: line || [],
      postalCode: postalCode || '',
      state: state || '',
      country: country || '',
      use: use || '',
      extension,
    });
  }, [externalAddress]);

  const handleAddressLineChange =
    (lineNumber: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateAddressData((address) => {
        const lines = [...(address?.line ?? [])];
        lines[lineNumber] = event.target.value;
        return { line: lines };
      });
      setError(null);
    };

  const handleFieldChange =
    (fieldName: keyof Address) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateAddressData((_) => ({
        [fieldName]: event.target.value,
      }));
      setError(null);
    };

  const onPlaceSelected = (place: any) => {
    const addressArray = place.address_components;
    const line = getLine(addressArray);
    const city = getCity(addressArray);
    const state = getState(addressArray);
    const district = getDistrict(addressArray);
    const postalCode = getPostalCode(addressArray);
    const country = getCountry(addressArray);
    setUseApi(true);
    setAddressData({
      id: place.place_id,
      line: [line],
      city: city || '',
      district: district || '',
      state: state || '',
      postalCode: postalCode || '',
      country: country || '',
      use: 'home',
      extension: [
        {
          extension: [
            {
              url: 'identifier',
              valueString: place.place_id,
            },
            {
              url: 'valid',
              valueBoolean: true,
            },
            {
              url: 'address',
              valueString: line,
            },
            {
              url: 'latitude',
              valueDecimal: place.geometry.location.lat(),
            },
            {
              url: 'longitude',
              valueDecimal: place.geometry.location.lng(),
            },
          ],
          url: `${FHIR_API}/StructureDefinition/PH-address-valid`,
        },
      ],
    });
    setMarkerPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const validateRequiredFields = (payload: any) => {
    let isValid = true;
    if (
      isEmpty(payload?.id) ||
      isEmpty(payload?.line?.[0]) ||
      isEmpty(payload?.city) ||
      isEmpty(payload?.district) ||
      isEmpty(payload?.state) ||
      isEmpty(payload?.postalCode)
    ) {
      setError('Please, fill all required fields');
      isValid = false;
    }
    return isValid;
  };

  const saveAddress = () => {
    if (!useApi && !!addressData) {
      delete addressData?.extension;
      addressData!.id = uuidv4();
    }

    if (!validateRequiredFields(addressData)) return;

    handleSave(addressData);
    setUseApi(false);
  };

  useEffect(() => {
    if (selectedAddress !== null) {
      placesService?.getDetails(
        {
          placeId: selectedAddress.place_id,
        },
        onPlaceSelected
      );
    }
  }, [selectedAddress]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={5} style={{ paddingLeft: 0, paddingTop: 0 }}>
          <List>
            <ListItem>
              <AddressAutoComplete
                address={selectedAddress}
                onAddressChange={(value) => {
                  setSelectedAddress(value);
                  setError(null);
                }}
              />
            </ListItem>

            <ListItem>
              <TextField
                label={`${i18n('addAddress.streetAddress', 'crs')}*`}
                value={addressData?.line?.[0] || ''}
                onChange={handleAddressLineChange(0)}
                fullWidth
                error={!!error && isEmpty(addressData?.line?.[0]) ? true : false}
              />
            </ListItem>

            <ListItem>
              <TextField
                label={i18n('addAddress.unitSuitFloor', 'crs')}
                value={addressData?.line?.[1] || ''}
                onChange={handleAddressLineChange(1)}
                fullWidth
              />
            </ListItem>

            <ListItem>
              <TextField
                label={`${i18n('addAddress.city', 'crs')}*`}
                value={addressData?.city || ''}
                onChange={handleFieldChange('city')}
                fullWidth
                error={!!error && !addressData?.city ? true : false}
              />
            </ListItem>

            <ListItem>
              <TextField
                label={`${i18n('addAddress.county', 'crs')}*`}
                value={addressData?.district || ''}
                onChange={handleFieldChange('district')}
                fullWidth
                error={!!error && !addressData?.district ? true : false}
              />
            </ListItem>

            <ListItem>
              <TextField
                label={`${i18n('addAddress.state', 'crs')}*`}
                value={addressData?.state || ''}
                onChange={handleFieldChange('state')}
                fullWidth
                error={!!error && !addressData?.state ? true : false}
              />
            </ListItem>

            <ListItem>
              <TextField
                label={`${i18n('addAddress.zipCode', 'crs')}*`}
                value={addressData?.postalCode || ''}
                onChange={handleFieldChange('postalCode')}
                fullWidth
                error={!!error && !addressData?.postalCode ? true : false}
              />
            </ListItem>
            {children}
          </List>
        </Grid>

        <Grid item xs={7} style={{ paddingLeft: 0, paddingTop: 0, marginTop: 1 }}>
          <Map
            center={{ lat: parseFloat(markerPosition.lat), lng: parseFloat(markerPosition.lng) }}
          >
            {markerPosition.lat !== '' && markerPosition.lng !== '' ? (
              <Marker
                position={{
                  lat: parseFloat(markerPosition.lat),
                  lng: parseFloat(markerPosition.lng),
                }}
              />
            ) : null}
          </Map>
        </Grid>

        {error && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography sx={{ color: '#FF4842' }}>{error}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 0.5 }}>
            <Button variant="contained" color="inherit" onClick={handleClose}>
              {i18n('cancel')}
            </Button>
            <Button variant="contained" onClick={saveAddress} disabled={disabled}>
              {i18n('save')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddAddress;
