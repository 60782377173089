import { Chip } from '@mui/material';

interface StatusChipProps {
  color: keyof typeof colorStatus;
  label: string;
  size?: 'small' | 'medium';
}

const colorStatus = {
  error: {
    background: 'rgba(219, 43, 57, 0.16)',
    text: '#DB2B39',
  },
  success: {
    background: 'rgba(92, 179, 148, 0.16)',
    text: '#007b55',
  },
  default: {
    background: 'rgba(145, 158, 171, 0.16)',
    text: '#212B36',
  },
  progress: {
    background: 'rgba(0, 184, 217, 0.16)',
    text: '#006c9c',
  }
};

const StatusChip = ({ label, color, size = 'small' }: StatusChipProps) => (
  <Chip
    label={label}
    size={size}
    sx={{
      backgroundColor: colorStatus[color].background,
      borderRadius: '6px',
      color: colorStatus[color].text,
      fontWeight: 'bold',
    }}
  />
);

export default StatusChip;
