import {
  GOAL_RELATIONSHIP_EXTENSION_URL,
  PARENT_GOAL_SUCCESSOR_RELATIONSHIP_CODING,
} from 'src/sections/crs/constants';
import { createWrapper } from '../../fhir-react/base';
import { getReferenceId } from 'src/utils/fhir';

export const GoalWrapper = createWrapper('Goal', (goal) => ({
  getPathway: () => goal?.category?.[0]?.coding?.[0]?.code,
  getPathwayDisplay: () => goal?.category?.[0]?.text,
  getPlainDescription: () => goal.description.text,
  getAchievementStatus: () => goal?.achievementStatus?.coding?.[0],
  getEndDate: () => goal?.target?.[0]?.dueDate,
  getComments: () => goal?.note?.[0]?.text,
  getDescriptionCode: () => goal?.description?.coding?.[0]?.code,
  getDescriptionDisplay: () => goal?.description?.coding?.[0]?.display,
  getParentGoalId: () => {
    const extension = goal?.extension?.find?.(
      ({ url, extension }) =>
        url === GOAL_RELATIONSHIP_EXTENSION_URL &&
        extension?.some?.(
          ({ url, valueCodeableConcept }) =>
            url === 'type' &&
            valueCodeableConcept?.coding?.[0]?.code ===
              PARENT_GOAL_SUCCESSOR_RELATIONSHIP_CODING.code &&
            valueCodeableConcept?.coding?.[0]?.system ===
              PARENT_GOAL_SUCCESSOR_RELATIONSHIP_CODING.system
        )
    );
    if (!extension) return null;

    const parentGoalId = getReferenceId(
      extension.extension?.find(({ url }) => url === 'target')?.valueReference?.reference
    );

    return parentGoalId;
  },
}));

export type WrappedGoal = ReturnType<typeof GoalWrapper>;
