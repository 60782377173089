import moment from 'moment';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { WrappedTask } from '../../@nicheaim/fhir-base/wrappers/Task';
import { SeverityStatusData } from '../../components/SeverityStatus';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import {
  CareTeamParticipant,
  CodeableConcept,
  Coding,
  Period,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { WrappedCondition } from '../../@nicheaim/fhir-base/wrappers/Condition';
import { PermissionsBase } from 'src/contexts/PermissionsContext';

export type CaseInformationData = {
  id: string;
  status: string;
  urgent: string;
  scope: string;
  assigned: string;
  description: string;
  date: Date;
};

export type TaskListData = {
  id: string;
  task: string;
  status: string;
  type: string;
  assigned: Date;
  category: string;
  dueTo: Date;
};

export type GroupedGoals = { [key: string]: string[] };

export interface GridRowBase {
  id: string;
}

export interface SeverityStatusDataWithDate extends SeverityStatusData {
  date: moment.Moment;
}

export interface GoalGridRowData extends GridRowBase {
  goal: string;
  status: string;
  endDate: SeverityStatusDataWithDate;
  pathway: string;
  pathwayDisplay: string;
  tasks: WrappedTask[];
  conditions: WrappedCondition[];
  wrappedGoal: WrappedGoal;
  subGoals?: GoalGridRowData[];
}

export interface WrappedTaskExtend extends WrappedTask {
  dontCollapsible?: boolean;
}

export interface TaskGridRowData extends GridRowBase {
  task: string;
  status: string;
  owner: string;
  codeCode?: string;
  codeDisplay?: string;
  endDate: SeverityStatusDataWithDate;
  basedOn?: Reference[];
  wrappedTask: WrappedTask;
  subTasks?: WrappedTask[];
  dontCollapsible?: boolean;
}

export interface ServiceRequestGridRowData extends GridRowBase {
  serviceReferral: string;
  status: string;
  assignedTo: string;
  assignedOn: string;
  dueTo: SeverityStatusDataWithDate;
  basedOn: Reference[];
  wrappedServiceRequest: WrappedServiceRequest;
  childs?: WrappedServiceRequest[];
  childCount?: Number;
}

export interface CareTeamGridRowData extends GridRowBase {
  noOfmembers: number;
  category: string;
  managingOrganization: string;
  status: SeverityStatusData;
  members: any[];
}

export interface CareTeamMember {
  id: string;
  name: string;
  memberType: string;
  associatedOrg: string;
  role: string;
  startDate: string;
  endDate: string;
  endDateObj: moment.Moment | null;
  startDateObj: moment.Moment | null;
  roleId: string | null;
  roleName?: string | null;
  associatedOrgId: string | null;
  associatedOrgName?: string | null;
  identifier: string;
  identifierType?: string | null;
}
export interface CareTeamMemberGridRowData extends CareTeamMember {
  member: CareTeamParticipant;
}

export enum CareTeamStatus {
  PROPOSED = 'proposed',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  INACTIVE = 'inactive',
  ENTEREDINERROR = 'entered-in-error',
}

export interface Option<T = undefined> {
  label: string;
  value: T;
}

// export interface CareTeamMember {
//   id: string;
//   name: string;
// }

export type onSuccess = (resource: any) => void;

export type CareTeamMemberResourceTypes = 'Practitioner' | 'PractitionerRole' | 'CareTeam';

export interface CRSGridProps {
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  patient: WrappedPatient;
}

export interface MemberEditableData {
  id: string;
  memberType: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  error: {
    startDate: string | null;
  };
  role: Coding | null;
}

export interface CareTeamMemberWithEditableData extends CareTeamMember {
  editableData?: MemberEditableData;
  versionId?: string;
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export type EditableTask = Modify<
  WrappedTask,
  {
    code: CodeableConcept | undefined | null;
    executionPeriod: Period | undefined | null;
    owner: Reference | undefined | null;
    requester: Reference | undefined | null;
  }
>;

export interface CareTeamPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
  members: {
    isAllowedToAdd: boolean;
    isAllowedToEdit: boolean;
    isAllowedToDelete: boolean;
  };
}
