import moment from 'moment';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { getCodingDisplayName } from 'src/sections/crs/helpers/common';
import { formatDateTimeToDefault } from 'src/utils/dates';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { EpisodeOfCareForm } from './EpisodeOfCareForm';
import { WrappedPractitionerRole } from '../../../../../../@nicheaim/fhir-base/wrappers/PractitionerRole';

type Props = {
  row: any | null;
  patient: WrappedPatient | null;
  practitionerRoles: WrappedPractitionerRole[];
};

export function Row({ row, patient, practitionerRoles }: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    console.log(row);
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.status}</TableCell>
        <TableCell>
          <Box sx={{ maxWidth: 300 }}>
            <Typography style={{ cursor: 'pointer' }} noWrap sx={{ fontSize: `0.75rem` }}>
              {row?.type?.[0]?.coding?.[0]?.display}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{formatDateTimeToDefault(row?.period?.start)}</TableCell>
        <TableCell>{formatDateTimeToDefault(row?.period?.end)}</TableCell>
        <TableCell>{row?.careManager?.display}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.EPISODE_OF_CARE.EDIT] })
                ? [
                    <MenuItem key={row?.id} onClick={() => handleEdit(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : null
            }
          />
        </TableCell>
      </TableRow>
      <EpisodeOfCareForm
        practitionerRoles={practitionerRoles}
        patient={patient}
        episodeOfCare={data}
        open={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
      />
    </>
  );
}
