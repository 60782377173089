// @ts-nocheck
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';
import { getNPIFromIdentifier, getTelecomValues, formatUSAddress } from "src/utils/fhir";

export const OrganizationWrapper = createWrapper('Organization', (organization) => ({
  
  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (organization.address && organization.address?.length > 0) {
      mainAddress = organization.address[0];
    }
    return mainAddress;
  },
  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();
    return mainAddress
      ? `${mainAddress?.city}, ${mainAddress?.state}, ${mainAddress?.postalCode}`
      : null;
  },
  getNPI: (): string => getNPIFromIdentifier(organization?.identifier ?? []),
  getPhones: (): string[] =>
    getTelecomValues("phone", organization?.telecom ?? []),
  getEmails: (): string[] =>
    getTelecomValues("email", organization?.telecom ?? []),
  getFormattedAddresses: (): string[] =>
    organization?.address?.map?.((address) => formatUSAddress(address)) ?? [],
  getType: (): string => {
    const { text, coding } =
      organization?.type?.find?.(
        ({ text, coding }) => !!coding?.[0]?.display || !!text
      ) ?? {};
    return (text || coding?.[0]?.display) ?? "";
  },
}));
export type WrappedOrganization = ReturnType<typeof OrganizationWrapper>;
