import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';
import useTable from 'src/hooks/useTable';
import { TableHeadCustom } from 'src/components/table';
import { useEpisodeOfCares, usePractitionerRoles } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_EPISODE_OF_CARE } from 'src/sections/crs/common/table-head';
import { v4 as uuidv4 } from 'uuid';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { Row } from './Row';
import { EpisodeOfCareWrapper } from '../../../../../../@nicheaim/fhir-base/wrappers/EpisodeOfCare';
import { EpisodeOfCareForm } from './EpisodeOfCareForm';
import { PractitionerRoleWrapper } from '../../../../../../@nicheaim/fhir-base/wrappers/PractitionerRole';

type Props = {
  patient: WrappedPatient | null;
};

export function EpisodeOfCareList({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [add, setOpenAdd] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const { i18n } = useLocales();

  const [episodesOfCare, { update: updateEpisodeOfCare, refresh: refreshEpisodesOfCare }] =
    useEpisodeOfCares({
      filter: { patient: patient?.id },
      map: EpisodeOfCareWrapper,
      autofetch: !!patient?.id,
    });

  const [practitionerRoles, { isFetching: isPractitionerRolesFetching }] = usePractitionerRoles({
    map: PractitionerRoleWrapper,
  });

  const paginatedEpisodesOfCare =
    episodesOfCare?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.EPISODE_OF_CARE.ADD] }) && (
                  <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      onClick={() => setOpenAdd(true)}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                    >
                      {i18n('patients.details.episodeOfCare.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_EPISODE_OF_CARE, 'crs')} />
              <TableBody>
                {paginatedEpisodesOfCare.length ? (
                  paginatedEpisodesOfCare.map((row: any) => (
                    <Row
                      key={uuidv4()}
                      row={row}
                      patient={patient}
                      practitionerRoles={practitionerRoles}
                    />
                  ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_EPISODE_OF_CARE?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={episodesOfCare?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <EpisodeOfCareForm
        practitionerRoles={practitionerRoles}
        patient={patient}
        episodeOfCare={null}
        open={add}
        onClose={() => setOpenAdd(false)}
      />
    </>
  );
}
