import { useState } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import uuidv4 from 'src/utils/uuidv4';

const SelectComponent = (props: any) => {
  const items = props?.others?.data && props?.others?.data.length > 0 ? props?.others?.data.map((i: any) => ({ label: `${i?.firstName} ${i?.lastName}`, id: i?.userName, })) : [{ label: '', id: '' }];
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
  } = props?.others;

  return (
    <div style={{ marginBottom: 10 }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={value}
        inputValue={inputValue}
        onInputChange={(event: any, newInputValue: any) => {
          setInputValue(newInputValue);
        }}
        options={items}
        renderInput={(params) => <TextField {...params} label={props?.name || props?.elementName} />}
        renderOption={(props: any, option: any) => (
          <li {...props} key={uuidv4()}>
            {option?.label}
          </li>
        )}
        onChange={(event: any, username: any | null) => {
          if(!username) return;
          setValue(username);
          props?.others?.handler({
            record,
            checklistItem,
            refreshChecklistHandler,
            refreshRecordHandler,
            more: { selectedUser: username?.id },
          })
        }}
      />
    </div>
  );
};

export default SelectComponent;
