// @ts-nocheck
import { Autocomplete, AutocompleteChangeDetails, TextField } from "@mui/material";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import api from "src/services/api";
import PatientViewModel from "../tasks-activities/components/Patients/ViewModel/PatientViewModel";

interface PatientSearchDropdownProps {
  onChange: (_: any, value: any | null, reason: any, changes: AutocompleteChangeDetails<any> | undefined) => void;
  renderOption?: (props: any, option: any, { selected }: {
    selected: any;
}) => JSX.Element;
  disabled?: boolean;
  value: any;
  variant?: 'standard' | 'outlined' | 'filled';
  sx?: any;
  useFhirId?: boolean;
  multiple?: boolean;
  label?: string;
}

const PatientSearchDropdown = ({
  value,
  onChange,
  renderOption,
  disabled,
  variant = 'outlined',
  sx = {},
  useFhirId = false,
  multiple = false,
  label = 'Type to search patients'
}: PatientSearchDropdownProps) => {
  const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
  const [searchPatientValue, setSearchPatientValue] = useState<string>('');
  const [patients, setPatients] = useState<PatientViewModel[]>([]);

  useEffect(() => {
    if ((searchPatientValue.length >= 1 || searchPatientValue.length === 0) && !loadingPatients) {
      setLoadingPatients(true)
      api.patients
        .getCcmPatients({
          status: null,
          search: searchPatientValue.length >= 1 ? searchPatientValue : null,
          careTeamIds: null,
          pagination: {
            take: 200,
            page: 0,
          },
        })
        .then(({ patients: newPatients }) => {
          setPatients(newPatients);
          setLoadingPatients(false)
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPatientValue]);

  const onChangePatientSearch = _.debounce((e) => setSearchPatientValue(e.target.value), 400)

  const patientsOptions = useMemo(
    () => patients?.map((p) => ({
      label: `${p.name} ${p.mrn ? ` - ${p.mrn}` : ''}` ?? p.id,
      value: useFhirId ? p.fhirId : p.id
    })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patients]
  );
  
  return (
    <Autocomplete
      multiple={multiple}
      disablePortal
      options={patientsOptions}
      value={value}
      onChange={onChange}
      filterOptions={(options, state) => {
        if (state.inputValue !== '') {
          return options.filter((i) => i.label.toLowerCase().includes(state.inputValue.toLowerCase()))
        }

        return options;
      }}
      renderInput={(params) =>
        <TextField
          placeholder="Type to search patients"
          variant={variant}
          {...params}
          onChange={onChangePatientSearch}
          label={label}
        />
      }
      renderOption={renderOption ?? ((props, option, { index }) => (
        <li {...props} key={option.value + index}>{option.label}</li>
      ))}
      disableCloseOnSelect={multiple}
      disabled={disabled}
      loading={loadingPatients}
      loadingText="Loading patients..."
      sx={sx}
    />
  )
}

export default PatientSearchDropdown;
