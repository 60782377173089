import { createContext, ReactNode, useEffect, useState } from 'react';
import { allLangs, defaultLang } from 'src/config';
import { Helmet } from 'react-helmet-async';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData, {
  TenantThemeConfig,
  initialTenantConfigData,
} from 'src/hooks/useTenantConfigData';
import { getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';

type TenantThemeProps = {
  children: ReactNode;
};

const TenantThemeContext = createContext<TenantThemeConfig>(
  initialTenantConfigData.theme as TenantThemeConfig
);

function TenantThemeProvider({ children }: TenantThemeProps) {
  const [tenantTheme, setTenantTheme] = useState<TenantThemeConfig>(
    initialTenantConfigData.theme as TenantThemeConfig
  );

  const { onChangeLang } = useLocales();
  const { languageResource, theme } = useTenantConfigData();

  useEffect(() => {
    const getLangByModule = () => {
      const tenantCode = getTenantCodeFromCareflowToken();
      try {
        const lang = allLangs.find(
          ({ value }) =>
            value &&
            ((languageResource && value.toLowerCase() === languageResource?.toLowerCase?.()) ||
              (tenantCode && value.toLowerCase() === tenantCode?.toLowerCase?.()))
        )?.value;
        if (lang) return onChangeLang(lang);
      } catch (error) {
        console.error('Error while setting language', error);
      }
      onChangeLang(defaultLang.value);
    };
    getLangByModule();
  }, [languageResource]);

  useEffect(() => {
    if (!theme) return;
    setTenantTheme(theme);
    try {
      if (theme.settings) localStorage.setItem('settings', JSON.stringify(theme.settings));
    } catch (error) {
      console.error('Error while setting theme settings', error);
    }
  }, [theme]);

  return (
    <TenantThemeContext.Provider value={tenantTheme}>
      {!!tenantTheme?.tenantFavicon && (
        <Helmet>
          <link
            id="favicon"
            rel="shortcut icon"
            type="image/png"
            sizes="16x16"
            href={`${tenantTheme?.tenantFavicon}`}
          />
        </Helmet>
      )}

      {children}
    </TenantThemeContext.Provider>
  );
}

export { TenantThemeContext, TenantThemeProvider };
