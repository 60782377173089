import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { useCommunications, useServiceRequest } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import {
  Annotation,
  Communication,
  Reference,
  ServiceRequest,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import Iconify from 'src/components/Iconify';
import useObjectState from 'src/hooks/useObjectState';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import ReferralViewModel from '../ViewModel/ReferralViewModel';
import { PatientCard, SummaryTable } from 'src/sections/careflow/common';
import { ReferralStatuses } from '../constants';
import { getFhirIdFromReferenceString } from '../../Activities/activities-utils';
import { useGetCcmReferralNotes } from 'src/services/api/ccm-referrals';
import { useEffect, useMemo } from 'react';
import fhirSystem from 'src/fhir/system';

export type ReferralNotesPropsType = {
  referral: ReferralViewModel;
  onCancel: () => void;
};

const ReferralNotes = ({ referral: propreferral, onCancel }: ReferralNotesPropsType) => {
  const [{ note }, updateState] = useObjectState({
    note: '',
  });
  const { data: referralNotes, refetch } = useGetCcmReferralNotes(propreferral?.id);
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();
  const authUser = useAuth().getCurrentUser();
  const [referral, { update: updateReferral, refresh }] = useServiceRequest(propreferral?.fhirId);
  const [_, { create: createCommunication }] = useCommunications({
    autofetch: false,
  });
  const [communications] = useCommunications({
    filter: {
      'part-of': propreferral?.fhirId,
    },
    autofetch: true
  })

  const validateForm = () => {
    if (!note || note.replace(/<[^>]+>/g, '') === '') {
      return false;
    }

    return true;
  };

  useEffect(() => {
    refetch();
  }, [propreferral?.id])

  const onSavePress = () => {
    setIsLoading(true);
    if (!validateForm()) {
      setSuccess(null);
      setError('Please fill the note field');
      setIsLoading(false);
      return;
    }

    const ownerId = getFhirIdFromReferenceString(authUser.user_fhir_uri);
    const creationDate = moment.utc().toISOString();

    const newNote: Annotation = {
      text: note.replace(/<[^>]+>/g, ''),
      authorReference: {
        reference: `Practitioner/${ownerId}`,
        display: authUser.name,
      },
      time: creationDate,
    };

    const newCommunication: Communication = {
      resourceType: 'Communication',
      sent: creationDate,
      status: 'completed',
      note: [
        {
          text: note.replace(/<[^>]+>/g, ''),
          authorReference: {
            reference: `Practitioner/${ownerId}`,
            type: 'Practitioner',
          },
          time: creationDate,
        },
      ],
      text: {
        status: 'empty',
        div: note,
      },
      subject: {
        reference: `Patient/${propreferral?.patientFhirId}`,
        type: 'Patient',
      },
      partOf: [
        {
          reference: `ServiceRequest/${propreferral?.fhirId}`,
        },
      ],
      sender: {
        reference: `Practitioner/${ownerId}`,
        type: 'Practitioner',
        display: authUser.name,
      },
      category: [
        {
          text: 'Referral',
          coding: [
            {
              system: fhirSystem.note.type.asString(),
              code: 'referral',
              display: 'Referral',
            },
          ],
        },
      ],
    };

    updateReferral({
      ...(referral ?? {}),
      note: [...(referral?.note ?? []), newNote],
    } as ServiceRequest)
      .then(() => {
        createCommunication(newCommunication)
          .then(() => {
            setError(null);
            setSuccess('Note saved successfully');
            setIsLoading(false);
            updateState({
              note: '',
            });
            refresh();
          })
          .catch(() => {
            setError('Error saving note. Please try again');
            setSuccess(null);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setError('Error saving note. Please try again');
        setSuccess(null);
        setIsLoading(false);
      });
  };

  const parsedCommunications = useMemo(() => [
      ...(communications ?? []),
      ...(referralNotes?.map(c => ({
        id: c.id,
        text: {
          div: c.note,
        },
        sent: c.createdAt,
        sender: {
          display: c.createdBy,
        }
      })) ?? [])
    ].sort((a, b) => {
      if (a.sent > b.sent) {
        return -1;
      }

      return 1
    }), [communications, referralNotes])

  return (
    <Box
      sx={{
        borderRadius: 1,
        width: '70%',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        m: 5,
        overflowY: 'scroll',
      }}
    >
      <Box sx={{ my: 3, justifyContent: 'space-between', display: 'flex', width: '100%' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Referral Notes
          </Typography>
        </Box>
        <Box sx={{ mt: -0.6 }}>
          <IconButton onClick={onCancel}>
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </Box>
      </Box>
      <PatientCard id={propreferral?.patientFhirId} showCloseButton={false} />
      <Box sx={{ pt: 2 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Date:</span> {moment(propreferral?.date).format('MMM DD, YYYY')}
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Referred To:</span>{' '}
          {propreferral?.referredFromPractitionerId ? (
            <>
              {propreferral?.referredFromPractitionerName} -{' '}
              {propreferral?.referredFromPractitionerOrganizationName}
            </>
          ) : propreferral?.referredFromOrganizationId ? (
            <>{propreferral?.referredFromOrganizationName}</>
          ) : propreferral?.referredFromDisplay ? (
            <>{propreferral?.referredFromDisplay}</>
          ) : null}
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Referred From:</span>{' '}
          {propreferral?.referredToPractitionerId ? (
            <>
              {propreferral?.referredToPractitionerName} -{' '}
              {propreferral?.referredToPractitionerOrganizationName}
            </>
          ) : propreferral?.referredToOrganizationName ? (
            <>{propreferral?.referredToOrganizationName}</>
          ) : propreferral?.referredToDisplay ? (
            <>{propreferral?.referredToDisplay}</>
          ) : null}
        </Typography>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Status:</span> {propreferral?.serviceName}
        </Typography>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Status:</span> {ReferralStatuses[propreferral?.status]}
        </Typography>
      </Box>
      <Divider sx={{ pt: 2 }} />
      <Grid item style={{ marginTop: '15px' }}>
        <FormControl fullWidth>
          <ReactQuill theme="snow" value={note} onChange={(val) => updateState({ note: val })} />
        </FormControl>
      </Grid>
      {error && (
        <Alert style={{ marginTop: '20px' }} severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert style={{ marginTop: '20px' }} severity="success">
          {success}
        </Alert>
      )}
      <ActionButtons
        isLoading={isLoading}
        leftButtonTitle="Clear note"
        onLeftButtonPress={() => updateState({ note: '' })}
        onRightButtonPress={onSavePress}
      />
      <Divider sx={{ mb: 2, mt: 2 }} />
      <SummaryTable
        columns={[
          {
            header: 'Date',
            field: 'sent',
            render(value: string) {
              return moment(value).format('MMM DD, Y - hh:mm a');
            },
          },
          {
            header: 'Note',
            field: '$',
            render(communication: Communication) {

              const text = communication?.text?.div || communication?.note?.[0]?.text;
              return text ? parse(text) : 'N/A';
            },
          },
          {
            header: 'Staff',
            field: 'sender',
            render(value: Reference) {
              return value.display ?? 'N/A';
            },
          },
        ]}
        data={parsedCommunications}
      />
    </Box>
  );
};

export default ReferralNotes;
