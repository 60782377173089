import { useCallback } from 'react';
import { useSnackbar, OptionsObject } from 'notistack';
import useLocales from './useLocales';

const useNotiMessage = () => {
  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const showErrorMessage = useCallback(
    (errorMessage: string, snackBarProps: OptionsObject = {}) => {
      if (!errorMessage.length) return;
      const separator = errorMessage[errorMessage.length - 1] !== '.' ? '. ' : ' ';
      enqueueSnackbar(`${errorMessage}${separator}${i18n('error.defaultFallback')}`, {
        persist: true,
        variant: 'error',
        ...snackBarProps,
      });
    },
    [enqueueSnackbar]
  );

  const showSuccessMessage = useCallback(
    (message: string, snackBarProps: OptionsObject = {}) => {
      enqueueSnackbar(message, {
        variant: 'success',
        ...snackBarProps,
      });
    },
    [enqueueSnackbar]
  );

  const showWarningMessage = useCallback(
    (message: string, snackBarProps: OptionsObject = {}) => {
      enqueueSnackbar(message, {
        variant: 'warning',
        ...snackBarProps,
      });
    },
    [enqueueSnackbar]
  );

  return { showErrorMessage, showSuccessMessage, showWarningMessage };
};

export default useNotiMessage;
