// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Stack,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Card,
  Alert,
  Grid,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { SummaryTable } from 'src/sections/careflow/common';
import { useMedication, useMedicationAdministrations, useMedicationDispenses } from 'src/@nicheaim/fhir-react';
import moment from 'moment';
import parse from 'html-react-parser';
import { MedicationAdministration } from 'src/@nicheaim/fhir-base/mappings/MedicationAdministration';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { MedicationDispense } from 'src/@nicheaim/fhir-base/mappings/MedicationDispense';
// import { useMatch } from 'react-router';
import TabItem from '../../group-sessions/types/TabItem';
import { useQuery } from '@tanstack/react-query';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import { DatePicker } from "@mui/lab";
import {
  PatientInfoHeader,
} from '../patient-info-header';
import { useCreateMedicationTransaction, useMedicationTransactionTypes } from 'src/services/api/medication-transaction';
import fhirSystem from 'src/fhir/system';
import { useParams } from 'react-router';

interface MedicationAdministrationsSummaryProps {
  isExpanded: boolean;
  patient: any;
  onExpand: (index: number) => void;
  showExpandButton?: boolean;
  showPatientColumn?: boolean;
  showPatientHeader?: boolean;
}

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: 1,
    label: 'Not Taken',
  },
  {
    key: 1,
    value: 2,
    label: 'Taken',
  },
];

const MedicationAdministrationsSummary = ({ isExpanded, onExpand, showExpandButton = true, showPatientColumn = false, showPatientHeader = false, patient }: MedicationAdministrationsSummaryProps) => {
  const authUser = useAuth().getCurrentUser();
  const params = useParams();
  const { date: paramDate, medicationId: paramMedicationId } = params as { medicationId?: string, date?: string } 
  const [selectedMedicationAdministration, setselectedMedicationAdministration] = useState<MedicationAdministration | null>(null);
  const [date, setDate] = useState<Date | null>(paramDate ? moment(paramDate).toDate() :  new Date());
  const [note, setNote] = useState<string | null>(null);
  const [wasTaken, setWasTaken] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { mutateAsync: createTransaction } = useCreateMedicationTransaction();
  const { data: transactionTypes } = useMedicationTransactionTypes();

  // TODO:
  // const medicationAdministrationUrlMatch =
  //     useMatch('/dashboard/patient/:patientId/health-record/medication-administration/:medicationAdministrationId')

  const start = moment(date).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  const end = moment(date).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  const status = selectedTabIndex === 0 ? 'in-progress' : 'completed,not-done';

  const [medication] = useMedication(getFhirIdFromReferenceString(selectedMedicationAdministration?.medicationReference?.reference ?? ''));
  const [_, { update }] = useMedicationAdministrations({ autofetch: false });

  const getMedicationAdministrations = async () =>
    axiosFhirInstance.get(
      `/MedicationAdministration?patient=${patient?.id}&_sort=-authoredOn&status=${status}&effective-time=ge${start}&effective-time=le${end}`
    );

  const { data: medicationAdministrationsData, refetch: refresh } = useQuery(['MedicationAdministration'], getMedicationAdministrations);

  const { data, refetch: refreshDispenses } = useQuery(['MedicationDispense'], () => axiosFhirInstance
    .get(`/MedicationDispense?patient=${patient?.id}&_has:MedicationRequest:_id=${medicationsAdministrations.map(i => i.id).join(',')}&_sort-whenPrepared`))

  const { data: medicationRequestsData, refetch: refreshRequests } = useQuery(['MedicationRequest'], () => axiosFhirInstance
    .get(`/MedicationRequest?patient=${patient?.id}&_has:Medication:_id=${medicationsAdministrations.map(i => getFhirIdFromReferenceString(i.medicationReference?.reference ?? '')).join(',')}`))

  const dispenses: MedicationDispense[] = useMemo(() => data?.data?.entry?.map((i) => i.resource), [data]);
  const requests: MedicationRequest[] = useMemo(() => medicationRequestsData?.data?.entry?.map(i => i.resource), [medicationRequestsData]);

  const [medicationDispense, { update: updateMedicationDispense }] = useMedicationDispenses({
    autofetch: selectedMedicationAdministration ? true : false,
    filter: {
      prescription: getFhirIdFromReferenceString(selectedMedicationAdministration?.request?.reference ?? ''),
    },
  });

  const medicationForm = useMemo(() => medication?.form?.coding?.find(c => c.system === fhirSystem.medication.dosageForm.asString()) ?? null, [medication]);

  const medicationAdministrations: MedicationAdministration[] = useMemo(() => medicationAdministrationsData?.data?.entry?.map(i => i.resource) ?? [], [medicationAdministrationsData]);

  const medicationsAdministrations: MedicationAdministration[]
    = useMemo(() => medicationAdministrations
      .filter(i => i.resourceType === 'MedicationAdministration'),
      [medicationAdministrations]
    );


  const onSignMedicationAdministration = useCallback(async () => {
    if (selectedMedicationAdministration) {
      await update({
        ...selectedMedicationAdministration,
        performer: [
          {
            actor: {
              display: authUser.name,
              reference: `Practitioner/${getFhirIdFromReferenceString(authUser.user_fhir_uri)}`
            }
          }
        ],
        note: [
          {
            text: note ?? '',
            time: moment().toISOString(),
            authorString: authUser.name,
          }
        ],
        status: wasTaken ? 'completed' : 'not-done',
      }).then(async () => {
        if (wasTaken) {
          const dispense = medicationDispense?.[0];

          if (dispense) {
            const selectedRequest: MedicationRequest | undefined
                = requests?.find(i => i.medicationReference?.reference === selectedMedicationAdministration?.medicationReference?.reference);

            await updateMedicationDispense({
              ...dispense,
              quantity: {
                value: dispense.quantity?.value === 0 ? 0 : (dispense?.quantity?.value ?? 1) - (selectedMedicationAdministration?.dosage?.rateQuantity?.value ?? 0) ?? 1,
              }
            }).then(() => {
              createTransaction({
                medicationRequestFhirId: selectedRequest?.id!,
                medicationRequestRXNumber: selectedRequest?.identifier?.find((i) => i.type?.coding?.find(c => c.code === 'RX'))?.value ?? 'N/A',
                staffFhirId: getFhirIdFromReferenceString(authUser.user_fhir_uri ?? '') ?? '',
                patientFhirId: patient?.id,
                performedDate: moment().toString(),
                transactionTypeId: transactionTypes?.find(t => t.valueCode === 'dispensed')?.id!,
                quantity: selectedMedicationAdministration?.dosage?.rateQuantity?.value ?? 0,
                unitId: medicationForm?.code ?? '',
                unitName: medicationForm?.display ?? '',
                medicationId: getFhirIdFromReferenceString(selectedMedicationAdministration?.medicationReference?.reference ?? '')!,
                medicationName: selectedMedicationAdministration.medicationReference?.display ?? 'N/A',
                note: note ?? '',
                loggedUser: authUser,
              })
              .then(() => {
                setNote(null);
                setselectedMedicationAdministration(null);
                setWasTaken(false);
                refresh();
                refreshDispenses();
              })
              .catch(() => {
                setNote(null);
                setselectedMedicationAdministration(null);
                setWasTaken(false);
                refresh();
                refreshDispenses()
              })
            })
          } else {
            setNote(null);
            setselectedMedicationAdministration(null);
            setWasTaken(false);
            refresh();
            refreshDispenses()
          }
        } else {
          setNote(null);
          setselectedMedicationAdministration(null);
          setWasTaken(false);
          refresh();
          refreshDispenses()
        }
      })
    }
  }, [authUser, createTransaction, medicationDispense, note, patient?.id, refresh, refreshDispenses, requests, selectedMedicationAdministration, transactionTypes, update, updateMedicationDispense, wasTaken])

  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key as number);
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [selectedTabIndex]
  );

  useEffect(() => {
    refresh();
  }, [patient?.id])

  useEffect(() => {
    refreshDispenses();
  }, [patient?.id, medicationsAdministrations, refreshDispenses]);

  useEffect(() => {
    refreshRequests();
  }, [patient?.id, refreshRequests]);

  // TODO:
  // useEffect(() => {
  //   if (medicationAdministrationUrlMatch) {
      
  //   }
  // }, [medicationAdministrationUrlMatch]);

  useEffect(() => {
    refresh();
  }, [refresh, selectedTabIndex, patient, date]);

  const renderTable = useCallback(() => (
      <SummaryTable
        title={(
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="subtitle1">Medications</Typography>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <DatePicker
                value={date}
                onChange={(selectedDate) => {
                  setDate(selectedDate)
                }}
                renderInput={(params) => <TextField type="date" {...params} />}
              />
            </Grid>
          </Grid>
        )}
        columns={[
          {
            header: 'Taken',
            field: '$',
            render(medicationAdministration) {
              if (selectedMedicationAdministration?.id === medicationAdministration.id) {
                return (
                  <RadioGroup
                    defaultValue={false}
                  >
                    <FormControlLabel onChange={(ev) => setWasTaken(false)} value={false} control={<Radio />} label="No" />
                    <FormControlLabel onChange={(ev) => setWasTaken(true)} value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                )
              }
              return medicationAdministration.performer ? medicationAdministration.status === 'completed' ? 'Yes' : 'No' : 'Incomplete'
            }
          },
          ...showPatientColumn ? [{
            header: 'Patient',
            field: '$',
            render(medicationAdministration: MedicationAdministration) {
              return medicationAdministration.subject.display
            }
          }] : [],
          {
            header: 'Medication / Strength / Route',
            field: '$',
            render(medicationAdministration) {
              return medicationAdministration?.medicationReference?.display
            }
          },
          {
            header: 'Quantity',
            field: '$',
            render(medicationAdministration) {
              return medicationAdministration?.dosage?.rateQuantity.value ?? 0
            }
          },
          {
            header: 'PRN',
            field: '$',
            render(medicationAdministration) {
              const request: MedicationRequest | undefined
                = requests?.find(i => i.medicationReference?.reference === medicationAdministration.medicationReference.reference);
              return request?.dosageInstruction?.[0].asNeededBoolean ? 'Yes' : 'No'
            }
          },
          {
            header: 'Inventory',
            field: '$',
            visible: isExpanded,
            render(medicationAdministration) {
              const dispense: MedicationDispense | undefined
                = dispenses?.find(i => i.authorizingPrescription?.[0].reference === medicationAdministration.request.reference);
              const request: MedicationRequest | undefined
                = requests?.find(i => i.medicationReference?.reference === medicationAdministration.medicationReference.reference);
              return medicationAdministration.status === 'completed' ? (
                'Dispensed from patient inventory'
              ) : (
                `Patient Inventory Quantity: ${dispense?.quantity?.value ?? 0}, refills ${request?.dispenseRequest?.numberOfRepeatsAllowed}`
              )
            }
          },
          {
            header: 'Date',
            field: '$',
            render(medicationAdministration) {
              const start = medicationAdministration.effectiveDateTime
              return start ? moment(start).format('MMM DD YYYY hh:mm a') : 'N/A';
            },
          },
          {
            header: 'Note',
            field: '$',
            visible: isExpanded,
            render(medicationAdministration) {
              if (selectedMedicationAdministration?.id === medicationAdministration?.id) {
                return (
                  <TextField
                    value={note}
                    onChange={(e) =>
                      setNote(e.target.value)
                    }
                    sx={{
                      height: '10px',
                      top: '-20px',
                    }}
                  />
                )
              } else {
                const noteFound = medicationAdministration.note?.[0];
                return noteFound ? parse(noteFound.text) : 'N/A';
              }
            },
          },
          {
            header: 'Staff',
            field: '$',
            visible: isExpanded,
            render(medicationAdministration) {
              const performer = medicationAdministration.performer?.[0]?.actor?.display;
              const dispense: MedicationDispense | undefined
                = dispenses?.find(i => i.authorizingPrescription?.[0].reference === medicationAdministration.request.reference);
              
              if (medicationAdministration.status !== 'completed' && (dispense?.quantity?.value ?? 0) < medicationAdministration?.dosage?.rateQuantity?.value) {
                return <Alert severity='warning' color="warning">Insufficient Inventory</Alert>
              }

              if (medicationAdministration.status !== 'completed' && !performer && selectedMedicationAdministration?.id !== medicationAdministration?.id) {
                return <Button onClick={() => setselectedMedicationAdministration(medicationAdministration)} variant="outlined" color="primary">Sign by</Button>
              }
              
              if (!performer && selectedMedicationAdministration && selectedMedicationAdministration?.id === medicationAdministration?.id) {
                return (
                  <Stack flexDirection="row">
                    <IconButton onClick={() => onSignMedicationAdministration()}>
                      <Iconify icon={'mdi:content-save-check-outline'} width={27} height={27} />
                    </IconButton>
                    <IconButton onClick={() => {
                      setselectedMedicationAdministration(null);
                      setNote(null);
                      setWasTaken(false);
                    }}>
                      <Iconify icon={'material-symbols:close-rounded'} width={27} height={27} />
                    </IconButton>
                  </Stack>
                )
              } 
              
              if (performer) {
                return `Signed by: ${performer} on ${moment(medicationAdministration.meta.lastUpdated).format('MMM DD YYYY hh:mm a')}`;
              }
            },
          },
        ]}
        data={medicationsAdministrations}
        actionBar={
          <>
            {showExpandButton && (
              <IconButton onClick={() => onExpand?.(3)}>
                <Iconify icon="eva:expand-fill" />
              </IconButton>
            )}
          </>
        }
      />
    ), [showExpandButton, dispenses, isExpanded, medicationsAdministrations, note, onExpand, onSignMedicationAdministration, selectedMedicationAdministration, wasTaken])

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, overflow: 'scroll', flexDirection: 'column' }}>
      {showPatientHeader && (
        <PatientInfoHeader
          patient={patient}
          displayMode={true}
        />
      )}
      {renderTabs()}
      {renderTable()}
    </Card>
  );
};

export default MedicationAdministrationsSummary;
