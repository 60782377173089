import { Container, Grid, Modal } from '@mui/material';
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Filters from './components/Filters/Filters';
import { ListTable } from './components/ListTable/ListTable';
import AddGroupSessionModal from './components/AddGroupSessionModal/AddGroupSessionModal';
import { DetailTable } from './components/DetailTable/DetailTable';
import { GroupSessionContext } from './GroupSessionContext';
import useController from './useController';
import { Route, Routes } from 'react-router';


export const GroupSessions = () => {
  const [
    {
      pagination,
      entities,
      selectedGroupSession,
      isLoading,
    },
    {
      filterEntities,
      onSaveGroupSession,
      onSelectGroupSession,
      openAddSessionModal,
      closeAddSessionModal,
      getEntities,
    },
  ] = useController();

  return (
    <GroupSessionContext.Provider
      value={{
        openScheduleSessionModal: openAddSessionModal,
        filterEntities,
        getEntities,
      }}
    >
      <Page title="Group sessions">
        <Routes>
          <Route
            path=":groupSessionId/edit"
            element={
              <Modal open={true} onClose={closeAddSessionModal}>
                <AddGroupSessionModal
                  onSaveCallback={onSaveGroupSession}
                  onCloseModal={closeAddSessionModal}
                  groupSession={selectedGroupSession}
                />
              </Modal>
            }
          />
          <Route
            path="new"
            element={
              <Modal open={true} onClose={closeAddSessionModal}>
                <AddGroupSessionModal
                  onSaveCallback={onSaveGroupSession}
                  onCloseModal={closeAddSessionModal}
                  groupSession={null}
                />
              </Modal>
            }
          />
        </Routes>
        <Container maxWidth={'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Breadcrumbs
                      title='Group Sessions'
                      links={[
                        {
                          href: '/dashboard',
                          name: 'Dashboard',
                        },
                        {
                          href: '',
                          name: 'Group Sessions',
                        },
                      ]}
                    />
                  }
                />
                <Route
                  path=":groupSessionId/sessions"
                  element={
                    <Breadcrumbs
                      title={`${selectedGroupSession?.name} - ${selectedGroupSession?.description}`
                      }
                      links={[
                        {
                          href: '/dashboard',
                          name: 'Dashboard',
                        },
                        {
                          href: '/dashboard/group-sessions',
                          name: 'Group Sessions',
                        },
                        {
                          href: '',
                          name: 'details'
                        }
                      ]}
                    />
                  }
                />
            </Routes>
              
            </Grid>
          </Grid>
          
          <Routes>
            <Route
              path=":groupSessionId/sessions"
              element={

                <DetailTable item={selectedGroupSession!} />
              }
            />
            <Route
              path="*"
              element={
                <>
                  <Filters
                    onChangeFilter={(value: string) => {
                      console.log({
                        value
                      })
                      filterEntities(value, 2);
                    }}
                  />
                  <ListTable
                    data={{
                      pagination,
                      items: entities,
                    }}
                    onSelectGroupSession={onSelectGroupSession}
                    isLoading={isLoading}
                  />
                </>
              }
            />
          </Routes>
        </Container>
      </Page>
    </GroupSessionContext.Provider>
  );
};
