import { createContext, useContext } from 'react';
import { FhirClient } from '../fhir-base';

interface FhirClientContext {
  // TODO: type client names
  clients: Record<string, FhirClient>;
}

export type FhirClientProviderProps = {
  children: React.ReactNode;
  // TODO: type client names
  clients: Record<string, FhirClient>;
};

const defaultClient: FhirClient = {
  findAll: async () => {
    throw new Error('Not implemented');
  },
  findById: async () => {
    throw new Error('Not implemented');
  },
  createOne: async () => {
    throw new Error('Not implemented');
  },
  updateOne: async () => {
    throw new Error('Not implemented');
  },
  patchOne: async () => {
    throw new Error('Not implemented');
  },
  removeOne: async () => {
    throw new Error('Not implemented');
  },
  get: async () => {
    throw new Error('Not implemented');
  },
};

const context = createContext<FhirClientContext>({
  clients: {
    default: defaultClient,
  },
});

/**
 * FhirClientProvider provides a context for FhirClient components to access the FhirRepository.
 */
export function FhirClientProvider({ children, ...options }: FhirClientProviderProps) {
  return <context.Provider value={options}>{children}</context.Provider>;
}

export function useFhirContext() {
  return useContext(context);
}
