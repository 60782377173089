import { useMemo } from 'react';
import { ObservationWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/Observation';
import { useObservations } from '../../../../../@nicheaim/fhir-react';
import moment from 'moment';

type Props = {
  patientId?: string;
};

const useScores = ({ patientId }: Props) => {
  const [observations] = useObservations({
    filter: { patient: patientId },
    autofetch: !!patientId,
    map: ObservationWrapper,
  });

  const prs = useMemo(() => {
    if (observations) {
      try {
        return (
          observations
            ?.filter((x) => x?.isPrs())
            ?.sort((a, b) => moment(b?.effectiveDateTime).diff(a?.effectiveDateTime))?.[0] ?? null
        );
      } catch (error) {
        return null;
      }
    }
  }, [observations]);

  const sil = useMemo(() => {
    if (observations) {
      try {
        return (
          observations
            ?.filter((x) => x?.isSil())
            ?.sort((a, b) => moment(b?.effectiveDateTime).diff(a?.effectiveDateTime))?.[0] ?? null
        );
      } catch (error) {
        return null;
      }
    }
  }, [observations]);

  return {
    sil,
    prs,
  };
};

export default useScores;
