import { getFormattedDatePeriod } from "src/utils/dates";
import PracticeStatusAlert from "./PracticeStatusAlert";
import { Practice } from "src/@types/crs/providerDirectory";
import { NPI_PROVIDER_IDENTIFIER_CODE } from "../constants";
import CloseIconButton from "src/components/CloseIconButton";
import { Box, Drawer, Grid, Stack, Typography } from "@mui/material";
import { ContactMail, ContactPhone, Place } from "@mui/icons-material";
import { InfoItem, InfoSection } from "./GeneralPractitionerPreviewDrawer";
import { AddressTable, TelecomTable } from "./new/ProviderDetailPreviewDialog";

export interface PracticeDetailPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  practice: Practice | null;
}

const PracticeDetailPreviewDialog = ({
  isOpen,
  onClose,
  practice,
}: PracticeDetailPreviewDialogProps) => {
  const npi = practice?.indentifier?.find?.(
    ({ code }) => code === NPI_PROVIDER_IDENTIFIER_CODE
  )?.value;
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: 10000 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "auto",
          flex: 1,
        }}
      >
        <Box
          sx={{
            boxShadow: "8px -30px 24px rgba(22,28,36,0.16)",
            display: "flex",
            flexDirection: "column",
            width: "30vw",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
              borderBottomColor: "#E5E8EB",
              paddingY: 1,
            }}
          >
            <Grid width={"100%"} height={"100%"} container sx={{ paddingX: 3 }}>
              <Stack
                style={{
                  width: "100%",
                }}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction="row"
              >
                <Typography variant="h6" color="text.primary">
                  {`Practice Details`}
                </Typography>
                <CloseIconButton
                  onClick={() => {
                    onClose?.();
                  }}
                />
              </Stack>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingX: 3,
              marginTop: 2.4,
            }}
          >
            <Box>
              <InfoItem label="NPI" value={npi} />
              <InfoItem label="Name" value={practice?.name?.trim?.()} />
              <InfoItem
                label="Period"
                value={getFormattedDatePeriod({
                  end: practice?.period_end ?? undefined,
                  start: practice?.period_start ?? undefined,
                })}
              />
              {!!practice?.status && (
                <InfoItem
                  label="Status"
                  value={
                    <Box sx={{ width: "30%" }}>
                      <PracticeStatusAlert status={practice.status} />
                    </Box>
                  }
                />
              )}
              <Box sx={{ marginTop: 3 }} />
              <InfoSection
                title="Phones"
                shouldShow
                titleIcon={<ContactPhone />}
              >
                <Grid item xs={12}>
                  <TelecomTable
                    type={"phone"}
                    telecom={
                      practice?.telecom?.filter?.(
                        ({ type }) => type === "phone"
                      ) ?? []
                    }
                  />
                </Grid>
              </InfoSection>
              <Box sx={{ marginBottom: 1.3 }} />
              <InfoSection title="Email" shouldShow titleIcon={<ContactMail />}>
                <Grid item xs={12}>
                  <TelecomTable
                    type={"email"}
                    telecom={
                      practice?.telecom?.filter?.(
                        ({ type }) => type === "email"
                      ) ?? []
                    }
                  />
                </Grid>
              </InfoSection>
              <Box sx={{ marginBottom: 1.3 }} />
              <InfoSection title="Addresses" shouldShow titleIcon={<Place />}>
                <Grid item xs={12}>
                  <AddressTable addresses={practice?.address ?? []} />
                </Grid>
              </InfoSection>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default PracticeDetailPreviewDialog;
