import { InputAdornment, Stack, TextField } from "@mui/material";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";

type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
};

export default function OrganizationTableToolbar({
  filterName,
  onFilterName,
}: Props) {
  const { i18n } = useLocales();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={i18n('admin.searchOrganization')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}