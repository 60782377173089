// @ts-nocheck
import {
  Container,
  Grid,
  Box,
  Modal,
} from "@mui/material";
import Bed from '../rounding/Model/Bed';
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Filters } from "./components/Filters/Filters";
import ListTable from "./components/ListTable/ListTable";
import AddBed from './components/AddBed/AddBed';
import { CensusProvider } from './CensusContext';
import BedAssignment from '../rounding/Model/BedAssignment';
import { useCensusBeds } from 'src/services/api/census/hooks';
import useObjectState from 'src/hooks/useObjectState';
import { useEffect } from "react";
import { BedPaginationType } from "src/services/api/census";

export type CensusStateType = {
  showAddBedModal: boolean;
  selectedBed: Bed | null;
  searchValue: string | null;
  locationFhirId: string | null;
  pagination: BedPaginationType;
}

export const Census = () => {
  const [{
    selectedBed,
    showAddBedModal,
    searchValue,
    locationFhirId,
    pagination,
  }, updateState] = useObjectState<CensusStateType>({
    showAddBedModal: false,
    selectedBed: null,
    searchValue: null,
    locationFhirId: null,
    pagination: {
      take: 10,
      skip: 0,
    }
  })
  const { data: response, isLoading, isFetching, refetch } = useCensusBeds({
    search: searchValue,
    locationFhirId: locationFhirId,
    status: 'active',
    pagination: {
      take: pagination?.take,
      skip: pagination?.skip
    },
  });
  const beds = response?.beds;
  const total = response?.total;

  const onSaveEntity = (bedId: number, bedAssignment: BedAssignment | null) => {
    refetch();
  };

  const onChangeLocation = (locationId: string | null) => updateState({
    locationFhirId: locationId,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const onChangeSearch = (search: string | null) => updateState({
    searchValue: search,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const openAddBedModal = (bed: Bed | null) => {
    updateState({
      selectedBed: bed,
      showAddBedModal: true,
    })
  };

  const closeAddBedModal = () => {
    updateState({
      selectedBed: null,
      showAddBedModal: false,
    })
  };

  useEffect(() => {
    refetch();
  }, [locationFhirId, refetch, searchValue, pagination])

  return (
    <CensusProvider
      value={{
        openAddBedModal,
        closeAddBedModal,
        onSaveEntity,
      }}
    >
      <Page title="Census">
        <Modal
          open={showAddBedModal}
          onClose={closeAddBedModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: 0,
              width: 400,
              height: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedBed && (
              <AddBed
                bed={selectedBed}
                onSaveCallback={onSaveEntity}
                onCancelPress={closeAddBedModal}
              />
            )}
          </Box>
        </Modal>
        <Container maxWidth={'xl'}>
          <Grid container spacing={3} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Breadcrumbs
                title="Census"
                links={[
                  {
                    href: '/dashboard',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Census',
                  }
                ]}
              />
            </Grid>
          </Grid>
          <Filters
            onChangeLocation={onChangeLocation}
            onChangeSearch={onChangeSearch}
          />
          <ListTable
            beds={isFetching ? [] : (beds ?? [])}
            isLoading={isLoading || isFetching}
            pagination={{
              take: pagination.take,
              page: pagination.skip,
              total,
            }}
            changePage={(newPage) => {
              updateState({
                pagination: {
                  take: pagination.take,
                  skip: newPage,
                },
              });
            }}
          />
        </Container>
      </Page>
    </CensusProvider>
  );
}
