import { createContext } from 'react';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { Extension } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export interface RelatedGoal {
  extension: Extension[];
  wrappedGoal: WrappedGoal;
}

export interface GoalContextData {
  relatedGoals?: RelatedGoal[];
}

const initialGoalContextData: GoalContextData = {
  relatedGoals: [],
};

const GoalContext = createContext<GoalContextData>(initialGoalContextData);

export default GoalContext;
