//@ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { DatePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider } from 'src/components/hook-form';
import { useConditions, useValueSet } from 'src/@nicheaim/fhir-react';
import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import DateTimePicker from 'src/components/DateTimePicker/DateTimePicker';
import { isTimeIncludedInDate } from 'src/utils/dates';
import { isArray } from 'lodash';
import InfoRibbon from '../../../../../../components/InfoRibbon';
import {
  InfoRibbonCondition,
  InfoRibbonPatient,
} from '../../../../../../components/InfoRibbonSections';
import AnnotationsModal from '../../../../common/AnnotationsModal';
import AnnotationsList from '../../../../common/AnnotationsList';

export enum AppointmentStatus {
  PROPOSED = 'proposed',
  PENDING = 'pending',
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  NO_SHOW = 'noshow',
  ENTERED_IN_ERROR = 'entered-in-error',
  CHECKED_IN = 'checked-in',
  WAITLIST = 'waitlist',
}

export enum AppointmentParticipantRequired {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  INFORMATION_ONLY = 'information-only',
}

export enum AppointmentParticipantStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  NEEDS_ACTION = 'needs-action',
}

type CustomParticipant = {
  actor: {
    label: string;
    value: string;
  };
  status: string;
  required: string;
};

type FormValue = {
  diagnosis: string;
  start: string;
  end: string;
};

type Props = {
  patient: WrappedPatient | null;
  appointment: any | null;
  open: boolean;
  onClose: VoidFunction;
  handleAppointment: (data: any) => Promise<any>;
};

export function ConditionForm({ patient, appointment, open, onClose, handleAppointment }: Props) {
  const [annotations, setAnnotations] = useState<any[]>([]);
  const [annotationsOpen, setAnnotationsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);
  const { i18n } = useLocales();

  const EventSchema = Yup.object().shape({
    diagnosis: Yup.string().required('Status is required'),
    end: Yup.date()
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
      .test('is-greater', 'End date must be greater than start date', function (value) {
        const startDate = moment(this.parent.start);
        const endDate = moment(value);
        if (!startDate.isValid() || !endDate.isValid()) return true;

        return startDate.isSameOrBefore(
          endDate,
          isTimeIncludedInDate(endDate) && isTimeIncludedInDate(startDate) ? undefined : 'date'
        );
      }),
  });

  const initDate = new Date();

  const [date, setDate] = useState<Date | null>(
    appointment ? appointment?.onsetPeriod?.recordedDate : initDate
  );

  const defaultValues = useMemo(
    () =>
      ({
        diagnosis: 'dsas',
        start: appointment?.onsetPeriod?.start || '',
        end: appointment?.onsetPeriod?.end || '',
      } as FormValue),
    [appointment]
  );

  const methods = useForm<FormValue>({ resolver: yupResolver(EventSchema) });

  const [, { create, update }] = useConditions({ autofetch: false });

  const [diagnosisCodes] = useValueSet('diagnosis-codes');

  const [conditionPriority] = useValueSet('ph-condition-category');

  const [diagnosis, setDiagnosis] = useState<Coding | undefined | null>();

  const [conditionPriorityValue, setConditionPriorityValue] = useState<Coding | undefined | null>();

  const [isTimeIncluded, setIsTimeIncluded] = useState(false);
  const [isTimeIncludedInEndInitially, setIsTimeIncludedInEndInitially] = useState(false);
  const [isTimeIncludedInStartInitially, setIsTimeIncludedInStartInitially] = useState(false);

  useEffect(() => {
    if (!appointment) return;
    setIsTimeIncludedInStartInitially(
      isTimeIncludedInDate(appointment?.onsetPeriod?.start ?? null)
    );
    setIsTimeIncludedInEndInitially(isTimeIncludedInDate(appointment?.onsetPeriod?.end ?? null));

    if (appointment?.note && isArray(appointment?.note)) {
      setAnnotations([...appointment.note]);
    } else {
      setAnnotations([]);
    }
  }, [appointment]);

  useEffect(() => {
    if (diagnosisCodes || conditionPriority) {
      setDiagnosis(appointment ? appointment.code.coding[0] : null);
      setConditionPriorityValue(appointment ? appointment.category[0].coding[0] : null);
    }
  }, [diagnosisCodes, appointment, conditionPriority]);

  const { reset, control, handleSubmit } = methods;

  const onSubmit = async (dataForm: FormValue) => {
    if (appointment) {
      updateCondition(dataForm);
    } else {
      createCondition(dataForm);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSaveAnnotation = async (data: any) => {
    setAnnotations((originalAnnotations) => [...originalAnnotations, data]);
  };

  const handleViewAllAnnotation = () => {
    setAnnotationsOpen(true);
  };

  const handleHideAllAnnotation = () => {
    setAnnotationsOpen(false);
  };

  const annotationModalBreadcrumbs = [
    `${i18n('patients.details.tabs.health', 'crs')}`,
    `${i18n('patients.details.conditions.title', 'crs')}`,
    `${i18n('annotations.details.title', 'crs')}`,
  ];

  const AnnotationsModalHeader = () =>
    !!patient ? (
      <InfoRibbon containerSx={{ height: '60px', marginTop: 0, flexWrap: 'wrap' }} xs={12}>
        <Grid container item xs={5} pl={3}>
          <InfoRibbonPatient patient={patient} />
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: '-1px', borderStyle: 'dashed' }} />
        <Grid container item xs={7} pl={3}>
          <InfoRibbonCondition condition={appointment} />
        </Grid>
      </InfoRibbon>
    ) : null;

  const createCondition = async (dataForm: any) => {
    const payload = {
      resourceType: 'Condition',
      subject: { reference: `Patient/${patient?.id}` },
      category: [
        {
          coding: [
            {
              code: conditionPriorityValue?.code,
              display: conditionPriorityValue?.display,
              system: conditionPriority?.compose?.include?.[0]?.system,
            },
          ],
          text: conditionPriorityValue?.display,
        },
      ],
      code: {
        coding: [
          {
            display: diagnosis?.display,
            system: diagnosisCodes?.compose?.include?.[0]?.system,
            code: diagnosis?.code,
          },
        ],
        text: diagnosis?.display,
      },
      recordedDate: date?.toISOString(),
      ...((dataForm?.start || dataForm?.end) && {
        onsetPeriod: {
          ...(dataForm?.start && { start: dataForm?.start }),
          ...(dataForm?.end && { end: dataForm?.end }),
        },
      }),
    };

    if (annotations && annotations.length > 0) {
      const note = annotations.map((x) => ({
        time: x?.time,
        text: x?.text,
        authorString: x?.authorString,
      }));
      payload.note = note;
    }

    await create(payload)
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };

  const updateCondition = async (dataForm: any) => {
    const payload = {
      id: appointment?.id,
      resourceType: 'Condition',
      subject: { reference: `Patient/${patient?.id}` },
      category: [
        {
          coding: [
            {
              code: conditionPriorityValue?.code,
              display: conditionPriorityValue?.display,
              system: conditionPriority?.compose?.include?.[0]?.system,
            },
          ],
          text: conditionPriorityValue?.display,
        },
      ],
      code: {
        coding: [
          {
            display: diagnosis?.display,
            system: diagnosisCodes?.compose?.include?.[0]?.system,
            code: diagnosis?.code,
          },
        ],
        text: diagnosis?.display,
      },
      recordedDate: date?.toISOString(),
      onsetPeriod:
        dataForm?.start && dataForm?.end
          ? {
              start: moment(dataForm?.start).toISOString(),
              end: moment(dataForm?.end).toISOString(),
            }
          : undefined,
    };

    if (annotations && annotations.length > 0) {
      const note = annotations.map((x) => ({
        time: x?.time,
        text: x?.text,
        authorString: x?.authorString,
      }));
      payload.note = note;
    }

    await update(payload)
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };
  return (
    <>
      {annotationsOpen ? (
        <AnnotationsModal
          open={annotationsOpen}
          isLoading={false}
          handlerSave={onSaveAnnotation}
          annotations={annotations}
          breadcrumbs={annotationModalBreadcrumbs}
          onClose={handleHideAllAnnotation}
          patient={patient}
          modalHeader={<AnnotationsModalHeader />}
        />
      ) : null}
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle>
          {appointment
            ? 'Edit Condition'
            : `${i18n('patients.details.conditions.titleAddConditions', 'crs')}`}{' '}
        </DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ m: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={2} sx={{ p: 2 }}>
                  <Autocomplete
                    disablePortal
                    // TODO please fix the options values
                    options={diagnosisCodes?.compose?.include[0]?.concept ?? ([] as Coding[])}
                    getOptionLabel={(option: any) => `${option.display} (${option.code})`}
                    value={diagnosis}
                    filterOptions={(options, params) =>
                      options.filter((c) =>
                        c.display
                          ?.toLocaleLowerCase()
                          .includes(params.inputValue.toLocaleLowerCase())
                      )
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={`${i18n('patients.details.conditions.diagnosis', 'crs')}*`}
                        margin="dense"
                        size="small"
                        variant="standard"
                      />
                    )}
                    onChange={(ev, newValue) => {
                      console.log(newValue);
                      setDiagnosis(newValue);
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    // TODO please fix the options values
                    options={conditionPriority?.compose?.include[0]?.concept ?? ([] as Coding[])}
                    getOptionLabel={(option: any) => `${option.display} (${option.code})`}
                    value={conditionPriorityValue}
                    filterOptions={(options, params) =>
                      options.filter((c) =>
                        c.display
                          ?.toLocaleLowerCase()
                          .includes(params.inputValue.toLocaleLowerCase())
                      )
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={`${i18n('patients.details.conditions.conditionPriority', 'crs')}*`}
                        margin="dense"
                        size="small"
                        variant="standard"
                      />
                    )}
                    onChange={(ev, newValue) => {
                      setConditionPriorityValue(newValue);
                    }}
                    style={{ marginBottom: '25px' }}
                  />

                  <DatePicker
                    label={i18n('patients.details.conditions.recordedDate', 'crs')}
                    value={date}
                    onChange={setDate}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                  />
                  <Typography sx={{ ml: 1, mt: 0.7, py: 2 }}>
                    {i18n('patients.details.conditions.onsetPeriod', 'crs')}
                  </Typography>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={{ width: 1 }}>
                    <Controller
                      name="start"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <DateTimePicker
                            {...field}
                            textFieldProps={{ fullWidth: true }}
                            label={i18n('patients.details.conditions.start', 'crs')}
                            isTimeIncluded={
                              appointment ? isTimeIncludedInStartInitially : isTimeIncluded
                            }
                            onTimeInclusionChange={setIsTimeIncluded}
                            isTimeOptional
                            errorMessage={error?.message}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="end"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateTimePicker
                          {...field}
                          textFieldProps={{ fullWidth: true }}
                          isTimeIncluded={
                            appointment ? isTimeIncludedInEndInitially : isTimeIncluded
                          }
                          label={i18n('patients.details.conditions.end', 'crs')}
                          isTimeOptional
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <AnnotationsList annotations={annotations} onViewAll={handleViewAllAnnotation} />
                </Stack>
              </Grid>
            </Grid>
            <Stack spacing={2} alignItems="center">
              <DialogActions>
                <Box sx={{ flexGrow: 1 }} />

                <Button variant="contained" color="info" onClick={handleClose}>
                  {i18n('cancel')}
                </Button>

                <Button
                  disabled={diagnosis == null || conditionPriorityValue == null}
                  variant="contained"
                  color="info"
                  type="submit"
                >
                  {i18n('submit')}
                </Button>
              </DialogActions>
            </Stack>
          </Card>
        </FormProvider>
      </Dialog>
    </>
  );
}
