import { GridColDef } from '@mui/x-data-grid';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import {
  CareTeamMemberGridRowData,
  CareTeamMemberResourceTypes,
  CareTeamPermissions,
  Option,
} from 'src/@types/crs/case';
import DataGridWithFilters, {
  GridFilterDrawerProps,
} from '../DataGridWithFilters/DataGridWithFilters';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import useObjectState from 'src/hooks/useObjectState';
import { momentDateComparator } from 'src/utils/dates';
import ActionButton, { MenuOption } from 'src/components/ActionButton';
import { Edit as EditIcon } from '@mui/icons-material';
import PeriodFilter from 'src/components/PeriodFilter';
import CareTeamMemberModal from './CareTeamMemberModal';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import CareTeamMemberEditModal from './CareTeamMemberEditModal';
import {
  Coding,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import CustomLink from 'src/sections/crs/common/CustomLink';
import PractitionerDrawer from 'src/sections/crs/provider/PractitionerDrawer';
import useLocales from 'src/hooks/useLocales';

export type CareTeamMemberOption = Option<CareTeamMemberResourceTypes>;

interface CareTeamMembersGridProps extends GridFilterDrawerProps<CareTeamMemberFilters> {
  patient: WrappedPatient;
  onMemberUpdateSuccess: Function;
  careTeamMembers: CareTeamMemberGridRowData[];
  careTeam: WrappedCareTeam;
  showPagination?: boolean;
  isNestedGrid?: boolean;
  associatedOrgs: Reference[];
  roles: Coding[];
}

export interface CareTeamMemberFilters {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  memberTypes: CareTeamMemberOption[];
  associatedOrg: Reference[];
  role: Coding[];
}

interface CareTeamMembersGridState {
  isModalOpen: boolean;
  isEditModalOpen: boolean;
  memberToEdit: CareTeamMemberGridRowData | null;
  associatedOrganizationIds: string[];
  isPractitionerDrawerOpen: boolean;
}

export const initialCareTeamMemberFilters: CareTeamMemberFilters = {
  startDate: null,
  endDate: null,
  memberTypes: [],
  associatedOrg: [],
  role: [],
};

const CareTeamMembersGrid = ({
  careTeamMembers,
  filterValues,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
  patient,
  showPagination = false,
  isNestedGrid = true,
  careTeam,
  onMemberUpdateSuccess,
  associatedOrgs,
  roles,
}: CareTeamMembersGridProps) => {
  
  const { i18n } = useLocales();
  const { filters, updateFilters, onClearAllFilters } =
  useGridFilterConsolidation<CareTeamMemberFilters>(filterValues, initialCareTeamMemberFilters);

  const [{ isEditModalOpen, isModalOpen, memberToEdit, isPractitionerDrawerOpen }, updateState] =
    useObjectState<CareTeamMembersGridState>({
      memberToEdit: null,
      isModalOpen: false,
      isEditModalOpen: false,
      associatedOrganizationIds: [],
      isPractitionerDrawerOpen: false,
    });

  const { isAllowedToEdit } = usePermissionsContext<CareTeamPermissions['members']>() ?? {};

  const getMenuOptions = (member: CareTeamMemberGridRowData): MenuOption[] => [
    {
      IconComponent: EditIcon,
      title: 'Edit',
      callback: () => {
        updateState({
          isEditModalOpen: true,
          memberToEdit: member,
        });
      },
      iconColor: '#00ab55',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => {
        const member = params.row as CareTeamMemberGridRowData;
        const { name, memberType } = member;

        return memberType === 'Practitioner' ? (
          <CustomLink
            to="#"
            onClick={() => {
              if (memberType === 'Practitioner')
                return updateState({ isPractitionerDrawerOpen: true, memberToEdit: member });
            }}
          >
            {' '}
            {name}{' '}
          </CustomLink>
        ): name
      }
    },
    {
      field: 'identifier',
      headerName: 'Identifier',
      flex: 1,
    },
    { field: 'memberType', headerName: 'Member Type', flex: 1 },
    { field: 'associatedOrg', headerName: 'Managing Organization', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1 },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      sortComparator: (_, __, cellParams1, cellParams2) => {
        const { date: date1 } = cellParams1?.value ?? {};
        const { date: date2 } = cellParams2?.value ?? {};
        return momentDateComparator(date1, date2);
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      sortComparator: (_, __, cellParams1, cellParams2) => {
        const { date: date1 } = cellParams1?.value ?? {};
        const { date: date2 } = cellParams2?.value ?? {};

        return momentDateComparator(date1, date2);
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const member = params.row as CareTeamMemberGridRowData;
        return !!isAllowedToEdit ? <ActionButton menuOptions={getMenuOptions(member)} /> : null;
      },
    },
  ];

  const handleClosePractitionerDrawer = () => {
    updateState({ isPractitionerDrawerOpen: false, memberToEdit: null });
  };

  return (
    <>
      <DataGridWithFilters
        addButtonTitle={'Manage Members'}
        title={i18n('case.details.careteams.addNewMember', 'crs')}
        onAddButtonClick={() => {
          updateState({
            isModalOpen: true,
          });
        }}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={careTeamMembers}
        columns={columns}
        getRowId={(row) => row.id}
        showPagination={showPagination}
        autoHeight={true}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        isNestedGrid={isNestedGrid}
        Filters={
          <>
            <Box py={3}>
              <Box marginBottom={1}>
                <PeriodFilter
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onStartDateChange={(newValue) => {
                    updateFilters({
                      startDate: newValue,
                    });
                  }}
                  onEndDateChange={(newValue) => {
                    updateFilters({
                      endDate: newValue,
                    });
                  }}
                />

                <Typography mb={1} mt={3} fontWeight={'bold'}>
                  Member Type
                </Typography>
                <Autocomplete
                  multiple
                  value={filters.memberTypes}
                  fullWidth
                  onChange={(_, newValue) => {
                    updateFilters({
                      memberTypes: newValue,
                    });
                  }}
                  options={careTeamMemberTypes}
                  getOptionLabel={({ label }: CareTeamMemberOption) => label}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />

                <Typography mb={1} mt={3} fontWeight={'bold'}>
                  Associated Organization
                </Typography>
                <Autocomplete
                  multiple
                  value={filters.associatedOrg}
                  fullWidth
                  onChange={(_, newValue) => {
                    updateFilters({
                      associatedOrg: newValue,
                    });
                  }}
                  options={associatedOrgs}
                  getOptionLabel={({ display }: Reference) => display ?? ''}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />

                <Typography mb={1} mt={3} fontWeight={'bold'}>
                  Role
                </Typography>
                <Autocomplete
                  multiple
                  value={filters.role}
                  fullWidth
                  onChange={(_, newValue) => {
                    updateFilters({
                      role: newValue,
                    });
                  }}
                  options={roles}
                  getOptionLabel={({ display }: Coding) => display ?? ''}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Box>
            </Box>
          </>
        }
        FilterDrawerProps={{
          title: i18n('case.details.careteams.filterPopUp.title', 'crs'),
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />
      <CareTeamMemberModal
        onMemberUpdateSuccess={onMemberUpdateSuccess}
        careTeamId={careTeam?.id as string}
        careTeamMembers={careTeamMembers}
        patient={patient}
        open={isModalOpen}
        onClose={() => {
          updateState({
            isModalOpen: false,
          });
        }}
      />
      <CareTeamMemberEditModal
        careTeamMember={memberToEdit}
        onMemberUpdateSuccess={onMemberUpdateSuccess}
        careTeam={careTeam}
        open={isEditModalOpen}
        onClose={() => {
          updateState({
            isEditModalOpen: false,
            memberToEdit: null,
          });
        }}
      />
      <PractitionerDrawer
        open={isPractitionerDrawerOpen}
        practitionerId={memberToEdit?.id}
        onClose={handleClosePractitionerDrawer}
        onCloseIconButtonClick={handleClosePractitionerDrawer}
      />
    </>
  );
};

const careTeamMemberTypes: CareTeamMemberOption[] = [
  { label: 'Practitioner', value: 'Practitioner' },
  { label: 'Practitioner Role', value: 'PractitionerRole' },
  { label: 'Care Team', value: 'CareTeam' },
];

export default CareTeamMembersGrid;
