import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import ContactAction from './ContactAction';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  row: any;
  patient: WrappedPatient | null;
};

export default function ContactRow({ row, patient }: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {`${row?.name?.given?.[0]} ${row?.name?.family}`}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e?.system === 'phone')?.[0]?.value}
            </Typography>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e?.system === 'email')?.[0]?.value}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACTS.EDIT] })
                ? [
                    <MenuItem key={row?.id} onClick={() => handleEdit(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : null
            }
          />
        </TableCell>
      </TableRow>
      <ContactAction
        patient={patient}
        contact={data}
        open={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
      />
    </>
  );
}
