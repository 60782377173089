import ccmAcls from './ccm/ccmAcls';
import ccmAclsGroups from './ccm/ccmAclsGroups';
import { getUserPermissions } from './get.user.permissions';

export enum ACLValidationMode {
  INCLUSIVE = 'inclusive',
  EXCLUSIVE = 'exclusive',
}

export const adminAll: string = 'root.admin.*';
export const SysAdmin: string = 'root.*';
export const CCMUser: string = 'root.ccm.*';

// CCM ACLS AND ACLS GROUPS
export const CCM_ACLS = ccmAcls;
export const permissionsAcls = ccmAclsGroups;

export function getMatchingPermissions(
  assignedPermissionsSpec: string,
  requiredPermissions: string[]
): string[] {
  if (!assignedPermissionsSpec || !requiredPermissions) {
    return [];
  }
  const regex = new RegExp(
    ['^', assignedPermissionsSpec.replace(/\./g, '\\.').replace(/\*/g, '.+'), '$'].join('')
  );
  return requiredPermissions.filter((permission) => permission.match(regex));
}

export function isAllowed(
  assignedPermissionsSpecs: string[],
  ...requiredPermissions: string[]
): boolean {
  if (
    !assignedPermissionsSpecs ||
    !requiredPermissions ||
    assignedPermissionsSpecs.length === 0 ||
    requiredPermissions.length === 0
  ) {
    return false;
  }
  const matchingPermissions = assignedPermissionsSpecs.reduce((matchingPermissions, spec) => {
    getMatchingPermissions(spec, requiredPermissions).forEach((permission) => {
      matchingPermissions.add(permission);
    });

    return matchingPermissions;
  }, new Set<string>());

  return requiredPermissions.some((permission) => matchingPermissions.has(permission));
}

export const getRelatedAcls = (aclsToCheck: string[]) => {
  let allAcls: string[] = [];
  try {
    aclsToCheck.map((acl) => {
      const acls = acl.split('.');
      allAcls = [
        acl,
        ...allAcls,
        ...acls.reduce<string[]>((upperAcls, acl, index) => {
          if (index === acls.length - 1) return upperAcls;
          const previousAcls = acls.slice(0, index);
          if (previousAcls.length) return [...upperAcls, `${previousAcls.join('.')}.${acl}.*`];
          return [...upperAcls, `${acl}.*`];
        }, []),
      ];
    });

    return [...new Set([...allAcls])];
  } catch (error) {
    return [];
  }
};

export interface ACLValidation {
  acls: string[];
}

/*Inclusive Mode = Will consider related(parents) ACL in the ACL hierarchy*/
/*Exclusive Mode = Will only consider acls being pass down as params*/
export function checkAclValidation(
  validation: ACLValidation,
  mode: ACLValidationMode = ACLValidationMode.INCLUSIVE
): boolean {
  const userPermissions = getUserPermissions();

  const { acls: aclsToCheck } = validation;

  switch (mode) {
    case ACLValidationMode.INCLUSIVE:
      const isAclValid = aclsToCheck.filter((permission) => userPermissions.isAllowed(permission));
      return !!isAclValid.length;
    default:
      const userAssignedPermissions = new Set(userPermissions.permissions);
      const validation = [...aclsToCheck, SysAdmin].some((acl) => userAssignedPermissions.has(acl));
      return validation;
  }
}
