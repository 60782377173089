// @ts-nocheck
import ArticleIcon from '@mui/icons-material/Article';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LinearProgress, MenuItem, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import agent from 'src/api/agent';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fPercent } from 'src/utils/formatNumber';
import { getAge } from 'src/utils/formatTime';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { PrintableCarePlan } from '../details/PrintableCarePlan';
import Edit from './Edit';
import CustomLink from 'src/sections/crs/common/CustomLink';

export default function Row({ row, workflowType, handleRefresh }: any) {
  const [workflow, setWorkflow] = useState<any>(null);
  const [stages, setStages] = useState<any>([]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  useEffect(() => {
    fetchWorkFlow();
  }, []);

  const fetchWorkFlow = async () => {
    const getWorkflow = await agent.Workflow.getWorkflowByCode(workflowType);
    setWorkflow(getWorkflow);
    setStages(getWorkflow?.stages);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    setOpenModalEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenModalEdit(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography noWrap variant="body2">
            <CustomLink 
              to={`${PATH_DASHBOARD.crs.case}/${row?.id}`}
            >
              {row?.internalNumber}
            </CustomLink>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {row?.createdOnFormatted &&
              format(new Date(row?.createdOnFormatted), 'MMM dd, yyyy hh:mm')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{row?.birthDate && `${getAge(row?.birthDate)} years`}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ textTransform: 'capitalize' }}>{row?.statusIndexed}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row?.status && capitalCase(row.status.replace('.', ' '))}</Typography>
        </TableCell>
        <TableCell>
          <Typography textTransform="capitalize">
            {row?.owner && row.owner.split('@')[0].replace('.', ' ')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{row?.scope}</Typography>
        </TableCell>
        <TableCell>
          <ProgressBar row={row?.stepOrder} stepLength={stages?.length} />
        </TableCell>
        {workflowType === 'REFERRALS_WORKFLOW' &&
          checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.EDIT] }) && (
            <TableCell align="right">
              <TableMoreMenu
                open={openMenu}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                actions={
                  <>
                    <MenuItem onClick={handleEdit}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>
                    <a
                      href={`${PATH_DASHBOARD.crs.referral}/${row?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <MenuItem sx={{ color: 'black' }}>
                        <OpenInNewIcon />
                        Detail
                      </MenuItem>
                    </a>
                  </>
                }
              />
            </TableCell>
          )}

        {workflowType === 'CASES_WORKFLOW' && (
          <TableCell align="right">
            <TableMoreMenu
              width={260}
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CASES.EDIT] }) && (
                    <MenuItem onClick={handleEdit}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>
                  )}
                  {checkAclValidation({ acls: [crsAcls.CRS.CASE.VIEW] }) && (
                    <a
                      href={`${PATH_DASHBOARD.crs.case}/${row?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <MenuItem sx={{ color: 'black' }}>
                        <OpenInNewIcon />
                        Detail
                      </MenuItem>
                    </a>
                  )}
                  <PrintableCarePlan
                    key={row?.id}
                    carePlanId={row?.id}
                    renderButton={(onClick) => {
                      return (
                        <MenuItem onClick={onClick}>
                          <ArticleIcon />
                          Generate Printable Case
                        </MenuItem>
                      );
                    }}
                  />
                </>
              }
            />
          </TableCell>
        )}
      </TableRow>

      <Edit
        data={row}
        workflow={workflow}
        open={openModalEdit}
        onClose={handleCloseEdit}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

function ProgressBar({ row, stepLength }: any) {
  const percent = (row / stepLength) * 100;

  return (
    <Stack spacing={1}>
      {stepLength !== undefined && percent !== Infinity && (
        <>
          <LinearProgress variant="determinate" value={percent} color="info" sx={{ mt: 1 }} />
          <Stack
            direction="row"
            justifyContent={
              (percent >= 50 && percent < 100 && 'center') || (percent === 100 && 'right') || 'left'
            }
          >
            <Typography variant="subtitle2">{fPercent(percent) + ` - `}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {row + `/${stepLength}`}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}
