// @ts-nocheck
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import useObjectState from '../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  TextField,
  Alert,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { useLocations } from 'src/@nicheaim/fhir-react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Rounding from '../../Model/Rounding';
import { useBedsToRounding, useRoundingTypes, useSaveRoundingMutation } from 'src/services/api/rounding/index';
import { useCensusBeds } from 'src/services/api/census/hooks';
import Bed from '../../Model/Bed';
import { WidgetEnum } from 'src/sections/careflow/care-plan/constants';

const sectionOptions = [
  WidgetEnum.diagnosis,
  WidgetEnum.medications,
  WidgetEnum.vitals,
  WidgetEnum.labs,
  WidgetEnum.notes,
  WidgetEnum.services
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type AddRoundingPropsType = {
    onSaveCallback: (rounding: Rounding) => void;
    onCloseModal: () => void;
    rounding: Rounding | null;
}

type AddRoundingStateType = {
  categoryItem: { label: string; value: number; } | null;
  startDateInterval: string;
  startTimeInterval: string;
  interval: number;
  sectionItems: string[];
  bedItems: { label: string; value: number; }[];
  locationItem: { label: string; value: string; } | null;
  validated: boolean;
}

const defaulState: AddRoundingStateType = {
  categoryItem: null,
  startDateInterval: moment().format('YYYY-MM-DD'),
  startTimeInterval: moment().format('HH:mm'),
  interval: 60,
  sectionItems: [],
  bedItems: [],
  locationItem: null,
  validated: true,
};

const getDefaultState = (rounding?: Rounding | null): AddRoundingStateType => {
  if (rounding) {
    return {
      categoryItem: {
        label: rounding.category?.valueDisplayName,
        value: rounding.category?.id,
      },
      startDateInterval: moment(rounding.startInterval).format('YYYY-MM-DD'),
      startTimeInterval: moment(rounding.startInterval).format('HH:mm'),
      interval: rounding.timeInterval,
      sectionItems: rounding.sections.split(','),
      bedItems: [],
      locationItem: {
        label: '',
        value: rounding.locationFhirId,
      },
      validated: true,
    };
  }

  return defaulState;
};

const AddRounding = ({
  onSaveCallback,
  onCloseModal,
  rounding,
}: AddRoundingPropsType) => {
  const [{
    categoryItem,
    startDateInterval,
    startTimeInterval,
    interval,
    bedItems,
    sectionItems,
    locationItem,
    validated,
  }, updateState] = useObjectState<AddRoundingStateType>(getDefaultState(rounding));
  const [locations, { isLoading: loadingLocations }] = useLocations({
    filter: {
      _sort: 'name'
    }
  });
  const { mutateAsync: saveRounding, isSuccess, isLoading, isError } = useSaveRoundingMutation();
  const { data: bedsToRouding, isLoading: loadingBedsToRounding, refetch: refetchBedsToRounding } = useBedsToRounding(rounding?.id!);
  const { data: bedsResponse, isLoading: isLoadingBeds, refetch } = useCensusBeds({
    pagination: {
      take: 900000,
      skip: 0,
    },
    search: null,
    status: 'active',
    locationFhirId: locationItem?.value ?? null,
  });
  console.log({
    bedsToRouding,
    bedItems
  })
  const bedOptions = useMemo(() => !isLoadingBeds ? bedsResponse?.beds?.map((bed: Bed) => ({
    label: bed.identifier,
    value: bed.id,
  })) : [], [bedsResponse?.beds, isLoadingBeds]);

  const { data: roundingTypes } = useRoundingTypes();

  const onCancelPress = () => {
    onCloseModal();
  }

  const validate = (): boolean => {
    if (!startDateInterval || !startTimeInterval || interval < 15 || !categoryItem || !locationItem || bedItems.length === 0) {
      return false;
    }

    return true;
  }
  const onSavePress = () => {
    if (!validate()) {
      updateState({
        validated: false,
      })
      return;
    }
    saveRounding({
      id: rounding?.id,
      categoryId: categoryItem?.value,
      startInterval: moment(startDateInterval)
      .set({
        hour: +startTimeInterval.split(':')[0],
        minute: +startTimeInterval.split(':')[1],
        seconds: 0,
      })
      .format(),
      interval: interval,
      beds: bedItems?.map((b) => b.value),
      sections: sectionItems.join(','),
      locationFhirId: locationItem?.value,
    }).then((response) => {
      onSaveCallback(response)
      refetchBedsToRounding();
      setTimeout(() => {
        onCloseModal();
      }, 800);
    });
  }

  useEffect(() => {
    updateState({
      bedItems: bedsToRouding?.map((bed) => ({
        label: bed.bed.identifier,
        value: bed.bed.id
      })) ?? []
    })
  }, [bedsToRouding])

  useEffect(() => {
    refetch();
  }, [locationItem, refetch])

  return (
    <Box>
      <Box
        sx={{
          my: 3,
          position: 'fixed',
          width: '500px',
          backgroundColor: 'white',
          opacity: 1,
          zIndex: 9,
          top: '-25px',
          left: 0,
          pl: 4,
          pr: 4,
        }}
      >
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {rounding ? 'Edit' : 'Add'} rounding
            </Typography>
          </Grid>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ marginTop: '80px' }}>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel>Category *</InputLabel>
          <Autocomplete
            disablePortal
            options={roundingTypes?.map((location) => ({
              label: location.valueDisplayName,
              value: location.id,
            })) ?? []}
            value={categoryItem}
            onChange={(_: any, value: any | null) => updateState({ categoryItem: value })}
            renderInput={(params) => (
              <TextField variant="standard" {...params} required />
            )}
          />
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel>Location *</InputLabel>
          <Select
            value={locationItem?.value}
            style={{
              width: '100%'
            }}
            onChange={(e) => updateState({ locationItem: { label: '', value: e.target.value } })}
          >
          {locations?.map((location) => (
            <MenuItem key={location.id} value={location.id} >{location.name}</MenuItem>
          ))}
          </Select>
        </Grid>
        <Grid container style={{ marginTop: '15px' }}>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <TextField
                label="Start date interval"
                value={startDateInterval}
                onChange={(e) => updateState({ startDateInterval: e.target.value })}
                type="date"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <TextField
                label="Start time interval"
                value={startTimeInterval}
                onChange={(e) => updateState({ startTimeInterval: e.target.value })}
                type="time"
                variant="standard"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <TextField
              label="Time interval"
              value={interval}
              onChange={(e) => updateState({ interval: parseInt(e.target.value) })}
              type="number"
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disablePortal
              options={sectionOptions ?? []}
              value={sectionItems}
              onChange={(_: any, value: any | null) => updateState({ sectionItems: value, bedItems: [] })}
              renderInput={(params) => (
                <TextField variant="standard" {...params} label="Sections" required />
              )}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={sectionItems.find((i) => i === option) ? true : false}
                  />
                  {option}
                </li>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disablePortal
              options={bedOptions}
              value={bedItems}
              onChange={(_: any, value: any | null) => {
                updateState({ bedItems: value })
              }}
              renderInput={(params) => (
                <TextField variant="standard" {...params} label="Beds" required />
              )}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={bedItems.find((i) => i.value === option.value) ? true : false}
                  />
                  {option.label}
                </li>
              )}
              disabled={!locationItem ? true : false}
            />
          </FormControl>
        </Grid>
        {locationItem && (
          <Alert style={{ marginTop: '20px' }} severity="info">
            Select a location to see available beds
          </Alert>
        )}

        {!validated && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            Please fill all fields and try again
          </Alert>
        )}
        
        {isError && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            Error saving rounding
          </Alert>
        )}
        {isSuccess && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            Rounding saved successfully
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading || loadingLocations || loadingBedsToRounding}
          onLeftButtonPress={onCancelPress}
          leftButtonTitle='Cancel'
          rightButtonTitle="Save"
          onRightButtonPress={onSavePress}
        />
      </Box>
    </Box>
  )
};

export default AddRounding;
