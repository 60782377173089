import React from 'react';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { PermissionsBase } from 'src/contexts/PermissionsContext';
import {
  CodeableConcept,
  FhirResource,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export interface AssessmentLinks {
  name: string;
  label: string;
  link: string;
}

export interface AssessmentURLAppend {
  name: string;
  urlAppend: string;
}

export type TaskHandler = (task: WrappedTask, externalHandler?: Function) => void;

export interface TaskHandlerByType {
  taskType: string;
  handler: TaskHandler;
}
export interface MenuTaskHandler extends TaskHandlerByType {
  label: string;
  icon: React.ReactNode;
  conditionToShow: (task: WrappedTask) => boolean;
}

export interface ContactDetailsPermissionProps {
  isAllowedToAdd: boolean;
  isAllowedToDelete: boolean;
}

export interface NotePermissions extends PermissionsBase {
  isAllowedToView: boolean;
  isAllowedToAdd: boolean;
}

export interface TaskPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
  isAllowedToAssignProgram: boolean;
  notes: NotePermissions;
}

export interface GoalsPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
  notes: NotePermissions;
  tasks: TaskPermissions;
}

export interface ResourceWithIncludedResources<T = FhirResource> {
  resource: T;
  includedResources: FhirResource[];
}

export interface ReferenceResources extends Reference, Partial<ResourceWithIncludedResources> {}
export interface WorkflowPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
  isAllowedToNext: boolean;
  isAllowedToClose: boolean;
}

export interface PatientEngagementPermissions extends PermissionsBase {
  isAllowedToEdit: boolean;
  notes: NotePermissions;
}

export enum RequesterResourceTypes {
  PRACTITIONER = 'Practitioner',
  PRACTITIONER_ROLE = 'PractitionerRole',
  ORGANIZATION = 'Organization',
  PATIENT = 'Patient',
  RELATED_PERSON = 'RelatedPerson',
}

export interface ConsentPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToEdit: boolean;
}

export interface GeneralPractitionerGrid {
  id: string;
  reference?: Reference;
  npi?: string;
  internal?: string;
  practitioner?: {
    id?: string;
    fullName?: string | null;
  };
  organization?: {
    id?: string;
    fullName?: string | null;
  };
  role?: CodeableConcept;
  roleActive?: boolean;
  phone?: string;
  email?: string;
  address?: string;
}
