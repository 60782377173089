import { SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM } from 'src/config';
import { WrappedHealthcareService } from '../../../../../@nicheaim/fhir-base/wrappers/HealthcareService';
import { WrappedOrganization } from '../../../../../@nicheaim/fhir-base/wrappers/Organization';
import { WrappedServiceRequest } from '../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';
import { getIdFromReference, getReferenceId } from '../../../../../utils/fhir';
import { ReferralStatuses } from 'src/sections/crs/referral/components/child-referral/constants';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';

export class PatientGenericServiceRequestDto {
  id?: string;

  status?: string;

  referredFromId?: string;

  referredFromName?: string;

  referredToId?: string;

  referredToName?: string;

  serviceId?: string;

  serviceCode?: string;

  serviceName?: string;

  serviceOrganizationName?: string;

  // hot fix to declare prop
  created?: string;

  internalNumber?: string;

  tasks?: WrappedTask[];
}

export const wrappedServiceRequestToPatientGenericServiceRequestDto = (
  serviceRequest: WrappedServiceRequest,
  organizations: WrappedOrganization[],
  healthcareServices: WrappedHealthcareService[],
  tasks: WrappedTask[]
) => {
  const dto: PatientGenericServiceRequestDto = {
    id: serviceRequest?.id,
  };

  dto.tasks = tasks.filter(
    ({ basedOn }) => !!basedOn?.some?.(({ reference }) => getReferenceId(reference) === dto.id)
  );

  if (serviceRequest?.status) {
    dto.status = ReferralStatuses?.[serviceRequest?.status] ?? '';
  }

  const perfOrgRef = serviceRequest?.performer?.filter((x) =>
    x?.reference?.startsWith('Organization')
  )?.[0];
  if (perfOrgRef && perfOrgRef?.reference) {
    const perfOrgId = getIdFromReference(perfOrgRef?.reference);
    const perfOrg = organizations.find((x) => x?.id === perfOrgId);
    if (perfOrg) {
      dto.referredToId = perfOrg?.id;
      dto.referredToName = perfOrg?.name;
    }
  }

  const reqRef = serviceRequest?.requester ?? null;
  if (reqRef) {
    dto.referredFromId = reqRef?.id;
    dto.referredFromName = reqRef?.display;
  }

  const perfServRef = serviceRequest?.performer?.filter((x) =>
    x?.reference?.startsWith('HealthcareService')
  )?.[0];
  if (perfServRef && perfServRef?.reference) {
    const perfServId = getIdFromReference(perfServRef?.reference);
    const perfServ = healthcareServices.find((x) => x?.id === perfServId);
    if (perfServ) {
      dto.serviceId = perfServ?.id;
      dto.serviceCode = perfServ?.program?.[0]?.coding?.[0]?.code ?? '';
      dto.serviceName = perfServ?.name ?? '';
    }

    const organizationId = perfServ?.providedBy?.reference?.split?.('/')?.[1];

    dto.serviceOrganizationName = organizationId
      ? organizations.find(({ id }) => id === organizationId)?.name ?? ''
      : '';
  }

  dto.created = serviceRequest?.authoredOn;

  dto.internalNumber =
    serviceRequest?.identifier?.find?.(
      ({ system }) => system === SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM
    )?.value ?? '';

  return dto;
};
