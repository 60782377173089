import { Grid, Box } from '@mui/material';
import { Person, User } from '../types';
import PersonGeneralInfoEditableCard from './PersonGeneralInfoEditableCard/PersonGeneralInfoEditableCard';
import PersonContactDetails from './PersonContactDetails/PersonContactDetails';

export interface PersonDetailsContainerProps {
  person: Person | null;
  user?: User | null;
  onPersonDetailsSave?: VoidFunction;
}

const PersonDetailsContainer = ({
  person,
  onPersonDetailsSave,
  user,
}: PersonDetailsContainerProps) => (
  <Box sx={{ width: '100%' }}>
    <Grid container sx={{ width: '100%' }} spacing={2}>
      <Grid item xs={6}>
        <PersonGeneralInfoEditableCard
          user={user}
          person={person}
          onPersonSave={onPersonDetailsSave}
        />
      </Grid>
      <Grid item xs={6}>
        <PersonContactDetails person={person} onPersonSave={onPersonDetailsSave} />
      </Grid>
    </Grid>
  </Box>
);

export default PersonDetailsContainer;
