import Cookies from 'js-cookie';
import { getAclsFromStorage } from 'src/services/api/permissions';
import { DEVELOPMENT_CONFIG, SESSION_EXPIRED_ROUTE } from '../../config';
import { getBackEndBaseUrl, getFrontendBaseUrl } from '../domain-utils';
import { isValidToken } from '../jwt';
import {
  NO_ALLOWING_CONTAINER,
  PermissionsContainer,
  newPermissionsContainer,
} from './permissions.container';

export function getUserPermissions(): PermissionsContainer {
  const careflowToken = Cookies.get('careflowToken');
  if (careflowToken && isValidToken(careflowToken)) {
    const acls = getAclsFromStorage();

    return newPermissionsContainer(acls, (dat) => dat);
  } else {
    return NO_ALLOWING_CONTAINER;
  }
}

export async function expireSession(extraMessage?: string) {
  try {
    await fetch(`${getBackEndBaseUrl()}/auth/logout-handler`, {
      method: 'post',
      body: new URLSearchParams({
        no_redirect: 'true',
        refresh_token: Cookies.get('refreshToken') || '',
      }),
    });
  } catch (err) {
    console.log('handled error: ', err);
  }

  sessionStorage.setItem('sessionExpired', 'true');
  let expireUrl = getFrontendBaseUrl() + SESSION_EXPIRED_ROUTE;

  if (extraMessage) {
    expireUrl += '?extra-message=' + extraMessage;
  }

  window.location.href = expireUrl;
}

export async function checkPermissions() {
  if (!DEVELOPMENT_CONFIG.disableTokenCheck && getUserPermissions() === NO_ALLOWING_CONTAINER) {
    console.log('checkPersmissions logout');
    await expireSession('session expired because session not pass user checking permisions');
  }
}
